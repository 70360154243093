import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class ImprRapportCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateD: "",
      dateF: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var array = url.split("=");
      var params = array[1];
      var arrayDate = params.split("/");
      var dateD = arrayDate[0];
      var dateF = arrayDate[1];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      this.setState({
        dateD: dateD,
        dateF: dateF,
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "7%" }} className="row">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              Rapport Recette Formation par Cycle entre {this.state.dateD} et{" "}
              {this.state.dateF}
            </label>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
        >
          <table style={{ border: "solid", marginBottom: "0", width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ border: "solid", color: "black" }}>
                  Total Remboursement
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  0.000{" "}
                </td>
              </tr>
              <tr>
                <td style={{ border: "solid", color: "black" }}>
                  Total de Recette en Espèces
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  0.000{" "}
                </td>
              </tr>
              <tr>
                <td style={{ border: "solid", color: "black" }}>
                  Total de Recette par Chèque
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  0.000{" "}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: "right",
                    border: "solid",
                    color: "black",
                    fontSize: "20px",
                    paddingRight: "5%",
                  }}
                >
                  Total
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  0.000{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprRapportCycle);
