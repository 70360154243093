import React, { Component, Fragment } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { RadioGroup, Radio } from 'react-radio-group'

class AjoutAutreFrais extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedScol: '1',
            selectedChoix: '1',
            mois: [{ value: 1, label: 'Janvier' }, { value: 2, label: 'Férvier' }, { value: 3, label: 'Mars' }, { value: 4, label: 'Avril' }, { value: 5, label: 'Mai' }, { value: 6, label: 'Juin' }, { value: 7, label: 'Juillet' }, { value: 8, label: 'Aout' }, { value: 9, label: 'Septembre' }, { value: 10, label: 'Octobre' }, { value: 11, label: 'Novembre' }, { value: 12, label: 'Decembre' }],
            selectedMois: '',
            autreFrais: [],
            selectedAutreFrais: '',
            nvAutreFrais: '',
            description: '',
            numIns: '',
            listApprenant: [],
            selectedApprenant: '',
            nvNomApp: '',
            numTel: '',
            totalPay: 0,
            datePay: new Date(),
            tabModPay: [{ modPay: '', mntModPay: '', numCheque: '', echeanceChec: new Date(), dateEncaiss: new Date(), titulaireChec: '', banque: '' }],
            groupe: '',
            disbut: true
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectAutreFrais.php').then(res => {
                console.log(res)
                this.setState({
                    autreFrais: res.data
                })
            })
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEleves.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        listApprenant: code_html
                    });
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectModePaiement.php').then(res => {
                console.log(res)
                this.setState({
                    listModPay: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    addType = (selectedScol) => {
        this.setState({ selectedScol: selectedScol });
    }

    addChoix = (selectedChoix) => {
        this.setState({ selectedChoix: selectedChoix });
    }

    addMois = selectedMois => {
        this.setState({ selectedMois });
    }

    addAutreFrais = selectedAutreFrais => {
        this.setState({ selectedAutreFrais });
    }

    addNvAutreFrais = (event) => {
        this.setState({
            nvAutreFrais: event.target.value
        });
    }

    addDescript = (event) => {
        this.setState({
            description: event.target.value
        });
    }

    addNumIns = (event) => {
        this.setState({
            numIns: event.target.value
        });
    }

    addApprenant = selectedApprenant => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (selectedApprenant != null) {
            this.setState({ selectedApprenant }, () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetInfoInsEleve.php',
                    type: 'POST',
                    data: {
                        eleveId: selectedApprenant.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            numIns: code_html.numIns,
                            numTel: code_html.numTel,
                            groupe: code_html.groupe
                        });
                    }
                });
            });
        }
    };

    addNvNomApp = (event) => {
        this.setState({
            nvNomApp: event.target.value
        });
    }

    addNumTel = (event) => {
        this.setState({
            numTel: event.target.value
        });
    }

    addTotPay = (event) => {
        this.setState({
            totalPay: event.target.value
        }, () => {
            var mnt = this.state.totalPay
            this.setState({
                tabModPay: this.state.tabModPay.map((el, id) => (id === 0 ? Object.assign(el, { mntModPay: mnt }) : el))
            });
        });
    }

    addDatePaiement = d => {
        this.setState({
            datePay: d
        });
    }

    addModPay = (selectedModPay, indexModPay) => {
        if (selectedModPay != null) {
            let modPay = selectedModPay;
            this.setState({
                tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { modPay: modPay }) : el))
            });
        }
    }

    addMntModPay = (event, indexModPay) => {
        let mntModPay = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { mntModPay: mntModPay }) : el))
        });
    }

    addNumChec = (event, indexModPay) => {
        let numCheque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { numCheque: numCheque }) : el))
        });
    }

    addEcheanceChec = (d, indexModPay) => {
        let dateChec = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { echeanceChec: dateChec }) : el))
        });
    }

    addDateEncaiss = (d, indexModPay) => {
        let dateEncaiss = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { dateEncaiss: dateEncaiss }) : el))
        });
    }

    addTitulaireChec = (event, indexModPay) => {
        let titulaireChec = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { titulaireChec: titulaireChec }) : el))
        });
    }

    addBanque = (event, indexModPay) => {
        let banque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { banque: banque }) : el))
        });
    }

    ajout = () => {
        this.setState({ disbut: false }, () => {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            var userId = userPermissions[0].idUser
            // convert date seance
            let datePay = this.state.datePay
            let month = '' + (datePay.getMonth() + 1);
            let day = '' + datePay.getDate();
            let year = datePay.getFullYear();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            var convertDatePay = [year, month, day].join('-');
            // 
            if (this.state.selectedScol == 1) {
                // convertir date echeance et encaissement cheque si le mode de paiement => cheque
                var convertDateEcheChec = ''
                var convertDateEncaissChec = ''
                for (var i = 0; i < this.state.tabModPay.length; i++) {
                    if (this.state.tabModPay[i].modPay.value == 2) {
                        // convertir date echeance cheque
                        var dateEcheChec = this.state.tabModPay[i].echeanceChec
                        let monthOne = '' + (dateEcheChec.getMonth() + 1);
                        let dayOne = '' + dateEcheChec.getDate();
                        let yearOne = dateEcheChec.getFullYear();
                        if (monthOne.length < 2)
                            monthOne = '0' + monthOne;
                        if (dayOne.length < 2)
                            dayOne = '0' + dayOne;
                        convertDateEcheChec = [yearOne, monthOne, dayOne].join('-');
                        // convertir date encaissement cheque
                        var dateEncaissChec = this.state.tabModPay[i].dateEncaiss
                        let monthTwo = '' + (dateEncaissChec.getMonth() + 1);
                        let dayTwo = '' + dateEncaissChec.getDate();
                        let yearTwo = dateEncaissChec.getFullYear();
                        if (monthTwo.length < 2)
                            monthTwo = '0' + monthTwo;
                        if (dayTwo.length < 2)
                            dayTwo = '0' + dayTwo;
                        convertDateEncaissChec = [yearTwo, monthTwo, dayTwo].join('-');
                    }
                }
                //
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddAutreFrais.php',
                    type: 'POST',
                    data: {
                        choix: this.state.selectedChoix,
                        nvAutreFrais: this.state.nvAutreFrais,
                        typeAutreFrais: this.state.selectedAutreFrais,
                        mois: this.state.selectedMois.value,
                        descript: this.state.description,
                        numIns: this.state.numIns,
                        apprenantId: this.state.selectedApprenant.value,
                        nomApprenant: this.state.selectedApprenant.label,
                        numTel: this.state.numTel,
                        groupe: this.state.groupe,
                        totalPay: this.state.totalPay,
                        datePay: convertDatePay,
                        modPay: this.state.tabModPay,
                        anneeScolaire: anneeScolaire,
                        userId: userId,
                        dateEcheChec: convertDateEcheChec,
                        dateEncaissChec: convertDateEncaissChec
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        if (code_html.Result == "OK") {
                            // Recu autre frais
                            var idAutreFrais = code_html.idAutreFrais
                            jQuery.ajax({
                                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuAF.php',
                                type: 'POST',
                                data: {
                                    idAutreFrais: idAutreFrais
                                },
                                dataType: 'json',
                                success: function (code_html, statut) {
                                    var server_path = code_html.server_path;
                                    var file_path = code_html.file_path
                                    var path = server_path + file_path;
                                    window.open(path, "_blank")
                                }
                            });
                            this.props.history.push('/AutreFrais');
                        }
                        else if (code_html.Result == "KO") {
                            alert("erreur,l'un de vos champs est vide!!");
                        }
                    }
                });
            }
            else {
                // convertir date echeance et encaissement cheque si le mode de paiement => cheque
                var convertDateEcheChec = ''
                var convertDateEncaissChec = ''
                for (var i = 0; i < this.state.tabModPay.length; i++) {
                    if (this.state.tabModPay[i].modPay.value == 2) {
                        // convertir date echeance cheque
                        var dateEcheChec = this.state.tabModPay[i].echeanceChec
                        let monthOne = '' + (dateEcheChec.getMonth() + 1);
                        let dayOne = '' + dateEcheChec.getDate();
                        let yearOne = dateEcheChec.getFullYear();
                        if (monthOne.length < 2)
                            monthOne = '0' + monthOne;
                        if (dayOne.length < 2)
                            dayOne = '0' + dayOne;
                        convertDateEcheChec = [yearOne, monthOne, dayOne].join('-');
                        // convertir date encaissement cheque
                        var dateEncaissChec = this.state.tabModPay[i].dateEncaiss
                        let monthTwo = '' + (dateEncaissChec.getMonth() + 1);
                        let dayTwo = '' + dateEncaissChec.getDate();
                        let yearTwo = dateEncaissChec.getFullYear();
                        if (monthTwo.length < 2)
                            monthTwo = '0' + monthTwo;
                        if (dayTwo.length < 2)
                            dayTwo = '0' + dayTwo;
                        convertDateEncaissChec = [yearTwo, monthTwo, dayTwo].join('-');
                    }
                }
                //
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddAutreFrais.php',
                    type: 'POST',
                    data: {
                        choix: this.state.selectedChoix,
                        nvAutreFrais: this.state.nvAutreFrais,
                        typeAutreFrais: this.state.selectedAutreFrais,
                        mois: this.state.selectedMois.value,
                        descript: this.state.description,
                        numIns: this.state.numIns,
                        apprenantId: 0,
                        nomApprenant: this.state.nvNomApp,
                        numTel: this.state.numTel,
                        groupe: 0,
                        totalPay: this.state.totalPay,
                        datePay: convertDatePay,
                        modPay: this.state.tabModPay,
                        anneeScolaire: anneeScolaire,
                        userId: userId,
                        dateEcheChec: convertDateEcheChec,
                        dateEncaissChec: convertDateEncaissChec
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        if (code_html.Result == "OK") {
                            // Recu autre frais
                            var idAutreFrais = code_html.idAutreFrais
                            jQuery.ajax({
                                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuAF.php',
                                type: 'POST',
                                data: {
                                    idAutreFrais: idAutreFrais
                                },
                                dataType: 'json',
                                success: function (code_html, statut) {
                                    var server_path = code_html.server_path;
                                    var file_path = code_html.file_path
                                    var path = server_path + file_path;
                                    window.open(path, "_blank")
                                }
                            });
                            this.props.history.push('/AutreFrais');
                        }
                        else if (code_html.Result == "KO") {
                            alert("erreur,l'un de vos champs est vide!!");
                        }
                    }
                });
            }
        })
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout autre frais</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/AutreFrais" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <RadioGroup
                                            name="typeScol"
                                            selectedValue={this.state.selectedScol}
                                            onChange={this.addType}>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="1" />Eleve inscrit
                                                    </label>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="2" />Nouveau
                                                    </label>
                                        </RadioGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <RadioGroup
                                            name="typeChoix"
                                            selectedValue={this.state.selectedChoix}
                                            onChange={this.addChoix}>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="1" />Frais existant
                                                    </label>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="2" />Ajouter nouveau frais
                                                    </label>
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    {this.state.selectedChoix == 2 ?
                                        (<div className="col-md-6">
                                            <div className="form-group">
                                                <label>Ajouter nouveau frais</label>
                                                <input type="text" className="form-control" value={this.state.nvAutreFrais} onChange={this.addNvAutreFrais} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                            </div>
                                        </div>) : (<div className="col-md-6">
                                            <div className="form-group">
                                                <label>frais excitant</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.addAutreFrais}
                                                    options={this.state.autreFrais}
                                                />
                                            </div>
                                        </div>)}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <input type="text" className="form-control" placeholder='Description' onChange={this.addDescript} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mois</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addMois}
                                                options={this.state.mois}
                                            />
                                        </div>
                                    </div>
                                    {this.state.selectedScol == 1 ?
                                        (<Fragment>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Apprenant</label>
                                                    <Select
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={this.addApprenant}
                                                        options={this.state.listApprenant}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Numéro inscription</label>
                                                    <input type="text" className="form-control" value={this.state.numIns} onChange={this.addNumIns} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div></Fragment>) : (<div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Apprenant</label>
                                                    <input type="text" className="form-control" onChange={this.addNvNomApp} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>)}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Numéro téléphone</label>
                                            <input type="text" className="form-control" value={this.state.numTel} onChange={this.addNumTel} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Total à payer </label>
                                            <input type="number" className="form-control" value={this.state.totalPay} onChange={this.addTotPay} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date paiement</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.datePay}
                                                onChange={this.addDatePaiement}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <h3 style={{ paddingLeft: '15px' }}>Mode de paiement :</h3>
                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Mode paiement</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Montant Paiement</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Numéro cheque</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Echéance cheque</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Date encaissement</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Titulaire cheque</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Banque</th>
                                            </tr>
                                        </thead>
                                        {this.state.tabModPay.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-3"><Select
                                                        value={el.modPay}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.addModPay(e, index)}
                                                        options={this.state.listModPay}
                                                    />  </td>
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><input type="number" value={el.mntModPay} onChange={(e) => this.addMntModPay(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                    {this.state.tabModPay[index].modPay.value == 2 ?
                                                        (<Fragment>
                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.numCheque} onChange={(e) => this.addNumChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} dateFormat="dd/MM/yyy" selected={el.echeanceChec} onChange={(e) => this.addEcheanceChec(e, index)} /></td>
                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} dateFormat="dd/MM/yyy" selected={el.dateEncaiss} onChange={(e) => this.addDateEncaiss(e, index)} /></td>
                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.titulaireChec} onChange={(e) => this.addTitulaireChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.banque} onChange={(e) => this.addBanque(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                        </Fragment>) :
                                                        (<Fragment>
                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                        </Fragment>)}
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', marginTop: '10px' }}>
                                    <button disabled={!this.state.disbut} style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                            <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AjoutAutreFrais);