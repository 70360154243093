import React, { Component, Fragment } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class AjoutAttestation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listEleve: [],
            selectedElv: '',
            listMotif: [],
            selectedMotif: '',
            scolAnnee: '',
            naissance: '',
            cin: '',
            ins: '',
            niveau: '',
            today: '',
            codeTranstu: '',
            blockMotif: false
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectAppGroupes.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listEleve: code_html
                    });
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectMotifs.php').then(res => {
                console.log(res)
                this.setState({
                    listMotif: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    selectEleve = selectedElv => {
        if (selectedElv != null) {
            this.setState({ selectedElv: selectedElv });
        }
        else {
            this.setState({
                selectedElv: ''
            });
        }
    };

    selectMotif = selectedMotif => {
        if (selectedMotif != null) {
            this.setState({ selectedMotif: selectedMotif });
        }
        else {
            this.setState({ selectedMotif: "" });
        }
    };

    affiche = () => {
        if (this.state.selectedElv != '' && this.state.selectedMotif != '') {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            var anneeScolaireNv = parseInt(anneeScolaire) + 1
            var scolAnnee = anneeScolaire + '/' + anneeScolaireNv
            // Recuperer information eleve
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetInformationEleve.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire,
                    eleveId: this.state.selectedElv.value,
                    motifId: this.state.selectedMotif.value
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        today: code_html.today,
                        naissance: code_html.naissance,
                        cin: code_html.cin,
                        ins: code_html.idIns,
                        niveau: code_html.niveau,
                        scolAnnee: scolAnnee,
                        codeTranstu: code_html.code,
                        blockMotif: true
                    });
                }
            });
        }
        else {
            toast.error("⛔ Veuillez selectionner un apprenant et un motif !!", { containerId: 'A' });
        }
    }

    impr = () => {
        if (this.state.selectedElv != '' && this.state.selectedMotif != '') {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddMotif.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire,
                    eleveId: this.state.selectedElv.value,
                    motifId: this.state.selectedMotif.value
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    if (code_html.Result == "OK") {
                        var params = this.state.selectedElv.value + '-' + this.state.selectedMotif.value
                        window.open("http://artsmetiers.mtd-app.com/ImprMotif?params=" + params)
                        this.props.history.push('/Attestation');
                    }
                    else if (code_html.Result == "KO") {
                        alert("erreur,l'un de vos champs est vide!!");
                    }
                }
            });
        }
        else {
            toast.error("⛔ Veuillez selectionner un apprenant et un motif !!", { containerId: 'A' });
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout Attéstation</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Attestation"><button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Eléves</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.selectEleve(e)}
                                                options={this.state.listEleve}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Motif</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.selectMotif(e)}
                                                options={this.state.listMotif}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={() => this.affiche()}>Afficher</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.impr}><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Impression</button>
                                    </div>
                                </div>
                                {this.state.blockMotif == true ? (
                                    <div style={{ margin: '50px' }} className="row">
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '100px' }}><p style={{ fontWeight: 'bold' }}>Date:</p><p style={{ paddingLeft: '5px' }}>{this.state.today}</p></div>
                                        {this.state.selectedMotif.value == 5 ? (<div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '100px' }}><p style={{ fontWeight: 'bold' }}>Code TRANSTU</p><p style={{ paddingLeft: '5px', fontWeight: 'bold' }}>{this.state.codeTranstu}</p></div>) : (null)}
                                        <h4 style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>Attestation de présence</h4>
                                        <p style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>Je soussigné, Taoufik CHAIRI, Directeur Général du centre de formation</p>
                                        <p style={{ display: 'flex', justifyContent: 'center' }}>professionnelle Arts & Métiers,certifie par le présent que l'apprenant :</p>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Nom et Prénom :</p><p style={{ paddingLeft: '5px' }}>{this.state.selectedElv.label}</p></div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Né(e) le :</p><p style={{ paddingLeft: '5px' }}>{this.state.naissance}</p></div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>CIN  N ° :</p><p style={{ paddingLeft: '5px' }}>{this.state.cin}</p></div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><p>Est inscrit(e) dans notre centre sous le</p><p style={{ paddingLeft: '5px', fontWeight: 'bold' }}>N°</p> <p style={{ paddingLeft: '5px' }}>{this.state.ins}</p><p style={{ paddingLeft: '5px' }}>en vue de poursuivre sa</p></div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><p>scolarité en</p><p style={{ paddingLeft: '5px', fontWeight: 'bold' }}>{this.state.niveau}</p><p style={{ paddingLeft: '5px' }}>pour l'année scolaire</p><p style={{ paddingLeft: '5px' }}>{this.state.scolAnnee}</p></div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}><p>Motif de l'attestation : Constituer un dossier de </p><p style={{ paddingLeft: '5px' }}>{this.state.selectedMotif.label}</p></div>
                                        <p style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '310px', marginTop: '20px' }}>Le Directeur Général</p>
                                        <p style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '310px' }}>Taoufik CHAIRI</p>
                                    </div>) : (null)}
                            </div>
                        </div>
                    </div>
                    <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                </div>
            </div>
        );
    }
}
export default withRouter(AjoutAttestation);