import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";

class ImprNoteStageBTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listEleves: [],
      annee: "",
      yearAfter: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var arrayGroupe = url.split("=");
      var groupeId = arrayGroupe[1];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprNoteDeStage.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          groupeId: groupeId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listEleves: code_html,
            annee: anneeScolaire,
            yearAfter: parseFloat(anneeScolaire) + 1,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoGroupe.php",
        type: "POST",
        data: {
          groupeId: groupeId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            filiere: code_html.nomFiliere,
            niveau: code_html.niveau,
            groupe: code_html.groupe,
            annee: code_html.annee,
            anneeScolaire: code_html.anneeScolaire,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "2%" }} className="row">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>Rapport de note stage </label>
          </div>
        </div>
        <div style={{ textAlign: "center" }} className="row">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              {" "}
              Année Scolaire {this.state.annee}/{this.state.yearAfter}{" "}
            </label>
          </div>
        </div>
        <table
          border={0}
          align="center"
          cellSpacing={1}
          cellPadding={4}
          width={1100}
        >
          <tbody>
            <tr>
              <td height={42}>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Formation:
                  </p>
                  <p style={{ marginLeft: "5px", color: "black" }}>
                    De Octobre à Juillet
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td height={42}>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Spécialité:
                  </p>
                  <p style={{ marginLeft: "5px", color: "black" }}>
                    {this.state.filiere}
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td height={42}>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Niveau:
                  </p>
                  <p style={{ marginLeft: "5px", color: "black" }}>
                    {this.state.niveau}
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td height={42}>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Groupe:
                  </p>
                  <p style={{ marginLeft: "5px", color: "black" }}>
                    {this.state.groupe}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
        >
          <table style={{ border: "solid", width: "80%" }}>
            <thead>
              <th
                style={{ textAlign: "center", border: "solid", color: "black" }}
              >
                N°
              </th>
              <th
                style={{ textAlign: "center", border: "solid", color: "black" }}
              >
                {" "}
                Apprenant{" "}
              </th>
              <th
                style={{ textAlign: "center", border: "solid", color: "black" }}
              >
                {" "}
                Note De Stage{" "}
              </th>
            </thead>
            <tbody>
              {this.state.listEleves.map((elem, index) => (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {" "}
                    {index + 1}{" "}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      width: "30%",
                      color: "black",
                    }}
                  >
                    {" "}
                    {elem.nomEleve}{" "}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {elem.noteStage.map((obj) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> {obj} </p>
                      </div>
                    ))}
                    <p> Moyenne stage : {elem.moyStage} </p>
                  </td>
                  {/* {elem.noteStage.map((obj) => (
                    <td style={{ textAlign: "center", border: "solid" }}>
                      {obj}
                    </td>
                  ))} */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprNoteStageBTS);
