import React, { Component, Fragment } from "react";
import axios from "axios";
import jQuery from "jquery";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { RadioGroup, Radio } from "react-radio-group";

class AjoutRemboursement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRembours: new Date(),
      selectedScol: "",
      listApprenant: [],
      selectedApprenant: "",
      listRecu: [],
      selectedNumRecu: "",
      description: "",
      mntRebours: 0,
      tabModPay: [
        { modPay: "", mntModPay: "", numCheque: "", echeanceChec: new Date() },
      ],
      disbut: true,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var idRoleUser = userPermissions[0].idRoleUser;
      if (idRoleUser == 1) {
        axios
          .get(
            process.env.REACT_APP_API_URL +
              "Back_mediatraining/SelectModePaiement.php"
          )
          .then((res) => {
            console.log(res);
            this.setState({
              listModPay: res.data,
            });
          });
      } else {
        localStorage.clear();
        this.props.history.push("/");
      }
    } else {
      document.location = "/";
    }
  }

  addDateRembours = (d) => {
    this.setState({
      dateRembours: d,
    });
  };

  addType = (selectedScol) => {
    if (selectedScol != null) {
      this.setState({ selectedScol: selectedScol }, () => {
        var userPermissions = JSON.parse(
          localStorage.getItem("userPermissions")
        );
        var anneeScolaire = userPermissions[0].anneeScolaire;
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectEleveByScol.php",
          type: "POST",
          data: {
            selectedScol: selectedScol,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              listApprenant: code_html,
            });
          },
        });
      });
    }
  };

  addApprenant = (selectedApprenant) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState({ selectedApprenant }, () => {
      if (selectedApprenant != null) {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetRecuByEleve.php",
          type: "POST",
          data: {
            selectedScol: this.state.selectedScol,
            idApprenant: selectedApprenant.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              listRecu: code_html,
            });
          },
        });
      }
    });
  };

  addNumRecu = (selectedNumRecu) => {
    this.setState({ selectedNumRecu }, () => {
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/GetMntRecu.php",
        type: "POST",
        data: {
          selectedScol: this.state.selectedScol,
          idRecu: selectedNumRecu.value,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState(
            {
              mntRebours: code_html,
            },
            () => {
              var mnt = this.state.mntRebours;
              this.setState({
                tabModPay: this.state.tabModPay.map((el, id) =>
                  id === 0
                    ? Object.assign(el, {
                        mntModPay: Number.parseFloat(mnt).toFixed(3),
                      })
                    : el
                ),
              });
            }
          );
        },
      });
    });
  };

  addDescript = (event) => {
    this.setState({
      description: event.target.value,
    });
  };

  addMntRebours = (event) => {
    this.setState(
      {
        mntRebours: event.target.value,
      },
      () => {
        var mnt = this.state.mntRebours;
        this.setState({
          tabModPay: this.state.tabModPay.map((el, id) =>
            id === 0 ? Object.assign(el, { mntModPay: mnt }) : el
          ),
        });
      }
    );
  };

  addModPay = (selectedModPay, indexModPay) => {
    if (selectedModPay != null) {
      let modPay = selectedModPay;
      this.setState({
        tabModPay: this.state.tabModPay.map((el, id) =>
          id === indexModPay ? Object.assign(el, { modPay: modPay }) : el
        ),
      });
    }
  };

  addMntModPay = (event, indexModPay) => {
    let mntModPay = event.target.value;
    this.setState({
      tabModPay: this.state.tabModPay.map((el, id) =>
        id === indexModPay ? Object.assign(el, { mntModPay: mntModPay }) : el
      ),
    });
  };

  addNumChec = (event, indexModPay) => {
    let numCheque = event.target.value;
    this.setState({
      tabModPay: this.state.tabModPay.map((el, id) =>
        id === indexModPay ? Object.assign(el, { numCheque: numCheque }) : el
      ),
    });
  };

  addEcheanceChec = (d, indexModPay) => {
    let dateChec = d;
    this.setState({
      tabModPay: this.state.tabModPay.map((el, id) =>
        id === indexModPay ? Object.assign(el, { echeanceChec: dateChec }) : el
      ),
    });
  };

  ajout = () => {
    this.setState({ disbut: false }, () => {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var userId = userPermissions[0].idUser;
      // convert date rembours
      let dateRembours = this.state.dateRembours;
      let month = "" + (dateRembours.getMonth() + 1);
      let day = "" + dateRembours.getDate();
      let year = dateRembours.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      var convertDateRembours = [year, month, day].join("-");
      // convertir date echeance si le mode de paiement => cheque
      var convertDateEcheChec = "";
      for (var i = 0; i < this.state.tabModPay.length; i++) {
        if (this.state.tabModPay[i].modPay.value == 2) {
          // convertir date echeance cheque
          var dateEcheChec = this.state.tabModPay[i].echeanceChec;
          let monthOne = "" + (dateEcheChec.getMonth() + 1);
          let dayOne = "" + dateEcheChec.getDate();
          let yearOne = dateEcheChec.getFullYear();
          if (monthOne.length < 2) monthOne = "0" + monthOne;
          if (dayOne.length < 2) dayOne = "0" + dayOne;
          convertDateEcheChec = [yearOne, monthOne, dayOne].join("-");
        }
      }
      //
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/AddRemboursement.php",
        type: "POST",
        data: {
          selectedScol: this.state.selectedScol,
          dateRembours: convertDateRembours,
          eleveId: this.state.selectedApprenant.value,
          recu: this.state.selectedNumRecu.value,
          description: this.state.description,
          mntRebours: this.state.mntRebours,
          type: this.state.selectedScol,
          modPay: this.state.tabModPay,
          anneeScolaire: anneeScolaire,
          userId: userId,
          dateEcheChec: convertDateEcheChec,
        },
        dataType: "json",
        success: (code_html, statut) => {
          if (code_html.Result == "OK") {
            this.props.history.push("/Remboursement");
          } else if (code_html.Result == "KO") {
            alert("erreur,l'un de vos champs est vide!!");
          }
        },
      });
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Ajout remboursement</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/Remboursement">
                    <button
                      style={{
                        background: "#DC143C",
                        border: "1px solid #DC143C",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i
                        style={{ paddingRight: "5px" }}
                        className="fas fa-arrow-circle-left"
                      ></i>
                      Retour
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date remboursement</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        selected={this.state.dateRembours}
                        onChange={this.addDateRembours}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <RadioGroup
                      name="typeScol"
                      selectedValue={this.state.selectedScol}
                      onChange={this.addType}
                    >
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="1" />
                        Formation
                      </label>
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="2" />
                        Cycle
                      </label>
                    </RadioGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>eleve</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addApprenant}
                        options={this.state.listApprenant}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Numéro recu</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addNumRecu}
                        options={this.state.listRecu}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Description</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        onChange={this.addDescript}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Montant à rembourser</label>
                      <input
                        type="number"
                        className="form-control"
                        value={this.state.mntRebours}
                        onChange={this.addMntRebours}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <h3 style={{ paddingLeft: "15px" }}>Mode de paiement :</h3>
                  <table
                    style={{ marginTop: "10px" }}
                    className="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }} scope="col">
                          Mode paiement
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Montant Paiement
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Numéro cheque
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Echéance cheque
                        </th>
                      </tr>
                    </thead>
                    {this.state.tabModPay.map((el, index) => (
                      <tbody>
                        <tr>
                          <td className="col-md-3">
                            <Select
                              value={el.modPay}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => this.addModPay(e, index)}
                              options={this.state.listModPay}
                            />{" "}
                          </td>
                          <td
                            className="col-md-3"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="number"
                              value={el.mntModPay}
                              onChange={(e) => this.addMntModPay(e, index)}
                              style={{
                                textAlign: "center",
                                borderRadius: "5px",
                                border: "solid 1px #B3B3B3",
                              }}
                            />
                          </td>
                          {this.state.tabModPay[index].modPay.value == 2 ? (
                            <Fragment>
                              <td
                                className="col-md-3"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="number"
                                  value={el.numCheque}
                                  onChange={(e) => this.addNumChec(e, index)}
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    border: "solid 1px #B3B3B3",
                                  }}
                                />
                              </td>
                              <td
                                className="col-md-3"
                                style={{ textAlign: "center" }}
                              >
                                <DatePicker
                                  class="form-control ddate"
                                  style={{ border: "none" }}
                                  dateFormat="dd/MM/yyy"
                                  selected={el.echeanceChec}
                                  onChange={(e) =>
                                    this.addEcheanceChec(e, index)
                                  }
                                />
                              </td>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <td
                                className="col-md-3"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="number"
                                  disabled
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    border: "solid 1px #B3B3B3",
                                  }}
                                />
                              </td>
                              <td
                                className="col-md-3"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  type="text"
                                  disabled
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    border: "solid 1px #B3B3B3",
                                  }}
                                />
                              </td>
                            </Fragment>
                          )}
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  <button
                    disabled={!this.state.disbut}
                    style={{ borderRadius: "5px" }}
                    type="button"
                    className="btn btn-success"
                    onClick={this.ajout}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
              <ToastContainer
                transition={Flip}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AjoutRemboursement);
