import React, { Component , Fragment } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

class ConsulterJury extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Entete Exam",
          selector: "entete_exam",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "Explorer",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ExplJury",
                state: { juryExamId: row.juryExamId },
              }}
            >
              <button className="buttonExplorer">
                <i style={{ color: "white" }} className="far fa-eye"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: "Modifier",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ModifJury",
                state: { juryExamId: row.juryExamId },
              }}
            >
              <button className="buttonModifier">
                <i style={{ color: "white" }} className="fas fa-pencil-alt"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "20%",
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      pending: false,
      juryExamId: "",
      listEntetExam: [],
      selectEntetExam: "",
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ConsulterJury.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectEntetExam.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listEntetExam: code_html,
          });
        },
      });
    }
  }

  addEntetExam = (selectEntetExam) => {
    this.setState({
      selectEntetExam: selectEntetExam,
    });
  };

  newElement(page, totalRows) {
    this.setState(
      {
        pending: true,
      },
      () => {
        if (this.state.selectEntetExam != "") {
          var entetExamId = this.state.selectEntetExam.value;
        } else {
          var entetExamId = "";
        }
        var userPermissions = JSON.parse(
          localStorage.getItem("userPermissions")
        );
        var anneeScolaire = userPermissions[0].anneeScolaire;
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ConsulterJurySearch.php",
          type: "POST",
          data: {
            entetExamId: entetExamId,
            anneeScolaire: anneeScolaire,
            param: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: page,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  perPage(currentRowsPerPage, currentPage) {
    this.setState(
      {
        nbrEltperPage: currentRowsPerPage,
        pending: true,
      },
      () => {
        if (this.state.selectEntetExam != "") {
          var entetExamId = this.state.selectEntetExam.value;
        } else {
          var entetExamId = "";
        }
        var userPermissions = JSON.parse(
          localStorage.getItem("userPermissions")
        );
        var anneeScolaire = userPermissions[0].anneeScolaire;
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ConsulterJurySearch.php",
          type: "POST",
          data: {
            entetExamId: entetExamId,
            anneeScolaire: anneeScolaire,
            param: this.state.filterElement,
            limit: currentRowsPerPage,
            page: currentPage,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  filterSearch(event) {
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        if (this.state.selectEntetExam != "") {
          var entetExamId = this.state.selectEntetExam.value;
        } else {
          var entetExamId = "";
        }
        var userPermissions = JSON.parse(
          localStorage.getItem("userPermissions")
        );
        var anneeScolaire = userPermissions[0].anneeScolaire;
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ConsulterJurySearch.php",
          type: "POST",
          data: {
            entetExamId: entetExamId,
            anneeScolaire: anneeScolaire,
            param: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  recherche = () => {
    if (this.state.selectEntetExam != "") {
      var entetExamId = this.state.selectEntetExam.value;
    } else {
      var entetExamId = "";
    }
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/ConsulterJurySearch.php",
      type: "POST",
      data: {
        entetExamId: entetExamId,
        anneeScolaire: anneeScolaire,
        param: this.state.filterElement,
        limit: this.state.nbrEltperPage,
        page: 1,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  afficherTout = () => {
    this.setState({
      selectEntetExam: "",
    });
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/ConsulterJury.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Jury</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/AjoutJury">
                    {" "}
                    <button
                      style={{
                        background: "#3A3F51",
                        border: "1px solid #3A3F51",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i className="fas fa-plus"></i>Ajout Jury
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>
                  <Fragment>
                    <div className="col-md-6">
                      <label> Exam </label>
                      <div className="form-group">
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addEntetExam}
                          options={this.state.listEntetExam}
                        />
                      </div>
                    </div>
                  </Fragment>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.afficherTout}
                    >
                      Afficher tout
                    </button>
                  </div>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  noHeader={true}
                  progressPending={this.state.pending}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsulterJury;
