import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import './paginate.css'

class ImprCalEFF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insEleve: "",
      nomElv: "",
      specialite: "",
      degre: "",
      arrayTheorique: [],
      arrayPratique: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var params = arrayUrl[1];
      var arrayParams = params.split("-");
      var eleveId = arrayParams[0];
      var insId = arrayParams[1];
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprCalElvEFF.php",
        type: "POST",
        data: {
          eleveId: eleveId,
          insId: insId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            insEleve: code_html[0][0].insElv,
            nomElv: code_html[0][0].nomElv,
            specialite: code_html[0][0].specialite,
            degre: code_html[0][0].degre,
            arrayTheorique: code_html[1],
            arrayPratique: code_html[2],
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
                marginTop:'120px'
              }}
            >
              Convocation à
            </p>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              l'examen fin de formation
            </p>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              marginLeft: "5%",
            }}
          >
            <p style={{ fontWeight: "bold" }}>L'apprenant :</p>
            <p style={{ marginLeft: "5px" }}>{this.state.nomElv}</p>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", marginLeft: "5%" }}>
            <p style={{ fontWeight: "bold" }}>N° d'inscription:</p>
            <p style={{ marginLeft: "5px" }}>{this.state.insEleve}</p>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", marginLeft: "5%" }}>
            <p style={{ fontWeight: "bold" }}>Spécialité :</p>
            <p style={{ marginLeft: "5px" }}>{this.state.specialite}</p>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", marginLeft: "5%" }}>
            <p style={{ fontWeight: "bold" }}>Degré :</p>
            <p style={{ marginLeft: "5px" }}>{this.state.degre}</p>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", marginLeft: "5%" }}>
           {this.state.arrayTheorique.length > 0 &&
            this.state.arrayPratique.length > 0 ? (
            
              <div>
                <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
                  Calendrier des épreuves d'examen
                </p>
                <p style={{ fontWeight: "bold" }}>
                  A/ Calendrier des epreuves de la théorie
                </p>
                {this.state.arrayTheorique.map((el) => (
                  <div>
                    <p
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      {el.date}
                    </p>
                    {el.detailDate.map((ele) => (
                      <div style={{ display: "flex" , marginBottom:'-20px' }}>
                        <p style={{ width: "30%" }}>{ele.matiere}</p>
                        <p style={{ marginLeft: "5%", marginRight: "7%" }}>
                          {ele.hd}
                          <p></p>
                        </p>
                        ----<p style={{ marginLeft: "7%" }}>{ele.hf}</p>
                      </div>
                    ))}
                  </div>
                ))}
                <p style={{ fontWeight: "bold" }}>
                  B/ Calendrier des epreuves pratiques
                </p>
                {this.state.arrayPratique.map((el) => (
                  <div>
                    <p
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      {el.date}
                    </p>
                    {el.detailDate.map((ele) => (
                      <div style={{ display: "flex" ,marginBottom:"-10px" }}>
                        <p style={{ width: "30%" }}>{ele.matiere}</p>
                        <p style={{ marginLeft: "5%", marginRight: "7%" }}>
                          {ele.hd}
                        </p>
                        ----
                        <p style={{ marginLeft: "7%" }}>{ele.hf}</p>
                        <p style={{ marginLeft: "5%" }}>{ele.salle}</p>
                      </div>
                    ))}
                  </div>
                ))}
                <p style={{ fontSize: "15px" ,marginTop:'20px' }}>
                  L'apprenant est tenu de se présenter, muni (e) de la présente
                  convocation et de la carte d'identité nationale à l'examen
                  final.
                </p>
                <div style={{ display: "flex", justifyContent: "flex-end",marginTop:'30px' }}>
                  {" "}
                  <p> Le Directeur Général </p>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <p>Taoufik CHAIRI</p>
                </div>
              </div>
            ) : (
              <div>
                <p style={{ fontWeight: "bold", color: "red" }}>
                  L'apprenant n'a pas payer la totalité des frais de la
                  formation
                </p>
              </div>
            )}
         
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprCalEFF);
