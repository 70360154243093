import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class impDeclarationEns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conseilAssistance: [],
      scolaire: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var ensId = arrayUrl[1];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var scolaire = anneeScolaire + "/" + (parseInt(anneeScolaire) + 1);
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/Imprdeclaration.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            impdeclaration: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Gestion Enseignants</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          declaration employeur
          <table
            align="center"
            border={1}
            cellspacing={1}
            cellpadding={4}
            bgcolor="#000000"
            width={1125}
          >
            <tr
              bgcolor="#FFFFFF"
              align="center"
              style={{
                color: "#000000",
                fontFamily: "Arial",
                fontSize: "13px",
              }}
            >
              <td width="6%" height={26} colspan=""></td>
              <td width="10%" height={26}></td>
              <td width="5%" height={26}></td>
              <td width="6%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Janvier</strong>
                </font>
              </td>
              <td width="6%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Fevrier</strong>
                </font>
              </td>
              <td width="6%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Mars</strong>
                </font>
              </td>
              <td width="4%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Avril</strong>
                </font>
              </td>
              <td width="4%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Mai</strong>
                </font>
              </td>
              <td width="4%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Juin</strong>
                </font>
              </td>
              <td width="4%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Juillet</strong>
                </font>
              </td>
              <td width="3%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Aout</strong>
                </font>
              </td>
              <td width="6%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Septembre</strong>
                </font>
              </td>
              <td width="5%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Octobre</strong>
                </font>
              </td>
              <td width="6%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Novembre</strong>
                </font>
              </td>
              <td width="6%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Décembre</strong>
                </font>
              </td>
              <td width="19%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Total </strong>
                </font>
              </td>
              <td width="8%">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  <strong>Total Formateur</strong>
                </font>
              </td>
            </tr>
            <tr
              style="color:#000000; font-family:Arial; font-size:13px;display:"
              bgcolor="#FFFFFF"
              align="center"
            >
              <td height="61" rowspan="4" align="left">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  {" "}
                  00320313
                </font>
              </td>
              <td height="61" rowspan="4" align="left">
                <font face="Verdana, Arial, Helvetica, sans-serif" size="1">
                  {" "}
                </font>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(impDeclarationEns);
