import React, { Component } from 'react'
import jQuery from 'jquery';



export class ImprficheEnseignant extends Component {
  

constructor (props){
 super(props);
 this.state = {
    nomComplet : "", 
    cin : "",
    nationalite: "",
    dateDelivre: "",
    villeDelivre: "",
    dateNaissance: "",
    lieuNaissance: "",
    adresseOfficielle: "",
    adresseTun: "",
    email: "",
    mobile: "",
    tel: "",
    qualification: "",
    fb: "",
    typePaiement : "" , 
    photo : "" , 
    scolaire: "",
 };
}    


componentDidMount() {
  if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    var url = window.location.href;
    var arrayUrl = url.split("=");
    var idEnseignant = arrayUrl[1];
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/FicheEns.php?idEns="+idEnseignant,
      type: "GET",
      data: {
        anneeScolaire: anneeScolaire,
        idEnseignant: idEnseignant
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({

          scolaire: code_html.scolaire,
          nomComplet : code_html.nomComplet , 
          nationalite: code_html.nationalite,
          cin: code_html.cin,
          dateDelivre : code_html.dateDelivre,
          villeDelivre : code_html.villeDelivre , 
          dateNaissance: code_html.dateNaissance,
          lieuNaissance: code_html.lieuNaissance, 
          adresseOfficielle: code_html.adresseOfficielle,
          adresseTun: code_html.adresseTun,
          email : code_html.email , 
          mobile: code_html.mobile,
          tel: code_html.tel,
          qualification: code_html.qualification,
          fb: code_html.fb,
         
          anneeScolaire: code_html.anneeScolaire,
          scolaire : code_html.scolaire, 
       
        });
      },
    });
  } else {
    document.location = "/";
  }
}

print = () => {
  window.print();
};

    render() {
    return (
      <div className="white-box">

<div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
          <font
                                  face="Verdana, Arial, Helvetica, sans-serif"
                                  size={1}
                                >
                                  <br />
                                  8, rue borj Bourguiba, le Passage 1001- Tunis
                                  <br /> <br />
                                  Tél. 71 240 204 /52 875 028 <br />
                                  <br />
                                </font>
                                <font
                       
                       size={2}
                     >
                       <strong>Année Scolaire : </strong>{" "}
                       {this.state.scolaire}
                     </font>
                                
          </div>
        </div>
<br></br>

<p style={{color : 'black' , fontSize : '20px'  , textAlign: "center",
            display: "flex",
            justifyContent: "center", fontWeight: 'bold'}}>  Fiche Enseignant</p>

            
<br></br>
<div>
<table style={{ color: 'black' }} width={890} border={0} align="center">
<tbody>
<tr>
<div style={{ display: 'flex', justifyContent: 'space-between' }}>               
                <td>
                  <img   style={{  paddingLeft : '370px'   }}  height="150px" width="200px"  src={"http://localhost:3000/images/Dossier_image/" + this.state.photo}/></td>
</div>
</tr>
<br></br>
<br></br>
<tr>
<div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia'}}> Nom et prénom  :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}> {this.state.nomComplet} </p>
                </div>
</tr>
<br></br>
<tr>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black'  , fontFamily : 'Georgia'}}> Date de naissance  :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>   {this.state.dateNaissance}       </p>
                </div>
              
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia' }}> Lieu de naissance : </p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.lieuNaissance}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia' }}> Nationalite : </p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.nationalite}</p>
                </div>
              </div>
            </tr>

<br></br>      

<tr>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black'  , fontFamily : 'Georgia'}}> Mobile :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}> {this.state.mobile}  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia' , marginLeft : '260px' }}> Telephone : </p>
                  <p style={{ marginLeft: '5px', color: 'black' }}>{this.state.tel}</p>
                </div>
              </div>
            </tr> 
<br></br>
<tr>
              
<div style={{ display: 'flex' }}>
                <p style={{ fontWeight: 'bold', color: 'black' ,  fontFamily : 'Georgia'}}> Adresse officielle : </p>
                <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.adresseOfficielle}</p> <br></br>
</div>
</tr>
<br></br>
<tr>
<p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia' }}>Adresse en tunisie : </p>
<p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.adresseTun}</p>
</tr>
<br>
</br>
            <tr>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black'  , fontFamily : 'Georgia'}}>Numéro CIN  :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>   {this.state.cin}       </p>
                </div>
              
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia' }}>Delivré le : </p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.dateDelivre}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia' }}>Lieu : </p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.villeDelivre}</p>
                </div>
              </div>
            </tr>

<br></br>

<tr>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black'  , fontFamily : 'Georgia'}}>Adresse email :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}> {this.state.email}  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia' , marginLeft : '180px' }}>Facebook : </p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.fb}</p>
                </div>
              </div>
            </tr>

<br></br>
 <tr>
 <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia'}}>Qualification : </p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.qualification}</p>
                </div>
 </tr>


</tbody>

</table>

</div>
<div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>





      </div>
    )
  }
}

export default ImprficheEnseignant