import React, { Component } from "react";
import jQuery, { data } from "jquery";
import "./paginate.css";
import { NumberToLetter } from 'convertir-nombre-lettre';


class Imprecu extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        data: [],
        nomComplet:"",
    
    };
  }

  impr = () => {
    window.print();
  };  

  componentDidMount() {
    if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
      var url = window.location.href;
      var array = url.split("=");
      var params = array[1];
      var arrayInfo = params.split("/");
      var idRemboursement = arrayInfo[0];
      var anneeScolaire = arrayInfo[1];
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        
        
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/imprecu.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire,
                idRemboursement: idRemboursement
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                      data: code_html.data,
                       nomComplet:code_html.data[0].nomComplet,
                       idRemboursement:code_html.data[0].idRemboursement,
                        date: (code_html.data[0].date),
                        type:code_html.data[0].type,
                        description:code_html.data[0].description,
                        mntRebours: NumberToLetter(code_html.data[0].mnt),
                        mntReboursn: code_html.data[0].mnt,
                        numRecu:code_html.data[0].numRecu,
                        mode:code_html.data[0].mode,
                        

                        
                    pending: false
                    
                });
            }
        })
        
    }else {
            localStorage.clear();
            document.location = '/'
        }
   
}

  print = () => {
    window.print();
  };    

  render() {
   
    return (
        <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-around" ,
          }}
          className="row"
        >
          
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group" style={{width:"135px", textAlign: "left"}}>
          <p style={{ fontWeight: "bold" }}>

          8,rue borj
          Bourguiba,le Passage
          1001-Tunis
          Tél. 71 240 204 /52
          875 028</p>

          </div>
        </div>
        <br></br>
        <strong><p style={{display:'flex',justifyContent:"center",fontSize:"20px",fontWeight: 'bold', color: 'black',padding:"30px" }}>Recu N°{this.state.numRecu}</p></strong>
        
        <div>
        <table style={{ color: 'black' }} width={890} border={0} align="center">
        <tbody>
        <tr>
        <div style={{ display: 'flex' }}>
                          <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia',fontSize:"18px"}}> Reçu de Mme, Mlle, Mr :</p>
                          <p style={{ paddingLeft: '5px', color: 'black',fontSize:"18px" }}> {this.state.nomComplet} </p>
                        </div>
        </tr>
        <br></br>
       
        
        <tr>
                     
                        <div style={{ display: 'flex' }}>
                          <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia',fontSize:"18px" ,padding:"5px"}}> Montant remboursé  en </p><p style={{fontSize:"12px",paddingTop:"8px"}}>(Lettre):</p> 
                          <p style={{ paddingLeft: '5px', color: 'black',fontSize:"18px" }}>{this.state.mntRebours } Dinars.</p>
                        </div>
                    </tr>
        <br></br>  
        <tr>
                     
                        <div style={{ display: 'flex' }}>
                          <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia',fontSize:"18px",padding:"5px" }}> Montant remboursé  en </p> <p style={{fontSize:"12px",paddingTop:"8px"}}>(Chiffre):</p> 
                          <p style={{ paddingLeft: '5px', color: 'black',fontSize:"18px",paddingTop:"3px" }}>{this.state.mntReboursn}.</p>
                        </div>
                    </tr>
        <br></br>     
        
        <tr>
          
                      
        <div style={{ display: 'flex' }}>
                        <p style={{ fontWeight: 'bold', color: 'black' ,  fontFamily : 'Georgia',fontSize:"18px"}}> Description : </p>
                        <p style={{ paddingLeft: '5px', color: 'black',fontSize:"18px" }}>{this.state.description}</p> <br></br>
        </div>
        </tr>
        
        <br></br>
        <tr>
                        <div style={{ display: 'flex' }}>
                          <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia' ,fontSize:"18px" }}> Mode  : </p>
                          <p style={{ marginLeft: '5px', color: 'black' ,fontSize:"18px"}}>{this.state.mode}</p>
                        </div>
                     
                    </tr> 
        <br></br>
                   
         <tr>
         <div style={{ display: 'flex' }}>
                          <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia',fontSize:"18px"}}>type : </p>
                          <p style={{ paddingLeft: '5px', color: 'black',fontSize:"18px" }}>{this.state.type}</p>
        </div>
         </tr>
         <br></br>
         <br></br>
        <tr>
                        <div style={{ display: 'flex' }}>
                          <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia'  ,fontSize:"18px"}}> Payé en  : </p>
                          <p style={{ marginLeft: '5px', color: 'black' ,fontSize:"18px"}}>{this.state.mode}</p>
                          <p style={{ fontWeight: 'bold', color: 'black' , fontFamily : 'Georgia' ,paddingLeft:"520px",fontSize:"18px" }}> Le  : </p>
                          <p style={{ marginLeft: '40px', color: 'black' ,fontSize:"18px" }}>{this.state.date}</p>
                        </div>
                     
                    </tr> 
        <br></br>
        
        </tbody>
        
        </table>
        
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default Imprecu;



