import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'

class ModifTypeEnseign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idTypeEnseign: '',
            nom: '',
            coeiff: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            let idTypeEnseign = this.props.location.state.idTypeEnseign;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetTypeEnseignById.php?id=' + idTypeEnseign).then(res => {
                console.log(res)
                this.setState({
                    idTypeEnseign: res.data.id,
                    nom: res.data.nom,
                    coeiff: res.data.coeiff
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    updNom = (event) => {
        this.setState({
            nom: event.target.value
        });
    }

    updCoeiff = (event) => {
        this.setState({
            coeiff: event.target.value
        });
    }

    modif = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdTypeEnseign.php?id=' + this.state.idTypeEnseign,
            type: 'POST',
            data: {
                nom: this.state.nom,
                coeiff: this.state.coeiff,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/TypeEnseign');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification type enseignement</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/TypeEnseign" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom</label>
                                            <input type="text" className="form-control" value={this.state.nom} onChange={this.updNom} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Coefficient</label>
                                            <input type="number" className="form-control" value={this.state.coeiff} onChange={this.updCoeiff} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifTypeEnseign);