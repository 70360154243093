import React, { Component, Fragment } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios'
import jQuery from 'jquery'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AutreFrais extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Apprenant',
                    selector: 'nomComplet',
                    sortable: false,
                    center: true,
                    style: { width: '20%' }
                },
                {
                    name: 'Frais réglé',
                    cell: row => <div onClick={() => this.toggleFrais(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#FFB6C1', fontSize: '20px' }} className="fas fa-info"></i></div><div style={{ marginLeft: '5px' }}></div>Description</div>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '15%'
                },
                {
                    name: 'Montant',
                    selector: 'montant',
                    sortable: false,
                    center: true,
                    style: { width: '15%' }
                },
                {
                    name: 'Date reglement',
                    selector: 'date',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Type paiement',
                    selector: 'mode',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Modification',
                    cell: row => <Link to={{
                        pathname: '/ModifAutreFrais',
                        state: { idAutreFrais: row.idAutreFrais }
                    }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Recu',
                    cell: row => <div onClick={() => this.recu(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F', fontSize: '20px' }} className="fas fa-file-alt"></i></div><div style={{ marginLeft: '5px' }}></div></div>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Suppression',
                    cell: row => <button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggletwo(row.idAutreFrais)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                }
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            modal: false,
            pending: true,
            frais: '',
            typeFrais: '',
            modalFrais: false,
            // search
            listEleve: [],
            selectedEleve: '',
            autreFrais: [],
            selectedAutreFrais: '',
            dateD: '',
            dateF: '',
            idAutreFrais: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AutreFrais.php',
                type: 'POST',
                data: {
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectElevesAutreFrais.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listEleve: code_html
                    })
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectAutreFraisUtiliser.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        autreFrais: code_html
                    })
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    newElement = (page, totalRows) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedEleve != '') {
            var eleveId = this.state.selectedEleve.label
        }
        else {
            var eleveId = ''
        }
        if (this.state.selectedAutreFrais != '') {
            var typeFraisId = this.state.selectedAutreFrais.value
        }
        else {
            var typeFraisId = ''
        }
        if ((eleveId == '') && (typeFraisId == '') && (this.state.dateD == '') && (this.state.dateF == '')) {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AutreFrais.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
        else {
            if (this.state.dateD != '') {
                // convert date debut 
                let dateD = this.state.dateD
                let month = '' + (dateD.getMonth() + 1);
                let day = '' + dateD.getDate();
                let year = dateD.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var convertDateD = [year, month, day].join('-');
            }
            else {
                var convertDateD = ''
            }
            if (this.state.dateF != '') {
                // convert date fin
                let dateF = this.state.dateF
                let monthOne = '' + (dateF.getMonth() + 1);
                let dayOne = '' + dateF.getDate();
                let yearOne = dateF.getFullYear();
                if (monthOne.length < 2)
                    monthOne = '0' + monthOne;
                if (dayOne.length < 2)
                    dayOne = '0' + dayOne;
                var convertDateF = [yearOne, monthOne, dayOne].join('-');
            }
            else {
                var convertDateF = ''
            }
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AutreFraisSearch.php',
                        type: 'POST',
                        data: {
                            eleveId: eleveId,
                            typeFraisId: typeFraisId,
                            dateD: convertDateD,
                            dateF: convertDateF,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    perPage = (currentRowsPerPage, currentPage) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedEleve != '') {
            var eleveId = this.state.selectedEleve.label
        }
        else {
            var eleveId = ''
        }
        if (this.state.selectedAutreFrais != '') {
            var typeFraisId = this.state.selectedAutreFrais.value
        }
        else {
            var typeFraisId = ''
        }
        if ((eleveId == '') && (this.state.dateD == '') && (this.state.dateF == '')) {
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AutreFrais.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            );
        }
        else {
            if (this.state.dateD != '') {
                // convert date debut 
                let dateD = this.state.dateD
                let month = '' + (dateD.getMonth() + 1);
                let day = '' + dateD.getDate();
                let year = dateD.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var convertDateD = [year, month, day].join('-');
            }
            else {
                var convertDateD = ''
            }
            if (this.state.dateF != '') {
                // convert date fin
                let dateF = this.state.dateF
                let monthOne = '' + (dateF.getMonth() + 1);
                let dayOne = '' + dateF.getDate();
                let yearOne = dateF.getFullYear();
                if (monthOne.length < 2)
                    monthOne = '0' + monthOne;
                if (dayOne.length < 2)
                    dayOne = '0' + dayOne;
                var convertDateF = [yearOne, monthOne, dayOne].join('-');
            }
            else {
                var convertDateF = ''
            }
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AutreFraisSearch.php',
                        type: 'POST',
                        data: {
                            eleveId: eleveId,
                            typeFraisId: typeFraisId,
                            dateD: convertDateD,
                            dateF: convertDateF,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    filterSearch = (event) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AutreFrais.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }
    impr = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/imAutreFrais.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: function (code_html, statut) {
                
        window.open("http://artsmetiers.mtd-app.com/ImpFrais")
            }
        });
    }
    searchEleve = selectedEleve => {
        if (selectedEleve != null) {
            this.setState({ selectedEleve });
        }
        else {
            var el = { value: '', label: '' }
            this.setState({ selectedEleve: el });
        }
    }

    searchAutreFrais = selectedAutreFrais => {
        this.setState({ selectedAutreFrais });
    }

    searchDateD = d => {
        this.setState({
            dateD: d
        });
    }

    searchDateF = d => {
        this.setState({
            dateF: d
        });
    }

    recherche = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedEleve != '') {
            var eleveId = this.state.selectedEleve.label
        }
        else {
            var eleveId = ''
        }
        if (this.state.selectedAutreFrais != '') {
            var typeFraisId = this.state.selectedAutreFrais.value
        }
        else {
            var typeFraisId = ''
        }
        // convert date debut 
        if (this.state.dateD != '') {
            let dateD = this.state.dateD
            let month = '' + (dateD.getMonth() + 1);
            let day = '' + dateD.getDate();
            let year = dateD.getFullYear();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            var convertDateD = [year, month, day].join('-');
        }
        else {
            var convertDateD = ''
        }
        // convert date fin
        if (this.state.dateF != '') {
            let dateF = this.state.dateF
            let monthOne = '' + (dateF.getMonth() + 1);
            let dayOne = '' + dateF.getDate();
            let yearOne = dateF.getFullYear();
            if (monthOne.length < 2)
                monthOne = '0' + monthOne;
            if (dayOne.length < 2)
                dayOne = '0' + dayOne;
            var convertDateF = [yearOne, monthOne, dayOne].join('-');
        }
        else {
            var convertDateF = ''
        }
        this.setState({
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AutreFraisSearch.php',
                    type: 'POST',
                    data: {
                        eleveId: eleveId,
                        typeFraisId: typeFraisId,
                        dateD: convertDateD,
                        dateF: convertDateF,
                        anneeScolaire: anneeScolaire,
                        limit: this.state.nbrEltperPage,
                        page: 1
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    afficherTout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AutreFrais.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false
                });
            }
        });
    }

    toggleFrais = (row) => {
        var frais = row.frais
        var typeFrais = row.typeFrais
        this.setState(prevState => ({
            modalFrais: !prevState.modalFrais,
            frais: frais,
            typeFrais: typeFrais
        }));
    }

    toggleFermerFrais = () => {
        this.setState(prevState => ({
            modalFrais: !prevState.modalFrais,
        }));
    }

    recu = (row) => {
        var idAutreFrais = row.idAutreFrais
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuAF.php',
            type: 'POST',
            data: {
                idAutreFrais: idAutreFrais
            },
            dataType: 'json',
            success: function (code_html, statut) {
                var server_path = code_html.server_path;
                var file_path = code_html.file_path
                var path = server_path + file_path;
                window.open(path, "_blank")
            }
        });
    }

    toggletwo = (idAutreFrais) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            idAutreFrais: idAutreFrais
        }));
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    delete = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        //
        var idAutreFrais = this.state.idAutreFrais
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/DeleteAutreFrais.php',
            type: 'POST',
            data: {
                idAutreFrais: idAutreFrais,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                this.setState(prevState => ({
                    modal: !prevState.modal,
                }));
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AutreFrais.php',
                    type: 'POST',
                    data: {
                        query: '',
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Autre frais</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/AjoutAutreFrais" > <button style={{ background: '#3A3F51', border: '1px solid #3A3F51', borderRadius: '5px' }} type="button" className="btn btn-info"> <i className="fas fa-plus"></i>Autre frais</button></Link>
                                </div>
                                <div className="row">
                                    <h4 style={{ paddingLeft: '10px' }}>Critères de recherche</h4>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Apprenant</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.searchEleve(e)}
                                                options={this.state.listEleve}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Frais existant</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.searchAutreFrais}
                                                options={this.state.autreFrais}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date debut</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.dateD}
                                                onChange={this.searchDateD}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date fin</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.dateF}
                                                onChange={this.searchDateF}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.recherche}>Rechercher</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.afficherTout}>Afficher tout</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.impr}>imprimer tout</button>
                                    </div>
                                </div>
                                <div className="mb-0 form-group">
                                    <div className="input-group-alternative input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input onChange={(currentRowsPerPage, currentPage) => this.filterSearch(currentRowsPerPage, currentPage)} placeholder="Search" type="text" className="search" />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
                            </div>
                            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} fade={false}>
                                <ModalHeader toggle={this.toggle}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                <ModalBody>
                                    <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <p>Êtes-Vous sûr de vouloir supprimer ce frais ?</p>
                                        </div>
                                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                            <div><button onClick={() => this.delete()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                            <div><button onClick={() => this.toggle()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                            <Modal isOpen={this.state.modalFrais} toggle={this.toggleFermerFrais} className={this.props.className} fade={false}>
                                <ModalHeader toggle={this.toggleFermerFrais}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#FFB6C1' }} className="fas fa-info"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Description du frais</p></div></div></ModalHeader>
                                <ModalBody>
                                    <div>
                                        <table className="table table-bordered">
                                            <thead >
                                                <tr>
                                                    <th style={{ textAlign: 'center' }} scope="col">Frais</th>
                                                    <th style={{ textAlign: 'center' }} scope="col">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}><p>{this.state.typeFrais}</p></td>
                                                    <td style={{ textAlign: 'center' }}><p>{this.state.frais}</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                            <div><button onClick={() => this.toggleFermerFrais()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AutreFrais;