import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";

class ImprReleveNoteBTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomEleve: "",
      naissance: "",
      filiere: "",
      niveau: "",
      diplome: "",
      groupe: "",
      annee: "",
      anneeScolaire: "",
      eleveNoteEP: [],
      eleveNoteET: [],
      eleveNoteEG: [],
      stage: "",
      pfe: "",
      moyenneGen: "",
      nivEleve: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var eleveId = arrayUrl[1];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprReleveNoteBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          eleveId: eleveId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            eleveNoteEP: code_html[0],
            eleveNoteET: code_html[1],
            eleveNoteEG: code_html[2],
            stage: code_html.stage,
            pfe: code_html.pfe,
            moyenneGen: code_html.moyenneGen,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/NiveauEleve.php",
        type: "POST",
        data: {
          eleveId: eleveId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            nivEleve: code_html,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoEleveBTS.php",
        type: "POST",
        data: {
          eleveId: eleveId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            nomEleve: code_html.nomEleve,
            naissance: code_html.naissance,
            filiere: code_html.nomFiliere,
            niveau: code_html.niveau,
            annee: code_html.annee,
            anneeScolaire: code_html.anneeScolaire,
            diplome: code_html.nomSpec,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
          </div>
        </div>
        <div style={{ marginTop: "3%" }}>
          <table
            border={0}
            align="center"
            cellSpacing={1}
            cellPadding={4}
            width={1100}
          >
            <tbody>
              <tr>
                <td width="100%" align height={54}>
                  <b>
                    <font
                      style={{ fontSize: "14px", textAlign: "center" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      <h3> Relevé de note &nbsp;</h3>
                    </font>
                  </b>
                </td>
              </tr>
              <tr>
                <td width="100%" align={"center"}>
                  <b>
                    <font
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      <h4 style={{ fontWeight: "bold" }}>
                        {" "}
                        Annee Scolaire : {this.state.anneeScolaire} &nbsp;
                      </h4>
                    </font>
                  </b>
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Nom et Prénom:</u>
                    </b>
                    &nbsp; {this.state.nomEleve}
                  </font>{" "}
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Date & lieu de naissance:</u>
                    </b>
                    &nbsp; {this.state.naissance}
                  </font>{" "}
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Niveau:</u>
                    </b>
                    &nbsp; {this.state.niveau}
                  </font>{" "}
                </td>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Diplome:</u>
                    </b>
                    &nbsp;{this.state.diplome}
                  </font>{" "}
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Spécialité:</u>
                    </b>
                    &nbsp;{this.state.filiere}
                  </font>{" "}
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Promotion:</u>
                    </b>
                    &nbsp;{this.state.annee}
                  </font>{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {this.state.nivEleve == 2 ? (
          <div>
            {" "}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Table style={{ width: "50%" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      N°
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Intitulé de la Compétence
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      dure
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      En cours de maaitrisé
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Maitrisée (Succee / Echec )
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Présence
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Observation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colspan="7"
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Enseignement Pratique(5)
                    </td>
                  </tr>
                  {this.state.eleveNoteEP.map((el, indexOfLigne) => (
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {indexOfLigne + 1}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.matiere}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.dure}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      ></td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.matiereNote}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {el.absence}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      ></td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colspan="7"
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Enseignement Technique(2)
                    </td>
                  </tr>
                  {this.state.eleveNoteET.map((el, indexOfLigne) => (
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {indexOfLigne + 1}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.matiere}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.dure}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      ></td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.matiereNote}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {el.absence}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      ></td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colspan="7"
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Enseignement Géneral(1)
                    </td>
                  </tr>
                  {this.state.eleveNoteEG.map((el, indexOfLigne) => (
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {indexOfLigne + 1}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.matiere}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.dure}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      ></td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.matiereNote}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {el.absence}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      ></td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colspan="2"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Total Stage
                    </td>
                    <td
                      colspan="1"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      150j
                    </td>
                    <td
                      colspan="4"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {this.state.stage}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Note PFE
                    </td>
                    <td
                      colspan="1"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    ></td>
                    <td
                      colspan="4"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {this.state.pfe}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Moyenne Général
                    </td>
                    <td
                      colspan="1"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    ></td>
                    <td
                      colspan="4"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {this.state.moyenneGen}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Resultat Final
                    </td>
                    <td
                      colspan="4"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Le directeur du centre
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>{" "}
          </div>
        ) : (
          <div>
            {" "}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Table style={{ width: "50%" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      N°
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Intitulé de la Compétence
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      dure
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      En cours de maaitrisé
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Maitrisée (Succee / Echec )
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Présence
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Observation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colspan="7"
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Enseignement Pratique(5)
                    </td>
                  </tr>
                  {this.state.eleveNoteEP.map((el, indexOfLigne) => (
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {indexOfLigne + 1}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.matiere}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.dure}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {el.matiereNote}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {el.absence}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      ></td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colspan="7"
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Enseignement Technique(2)
                    </td>
                  </tr>
                  {this.state.eleveNoteET.map((el, indexOfLigne) => (
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {indexOfLigne + 1}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.matiere}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.dure}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {el.matiereNote}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {el.absence}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      ></td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colspan="7"
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Enseignement Géneral(1)
                    </td>
                  </tr>
                  {this.state.eleveNoteEG.map((el, indexOfLigne) => (
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {indexOfLigne + 1}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.matiere}{" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                        {el.dure}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {el.matiereNote}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {" "}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {el.absence}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      ></td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colspan="2"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Total Stage
                    </td>
                    <td
                      colspan="1"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      150j
                    </td>
                    <td
                      colspan="4"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {this.state.stage}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Moyenne Général
                    </td>
                    <td
                      colspan="1"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    ></td>
                    <td
                      colspan="4"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {this.state.moyenneGen}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Resultat Final
                    </td>
                    <td
                      colspan="4"
                      style={{
                        border: "solid",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Le directeur du centre
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>{" "}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <i id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprReleveNoteBTS);
