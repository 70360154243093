import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class ConsulterNoteExamBts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNiveau: [],
      selectedNiveau: "",
      listGroupes: [],
      selectedGroupe: "",
      listMatieres: [],
      selectedMatieres: "",
      listJury: [],
      blockEleve: false,
      moyenne: "",
      date: new Date(),
      listNotes: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectNiveauBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listNiveau: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addNiveau = (selectedNiveau) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedNiveau != null) {
      this.setState({ selectedNiveau }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectGroupeByJury.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listGroupes: code_html,
            });
          },
        });
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectMatieresPratiquesByJury.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listMatieres: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        selectedNiveau: "",
        selectedGroupe: "",
      });
    }
  };

  addGroupe = (selectedGroupe) => {
    this.setState({ selectedGroupe: selectedGroupe });
  };

  addMatiere = (selectedMatieres) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedMatieres != null) {
      this.setState({ selectedMatieres: selectedMatieres }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ConsulterNoteExamBts.php",
          type: "POST",
          data: {
            niveauId: this.state.selectedNiveau.value,
            groupeId: this.state.selectedGroupe.value,
            matiereId: selectedMatieres.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listJury: code_html[0],
              listEleves: code_html[1],
            });
          },
        });
      });
    } else {
      this.setState({
        selectedMatieres: "",
      });
    }
  };

  affich = () => {
    this.setState({
      blockEleve: true,
    });
  };

  changeNote = (indexOfLigne, indexOfColumn, indice, event) => {
    if (parseFloat(event) <= 20) {
      var arrayEleve = this.state.listEleves;
      arrayEleve[indexOfLigne].noteEleve[indexOfColumn].note[
        indice
      ].noteMatiere = event;
      this.setState({ arrayEleve: arrayEleve });
    } else if (event == "") {
      var arrayEleve = this.state.listEleves;
      arrayEleve[indexOfLigne].noteEleve[indexOfColumn].note[
        indice
      ].noteMatiere = "";
      this.setState({ arrayEleve: arrayEleve });
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      });
    }
    var arr = [];
    var arrayX = [];
    //
    for (
      let i = 0;
      i < arrayEleve[indexOfLigne].noteEleve[0].note.length;
      i++
    ) {
      var x = arrayEleve[indexOfLigne].noteEleve[0].note[i].noteMatiere;
      if (x != "") {
        var xx = x;
      } else {
        var xx = 0;
      }
      arrayX.push(xx);
    }
    var arrayNoteX = arrayX.map((i) => Number(i));
    var nbr = arrayNoteX.length;
    var MoyenneX = arrayNoteX.reduce((a, b) => a + b, 0) / nbr;
    //
    var arrayP = [];
    for (
      let i = 0;
      i < arrayEleve[indexOfLigne].noteEleve[1].note.length;
      i++
    ) {
      var p = arrayEleve[indexOfLigne].noteEleve[1].note[i].noteMatiere;
      if (p != "") {
        var pp = p;
      } else {
        var pp = 0;
      }
      arrayP.push(pp);
    }
    var arrayNoteP = arrayP.map((i) => Number(i));
    var nbr = arrayNoteP.length;
    var MoyenneP = arrayNoteP.reduce((a, b) => a + b, 0) / nbr;
    //
    var arrayZ = [];
    for (
      let i = 0;
      i < arrayEleve[indexOfLigne].noteEleve[2].note.length;
      i++
    ) {
      var z = arrayEleve[indexOfLigne].noteEleve[2].note[i].noteMatiere;
      if (z != "") {
        var zz = z;
      } else {
        var zz = 0;
      }
      arrayZ.push(zz);
    }
    var arrayNoteZ = arrayZ.map((i) => Number(i));
    var nbr = arrayNoteZ.length;
    var MoyenneZ = arrayNoteZ.reduce((a, b) => a + b, 0) / nbr;
    //
    var arrayH = [];
    for (
      let i = 0;
      i < arrayEleve[indexOfLigne].noteEleve[3].note.length;
      i++
    ) {
      var h = arrayEleve[indexOfLigne].noteEleve[3].note[i].noteMatiere;
      if (h != "") {
        var hh = h;
      } else {
        var hh = 0;
      }
      arrayH.push(hh);
    }
    var arrayNoteH = arrayH.map((i) => Number(i));
    var nbr = arrayNoteH.length;
    var MoyenneH = arrayNoteH.reduce((a, b) => a + b, 0) / nbr;
    //
    var arr = [];
    for (
      let i = 0;
      i < arrayEleve[indexOfLigne].noteEleve[4].note.length;
      i++
    ) {
      var a = arrayEleve[indexOfLigne].noteEleve[4].note[i].noteMatiere;
      if (a != "") {
        var c = a;
      } else {
        var c = "";
      }
      if (c != "") {
        arr.push(c);
      }
    }
    for (
      let j = 0;
      j < arrayEleve[indexOfLigne].noteEleve[5].note.length;
      j++
    ) {
      var b = arrayEleve[indexOfLigne].noteEleve[5].note[j].noteMatiere;
      if (b != "") {
        var y = b;
      } else {
        var y = "";
      }
      if (y != "") {
        arr.push(y);
      }
    }
    for (
      let f = 0;
      f < arrayEleve[indexOfLigne].noteEleve[6].note.length;
      f++
    ) {
      var n = arrayEleve[indexOfLigne].noteEleve[6].note[f].noteMatiere;
      if (n != "") {
        var o = n;
      } else {
        var o = "";
      }
      if (o != "") {
        arr.push(o);
      }
    }
    var arrayNoteSousMat = arr.map((i) => Number(i));
    var nbr = arr.length;
    var MoyenneSousMatiere = arrayNoteSousMat.reduce((a, b) => a + b, 0) / nbr;
    if (isNaN(MoyenneSousMatiere)) {
      MoyenneSousMatiere = 0;
    } else {
      MoyenneSousMatiere = MoyenneSousMatiere;
    }
    // 
    var arrayG = [];
    for (
      let i = 0;
      i < arrayEleve[indexOfLigne].noteEleve[7].note.length;
      i++
    ) {
      var g = arrayEleve[indexOfLigne].noteEleve[7].note[i].noteMatiere;
      if (g != "") {
        var gg = g;
      } else {
        var gg = 0;
      }
      arrayG.push(gg);
    }
    var arrayNoteG = arrayG.map((i) => Number(i));
    var nbr = arrayNoteG.length;
    var MoyenneG = arrayNoteG.reduce((a, b) => a + b, 0) / nbr;
    var moyenne =
      (MoyenneX +
        MoyenneP +
        MoyenneZ +
        MoyenneH * 2 +
        MoyenneSousMatiere * 3 +
        MoyenneG) /
      9;
    var array = this.state.listEleves;
    array[indexOfLigne].moyenne = moyenne;
    this.setState({
      listEleves: array,
    });
  };

  ajoutMoyenneExam = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.date != "") {
      var date = this.state.date;
      var convertDate = date.toISOString().split("T")[0];
    } else {
      var convertDate = "";
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/AjoutMoyenneExamBts.php",
      type: "POST",
      data: {
        niveauId: this.state.selectedNiveau.value,
        groupeId: this.state.selectedGroupe.value,
        matiereId: this.state.selectedMatieres.value,
        anneeScolaire: anneeScolaire,
        dateAjoutNote: convertDate,
        listEleves: this.state.listEleves,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          // this.props.history.push("/Menu");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/AddNoteExamenBts.php",
      type: "POST",
      data: {
        niveauId: this.state.selectedNiveau.value,
        groupeId: this.state.selectedGroupe.value,
        matiereId: this.state.selectedMatieres.value,
        anneeScolaire: anneeScolaire,
        dateAjoutNote: convertDate,
        listEleves: this.state.listEleves,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Menu");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Examen pratique </h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Niveau</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addNiveau}
                        options={this.state.listNiveau}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroupe}
                        options={this.state.listGroupes}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Matiere :</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addMatiere}
                        options={this.state.listMatieres}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div>
                    <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.affich}>Afficher</button>
                  </div>
                </div>
                {this.state.blockEleve == true ? (
                  <div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ textAlign: "center" }}>Eleve</th>
                          {this.state.listJury.map((elem) => (
                            <th style={{ textAlign: "center" }}>
                              {elem.entete_exam}
                              <p> Coeff : {elem.coeff}</p>
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                {elem.allJury.map((obj) => (
                                  <div>
                                    <p>{obj.jury}</p>
                                  </div>
                                ))}
                              </p>
                            </th>
                          ))}
                          <th style={{ textAlign: "center" }}>Moyenne</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listEleves.map((el, indexOfLigne) => (
                          <tr>
                            <td>{indexOfLigne + 1}</td>
                            <td style={{ textAlign: "center" }}>
                              {el.nomComplet}
                            </td>
                            {el.noteEleve.map((element, indexOfColumn) => (
                              <td style={{ textAlign: "center" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  {element.note.map((ele, indice) => (
                                    <input
                                      type="text"
                                      value={ele.noteMatiere}
                                      onChange={(e) =>
                                        this.changeNote(
                                          indexOfLigne,
                                          indexOfColumn,
                                          indice,
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    ></input>
                                  ))}
                                </div>
                              </td>
                            ))}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1.5vh",
                              }}
                            >
                              <input
                                type="text"
                                value={el.moyenne}
                                style={{
                                  textAlign: "center",
                                  width: "50px",
                                }}
                              />
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                      <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajoutMoyenneExam}>Confirmer</button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
      </div>
    );
  }
}

export default withRouter(ConsulterNoteExamBts);
