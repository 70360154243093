import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { RadioGroup, Radio } from 'react-radio-group'
import './paginate.css'

class AjoutVisiteur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prenom: '',
            nom: '',
            dateNaissance: new Date(),
            selectedGender: 'Masculin',
            mobile: '',
            listNivBase: [],
            selectedNivBase: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectNivBase.php').then(res => {
                console.log(res)
                this.setState({
                    listNivBase: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    addPrenom = (event) => {
        this.setState({
            prenom: event.target.value
        });
    }

    addNom = (event) => {
        this.setState({
            nom: event.target.value
        });
    }

    addDateNaissance = d => {
        this.setState({
            dateNaissance: d
        });
    };

    addGender = (selectedGender) => {
        this.setState({ selectedGender: selectedGender });
    }

    addMobile = (event) => {
        this.setState({
            mobile: event.target.value
        });
    }

    addNivBase = selectedNivBase => {
        this.setState({ selectedNivBase });
        console.log(`nivBase selected:`, selectedNivBase);
    };

    addImageVerso(e) {
        this.setState({ imageVerso: e.target.files })
    }

    ajout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        // Convert date naissance
        let dateNaissance = this.state.dateNaissance
        let month_two = '' + (dateNaissance.getMonth() + 1);
        let day_two = '' + dateNaissance.getDate();
        let year_two = dateNaissance.getFullYear();
        if (month_two.length < 2)
            month_two = '0' + month_two;
        if (day_two.length < 2)
            day_two = '0' + day_two;
        let convertDateNaissance = [year_two, month_two, day_two].join('-');
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddVisiteur.php',
            type: 'POST',
            data: {
                prenom: this.state.prenom,
                nom: this.state.nom,
                dateNaissance: convertDateNaissance,
                sexe: this.state.selectedGender,
                mobile: this.state.mobile,
                nivBaseId: this.state.selectedNivBase.value,
                userId: userId,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Visiteurs');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout visiteur</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Visiteurs" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Prénom</label>
                                            <input type="text" className="form-control" placeholder="Prénom" onChange={this.addPrenom} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom</label>
                                            <input type="text" className="form-control" placeholder="Nom" onChange={this.addNom} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Sexe</label>
                                        <RadioGroup
                                            name="sexe"
                                            selectedValue={this.state.selectedGender}
                                            onChange={this.addGender}>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="Masculin" />Masculin
                                                </label>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="Feminin" />Feminin
                                                </label>
                                        </RadioGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Date naissance</label>
                                        <div className="form-group">
                                            <DatePicker class="form-control"
                                                selected={this.state.dateNaissance}
                                                onChange={this.addDateNaissance}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mobile</label>
                                            <input type="text" className="form-control" placeholder="Mobile" onChange={this.addMobile} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau de base</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addNivBase}
                                                options={this.state.listNivBase}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AjoutVisiteur);