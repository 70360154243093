import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModifNiveau extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idNiveau: '',
            formations: [],
            selectedFormation: '',
            idligne:[],
            nomNiveau: '',
            periodeNiv: '',
            mntInitial: 0,
            niveaux: [],
            indexLignFormationASupprimer: '',
            modal: false,
            typesPayEleve: [],
            selectedTypePayEleve: '',
            checked: false,
            tabChoix: [],
            idFraisNivASupprimer: '',
            tabIdFraisNivDelete: []
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            let idNiveau = this.props.location.state.idNiveau;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetNiveauByIdModif.php?id=' + idNiveau).then(res => {
                this.setState({
                    idNiveau: res.data[0].idNiveau,
                    selectedFormation: res.data[0].formation,
                    nomNiveau: res.data[0].nomNiveau,
                    periodeNiv: res.data[0].periodeNiv,
                    niveaux: res.data[1],
                    idligne:res.data[2]
                }, () => {
                    if (res.data[1].length > 0) {
                        this.setState({
                            mntInitial: res.data[1][0].mntInitiale
                        });
                    }
                    else {
                        this.setState({
                            mntInitial: 0
                        });
                    }console.log(this.state.idligne);
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetChoixModif.php?id=' + idNiveau).then(res => {
                this.setState({
                    tabChoix: res.data
                }, () => {
                    console.log(this.state.tabChoix)
                })
            })
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFormations.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        formations: code_html
                    });
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectTypesPayEleve.php').then(res => {
                console.log(res)
                this.setState({
                    typesPayEleve: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    modifFormation = selectedFormation => {
        this.setState({ selectedFormation });
        console.log(`Formation selected:`, selectedFormation);
    };

    modifNomNiveau = (event) => {
        this.setState({
            nomNiveau: event.target.value
        });
    }

    modifPeriodeNiveau = (event) => {
        this.setState({
            periodeNiv: event.target.value
        });
    }

    modifMntInitial = (event) => {
        this.setState({
            mntInitial: event.target.value
        }, () => {
            var stateCopy = this.state.tabChoix;

            for (var i = 0; i < this.state.tabChoix.length; i++) {
                var sousTabChoix = this.state.tabChoix[i]
                for (var j = 0; j < sousTabChoix.length; j++) {
                    if (sousTabChoix[j].choixPay == "Annuel") {
                        stateCopy[i][0].caze[0] = this.state.mntInitial;
                    }
                    else if (sousTabChoix[j].choixPay == "Montant réduction") {
                        var pRed = stateCopy[i][0].caze[0]
                        stateCopy[i][1].caze[1] = this.state.mntInitial - (this.state.mntInitial / pRed);
                    }
                    else if (sousTabChoix[j].choixPay == "Montant mensuel") {
                        var nbMois = stateCopy[i][0].caze[0]
                        stateCopy[i][1].caze[1] = this.state.mntInitial / nbMois;
                    }
                }
            }
            this.setState({
                tabChoix: stateCopy
            }, () => {
                console.log(this.state.tabChoix)
            });
        });
    }

    additionLignFormation = () => {
        let element = { idFraisNiv: -1, typePayEleve: '', annuelle: [], reduction: [], mensuel: [], tranches2: [], tranches3: [] }
        this.setState({
            niveaux: [...this.state.niveaux, element]
        })
    }

    modifTypePayEleve = (selectedTypePayEleve, indexTypePayEleve) => {
        if (selectedTypePayEleve != null) {
            let typePayEleve = selectedTypePayEleve;
            this.setState({
                selectedTypePayEleve: selectedTypePayEleve,
                niveaux: this.state.niveaux.map((el, id) => (id === indexTypePayEleve ? Object.assign(el, { typePayEleve: typePayEleve }) : el))
            }, () => {
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetChoix.php?id=' + selectedTypePayEleve.value).then(res => {
                    console.log(res)
                    if (res.data.length === 1) {
                        var stateCopy = this.state.tabChoix;
                        stateCopy[indexTypePayEleve] = res.data;
                        this.setState({
                            tabChoix: stateCopy
                        }, () => {
                            console.log(this.state.tabChoix)
                            const posAnnee = document.getElementsByClassName('posAnnee');
                            console.log(posAnnee.length - 1)
                            let indexAnnuel = posAnnee.length - 1
                            let mntInitial = this.state.mntInitial
                            var stateCopy = this.state.tabChoix;
                            stateCopy[indexAnnuel][0].caze[0] = mntInitial;
                            this.setState({
                                tabChoix: stateCopy
                            });
                        })
                    }
                    else if (res.data.length > 0) {
                        var stateCopy = this.state.tabChoix;
                        stateCopy[indexTypePayEleve] = res.data;
                        this.setState({
                            tabChoix: stateCopy
                        }, () => {
                            console.log(this.state.tabChoix)
                        })
                    }
                    else {
                        var stateCopy = this.state.tabChoix;
                        stateCopy[indexTypePayEleve] = null;
                        this.setState({
                            tabChoix: stateCopy
                        }, () => {
                            console.log(this.state.tabChoix)
                        })
                    }
                })
            });
        }
    }

    add = (event, indexLignTab, indice, nomChoix) => {
        let include = this.state.tabChoix[indexLignTab][indice].idSousTypePayEleve
        console.log(include)
        if (include != undefined) {
            let mntTranche = event.target.value;
            var stateCopy = this.state.tabChoix;
            stateCopy[indexLignTab][indice].caze[indice] = mntTranche;
            this.setState({
                tabChoix: stateCopy
            }, () => {
                console.log(this.state.tabChoix)
            });
        }
        else {
            if (nomChoix == "Nombre mois") {
                let nbrMois = event.target.value;
                var stateCopy = this.state.tabChoix;
                stateCopy[indexLignTab][indice].caze[indice] = nbrMois;
                this.setState({
                    tabChoix: stateCopy
                }, () => {
                    let montantInitiale = parseInt(this.state.mntInitial)
                    let nombreMois = parseInt(this.state.tabChoix[indexLignTab][indice].caze[indice])
                    if (montantInitiale > 0 && nombreMois > 0) {
                        let mntInitiale = parseInt(this.state.mntInitial);
                        let nbrMois = parseInt(this.state.tabChoix[indexLignTab][indice].caze[indice])
                        let mntCalcul = mntInitiale / nbrMois
                        var stateCopy = this.state.tabChoix;
                        stateCopy[indexLignTab][indice + 1].caze[indice + 1] = mntCalcul;
                        this.setState({
                            tabChoix: stateCopy
                        });
                    }
                });
            }
            else if (nomChoix == "Réduction %") {
                let pourcentageRed = event.target.value;
                var stateCopy = this.state.tabChoix;
                stateCopy[indexLignTab][indice].caze[indice] = pourcentageRed;
                this.setState({
                    tabChoix: stateCopy
                }, () => {
                    let montantInitiale = parseInt(this.state.mntInitial)
                    let pourcentageReduction = parseInt(this.state.tabChoix[indexLignTab][indice].caze[indice])
                    if (montantInitiale > 0 && pourcentageReduction > 0) {
                        let mntInitiale = parseInt(this.state.mntInitial);
                        let pourcentageRed = parseInt(this.state.tabChoix[indexLignTab][indice].caze[indice])
                        let mntCalcul = mntInitiale - ((mntInitiale * pourcentageRed) / 100)
                        var stateCopy = this.state.tabChoix;
                        stateCopy[indexLignTab][indice + 1].caze[indice + 1] = mntCalcul;
                        this.setState({
                            tabChoix: stateCopy
                        });
                    }
                });
            }
        }
    }

    toggleLignFormation = (indexLignFormation, idFraisNiv) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            idFraisNivASupprimer: idFraisNiv,
            indexLignFormationASupprimer: indexLignFormation
            
        }));
    }

    deleteLignFormation = () => {
        this.state.niveaux.splice(this.state.indexLignFormationASupprimer, 1)
        this.state.tabChoix.splice(this.state.indexLignFormationASupprimer, 1)
        let idFraisNivASupprimer = this.state.idFraisNivASupprimer
        if(this.state.idligne.length<=4){
            var i=1;
        }else{
            var i=2;
        }
        for( i;i<this.state.idligne.length;i++){
        
           this.state.tabIdFraisNivDelete=[...this.state.tabIdFraisNivDelete, this.state.idligne[i]]
    
    }
    console.log("tesst")
        console.log(this.state.tabIdFraisNivDelete);
        console.log("tesst")
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteLignFormation = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    modif = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        // recuperer et ranger valeur tableau
        var cazeTranche3 = { typePayEleve: '', tranche3: [{ tranche: [], idSousTypePayEleve: [] }] }
        var cazeTranche2 = { typePayEleve: '', tranche2: [{ tranche: [], idSousTypePayEleve: [] }] }
        var cazeMensuel = { typePayEleve: '', nbrMois: '', mntMensuel: '' }
        var cazeReduction = { typePayEleve: '', pourcentRed: '', mntRed: '' }
        var cazeAnnuel = { typePayEleve: '', mntAnnuel: '' }
        for (var i = 0; i < this.state.niveaux.length; i++) {
            if (this.state.tabChoix[i].length === 3) {
                let tranche3 = this.state.tabChoix[i]
                for (var j = 0; j < tranche3.length; j++) {
                    let ligneCaze = tranche3[j];
                    if (j == 0) {
                        cazeTranche3.tranche3[0].tranche.push(ligneCaze.caze[0])
                        cazeTranche3.tranche3[0].idSousTypePayEleve.push(ligneCaze.idSousTypePayEleve)
                        console.log(ligneCaze.idSousTypePayEleve[0])
                    }
                    else if (j == 1) {
                        cazeTranche3.tranche3[0].tranche.push(ligneCaze.caze[1])
                        cazeTranche3.tranche3[0].idSousTypePayEleve.push(ligneCaze.idSousTypePayEleve)
                    }
                    else if (j == 2) {
                        cazeTranche3.tranche3[0].tranche.push(ligneCaze.caze[2])
                        cazeTranche3.tranche3[0].idSousTypePayEleve.push(ligneCaze.idSousTypePayEleve)
                    }
                }
            }
            else if (this.state.tabChoix[i].length === 2) {
                if (this.state.tabChoix[i][0].idSousTypePayEleve != undefined) {
                    let tranche2 = this.state.tabChoix[i]
                    for (var j = 0; j < tranche2.length; j++) {
                        let ligneCaze = tranche2[j]
                        if (j == 0) {
                            cazeTranche2.tranche2[0].tranche.push(ligneCaze.caze[0])
                            cazeTranche2.tranche2[0].idSousTypePayEleve.push(ligneCaze.idSousTypePayEleve)
                        }
                        else if (j == 1) {
                            cazeTranche2.tranche2[0].tranche.push(ligneCaze.caze[1])
                            cazeTranche2.tranche2[0].idSousTypePayEleve.push(ligneCaze.idSousTypePayEleve)
                        }
                    }
                }
                else if (this.state.tabChoix[i][0].choixPay == "Nombre mois" || this.state.tabChoix[i][0].choixPay == "Montant mensuel") {
                    let mensuel = this.state.tabChoix[i]
                    for (var j = 0; j < mensuel.length; j++) {
                        let ligneCaze = mensuel[j]
                        if (j == 0) {
                            cazeMensuel = Object.assign(cazeMensuel, { nbrMois: ligneCaze.caze[0] })
                        }
                        else if (j == 1) {
                            cazeMensuel = Object.assign(cazeMensuel, { mntMensuel: ligneCaze.caze[1] })
                        }
                    }
                }
                else if (this.state.tabChoix[i][0].choixPay == "Réduction %" || this.state.tabChoix[i][0].choixPay == "Montant réduction") {
                    let reduction = this.state.tabChoix[i]
                    for (var j = 0; j < reduction.length; j++) {
                        let ligneCaze = reduction[j]
                        if (j == 0) {
                            cazeReduction = Object.assign(cazeReduction, { pourcentRed: ligneCaze.caze[0] })
                        }
                        else if (j == 1) {
                            cazeReduction = Object.assign(cazeReduction, { mntRed: ligneCaze.caze[1] })
                        }
                    }
                }
            }
            else if (this.state.tabChoix[i].length === 1) {
                let annuel = this.state.tabChoix[i]
                for (var j = 0; j < annuel.length; j++) {
                    let ligneCaze = annuel[j]
                    cazeAnnuel = Object.assign(cazeAnnuel, { mntAnnuel: ligneCaze.caze[j] })
                }
            }
        }
        // mettre dans le tableau niveau
        for (var i = 0; i < this.state.niveaux.length; i++) {
            if (this.state.niveaux[i].typePayEleve.label == "Annuel") {
                this.setState({
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { annuelle: cazeAnnuel }) : el)),
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { mntInitiale: this.state.mntInitial }) : el)),
                });
            }
            else if (this.state.niveaux[i].typePayEleve.label == "Annuel avec réduction") {
                this.setState({
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { reduction: cazeReduction }) : el)),
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { mntInitiale: this.state.mntInitial }) : el)),
                });
            }
            else if (this.state.niveaux[i].typePayEleve.label == "Mensuelle") {
                this.setState({
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { mensuel: cazeMensuel }) : el)),
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { mntInitiale: this.state.mntInitial }) : el)),
                });
            }
            else if (this.state.niveaux[i].typePayEleve.label == "2 Tranche") {
                this.setState({
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { tranches2: [...this.state.niveaux[0].tranches2, cazeTranche2] }) : el)),
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { mntInitiale: this.state.mntInitial }) : el)),
                });
            }
            else if (this.state.niveaux[i].typePayEleve.label == "3 Tranches") {
                this.setState({
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { tranches3: [...this.state.niveaux[0].tranches3, cazeTranche3] }) : el)),
                    niveaux: this.state.niveaux.map((el, id) => (id === i ? Object.assign(el, { mntInitiale: this.state.mntInitial }) : el)),
                });
            }
        }
        console.log(this.state.tabChoix)
        console.log(this.state.niveaux)
      
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdNiveau.php?id=' + this.state.idNiveau,
            type: 'POST',
            data: {
                formationId: this.state.selectedFormation.value,
                nomNiveau: this.state.nomNiveau,
                periodeNiv: this.state.periodeNiv,
                mntInitial: this.state.mntInitial,
                niveaux: this.state.niveaux,
                tabIdFraisNiv: this.state.tabIdFraisNivDelete,
                anneeScolaire: anneeScolaire,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Niveaux');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification niveau</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Niveaux" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Formation</label>
                                            <Select
                                                value={this.state.selectedFormation}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.modifFormation}
                                                options={this.state.formations}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" className="form-control" value={this.state.nomNiveau} onChange={this.modifNomNiveau} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Obsérvation d'attestation (durée formation)</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" className="form-control" value={this.state.periodeNiv} onChange={this.modifPeriodeNiveau} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Montant initial</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" className="form-control" value={this.state.mntInitial} onChange={this.modifMntInitial} />
                                        </div>
                                    </div>
                                </div>
                                {this.state.mntInitial !== 0 ?
                                    (<div className="row">
                                        <table style={{ marginTop: '10px' }} className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: 'center' }} scope="col">Type paiement elève</th>
                                                    <th style={{ textAlign: 'center' }} scope="col">Montant</th>
                                                    <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                                </tr>
                                            </thead>
                                            {this.state.niveaux.map((el, index) =>
                                                <tbody>
                                                    <tr>
                                                        <td><Select
                                                            className='posAnnee'
                                                            value={el.typePayEleve}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={(e) => this.modifTypePayEleve(e, index)}
                                                            options={this.state.typesPayEleve}
                                                        />  </td>
                                                        <td style={{ textAlign: 'center' }}>{this.state.tabChoix[index] == null ? (null) : (<div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '-15px' }}>{this.state.tabChoix[index].map((el, indice) => <div><p>{el.choixPay}</p><input className="tranche" type="number" value={el.caze[indice]} onChange={(e) => this.add(e, index, indice, el.choixPay)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></div>)}</div>)}</td>
                                                        <td style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleLignFormation(index, el.idFraisNiv)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </table>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                            <Button style={{ borderRadius: '5px' }} onClick={this.additionLignFormation} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                        </div>
                                    </div>) : (null)}
                                <Modal isOpen={this.state.modal} toggle={this.nonDeleteLignFormation} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteLignFormation}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer ce niveau ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteLignFormation()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteLignFormation()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifNiveau);