import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class ListNoteBts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Modifier",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ModifNoteBTS",
                state: { idNote: row.idNote },
              }}
            >
              <button className="buttonModifier">
                <i style={{ color: "white" }} className="fas fa-pencil-alt"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      listNiveau: [],
      listGroupes: [],
      listMatieres: [],
      listSousMat: [],
      listNatureNote: [],
      listEleves: [],
      selectedGroupe: "",
      selectedNiveau: "",
      selectedMatiere: "",
      selectedSousMat: "",
      selectedTrimestre: "",
      blockSousMatiere: false,
      dateAjoutNote: new Date(),
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      pending: true,
      blockDataTable: false,
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectNiveauBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listNiveau: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addNiveau = (selectedNiveau) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedNiveau != null) {
      this.setState({ selectedNiveau }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectedGroupe.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listGroupes: code_html,
            });
          },
        });
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectedMatieres.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listMatieres: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        listGroupes: "",
        listMatieres: "",
      });
    }
  };

  addGroup = (selectedGroupe) => {
    if (selectedGroupe != null) {
      this.setState({ selectedGroupe: selectedGroupe }, () => { });
    } else {
      this.setState({
        selectedGroupe: "",
      });
    }
  };

  addMatiere = (selectedMatiere) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedMatiere != null) {
      this.setState({ selectedMatiere: selectedMatiere }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectedSousMat.php",
          type: "POST",
          data: {
            matiereId: selectedMatiere.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listSousMat: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        listSousMat: [],
        selectedMatiere: "",
      });
    }
  };

  addSousMat = (selectedSousMat) => {
    if (selectedSousMat != "") {
      this.setState({ selectedSousMat: selectedSousMat });
    } else {
      this.setState({
        selectedSousMat: "",
      });
    }
  };

  rechercher = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (
      this.state.selectedNiveau != "" &&
      this.state.selectedGroupe != "" &&
      this.state.selectedMatiere != ""
    ) {
      if (this.state.listSousMat.length == 0) {
        if (this.state.selectedSousMat != "") {
          var idSousMat = "";
        } else {
          var idSousMat = -1;
        }
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ListNoteBts.php",
          type: "POST",
          data: {
            matiereId: this.state.selectedMatiere.value,
            sousMatId: idSousMat,
            anneeScolaire: anneeScolaire,
            groupeId: this.state.selectedGroupe.value,
            query: "",
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            var arrayAncColumns = [
              {
                name: "Modifier",
                cell: (row) => (
                  <Link
                    to={{
                      pathname: "/ModifNoteBTS",
                      state: { idNote: row.idNote },
                    }}
                  >
                    <button className="buttonModifier">
                      <i
                        style={{ color: "white" }}
                        className="fas fa-pencil-alt"
                      ></i>
                    </button>
                  </Link>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ];
            var arrayRecColumns = code_html.columns;
            var arrayColumns = [];
            var arrayColumns = arrayRecColumns.concat(arrayAncColumns);
            this.setState({
              nombreTotalElement: code_html.total,
              columns: arrayColumns,
              data: code_html.data,
              pending: false,
              blockDataTable: true,
            });
          },
        });
      } else {
        if (this.state.selectedSousMat != "") {
          var idSousMat = this.state.selectedSousMat.value;
        } else {
          var idSousMat = -1;
        }
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ListNoteBts.php",
          type: "POST",
          data: {
            matiereId: this.state.selectedMatiere.value,
            sousMatId: idSousMat,
            anneeScolaire: anneeScolaire,
            groupeId: this.state.selectedGroupe.value,
            query: "",
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            var arrayAncColumns = [
              {
                name: "Modifier",
                cell: (row) => (
                  <Link
                    to={{
                      pathname: "/ModifNoteBTS",
                      state: { idNote: row.idNote },
                    }}
                  >
                    <button className="buttonModifier">
                      <i
                        style={{ color: "white" }}
                        className="fas fa-pencil-alt"
                      ></i>
                    </button>
                  </Link>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ];
            var arrayRecColumns = code_html.columns;
            var arrayColumns = [];
            var arrayColumns = arrayRecColumns.concat(arrayAncColumns);
            this.setState({
              nombreTotalElement: code_html.total,
              columns: arrayColumns,
              data: code_html.data,
              pending: false,
              blockDataTable: true,
            });
          },
        });
      }
    } else {
      toast.error("⛔ Veuillez verifier les critéres de recherche !!", {
        containerId: "A",
      });
    }
  };

  newElement(page, totalRows) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (
      this.state.selectedNiveau != "" &&
      this.state.selectedGroupe != "" &&
      this.state.selectedMatiere != ""
    ) {
      if (this.state.listSousMat.length == 0) {
        if (this.state.selectedSousMat != "") {
          var idSousMat = "";
        } else {
          var idSousMat = -1;
        }
        this.setState(
          {
            pending: true,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/ListNoteBts.php",
              type: "POST",
              data: {
                matiereId: this.state.selectedMatiere.value,
                sousMatId: idSousMat,
                anneeScolaire: anneeScolaire,
                groupeId: this.state.selectedGroupe.value,
                query: this.state.filterElement,
                limit: this.state.nbrEltperPage,
                page: page,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                var arrayAncColumns = [
                  {
                    name: "Modifier",
                    cell: (row) => (
                      <Link
                        to={{
                          pathname: "/ModifNoteBTS",
                          state: { idNote: row.idNote },
                        }}
                      >
                        <button className="buttonModifier">
                          <i
                            style={{ color: "white" }}
                            className="fas fa-pencil-alt"
                          ></i>
                        </button>
                      </Link>
                    ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
                ];
                var arrayRecColumns = code_html.columns;
                var arrayColumns = [];
                var arrayColumns = arrayRecColumns.concat(arrayAncColumns);
                this.setState({
                  nombreTotalElement: code_html.total,
                  columns: arrayColumns,
                  data: code_html.data,
                  pending: false,
                  blockDataTable: true,
                });
              },
            });
          }
        );
      } else {
        if (this.state.selectedSousMat != "") {
          var idSousMat = this.state.selectedSousMat.value
          //
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ListNoteBts.php",
            type: "POST",
            data: {
              matiereId: this.state.selectedMatiere.value,
              sousMatId: idSousMat,
              anneeScolaire: anneeScolaire,
              groupeId: this.state.selectedGroupe.value,
              query: "",
              limit: this.state.nbrEltperPage,
              page: 1,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              var arrayAncColumns = [
                {
                  name: "Modifier",
                  cell: (row) => (
                    <Link
                      to={{
                        pathname: "/ModifNoteBTS",
                        state: { idNote: row.idNote },
                      }}
                    >
                      <button className="buttonModifier">
                        <i
                          style={{ color: "white" }}
                          className="fas fa-pencil-alt"
                        ></i>
                      </button>
                    </Link>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ];
              var arrayRecColumns = code_html.columns;
              var arrayColumns = [];
              var arrayColumns = arrayRecColumns.concat(arrayAncColumns);
              this.setState({
                nombreTotalElement: code_html.total,
                columns: arrayColumns,
                data: code_html.data,
                pending: false,
                blockDataTable: true,
              });
            },
          });
        } else {
          toast.error("⛔ Veuillez verifier les critéres de recherche !!", {
            containerId: "A",
          });
        }
      }
    } else {
      toast.error("⛔ Veuillez verifier les critéres de recherche !!", {
        containerId: "A",
      });
    }
  }

  perPage(currentRowsPerPage, currentPage) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (
      this.state.selectedNiveau != "" &&
      this.state.selectedGroupe != "" &&
      this.state.selectedMatiere != ""
    ) {
      if (this.state.listSousMat.length == 0) {
        if (this.state.selectedSousMat != "") {
          var idSousMat = "";
        } else {
          var idSousMat = -1;
        }
        this.setState(
          {
            nbrEltperPage: currentRowsPerPage,
            pending: true,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/ListNoteBts.php",
              type: "POST",
              data: {
                matiereId: this.state.selectedMatiere.value,
                sousMatId: idSousMat,
                anneeScolaire: anneeScolaire,
                groupeId: this.state.selectedGroupe.value,
                query: this.state.filterElement,
                limit: currentRowsPerPage,
                page: currentPage,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                var arrayAncColumns = [
                  {
                    name: "Modifier",
                    cell: (row) => (
                      <Link
                        to={{
                          pathname: "/ModifNoteBTS",
                          state: { idNote: row.idNote },
                        }}
                      >
                        <button className="buttonModifier">
                          <i
                            style={{ color: "white" }}
                            className="fas fa-pencil-alt"
                          ></i>
                        </button>
                      </Link>
                    ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
                ];
                var arrayRecColumns = code_html.columns;
                var arrayColumns = [];
                var arrayColumns = arrayRecColumns.concat(arrayAncColumns);
                this.setState({
                  nombreTotalElement: code_html.total,
                  columns: arrayColumns,
                  data: code_html.data,
                  pending: false,
                  blockDataTable: true,
                });
              },
            });
          }
        );
      } else {
        if (this.state.selectedSousMat != "") {
          var idSousMat = this.state.selectedSousMat.value
          //
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ListNoteBts.php",
            type: "POST",
            data: {
              matiereId: this.state.selectedMatiere.value,
              sousMatId: idSousMat,
              anneeScolaire: anneeScolaire,
              groupeId: this.state.selectedGroupe.value,
              query: "",
              limit: this.state.nbrEltperPage,
              page: 1,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              var arrayAncColumns = [
                {
                  name: "Modifier",
                  cell: (row) => (
                    <Link
                      to={{
                        pathname: "/ModifNoteBTS",
                        state: { idNote: row.idNote },
                      }}
                    >
                      <button className="buttonModifier">
                        <i
                          style={{ color: "white" }}
                          className="fas fa-pencil-alt"
                        ></i>
                      </button>
                    </Link>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ];
              var arrayRecColumns = code_html.columns;
              var arrayColumns = [];
              var arrayColumns = arrayRecColumns.concat(arrayAncColumns);
              this.setState({
                nombreTotalElement: code_html.total,
                columns: arrayColumns,
                data: code_html.data,
                pending: false,
                blockDataTable: true,
              });
            },
          });
        } else {
          toast.error("⛔ Veuillez verifier les critéres de recherche !!", {
            containerId: "A",
          });
        }
      }
    } else {
      toast.error("⛔ Veuillez verifier les critéres de recherche !!", {
        containerId: "A",
      });
    }
  }

  filterSearch(event) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        if (this.state.listSousMat.length == 0) {
          if (this.state.selectedSousMat != "") {
            var idSousMat = "";
          } else {
            var idSousMat = -1;
          }
        }
        else {
          var idSousMat = this.state.selectedSousMat.value;
        }
        //
        if (this.state.selectedMatiere.value != "") {
          var matiereId = this.state.selectedMatiere.value;
        } else {
          var matiereId = "";
        }
        if (this.state.selectedGroupe.value != "") {
          var groupeId = this.state.selectedGroupe.value;
        } else {
          var groupeId = "";
        }
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ListNoteBts.php",
          type: "POST",
          data: {
            matiereId: matiereId,
            groupeId: groupeId,
            anneeScolaire: anneeScolaire,
            sousMatId: idSousMat,
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            var arrayAncColumns = [{
              name: 'Modifier',
              cell: row => <Link to={{
                pathname: '/ModifNoteBTS',
                state: { idNote: row.idNote }
              }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link>,
              ignoreRowClick: true,
              allowOverflow: true,
              button: true
            }]
            var arrayRecColumns = code_html.columns
            var arrayColumns = []
            var arrayColumns = arrayRecColumns.concat(arrayAncColumns);
            this.setState({
              nombreTotalElement: code_html.total,
              columns: arrayColumns,
              data: code_html.data,
              pending: false,
              blockDataTable: true
            });
          },
        });
      }
    );
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Notes</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Niveau</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addNiveau}
                        options={this.state.listNiveau}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroup}
                        options={this.state.listGroupes}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Matière</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addMatiere}
                        options={this.state.listMatieres}
                      />
                    </div>
                  </div>
                  {this.state.listSousMat.length > 0 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Sous Matière</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addSousMat}
                          options={this.state.listSousMat}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.rechercher}
                    >
                      Rechercher
                    </button>
                  </div>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                {this.state.blockDataTable == true ? (
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover={true}
                    pagination={true}
                    paginationServer={true}
                    onChangePage={(page, totalRows) =>
                      this.newElement(page, totalRows)
                    }
                    paginationTotalRows={this.state.nombreTotalElement}
                    paginationPerPage={this.state.nbrEltperPage}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                      this.perPage(currentRowsPerPage, currentPage)
                    }
                    paginationResetDefaultPage={this.state.resetFirstPage}
                    paginationComponentOptions={{
                      rowsPerPageText: "Elements par page:",
                      rangeSeparatorText: "de",
                      noRowsPerPage: false,
                    }}
                    noHeader={true}
                    progressPending={this.state.pending}
                  />
                ) : null}
              </div>
              <ToastContainer
                transition={Flip}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ListNoteBts;
