import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';

class ModifFormation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idFormation: '',
            listFilieres: [],
            selectedFiliere: '',
            listSpecialites: [],
            selectedSpecialite: '',
            nomFormation: '',
            listNivBase: [],
            selectedNivBase: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var idFormation = this.props.location.state.idFormation;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetFormatById.php?id=' + idFormation).then(res => {
                console.log(res)
                this.setState({
                    idFormation: res.data.idFormation,
                    selectedFiliere: res.data.filiere,
                    selectedSpecialite: res.data.specialite,
                    selectedNivBase: res.data.nivBase,
                    nomFormation: res.data.nomFormation
                })
            })
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFilieres.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listFilieres: code_html
                    })
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectNivBase.php').then(res => {
                console.log(res)
                this.setState({
                    listNivBase: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    updFiliere = selectedFiliere => {
        if (selectedFiliere != null) {
            this.setState({ selectedFiliere }, () => {
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectSpecialites.php?id=' + selectedFiliere.value).then(res => {
                    console.log(res)
                    this.setState({
                        listSpecialites: res.data
                    })
                })
            });
        }
    };

    updSpecialite = selectedSpecialite => {
        if (selectedSpecialite != null) {
            this.setState({ selectedSpecialite });
            console.log(`Specialite selected:`, selectedSpecialite);
        }
    };

    updNivBase = selectedNivBase => {
        if (selectedNivBase != null) {
            this.setState({ selectedNivBase });
            console.log(`NivBase selected:`, selectedNivBase);
        }
    };

    updNomFormation = (event) => {
        this.setState({
            nomFormation: event.target.value
        });
    }

    ajout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdFormation.php?id=' + this.state.idFormation,
            type: 'POST',
            data: {
                filiereId: this.state.selectedFiliere.value,
                specialiteId: this.state.selectedSpecialite.value,
                nomFormation: this.state.nomFormation,
                nivBaseId: this.state.selectedNivBase.value,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Formations');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification formation</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Formations" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Filiére</label>
                                            <Select
                                                value={this.state.selectedFiliere}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updFiliere}
                                                options={this.state.listFilieres}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Spécialité</label>
                                            <Select
                                                value={this.state.selectedSpecialite}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updSpecialite}
                                                options={this.state.listSpecialites}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau de Base</label>
                                            <Select
                                                value={this.state.selectedNivBase}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updNivBase}
                                                options={this.state.listNivBase}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom formation</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" className="form-control" value={this.state.nomFormation} onChange={this.updNomFormation} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifFormation);
