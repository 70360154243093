import React, { Component, Fragment } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios'
import jQuery from 'jquery'
import './paginate.css'
import Collapsible from 'react-collapsible';
import Select from 'react-select';

class ReglementEnseignant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Enseignant',
                    selector: 'nomEns',
                    sortable: false,
                    center: true,
                    style: { width: '20%' }
                },
                {
                    name: 'Detail',
                    cell: row =>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} scope="col">Mois</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Somme</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Detail reglement par mois</th>
                                </tr>
                            </thead>
                            {row.detailReg.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'center' }}><p>{el.mois}</p></td>
                                        <td style={{ textAlign: 'center' }}><p>{el.sommeMois}</p></td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Collapsible trigger={<button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }}><i style={{ color: 'white' }} className="fas fa-info"></i></button>}>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }} scope="col">Date</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Type</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Mode paiement</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Numéro piéce</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Modif</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Supp</th>
                                                        </tr>
                                                    </thead>
                                                    {el.detailRegMois.map(ele =>
                                                        <tbody>
                                                            <tr>
                                                                <td>{ele.date}</td>
                                                                <td>{ele.type}</td>
                                                                <td>{ele.montant}</td>
                                                                <td>{ele.typePay.map((elm, ind) =>
                                                                    elm.mode.map(elem =>
                                                                        <p>{elem.label}</p>
                                                                    )
                                                                )}
                                                                </td>
                                                                <td>{ele.typePay.map((elm, ind) =>
                                                                    elm.numPiece.map(ellem =>
                                                                        <p>{ellem.numP}</p>
                                                                    )
                                                                )}
                                                                </td>
                                                                <td><Link to={{
                                                                    pathname: '/ModifReglementEnseignant',
                                                                    state: { idReg: ele.idReg }
                                                                }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link>
                                                                </td>
                                                                <td><Link to={{
                                                                    pathname: '/ModifReglementEnseignant',
                                                                    state: { idReg: ele.idReg }
                                                                }}><button className='buttonSupprimer'><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></Link>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </table>
                                            </Collapsible>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '80%'
                }
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            pending: true,
            // search
            listEnseignant: [],
            selectedIdEns: '',
            mois: [{ value: 1, label: 'Janvier' }, { value: 2, label: 'Férvier' }, { value: 3, label: 'Mars' }, { value: 4, label: 'Avril' }, { value: 5, label: 'Mai' }, { value: 6, label: 'Juin' }, { value: 7, label: 'Juillet' }, { value: 8, label: 'Aout' }, { value: 9, label: 'Septembre' }, { value: 10, label: 'Octobre' }, { value: 11, label: 'Novembre' }, { value: 12, label: 'Decembre' }],
            selectedMois: ''
        }
        this.newElement = this.newElement.bind(this);
        this.filterSearch = this.filterSearch.bind(this);
    }

    componentDidMount() {
        var ladate=new Date().getFullYear()
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var idRoleUser = userPermissions[0].idRoleUser
            if (idRoleUser == 1) {
                var anneeScolaire = userPermissions[0].anneeScolaire
                
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsEnseignant.php',
                    type: 'POST',
                    data: {
                        query: '',
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEnsByAnneeScol.php',
                    type: 'POST',
                    data: {
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listEnseignant: code_html
                        })
                    }
                });
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectModePaiement.php').then(res => {
                    console.log(res)
                    this.setState({
                        listModPay: res.data
                    })
                })
           
            }
            else {
                localStorage.clear();
                document.location = '/'
            }
        }
        else {
            document.location = '/'
        }
    }

    newElement(page, totalRows) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedIdEns != '') {
            var idEns = this.state.selectedIdEns.value
        }
        else {
            var idEns = ''
        }
        if (this.state.selectedMois != '') {
            var idMois = this.state.selectedMois.value
        }
        else {
            var idMois = ''
        }
        this.setState({
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsEnseignantSearch.php',
                    type: 'POST',
                    data: {
                        filterElement: this.state.filterElement,
                        idEns: idEns,
                        idMois: idMois,
                        limit: this.state.nbrEltperPage,
                        page: page,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }
        )
    }

    perPage(currentRowsPerPage, currentPage) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedIdEns != '') {
            var idEns = this.state.selectedIdEns.value
        }
        else {
            var idEns = ''
        }
        if (this.state.selectedMois != '') {
            var idMois = this.state.selectedMois.value
        }
        else {
            var idMois = ''
        }
        this.setState({
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsEnseignantSearch.php',
                    type: 'POST',
                    data: {
                        filterElement: this.state.filterElement,
                        idEns: idEns,
                        idMois: idMois,
                        limit: currentRowsPerPage,
                        page: currentPage,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }
        )
    }

    filterSearch(event) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedIdEns != '') {
            var idEns = this.state.selectedIdEns.value
        }
        else {
            var idEns = ''
        }
        if (this.state.selectedMois != '') {
            var idMois = this.state.selectedMois.value
        }
        else {
            var idMois = ''
        }
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsEnseignantSearch.php',
                    type: 'POST',
                    data: {
                        filterElement: this.state.filterElement,
                        idEns: idEns,
                        idMois: idMois,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    searchEnseignant = selectedIdEns => {
        if (selectedIdEns !== null) {
            this.setState({ selectedIdEns });
        }
        else {
            this.setState({
                selectedIdEns: ''
            });
        }
    };

    searchMois = selectedMois => {
        if (selectedMois != null) {
            this.setState({ selectedMois });
        }
        else {
            this.setState({ selectedMois: '' });
        }console.log(this.state.selectedMois)
    }

    recherche = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedIdEns != '') {
            var idEns = this.state.selectedIdEns.value
        }
        else {
            var idEns = ''
        }
        if (this.state.selectedMois != '') {
            var idMois = this.state.selectedMois.value
        }
        else {
            var idMois = ''
        }
        this.setState({
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsEnseignantSearch.php',
                    type: 'POST',
                    data: {
                        filterElement: this.state.filterElement,
                        idEns: idEns,
                        idMois: idMois,
                        anneeScolaire: anneeScolaire,
                        limit: this.state.nbrEltperPage,
                        page: 1
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    afficherTout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            selectedIdEns: '',
            selectedMois: ''
        }, () => {
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsEnseignant.php',
                type: 'POST',
                data: {
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
        });
    }

    imprEnsPayer = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedIdEns != '') {
            var idEns = this.state.selectedIdEns.value
        }
        else {
            var idEns = ''
        }
        if (this.state.selectedMois != '') {
            var mois = this.state.selectedMois.value
        }
        else {
            var mois = ''
        }
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ImprRegEnsPayer.php',
            type: 'POST',
            data: {
                anneeScolaire: anneeScolaire,
                idEns: idEns,
                mois: mois
            },
            dataType: 'json',
            success: function (code_html, statut) {
                var server_path = code_html.server_path;
                var file_path = code_html.file_path
                var path = server_path + file_path;
                window.open(path, "_blank")
            }
        });
    }

    imprDetRegEns = () => {
        
        if (this.state.selectedMois != '') {
            var mois = this.state.selectedMois.value
        }
        else {
            var mois = ''
        }
        if (this.state.selectedIdEns != '') {
            var idEns = this.state.selectedIdEns.value
        }
        else {
            var idEns = ''
        }
        // Convert date
        var today = new Date();
        var dateEcheChec = today
        let monthOne = '' + (dateEcheChec.getMonth() + 1);
        let dayOne = '' + dateEcheChec.getDate();
        let yearOne = dateEcheChec.getFullYear();
        if (monthOne.length < 2)
            monthOne = '0' + monthOne;
        if (dayOne.length < 2)
            dayOne = '0' + dayOne;
        today = [dayOne, monthOne, yearOne].join('/')
        //
        var params = mois + '-'+idEns + '-' + today 
        window.open("http://artsmetiers.mtd-app.com/ImprDetailRegEns?params=" + params)
       // window.open("http://localhost:3000/ImprDetailRegEns?params=" + params)
        
    }

    imprEnsNonPayer = () => {
        var ladate=new Date().getFullYear()
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
    if(anneeScolaire==ladate ||anneeScolaire==(ladate-1)){
        if (this.state.selectedIdEns != '') {
            var idEns = this.state.selectedIdEns.value
        }
        else {
            var idEns = ''
        }
        if (this.state.selectedMois != '') {
            var mois = this.state.selectedMois.value
        }
        else {
            var mois = ''
        }
        // Convert date
        var today = new Date();
        var dateEcheChec = today
        let monthOne = '' + (dateEcheChec.getMonth() + 1);
        let dayOne = '' + dateEcheChec.getDate();
        let yearOne = dateEcheChec.getFullYear();
        if (monthOne.length < 2)
            monthOne = '0' + monthOne;
        if (dayOne.length < 2)
            dayOne = '0' + dayOne;
        today = [dayOne, monthOne, yearOne].join('/')
        //
        var params = idEns + '-' + mois + '-' + today
        window.open("http://artsmetiers.mtd-app.com/ImprEnsNonPayer?params=" + params)
       // window.open("http://localhost:3000/ImprEnsNonPayer?params=" + params)
    }else{alert("annee non disponible")}
    }


    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Reglement Enseignant</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="row">
                                    <h4 style={{ paddingLeft: '10px' }}>Critères de recherche</h4>
                                    <Fragment>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Enseignant</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchEnseignant}
                                                    options={this.state.listEnseignant}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Mois</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => this.searchMois(e)}
                                                    options={this.state.mois}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.recherche}>Rechercher</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.afficherTout}>Afficher tout</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.imprEnsPayer}><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Impression</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.imprDetRegEns}><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Impression detail reglement</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.imprEnsNonPayer}><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Imprimer liste formateur non payé</button>
                                    </div>
                                </div>
                                <div style={{ paddingTop: '10px' }} className="mb-0 form-group">
                                    <div className="input-group-alternative input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input onChange={(currentRowsPerPage, currentPage) => this.filterSearch(currentRowsPerPage, currentPage)} placeholder="Search" type="text" className="search" />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReglementEnseignant;