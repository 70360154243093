import React, { Component } from "react";
import jQuery from "jquery";
import 'rc-checkbox/assets/index.css';
import './paginate.css'

class ImprDetailRegEns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            nomMois: '',
            moisExist: '',
            today: '',
            arrayDetRegEns: [],
            total:0
        };
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
            var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
            var anneeScolaire = userPermissions[0].anneeScolaire;
            var url = window.location.href
            var arrayUrl = url.split("=");
            var params = arrayUrl[1]
            var arrayParams = params.split("-");
       
            
            var mois = arrayParams[0];
            var idEns = arrayParams[1];
            if (mois == 1) {
                var nomMois = "Janvier"
            }
            else if (mois == 2) {
                var nomMois = "Février"
            }
            else if (mois == 3) {
                var nomMois = "Mars"
            }
            else if (mois == 4) {
                var nomMois = "Avril"
            }
            else if (mois == 5) {
                var nomMois = "Mai"
            }
            else if (mois == 6) {
                var nomMois = "Juin"
            }
            else if (mois == 7) {
                var nomMois = "Juillet"
            }
            else if (mois == 8) {
                var nomMois = "Aout"
            }
            else if (mois == 9) {
                var nomMois = "Septembre"
            }
            else if (mois == 10) {
                var nomMois = "Octobre"
            }
            else if (mois == 11) {
                var nomMois = "Novembre"
            }
            else if (mois == 12) {
                var nomMois = "Decembre"
            }
            /*if (mois == 1) {
                var nomMois = "Decembre"
            }
            else if (mois == 2) {
                var nomMois = "Janvier"
            }
            else if (mois == 3) {
                var nomMois = "Février"
            }
            else if (mois == 4) {
                var nomMois = "Mars"
            }
            else if (mois == 5) {
                var nomMois = "Avril"
            }
            else if (mois == 6) {
                var nomMois = "Mai"
            }
            else if (mois == 7) {
                var nomMois = "Juin"
            }
            else if (mois == 8) {
                var nomMois = "Juillet"
            }
            else if (mois == 9) {
                var nomMois = "Aout"
            }
            else if (mois == 10) {
                var nomMois = "Septembre"
            }
            else if (mois == 11) {
                var nomMois = "Octobre"
            }
            else if (mois == 12) {
                var nomMois = "Novembre"
            }
            else {
                var nomMois = ""
            } */
            var today = arrayParams[1]
            this.setState({
                nomMois: nomMois,
                moisExist: mois,
                today: today
            }, () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/DetailRegEns.php',
                    type: 'POST',
                    data: {
                        anneeScolaire: anneeScolaire,
                        mois: mois,
                        id:idEns
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                    
                            arrayDetRegEns: code_html.data,
                        
                        });
                    }
                });
            });
        } else {
            document.location = "/";
        }
    }

    impr = () => {
        window.print();
    }

    render() {
        return (
            <div className="white-box">
                <h4 style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Paiement formateurs </h4>
                <h5 style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>{this.state.today}</h5>
                {this.state.moisExist != '' ? (<h5 style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>{"Mois : " + this.state.nomMois}</h5>) : (null)}
                <table style={{ border: 'solid' }} className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center', border: 'solid', color: 'black', fontWeight: 'bold', fontSize: '13px' }} scope="col">CIN</th>
                            <th style={{ textAlign: 'center', border: 'solid', color: 'black', fontWeight: 'bold', fontSize: '13px' }} scope="col">Formateur</th>
                            <th style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'black', fontWeight: 'bold', fontSize: '13px' }} scope="col">
                               <p>Montant</p>
                                <p>Type de paiement</p>
                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.arrayDetRegEns.map(el =>
                            <tr>
                                <td style={{ textAlign: 'center', border: 'solid', color: 'black', fontWeight: 'bold', fontSize: '11px' }}>
                                  {el.numero} 
                                </td>
                                <td style={{ textAlign: 'center', border: 'solid', color: 'black', fontWeight: 'bold', fontSize: '11px' }}>
                                    {el.nomEns}
                                    
                                </td>
                                <td style={{  border: 'solid', color: 'black', fontWeight: 'bold', fontSize: '11px' }}>
                                    <div>
                                        <table style={{ width: '100%' }}>
                                            {el.detailReg.map(elei=>
                                            <tbody>
                                            
                                              {elei.detailRegMois.length>1?(  
                                            elei.detailRegMois.map(ele =>
                                                    
                                                            <tr>
                                                               
                                                            <td style={{ textAlign: 'left', color: 'black', fontWeight: 'bold', fontSize: '11px' ,width:"100px"}}>{ele.montant}</td>
                                                            <td style={{ color: 'black', fontWeight: 'bold', fontSize: '11px',width:"100px"}}>{ele.typePay.map((elm, ind) =>
                                                                    elm.mode.map(elem =>
                                                                        <td style={{textAlign: 'right', color: 'black', fontWeight: 'bold', fontSize: '11px',width:"500px" }}><p>{elem.label}</p></td>
                                                                    )
                                                                )}
                                                                </td>
                                                                
                                                                <p style={{display:'none'}}>{this.state.total += Number.parseFloat(ele.montant)} </p> 
                                                            </tr>
                                                 
                                            )):(
                                                elei.detailRegMois.map(ele =>
                                                    
                                                    <tr>
                                                       
                                                    <td style={{ textAlign: 'left', color: 'black', fontWeight: 'bold', fontSize: '11px' ,width:"100px"}}>{elei.sommeMois}</td>
                                                    <td style={{ color: 'black', fontWeight: 'bold', fontSize: '11px',width:"100px"}}>{ele.typePay.map((elm, ind) =>
                                                            elm.mode.map(elem =>
                                                                <td style={{textAlign: 'right', color: 'black', fontWeight: 'bold', fontSize: '11px',width:"500px" }}><p>{elem.label}</p></td>
                                                            )
                                                        )}
                                                        </td>
                                                        
                                                        <p style={{display:'none'}}>{this.state.total += Number.parseFloat(ele.montant)} </p> 
                                                    </tr>
                                         
                                    )
                                            )
                                            }

                                            </tbody>)}
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <th colSpan="2" style={{ textAlign: 'center', border: 'solid', color: 'black', fontWeight: 'bold', textAlign: 'right', fontSize: '20px' }} scope="col">Total</th>
                            <th style={{ textAlign: 'right', border: 'solid', color: 'black', fontWeight: 'bold', fontSize: '20px' }} scope="col">{Number.parseFloat(this.state.total).toFixed(3)}</th>
                        </tr>
                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'center' }}><i id="print_me_not" style={{ fontSize: '30px', cursor: 'pointer' }} onClick={this.impr} class="fas fa-print"></i></div>
            </div>
        );
    }
}

export default ImprDetailRegEns;

