import React, { Component, Fragment } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

class ModifReglFraisApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initStatus: 1,
            listEleve: [],
            selectedEleve: '',
            nomEleve: '',
            arrayRegl: [],
            infoFormatEleve: [],
            fraisReglEleve: [],
            reglEleve: [],
            mntFraisTotalPayer: 0,
            mntIncripTotalPayer: 0,
            tabModPay: [{ modPay: '', mntModPay: '', numCheque: '', echeanceChec: new Date(), dateEncaiss: new Date(), titulaireChec: '', banque: '' }],
            listModPay: [],
            lengthFraisReglEleve: '',
            lengthReglEleve: '',
            disabledFrais: false,
            disabledReg: false,
            arrayModePaiement: [],
            arrayPaiement: [],
            datePaiement: '',
            disbut: true
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEleves.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listEleve: code_html
                    });
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectModePaiement.php').then(res => {
                console.log(res)
                this.setState({
                    listModPay: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    selectEleve = (selectedEleve) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (selectedEleve != null) {
            this.setState({
                selectedEleve: selectedEleve,
                nomEleve: selectedEleve.label
            }, () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetReglByEleve.php',
                    type: 'POST',
                    data: {
                        eleveId: this.state.selectedEleve.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            arrayRegl: code_html,
                            infoFormatEleve: code_html[0],
                            fraisReglEleve: code_html[1],
                            initStatus: 0
                        }, () => {
                            // Somme frais reglement
                            var sommeMntFraisPayer = 0;
                            for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                                sommeMntFraisPayer += parseInt(this.state.fraisReglEleve[i].mnt)
                            }
                            this.setState({
                                mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                            });
                        });
                    }
                });
            });
        }
        else {
            this.setState({
                arrayRegl: [],
                infoFormatEleve: [],
                fraisReglEleve: [],
                initStatus: 1
            });
        }
    }

    addDatePaiement = d => {
        this.setState({
            datePaiement: d
        });
    };

    updMntFraisPayer = (event, indexMntFraisPayer) => {
        var mntFraisPay = event.target.value
        var mntTotal = parseInt(this.state.fraisReglEleve[indexMntFraisPayer].mntTotal)
        var mntDejaPayer = parseInt(this.state.fraisReglEleve[indexMntFraisPayer].mntPay)
        if (mntDejaPayer == 0) {
            if (mntTotal >= mntFraisPay) {
                this.setState({
                    fraisReglEleve: this.state.fraisReglEleve.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mnt: mntFraisPay }) : el))
                }, () => {
                    var mntRestNv = mntTotal - mntFraisPay
                    this.setState({
                        fraisReglEleve: this.state.fraisReglEleve.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntRest: Number.parseFloat(mntRestNv).toFixed(3) }) : el))
                    });
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                        if (this.state.fraisReglEleve[i].mnt != "") {
                            sommeMntFraisPayer += parseInt(this.state.fraisReglEleve[i].mnt)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                })
            }
            else {
                toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
            }
        }
        else if (mntDejaPayer > 0) {
            var restMnt = mntTotal - mntDejaPayer
            if (parseInt(restMnt) >= parseInt(mntFraisPay)) {
                this.setState({
                    fraisReglEleve: this.state.fraisReglEleve.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mnt: mntFraisPay }) : el))
                }, () => {
                    if (mntFraisPay > 0) {
                        var mntRestNv = restMnt - mntFraisPay
                        this.setState({
                            fraisReglEleve: this.state.fraisReglEleve.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntRest: Number.parseFloat(mntRestNv).toFixed(3) }) : el))
                        });
                    }
                    else if (mntFraisPay == 0) {
                        var mntRestNv = mntTotal - mntDejaPayer
                        this.setState({
                            fraisReglEleve: this.state.fraisReglEleve.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntRest: Number.parseFloat(mntRestNv).toFixed(3) }) : el))
                        });
                    }
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                        if (this.state.fraisReglEleve[i].mnt != "") {
                            sommeMntFraisPayer += parseInt(this.state.fraisReglEleve[i].mnt)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                })
            }
            else {
                toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
            }
        }
    }

    addPayTotFrais = (e, index) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayFrais = this.state.fraisReglEleve
            var mnt = arrayFrais[index].mntRest
            var elFrais = arrayFrais[index]
            elFrais: Object.assign(elFrais, { mnt: mnt })
            this.setState({
                fraisReglEleve: arrayFrais
            }, () => {
                var mntRest = this.state.fraisReglEleve[index].mntTotal - (parseInt(this.state.fraisReglEleve[index].mntPay) + parseInt(this.state.fraisReglEleve[index].mnt))
                var arrayFrais = this.state.fraisReglEleve
                var elFrais = arrayFrais[index]
                elFrais: Object.assign(elFrais, { mntRest: Number.parseFloat(mntRest).toFixed(3) })
                this.setState({
                    fraisReglEleve: arrayFrais
                }, () => {
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                        if (this.state.fraisReglEleve[i].mnt != "") {
                            sommeMntFraisPayer += parseInt(this.state.fraisReglEleve[i].mnt)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                });
            });
        }
        else {
            var arrayFrais = this.state.fraisReglEleve
            var mnt = 0
            var elFrais = arrayFrais[index]
            elFrais: Object.assign(elFrais, { mnt: mnt })
            this.setState({
                fraisReglEleve: arrayFrais
            }, () => {
                var mntRest = this.state.fraisReglEleve[index].mntTotal - (parseInt(this.state.fraisReglEleve[index].mntPay) + parseInt(this.state.fraisReglEleve[index].mnt))
                var arrayFrais = this.state.fraisReglEleve
                var elFrais = arrayFrais[index]
                elFrais: Object.assign(elFrais, { mntRest: Number.parseFloat(mntRest).toFixed(3) })
                this.setState({
                    fraisReglEleve: arrayFrais
                }, () => {
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                        if (this.state.fraisReglEleve[i].mnt != "") {
                            sommeMntFraisPayer += parseInt(this.state.fraisReglEleve[i].mnt)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                });
            });
        }
    }

    additionLign = () => {
        let element = { modPay: '', mntModPay: '', numCheque: '', echeanceChec: new Date(), dateEncaiss: new Date(), titulaireChec: '', banque: '' }
        this.setState({
            tabModPay: [...this.state.tabModPay, element]
        })
    }

    addModPay = (selectedModPay, indexModPay) => {
        if (selectedModPay != null) {
            let modPay = selectedModPay;
            this.setState({
                tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { modPay: modPay }) : el))
            });
        }
    }

    addMntModPay = (event, indexModPay) => {
        let mntModPay = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { mntModPay: mntModPay }) : el))
        });
    }

    addNumChec = (event, indexModPay) => {
        let numCheque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { numCheque: numCheque }) : el))
        });
    }

    addEcheanceChec = (d, indexModPay) => {
        let dateChec = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { echeanceChec: dateChec }) : el))
        });
    }

    addDateEncaiss = (d, indexModPay) => {
        let dateEncaiss = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { dateEncaiss: dateEncaiss }) : el))
        });
    }

    addTitulaireChec = (event, indexModPay) => {
        let titulaireChec = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { titulaireChec: titulaireChec }) : el))
        });
    }

    addBanque = (event, indexModPay) => {
        let banque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { banque: banque }) : el))
        });
    }

    toggleLign = (index) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            indexLignASupprimer: index
        }));
    }

    deleteLign = () => {
        this.state.tabModPay.splice(this.state.indexLignASupprimer, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteLign = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    modifRegInscrip = () => {
        if (this.state.datePaiement != '') {
            var sommePaiement = 0
            sommePaiement = parseInt(this.state.mntFraisTotalPayer)
            var sommeReg = 0
            for (var i = 0; i < this.state.tabModPay.length; i++) {
                sommeReg += parseInt(this.state.tabModPay[i].mntModPay)
            }
            if (sommePaiement !== sommeReg) {
                toast.error('⛔ Veuillez vérifier les sommes saisie !', { containerId: 'A' });
            }
            else {
                this.setState({ disbut: false }, () => {
                    var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                    var anneeScolaire = userPermissions[0].anneeScolaire
                    var userId = userPermissions[0].idUser
                    var arrayModPay = []
                    for (var i = 0; i < this.state.tabModPay.length; i++) {
                        arrayModPay.push(this.state.tabModPay[i].modPay.value)
                    }
                    // convert date paiement
                    let datePaiement = this.state.datePaiement
                    let month = '' + (datePaiement.getMonth() + 1);
                    let day = '' + datePaiement.getDate();
                    let year = datePaiement.getFullYear();
                    if (month.length < 2)
                        month = '0' + month;
                    if (day.length < 2)
                        day = '0' + day;
                    var convertDatePaiement = [year, month, day].join('-');
                    //
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdReglEleveFrais.php',
                        type: 'POST',
                        data: {
                            eleveId: this.state.infoFormatEleve[0].eleveId,
                            idInscrip: this.state.infoFormatEleve[0].idIns,
                            regFrais: this.state.fraisReglEleve,
                            modPay: this.state.tabModPay,
                            groupId: this.state.infoFormatEleve[0].groupeId,
                            arrayIdModPay: arrayModPay,
                            anneeScolaire: anneeScolaire,
                            dateReg: convertDatePaiement,
                            userId: userId,
                            typePaiement: this.state.infoFormatEleve[0].mode,
                            montantTotal: sommeReg
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            if (code_html.Result == "OK") {
                                var idRegFor = code_html.idRegFor
                                //
                                var incluEsp = false
                                var incluChec = false
                                // selon le type de paiement 
                                jQuery.ajax({
                                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ApprenantsTypePayFor.php',
                                    type: 'POST',
                                    data: {
                                        idRegFor: idRegFor
                                    },
                                    dataType: 'json',
                                    success: (code_html, statut) => {
                                        this.setState({
                                            arrayModePaiement: code_html
                                        }, () => {
                                            if (this.state.arrayModePaiement.includes('1') || this.state.arrayModePaiement.includes('3')) {
                                                incluEsp = true
                                            }
                                            if (this.state.arrayModePaiement.includes('2')) {
                                                incluChec = true
                                            }
                                            if (incluChec == true) {
                                                // Recu frais
                                                jQuery.ajax({
                                                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegForFraisChec.php',
                                                    type: 'POST',
                                                    data: {
                                                        idRegFor: idRegFor
                                                    },
                                                    dataType: 'json',
                                                    success: (code_html, statut) => {
                                                        var server_path = code_html.server_path;
                                                        var file_path = code_html.file_path
                                                        var path = server_path + file_path;
                                                        window.open(path, "_blank")
                                                        this.props.history.push('/ReglApprenant');
                                                    }
                                                });
                                            }
                                            else if (incluEsp == true) {
                                                // Recu frais
                                                jQuery.ajax({
                                                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegForFraisEsp.php',
                                                    type: 'POST',
                                                    data: {
                                                        idRegFor: idRegFor
                                                    },
                                                    dataType: 'json',
                                                    success: (code_html, statut) => {
                                                        var server_path = code_html.server_path;
                                                        var file_path = code_html.file_path
                                                        var path = server_path + file_path;
                                                        window.open(path, "_blank")
                                                        this.props.history.push('/ReglApprenant');
                                                    }
                                                });
                                            }
                                        })
                                    }
                                });
                            }
                            else {
                                alert("prbl lors de l'insertion")
                            }
                        }
                    });
                })
            }
        }
        else {
            toast.error('⛔ Veuillez saisir le champs date de paiement !!', { containerId: 'A' });
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Mise à jour reglement frais</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/ReglApprenant" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Eléves</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.selectEleve(e)}
                                                options={this.state.listEleve}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date paiement</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.datePaiement}
                                                onChange={this.addDatePaiement}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                    {this.state.arrayRegl.length > 0 ?
                                        (<Fragment>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Groupe</label>
                                                    <input type="text" disabled value={this.state.infoFormatEleve[0].groupe} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Niveau</label>
                                                    <input type="text" disabled value={this.state.infoFormatEleve[0].niveau} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>formation</label>
                                                    <input type="text" disabled value={this.state.infoFormatEleve[0].formation} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Specialite</label>
                                                    <input type="text" disabled value={this.state.infoFormatEleve[0].nomSpecialite} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                        </Fragment>) : (null)
                                    }
                                </div>
                                {this.state.fraisReglEleve.length > 0 ?
                                    (<Fragment>
                                        <h3>Paiement frais formation :</h3>
                                        <div className="row">
                                            {this.state.fraisReglEleve.length > 0 ?
                                                (<Fragment>
                                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Montant deja payé</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Montant a payé</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                                            </tr>
                                                        </thead>
                                                        {this.state.fraisReglEleve.map((el, index) =>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="col-md-3" style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.nomFrais}</p></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntTotal).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={el.mntPay} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.mnt} onChange={(e) => this.updMntFraisPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntRest).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                    <td className="col-md-1">
                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <Checkbox
                                                                                onChange={(e) => this.addPayTotFrais(e, index)}
                                                                                disabled={this.state.disabledReg}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                                <td className="col-md-3" style={{ textAlign: 'center', fontWeight: 'bold' }}>Montant total frais payer</td>
                                                                <td className="col-md-2"></td>
                                                                <td className="col-md-2"></td>
                                                                <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.state.mntFraisTotalPayer}</td>
                                                                <td className="col-md-2"></td>
                                                                <td className="col-md-1"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table> </Fragment>) : (<p style={{ paddingLeft: '15px' }}>{"L'eléve " + this.state.nomEleve + " a payé la totalité des frais de la formation"}</p>)}
                                        </div>
                                        {this.state.fraisReglEleve.length > 0 ?
                                            (<Fragment>
                                                <div className="row">
                                                    <h3 style={{ paddingLeft: '15px' }}>Mode de paiement :</h3>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                                        <Button style={{ borderRadius: '5px' }} onClick={this.additionLign} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                                    </div>
                                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'center' }} scope="col">Mode paiement</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Montant Paiement</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Numéro cheque</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Echéance cheque</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Date encaissement</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Titulaire cheque</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Banque</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                                            </tr>
                                                        </thead>
                                                        {this.state.tabModPay.map((el, index) =>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="col-md-2"><Select
                                                                        value={el.modPay}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={(e) => this.addModPay(e, index)}
                                                                        options={this.state.listModPay}
                                                                    />  </td>
                                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><input type="number" value={el.mntModPay} onChange={(e) => this.addMntModPay(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} /></td>
                                                                    {this.state.tabModPay[index].modPay.value == 2 ?
                                                                        (<Fragment>
                                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.numCheque} onChange={(e) => this.addNumChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker className="form-control ddate" style={{ border: 'none' }} dateFormat="dd/MM/yyy" selected={el.echeanceChec} onChange={(e) => this.addEcheanceChec(e, index)} /></td>
                                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker className="form-control ddate" style={{ border: 'none' }} dateFormat="dd/MM/yyy" selected={el.dateEncaiss} onChange={(e) => this.addDateEncaiss(e, index)} /></td>
                                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.titulaireChec} onChange={(e) => this.addTitulaireChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.banque} onChange={(e) => this.addBanque(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLign(index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                                        </Fragment>) :
                                                                        (<Fragment>
                                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                            <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} onClick={() => this.toggleLign(index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                                        </Fragment>)}
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                                    <button disabled={!this.state.disbut} style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={() => this.modifRegInscrip()}>Confirmer</button>
                                                </div></Fragment>) : (null)}
                                    </Fragment>) : this.state.initStatus == 1 ? (<p>Veuillez choisir un apprenant</p>) : (<div>
                                        <h3 style={{ paddingLeft: '15px' }}>Paiement frais formation :</h3>
                                        <p style={{ paddingLeft: '15px' }}>{"L'eléve " + this.state.nomEleve + " a payé la totalité des frais"}</p>
                                    </div>)}
                            </div>
                            <Modal isOpen={this.state.modal} toggle={this.nonDeleteLign} className={this.props.className} fade={false}>
                                <ModalHeader toggle={this.nonDeleteLign}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                <ModalBody>
                                    <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <p>Êtes-Vous sûr de vouloir supprimer ce mode de paiement ?</p>
                                        </div>
                                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                            <div><button onClick={() => this.deleteLign()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                            <div><button onClick={() => this.nonDeleteLign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                            <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(ModifReglFraisApp);