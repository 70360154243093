import React, { Component } from "react";
import jQuery from "jquery";
import axios from 'axios'
import Select from "react-select";
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'

class ModifNivNateNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrayData: [],
            listNiveau: [],
            selectedNiveau: '',
            listMat: [],
            selectedMat: '',
            listSousMat: [],
            selectedSousMat: '',
            listNatNote: [],
            selectedNatNote: ''
        };
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
            var niveauId = this.props.location.state.niveauId;
            var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
            var anneeScolaire = userPermissions[0].anneeScolaire;
            jQuery.ajax({
                url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/GetNivNatNoteById.php",
                type: "POST",
                data: {
                    niveauId: niveauId,
                    anneeScolaire: anneeScolaire,
                },
                dataType: "json",
                success: (code_html, statut) => {
                    console.log(code_html);
                    this.setState({
                        selectedNiveau: code_html[0].niveau,
                        arrayData: code_html
                    });
                },
            });
            jQuery.ajax({
                url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/SelectNiveau.php",
                type: "POST",
                data: {
                    anneeScolaire: anneeScolaire,
                },
                dataType: "json",
                success: (code_html, statut) => {
                    console.log(code_html);
                    this.setState({
                        listNiveau: code_html
                    });
                },
            });
            jQuery.ajax({
                url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/SelectMatiereByNiv.php",
                type: "POST",
                data: {
                    niveauId: niveauId,
                    anneeScolaire: anneeScolaire,
                },
                dataType: "json",
                success: (code_html, statut) => {
                    console.log(code_html);
                    this.setState({
                        listMat: code_html
                    });
                },
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectNatNote.php').then(res => {
                console.log(res)
                this.setState({
                    listNatNote: res.data
                })
            })
        }
    }

    modifNiveau = selectedNiveau => {
        var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
        var anneeScolaire = userPermissions[0].anneeScolaire;
        this.setState({ selectedNiveau: selectedNiveau }, () => {
            jQuery.ajax({
                url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/SelectMatiereByNiv.php",
                type: "POST",
                data: {
                    niveauId: selectedNiveau.value,
                    anneeScolaire: anneeScolaire,
                },
                dataType: "json",
                success: (code_html, statut) => {
                    console.log(code_html);
                    this.setState({
                        listMat: code_html
                    });
                },
            });
        });
    };

    modifMat = (selectedMat, index) => {
        // Recuperer les sous matiere 
        jQuery.ajax({
            url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/SelectSousMatByMat.php",
            type: "POST",
            data: {
                matiereId: selectedMat.value
            },
            dataType: "json",
            success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                    listSousMat: code_html
                });
            },
        })
        var arrayData = this.state.arrayData
        arrayData[index].matiere = selectedMat
        this.setState({
            arrayData: arrayData
        });
    };

    modifSousMat = (selectedSousMat, index) => {
        var arrayData = this.state.arrayData
        arrayData[index].sousMat = selectedSousMat
        this.setState({
            arrayData: arrayData
        });
    };

    modifNatNote = (selectedNatNote, index) => {
        var arrayData = this.state.arrayData
        arrayData[index].note = selectedNatNote
        this.setState({
            arrayData: arrayData
        });
    };

    modif = () => {
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdNivNateNote.php',
            type: 'POST',
            data: {
                niveauId: this.state.selectedNiveau.value,
                arrayData: this.state.arrayData
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/NiveauNatureNote');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification niveau nature note</h4>
                        </div>
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/NiveauNatureNote" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                value={this.state.selectedNiveau}
                                                onChange={this.modifNiveau}
                                                options={this.state.listNiveau}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <table className="col-md-12">
                                        <thead>
                                            <th style={{ textAlign: 'center', padding: '5px' }}>Matiere</th>
                                            <th style={{ textAlign: 'center', padding: '5px' }}>Sous-matiére</th>
                                        </thead>
                                        <tbody>
                                            {this.state.arrayData.map((el, index) =>
                                                <tr>
                                                    <td className="col-md-4">
                                                        <div className="form-group">
                                                            <Select
                                                                value={el.matiere}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={(e) => this.modifMat(e, index)}
                                                                options={this.state.listMat}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="col-md-4">
                                                        <div className="form-group">
                                                            <Select
                                                                className="indice"
                                                                value={el.sousMat}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={(e) => this.modifSousMat(e, index)}
                                                                options={this.state.listSousMat}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="col-md-4">
                                                        <div className="form-group">
                                                            <Select
                                                                className="indice"
                                                                value={el.note}
                                                                isMulti={true}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={(e) => this.modifNatNote(e, index)}
                                                                options={this.state.listNatNote}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(ModifNivNateNote);