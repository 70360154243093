import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import Table from "reactstrap/lib/Table";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";

class ResultatBTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listGroupe: [],
      selectGroupe: "",
      releveNote: [],
      blockNote: false,
      filiere: "",
      niveau: "",
      groupe: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetGroupePrBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listGroupe: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addGroupe = (selectGroupe) => {
    if (selectGroupe != null) {
      this.setState({ selectGroupe }, () => { });
    } else {
      this.setState({
        selectGroupe: "",
      });
    }
  };

  affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/ResultatBTSPr.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        groupeId: this.state.selectGroupe.value,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          releveNote: code_html,
          blockNote: true,
        });
      },
    });
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/GetInfoGroupe.php",
      type: "POST",
      data: {
        groupeId: this.state.selectGroupe.value,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          filiere: code_html.nomFiliere,
          niveau: code_html.niveau,
          groupe: code_html.groupe,
        });
      },
    });
  };

  impr = () => {
    var idGroupe = this.state.selectGroupe.value;
    window.open("http://artsmetiers.mtd-app.com/ImprResultatPrBTS?idGroupe=" + idGroupe);
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 style={{ fontWeight: "bold" }} className="page-title">
                Resultat 1ére Année{" "}
              </h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroupe}
                        options={this.state.listGroupe}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Afficher
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={this.impr}
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
                {this.state.blockNote == true ? (
                  <div>
                    <div>
                      <div
                        className="row"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>Resultat 1ére Année</p>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                        className="row"
                      >
                        <label style={{ fontWeight: "bold" }}>
                          de (groupe : {this.state.groupe}) {this.state.niveau}{" "}
                          - Spécialité {this.state.filiere}
                        </label>
                      </div>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Eleve</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.releveNote.map((el, indexOfLigne) => (
                          <tr>
                            <td>{indexOfLigne + 1}</td>
                            <td style={{ width: "20vw" }}>{el.eleve}</td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  {el.allNote.noteEP.map((elem) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <p style={{ width: "15vw" }}>
                                        {" "}
                                        {elem.matiere}{" "}
                                      </p>
                                      <p> {elem.matiereNote} </p>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {el.allNote.noteET.map((elem) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <p style={{ width: "15vw" }}>
                                        {" "}
                                        {elem.matiere}{" "}
                                      </p>
                                      <p> {elem.matiereNote} </p>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {el.allNote.noteEG.map((elem) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <p style={{ width: "15vw" }}>
                                        {" "}
                                        {elem.matiere}{" "}
                                      </p>
                                      <p> {elem.matiereNote} </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </td>
                            <tr
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                fontWeight: "bold",
                              }}
                            >
                              <td> {el.allNote.nameEP} </td>
                              <td> {el.allNote.moyEP} </td>
                              <td> {el.allNote.nameET} </td>
                              <td> {el.allNote.moyET} </td>
                              <td> {el.allNote.nameEG} </td>
                              <td> {el.allNote.moyEG} </td>
                            </tr>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.moyenneGen} </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResultatBTS);
