import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class Rattrapage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listGroupe: [],
      selectGroupe: "",
      releveNote: [],
      blockNote: false,
      releveeByGroupe: [],
      filiere: "",
      niveau: "",
      groupe: "",
      arrayEleve: [],
      modalDelete: false,
      eleveId: "",
      mois: [{ value: 1, label: 'Janvier' }, { value: 2, label: 'Férvier' }, { value: 3, label: 'Mars' }, { value: 4, label: 'Avril' }, { value: 5, label: 'Mai' }, { value: 6, label: 'Juin' }, { value: 7, label: 'Juillet' }, { value: 8, label: 'Aout' }, { value: 9, label: 'Septembre' }, { value: 10, label: 'Octobre' }, { value: 11, label: 'Novembre' }, { value: 12, label: 'Decembre' }],
      selectedMois: '',
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetGroupeNonBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listGroupe: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addGroupe = (selectGroupe) => {
    if (selectGroupe != null) {
      this.setState({ selectGroupe }, () => {});
    } else {
      this.setState({
        selectGroupe: "",
      });
    }
  };
  searchMois = selectedMois => {
    if (selectedMois != null) {
        this.setState({ selectedMois });
    }else{
      selectedMois=''
    }console.log(this.state.selectedMois.value)
    
    
}
addannee = (event) => {
  this.setState({
      annee: event.target.value
  });
}
  affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/Rattrapage.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        groupeId: this.state.selectGroupe.value,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          blockNote: true,
          listEleves: code_html,
        });
      },
    });
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/GetInfoGroupe.php",
      type: "POST",
      data: {
        groupeId: this.state.selectGroupe.value,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          filiere: code_html.nomFiliere,
          niveau: code_html.niveau,
          groupe: code_html.groupe,
        });
      },
    });
  };

  toggleSupp = (id) => {
    this.setState((prevState) => ({
      modalDelete: !prevState.modalDelete,
      eleveId: id,
    }));
  };

  fermerModal = () => {
    this.setState((prevState) => ({
      modalDelete: !prevState.modalDelete,
    }));
  };
  fermerModalR = () => {
    this.setState((prevState) => ({
      modalDelete: !prevState.modalDelete,
    }));
  };

  cocheEleve = (e, index, el) => {
    var eleveId = el.eleveId;
    if (e.target.checked === true) {
      var array = this.state.arrayEleve;
      array.push(eleveId);
      this.setState({
        arrayEleve: array,
      });
    } else {
      var array = this.state.arrayEleve;
      array.splice(index, 2);
      this.setState({
        arrayEleve: array,
      });
    }
  };
  Ratra = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/FiltrersousRattrapage.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        groupeId: this.state.selectGroupe.value,
        eleve: this.state.arrayEleve,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          alert("Filtrer au 2eme ratrapage");
          window.location.reload();
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  Ratrap = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    {
      this.state.selectGroupe.length != 0
        ? jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/Rattrapage_sous.php",
            type: "POST",
            data: {
              anneeScolaire: anneeScolaire,
              groupeId: this.state.selectGroupe.value,
            },
            dataType: "json",
            success: (code_html, statut) => {
              this.setState({
                blockNoteR: true,
                listElevesRatra: code_html,
              });
            },
          })
        : alert(" Choisir Groupe !!!");
    }
  };
  deleteEleve = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/DeleteElvRattrapage.php",
      type: "POST",
      data: {
        eleveId: this.state.eleveId,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modalDelete: !this.state.modalDelete,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Rattrapage.php",
              type: "POST",
              data: {
                anneeScolaire: anneeScolaire,
                groupeId: this.state.selectGroupe.value,
              },
              dataType: "json",
              success: (code_html, statut) => {
                this.setState({
                  blockNote: true,
                  listEleves: code_html,
                });
              },
            });
          }
        );
      },
    });
  };
  deleteEleveRat = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/DeleteElvsousRattrapage.php",
      type: "POST",
      data: {
        eleveId: this.state.eleveId,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modalDelete: !this.state.modalDelete,
          },
          window.location.reload()
        );
      },
    });
  };

  print = () => {
    if(this.state.selectedMois !=''){ 
      var groupeId = this.state.selectGroupe.value;
     
      var mois=this.state.selectedMois.value;
  
      var annee=this.state.annee;
     
      var params = groupeId + '-'+mois + '-' + annee 
    
    window.open(
      "http://artsmetiers.mtd-app.com/ImprRattrapage?params=" + params
    );

  }else{alert("choisir mois et annee")}
};
  printR = () => {
    var groupeId = this.state.selectGroupe.value;
    window.open("http://artsmetiers.mtd-app.com/ImprSouRattrapage?groupeId=" + groupeId);
    //window.open("http://localhost:3000/ImprSouRattrapage?groupeId=" + groupeId);
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Rattrapage </h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroupe}
                        options={this.state.listGroupe}
                      />
                    </div>
                    <div className="col-md-2" style={{paddingLeft:"40px" }}>
                                            
                                            <label>Mois</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.searchMois(e)}
                                                options={this.state.mois}
                                            />
                                        
                                    </div>
                                     <div className="col-md-2" style={{paddingLeft:"40px"}}>
                                        <label>Annee</label>
                                     <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" className="form-control" placeholder='annee' onChange={(e) => this.addannee(e)} />
                                        
                                    </div>
              
              
            
                  
                  <div></div>
                  <div style={{ display: "flex", justifyContent: "flex-end",paddingTop:"10px" }}>
                    <div style={{paddingTop:"10px"}}>
                      <Button
                        color="warning"
                        style={{
                          borderRadius: "25px",
                          marginTop: "15px",
                        }}
                        onClick={this.Ratrap}
                      >
                        2éme Rattrapage
                      </Button>
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.print}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
                {this.state.blockNote == true ? (
                  <div>
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                        className="row"
                      >
                        <label>Rattrapage</label>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            style={{ display: "flex" }}
                            className="form-group"
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Spécialité :{" "}
                            </label>
                            <label style={{ paddingLeft: "5px" }}>
                              {" "}
                              {this.state.filiere}{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            style={{ display: "flex" }}
                            className="form-group"
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Niveau :{" "}
                            </label>
                            <label style={{ paddingLeft: "5px" }}>
                              {this.state.niveau}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            style={{ display: "flex" }}
                            className="form-group"
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Groupe :
                            </label>
                            <label style={{ paddingLeft: "5px" }}>
                              {" "}
                              {this.state.groupe}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            N°{" "}
                          </th>
                          <th style={{ textAlign: "left", color: "#ac3939" }}>
                            {" "}
                            Apprenant{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Trimestre 1{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Trimestre 2{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Trimestre 3{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Moy.Annuelle{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Examen fin d'année{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Stage{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Moy.Admission{" "}
                          </th>
                          {/* <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Rang{" "}
                          </th> */}
                          <th>2éme.Rat</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listEleves.map(
                          (el, indexOfLigne, index) => (
                            <tr>
                              <td>{indexOfLigne + 1}</td>
                              <td> {el.nomEleve} </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.tr1}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.tr2}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.tr3}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {el.moyAnnuelle}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.examen}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.stage}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.moyenne}
                                </p>
                              </td>
                              {/* <td style={{ textAlign: "center" }}>
                                {indexOfLigne + 1}
                              </td> */}
                              <td>
                                <Checkbox
                                  onChange={(e) =>
                                    this.cocheEleve(e, index, el)
                                  }
                                  disabled={this.state.disabled}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <i
                                  onClick={() => this.toggleSupp(el.eleveId)}
                                  style={{
                                    cursor: "pointer",
                                    color: "#ac3939",
                                    fontSize: "20px",
                                  }}
                                  class="fas fa-trash"
                                ></i>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        style={{ borderRadius: "5px" }}
                        type="button"
                        className="btn btn-success"
                        onClick={this.Ratra}
                      >
                        Ajout au 2éme Ratrappage
                      </button>
                    </div>
                  </div>
                ) : null}
                <Modal
                  isOpen={this.state.modalDelete}
                  toggle={this.fermerModal}
                  className={this.props.className}
                  fade={false}
                >
                  <ModalHeader toggle={this.fermerModal}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "465px",
                      }}
                    >
                      <img
                        width="30%"
                        src="./images/deleteModal.png"
                        alt=".."
                      />
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <div
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        FontSize: "14px",
                        FontWeight: "700",
                        LineHeight: "18.375px",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>
                          Êtes-Vous sûr de vouloir supprimer cet Apprenant ?
                        </p>
                      </div>
                      <div
                        className="hvr-push"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <button
                            onClick={() => this.deleteEleve()}
                            style={{
                              backgroundColor: "#00FF7F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-up"
                            ></i>
                            Oui
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={() => this.fermerModal()}
                            style={{
                              backgroundColor: "#D9534F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-down"
                            ></i>
                            Non
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div></div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                ></div>
                {this.state.blockNoteR == true ? (
                  <div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                        className="row"
                      >
                        <div>
                          <label> 2éme Filtrage Rattrapage </label>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                     {/*  <button
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#761C19",
                            borderColor: "#761C19",
                          }}
                          type="button"
                          className="btn btn-success"
                          onClick={this.printR}
                        >
                          <i
                            style={{ color: "white", paddingRight: "5px" }}
                            class="fas fa-print"
                          ></i>
                          Impression
                        </button>*/}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            style={{ display: "flex" }}
                            className="form-group"
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Spécialité :{" "}
                            </label>
                            <label style={{ paddingLeft: "5px" }}>
                              {" "}
                              {this.state.filiere}{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            style={{ display: "flex" }}
                            className="form-group"
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Niveau :{" "}
                            </label>
                            <label style={{ paddingLeft: "5px" }}>
                              {this.state.niveau}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            style={{ display: "flex" }}
                            className="form-group"
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Groupe :
                            </label>
                            <label style={{ paddingLeft: "5px" }}>
                              {" "}
                              {this.state.groupe}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            N°{" "}
                          </th>
                          <th style={{ textAlign: "left", color: "#ac3939" }}>
                            {" "}
                            Apprenant{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Trimestre 1{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Trimestre 2{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Trimestre 3{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Moy.Annuelle{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Examen fin d'année{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Stage{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Moy.Admission{" "}
                          </th>
                          {/* <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Rang{" "}
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listElevesRatra.map(
                          (ele, indexOfLigne, ind) => (
                            <tr>
                              <td>{indexOfLigne + 1}</td>
                              <td> {ele.nomEleve} </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {ele.tr1}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {ele.tr2}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {ele.tr3}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {ele.moyAnnuelle}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {ele.examen}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {ele.stage}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {ele.moyenne}
                                </p>
                              </td>
                              {/* <td style={{ textAlign: "center" }}>
                                {indexOfLigne + 1}
                              </td> */}

                              <td style={{ textAlign: "center" }}>
                                <i
                                  onClick={() => this.toggleSupp(ele.eleveId)}
                                  style={{
                                    cursor: "pointer",
                                    color: "#ac3939",
                                    fontSize: "20px",
                                  }}
                                  class="fas fa-trash"
                                ></i>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
                <Modal
                  isOpen={this.state.modalDelete}
                  toggle={this.fermerModalR}
                  className={this.props.className}
                  fade={false}
                >
                  <ModalHeader toggle={this.fermerModalR}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "465px",
                      }}
                    >
                      <img
                        width="30%"
                        src="./images/deleteModal.png"
                        alt=".."
                      />
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <div
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        FontSize: "14px",
                        FontWeight: "700",
                        LineHeight: "18.375px",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>
                          Êtes-Vous sûr de vouloir supprimer cet Apprenant ?
                        </p>
                      </div>
                      <div
                        className="hvr-push"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <button
                            onClick={() => this.deleteEleveRat()}
                            style={{
                              backgroundColor: "#00FF7F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-up"
                            ></i>
                            Oui
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={() => this.fermerModalR()}
                            style={{
                              backgroundColor: "#D9534F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-down"
                            ></i>
                            Non
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Rattrapage);
