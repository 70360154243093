import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { RadioGroup, Radio } from 'react-radio-group'

class ConfirmationCycle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCycle: '',
            nomCycle: '',
            nbrHeure: '',
            tarifCycle: '',
            tarifAvecCarte: '',
            selectedType: '',
            anneScol: '',
            enseignants: [],
            arrayCycle: [],
            users:'',
            indexLignEnseignASupprimer: '',
            indexSousLignEnseignASupprimer: '',
            modalEnseign: false,
            listChoix: ['Oui', 'Non'],
            idDetCycleSup: '',
            tabDeleteIdDetCycle: [],
            selectedforcycles:'',
         
            typeForCycle: '',
            forcycle:0,
            listtype: [
                {value:0 , label:"Forfait"},
                  {value:1 ,label:"Heure"}
            ]
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
         
            this.state.users=userPermissions[0].idUser
           
            var idCycle = this.props.location.state.idCycle;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetCycleModifById.php?id=' + idCycle).then(res => {
                console.log(res)
                this.setState({
                    idCycle: idCycle,
                    nomCycle: res.data[0].cycle,
                    typeForCycle: res.data[0].typeEduc,
                    nbrHeure: res.data[0].heure,
                    tarifCycle: res.data[0].tarif,
                    tarifAvecCarte: res.data[0].carte,
                    arrayCycle: res.data[0].detailCycle,
                    //SelectedForcycle: res.data[0].type
                })
            })
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetAnneeScolaire.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        anneScol: code_html.scolaire
                    });
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectCours.php').then(res => {
                console.log(res)
                this.setState({
                    cours: res.data
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEnseignants.php').then(res => {
                console.log(res)
                this.setState({
                    enseignants: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }
    addforfait = (selectedforcycle,
        index,indice) => {
        if (selectedforcycle != null) {
            var z = this.state.arrayCycle[index].infoEnseignant
    
            var x = selectedforcycle
            z: z.map((el, id) => (id === indice ? Object.assign(el, { selectedforcycle: x }) : el))
            this.setState({
                arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, {infoEnseignant: z }) : el))
            });
        }
        else {
           var z= this.state.arrayCycle[index].infoEnseignant
            var x = { value: '', label: '' }
            z: z.map((el, id) => (id === indice ? Object.assign(el, { selectedforcycle: x }) : el))
            this.setState({
                arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
            });
        }
          
    }
    
    addChoix = (e, index) => {
        let choix = e;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { selectedChoix: choix }) : el))
        });
    }
    
    addEnseignent = (selectedEnseignent, index, indice) => {
        if (selectedEnseignent != null) {
            var z = this.state.arrayCycle[index].infoEnseignant
            var x = selectedEnseignent
            z: z.map((el, id) => (id === indice ? Object.assign(el, { selectedEnseignent: x }) : el))
            this.setState({
                arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
            });
        }
        else {
            var z = this.state.arrayCycle[index].infoEnseignant
            var x = { value: '', label: '' }
            z: z.map((el, id) => (id === indice ? Object.assign(el, { selectedEnseignent: x }) : el))
            this.setState({
                arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
            });
        }
    }

    addNbrHeureParEnseign = (e, index, indice) => {
        var z = this.state.arrayCycle[index].infoEnseignant
        var x = e.target.value
        z: z.map((el, id) => (id === indice ? Object.assign(el, { nbrHeureParEnseign: x }) : el))
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
        });
    }

    updHonoraireParEnseign = (e, index, indice) => {
        var z = this.state.arrayCycle[index].infoEnseignant
        var x = e.target.value
        z: z.map((el, id) => (id === indice ? Object.assign(el, { honoraireParEnseign: x }) : el))
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
        });
    }

    additionEnseignant = (index) => {
        var x = this.state.arrayCycle[index].infoEnseignant
        let element = { selectedEnseignent: "",selectedforcycle:"", nbrHeureParEnseign: 0, honoraireParEnseign: 0.000, ancLignEnseign: -1 }
        x.push(element)
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: x }) : el))
        });
    }
    
    toggleLignEnseign = (index, indice, idDetCycle) => {
        this.setState(prevState => ({
            modalEnseign: !prevState.modalEnseign,
            indexLignEnseignASupprimer: index,
            indexSousLignEnseignASupprimer: indice,
            idDetCycleSup: idDetCycle
        }));
    }
    
    deleteLignEnseign = () => {
        this.state.arrayCycle[this.state.indexLignEnseignASupprimer].infoEnseignant.splice(this.state.indexSousLignEnseignASupprimer, 1)
        
        let idDetCycleSup = this.state.idDetCycleSup
        this.setState({
            tabDeleteIdDetCycle: [...this.state.tabDeleteIdDetCycle, idDetCycleSup]
        });
        this.setState(prevState => ({
            modalEnseign: !prevState.modalEnseign
        }));
    }

    nonDeleteLignEnseign = () => {
        this.setState(prevState => ({
            modalEnseign: !prevState.modalEnseign
        }));
    }

    modif = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConfirmationCycle.php?id=' + this.state.idCycle,
            type: 'POST',
            data: {
                arrayCycle: this.state.arrayCycle,
                tabDeleteIdDetCycle: this.state.tabDeleteIdDetCycle,
                userId: userId,
                anneeScolaire: anneeScolaire,
                roleId :this.state.users
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Cycles');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }
    
    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Confirmation cycle</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Cycles" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Cycle</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" disabled className="form-control" value={this.state.nomCycle} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Heure</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" disabled className="form-control" value={this.state.nbrHeure} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tarif cycle</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" disabled className="form-control" value={this.state.tarifCycle} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tarif avec carte</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" disabled className="form-control" value={this.state.tarifAvecCarte} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Type Formation</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" disabled className="form-control" value={this.state.typeForCycle} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3 style={{ paddingLeft: '15px' }}>{'Planning Cycle Année Scolaire ' + this.state.anneScol}</h3>
                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Mois</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Date Debut</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Date Fin</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Horraires</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Confirmation</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Enseignent</th>
                                            </tr>
                                        </thead>
                                        {this.state.arrayCycle.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}>{el.mois.label}</td>
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}>{el.dateD}</td>
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}>{el.dateF}</td>
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}>{el.horaire.label}</td>
                                                    <td className="col-md-1">
                                                        <RadioGroup
                                                            name={"type" + index}
                                                            selectedValue={el.selectedChoix}
                                                            onChange={(e) => this.addChoix(e, index)}>
                                                            {this.state.listChoix.map(elm =>
                                                                <label style={{ paddingLeft: '5px' }}>
                                                                    <Radio value={elm} />{elm}
                                                                </label>
                                                            )}
                                                        </RadioGroup>
                                                    </td>
                                                    {this.state.arrayCycle[index].selectedChoix == "Oui" ?
                                                        (<Fragment>
                                                            <td className="col-md-7" style={{ textAlign: 'center' }}>
                                                                <div>
                                                                    <table className="table table-bordered">
                                                                        <thead >
                                                        
                                                                            <tr>
                                                                                <th style={{ textAlign: 'center' }} scope="col">Prof</th>
                                                                                {this.state.users== 1?(
                                                                                <th style={{ textAlign: 'center' }} scope="col">Type</th>
                                                                                ):(null)}
                                                                                <th style={{ textAlign: 'center' }} scope="col">Nbr-heure</th>
                                                                                <th style={{ textAlign: 'center' }} scope="col">Honoraire</th>
                                                                                <th style={{ textAlign: 'center' }} scope="col">Supp</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {el.infoEnseignant.map((ele, indice) =>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="col-md-4">
                                                                                    
                                                                                        <div>
                                                                                            
                                                                                            <Select
                                                                                                value={ele.selectedEnseignent}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                onChange={(e) => this.addEnseignent(e, index, indice)}
                                                                                                options={this.state.enseignants}
                                                                                            />
                                                                                            
                                                                                        </div>
                                                                                    </td>
                                                                                    {this.state.users== 1?(
                                                                                    <td> 
                                                                                    
                                                                                    <Select 
                                                                                                value={ele.selectedforcycle}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                onChange={(e) => this.addforfait(e, index,indice)}
                                                                                                options={this.state.listtype}
                                                                                            /> 
                         
                                                                                    </td>):null}
                                                                                    
                                                                                    <td className="col-md-3">
                                                                                        <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} value={ele.nbrHeureParEnseign} type="number" className="form-control" onChange={(e) => this.addNbrHeureParEnseign(e, index, indice)} />
                                                                                    </td>
                                                                                    <td className="col-md-3">
                                                                                        <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} value={Number.parseFloat(ele.honoraireParEnseign).toFixed(3)} type="number" className="form-control" onChange={(e) => this.updHonoraireParEnseign(e, index, indice)} />
                                                                                    </td>
                                                                                    <td className="col-md-3">
                                                                                        <button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLignEnseign(index, indice, ele.idDetCycle)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        )}
                                                                    </table>
                                                                </div>
                                                        Ajout
                                                        <i onClick={() => this.additionEnseignant(index)} className="fas fa-plus-circle"></i>
                                                            </td>
                                                        </Fragment>)
                                                        : (<Fragment>
                                                            <td>
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </Fragment>)}
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {// Suppression enseignant
                        <Modal isOpen={this.state.modalEnseign} toggle={this.nonDeleteLignEnseign} className={this.props.className} fade={false}>
                            <ModalHeader toggle={this.nonDeleteLignEnseign}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                            <ModalBody>
                                <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                                    </div>
                                    <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                        <div><button onClick={() => this.deleteLignEnseign()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                        <div><button onClick={() => this.nonDeleteLignEnseign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                        //
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(ConfirmationCycle);