import React, { Component } from "react";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

class ListGroupe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "N° d'inscription",
          selector: "insId",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "Groupe",
          selector: "groupe",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "Apprenant",
          selector: "nomEleve",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },

        {
          name: "Ajouter",
          cell: (row) => (
            <Checkbox
              onChange={(e) => this.cocheEleve(e, row.index, row.eleveId)}
              disabled={this.state.disabled}
            />
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      pending: true,
      disabled: false,
      listGroupe: [],
      selectGroupe: "",
      listG: [
        { value: 1, label: "G1" },
        { value: 2, label: "G2" },
        { value: 3, label: "G3" },
        { value: 4, label: "G4" },
        { value: 5, label: "G5" },
        { value: 6, label: "G6" },
        { value: 7, label: "G IFORMATIQUE 1" },
        { value: 8, label: "G IFORMATIQUE 2" },
        { value: 9, label: "G IFORMATIQUE 3" },
      ],
      selectG: "",
      arrayEleveGroupe: [],
      block: false,
    };
    // this.newElement = this.newElement.bind(this);
    // this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetGroupe.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listGroupe: code_html,
          });
        },
      });
    }
  }

  addGroupe = (selectGroupe) => {
    if (selectGroupe != null) {
      this.setState({ selectGroupe });
    } else {
      this.setState({
        selectGroupe: "",
      });
    }
  };

  addG = (selectG) => {
    if (selectG != null) {
      this.setState({ selectG });
    } else {
      this.setState({
        selectG: "",
      });
    }
  };

  cocheEleve = (e, index, eleveId) => {
    if (e.target.checked === true) {
      var array = this.state.arrayEleveGroupe;
      array.push(eleveId);
      this.setState({
        arrayEleveGroupe: array,
      });
    } else {
      var array = this.state.arrayEleveGroupe;
      array.splice(index, 1);
      this.setState({
        arrayEleveGroupe: array,
      });
    }
  };


  affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/ListGroupe.php",
      type: "POST",
      data: {
        groupeId: this.state.selectGroupe,
        anneeScolaire: anneeScolaire,
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
          block: true,
        });
      },
    });
  };

  ajoutListGroupe = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/AjoutListGroupe.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        selectG : this.state.selectG.value,
        groupe : this .state.selectGroupe,
        eleve : this.state.arrayEleveGroupe

      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/ConsulterListGroupe");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };


  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">List Groupe</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="mb-0 form-group">
                  <div style={{display:'flex',justifyContent:'center'}} className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Groupe</label>
                        <Select
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addGroupe}
                          options={this.state.listGroupe}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Rechercher
                    </button>
                  </div>
                </div>
                {this.state.block === true ? (
                  <div>
                    {" "}
                    <DataTable
                      columns={this.state.columns}
                      data={this.state.data}
                      highlightOnHover={true}
                    //   pagination={true}
                    //   paginationServer={true}
                    //   onChangePage={(page, totalRows) =>
                    //     this.newElement(page, totalRows)
                    //   }
                    //   paginationTotalRows={this.state.nombreTotalElement}
                    //   paginationPerPage={this.state.nbrEltperPage}
                    //   paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                    //   onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    //     this.perPage(currentRowsPerPage, currentPage)
                    //   }
                    //   paginationResetDefaultPage={this.state.resetFirstPage}
                    //   paginationComponentOptions={{
                    //     rowsPerPageText: "Elements par page:",
                    //     rangeSeparatorText: "de",
                    //     noRowsPerPage: false,
                    //   }}
                    //   progressPending={this.state.pending}
                    />
                    <div style={{display:'flex',justifyContent:'center'}} className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>N° Groupe</label>
                          <Select
                            isClearable={true}
                            isSearchable={true}
                            onChange={this.addG}
                            options={this.state.listG}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}} className="row">
                      <button
                        style={{ borderRadius: "5px"}}
                        type="button"
                        className="btn btn-success"
                        onClick={this.ajoutListGroupe}
                      >
                        Confirmer
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ListGroupe);
