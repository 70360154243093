import React, { Component, Fragment } from 'react'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class AjoutSeance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listFormation: [],
            selectedFormation: '',
            listMatiere: [],
            selectedMatiere: '',
            listEnseignant: [],
            selectedEnseignant: '',
            listGroupe: [],
            selectedGroupe: '',
            dateSeance: new Date(),
            heureDebut: new Date(),
            heureFin: new Date(),
            listEleve: [],
            block: false,
            blockEleve: false,
            disabled: false
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectGroupes.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listGroupe: code_html
                    });
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    choixGroupe = selectedGroupe => {
        if (selectedGroupe != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            this.setState({ selectedGroupe }, () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectMatiereByGroup.php',
                    type: 'POST',
                    data: {
                        groupId: selectedGroupe.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listMatiere: code_html
                        });
                    }
                });
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetEleveByGroup.php',
                    type: 'POST',
                    data: {
                        groupId: selectedGroupe.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listEleve: code_html
                        });
                    }
                });
            })
        }
    }

    choixMatiere = selectedMatiere => {
        if (selectedMatiere != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            this.setState({ selectedMatiere }, () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEnsByMatiere.php',
                    type: 'POST',
                    data: {
                        matiereId: selectedMatiere.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listEnseignant: code_html
                        });
                    }
                });
            })
        }
    };

    choixEnseignant = selectedEnseignant => {
        if (selectedEnseignant != null) {
            this.setState({
                selectedEnseignant,
                block: true,
                blockEleve: true
            })
        }
    };

    addDateSeance = d => {
        this.setState({
            dateSeance: d
        });
    }

    addEtatTous = (e) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked == true) {
            this.setState({
                blockEleve: false
            }, () => {
                for (var i = 0; i < this.state.listEleve.length; i++) {
                    this.setState({
                        listEleve: this.state.listEleve.map((el, id) => (id === i ? Object.assign(el, { etat: 0 }) : el))
                    })
                }
            });
        }
        else {
            this.setState({
                blockEleve: true
            });
        }
    }

    addEtat = (event, index) => {
        if (event.target.checked == true) {
            let etat = 1
            this.setState({
                listEleve: this.state.listEleve.map((el, id) => (id === index ? Object.assign(el, { etat: etat }) : el))
            })
        }
        else {
            this.setState({
                listEleve: this.state.listEleve.map((el, id) => (id === index ? Object.assign(el, { etat: 0 }) : el))
            })
        }
    }

    addDateDebutSeance = d => {
        this.setState({
            heureDebut: d
        });
    }

    addDateFinSeance = d => {
        this.setState({
            heureFin: d
        });
    }

    toggle = () => {
        this.setState({
            listEleve: this.state.listEleve.map((el) => (Object.assign(el, { etat: !el.etat })))
        })
    }

    ajout = () => {
        var beginSeance = this.state.heureDebut.getTime() / 1000;
        var endSeance = this.state.heureFin.getTime() / 1000;
        if (endSeance > beginSeance) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            var userId = userPermissions[0].idUser
            // convert date seance
            let dateSeance = this.state.dateSeance
            let month = '' + (dateSeance.getMonth() + 1);
            let day = '' + dateSeance.getDate();
            let year = dateSeance.getFullYear();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            var convertDateSeance = [year, month, day].join('-');
            //**** Date debut *******/
            // convert heure debut pour hd
            var hd = this.state.heureDebut.toTimeString();
            hd = hd.split(' ')[0];
            // convert heure debut => ymd hms
            let date_obOne = this.state.heureDebut;
            let dateOne = ("0" + date_obOne.getDate()).slice(-2);
            let monthOne = ("0" + (date_obOne.getMonth() + 1)).slice(-2);
            let yearOne = date_obOne.getFullYear();
            let hoursOne = date_obOne.getHours();
            let minutesOne = date_obOne.getMinutes();
            let secondsOne = date_obOne.getSeconds();
            var dateDebut = convertDateSeance + " " + hoursOne + ":" + minutesOne + ":" + secondsOne;
            //**** Date fin *******/
            // convert heure fin pour hf 
            var hf = this.state.heureFin.toTimeString();
            hf = hf.split(' ')[0];
            // convert heure fin => ymd hms
            let date_obTwo = this.state.heureFin;
            let dateTwo = ("0" + date_obTwo.getDate()).slice(-2);
            let monthTwo = ("0" + (date_obTwo.getMonth() + 1)).slice(-2);
            let yearTwo = date_obTwo.getFullYear();
            let hoursTwo = date_obTwo.getHours();
            let minutesTwo = date_obTwo.getMinutes();
            let secondsTwo = date_obTwo.getSeconds();
            var dateFin = convertDateSeance + " " + hoursTwo + ":" + minutesTwo + ":" + secondsTwo;
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddAbsence.php',
                type: 'POST',
                data: {
                    date: convertDateSeance,
                    departSeance: hd,
                    dateDebut: dateDebut,
                    finSeance: hf,
                    dateFin: dateFin,
                    groupId: this.state.selectedGroupe.value,
                    matiereId: this.state.selectedMatiere.value,
                    ensId: this.state.selectedEnseignant.value,
                    eleve: this.state.listEleve,
                    anneeScolaire: anneeScolaire,
                    userId: userId
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    if (code_html.Result == "OK") {
                        this.props.history.push('/Absence');
                    }
                    else if (code_html.Result == "KO") {
                        alert("erreur,l'un de vos champs est vide!!");
                    }
                }
            });
        }
        else {
            toast.error('⛔ veuillez verifier la date de debut et fin de la séance !!', { containerId: 'A' });
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout séance</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Absence" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Groupe</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.choixGroupe}
                                                options={this.state.listGroupe}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Matiére</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.choixMatiere}
                                                options={this.state.listMatiere}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Enseignant</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.choixEnseignant}
                                                options={this.state.listEnseignant}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date séance</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.dateSeance}
                                                onChange={this.addDateSeance}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Depart séance</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.heureDebut}
                                                onChange={this.addDateDebutSeance}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="H:mm aa"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Fin séance</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.heureFin}
                                                onChange={this.addDateFinSeance}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="H:mm aa"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {this.state.block == true ?
                                    (
                                        <Fragment>
                                            {this.state.listEleve.length > 0 ?
                                                (<Fragment>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label style={{ paddingRight: '10px' }}>Pas d'absence</label>
                                                            <Checkbox
                                                                onChange={(e) => this.addEtatTous(e)}
                                                                disabled={this.state.disabled}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <button style={{ backgroundColor: '#337AB7', borderColor: '#337AB7', color: 'white' }} onClick={this.toggle}>Tous absents</button>
                                                        </div>
                                                    </div>
                                                </Fragment>) : (null)}
                                            {this.state.blockEleve == true ?
                                                (<div className="row">
                                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'center' }} scope="col">Prenom</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Assiduité</th>
                                                            </tr>
                                                        </thead>
                                                        {this.state.listEleve.map((el, index) =>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.prenom}</p></td>
                                                                    <td style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.nom}</p></td>
                                                                    <td style={{ textAlign: 'center' }}> <label style={{ paddingRight: '10px' }}> Absent</label>
                                                                        <Checkbox
                                                                            onChange={(e) => this.addEtat(e, index)}
                                                                            disabled={this.state.disabled}
                                                                            checked={el.etat}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </div>) : (null)}
                                        </Fragment>) : (null)}
                                <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                        <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AjoutSeance);