import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class EnseignantsGroupes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nomEnseignant: '',
            listGroupes: [],
            selectedGroup: '',
            listMatieres: [],
            selectedMatiere: '',
            enseignGroup: [{ idEnseignGroup: -1, group: '', matiere: '' }],
            indexEnseignGroupASupprimer: '',
            idEnseignGroupASupprimer: '',
            tabIdEnseignGroupASupprimer: [],
            modal: false,
            idEnseignant: '',
            anneeScolaire: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            let idEnseignant = this.props.location.state.idEnseignant;
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetEnseignGroupByEnseignId.php?id=' + idEnseignant,
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        nomEnseignant: code_html[0].nomEnseignant,
                        enseignGroup: code_html[1],
                        idEnseignant: idEnseignant,
                        anneeScolaire: anneeScolaire
                    })
                }
            });
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectGroupes.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listGroupes: code_html
                    })
                }
            });
        }
    }

    additionLign = () => {
        let element = { idEnseignGroup: -1, group: '', matiere: '' }
        this.setState({
            enseignGroup: [...this.state.enseignGroup, element]
        })
    }

    updGroup = (selectedGroup, indexGroup) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        let group = selectedGroup;
        this.setState({
            enseignGroup: this.state.enseignGroup.map((el, id) => (id === indexGroup ? Object.assign(el, { group: group }) : el))
        }, () => {
            if (group != null) {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectMatiereSelonGroup.php',
                    type: 'POST',
                    data: {
                        groupId: group.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listMatieres: code_html
                        })
                    }
                });
            }
        });
    }

    updMatiere = (selectedMatiere, indexMatiere) => {
        let matiere = selectedMatiere;
        this.setState({
            enseignGroup: this.state.enseignGroup.map((el, id) => (id === indexMatiere ? Object.assign(el, { matiere: matiere }) : el))
        });
    }

    toggleEnseignGroup = (idEnseignGroup, indexEnseignGroup) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            idEnseignGroupASupprimer: idEnseignGroup,
            indexEnseignGroupASupprimer: indexEnseignGroup
        }));
    }

    deleteEnseignGroup = () => {
        let idEnseignGroupASupprimer = this.state.idEnseignGroupASupprimer
        this.setState({
            tabIdEnseignGroupASupprimer: [...this.state.tabIdEnseignGroupASupprimer, idEnseignGroupASupprimer]
        });
        this.state.enseignGroup.splice(this.state.indexEnseignGroupASupprimer, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteEnseignGroup = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    modif = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdEnseignGroup.php',
            type: 'POST',
            data: {
                enseignGroup: this.state.enseignGroup,
                year: anneeScolaire,
                idEnseignGroupDelete: this.state.tabIdEnseignGroupASupprimer,
                idEnseignant: this.state.idEnseignant,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Enseignants');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title"> {"Groupes par enseignant: " + this.state.nomEnseignant}</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Enseignants" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                {/* {this.state.enseignGroup.length < 2 ? (<p>{this.state.nomEnseignant + "n'a pas de groupe en " + this.state.anneeScolaire}</p>) : (null)} */}
                                <div className="row">
                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Groupe</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Matiére</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                            </tr>
                                        </thead>
                                        {this.state.enseignGroup.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-6"><Select
                                                        value={el.group}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.updGroup(e, index)}
                                                        options={this.state.listGroupes}
                                                    />  </td>
                                                    <td className="col-md-6"><Select
                                                        value={el.matiere}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.updMatiere(e, index)}
                                                        options={this.state.listMatieres}
                                                    />  </td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleEnseignGroup(el.idEnseignGroup, index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                        <Button style={{ borderRadius: '5px' }} onClick={this.additionLign} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                    </div>
                                </div>
                                <Modal isOpen={this.state.modal} toggle={this.nonDeleteEnseignGroup} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteEnseignGroup}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteEnseignGroup()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteEnseignGroup()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EnseignantsGroupes);