import React, { Component } from 'react';
import { Link, BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import axios from 'axios'
import jQuery from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import Select from 'react-select';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userPasse: '',
            anneeScolaire: [],
            selectedAnneeScolaire: null
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectAnneeScolaire.php').then(res => {
            console.log(res)
            this.setState({
                anneeScolaire: res.data
            })
        })
        // Recuperer l'année scolaire récente
        axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetAnneeScolRecente.php').then(res => {
            console.log(res)
            this.setState({
                selectedAnneeScolaire: res.data
            })
        })
    }

    changeusername = (event) => {
        this.setState({
            userName: event.target.value
        });
    }

    changeuserpasse = (event) => {
        this.setState({
            userPasse: event.target.value
        });
    }

    addAnneeScolaire = selectedAnneeScolaire => {
        this.setState({ selectedAnneeScolaire })
    };

    connect = () => {
        if (this.state.selectedAnneeScolaire != null) {
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConnectUtilisateur.php',
                type: 'POST',
                data: {
                    userName: this.state.userName,
                    userPasse: this.state.userPasse,
                    anneeScolaire: this.state.selectedAnneeScolaire.value
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    if (code_html[0].connexion === "OK") {
                        localStorage.setItem('userPermissions', JSON.stringify(code_html));
                        toast.success('✅ Bienvenue dans Arts et Métiers !', { containerId: 'A' });
                        // Recuperer idUser et ajouter le moment de connection de l'utilisateur
                        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                        var userId = userPermissions[0].idUser
                        jQuery.ajax({
                            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddDateConnexion.php',
                            type: 'POST',
                            data: {
                                userId: userId
                            },
                            dataType: 'json',
                            success: (code_html, statut) => {
                                if (code_html.Result == "OK") {
                                    document.location = "/Menu"
                                }
                            }
                        });
                    }
                    else if (code_html.connection === "KO") {
                        toast.error('⛔ Veuillez vérifier votre login et mot de passe !', { containerId: 'A' });
                    }
                }
            });
        }
        else {
            toast.error('⛔ Veuillez choisir une année scolaire !', { containerId: 'A' });
        }
    }

    render() {
        return (
            <section id="wrapper" className="login-register">
                <div style={{ background: '#fff', position: 'absolute', width: '420px', marginLeft: '35%', marginTop: '5%', height: '53px' }}>
                    <div className="white-box">
                        <form className="form-horizontal m-t-20" action="index.html">
                            <div className="form-group ">
                                <div style={{ display: 'flex', justifyContent: 'center' }} className="col-xs-12">
                                    <img className="rounded" src="images/art_metier.png" alt="Media" />
                                </div>
                            </div>
                            <div className="form-group ">
                                <div style={{ display: 'flex', alignItems: 'center' }} className="col-xs-12">
                                    <div style={{ backgroundColor: '#338CA0', height: '38px', display: 'flex', alignItems: 'center', width: '25px', justifyContent: 'center' }}>
                                        <i style={{ color: 'white' }} className="fas fa-envelope-square"></i>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <input style={{ borderColor: '#006F88' }} className="form-control" type="text" required placeholder="Login" onChange={this.changeusername} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div style={{ display: 'flex', alignItems: 'center' }} className="col-xs-12">
                                    <div style={{ backgroundColor: '#338CA0', height: '38px', display: 'flex', alignItems: 'center', width: '25px', justifyContent: 'center' }}>
                                        <i style={{ color: 'white' }} className="fas fa-lock"></i>
                                    </div>
                                    <div style={{ width: '100%' }}>
                                        <input style={{ borderColor: '#006F88' }} className="form-control" type="password" required placeholder="Password" onChange={this.changeuserpasse} />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                                    <label>Année scolaire</label>
                                    <Select
                                        value={this.state.selectedAnneeScolaire}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.addAnneeScolaire}
                                        options={this.state.anneeScolaire}
                                    />
                                </div>
                            </div>
                            <div className="form-group text-center m-t-40">
                                <div className="col-xs-12">
                                    <button onClick={this.connect} style={{ width: '40%', backgroundColor: '#006F88', borderColor: '#006F88', fontSize: '16px', borderRadius: '5px', marginTop: '-25px' }} className="btn btn-success btn-lg btn-block text-uppercase waves-effect waves-light" type="button">Connexion</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
            </section>
        );
    }
}
export default Login;