import React, { Component, Fragment } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios'
import jQuery from 'jquery'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class ConseilAssistance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Date',
                    selector: 'date',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Enseignant',
                    selector: 'enseignant',
                    sortable: false,
                    center: true,
                    style: { width: '30%' }
                },
                {
                    name: 'Service',
                    cell: row => <div onClick={() => this.toggleConseilAssist(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#FFB6C1', fontSize: '20px' }} className="fas fa-info"></i></div><div style={{ marginLeft: '5px' }}></div>Description</div>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: "Nombres d'heure",
                    selector: 'nbrHeure',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: "Type",
                    selector: 'type',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: "Montant",
                    selector: 'mnt',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Modification',
                    cell: row => <Link to={{
                        pathname: '/ModifConseilAssist',
                        state: { idConseilAssist: row.idConseilAssist }
                    }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Suppression',
                    cell: row => <button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggletwo(row.idConseilAssist)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                }
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            modal: false,
            pending: true,
            modalConseilAssist: false,
            idConseilAssist: '',
            service: '',
            // search
            enseignant: [],
            selectedEnseignant: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConseilAssist.php',
                type: 'POST',
                data: {
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEnsConseilAssist.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        enseignant: code_html
                    })
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    newElement = (page, totalRows) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedEnseignant != '') {
            var ensId = this.state.selectedEnseignant.value
        }
        else {
            var ensId = ''
        }
        if (ensId == '') {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConseilAssist.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
        else {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConseilAssistSearch.php',
                        type: 'POST',
                        data: {
                            ensId: ensId,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    perPage = (currentRowsPerPage, currentPage) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedEnseignant != '') {
            var ensId = this.state.selectedEnseignant.value
        }
        else {
            var ensId = ''
        }
        if (ensId == '') {
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConseilAssist.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            );
        }
        else {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConseilAssistSearch.php',
                        type: 'POST',
                        data: {
                            ensId: ensId,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    filterSearch = (event) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConseilAssist.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    searchEns = selectedEnseignant => {
        if (selectedEnseignant != null) {
            this.setState({ selectedEnseignant: selectedEnseignant });
        }
        else {
            this.setState({ selectedEnseignant: '' });
        }
    }

    recherche = () => {
        if (this.state.selectedEnseignant != '') {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            this.setState({
                resetFirstPage: !this.state.resetFirstPage,
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConseilAssistSearch.php',
                        type: 'POST',
                        data: {
                            ensId: this.state.selectedEnseignant.value,
                            anneeScolaire: anneeScolaire,
                            limit: this.state.nbrEltperPage,
                            page: 1
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    })
                }
            );
        }
        else {
            toast.error("⛔ Veuillez selectionner un enseignant !!", { containerId: 'A' });
        }
    }

    afficherTout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConseilAssist.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false
                });
            }
        });
    }

    toggleConseilAssist = (row) => {
        var service = row.service
        this.setState(prevState => ({
            modalConseilAssist: !prevState.modalConseilAssist,
            service: service
        }));
    }

    toggleConseilAssistFermer = () => {
        this.setState(prevState => ({
            modalConseilAssist: !prevState.modalConseilAssist,
        }));
    }

    toggletwo = (idConseilAssist) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            idConseilAssist: idConseilAssist
        }));
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    delete = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        //
        var idConseilAssist = this.state.idConseilAssist
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/DeleteConseilAssist.php',
            type: 'POST',
            data: {
                idConseilAssist: idConseilAssist,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                this.setState(prevState => ({
                    modal: !prevState.modal,
                }));
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ConseilAssist.php',
                    type: 'POST',
                    data: {
                        query: '',
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }
        });
    }

    impr = () => {
        if (this.state.selectedEnseignant != '') {
            var ensId = this.state.selectedEnseignant.value
        }
        else {
            var ensId = ''
        }
        window.open("http://artsmetiers.mtd-app.com/ImprConseilAssistance?ensId=" + ensId)
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title"> Conseils et assistance</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/AjoutConseilAssist" > <button style={{ background: '#3A3F51', border: '1px solid #3A3F51', borderRadius: '5px' }} type="button" className="btn btn-info"> <i className="fas fa-plus"></i>Assistance</button></Link>
                                </div>
                                <div className="row">
                                    <h4 style={{ paddingLeft: '10px' }}>Critères de recherche</h4>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Enseignant</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.searchEns(e)}
                                                options={this.state.enseignant}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.recherche}>Rechercher</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.afficherTout}>Afficher tout</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.impr}><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Impression</button>
                                    </div>
                                </div>
                                <div className="mb-0 form-group">
                                    <div className="input-group-alternative input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input onChange={(currentRowsPerPage, currentPage) => this.filterSearch(currentRowsPerPage, currentPage)} placeholder="Search" type="text" className="search" />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
                            </div>
                            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} fade={false}>
                                <ModalHeader toggle={this.toggle}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                <ModalBody>
                                    <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                                        </div>
                                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                            <div><button onClick={() => this.delete()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                            <div><button onClick={() => this.toggle()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                            <Modal isOpen={this.state.modalConseilAssist} toggle={this.toggleConseilAssistFermer} className={this.props.className} fade={false}>
                                <ModalHeader toggle={this.toggleConseilAssistFermer}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#FFB6C1' }} className="fas fa-info"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Description du service</p></div></div></ModalHeader>
                                <ModalBody>
                                    <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <p>{this.state.service}</p>
                                        </div>
                                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                            <div><button onClick={() => this.toggleConseilAssistFermer()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                            <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConseilAssistance;