import React, { Component, Fragment } from "react";
import jQuery from "jquery";
import "./paginate.css";

class ImprPayAppCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayPayAppCycle: [],
      detailCycle: "",
      nomCycle: "",
      dateSession: "",
      tarif: "",
      nomEns: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
        var url = window.location.href;
        var arrayUrl = url.split("=");
        var params = arrayUrl[1];
        var arrayParams = params.split("-");
        var nom = arrayParams[0];
        var prenom = arrayParams[1];
        var cin = arrayParams[2];
        var lieu = arrayParams[3];
        var niveau = arrayParams[4];
        var typeFor = arrayParams[5];
        var detailCycle = arrayParams[6];
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ListPayAppCycle.php",
        type: "POST",
        data: {
            nom: nom,
            prenom: prenom,
            cin: cin,
            lieu: lieu,
            niveau: niveau,
            typeFor: typeFor,
          detailCycle: detailCycle,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            arrayPayAppCycle: code_html,
            detailCycle: detailCycle,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoListPayCycle.php",
        type: "POST",
        data: {
          detailCycle: detailCycle,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nomCycle: code_html.nomCycle,
            dateSession: code_html.dateSession,
            nomEns: code_html.nomEns,
            tarif: code_html.tarif,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  impr = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        {this.state.detailCycle != "" ? (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h3>
                {" "}
                Titre : {this.state.nomCycle} {this.state.dateSession}{" "}
              </h3>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h4>
                {" "}
                Formateur : {this.state.nomEns} 
              </h4>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h5>
                Tarif : {this.state.tarif} 
              </h5>
            </div>
          </div>
        ) : null}
        <table
          align="center"
          border={1}
          cellSpacing={0}
          cellPadding={0}
          bgcolor="#000000"
          width={1000}
        >
          <tbody>
            <tr
              bgcolor="#FFFFFF"
              align="center"
              style={{
                color: "#000000",
                fontFamily: "Arial",
                fontSize: "14px",
              }}
            >
              <th width={17}> </th>
              <th width={50}>
                {" "}
                <font color="#">
                  <font face="Verdana, Arial, Helvetica, sans-serif" size={1}>
                    <b  style={{
               
                fontSize: "14px"
              }}>N° ins</b>
                  </font>
                </font>
              </th>
             
                <th width={50}>
                <font face="Verdana, Arial, Helvetica, sans-serif" style={{
               
               fontSize: "14px"
             }}>
                
                  Carte.AD
                </font>
                </th>
              <th style={{ textAlign: "center" }} width={100}>
                {" "}
                <font face="Verdana, Arial, Helvetica, sans-serif" style={{
               
               fontSize: "14px"
             }}>
                  {" "}
                  Nom & Prénom
                </font>
              </th>
              <th style={{ textAlign: "center" }} width={270}>
                {" "}
                <font face="Verdana, Arial, Helvetica, sans-serif" style={{
               
               fontSize: "14px"
             }}>
                  {" "}
                  Frais Cycle
                </font>
              </th>
              <th style={{ textAlign: "center" }} width={352}>
                <table
                  style={{ width: "100%" }}
                  align="center"
                  border={0}
                  cellSpacing={1}
                  cellPadding={4}
                  bgcolor="#000000"
                >
                  <tbody>
                    <tr
                      bgcolor="#FFFFFF"
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontFamily: "Arial",
                        fontSize: "14px",
                      }}
                    >
                      <td style={{ border: "solid 1px" }} width={65}>
                        <font color="#" face="Arial" size={2}>
                          Reçu
                        </font>
                      </td>
                      <td style={{ border: "solid 1px" }} width={90}>
                        <font color="#" face="Arial" size={2}>
                          Date
                        </font>
                      </td>
                      <td style={{ border: "solid 1px" }} width={85}>
                        <font color="#" face="Arial" size={2}>
                          Montant
                        </font>
                      </td>
                      <td style={{ border: "solid 1px" }}>
                        <font color="#" face="Arial" size={2}>
                          mode Paiement
                        </font>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </th>
            </tr>
            {this.state.arrayPayAppCycle.map((el, index) => (
              <tr
                bgcolor="#FFFFFF"
                align="center"
                style={{
                  color: "#000000",
                  fontFamily: "Arial",
                  fontSize: "14px",
                }}
              >
                <td>
                  <font face="Verdana, Arial, Helvetica, sans-serif" size={1}>
                    {index + 1}
                  </font>
                </td>
                <td>
                  <font face="Verdana, Arial, Helvetica, sans-serif" size={1}>
                    {el.num}
                  </font>
                </td>
                <td>
                  <font face="Verdana, Arial, Helvetica, sans-serif" size={1}>
                    {el.carte}
                  </font>
                </td>
                <td style={{fontSize: "14px",paddingLeft:"5px"}}align="left">{el.nomComplet}</td>
                <td align="center">
                  <table
                    align="center"
                    border={0}
                    cellSpacing={1}
                    cellPadding={4}
                    
                    bgcolor="#000000"
                    width={270}
                  >
                    {" "}
                    <tbody>
                      {el.infoAppCycle.map((ele) => (
                        <tr
                          bgcolor="#FFFFFF"
                          align="left"
                          style={{
                            color: "#000000",
                            fontFamily: "Arial",
                            fontSize: "14px",
                            
                          }}
                        >
                          {" "}
                          <td width={150} className="text">
                            {ele.frais}
                          </td>
                          <td
                            width={16}
                            align="right"
                            className="text"
                            style={{ textAlign: "right" }}
                          >
                            <strong>{ele.montant}</strong>
                          </td>
                        </tr>
                      ))}
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        
                        }}
                      >
                        <th
                          width={150}
                          className="SiteCelluleDecorFormulairadmin"
                          style={{ textAlign: "right" }}
                        >
                          <font color="#">
                            <font
                              face="Verdana, Arial, Helvetica, sans-serif"
                              fontSize={1}
                            >
                              Total Frais
                            </font>
                          </font>
                        </th>
                        <td
                          width={16}
                          bgcolor="F5F5F5"
                          className="text"
                          style={{
                            textAlign: "right",
                            fontSize: "14px",
                            paddingLeft:"8px",
                           
                          }}
                        >
                          <strong>{el.mntTotFrais}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td align="center" valign="top">
                  <table
                    style={{ width: "100%" }}
                    align="center"
                    border={0}
                    cellSpacing={1}
                    cellPadding={4}
                    bgcolor="#000000"
                  >
                    <tbody>
                      {el.infoPayAppCycle.map((ele) => (
                        <tr
                          bgcolor="#FFFFFF"
                          align="center"
                          style={{
                            color: "#000000",
                            fontFamily: "Arial",
                            fontSize: "14px",
                          }}
                        >
                          <td style={{fontSize: "14px"}} width={65}>
                            <font face="Arial" size={1}></font>
                            {ele.recu}
                          </td>
                          <td style={{fontSize: "13px"}} width={90}>
                            <font face="Arial" size={1}></font>
                            {ele.date}
                          </td>
                          <td
                            style={{ fontSize: "14px", textAlign: "center" }}
                            width={85}
                            align="right"
                          >
                            <font face="verdana" style={{fontSize: "14px"}}>
                              <strong>{ele.montant}</strong>
                            </font>
                          </td>
                          <td style={{fontSize: "14px"}} align="left">
                            <font face="Arial" size={1}>
                              {" "}
                              {ele.mode != "Espéce" ? (
                                <Fragment>
                                  <div  >
                                    <p style={{fontSize: "12px" ,paddingTop:"10px"}} >
                                      {ele.mode }
                                    
                                    
                                      (N°:
                                    
                                    { ele.numPiece}:
                                   
                                     <mark><strong><u>Propriétaire:</u></strong> </mark>
                                    
                                  {ele.proprietaire}:
                                 
                                    <mark>  <strong><u>Echéance:</u></strong> </mark>
                                    
                                    {ele.echeance})
                                    </p>
                                  </div>
                                </Fragment>
                              ) : (
                                <div style={{ display: "flex",fontSize: "14px",paddingTop:"12px" }}>
                                  <p>{ele.mode}</p>
                                </div>
                              )}
                            </font>
                          </td>
                        </tr>
                      ))}
                      {el.reste > 0 ? (
                        <tr
                          bgcolor="#FFFFFF"
                          align="center"
                          style={{
                            color: "#000000",
                            fontFamily: "Arial",
                            fontSize: "14px",
                          }}
                        >
                          <td colSpan="3">
                            <font face="verdana" style={{fontSize: "14px"}}>
                              <b>Montant Restant</b>
                            </font>
                          </td>
                          <td colSpan="2">
                            <font face="Arial"style={{fontSize: "14px",paddingLeft:"10px"}}>
                              {el.reste}
                            </font>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default ImprPayAppCycle;
