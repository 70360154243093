import React, { Component, Fragment } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios'
import jQuery from 'jquery'
import './paginate.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

class HistoriqueConnexion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Users',
                    selector: 'user',
                    sortable: false,
                    center: true,
                    style: { width: '35%' }
                },
                {
                    name: 'Date connexion',
                    selector: 'dateConnex',
                    sortable: false,
                    center: true,
                    style: { width: '35%' }
                },
                {
                    name: 'Historique',
                    cell: row => <div onClick={() => this.histUser(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87CEEB' }} className="fas fa-chalkboard-teacher"></i></div><div style={{ marginLeft: '5px' }}><p>Historique</p></div></div>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '30%'
                },
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            modal: false,
            pending: true,
            realisation: [],
            dateSearch: '',
            utilisateurs: [],
            selectedUser: ''
        }
        this.newElement = this.newElement.bind(this);
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var idRoleUser = userPermissions[0].idRoleUser
            if (idRoleUser == 1) {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/HistConnexion.php',
                    type: 'POST',
                    data: {
                        query: '',
                        limit: this.state.nbrEltperPage,
                        page: 1
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectUsers.php').then(res => {
                    this.setState({
                        utilisateurs: res.data
                    })
                })
            }
            else {
                localStorage.clear();
                document.location = '/'
            }
        }
        else {
            document.location = '/'
        }
    }

    histUser = (row) => {
        this.setState({
            realisation: row.realisation
        }, () => {
            this.setState(prevState => ({
                modal: !prevState.modal,
            }));
        });
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    newElement(page, totalRows) {
        if (this.state.selectedUser == "" && this.state.dateSearch == "") {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/HistConnexion.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: this.state.nbrEltperPage,
                            page: page
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
        else {
            if (this.state.selectedUser != '') {
                var userId = this.state.selectedUser.value
            }
            else {
                var userId = ''
            }
            if (this.state.dateSearch != '') {
                // convert date format y-m-d
                let dateSearch = this.state.dateSearch
                let month = '' + (dateSearch.getMonth() + 1);
                let day = '' + dateSearch.getDate();
                let year = dateSearch.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var searchDate = [year, month, day].join('-');
            }
            else {
                var searchDate = ''
            }
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/HistUserSearch.php',
                type: 'POST',
                data: {
                    limit: this.state.nbrEltperPage,
                    page: page,
                    userId: userId,
                    dateSearch: searchDate
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            })
        }
    }

    perPage(currentRowsPerPage, currentPage) {
        if (this.state.selectedUser == "" && this.state.dateSearch == "") {
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/HistConnexion.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: currentRowsPerPage,
                            page: currentPage
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            );
        }
        else {
            if (this.state.selectedUser != '') {
                var userId = this.state.selectedUser.value
            }
            else {
                var userId = ''
            }
            if (this.state.dateSearch != '') {
                // convert date format y-m-d
                let dateSearch = this.state.dateSearch
                let month = '' + (dateSearch.getMonth() + 1);
                let day = '' + dateSearch.getDate();
                let year = dateSearch.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var searchDate = [year, month, day].join('-');
            }
            else {
                var searchDate = ''
            }
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/HistUserSearch.php',
                type: 'POST',
                data: {
                    limit: currentRowsPerPage,
                    page: currentPage,
                    userId: userId,
                    dateSearch: searchDate
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            })
        }
    }

    selectUser = selectedUser => {
        if (selectedUser != null) {
            this.setState({ selectedUser });
        }
        else {
            this.setState({ selectedUser: '' });
        }
    };

    searchDate = d => {
        this.setState({
            dateSearch: d
        });
    };

    chercher = () => {
        if (this.state.selectedUser != '') {
            var userId = this.state.selectedUser.value
        }
        else {
            var userId = ''
        }
        if (this.state.dateSearch != '') {
            // convert date format y-m-d
            let dateSearch = this.state.dateSearch
            let month = '' + (dateSearch.getMonth() + 1);
            let day = '' + dateSearch.getDate();
            let year = dateSearch.getFullYear();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            var searchDate = [year, month, day].join('-');
        }
        else {
            var searchDate = ''
        }
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/HistUserSearch.php',
            type: 'POST',
            data: {
                limit: this.state.nbrEltperPage,
                page: 1,
                userId: userId,
                dateSearch: searchDate
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false
                });
            }
        })
    }

    afficherTous = () => {
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/HistConnexion.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false
                });
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Historique</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Users</label>
                                            <div>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => this.selectUser(e)}
                                                    options={this.state.utilisateurs}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Date</label>
                                                <DatePicker className="form-control ddate" style={{ border: 'none' }}
                                                    selected={this.state.dateSearch}
                                                    onChange={this.searchDate}
                                                    dateFormat="dd/MM/yyy"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div className="col-md-3">
                                        <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={() => this.chercher()}>Chercher</button>
                                    </div>
                                    <div className="col-md-3">
                                        <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={() => this.afficherTous()}>Retour</button>
                                    </div>
                                </div>
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                    selectableRowsVisibleOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} fade={false}>
                    <ModalHeader toggle={this.toggle}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87CEEB' }} className="fas fa-chalkboard-teacher"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Historique action utilisateur</p></div></div></ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} scope="col">Action</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Num-action</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Date</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Table</th>
                                </tr>
                            </thead>
                            {this.state.realisation.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'center' }}><p>{el.action}</p></td>
                                        <td style={{ textAlign: 'center' }}><p>{index + 1}</p></td>
                                        <td style={{ textAlign: 'center' }}><p>{el.dateAction}</p></td>
                                        <td style={{ textAlign: 'center' }}><p>{el.nomTable}</p></td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                            <div><button onClick={() => this.toggle()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default HistoriqueConnexion;