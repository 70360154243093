import React, { Component } from "react";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class Attestation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Eleve",
          selector: "nomEleve",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "Date",
          selector: "date",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "Motif",
          selector: "motif",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "IMP",
          cell: (row) => (
            <i onClick={() => this.impAtt(row.attestationId)}
              style={{ cursor: "pointer", color: "#ac3939", fontSize: "20px" }}
              class="fas fa-print"
            ></i>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: "Supp",
          cell: (row) => (
            <i
              onClick={() => this.toggleSupp(row.attestationId)}
              style={{
                cursor: "pointer",
                color: "#ac3939",
                fontSize: "20px",
              }}
              class="fas fa-trash"
            ></i>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      pending: true,
      disabled: false,
      listEleve: [],
      listMotif: [],
      selectEleve: "",
      selectMotif: "",
      modalDelete: false,
      attestationId: "",
    };
    this.newElement = this.newElement.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetElvByAttestation.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listEleve: code_html,
          });
        },
      });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetMotif.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listMotif: code_html,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/Attestation.php",
        type: "POST",
        data: {
          groupeId: this.state.selectGroupe,
          anneeScolaire: anneeScolaire,
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
    }
  }

  newElement(page, totalRows) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectEleve != "" && this.state.selectMotif != "") {
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/Attestation.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      this.setState(
        {
          pending: true,
        },
        () => {
          if (this.state.selectEleve != "") {
            var eleveId = this.state.selectEleve.value;
          } else {
            var eleveId = "";
          }
          if (this.state.selectMotif != "") {
            var motifId = this.state.selectMotif.value;
          } else {
            var motifId = "";
          }
          //
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/AttestationSearch.php",
            type: "POST",
            data: {
              eleveId: eleveId,
              motifId: motifId,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }

  perPage(currentRowsPerPage, currentPage) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectEleve != "" && this.state.selectMotif != "") {
      this.setState(
        {
          nbrEltperPage: currentRowsPerPage,
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/Attestation.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: currentRowsPerPage,
              page: currentPage,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      if (this.state.selectEleve != "") {
        var eleveId = this.state.selectEleve.value;
      } else {
        var eleveId = "";
      }
      if (this.state.selectMotif != "") {
        var motifId = this.state.selectMotif.value;
      } else {
        var motifId = "";
      }
      //
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/AttestationSearch.php",
        type: "POST",
        data: {
          eleveId: eleveId,
          motifId: motifId,
          limit: currentRowsPerPage,
          page: currentPage,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
    }
  }

  addEleve = (selectEleve) => {
    if (selectEleve != null) {
      this.setState({ selectEleve });
    } else {
      this.setState({
        selectEleve: "",
      });
    }
  };

  addMotif = (selectMotif) => {
    if (selectMotif != null) {
      this.setState({ selectMotif });
    } else {
      this.setState({
        selectMotif: "",
      });
    }
  };

  affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/Attestation.php",
      type: "POST",
      data: {
        groupeId: this.state.selectGroupe,
        anneeScolaire: anneeScolaire,
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  rechercher = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectEleve != "") {
      var eleveId = this.state.selectEleve.value;
    } else {
      var eleveId = "";
    }
    if (this.state.selectMotif != "") {
      var motifId = this.state.selectMotif.value;
    } else {
      var motifId = "";
    }
    //
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/AttestationSearch.php",
      type: "POST",
      data: {
        eleveId: eleveId,
        motifId: motifId,
        limit: this.state.nbrEltperPage,
        page: 1,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  impAtt = (id) => {
    var attId = id
    window.open("http://artsmetiers.mtd-app.com/ImprMotifEleve?attId=" + attId)
  }

  toggleSupp = (id) => {
    this.setState((prevState) => ({
      modalDelete: !prevState.modalDelete,
      attestationId: id,
    }));
  };

  fermerModal = () => {
    this.setState((prevState) => ({
      modalDelete: !prevState.modalDelete,
    }));
  };

  deleteEleve = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/DeleteElvAttestation.php",
      type: "POST",
      data: {
        attestationId: this.state.attestationId,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modalDelete: !this.state.modalDelete,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Attestation.php",
              type: "POST",
              data: {
                groupeId: this.state.selectGroupe,
                anneeScolaire: anneeScolaire,
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
              },
              dataType: "json",
              success: (code_html, statut) => {
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                  pending: false,
                });
              },
            });
          }
        );
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Attestation</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                  <Link to="/AjoutAttestation" > <button style={{ background: '#3A3F51', border: '1px solid #3A3F51', borderRadius: '5px' }} type="button" className="btn btn-info"> <i className="fas fa-plus"></i>Attéstation</button></Link>
                </div>
                <div className="mb-0 form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Eleve</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addEleve}
                          options={this.state.listEleve}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Motif</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addMotif}
                          options={this.state.listMotif}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.rechercher}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Afficher tout
                    </button>
                  </div>
                </div>
                <div>
                  {" "}
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover={true}
                    pagination={true}
                    paginationServer={true}
                    onChangePage={(page, totalRows) =>
                      this.newElement(page, totalRows)
                    }
                    paginationTotalRows={this.state.nombreTotalElement}
                    paginationPerPage={this.state.nbrEltperPage}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                      this.perPage(currentRowsPerPage, currentPage)
                    }
                    paginationResetDefaultPage={this.state.resetFirstPage}
                    paginationComponentOptions={{
                      rowsPerPageText: "Elements par page:",
                      rangeSeparatorText: "de",
                      noRowsPerPage: false,
                    }}
                    progressPending={this.state.pending}
                  />
                </div>
                <Modal
                  isOpen={this.state.modalDelete}
                  toggle={this.fermerModal}
                  className={this.props.className}
                  fade={false}
                >
                  <ModalHeader toggle={this.fermerModal}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "465px",
                      }}
                    >
                      <img
                        width="30%"
                        src="./images/deleteModal.png"
                        alt=".."
                      />
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <div
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        FontSize: "14px",
                        FontWeight: "700",
                        LineHeight: "18.375px",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>
                          Êtes-Vous sûr de vouloir supprimer cet Apprenant ?
                        </p>
                      </div>
                      <div
                        className="hvr-push"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <button
                            onClick={() => this.deleteEleve()}
                            style={{
                              backgroundColor: "#00FF7F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-up"
                            ></i>
                            Oui
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={() => this.fermerModal()}
                            style={{
                              backgroundColor: "#D9534F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-down"
                            ></i>
                            Non
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Attestation);
