import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';

class MenuDocumentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Admin 
            barreNavAdmin: [{ titre: 'Gestion Enseignants', element: [{ nomElement: "Liste enseignants", iconElement: 'fas fa-chalkboard-teacher', linkElement: '/Enseignants', sousPermission: 0, arraySousElement: [] }, { nomElement: "Présence enseignant", iconElement: 'fas fa-user-clock', linkElement: '/PresenceEnseignant', sousPermission: 0, arraySousElement: [] },{
                nomElement: "Gestion enseignant",
                iconElement: "fas fa-users",
                linkElement: "/GestionEnseignant",
                sousPermission: 0,
                arraySousElement: [],
              }, { nomElement: 'Conseils et assitances', linkElement: '/ConseilAssistance', sousPermission: 0, iconElement: 'fas fa-handshake', arraySousElement: [] }, { nomElement: "Honoraires formateurs", iconElement: '', linkElement: '', sousPermission: 0, arraySousElement: [{ nomSousElement: 'Consulter reglement', linkSousElement: '/ReglementEnseignant', sousSousPermission: 0, iconSousElement: 'fas fa-eye', arraySousSousElement: [] }, { nomSousElement: 'Solder compte', linkSousElement: '/PayReglementEnseignant', sousSousPermission: 0, iconSousElement: 'fas fa-edit', arraySousSousElement: [] }] }, { nomElement: 'Chéques enseignants', linkElement: '/ChequeEns', sousPermission: 0, iconElement: 'far fa-credit-card', arraySousElement: [] }], iconTitre: 'fas fa-chalkboard-teacher', permission: 0 }],
            // Scolarite
            barreNavScol: [{ titre: 'Assiduité', element: [{ nomElement: "Ajout séance", iconElement: 'far fa-calendar', linkElement: '/AjoutSeance', sousPermission: 0, arraySousElement: [] }, { nomElement: "Absence apprenants", iconElement: 'fas fa-calendar-day', linkElement: '/Absence', sousPermission: 0, arraySousElement: [] }, { nomElement: "Présences enseignants", iconElement: 'fas fa-calendar-check', linkElement: '/PresenceEnseignant', sousPermission: 0, arraySousElement: [] }, { nomElement: "Attestation", iconElement: 'fas fa-paste', linkElement: '/Attestation', sousPermission: 0, arraySousElement: [] }, { nomElement: "Demande d'attestation", iconElement: "fas fa-sticky-note", linkElement: "/DemandeAttestation", sousPermission: 0, arraySousElement: [], }], iconTitre: 'fas fa-list-alt', permission: 0 }],
            // Role selon l'utilisateur (soit admin ou scolarite)
            roleId: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var roleId = userPermissions[0].idRoleUser
            this.setState({
                roleId: roleId
            });
        }
        else {
            document.location = '/'
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            {this.state.roleId == 1 ? (
                                <h4 className="page-title">Gestion enseignants</h4>
                            ) : (
                                <h4 className="page-title">Asseduité</h4>
                            )}
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box" >
                                <div className="row">
                                    {this.state.roleId == 1 ? (
                                        this.state.barreNavAdmin.map((el, index) =>
                                            <div className="col-md-4">
                                                <div className="white-box" style={{ border: 'solid 1px', borderRadius: '5px' }}>
                                                    <h3 style={{ border: 'solid 1px', backgroundColor: 'black', color: 'white' }}><i style={{ paddingRight: '5px', paddingLeft: '5px' }} className={el.iconTitre}></i>{el.titre}</h3>
                                                    <div className="myadmin-dd dd" id="nestable">
                                                        <ol className="dd-list">
                                                            {el.element.map((ele, indice) =>
                                                                <div>
                                                                    {
                                                                        (ele.arraySousElement.length > 0) ?
                                                                            // Noeud Parent Ayant des Fils Feuilles
                                                                            (<Fragment>
                                                                                <li className="dd-item" data-id={index}>
                                                                                    {/* <button data-action="collapse" type="button">Collapse</button><button data-action="expand" type="button" style={{display: 'none'}}>Expand</button> */}
                                                                                    <div style={{ border: '1px solid rgba(120,130,140,.13)', padding: '8px 16px', height: 'auto', fontWeight: '600', color: '#2b2b2b' }}><i style={{ paddingRight: '5px' }} className={ele.iconElement}></i>{ele.nomElement}</div>
                                                                                    <ol className="dd-list">
                                                                                        {ele.arraySousElement.map(elm =>
                                                                                            <li className="dd-item" data-id={index + 1}>
                                                                                                <Link style={{ color: '#2b2b2b' }} to={elm.linkSousElement}><div style={{ border: '1px solid rgba(120,130,140,.13)', padding: '8px 16px', height: 'auto', fontWeight: '600' }}><i style={{ paddingRight: '5px' }} className={elm.iconSousElement}></i>{elm.nomSousElement}</div></Link>
                                                                                                {elm.arraySousSousElement.length > 0 ? (
                                                                                                    <ol className="dd-list">
                                                                                                        {elm.arraySousSousElement.map(ellm =>
                                                                                                            <li className="dd-item" data-id={index + 1}>
                                                                                                                <Link style={{ color: '#2b2b2b' }} to={ellm.linkSousSousElement}><div style={{ border: '1px solid rgba(120,130,140,.13)', padding: '8px 16px', height: 'auto', fontWeight: '600' }}><i style={{ paddingRight: '5px' }} className={ellm.iconSousSousElement}></i>{ellm.nomSousSousElement}</div></Link>
                                                                                                            </li>)}
                                                                                                    </ol>) : (null)}
                                                                                            </li>)}
                                                                                    </ol>
                                                                                </li> </Fragment>) :
                                                                            // Noeud Feuille (Terminaison)
                                                                            (<Link style={{ color: '#2b2b2b' }} to={ele.linkElement}><div style={{ border: '1px solid rgba(120,130,140,.13)', padding: '8px 16px', height: 'auto', fontWeight: '600' }}><i style={{ paddingRight: '5px' }} className={ele.iconElement}></i>{ele.nomElement}</div></Link>)
                                                                    }
                                                                </div>
                                                            )}
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>)
                                    ) :
                                        (
                                            this.state.barreNavScol.map((el, index) =>
                                                <div className="col-md-4">
                                                    <div className="white-box" style={{ border: 'solid 1px', borderRadius: '5px' }}>
                                                        <h3 style={{ border: 'solid 1px', backgroundColor: 'black', color: 'white' }}><i style={{ paddingRight: '5px', paddingLeft: '5px' }} className={el.iconTitre}></i>{el.titre}</h3>
                                                        <div className="myadmin-dd dd" id="nestable">
                                                            <ol className="dd-list">
                                                                {el.element.map((ele, indice) =>
                                                                    <div>
                                                                        {
                                                                            (ele.arraySousElement.length > 0) ?
                                                                                // Noeud Parent Ayant des Fils Feuilles
                                                                                (<Fragment>
                                                                                    <li className="dd-item" data-id={index}>
                                                                                        {/* <button data-action="collapse" type="button">Collapse</button><button data-action="expand" type="button" style={{display: 'none'}}>Expand</button> */}
                                                                                        <div style={{ border: '1px solid rgba(120,130,140,.13)', padding: '8px 16px', height: 'auto', fontWeight: '600', color: '#2b2b2b' }}><i style={{ paddingRight: '5px' }} className={ele.iconElement}></i>{ele.nomElement}</div>
                                                                                        <ol className="dd-list">
                                                                                            {ele.arraySousElement.map(elm =>
                                                                                                <li className="dd-item" data-id={index + 1}>
                                                                                                    <Link style={{ color: '#2b2b2b' }} to={elm.linkSousElement}><div style={{ border: '1px solid rgba(120,130,140,.13)', padding: '8px 16px', height: 'auto', fontWeight: '600' }}><i style={{ paddingRight: '5px' }} className={elm.iconSousElement}></i>{elm.nomSousElement}</div></Link>
                                                                                                    {elm.arraySousSousElement.length > 0 ? (
                                                                                                        <ol className="dd-list">
                                                                                                            {elm.arraySousSousElement.map(ellm =>
                                                                                                                <li className="dd-item" data-id={index + 1}>
                                                                                                                    <Link style={{ color: '#2b2b2b' }} to={ellm.linkSousSousElement}><div style={{ border: '1px solid rgba(120,130,140,.13)', padding: '8px 16px', height: 'auto', fontWeight: '600' }}><i style={{ paddingRight: '5px' }} className={ellm.iconSousSousElement}></i>{ellm.nomSousSousElement}</div></Link>
                                                                                                                </li>)}
                                                                                                        </ol>) : (null)}
                                                                                                </li>)}
                                                                                        </ol>
                                                                                    </li> </Fragment>) :
                                                                                // Noeud Feuille (Terminaison)
                                                                                (<Link style={{ color: '#2b2b2b' }} to={ele.linkElement}><div style={{ border: '1px solid rgba(120,130,140,.13)', padding: '8px 16px', height: 'auto', fontWeight: '600' }}><i style={{ paddingRight: '5px' }} className={ele.iconElement}></i>{ele.nomElement}</div></Link>)
                                                                        }
                                                                    </div>
                                                                )}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>)
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuDocumentation;