import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import Table from "reactstrap/lib/Table";
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

class ResultatPizzaiolo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listCycle: [],
      selectCycle: [],
      blockNote: false,
      date: new Date(),
      arr: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetCycle.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listCycle: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addCycle = (selectCycle) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectCycle != null) {
      this.setState({ selectCycle }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetEleveByIdCycle.php",
          type: "POST",
          data: {
            cycleChoisi: this.state.selectCycle.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listEleves: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        selectCycle: "",
      });
    }
  };

  changeNoteHy = (indexOfLigne, event) => {
    var arrayEleve = this.state.listEleves;
    arrayEleve[indexOfLigne].hygiene = event;
    var a = arrayEleve[indexOfLigne].hygiene;
    var b = arrayEleve[indexOfLigne].technologie;
    var c = arrayEleve[indexOfLigne].gestionDePointDeVente;
    var o = arrayEleve[indexOfLigne].ep
    var i = arrayEleve[indexOfLigne].stage
    if (a == "") {
      a = 0
    } else {
      a = a
    }
    if (b == "") {
      b = 0
    } else {
      b = b
    }
    if (c == "") {
      c = 0
    } else {
      c = c
    }
    if (o == "") {
      o = 0
    } else {
      o = o
    }
    if (i == "") {
      i = 0
    } else {
      i = i
    }
    var moyenneET = (parseFloat(a) + parseFloat(b) + parseFloat(c)) / 3;
    arrayEleve[indexOfLigne].et = moyenneET;
    var moyenneEFF = (moyenneET + (parseFloat(o) * 2)) / 3
    arrayEleve[indexOfLigne].mef = moyenneEFF
    var moyenneGen = ((moyenneEFF * 2) + parseFloat(i)) / 3
    arrayEleve[indexOfLigne].moyG = moyenneGen
    this.setState({
      listEleves: arrayEleve,
    });
  };

  changeNoteTech = (indexOfLigne, event) => {
    var arrayEleve = this.state.listEleves;
    arrayEleve[indexOfLigne].technologie = event;
    var a = arrayEleve[indexOfLigne].hygiene;
    var b = arrayEleve[indexOfLigne].technologie;
    var c = arrayEleve[indexOfLigne].gestionDePointDeVente;
    var o = arrayEleve[indexOfLigne].ep
    var i = arrayEleve[indexOfLigne].stage
    if (a == "") {
      a = 0
    } else {
      a = a
    }
    if (b == "") {
      b = 0
    } else {
      b = b
    }
    if (c == "") {
      c = 0
    } else {
      c = c
    }
    if (o == "") {
      o = 0
    } else {
      o = o
    }
    if (i == "") {
      i = 0
    } else {
      i = i
    }
    var moyenneET = (parseFloat(a) + parseFloat(b) + parseFloat(c)) / 3;
    arrayEleve[indexOfLigne].et = moyenneET;
    var moyenneEFF = (moyenneET + (parseFloat(o) * 2)) / 3
    arrayEleve[indexOfLigne].mef = moyenneEFF
    var moyenneGen = ((moyenneEFF * 2) + parseFloat(i)) / 3
    arrayEleve[indexOfLigne].moyG = moyenneGen
    this.setState({
      listEleves: arrayEleve,
    });
  };

  changeNoteGes = (indexOfLigne, event) => {
    var arrayEleve = this.state.listEleves;
    arrayEleve[indexOfLigne].gestionDePointDeVente = event;
    var a = arrayEleve[indexOfLigne].hygiene;
    var b = arrayEleve[indexOfLigne].technologie;
    var c = arrayEleve[indexOfLigne].gestionDePointDeVente;
    var o = arrayEleve[indexOfLigne].ep
    var i = arrayEleve[indexOfLigne].stage
    if (a == "") {
      a = 0
    } else {
      a = a
    }
    if (b == "") {
      b = 0
    } else {
      b = b
    }
    if (c == "") {
      c = 0
    } else {
      c = c
    }
    if (o == "") {
      o = 0
    } else {
      o = o
    }
    if (i == "") {
      i = 0
    } else {
      i = i
    }
    var moyenneET = (parseFloat(a) + parseFloat(b) + parseFloat(c)) / 3;
    arrayEleve[indexOfLigne].et = moyenneET;
    var moyenneEFF = (moyenneET + (parseFloat(o) * 2)) / 3
    arrayEleve[indexOfLigne].mef = moyenneEFF
    var moyenneGen = ((moyenneEFF * 2) + parseFloat(i)) / 3
    arrayEleve[indexOfLigne].moyG = moyenneGen
    this.setState({
      listEleves: arrayEleve,
    });
  };


  changeNoteEP = (indexOfLigne, event) => {
    var arrayEleve = this.state.listEleves;
    arrayEleve[indexOfLigne].ep = event;
    var x = arrayEleve[indexOfLigne].ep
    var z = arrayEleve[indexOfLigne].et
    var i = arrayEleve[indexOfLigne].stage
    if (x == "") {
      x = 0
    } else {
      x = x
    }
    if (z == "") {
      z = 0
    } else {
      z = z
    }
    if (i == "") {
      i = 0
    } else {
      i = i
    }
    var moyenneEFF = ((parseFloat(x) * 2) + parseFloat(z)) / 3
    arrayEleve[indexOfLigne].mef = moyenneEFF
    var moyenneGen = ((moyenneEFF * 2) + parseFloat(i)) / 3
    arrayEleve[indexOfLigne].moyG = moyenneGen
    this.setState({
      listEleves: arrayEleve,
    });
  };

  changeNoteST = (indexOfLigne, event) => {
    var arrayEleve = this.state.listEleves;
    arrayEleve[indexOfLigne].stage = event;
    var e = arrayEleve[indexOfLigne].stage
    var f = arrayEleve[indexOfLigne].mef
    if (e == "") {
      e = 0
    } else {
      e = e
    }
    if (f == "") {
      f = 0
    } else {
      f = f
    }
    var moyenneGen = ((parseFloat(f) * 2) + parseFloat(e)) / 3
    arrayEleve[indexOfLigne].moyG = moyenneGen
    this.setState({
      listEleves: arrayEleve,
    });
  };

  affiche = () => {
    this.setState({
      blockNote: true,
    });
  };

  ajoutNotePizz = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.date != "") {
      var date = this.state.date;
      var convertDate = date.toISOString().split("T")[0];
    } else {
      var convertDate = "";
    }
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/AddNotePizz.php",
      type: "POST",
      data: {
        cycleChoisi: this.state.selectCycle.value,
        anneeScolaire: anneeScolaire,
        dateAjoutNote: convertDate,
        listEleves: this.state.listEleves,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Menu");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  impr = () => {
    var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
    var anneeScolaire = userPermissions[0].anneeScolaire
    var idDetailCycle = this.state.selectCycle.value
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuResCycle.php',
      type: 'POST',
      data: {
        idDetailCycle: idDetailCycle,
        anneeScolaire: anneeScolaire
      },
      dataType: 'json',
      success: function (code_html, statut) {
        var server_path = code_html.server_path;
        var file_path = code_html.file_path
        var path = server_path + file_path;
        window.open(path, "_blank")
      }
    });
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Resultat ACC </h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Cycle</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addCycle}
                        options={this.state.listCycle}
                        formatGroupLabel={formatGroupLabel}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div>
                    <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19', marginBottom: '10px' }} type="button" className="btn btn-success" onClick={this.affiche}>Rechercher</button>
                  </div>
                  <div>
                    <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19', marginBottom: '10px' }} type="button" className="btn btn-success" onClick={this.impr}>Resultat</button>
                  </div>
                </div>
                {this.state.blockNote == true ? (
                  <div>
                    <Table style={{ border: 'solid' }}>
                      <thead>
                        <th style={{ border: 'solid', color: 'black', textAlign: 'center' }}>N° :</th>
                        <th style={{ border: 'solid', color: 'black', textAlign: 'center' }}>Apprenant</th>
                        <th colSpan={6} style={{ border: 'solid', textAlign: 'center', color: 'black' }}>EFF</th>
                        <th style={{ border: 'solid', textAlign: 'center', color: 'black' }}>Stage</th>
                        <th style={{ border: 'solid', textAlign: 'center', color: 'black' }}>Moy.Gén</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={2} style={{ textAlign: "center", border: 'solid', color: 'black' }}> Module </td>
                          <td style={{ textAlign: "center", border: 'solid', color: 'black' }}> Hygiéne </td>
                          <td style={{ textAlign: "center", border: 'solid', color: 'black' }}> Technologie </td>
                          <td style={{ textAlign: "center", border: 'solid', color: 'black' }}> Gestion PV </td>
                          <td style={{ textAlign: "center", border: 'solid', color: 'black' }}> ET </td>
                          <td style={{ textAlign: "center", border: 'solid', color: 'black' }}> EP </td>
                          <td style={{ textAlign: "center", border: 'solid', color: 'black' }}> Moy EFF </td>
                          <td style={{ textAlign: "center", border: 'solid', color: 'black' }}> Stage </td>
                          <td style={{ textAlign: "center", border: 'solid', color: 'black' }}> Moy Gén. </td>
                        </tr>
                        {this.state.listEleves.map((el, indexOfLigne) => (
                          <tr>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}>{indexOfLigne + 1}</td>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}> {el.nomComplet} </td>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}>
                              <input
                                style={{
                                  textAlign: "center",
                                  width: "50px"
                                }}
                                type="text"
                                value={el.hygiene}
                                onChange={(e) =>
                                  this.changeNoteHy(
                                    indexOfLigne,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}>
                              <input
                                style={{
                                  textAlign: "center",
                                  width: "50px"
                                }}
                                type="text"
                                value={el.technologie}
                                onChange={(e) =>
                                  this.changeNoteTech(
                                    indexOfLigne,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}>
                              <input
                                style={{
                                  textAlign: "center",
                                  width: "50px"
                                }}
                                type="text"
                                value={el.gestionDePointDeVente}
                                onChange={(e) =>
                                  this.changeNoteGes(
                                    indexOfLigne,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}>
                              <input
                                disabled
                                style={{
                                  textAlign: "center",
                                  width: "50px"
                                }}
                                type="text"
                                value={el.et}
                              />
                            </td>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}>
                              <input
                                style={{
                                  textAlign: "center",
                                  width: "50px",
                                }}
                                type="text"
                                value={el.ep}
                                onChange={(e) =>
                                  this.changeNoteEP(
                                    indexOfLigne,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}>
                              <input
                                disabled
                                style={{
                                  textAlign: "center",
                                  width: "50px",
                                }}
                                type="text"
                                value={el.mef}
                              />
                            </td>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}>
                              <input
                                style={{
                                  textAlign: "center",
                                  width: "50px",
                                }}
                                type="text"
                                value={el.stage}
                                onChange={(e) =>
                                  this.changeNoteST(
                                    indexOfLigne,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td style={{ textAlign: "center", border: 'solid', color: 'black' }}>
                              <input
                                disabled
                                style={{
                                  textAlign: "center",
                                  width: "50px",
                                }}
                                type="text"
                                value={el.moyG}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                      <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajoutNotePizz}>Confirmer</button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ResultatPizzaiolo);
