import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModifMatieresFormat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listFilieres: [],
            selectedFiliere: '',
            matieresParFormat: [],
            indexLignMatForASupprimer: '',
            modal: false,
            listFormations: [],
            selectedFormation: '',
            listNiveaux: [],
            selectedNiveau: '',
            listMatieres: [],
            selectedMatiere: '',
            idMatForASupprimer: '',
            tabIdMatForDelete: []
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var idNiveau = this.props.location.state.idNiveau;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetMatForById.php?id=' + idNiveau).then(res => {
                console.log(res)
                this.setState({
                    selectedFiliere: res.data[0].filiere,
                    selectedFormation: res.data[0].formatSpec,
                    selectedNiveau: res.data[0].niveau,
                    matieresParFormat: res.data[1]
                })
            })
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFilieres.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listFilieres: code_html
                    })
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectMatieres.php').then(res => {
                console.log(res)
                this.setState({
                    listMatieres: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    additionLignFormation = () => {
        let element = { idMatFor: -1, matiere: '' }
        this.setState({
            matieresParFormat: [...this.state.matieresParFormat, element]
        })
    }

    updFiliere = selectedFiliere => {
        if (selectedFiliere != null) {
            this.setState({ selectedFiliere }, () => {
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFormaSpec.php?id=' + selectedFiliere.value).then(res => {
                    console.log(res)
                    this.setState({
                        listFormations: res.data
                    })
                })
            })
        }
    };

    updFormation = selectedFormation => {
        if (selectedFormation != null) {
            this.setState({ selectedFormation }, () => {
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectSpecNiv.php?id=' + selectedFormation.value).then(res => {
                    console.log(res)
                    this.setState({
                        listNiveaux: res.data
                    })
                })
            })
        }
    };

    updNiveau = selectedNiveau => {
        this.setState({ selectedNiveau })
    };

    updMatFor = (selectedMatiere, indexMatiere) => {
        let matiere = selectedMatiere;
        this.setState({
            matieresParFormat: this.state.matieresParFormat.map((el, id) => (id === indexMatiere ? Object.assign(el, { matiere: matiere }) : el))
        });
    }

    toggleMatFor = (idMatFor, indexMatFor) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            idMatForASupprimer: idMatFor,
            indexLignMatForASupprimer: indexMatFor
        }));
    }

    deleteMatFor = () => {
        let idMatForASupprimer = this.state.idMatForASupprimer
        this.setState({
            tabIdMatForDelete: [...this.state.tabIdMatForDelete, idMatForASupprimer]
        });
        this.state.matieresParFormat.splice(this.state.indexLignMatForASupprimer, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteMatFor = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    modif = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdMatFor.php',
            type: 'POST',
            data: {
                formationId: this.state.selectedFormation.value,
                niveauId: this.state.selectedNiveau.value,
                matieresParFormat: this.state.matieresParFormat,
                idMatForDelete: this.state.tabIdMatForDelete,
                anneeScolaire: anneeScolaire,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/MatieresFormat');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification matiére par niveau</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/MatieresFormat"> <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Filiére</label>
                                            <Select
                                                value={this.state.selectedFiliere}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updFiliere}
                                                options={this.state.listFilieres}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Formation-Specialité</label>
                                            <Select
                                                value={this.state.selectedFormation}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updFormation}
                                                options={this.state.listFormations}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau</label>
                                            <Select
                                                value={this.state.selectedNiveau}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updNiveau}
                                                options={this.state.listNiveaux}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <table style={{ width: '100%', marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Matiére</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                            </tr>
                                        </thead>
                                        {this.state.matieresParFormat.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-10"><Select
                                                        value={el.matiere}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.updMatFor(e, index)}
                                                        options={this.state.listMatieres}
                                                    />  </td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleMatFor(el.idMatFor, index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                        <Button style={{ borderRadius: '5px' }} onClick={this.additionLignFormation} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                    </div>
                                </div>
                                <Modal isOpen={this.state.modal} toggle={this.nonDeleteMatFor} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteMatFor}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer cette formation ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteMatFor()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteMatFor()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifMatieresFormat);