import React, { Component, Fragment } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import jQuery from 'jquery'
import './paginate.css'
import YearPicker from "react-year-picker";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { Flip} from "react-toastify";



class Visiteurs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Prénom-Nom',
                    selector: 'nomComplet',
                    sortable: false,
                    center: true
                },
                {
                    name: 'Mobile',
                    selector: 'mobile',
                    sortable: false,
                    center: true
                },
                {
                    name: "Modif",
                    cell: (row) => (
                      <Link
                        to={{
                          pathname: "/Modifvisiteurs",
                          state: { idVisiteur: row.idVisiteur },
                        }}
                      >
                        <button className="buttonModifier">
                          <i style={{ color: "white" }} className="fas fa-pencil-alt"></i>
                        </button>
                      </Link>
                    ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: "5%",
                  },
                  {
                    name: "Supp",
                    cell: (row) =>
                        <button
                          className="buttonSupprimer"
                          onClick={() => this.toggletwo(row.idVisiteur)}
                        >
                          <i style={{ color: "white" }} className="fas fa-trash-alt"></i>
                        </button>
                      ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: "5%",
                  },


                
            
            ],

            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            modal: false,
            pending: true,
            selectEtat: "",
            anneeNaissance : "",
            idVisiteur: "",
            idRoleUser: "",


        }
        this.newElement = this.newElement.bind(this);
        this.filterSearch = this.filterSearch.bind(this);
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Visiteurs.php',
                type: 'POST',
                data: {
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    newElement(page, totalRows) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Visiteurs.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: page,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }
        )
    }

    perPage(currentRowsPerPage, currentPage) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState(
            {
                nbrEltperPage: currentRowsPerPage,
                pending: true
            }
            , () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Visiteurs.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: currentRowsPerPage,
                        page: currentPage,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }
        );
    }

    filterSearch(event) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Visiteurs.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

      
    addAnneeNaiss = (d) => {
        this.setState({
          anneeNaissance: d,
        });
      };

    Rechercher = () =>{
        var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
        var anneeScolaire = userPermissions[0].anneeScolaire;

        this.setState(
            {
          resetFirstPage: !this.state.resetFirstPage,
          pending : true , 
            },
        ()=>{
            jQuery.ajax({
                url:
                  process.env.REACT_APP_API_URL +
                  "Back_mediatraining/VisiteursSearch.php",
                type: "POST",
                data: {
                  anneeNaissance: this.state.anneeNaissance,
                  limit: this.state.nbrEltperPage,
                  page: 1,
                  anneeScolaire: anneeScolaire,
                
                },
                dataType: "json",
                success: (code_html, statut) => {
                  console.log(code_html);
                  this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false,
                
                  });
                },
              });
        }
        )
    }


    toggletwo = (idVisiteur) => {
        this.setState((prevState) => ({
          modal: !prevState.modal,
          idVisiteur: idVisiteur
        }));
      };



    toggle = () => {
        this.setState((prevState) => ({
          modal: !prevState.modal
        }));
      };
    


      deleteVisiteur() {
        var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
        var userId = userPermissions[0].idUser;
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/DeleteVisiteur.php?id=" +
            this.state.idVisiteur +
            "&userId=" +
            userId,
          type: "POST",
          dataType: "json",
          success: (code_html, statut) => {
            this.setState(prevState=>({
              modal: !prevState.modal,
            }));
              
            window.location.reload()
                jQuery.ajax({
                  url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/Visiteurs.php",
                  type: "POST",
                  data: {
                    query: "",
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    
                  },
                  dataType: "json",
                  success: (code_html, statut) => {
                    console.log(code_html);
                    this.setState({
                      nombreTotalElement: code_html.total,
                      data: code_html.data,
                      pending:false
                    });
                  }
                });
              }
        });
      }


    imprListeVis = () => {
        if (this.state.selectEtat != "") {
            var etat = this.state.selectEtat.value;
          } else {
            var etat = 0;
          }
          window.open("http://artsmetiers.mtd-app.com/ImprListVis?etat=" + etat);
    }
    
    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Visiteurs</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                            <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>

                            <div className="col-md-6">
                      <div className="form-group">
                        <label>Annee de naissance</label>
                        <YearPicker
                  
                          style={{   width: "150%",  }}
                          selected={this.state.anneeNaissance}
                          onChange={this.addAnneeNaiss}
                          
                        />
                      </div>
                    </div>
                    <br></br><br></br>
                    <div >
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                        
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this. Rechercher }
                    >
                      Rechercher
                    </button>
                  </div>
                                <div className="mb-0 form-group">
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                        <div style={{ marginRight: '10px' }}><Link to="/AjoutVisiteur" > <button style={{ background: '#3A3F51', border: '1px solid #3A3F51', borderRadius: '5px' }} type="button" className="btn btn-info"> <i className="fas fa-plus"></i>Visiteur</button></Link></div>
                                    </div>
                                    <div className="input-group-alternative input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input onChange={(currentRowsPerPage, currentPage) => this.filterSearch(currentRowsPerPage, currentPage)} placeholder="Search" type="text" className="search" />
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex",justifyContent:"flex-end"}}>
                                <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                       
                        
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.imprListeVis}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression Liste
                    </button>
                                </div>
                                
                                <DataTable
                                    columns={this.state.columns} 
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
                                 <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggle}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir supprimer cet visiteur ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.deleteVisiteur()}
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggle()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
        
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Visiteurs;