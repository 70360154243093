import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'rc-checkbox/assets/index.css';
import { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { RadioGroup, Radio } from 'react-radio-group'
import './paginate.css'

class ModifHonoraireEnsCycle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCycle: '',
            nomCycle: '',
            nbrHeure: '',
            tarifCycle: '',
            tarifAvecCarte: '',
            selectedType: '',
            anneScol: '',
            mois: [{ label: 'Janvier', value: '01' }, { label: 'Février', value: '02' }, { label: 'Mars', value: '03' }, { label: 'Avril', value: '04' }, { label: 'Mai', value: '05' }, { label: 'Juin', value: '06' }, { label: 'Juillet', value: '07' }, { label: 'Aout', value: '08' }, { label: 'Septembre', value: '09' }, { label: 'Octobre', value: '10' }, { label: 'November', value: '11' }, { label: 'Decembre', value: '12' }],
            enseignants: [],
            selectedEnseignent: '',
            arrayCycle: [],
            indexLignCycleASupprimer: '',
            modal: false,
            indexLignEnseignASupprimer: '',
            indexSousLignEnseignASupprimer: '',
            modalEnseign: false,
            listChoix: ['Oui', 'Non'],
            idMoisDetCyclSup: '',
            tabSupIdMoisDetCycle: [],
            idDetCycleSup: '',
            tabDeleteIdDetCycle: [],
            selectedTypeFormat: 2,
            listtype: [
                {value:0 , label:"Forfait"},
                  {value:1 ,label:"Heure"}
            ]
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            var idCycle = this.props.location.state.idCycle;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetCycleModifById.php?id=' + idCycle).then(res => {
                console.log(res)
                this.setState({
                    idCycle: idCycle,
                    nomCycle: res.data[0].cycle,
                    selectedTypeFormat: parseInt(res.data[0].idTypeEduc),
                    nbrHeure: res.data[0].heure,
                    tarifCycle: res.data[0].tarif,
                    tarifAvecCarte: res.data[0].carte,
                }, () => {
                    var arrayCycle = res.data[0].detailCycle
                    for (var i = 0; i < arrayCycle.length; i++) {
                        var convertDateD = new Date(arrayCycle[i].dateD)
                        var convertDateF = new Date(arrayCycle[i].dateF)
                        arrayCycle: arrayCycle.map((el, id) => (id === i ? Object.assign(el, { dateD: convertDateD }) : el))
                        arrayCycle: arrayCycle.map((el, id) => (id === i ? Object.assign(el, { dateF: convertDateF }) : el))
                    }
                    this.setState({
                        arrayCycle: arrayCycle
                    });
                })
            })
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetAnneeScolaire.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        anneScol: code_html.scolaire
                    });
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectCours.php').then(res => {
                console.log(res)
                this.setState({
                    cours: res.data
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEnseignants.php').then(res => {
                console.log(res)
                this.setState({
                    enseignants: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    updNomCycle = (event) => {
        this.setState({
            nomCycle: event.target.value
        });
    }

    updNbrHeure = (event) => {
        this.setState({
            nbrHeure: event.target.value
        });
    }

    updTarif = (event) => {
        this.setState({
            tarifCycle: event.target.value
        });
    }

    updTarifAvecCarte = (event) => {
        this.setState({
            tarifAvecCarte: event.target.value
        });
    }

    updTypeFormat = (selectedTypeFormat) => {
        this.setState({ selectedTypeFormat: selectedTypeFormat });
    }

    updMois = (selectedMois, index) => {
        let selectedMonth = selectedMois;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { mois: selectedMonth }) : el))
        });
    }

    updDateD = (d, index) => {
        let dateD = d;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { dateD: dateD }) : el))
        });
    }

    updDateF = (d, index) => {
        let dateF = d;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { dateF: dateF }) : el))
        });
    }

    updHorraireCours = (selectedCour, index) => {
        let selectedHorraireCour = selectedCour;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { horaire: selectedHorraireCour }) : el))
        });
    }
    addforfait = (selectedforcycle,index,indice) => {
        if (selectedforcycle != null) {
            var z = this.state.arrayCycle[index].infoEnseignant
    
            var x = selectedforcycle
            z: z.map((el, id) => (id === indice ? Object.assign(el, { selectedforcycle: x }) : el))
            this.setState({
                arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, {infoEnseignant: z }) : el))
            });
        }
        else {
           var z= this.state.arrayCycle[index].infoEnseignant
            var x = { value: '', label: '' }
            z: z.map((el, id) => (id === indice ? Object.assign(el, { selectedforcycle: x }) : el))
            this.setState({
                arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
            });
        }
          
    }
    updChoix = (e, index) => {
        let choix = e;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { selectedChoix: choix }) : el))
        });
    }

    additionLign = () => {
        let element = { mois: '', dateD: '', dateF: '', horaire: '', selectedChoix: "Non", infoEnseignant: [{ selectedEnseignent: [{ value: '', label: '' }], nbrHeureParEnseign: 0, honoraireParEnseign: 0.000, ancLignEnseign: -1 }] }
        this.setState({
            arrayCycle: [...this.state.arrayCycle, element]
        })
    }

    toggleLign = (index, idMois) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            indexLignCycleASupprimer: index,
            idMoisDetCyclSup: idMois.value
        }));
    }

    deleteLign = () => {
        this.state.arrayCycle.splice(this.state.indexLignCycleASupprimer, 1)
        let idMoisDetCyclSup = this.state.idMoisDetCyclSup
        this.setState({
            tabSupIdMoisDetCycle: [...this.state.tabSupIdMoisDetCycle, idMoisDetCyclSup]
        });
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteLign = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    updEnseignent = (selectedEnseignent, index, indice) => {
        if (selectedEnseignent != null) {
            var z = this.state.arrayCycle[index].infoEnseignant
            var x = selectedEnseignent
            z: z.map((el, id) => (id === indice ? Object.assign(el, { selectedEnseignent: x }) : el))
            this.setState({
                arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
            });
        }
        else {
            var z = this.state.arrayCycle[index].infoEnseignant
            var x = { value: '', label: '' }
            z: z.map((el, id) => (id === indice ? Object.assign(el, { selectedEnseignent: x }) : el))
            this.setState({
                arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
            });
        }
    }

    updNbrHeureParEnseign = (e, index, indice) => {
        var z = this.state.arrayCycle[index].infoEnseignant
        var x = e.target.value
        z: z.map((el, id) => (id === indice ? Object.assign(el, { nbrHeureParEnseign: x }) : el))
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
        });
    }

    updHonoraireParEnseign = (e, index, indice) => {
        var z = this.state.arrayCycle[index].infoEnseignant
        var x = e.target.value
        z: z.map((el, id) => (id === indice ? Object.assign(el, { honoraireParEnseign: x }) : el))
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: z }) : el))
        });
    }

    additionEnseignant = (index) => {
        var x = this.state.arrayCycle[index].infoEnseignant
        let element = { selectedEnseignent: "", nbrHeureParEnseign: 0, honoraireParEnseign: 0.000, idDetCycle: '', ancLignEnseign: -1 }
        x.push(element)
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { infoEnseignant: x }) : el))
        });
    }

    toggleLignEnseign = (index, indice, idDetCycle) => {
        this.setState(prevState => ({
            modalEnseign: !prevState.modalEnseign,
            indexLignEnseignASupprimer: index,
            indexSousLignEnseignASupprimer: indice,
            idDetCycleSup: idDetCycle
        }));
    }

    deleteLignEnseign = () => {
        this.state.arrayCycle[this.state.indexLignEnseignASupprimer].infoEnseignant.splice(this.state.indexSousLignEnseignASupprimer, 1)
        let idDetCycleSup = this.state.idDetCycleSup
        this.setState({
            tabDeleteIdDetCycle: [...this.state.tabDeleteIdDetCycle, idDetCycleSup]
        });
        this.setState(prevState => ({
            modalEnseign: !prevState.modalEnseign
        }));
    }

    nonDeleteLignEnseign = () => {
        this.setState(prevState => ({
            modalEnseign: !prevState.modalEnseign
        }));
    }

    modif = () => {
        // Convert date debut et date fin
        var arrCycle = this.state.arrayCycle
        var longeur = arrCycle.length
        for (var i = 0; i < longeur; i++) {
            // Convert date debut
            let dateDebut = arrCycle[i].dateD
            let monthD = '' + (dateDebut.getMonth() + 1);
            let dayD = '' + dateDebut.getDate();
            let yearD = dateDebut.getFullYear();
            if (monthD.length < 2)
                monthD = '0' + monthD;
            if (dayD.length < 2)
                dayD = '0' + dayD;
            var convertDateD = [yearD, monthD, dayD].join('-');
            arrCycle: arrCycle.map((el, id) => (id === i ? Object.assign(el, { dateD: convertDateD }) : el))
            // Convert date fin
            let dateFin = arrCycle[i].dateF
            let monthF = '' + (dateFin.getMonth() + 1);
            let dayF = '' + dateFin.getDate();
            let yearF = dateFin.getFullYear();
            if (monthF.length < 2)
                monthF = '0' + monthF;
            if (dayF.length < 2)
                dayF = '0' + dayF;
            var convertDateF = [yearF, monthF, dayF].join('-');
            arrCycle: arrCycle.map((el, id) => (id === i ? Object.assign(el, { dateF: convertDateF }) : el))
        }
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        var roleId = userPermissions[0].idRoleUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdCycle.php?id=' + this.state.idCycle,
            type: 'POST',
            data: {
                roleId: roleId,
                nomCycle: this.state.nomCycle,
                nbrHeureCycle: this.state.nbrHeure,
                tarifCycle: this.state.tarifCycle,
                tarifCycleAvecCarte: this.state.tarifAvecCarte,
                typeEduc: this.state.selectedTypeFormat,
                arrayCycle: arrCycle,
                tabSupIdMoisDetCycle: this.state.tabSupIdMoisDetCycle,
                tabDeleteIdDetCycle: this.state.tabDeleteIdDetCycle,
                userId: userId,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Cycles');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification cycle</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Cycles" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Cycle</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" className="form-control" value={this.state.nomCycle} onChange={(e) => this.updNomCycle(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Heure</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" className="form-control" value={this.state.nbrHeure} onChange={(e) => this.updNbrHeure(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tarif cycle</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" className="form-control" value={this.state.tarifCycle} onChange={(e) => this.updTarif(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tarif avec carte</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" className="form-control" value={this.state.tarifAvecCarte} onChange={(e) => this.updTarifAvecCarte(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Type Formation</label>
                                        <RadioGroup
                                            name="typeFormat"
                                            selectedValue={this.state.selectedTypeFormat}
                                            onChange={this.updTypeFormat}>
                                            <div>
                                                <label style={{ paddingRight: '5px' }}>
                                                    <Radio value={1} />Indviduelle
                                                </label>
                                                <label style={{ paddingRight: '5px' }}>
                                                    <Radio value={2} />Cycle
                                                </label>
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3 style={{ paddingLeft: '15px' }}>{'Planning Cycle Année Scolaire ' + this.state.anneScol}</h3>
                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Mois</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Debut</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Fin</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Horraires</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Confirmation</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Enseignent</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Supprimer</th>
                                            </tr>
                                        </thead>
                                        {this.state.arrayCycle.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><Select
                                                        value={el.mois}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.updMois(e, index)}
                                                        options={this.state.mois}
                                                    /></td>
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><DatePicker style={{ border: 'none' }} dateFormat={'dd/MM/yyyy'} selected={el.dateD} onChange={(e) => this.updDateD(e, index)} /></td>
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><DatePicker style={{ border: 'none' }} dateFormat={'dd/MM/yyyy'} selected={el.dateF} onChange={(e) => this.updDateF(e, index)} /></td>
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><Select
                                                        value={el.horaire}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.updHorraireCours(e, index)}
                                                        options={this.state.cours}
                                                    />
                                                    </td>
                                                    <td className="col-md-1">
                                                        <RadioGroup
                                                            name={"type" + index}
                                                            selectedValue={el.selectedChoix}
                                                            onChange={(e) => this.updChoix(e, index)}>
                                                            {this.state.listChoix.map(elm =>
                                                                <label style={{ paddingLeft: '5px' }}>
                                                                    <Radio value={elm} />{elm}
                                                                </label>
                                                            )}
                                                        </RadioGroup>
                                                    </td>
                                                    {this.state.arrayCycle[index].selectedChoix == "Oui" ?
                                                        (<Fragment>
                                                            <td className="col-md-4" style={{ textAlign: 'center' }}>
                                                                <div>
                                                                    <table className="table table-bordered">
                                                                        <thead >
                                                                            <tr>
                                                                                <th style={{ textAlign: 'center' }} scope="col">Prof</th>
                                                                                <th style={{ textAlign: 'center' }} scope="col">Type</th>
                                                                                <th style={{ textAlign: 'center' }} scope="col">Nbr-heure</th>
                                                                                <th style={{ textAlign: 'center' }} scope="col">Honoraire</th>
                                                                                <th style={{ textAlign: 'center' }} scope="col">Supp</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {el.infoEnseignant.map((ele, indice) =>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="col-md-3">
                                                                                        <div>
                                                                                            <Select
                                                                                                value={ele.selectedEnseignent}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                onChange={(e) => this.updEnseignent(e, index, indice)}
                                                                                                options={this.state.enseignants}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td> 
                                                                                    
                                                                                    <Select 
                                                                                                value={ele.selectedforcycle}
                                                                                                isClearable={true}
                                                                                                isSearchable={true}
                                                                                                onChange={(e) => this.addforfait(e, index,indice)}
                                                                                                options={this.state.listtype}
                                                                                            /> 
 
                                                                                    </td>
                                                                                    
                                                                                    <td className="col-md-2">
                                                                                        <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} value={ele.nbrHeureParEnseign} type="number" className="form-control" onChange={(e) => this.updNbrHeureParEnseign(e, index, indice)} />
                                                                                    </td>
                                                                                    <td className="col-md-3">
                                                                                        <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} value={Number.parseFloat(ele.honoraireParEnseign).toFixed(3)} type="number" className="form-control" onChange={(e) => this.updHonoraireParEnseign(e, index, indice)} />
                                                                                    </td>
                                                                                    <td className="col-md-1">
                                                                                        <button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLignEnseign(index, indice, ele.idDetCycle)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        )}
                                                                    </table>
                                                                </div>
                                                        Ajout
                                                        <i onClick={() => this.additionEnseignant(index)} className="fas fa-plus-circle"></i>
                                                            </td>
                                                        </Fragment>)
                                                        : (<Fragment>
                                                            <td>
                                                            </td>
                                                        </Fragment>)}
                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLign(index, el.mois)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                        <Button style={{ borderRadius: '5px' }} onClick={this.additionLign} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal isOpen={this.state.modal} toggle={this.nonDeleteLign} className={this.props.className} fade={false}>
                        <ModalHeader toggle={this.nonDeleteLign}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                        <ModalBody>
                            <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                                </div>
                                <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                    <div><button onClick={() => this.deleteLign()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                    <div><button onClick={() => this.nonDeleteLign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.modalEnseign} toggle={this.nonDeleteLignEnseign} className={this.props.className} fade={false}>
                        <ModalHeader toggle={this.nonDeleteLignEnseign}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                        <ModalBody>
                            <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                                </div>
                                <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                    <div><button onClick={() => this.deleteLignEnseign()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                    <div><button onClick={() => this.nonDeleteLignEnseign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifHonoraireEnsCycle);