import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import Table from "reactstrap/lib/Table";
import { withRouter } from "react-router";

class AjoutNoteExaman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNiveau: [],
      listGroupes: [],
      listMatieres: [],
      listEleves: [],
      selectedGroupe: "",
      selectedNiveau: "",
      blockListEleve: false,
      dateAjoutNote: new Date(),
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectNiveau.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listNiveau: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addNiveau = (selectedNiveau) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedNiveau != null) {
      this.setState({ selectedNiveau }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectedGroupe.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listGroupes: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        selectedNiveau: "",
      });
    }
  };

  addGroup = (selectedGroup) => {
    if (selectedGroup != null) {
      this.setState({ selectedGroup }, () => { });
    } else {
      this.setState({
        selectedGroup: "",
      });
    }
  };

  affiche = (selectedMatieres) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedMatieres != null) {
      this.setState({ selectedMatieres: selectedMatieres }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectEleveByGoupeId.php",
          type: "POST",
          data: {
            groupeId: this.state.selectedGroup.value,
            matiereId: this.state.selectedMatieres.value,
            niveauId: this.state.selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listMatieres: code_html[0],
              listEleves: code_html[1],
              blockListEleve: true,
            });
          },
        });
      });
    } else {
      this.setState({
        selectedMatieres: "",
      });
    }
  };

  changeNote = (indexOfLigne, indexOfColumn, event) => {
    if (parseFloat(event) <= 20) {
      var arrayEleve = this.state.listEleves;
      arrayEleve[indexOfLigne].examenNote[indexOfColumn].noteExamen = event;
      this.setState({ listEleves: arrayEleve });
    } else if (event == "") {
      var arrayEleve = this.state.listEleves;
      arrayEleve[indexOfLigne].examenNote[indexOfColumn].noteExamen = "";
      this.setState({ listEleves: arrayEleve });
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      });
    }
  };


  ajoutNoteMatiere = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.dateAjoutNote != "") {
      let dateAjoutNote = this.state.dateAjoutNote;
      let month_three = "" + (dateAjoutNote.getMonth() + 1);
      let day_three = "" + dateAjoutNote.getDate();
      let year_three = dateAjoutNote.getFullYear();
      if (month_three.length < 2) month_three = "0" + month_three;
      if (day_three.length < 2) day_three = "0" + day_three;
      var convertDateAjoutNote = [year_three, month_three, day_three].join("-");
    } else {
      var convertDateAjoutNote = "";
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/AjoutNoteExam.php",
      type: "POST",
      data: {
        niveauId: this.state.selectedNiveau.value,
        groupeId: this.state.selectedGroup.value,
        listEleves: this.state.listEleves,
        dateAjoutNote: convertDateAjoutNote,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Menu");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Ajout EFF</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Niveau</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addNiveau}
                        options={this.state.listNiveau}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroup}
                        options={this.state.listGroupes}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Afficher
                    </button>
                  </div>
                </div>
                {this.state.blockListEleve == true ? (
                  <div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Eleve</th>
                          {this.state.listMatieres.map((elem) => (
                            <th style={{ textAlign: "center" }}>
                              {elem.label}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listEleves.map((el, indexOfLigne) => (
                          <tr>
                            <td> {el.nomEleve} </td>
                            {el.examenNote.map((obj, indexOfColumn) => (
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <input
                                    style={{
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                    type="text"
                                    value={obj.noteExamen}
                                    onChange={(e) =>
                                      this.changeNote(
                                        indexOfLigne,
                                        indexOfColumn,
                                        e.target.value
                                      )
                                    }
                                  ></input>
                                </div>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <button
                      style={{ borderRadius: "5px", marginLeft: "620px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ajoutNoteMatiere}
                    >
                      Confirmer
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
      </div>
    );
  }
}

export default withRouter(AjoutNoteExaman);

