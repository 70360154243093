import React, { Component } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import jQuery from "jquery";
import "./paginate.css";

class Statistique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recetteMois: [],
      totEsp: 0,
      totChec: 0,
      totChecFor: 0,
      totVir: 0,
      totSom: 0,
    };
  }

  componentDidMount() { 
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var idRoleUser = userPermissions[0].idRoleUser;
      if (idRoleUser == 1) {
        var anneeScolaire = userPermissions[0].anneeScolaire;
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/Statistique.php",
          type: "POST",
          data: {
            query: "",
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState(
              {
                recetteMois: code_html,
              },
              () => {
                var espece = 0;
                var cheque = 0;
                var virement = 0;
                var sommeTot = 0;
                for (var i = 0; i < this.state.recetteMois.length; i++) {
                  espece += this.state.recetteMois[i].espece;
                  cheque += this.state.recetteMois[i].chec;
                  virement += this.state.recetteMois[i].virement;
                  sommeTot += this.state.recetteMois[i].total;
                }
                this.setState({
                  totEsp: espece,
                  totChec: cheque,
                  totChecFor: 0,
                  totVir: virement,
                  totSom: sommeTot,
                });
              }
            );
          },
        });
      } else {
        localStorage.clear();
        document.location = "/";
      }
    } else {
      document.location = "/";
    }
  }

  impr = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/ImprStatAnnee.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: function (code_html, statut) {
        var server_path = code_html.server_path;
        var file_path = code_html.file_path;
        var path = server_path + file_path;
        window.open(path, "_blank");
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Statistique</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.impr}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
                <table
                  style={{ marginTop: "10px" }}
                  className="table table-bordered"
                >
                  <thead>
                    <tr>
                      <th scope="col" style={{ textAlign: "center" }}>
                        <i
                          style={{ color: "#ffc40c" }}
                          class="fas fa-hand-holding-usd"
                        ></i>
                      </th>
                      {this.state.recetteMois.map((el, index) => (
                        <th
                          style={{
                            textAlign: "center",
                            color: "#D9534F",
                            fontWeight: "bold",
                          }}
                          scope="col"
                        >
                          {el.mois}
                        </th>
                      ))}
                      <th
                        style={{
                          textAlign: "center",
                          color: "#87CEEB",
                          fontWeight: "bold",
                        }}
                        scope="col"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#337AB7",
                          fontWeight: "bold",
                        }}
                      >
                        Espéce
                      </td>
                      {this.state.recetteMois.map((el, index) => (
                        <td style={{ textAlign: "center" }}>{el.espece}</td>
                      ))}
                      <td style={{ textAlign: "center" }}>
                        {this.state.totEsp}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#337AB7",
                          fontWeight: "bold",
                        }}
                      >
                        Chéque
                      </td>
                      {this.state.recetteMois.map((el, index) => (
                        <td style={{ textAlign: "center" }}>{el.chec}</td>
                      ))}
                      <td style={{ textAlign: "center" }}>
                        {this.state.totChec}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#337AB7",
                          fontWeight: "bold",
                        }}
                      >
                        Chéque formation
                      </td>
                      {this.state.recetteMois.map((el, index) => (
                        <td style={{ textAlign: "center" }}>{el.checFor}</td>
                      ))}
                      <td style={{ textAlign: "center" }}>
                        {this.state.totChecFor}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#337AB7",
                          fontWeight: "bold",
                        }}
                      >
                        Virement
                      </td>
                      {this.state.recetteMois.map((el, index) => (
                        <td style={{ textAlign: "center" }}>{el.virement}</td>
                      ))}
                      <td style={{ textAlign: "center" }}>
                        {this.state.totVir}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          color: "#337AB7",
                          fontWeight: "bold",
                        }}
                      >
                        Somme total
                      </td>
                      {this.state.recetteMois.map((el, index) => (
                        <td style={{ textAlign: "center" }}>{el.total}</td>
                      ))}
                      <td style={{ textAlign: "center" }}>
                        {this.state.totSom}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Statistique;
