import React, { Component } from "react";
import jQuery from "jquery";
import "./paginate.css";
import { withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

class ImpDecla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anneeScolaire: "",
      anneeScolaireAfter: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var id_annee = arrayUrl[1];
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprDeclaration.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          id_annee: id_annee,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            data: code_html,
            text: code_html.text,
            annee: code_html.annee,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  impr = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div style={{ margin: "0px" }} className="row">
          <h4
            style={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              fontSize: "30px",
              marginTop: "4%",
            }}
          >
            Declaration Enseignants : {this.state.annee}
          </h4>
        </div>

        {ReactHtmlParser(this.state.text)}

        <div
          style={{ display: "flex", justifyContent: "center", padding: "5px" }}
        >
          <i
            id="print_me_not"
            style={{
              paddingTop: "10px",
              fontSize: "30px",
              cursor: "pointer",
              color: "#52ac52",
            }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImpDecla);
