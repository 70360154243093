import React, { Component } from "react";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class DemandeAttestation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Eleve",
          selector: "nomEleve",
          sortable: false,
          center: true,
          style: { width: "30%" },
        },
        {
          name: "Date",
          selector: "date",
          sortable: false,
          center: true,
          style: { width: "20%" },
        },
        {
          name: "Motif",
          selector: "attestation",
          sortable: false,
          center: true,
          style: { width: "30%" },
        },
        {
          name: "",
          cell: (row) => (
            <div style={{ width: '100%' }}>
              <Select
                value={row.selectTypeAtt}
                isClearable={true}
                isSearchable={true}
                onChange={(e) => this.addAttestaion(e, row.attestationId)}
                options={row.typeAtt}
              />
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "20%",
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      pending: true,
      listEleve: [],
      typeAtt: [],
      selectEleve: "",
      selectTypeAtt: [],
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/DemandeAttestation.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetElvByDemande.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listEleve: code_html,
          });
        },
      });
    }
  }

  newElement(page, totalRows) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        pending: true,
      },
      () => {
        if (this.state.selectEleve != "") {
          var eleveId = this.state.selectEleve.value;
        } else {
          var eleveId = "";
        }
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/DemandeAttestationSearch.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
            eleveId: eleveId,
            param: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: page,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  perPage(currentRowsPerPage, currentPage) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        nbrEltperPage: currentRowsPerPage,
        pending: true,
      },
      () => {
        if (this.state.selectEleve != "") {
          var eleveId = this.state.selectEleve.value;
        } else {
          var eleveId = "";
        }
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/DemandeAttestationSearch.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
            eleveId: eleveId,
            param: this.state.filterElement,
            limit: currentRowsPerPage,
            page: currentPage,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  filterSearch(event) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        if (this.state.selectEleve != "") {
          var eleveId = this.state.selectEleve.value;
        } else {
          var eleveId = "";
        }
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/DemandeAttestationSearch.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
            eleveId: eleveId,
            param: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  addEleve = (selectEleve) => {
    if (selectEleve != null) {
      this.setState({ selectEleve });
    } else {
      this.setState({
        selectEleve: "",
      });
    }
  };

  addAttestaion = (selectTypeAtt, attestationId) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/ModifEtatDemande.php",
      type: "POST",
      data: {
        selectTypeAtt: selectTypeAtt.value,
        attestationId: attestationId,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/DemandeAttestation.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
            query: "",
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      },
    });
  };

  recherche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectEleve != "") {
      var eleveId = this.state.selectEleve.value;
    } else {
      var eleveId = "";
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/DemandeAttestationSearch.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        eleveId: eleveId,
        param: this.state.filterElement,
        limit: this.state.nbrEltperPage,
        page: 1,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  afficherTout = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        selectEleve: "",
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/DemandeAttestation.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
            query: "",
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  print = () => {
    if (this.state.selectEleve != "") {
      var eleveId = this.state.selectEleve.value;
    } else {
      var eleveId = ""
    }
    window.open("http://artsmetiers.mtd-app.com/ImprDemandeAttestation?id=" + eleveId);
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Demande Attestation</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="mb-0 form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Eleve</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addEleve}
                          options={this.state.listEleve}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.afficherTout}
                    >
                      Afficher tout
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.print}
                    >
                      Imprimer
                    </button>
                  </div>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                  noHeader={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DemandeAttestation);
