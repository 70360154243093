import React, { Component } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import axios from 'axios'

class ExplNiveau extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFormation: '',
            nomNiveau: '',
            mntInitial: '',
            niveaux: []
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            let idNiveau = this.props.location.state.idNiveau;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetNiveauById.php?id=' + idNiveau).then(res => {
                this.setState({
                    selectedFormation: res.data[0].formation.label,
                    nomNiveau: res.data[0].nomNiveau,
                    periodeNiv: res.data[0].periodeNiv,
                    niveaux: res.data[1]
                }, () => {
                    if (res.data[1].length > 0) {
                        this.setState({
                            mntInitial: res.data[1][0].mntInitiale
                        });
                    }
                    else {
                        this.setState({
                            mntInitial: 0
                        });
                    }
                })
            })
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Informations niveau</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Niveaux" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Formation</label>
                                            <input type="text" disabled value={this.state.selectedFormation} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau</label>
                                            <input type="text" disabled value={this.state.nomNiveau} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Obsérvation d'attestation (durée formation)</label>
                                            <input type="text" disabled className="form-control" value={this.state.periodeNiv} style={{ backgroundColor: 'white', borderRadius: '5px' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Montant initial</label>
                                            <input type="number" disabled value={this.state.mntInitial} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px' }} />
                                        </div>
                                    </div>
                                </div>
                                {this.state.niveaux.length > 0 ?
                                    (<div className="row">
                                        <div className="table-responsive" style={{ textAlign: 'center', width: '100%' }}>
                                            <table className="table align-items-center table-flush">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th style={{ textAlign: 'center' }} scope="col">Type paiement elève</th>
                                                        <th style={{ textAlign: 'center' }} scope="col">Montant</th>
                                                    </tr>
                                                </thead>
                                                {this.state.niveaux.map(el =>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {el.typePayEleve}
                                                            </td>
                                                            <td style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                {el.infoPaiement.map(ele => <div><div><p>{ele.itemInfo}</p></div><div><input disabled type="number" value={ele.itemVal} style={{ textAlign: 'center', height: '38px', borderRadius: '5px' }} /></div></div>)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </table>
                                        </div>
                                    </div>) : (null)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExplNiveau;