import React, { Component } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import axios from 'axios'

class ExplMatieresFormat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiliere: '',
            selectedFormation: '',
            selectedNiveau: '',
            matieresParFormat: []
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            let idNiveau = this.props.location.state.idNiveau;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetMatForById.php?id=' + idNiveau).then(res => {
                this.setState({
                    selectedFiliere: res.data[0].filiere.label,
                    selectedFormation: res.data[0].formatSpec.label,
                    selectedNiveau: res.data[0].niveau.label,
                    matieresParFormat: res.data[1]
                })
            })
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Informations matiéres par niveau</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/MatieresFormat" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Filiére</label>
                                            <input type="text" disabled value={this.state.selectedFiliere} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Formation-Specialité</label>
                                            <input type="text" disabled value={this.state.selectedFormation} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau de base</label>
                                            <input type="text" disabled value={this.state.selectedNiveau} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="table-responsive" style={{ textAlign: 'center', width: '100%' }}>
                                        <table className="table align-items-center table-flush">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th style={{ textAlign: 'center' }} scope="col">Matiéres</th>
                                                </tr>
                                            </thead>
                                            {this.state.matieresParFormat.map(el =>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {el.matiere.label}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExplMatieresFormat;