import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import Table from "reactstrap/lib/Table";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";

class ListVierge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNiveau: [],
      listGroupes: [],
      listMatieres: [],
      listSousMat: [],
      listNatureNote: [],
      listEleves: [],
      selectedGroupe: "",
      selectedNiveau: "",
      selectedMatiere: "",
      selectedSousMat: "",
      selectedTrimestre: "",
      blockListEleve: false,
      blockSousMatiere: false,
      dateAjoutNote: new Date(),
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectNiveau.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listNiveau: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addNiveau = (selectedNiveau) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedNiveau != null) {
      this.setState({ selectedNiveau }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectedGroupe.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listGroupes: code_html,
            });
          },
        });
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectedMatieres.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listMatieres: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        listGroupes: [],
        listMatieres: [],
      });
    }
  };

  addGroup = (selectedGroupe) => {
    if (selectedGroupe != null) {
      this.setState({ selectedGroupe }, () => {});
    } else {
      this.setState({
        selectedGroupe: "",
      });
    }
  };

  addMatiere = (selectedMatiere) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedMatiere != null) {
      this.setState({ selectedMatiere: selectedMatiere }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectSousMatByMat.php",
          type: "POST",
          data: {
            matiereId: selectedMatiere.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listSousMat: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        listSousMat: [],
        listEleves: [],
        listNatureNote: [],
      });
    }
  };

  addTrimestre = (selectedTrimestre) => {
    this.setState({ selectedTrimestre });
  };

  addSousMat = (selectedSousMat) => {
    this.setState({ selectedSousMat: selectedSousMat });
  };

  affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.listSousMat.length == 0) {
      if (this.state.selectedSousMat != "") {
        var idSousMat = "";
      } else {
        var idSousMat = -1;
      }
    } else {
      var idSousMat = this.state.selectedSousMat.value;
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/SelectedEleveByIdGroupe.php",
      type: "POST",
      data: {
        groupeId: this.state.selectedGroupe.value,
        niveauId: this.state.selectedNiveau.value,
        matiereId: this.state.selectedMatiere.value,
        anneeScolaire: anneeScolaire,
        sousMatId: idSousMat,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          listEleves: code_html[1],
          listNatureNote: code_html[0],
          blockListEleve: true,
        });
      },
    });
  };

  print = () => {
    if (this.state.listSousMat.length == 0) {
      if (this.state.selectedSousMat != "") {
        var idSousMat = "";
      } else {
        var idSousMat = -1;
      }
    } else {
      var idSousMat = this.state.selectedSousMat.value;
    }
    var id =
      this.state.selectedGroupe.value +
      "/" +
      this.state.selectedMatiere.value +
      "/" +
      this.state.selectedNiveau.value +
      "/" +
      idSousMat;
    window.open("http://artsmetiers.mtd-app.com/ImprListVierge?id=" + id);
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">List Vierge</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Niveau</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addNiveau}
                        options={this.state.listNiveau}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroup}
                        options={this.state.listGroupes}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Matière</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addMatiere}
                        options={this.state.listMatieres}
                      />
                    </div>
                  </div>
                  {this.state.listSousMat.length > 0 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Sous Matière</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addSousMat}
                          options={this.state.listSousMat}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.print}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
                {this.state.blockListEleve == true &&
                this.state.listEleves.length > 0 ? (
                  <div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ textAlign: "center" }}>Eleve</th>
                          {this.state.listNatureNote.map((elem) => (
                            <th style={{ textAlign: "center" }}>
                              {elem.natNot}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listEleves.map((el, indexOfLigne) => (
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {indexOfLigne + 1}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {" "}
                              {el.nomEleve}{" "}
                            </td>{" "}
                            {el.note.map((element, indexOfColumn) => (
                              <td style={{ textAlign: "center" }}>
                                <input
                                  type="text"
                                  disabled
                                  style={{ textAlign: "center" }}
                                ></input>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ListVierge);
