import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';

class ModifUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idUser: '',
            nom: '',
            roles: [],
            selectedRole: '',
            login: '',
            password: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var idRoleUser = userPermissions[0].idRoleUser
            if (idRoleUser == 1) {
                var idUser = this.props.location.state.idUser;
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetUserById.php?id=' + idUser).then(res => {
                    console.log(res)
                    this.setState({
                        idUser: res.data.idUser,
                        nom: res.data.nom,
                        selectedRole: res.data.role,
                        login: res.data.login,
                        password: res.data.password
                    })
                })
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectRoles.php').then(res => {
                    console.log(res)
                    this.setState({
                        roles: res.data
                    })
                })
            }
            else {
                localStorage.clear();
                this.props.history.push('/');
            }
        }
        else {
            document.location = '/'
        }
    }

    modifNom = (event) => {
        this.setState({
            nom: event.target.value
        });
    }

    modifRole = selectedRole => {
        this.setState({ selectedRole });
        console.log(`Role selected:`, selectedRole);
    };

    modifLogin = (event) => {
        this.setState({
            login: event.target.value
        });
    }

    modifPassword = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    modif = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdUser.php?id=' + this.state.idUser,
            type: 'POST',
            data: {
                nom: this.state.nom,
                idRole: this.state.selectedRole.value,
                login: this.state.login,
                password: this.state.password,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Users');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification utilisateur</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Users" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom</label>
                                            <input type="text" className="form-control" value={this.state.nom} onChange={this.modifNom} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Role</label>
                                            <Select
                                                value={this.state.selectedRole}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.modifRole}
                                                options={this.state.roles}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Login</label>
                                            <input type="text" className="form-control" value={this.state.login} onChange={this.modifLogin} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mot de passe</label>
                                            <input type="text" className="form-control" value={this.state.password} onChange={this.modifPassword} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifUser);