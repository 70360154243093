import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'

class ExplNivNateNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayData: [],
      listNiveau: [],
      selectedNiveau: '',
      listMat: []
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var niveauId = this.props.location.state.niveauId;
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetNivNatNoteById.php",
        type: "POST",
        data: {
          niveauId: niveauId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            selectedNiveau: code_html[0].niveau,
            arrayData: code_html
          });
        },
      });
    }
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Consultation niveau nature note</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                  <Link to="/NiveauNatureNote" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Niveau</label>
                      <Select
                        isDisabled={true}
                        isClearable={true}
                        isSearchable={true}
                        value={this.state.selectedNiveau}
                        onChange={this.addNiveau}
                        options={this.state.listNiveau}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <table className="col-md-12">
                    <thead>
                      <th style={{ textAlign: 'center', padding: '5px' }}>Matiere</th>
                      <th style={{ textAlign: 'center', padding: '5px' }}>Sous-matiére</th>
                    </thead>
                    <tbody>
                      {this.state.arrayData.map((el, index) =>
                        <tr>
                          <td className="col-md-4">
                            <div className="form-group">
                              <Select
                                isDisabled={true}
                                value={el.matiere}
                              />
                            </div>
                          </td>
                          <td className="col-md-4">
                            <div className="form-group">
                              <Select
                                isDisabled={true}
                                value={el.sousMat}
                              />
                            </div>
                          </td>
                          <td className="col-md-4">
                            <div className="form-group">
                              <Select
                                isDisabled={true}
                                value={el.note}
                                isMulti={true}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ExplNivNateNote;