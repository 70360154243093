import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';

class ModifMatiere extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idMatiere: '',
            matiere: {},
            ListTypeEns: [],
            selectedTypeEns: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var idMatiere = this.props.location.state.idMatiere;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetMatiereById.php?id=' + idMatiere).then(res => {
                console.log(res)
                this.setState({
                    idMatiere: res.data.idMatiere,
                    matiere: res.data
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectTypeEns.php').then(res => {
                console.log(res)
                this.setState({
                    ListTypeEns: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    updCode = (event) => {
        let code = event.target.value;
        this.setState({
            matiere: Object.assign(this.state.matiere, { code: code })
        });
    }

    updNomMatiere = (event) => {
        let nomMatiere = event.target.value;
        this.setState({
            matiere: Object.assign(this.state.matiere, { nomMatiere: nomMatiere })
        });
    }

    updAbrev = (event) => {
        let abrev = event.target.value;
        this.setState({
            matiere: Object.assign(this.state.matiere, { abrev: abrev })
        });
    }

    updTypeEns = (selectedTypeEns) => {
        let typeEns = selectedTypeEns;
        this.setState({
            matiere: Object.assign(this.state.matiere, { typeEns: typeEns })
        });
    }

    modif = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdMatiere.php?id=' + this.state.idMatiere,
            type: 'POST',
            data: {
                matiere: this.state.matiere,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Matieres');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification matiére</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Matieres"> <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <table style={{ width: '100%', marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Code</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Abrev</th>
                                                <th style={{ textAlign: 'center' }} scope="col">TypeEns</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="col-md-3" style={{ textAlign: 'center' }}><input type="text" value={this.state.matiere.code} onChange={(e) => this.updCode(e)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                <td className="col-md-3" style={{ textAlign: 'center' }}><input type="text" value={this.state.matiere.nomMatiere} onChange={(e) => this.updNomMatiere(e)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                <td className="col-md-3" style={{ textAlign: 'center' }}><input type="text" value={this.state.matiere.abrev} onChange={(e) => this.updAbrev(e)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                <td className="col-md-3" ><Select
                                                    value={this.state.matiere.typeEns}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => this.updTypeEns(e)}
                                                    options={this.state.ListTypeEns}
                                                />  </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifMatiere);