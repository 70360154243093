import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";
import './paginate.css'

class ImprConsNoteExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockNote: false,
      listEleves: [],
      listMatieres: [],
      listEG: [],
      listET: [],
      listEP: [],
      filiere: "",
      niveau: "",
      groupe: "",
      annee: "",
      anneeScolaire: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var groupeId = arrayUrl[1];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprConsNoteExam.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          groupeId: groupeId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState(
            {
              listMatieres: code_html[0],
              listEG: code_html[0][0],
              listET: code_html[0][1],
              listEP: code_html[0][2],
              listEleves: code_html[1],
            },
            () => {
              var lengthEg = this.state.listEG.length + 1;
              var lengthEt = this.state.listET.length + 1;
              var lengthEp = this.state.listEP.length + 1;
              this.setState({
                spanEg: lengthEg,
                spanEt: lengthEt,
                spanEp: lengthEp,
              });
            }
          );
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoGroupe.php",
        type: "POST",
        data: {
          groupeId: groupeId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            filiere: code_html.nomFiliere,
            niveau: code_html.niveau,
            groupe: code_html.groupe,
            annee: code_html.annee,
            anneeScolaire: code_html.anneeScolaire,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
          <table
            border={0}
            align="center"
            cellSpacing={1}
            cellPadding={4}
            width={1100}
          >
            <tbody>
              <tr>
                <td width={546} height={54}>
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b style={{corlor:'black'}}>
                      <u>Session : </u>
                    </b>
                    &nbsp;Juillet {this.state.annee}
                  </font>
                </td>
                <td width={535} align height={54}>
                  <b>
                    <font
                      style={{ fontSize: "14px" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      <h3>Résultat Examen Final &nbsp;</h3>
                    </font>
                  </b>
                </td>
              </tr>
              <tr>
                <td height={42}>
                  {" "}
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    {" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b style={{corlor:'black'}}>
                      <u>Spécialité:</u>
                    </b>
                    &nbsp;{this.state.filiere}
                  </font>
                </td>
                <td width={535} align="left">
                  <b>
                    <font
                      style={{ fontSize: "14px",color:'black' }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      Année Scolaire: {this.state.anneeScolaire}
                    </font>
                  </b>
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b style={{corlor:'black !important',fontWeight:'bold'}}>
                      <u>Niveau:</u>
                    </b>
                    &nbsp;{this.state.niveau}
                  </font>{" "}
                </td>
                <td width={535} align="left">
                  {" "}
                  <b style={{corlor:'black'}}>
                    <u>Groupe:</u>
                  </b>
                  &nbsp;{this.state.groupe}
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        <Table>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                N°
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                Apprenant
              </th>
              <th
                colspan={this.state.spanEg}
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                Enseignement Général
              </th>
              <th
                colspan={this.state.spanEt}
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                Enseignement Technique
              </th>
              <th
                colspan={this.state.spanEp}
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                Enseignement Pratique
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                Moyenne Trimestrielle{" "}
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                Rang
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ textAlign: "center", border: "solid", color: "black" }}
              ></td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Module
              </td>
              {this.state.listEG.map((el) => (
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {el}
                </td>
              ))}
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  backgroundColor: "#C0C0C0",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {" "}
                Moyenne EG{" "}
              </td>
              {this.state.listET.map((el) => (
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {el}
                </td>
              ))}
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  backgroundColor: "#C0C0C0",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {" "}
                Moyenne ET{" "}
              </td>
              {this.state.listEP.map((el) => (
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {el}
                </td>
              ))}
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  backgroundColor: "#C0C0C0",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {" "}
                Moyenne EP{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                }}
              ></td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                }}
              ></td>
            </tr>
            {this.state.listEleves.map((elem, index) => (
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {index + 1}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {elem.eleve}
                </td>
                {elem.allNote.noteEG.map((ele) => (
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {ele.matiereNote}
                  </td>
                ))}
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                  }}
                >
                  {" "}
                  {elem.allNote.moyEG}{" "}
                </td>
                {elem.allNote.noteET.map((ele) => (
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {ele.matiereNote}
                  </td>
                ))}
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                  }}
                >
                  {" "}
                  {elem.allNote.moyET}{" "}
                </td>
                {elem.allNote.noteEP.map((ele) => (
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {ele.matiereNote}
                  </td>
                ))}
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                  }}
                >
                  {" "}
                  {elem.allNote.moyEP}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                  }}
                >
                  {" "}
                  {elem.moyenneGen}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {index + 1}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ display: "flex", justifyContent: "center", marginTop : '20px' }}>
          <i id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprConsNoteExam);
