import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import './paginate.css'

class ImprConseilAssistance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conseilAssistance: [],
            scolaire: ''
        };
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
            var url = window.location.href;
            var arrayUrl = url.split("=");
            var ensId = arrayUrl[1];
            var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
            var anneeScolaire = userPermissions[0].anneeScolaire;
            var scolaire = anneeScolaire + '/' + (parseInt(anneeScolaire) + 1)
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetConseilAssistance.php",
                type: "POST",
                data: {
                    ensId: ensId,
                    anneeScolaire: anneeScolaire
                },
                dataType: "json",
                success: (code_html, statut) => {
                    this.setState({
                        conseilAssistance: code_html,
                        scolaire: scolaire,
                        somme: code_html[0].somme
                    });
                },
            });
        } else {
            document.location = "/";
        }
    }

    print = () => {
        window.print();
    };

    render() {
        return (
            <div className="white-box">
                <div
                    style={{
                        textAlign: "center"
                    }}
                    className="row"
                >
                    <h2 style={{ fontWeight: 'bold' }}>Conseils et assistance</h2>
                    <h2 style={{ fontWeight: 'bold' }}>Année Scolaire : {this.state.scolaire}</h2>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <table style={{ border: "solid" }}>
                        <thead>
                            <th
                                style={{ textAlign: "center", border: "solid", color: "black", width: '20%' }}
                            >
                                Date
                            </th>
                            <th
                                style={{ textAlign: "center", border: "solid", color: "black", width: '30%' }}
                            >
                                Enseignant
                            </th>
                            <th
                                style={{ textAlign: "center", border: "solid", color: "black", width: '40%' }}
                            >
                                Service
                            </th>
                            <th
                                style={{ textAlign: "center", border: "solid", color: "black", width: '10%' }}
                            >
                                Montant
                            </th>
                        </thead>
                        <tbody>
                            {this.state.conseilAssistance.map((elem, index) => (
                                <tr>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            border: "solid",
                                            color: "black",
                                        }}
                                    >
                                        {elem.date}
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            border: "solid",
                                            color: "black",
                                        }}
                                    >
                                        {elem.ens}
                                    </td>
                                    <td
                                        style={{
                                            padding: '5px',
                                            border: "solid",
                                            color: "black",
                                        }}
                                    >
                                        {elem.service}
                                    </td>
                                    <td
                                        style={{
                                            textAlign: "center",
                                            border: "solid",
                                            color: "black",
                                        }}
                                    >
                                        {elem.montant}
                                    </td>
                                </tr>
                            ))}
                        </tbody> 
                        <tr>
                            <th colSpan="3" className="col-md-8" style={{ textAlign: 'center', border: 'solid', color: 'black', fontWeight: 'bold', textAlign: 'right', fontSize: '20px' }} scope="col">Total</th>
                            <th className="col-md-1" style={{ textAlign: 'center', border: 'solid', color: 'black', fontWeight: 'bold', fontSize: '20px' }} scope="col">{this.state.somme}</th>
                        </tr>
                    </table>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                    }}
                >
                    <i id="print_me_not"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                        onClick={this.print}
                        class="fas fa-print"
                    ></i>
                </div>
            </div>
        );
    }
}

export default withRouter(ImprConseilAssistance);
