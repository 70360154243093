import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";

class ReleveNoteBTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listEleves: [],
      selectEleve: "",
      inscriptionId: "",
      niveau: "",
      nomFiliere: "",
      groupe: "",
      notes: [],
      moyenne: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetElevesBtsInscri.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listEleves: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addEleve = (selectEleve) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectEleve != null) {
      this.setState({ selectEleve }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetInfoByIdEleve.php",
          type: "POST",
          data: {
            eleveId: this.state.selectEleve.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              inscriptionId: code_html.inscriptionId,
              niveau: code_html.niveau,
              nomFiliere: code_html.nomFiliere,
              groupe: code_html.groupe,
            });
          },
        });
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ReleveNoteBTS.php",
          type: "POST",
          data: {
            eleveId: this.state.selectEleve.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              notes: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        selectEleve: "",
      });
    }
  };

  affiche = () => {
    this.setState({
      blockNote: true,
    });
  };

  print = () => {
    var eleveId = this.state.selectEleve.value;
    window.open("http://artsmetiers.mtd-app.com/ImprReleveNoteBTS?eleveId=" + eleveId);
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Relevé De Notes </h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Eleve</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addEleve}
                        options={this.state.listEleves}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Afficher
                    </button>
                  </div>
                  <div>
                    <button

                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.print}
                    >
                      <i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print" />
                      Imprimer
                    </button>
                  </div>
                </div>
                {this.state.blockNote == true ? (
                  <div>
                    <div style={{ margin: "5vh" }}>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Numéro Inscription : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          {this.state.inscriptionId}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Formation : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          De October à Juillet
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Spécialité : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          {this.state.nomFiliere}{" "}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Niveau : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          {this.state.niveau}{" "}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Groupe : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          {this.state.groupe}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h3>Relevé de notes</h3>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th style={{ color: "#ac3939" }}>Enseignement</th>
                          <th></th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            Moyenne Enseignement
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.notes.map((el) => (
                          <tr>
                            <td>{el.notes.nameEP}</td>
                            <td>
                              {el.notes.noteEP.map((elem) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{ width: "20vw" }}>
                                    {" "}
                                    {elem.matiere}{" "}
                                  </p>
                                  <p> {elem.matiereNote} </p>
                                </div>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.notes.moyEP} </p>
                            </td>
                          </tr>
                        ))}
                        {this.state.notes.map((el) => (
                          <tr>
                            <td>{el.notes.nameET}</td>
                            <td>
                              {el.notes.noteET.map((elem) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{ width: "20vw" }}>
                                    {" "}
                                    {elem.matiere}{" "}
                                  </p>
                                  <p> {elem.matiereNote} </p>
                                </div>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.notes.moyET} </p>
                            </td>
                          </tr>
                        ))}
                        {this.state.notes.map((el) => (
                          <tr>
                            <td>{el.notes.nameEG}</td>
                            <td>
                              {el.notes.noteEG.map((elem) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{ width: "20vw" }}>
                                    {" "}
                                    {elem.matiere}{" "}
                                  </p>
                                  <p> {elem.matiereNote} </p>
                                </div>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.notes.moyEG} </p>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                              Moyenne :{" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {this.state.notes.map((element) => (
                              <p> {element.moyGen} </p>
                            ))}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReleveNoteBTS);
