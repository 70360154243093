import React, { Component } from "react";
import jQuery from "jquery";
import "rc-checkbox/assets/index.css";
import "./paginate.css";

class ImprTrimestre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moisExist: "",
      nomMois: "",
      arrayEnsNonPayer: [],
      disabledEspece: false,
      disabledCheque: false,
      totalAPayer: 0,
      totalACompte: 0,
      totalEsp: 0,
      totalChec: 0,
      totalReste: 0,
      totalMontantBrut: 0,
      totalMontantNet: 0,
      montantBrutCoefficient: 0.85,
      montantNetCoefficient: 0.15,
      today: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var params = arrayUrl[1];
      var arrayParams = params.split("-");
      var idEns = arrayParams[0];
      var mois = arrayParams[1];
      if (mois == 1) {
        var nomMois = "Janvier";
      } else if (mois == 2) {
        var nomMois = "Février";
      } else if (mois == 3) {
        var nomMois = "Mars";
      } else if (mois == 4) {
        var nomMois = "Avril";
      } else if (mois == 5) {
        var nomMois = "Mai";
      } else if (mois == 6) {
        var nomMois = "Juin";
      } else if (mois == 7) {
        var nomMois = "Juillet";
      } else if (mois == 8) {
        var nomMois = "Aout";
      } else if (mois == 9) {
        var nomMois = "Septembre";
      } else if (mois == 10) {
        var nomMois = "Octobre";
      } else if (mois == 11) {
        var nomMois = "Novembre";
      } else if (mois == 12) {
        var nomMois = "Decembre";
      } else {
        var nomMois = "";
      }
      var today = arrayParams[2];
      this.setState(
        {
          nomMois: nomMois,
          moisExist: mois,
          today: today,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/RegEnsNonPayer.php",
            type: "POST",
            data: {
              anneeScolaire: anneeScolaire,
              idEns: idEns,
              mois: mois,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState(
                {
                  arrayEnsNonPayer: code_html,
                },
                
                () => {
                  var sommeAPayer = 0;
                  var sommeACompte = 0;
                  var sommeReste = 0;
                  var tab = this.state.arrayEnsNonPayer;
                  for (var i = 0; i < tab.length; i++) {
                    sommeAPayer += parseFloat(tab[i].totAPay);
                    sommeACompte += parseFloat(tab[i].aCompte);
                    sommeReste += parseFloat(tab[i].reste);
                  }
                  this.setState({
                    totalAPayer: sommeAPayer,
                    totalACompte: sommeACompte,
                    totalReste: sommeReste,
                  });
                }
              );
            },
          })
        }
          
      );
    } else {
      document.location = "/";
    }
  }

  impr = () => {
    window.print();
  };

  changeEspece = (e, index, indice) => {
    const arrayChange = this.state.arrayEnsNonPayer.slice(); 

    if (e.target.checked === true) {

      arrayChange[index].infoNonPayEns[indice].checkEsp = true;
      // somme a payer espece
      var sommeEsp = 0;
      var tab = arrayChange[index].infoNonPayEns;
      for (var i = 0; i < tab.length; i++) {
        var checkEsp = tab[i].checkEsp;
        var total = tab[i].montant;
        var aCompte = tab[i].aCompte;
        var aPayer = parseFloat(total) - parseFloat(aCompte);
        if (checkEsp) {
          sommeEsp += aPayer;
        }
      }
      arrayChange[index].espece = sommeEsp;
      this.setState(
        {
          arrayEnsNonPayer: arrayChange,
        },
        () => {
          var arrayChange = this.state.arrayEnsNonPayer;
          var totEsp = 0;
          for (var j = 0; j < arrayChange.length; j++) {
            totEsp += parseFloat(arrayChange[j].espece);
          }
          this.setState({
            arrayEnsNonPayer: arrayChange,
            totalEsp: totEsp,
          
          });
        }
      );
    } else {

      arrayChange[index].infoNonPayEns[indice].checkEsp = false;
      // somme a payer espece
      var sommeEsp = 0;
      
      var tab = arrayChange[index].infoNonPayEns;
      for (var i = 0; i < tab.length; i++) {
        var checkEsp = tab[i].checkEsp;
        var total = tab[i].montant;
        var aCompte = tab[i].aCompte;
        var aPayer = parseFloat(total) - parseFloat(aCompte);
        if (checkEsp) {
          sommeEsp += aPayer;
        }
      }
      arrayChange[index].espece = sommeEsp;
      this.setState(
        {
          arrayEnsNonPayer: arrayChange,
        },
        () => {
          var arrayChange = this.state.arrayEnsNonPayer;
          var totEsp = 0;
          for (var j = 0; j < arrayChange.length; j++) {
            totEsp += parseFloat(arrayChange[j].espece);
          }
          this.setState({
            totalEsp: totEsp,
          });
        }
      );
    }

    let totalMontantBrut = 0;
    let totalMontantNet = 0;
    const { montantBrutCoefficient, montantNetCoefficient } = this.state;

    arrayChange.forEach((item) => {
      item.infoNonPayEns.forEach((element) => {
        if (element.checkEsp || element.checkChec) {
          const montantBrut = parseFloat(element.montant) / montantBrutCoefficient;
          const montantNet = (parseFloat(element.montant) / montantBrutCoefficient) * montantNetCoefficient;
    
          const truncatedMontantBrut = Math.floor(montantBrut * 1000) / 1000;
          const truncatedMontantNet = Math.floor(montantNet * 1000) / 1000;
    
          totalMontantBrut += (element.checkEsp || element.checkChec) ? truncatedMontantBrut : 0;
          totalMontantNet += (element.checkEsp || element.checkChec) ? truncatedMontantNet : 0;
        }
      });
    });
    
    const truncatedTotalMontantBrut = Math.floor(totalMontantBrut * 1000) / 1000;
    const truncatedTotalMontantNet = Math.floor(totalMontantNet * 1000) / 1000;
    
    this.setState({
      arrayEnsNonPayer: arrayChange,
      totalMontantBrut: truncatedTotalMontantBrut,
      totalMontantNet: truncatedTotalMontantNet
    });
    
  };

  changeCheque = (e, index, indice) => {
    const arrayChange = this.state.arrayEnsNonPayer.slice(); 

    if (e.target.checked === true) {
      arrayChange[index].infoNonPayEns[indice].checkChec = true;
      // somme a payer espece
      var sommeChec = 0;
      
      var tab = arrayChange[index].infoNonPayEns;
      for (var i = 0; i < tab.length; i++) {
        var checkChec = tab[i].checkChec;
        var total = tab[i].montant;
        var aCompte = tab[i].aCompte;
        var aPayer = parseFloat(total) - parseFloat(aCompte);
        if (checkChec) {
          sommeChec += aPayer;
        }
      }
      arrayChange[index].cheque = sommeChec;
      this.setState(
        {
          arrayEnsNonPayer: arrayChange,
        },
        () => {
          var arrayChange = this.state.arrayEnsNonPayer;
          var totChec = 0;
          for (var j = 0; j < arrayChange.length; j++) {
            totChec += parseFloat(arrayChange[j].cheque);
          }
          this.setState({
            totalChec: totChec,
            totalMontantBrut: totalMontantBrut,
            totalMontantNet: totalMontantNet
          });
        }
      );
    } else {
      arrayChange[index].infoNonPayEns[indice].checkChec = false;
      // somme a payer espece
      var sommeChec = 0;
      var tab = arrayChange[index].infoNonPayEns;
      for (var i = 0; i < tab.length; i++) {
        var checkChec = tab[i].checkChec;
        var total = tab[i].montant;
        var aCompte = tab[i].aCompte;
        var aPayer = parseFloat(total) - parseFloat(aCompte);
        if (checkChec) {
          sommeChec += aPayer;
        }
      }
      arrayChange[index].cheque = sommeChec;
    
      this.setState(
        {
          arrayEnsNonPayer: arrayChange,
      
        },
        () => {
          var arrayChange = this.state.arrayEnsNonPayer;
          var totChec = 0;
          for (var j = 0; j < arrayChange.length; j++) {
            totChec += parseFloat(arrayChange[j].cheque);
          }
          this.setState({
            totalChec: totChec,
          });
        }
      );
    }
    let totalMontantBrut = 0;
    let totalMontantNet = 0;
    const { montantBrutCoefficient, montantNetCoefficient } = this.state;

    arrayChange.forEach((item) => {
      item.infoNonPayEns.forEach((element) => {
        if (element.checkEsp || element.checkChec) {
          const montantBrut = parseFloat(element.montant) / montantBrutCoefficient;
          const montantNet = (parseFloat(element.montant) / montantBrutCoefficient) * montantNetCoefficient;
    
          const truncatedMontantBrut = Math.floor(montantBrut * 1000) / 1000;
          const truncatedMontantNet = Math.floor(montantNet * 1000) / 1000;
    
          totalMontantBrut += (element.checkEsp || element.checkChec) ? truncatedMontantBrut : 0;
          totalMontantNet += (element.checkEsp || element.checkChec) ? truncatedMontantNet : 0;
        }
      });
    });
    
    const truncatedTotalMontantBrut = Math.floor(totalMontantBrut * 1000) / 1000;
    const truncatedTotalMontantNet = Math.floor(totalMontantNet * 1000) / 1000;
    
    this.setState({
      arrayEnsNonPayer: arrayChange,
      totalMontantBrut: truncatedTotalMontantBrut,
      totalMontantNet: truncatedTotalMontantNet
    });
    
  };

  
  render() {
    return (
      <div className="white-box">
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          Liste des Formateurs Non Payés{" "}
        </h4>
        <h5
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {this.state.today}
        </h5>
        {this.state.moisExist != "" ? (
          <h5
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            {"Mois : " + this.state.nomMois}
          </h5>
        ) : null}
        <table style={{ border: "solid" }} className="table table-bordered">
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "13%",
                }}
                scope="col"
              >
                Formateurs
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "11%",
                }}
                scope="col"
              >
                Type
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "8%",
                }}
                scope="col"
              >
                Mois
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "8%",
                }}
                scope="col"
              >
                NH/Mois
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "8%",
                }}
                scope="col"
              >
                Reste
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "8%",
                }}
                scope="col"
              >
                Total/Mois
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "8%",
                }}
                scope="col"
              >
                Total Général
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "8%",
                }}
                scope="col"
              >
                Espéce
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "10%",
                }}
                scope="col"
              >
                Chéque
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "9%",
                }}
                scope="col"
              >
                Montant Brut
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  width: "10%",
                }}
                scope="col"
              >
                retenu
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.arrayEnsNonPayer.map((el, index) => (
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "11px",
                  }}
                >
                  {el.nomEns}
                </td>
                <td
                  colSpan="10"
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  <div>
                    <table
                      id="insideArray"
                      style={{
                        border: "none",
                        marginBottom: "-10px",
                        marginTop: "-10px",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        {el.infoNonPayEns.map((ele, indice) => (
                          <tr>
                            <td
                              id="tr1"
                              style={{
                                border: "none",
                                color: "black",
                                width: "9.5%",
                                fontSize: "11px",
                              }}
                            >
                              {ele.nbrHeure != "" ? ele.type : null}
                            </td>
                            <td
                              id="tr2"
                              style={{
                                border: "none",
                                color: "black",
                                width: "8%",
                                fontSize: "11px",
                              }}
                            >
                              {ele.nbrHeure != "" ? ele.mois : null}
                            </td>
                            <td
                              id="tr3"
                              style={{
                                border: "none",
                                color: "black",
                                width: "7%",
                                fontSize: "11px",
                              }}
                            >
                              {ele.nbrHeure != "" ? ele.nbrHeure : null}
                            </td>
                            <td
                              id="tr3"
                              style={{
                                border: "none",
                                color: "black",
                                width: "8%",
                                fontSize: "11px",
                              }}
                            >
                              {ele.nbrHeure != "" ? ele.reste : null}
                            </td>
                            <td
                              id="tr4"
                              style={{
                                border: "none",
                                color: "black",
                                width: "8%",
                                fontSize: "11px",
                              }}
                            >
                              {ele.nbrHeure != ""
                                ? Number.parseFloat(ele.montant).toFixed(3)
                                : null}
                              {}
                            </td>
                            {indice == 0 ? (
                              <td
                                id="tr5"
                                rowSpan={el.infoNonPayEns.length}
                                style={{
                                  border: "none",
                                  color: "black",
                                  width: "8%",
                                  verticalAlign: "inherit",
                                  fontSize: "11px",
                                }}
                              >
                                {Number.parseFloat(el.reste).toFixed(3)}
                              </td>
                            ) : null}
                            <td
                              id="tr6"
                              style={{
                                border: "none",
                                color: "black",
                                width: "9%",
                                fontSize: "11px",
                              }}
                            >
                              {ele.nbrHeure !="" ? (
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    this.changeEspece(e, index, indice)
                                  }
                                  disabled={this.state.disabledEspece}
                                />
                              ) : null}
                            </td>
                            <td
                              id="tr7"
                              style={{
                                border: "none",
                                color: "black",
                                width: "9%",
                                fontSize: "11px",
                              }}
                            >
                              {" "}
                              {ele.nbrHeure != "" ? (
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    this.changeCheque(e, index, indice)
                                  }
                                  disabled={this.state.disabledCheque}
                                />
                              ) : null}
                            </td>
                            <td
                            id="tr8"
                            style={{
                            border: "none",
                            color: "black",
                            width: "8%",
                            fontSize: "11px",
                            }}
                              >
                            {ele.nbrHeure !== "" ?
                            (Math.floor(Number.parseFloat(ele.montant) / this.state.montantBrutCoefficient * 1000) / 1000).toFixed(3)
                              : null}
                            </td>
                              <td
                                  id="tr9"
                                  style={{
                                    border: "none",
                                    color: "black",
                                    width: "8%",
                                    fontSize: "11px",
                                    }}
                                      >
                                    {ele.nbrHeure !== "" ?
                                        (Math.floor(Number.parseFloat(ele.montant) / this.state.montantBrutCoefficient * this.state.montantNetCoefficient * 1000) / 1000).toFixed(3)
                                      : null}
                                      </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <th
                colSpan="6"
                className="col-md-8"
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "right",
                  fontSize: "20px",
                }}
                scope="col"
              >
                Total
              </th>
              <th
                className="col-md-1"
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                scope="col"
              >
                {Number.parseFloat(this.state.totalReste).toFixed(3)}
              </th>
              <th
                className="col-md-1"
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                scope="col"
              >
                {Number.parseFloat(this.state.totalEsp).toFixed(3)}
              </th>
              <th
                className="col-md-1"
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
                scope="col"
              >
                {Number.parseFloat(this.state.totalChec).toFixed(3)}
              </th>
              <th
                className="col-md-1"
                style={{
                textAlign: "center",
                border: "solid",
                color: "black",
                fontWeight: "bold",
                fontSize: "20px",
                }}
                scope="col"
                  >
                 {(Math.floor(this.state.totalMontantBrut * 1000) / 1000).toFixed(3)}
                  </th>
                  <th
                    className="col-md-1"
                    style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "20px",
                      }}
                      scope="col"
                        >
                  {(Math.floor(this.state.totalMontantNet * 1000) / 1000).toFixed(3)}
                      </th>
            </tr>
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default ImprTrimestre;
