import React, { Component } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

class ConsulterNotePFE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Eleve",
          selector: "nomEleve",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "note PFE",
          cell: (row) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {row.pfeNote.map((el) => (
                <p>{el}</p>
              ))}
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "40%",
        },
        // {
        //   name: "Modifier",
        //   cell: (row) => (
        //     <Link
        //       to={{
        //         pathname: "/ModifNotePFE",
        //         state: { idEleve: row.idEleve },
        //       }}
        //     >
        //       <button className="buttonModifier">
        //         <i style={{ color: "white" }} className="fas fa-pencil-alt"></i>
        //       </button>
        //     </Link>
        //   ),
        //   ignoreRowClick: true,
        //   allowOverflow: true,
        //   button: true,
        //   width: '20%'
        // },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      pending: false,
      niveauNoteId: "",
      listGroupe: [],
      selectGroupe: "",
      filiere: "",
      niveau: "",
      groupe: "",
      blockInfo: false,
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/GetGroupeBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listGroupe: code_html,
          });
        },
      });
    }
  }

  addGroupe = (selectGroupe) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectGroupe != null) {
      this.setState({ selectGroupe }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ConsulterNotePFE.php",
          type: "POST",
          data: {
            groupeId: selectGroupe.value,
            anneeScolaire: anneeScolaire,
            query: "",
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
              blockInfo: true,
            });
          },
        });
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetInfo.php",
          type: "POST",
          data: {
            groupeId: selectGroupe.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              filiere: code_html.nomFiliere,
              niveau: code_html.niveau,
              groupe: code_html.groupe,
            });
          },
        });
      });
    } else {
      this.setState({
        selectGroupe: "",
      });
    }
  };

  newElement(page, totalRows) {
    this.setState(
      {
        pending: true,
      },
      () => {
        var userPermissions = JSON.parse(
          localStorage.getItem("userPermissions")
        );
        var anneeScolaire = userPermissions[0].anneeScolaire;
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ConsulterNotePFE.php",
          type: "POST",
          data: {
            groupeId: this.state.selectGroupe.value,
            anneeScolaire: anneeScolaire,
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: page,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  perPage(currentRowsPerPage, currentPage) {
    this.setState(
      {
        nbrEltperPage: currentRowsPerPage,
        pending: true,
      },
      () => {
        var userPermissions = JSON.parse(
          localStorage.getItem("userPermissions")
        );
        var anneeScolaire = userPermissions[0].anneeScolaire;
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ConsulterNotePFE.php",
          type: "POST",
          data: {
            groupeId: this.state.selectGroupe.value,
            anneeScolaire: anneeScolaire,
            query: this.state.filterElement,
            limit: currentRowsPerPage,
            page: currentPage,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  filterSearch(event) {
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        var userPermissions = JSON.parse(
          localStorage.getItem("userPermissions")
        );
        var anneeScolaire = userPermissions[0].anneeScolaire;
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ConsulterNotePFE.php",
          type: "POST",
          data: {
            groupeId: this.state.selectGroupe.value,
            anneeScolaire: anneeScolaire,
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  impr = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/RecuNotePfe.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        idGroupe: this.state.selectGroupe.value,
      },
      dataType: "json",
      success: function (code_html, statut) {
        var server_path = code_html.server_path;
        var file_path = code_html.file_path;
        var path = server_path + file_path;
        window.open(path, "_blank");
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Note PFE</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/AjoutNotePFE">
                    {" "}
                    <button
                      style={{
                        background: "#3A3F51",
                        border: "1px solid #3A3F51",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i className="fas fa-plus"></i>Ajout Note PFE
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroupe}
                        options={this.state.listGroupe}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.impr}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
                {this.state.blockInfo == true ? (
                  <div className="row">
                    <div className="form-group">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop:'2%'
                        }}
                      >
                        <h3> Rapport de note PFE</h3>
                      </div>
                      <div style={{ marginLeft: "3%" }}>
                        <p>
                          <strong>Specialite : </strong> {this.state.filiere}
                        </p>
                        <p>
                          <strong>Niveau : </strong> {this.state.niveau}
                        </p>
                        <p>
                          <strong>Groupe : </strong> {this.state.groupe}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  noHeader={true}
                  progressPending={this.state.pending}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConsulterNotePFE;
