import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { RadioGroup, Radio } from 'react-radio-group'

class ModifApprenant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apprenantId: '',
            prenom: '',
            nom: '',
            selectedType: 'CIN',
            numSelonType: '',
            nationalite: '',
            dateDelivSelonType: '',
            villeDelivreSelonType: '',
            dateNaissance: '',
            lieuNaissance: '',
            email: '',
            selectedGender: 'Masculin',
            mobile: '',
            tel: '',
            adresseOfficiel: '',
            adresseTun: '',
            listNivBase: [],
            selectedNivBase: '',
            imageVerso: null
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            let idApprenant = this.props.location.state.idApprenant;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetApprenantById.php?id=' + idApprenant).then(res => {
                console.log(res)
                if (res.data.dateNum != "0000-00-00") {
                    var dateType = new Date(res.data.dateNum)
                }
                else {
                    var dateType = ''
                }
                this.setState({
                    apprenantId: res.data.idEleve,
                    prenom: res.data.prenom,
                    nom: res.data.nom,
                    selectedType: res.data.type,
                    numSelonType: res.data.numero,
                    nationalite: res.data.nationalite,
                    dateDelivSelonType: dateType,
                    villeDelivreSelonType: res.data.villeNum,
                    dateNaissance: new Date(res.data.naissance),
                    lieuNaissance: res.data.villeNaissance,
                    adresseOfficiel: res.data.adrOfficiel,
                    adresseTun: res.data.adresseTun,
                    email: res.data.email,
                    selectedGender: res.data.sex,
                    mobile: res.data.mobile,
                    selectedNivBase: res.data.nivBase,
                    tel: res.data.tel
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectNivBase.php').then(res => {
                console.log(res)
                this.setState({
                    listNivBase: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    updPrenom = (event) => {
        this.setState({
            prenom: event.target.value
        });
    }

    updNom = (event) => {
        this.setState({
            nom: event.target.value
        });
    }

    updType = (selectedType) => {
        this.setState({ selectedType: selectedType });
    }

    updNumSelonType = (event) => {
        this.setState({
            numSelonType: event.target.value
        });
    }

    updNationalite = (event) => {
        this.setState({
            nationalite: event.target.value
        });
    }

    updDateLivraison = d => {
        this.setState({
            dateDelivSelonType: d
        });
    };

    updVilleDelivreSelonType = (event) => {
        this.setState({
            villeDelivreSelonType: event.target.value
        });
    }

    updDateNaissance = d => {
        this.setState({
            dateNaissance: d
        });
    };

    updLieuNaissance = (event) => {
        this.setState({
            lieuNaissance: event.target.value
        });
    }

    updAdresseOfficiel = (event) => {
        this.setState({
            adresseOfficiel: event.target.value
        });
    }

    updAdresseTun = (event) => {
        this.setState({
            adresseTun: event.target.value
        });
    }

    updEmail = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    updGender = (selectedGender) => {
        this.setState({ selectedGender: selectedGender });
    }

    updMobile = (event) => {
        this.setState({
            mobile: event.target.value
        });
    }

    updTel = (event) => {
        this.setState({
            tel: event.target.value
        });
    }

    UpdNivBase = selectedNivBase => {
        this.setState({ selectedNivBase });
        console.log(`nivBase selected:`, selectedNivBase);
    };

    updImageVerso(e) {
        this.setState({ imageVerso: e.target.files })
    }

    modif = () => {
        if (this.state.dateDelivSelonType != '') {
            let dateDelivSelonType = this.state.dateDelivSelonType
            let month_one = '' + (dateDelivSelonType.getMonth() + 1);
            let day_one = '' + dateDelivSelonType.getDate();
            let year_one = dateDelivSelonType.getFullYear();
            if (month_one.length < 2)
                month_one = '0' + month_one;
            if (day_one.length < 2)
                day_one = '0' + day_one;
            var convertDateDelivSelonType = [year_one, month_one, day_one].join('-');
        }
        else {
            var convertDateDelivSelonType = ''
        }
        //
        if (this.state.dateNaissance != '') {
            let dateNaissance = this.state.dateNaissance
            let month_two = '' + (dateNaissance.getMonth() + 1);
            let day_two = '' + dateNaissance.getDate();
            let year_two = dateNaissance.getFullYear();
            if (month_two.length < 2)
                month_two = '0' + month_two;
            if (day_two.length < 2)
                day_two = '0' + day_two;
            var convertDateNaissance = [year_two, month_two, day_two].join('-');
        }
        else {
            var convertDateNaissance = ''
        }
        // Test si photo vide
        var pathImageVerso = '';
        console.log(this.state.imageVerso)
        if (this.state.imageVerso != null) {
            pathImageVerso = this.state.apprenantId
        }
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdApprenant.php?id=' + this.state.apprenantId,
            type: 'POST',
            data: {
                prenom: this.state.prenom,
                nom: this.state.nom,
                type: this.state.selectedType,
                numero: this.state.numSelonType,
                nationalite: this.state.nationalite,
                dateType: convertDateDelivSelonType,
                villeType: this.state.villeDelivreSelonType,
                dateNaissance: convertDateNaissance,
                lieuNaissance: this.state.lieuNaissance,
                adresseOfficiel: this.state.adresseOfficiel,
                adresseTun: this.state.adresseTun,
                email: this.state.email,
                sexe: this.state.selectedGender,
                mobile: this.state.mobile,
                tel: this.state.tel,
                nivBaseId: this.state.selectedNivBase.value,
                photo: pathImageVerso,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Apprenants');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
        // Test si photo vide
        if (this.state.imageVerso != null) {
            let files = this.state.imageVerso;
            console.warn('data files', files)
            let reader = new FileReader();
            reader.readAsDataURL(files[0])
            reader.onload = (e) => {
                console.log(e)
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UploadphotoAppr.php',
                    type: 'POST',
                    data: {
                        file: e.target.result,
                        nomfile: this.state.apprenantId
                    },
                    dataType: 'json',
                    success: function (code_html, statut) {
                    }
                }
                )
            }
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification apprenant</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Apprenants" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Prénom</label>
                                            <input type="text" className="form-control" value={this.state.prenom} onChange={this.updPrenom} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom</label>
                                            <input type="text" className="form-control" value={this.state.nom} onChange={this.updNom} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Sexe</label>
                                        <RadioGroup
                                            name="sexe"
                                            selectedValue={this.state.selectedGender}
                                            onChange={this.updGender}>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="Masculin" />Masculin
                                                </label>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="Feminin" />Feminin
                                                </label>
                                        </RadioGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Type</label>
                                        <RadioGroup
                                            name="type"
                                            selectedValue={this.state.selectedType}
                                            onChange={this.updType}>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="CIN" />CIN
                                                </label>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value="Passeport" />Passeport
                                                </label>
                                        </RadioGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Numero</label>
                                            <input type="text" className="form-control" value={this.state.numSelonType} onChange={this.updNumSelonType} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nationalité</label>
                                            <input type="text" className="form-control" value={this.state.nationalite} onChange={this.updNationalite} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Delivré le </label>
                                        <div className="form-group">
                                            <DatePicker class="form-control ddate" value={this.state.dateDelivSelonType} style={{ border: 'none' }}
                                                selected={this.state.dateDelivSelonType}
                                                onChange={this.updDateLivraison}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Delivré ville de</label>
                                            <input type="text" className="form-control" value={this.state.villeDelivreSelonType} onChange={this.updVilleDelivreSelonType} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Date naissance</label>
                                        <div className="form-group">
                                            <DatePicker class="form-control ddate" value={this.state.dateNaissance} style={{ border: 'none' }}
                                                selected={this.state.dateNaissance}
                                                onChange={this.updDateNaissance}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Lieu de naissance</label>
                                            <input type="text" className="form-control" value={this.state.lieuNaissance} onChange={this.updLieuNaissance} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Adresse officiel</label>
                                            <input type="text" className="form-control" value={this.state.adresseOfficiel} onChange={this.updAdresseOfficiel} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Adresse en tunisie</label>
                                            <input type="text" className="form-control" value={this.state.adresseTun} onChange={this.updAdresseTun} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" className="form-control" value={this.state.email} onChange={this.updEmail} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mobile</label>
                                            <input type="text" className="form-control" value={this.state.mobile} onChange={this.updMobile} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tel</label>
                                            <input type="text" className="form-control" value={this.state.tel} onChange={this.updTel} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau de base</label>
                                            <Select
                                                value={this.state.selectedNivBase}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.UpdNivBase}
                                                options={this.state.listNivBase}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Photo verso </label>
                                            <input type="file" className="form-control" onChange={(e) => this.updImageVerso(e)} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3' }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifApprenant);