import React from "react";
import Login from "./Login";
import { AppRoute } from "./AppRoute";
import StatiquePage from "./StatiquePage";
import NivBase from "./NivBase";
import AjoutNivBase from "./AjoutNivBase";
import ModifNivBase from "./ModifNivBase";
import Matieres from "./Matieres";
import TypeEnseign from "./TypeEnseign";
import AjoutTypeEnseign from "./AjoutTypeEnseign";
import ModifTypeEnseign from "./ModifTypeEnseign";
import AjoutMatiere from "./AjoutMatiere";
import ModifMatiere from "./ModifMatiere";
import Filieres from "./Filieres";
import AjoutFiliere from "./AjoutFiliere";
import ModifFiliere from "./ModifFiliere";
import MatieresFormat from "./MatieresFormat";
import Specialites from "./Specialites";
import AjoutSpecialite from "./AjoutSpecialite";
import ModifSpecialite from "./ModifSpecialite";
import Niveaux from "./Niveaux";
import AjoutNiveau from "./AjoutNiveau";
import ModifNiveau from "./ModifNiveau";
import ExplNiveau from "./ExplNiveau";
import AjoutMatFor from "./AjoutMatFor";
import ExplMatieresFormat from "./ExplMatieresFormat";
import ModifMatieresFormat from "./ModifMatieresFormat";
import Roles from "./Roles";
import Users from "./Users";
import AjoutUser from "./AjoutUser";
import ModifUser from "./ModifUser";
import Enseignants from "./Enseignants";
import AjoutEnseignant from "./AjoutEnseignant";
import ModifEnseignant from "./ModifEnseignant";
import ModPaiEnseignants from "./ModPaiEnseignants";
import AjoutModPaiEnseignant from "./AjoutModPaiEnseignant";
import ModifModPaiEnseignant from "./ModifModPaiEnseignant";
import PaiementsEnseignant from "./PaiementsEnseignant";
import Groupes from "./Groupes";
import AjoutGroupe from "./AjoutGroupe";
import ExplGroupe from "./ExplGroupe";
import ModifGroupe from "./ModifGroupe";
import EnseignantsGroupes from "./EnseignantsGroupes";
import Apprenants from "./Apprenants";
import AjoutApprenant from "./AjoutApprenant";
import ModifApprenant from "./ModifApprenant";
import FraisFormation from "./FraisFormation";
import AjoutFraisFormation from "./AjoutFraisFormation";
import ModifFraisFormation from "./ModifFraisFormation";
import Papiers from "./Papiers";
import AjoutPapier from "./AjoutPapier";
import ModifPapier from "./ModifPapier";
import Inscription from "./Inscription";
import Formations from "./Formations";
import AjoutFormation from "./AjoutFormation";
import ModifFormation from "./ModifFormation";
import ReglApprenant from "./ReglApprenant";
import Menu from "./Menu";
import MenuParametrage from "./MenuParametrage";
import MenuDocumentation from "./MenuDocumentation";
import MenuDonneeBase from "./MenuDonneeBase";
import HistoriqueConnexion from "./HistoriqueConnexion";

import ModifReglTrchApp from "./ModifReglTrchApp";
import Cycles from "./Cycles";
import AjoutCycle from "./AjoutCycle";
import ConfirmationCycle from "./ConfirmationCycle";
import ModifCycle from "./ModifCycle";
import InscripCycle from "./InscripCycle";
import ModifReglCycleApp from "./ModifReglCycleApp";
import ReglApprenantCycle from "./ReglApprenantCycle";
import AjoutSeance from "./AjoutSeance";
import Absence from "./Absence";
import AjoutAutreFrais from "./AjoutAutreFrais";
import AutreFrais from "./AutreFrais";
import ModifAutreFrais from "./ModifAutreFrais";
import AjoutRemboursement from "./AjoutRemboursement";
import Remboursement from "./Remboursement";
import ModifRemboursement from "./ModifRemboursement";
import RapportJournalier from "./RapportJournalier";
import PresenceEnseignant from "./PresenceEnseignant";
import MenuAnalyse from "./MenuAnalyse";
import AjoutConseilAssist from "./AjoutConseilAssist";
import ConseilAssistance from "./ConseilAssistance";
import ModifConseilAssist from "./ModifConseilAssist";
import PayReglementEnseignant from "./PayReglementEnseignant";
import ReglementEnseignant from "./ReglementEnseignant";
import ModifReglementEnseignant from "./ModifReglementEnseignant";
import AjoutVisiteur from "./AjoutVisiteur";
import Visiteurs from "./Visiteurs";
import ChequeEns from "./ChequeEns";
import Statistique from "./Statistique";
import MiseAJourReglFor from "./MiseAJourReglFor";
import ModifInsAppCycle from "./ModifInsAppCycle";
import AjoutFraisCycle from "./AjoutFraisCycle";
import FraisCycle from "./FraisCycle";
import ModifFraisCycle from "./ModifFraisCycle";
import AjoutHoraireCours from "./AjoutHoraireCours";
import HoraireCours from "./HoraireCours";
import ModifHoraireCours from "./ModifHoraireCours";
import ModifReglFraisApp from "./ModifReglFraisApp";
import RecuEmis from "./RecuEmis";
import ReglApprenantFrais from "./ReglApprenantFrais";
import DeclarationEmpl from "./DeclarationEmpl";
import NatureNote from "./NatureNote";
import AjoutNatureNote from "./AjoutNatureNote";
import AjoutNoteStage from "./AjoutNoteStage";
import ConsulterNoteStage from "./ConsulterNoteStage";
import ConsulterNoteStageBts from "./ConsulterNoteStageBts";
import ModifNoteStage from "./ModifNoteStage";
import ModifNoteStageBTS from "./ModifNoteStageBTS";
import AjoutNotePFE from "./AjoutNotePFE";
import ConsulterNotePFE from "./ConsulterNotePFE";
import ModifNotePFE from "./ModifNotePFE";
import ResultatTrimestriel from "./ResultatTrimestriel";
import ResultatPizzaiolo from "./ResultatPizzaiolo";
import ReleveNote from "./ReleveNote";
import ReleveNoteBTS from "./ReleveNoteBTS";
import NoteNiveau from "./NoteNiveau";
import NiveauNatureNote from "./NiveauNatureNote";
import ExplNivNateNote from "./ExplNivNateNote";
import ModifNivNateNote from "./ModifNivNateNote";
import AjoutNote from "./AjoutNote";
import ListNote from "./ListNote";
import AjoutNoteBTS from "./AjoutNoteBTS";
import ListNoteBts from "./ListNoteBts";
import ModifNoteNonBTS from "./ModifNoteNonBTS";
import ModifNoteBts from "./ModifNoteBts";
import AjoutNoteExamen from "./AjoutNoteExamen";
import AjoutJury from "./AjoutJury";
import AjoutJuryBts from "./AjoutJuryBts";
import ConsulterNoteExam from "./ConsulterNoteExam";
import ConsulterNoteExamBts from "./ConsulterNoteExamBts";
import ConsNoteExam from "./ConsNoteExam";
import ImprMoyenneAdmission from "./ImprMoyenneAdmission";
import MoyenneAdmission from "./MoyenneAdmission";
import ImprConsNoteExam from "./ImprConsNoteExam";
import ImprTrimestre from "./ImprTrimestre";
import MoyenneAdmissionBTS from "./MoyenneAdmissionBTS";
import ImprMoyenneAdmissionBTS from "./ImprMoyenneAdmissionBTS";
import ImprReleveNoteBTS from "./ImprReleveNoteBTS";
import ListVierge from "./ListVierge";
import ImprListVierge from "./ImprListVierge";
import AjouterCalendrier from "./AjouterCalendrier";
import ListGroupe from "./ListGroupe";
import ConsulterListGroupe from "./ConsulterListGroupe";
import ImprCalEFF from "./ImprCalEFF";
import ImprEnsNonPayer from "./ImprEnsNonPayer";
import ImprRemboursement from"./impRemboursement";
import AjoutAvis from "./AjoutAvis";
import ConsulterAvis from "./ConsulterAvis";
import AjoutDerog from "./AjoutDerog";
import Derogation from "./Derogation";
import ImprProfilApp from "./ImprProfilApp";
import AjoutAttestation from "./AjoutAttestation";
import ImprMotif from "./ImprMotif";
import ImprMotifEleve from "./ImprMotifEleve";
import ResultatBTS from "./ResultatBTS";
import ImprResultatPrBTS from "./ImprResultatPrBTS";
import Rattrapage from "./Rattrapage";
import ImprRattrapage from "./ImprRattrapage";
import RattrapageBTS from "./RattrapageBTS";
import ImprRattrapageBTS from "./ImprRattrapageBTS";
import Attestation from "./Attestation";
import MenuNote from "./MenuNote";
import MenuCal from "./MenuCal";
import AffichRes from "./AffichRes";
import ModifHonoraireEnsCycle from "./ModifHonoraireEnsCycle";
import ImprPayAppCycle from "./ImprPayAppCycle";
import ImprListeCycle from "./ImprListeCycle";
import ImprListNonPaye from "./ImprListNonPaye";
import ImprDetailRegEns from "./ImprDetailRegEns";
import ImprDetailCycle from "./ImprDetailCycle";
import ImprNoteStageBTS from "./ImprNoteStageBTS";
import ConsulterJury from "./ConsulterJury";
import ConsulterJuryBTS from "./ConsulterJuryBTS";
import ExplJury from "./ExplJury";
import ExplJuryBTS from "./ExplJuryBTS";
import ModifJury from "./ExplJuryBTS";
import ModifJuryBTS from "./ModifJuryBTS";
import ImprListEns from "./ImprListEns";
import ImprConseilAssistance from "./ImprConseilAssistance";
import DemandeAttestation from "./DemandeAttestation";
import ImprDemandeAttestation from "./ImprDemandeAttestation";
import ImprAttestationPres from "./ImprAttestationPres";
import MatSousMat from "./MatSousMat";
import ModifMatSousMat from "./ModifMatSousMat";
import ImprRapportFor from "./ImprRapportFor";
import ImprRapportCycle from "./ImprRapportCycle";
import ConsAvis from "./ConsAvis";
import ImprCheqEns from "./ImprCheqEns";
import ImprReleveNote from "./ImprReleveNote";
import ListAppInscri from "./ListAppInscri";
import ConsAppInscri from "./ConsAppInscri";
import ModifApprenantInscri from "./ModifApprenantInscri";
import ImprAbsence from "./ImprAbsence";
import ListAppCycle from "./ListAppCycle";
import AjoutAnnee from "./AjoutAnnee";
import ImprVisit from "./ImprVisit";
import ImprAppr from "./ImprAppr";
import impSouRatrappage from "./impSouRatrappage";
import ApprAnciens from "./ApprAnciens";
import ImprficheEnseignant from "./ImprficheEnseignant";
import ImprApprIns from "./ImprApprIns";
import Imprecu from "./ImpRecu";
import ImpDeclarationEns from "./ImpDeclarationEns";
import ImpDecla from "./ImpDecla";

import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import GestionEnseignant from "./GestionEnseignant";
import AjouDeclaration from "./AjouDeclaration";


//import AjoutAnnee from "./AjoutAnnee";
import ReleveFin from "./ReleveFin";
import ImpFinal from "./ImpFinal";
import Imprfrais from "./ImpFrais";
import ModifVisiteurs from "./ModifVisiteurs";
import ImpPresenceDetail from "./ImpPresenceDetail";


function App() {
  return (
    <Router>
      <Switch>
        <AppRoute
          path="/ModifReglementEnseignant"
          component={ModifReglementEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifHonoraireEnsCycle"
          component={ModifHonoraireEnsCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/PayReglementEnseignant"
          component={PayReglementEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsulterNoteStageBts"
          component={ConsulterNoteStageBts}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifModPaiEnseignant"
          component={ModifModPaiEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutModPaiEnseignant"
          component={AjoutModPaiEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifApprenantInscri"
          component={ModifApprenantInscri}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsulterNoteExamBts"
          component={ConsulterNoteExamBts}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MoyenneAdmissionBTS"
          component={MoyenneAdmissionBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ReglementEnseignant"
          component={ReglementEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifFraisFormation"
          component={ModifFraisFormation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/HistoriqueConnexion"
          component={HistoriqueConnexion}
          layout={StatiquePage}
        ></AppRoute>
    
        <AppRoute
          path="/AjoutFraisFormation"
          component={AjoutFraisFormation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifMatieresFormat"
          component={ModifMatieresFormat}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/PaiementsEnseignant"
          component={PaiementsEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ResultatTrimestriel"
          component={ResultatTrimestriel}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsulterListGroupe"
          component={ConsulterListGroupe}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsulterNoteStage"
          component={ConsulterNoteStage}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ReglApprenantFrais"
          component={ReglApprenantFrais}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifConseilAssist"
          component={ModifConseilAssist}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutConseilAssist"
          component={AjoutConseilAssist}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ReglApprenantCycle"
          component={ReglApprenantCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/EnseignantsGroupes"
          component={EnseignantsGroupes}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/PresenceEnseignant"
          component={PresenceEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/GestionEnseignant"
          component={GestionEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ExplMatieresFormat"
          component={ExplMatieresFormat}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/DemandeAttestation"
          component={DemandeAttestation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutRemboursement"
          component={AjoutRemboursement}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifRemboursement"
          component={ModifRemboursement}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutHoraireCours"
          component={AjoutHoraireCours}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifReglFraisApp"
          component={ModifReglFraisApp}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjouterCalendrier"
          component={AjouterCalendrier}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifHoraireCours"
          component={ModifHoraireCours}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModPaiEnseignants"
          component={ModPaiEnseignants}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ResultatPizzaiolo"
          component={ResultatPizzaiolo}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifReglCycleApp"
          component={ModifReglCycleApp}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConfirmationCycle"
          component={ConfirmationCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConseilAssistance"
          component={ConseilAssistance}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsulterNoteExam"
          component={ConsulterNoteExam}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/relevefinformation"
          component={ReleveFin}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/RapportJournalier"
          component={RapportJournalier}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifNoteStageBTS"
          component={ModifNoteStageBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsulterJuryBTS"
          component={ConsulterJuryBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MoyenneAdmission"
          component={MoyenneAdmission}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/NiveauNatureNote"
          component={NiveauNatureNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifNivNateNote"
          component={ModifNivNateNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifReglTrchApp"
          component={ModifReglTrchApp}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MiseAJourReglFor"
          component={MiseAJourReglFor}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifInsAppCycle"
          component={ModifInsAppCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifTypeEnseign"
          component={ModifTypeEnseign}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutTypeEnseign"
          component={AjoutTypeEnseign}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsulterNotePFE"
          component={ConsulterNotePFE}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutAttestation"
          component={AjoutAttestation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifMatSousMat"
          component={ModifMatSousMat}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifNoteNonBTS"
          component={ModifNoteNonBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/DeclarationEmpl"
          component={DeclarationEmpl}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ExplNivNateNote"
          component={ExplNivNateNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutNatureNote"
          component={AjoutNatureNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutFraisCycle"
          component={AjoutFraisCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifFraisCycle"
          component={ModifFraisCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifSpecialite"
          component={ModifSpecialite}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutSpecialite"
          component={AjoutSpecialite}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifEnseignant"
          component={ModifEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifAutreFrais"
          component={ModifAutreFrais}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutEnseignant"
          component={AjoutEnseignant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutAutreFrais"
          component={AjoutAutreFrais}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutNoteExamen"
          component={AjoutNoteExamen}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutNoteStage"
          component={AjoutNoteStage}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifNoteStage"
          component={ModifNoteStage}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MatieresFormat"
          component={MatieresFormat}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutFormation"
          component={AjoutFormation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifFormation"
          component={ModifFormation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifApprenant"
          component={ModifApprenant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutApprenant"
          component={AjoutApprenant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/FraisFormation"
          component={FraisFormation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ReglApprenant"
          component={ReglApprenant}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutVisiteur"
          component={AjoutVisiteur}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Modifvisiteurs"
          component={ModifVisiteurs}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ReleveNoteBTS"
          component={ReleveNoteBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ListAppInscri"
          component={ListAppInscri}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ListAppCycle"
          component={ListAppCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsAppInscri"
          component={ConsAppInscri}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsulterAvis"
          component={ConsulterAvis}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Remboursement"
          component={Remboursement}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsulterJury"
          component={ConsulterJury}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/RattrapageBTS"
          component={RattrapageBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsNoteExam"
          component={ConsNoteExam}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifNoteBts"
          component={ModifNoteBts}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/HoraireCours"
          component={HoraireCours}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/InscripCycle"
          component={InscripCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifFiliere"
          component={ModifFiliere}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutFiliere"
          component={AjoutFiliere}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifMatiere"
          component={ModifMatiere}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutMatiere"
          component={AjoutMatiere}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifNivBase"
          component={ModifNivBase}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutJuryBts"
          component={AjoutJuryBts}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutNivBase"
          component={AjoutNivBase}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutNoteBTS"
          component={AjoutNoteBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutNotePFE"
          component={AjoutNotePFE}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifNotePFE"
          component={ModifNotePFE}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifJuryBTS"
          component={ModifJuryBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ExplJuryBTS"
          component={ExplJuryBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Attestation"
          component={Attestation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ResultatBTS"
          component={ResultatBTS}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutSeance"
          component={AjoutSeance}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/TypeEnseign"
          component={TypeEnseign}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Specialites"
          component={Specialites}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutMatFor"
          component={AjoutMatFor}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Inscription"
          component={Inscription}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Enseignants"
          component={Enseignants}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifGroupe"
          component={ModifGroupe}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifPapier"
          component={ModifPapier}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutPapier"
          component={AjoutPapier}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutGroupe"
          component={AjoutGroupe}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifNiveau"
          component={ModifNiveau}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutNiveau"
          component={AjoutNiveau}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Statistique"
          component={Statistique}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ListNoteBts"
          component={ListNoteBts}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MatSousMat"
          component={MatSousMat}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Rattrapage"
          component={Rattrapage}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Derogation"
          component={Derogation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ReleveNote"
          component={ReleveNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/FraisCycle"
          component={FraisCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Apprenants"
          component={Apprenants}
          layout={StatiquePage}
        ></AppRoute>
         <AppRoute
          path="/ApprAnciens"
          component={ApprAnciens}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ExplGroupe"
          component={ExplGroupe}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ExplNiveau"
          component={ExplNiveau}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Formations"
          component={Formations}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutCycle"
          component={AjoutCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AutreFrais"
          component={AutreFrais}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifCycle"
          component={ModifCycle}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/NatureNote"
          component={NatureNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/NoteNiveau"
          component={NoteNiveau}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ListVierge"
          component={ListVierge}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MenuQuatre"
          component={MenuDocumentation}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ListGroupe"
          component={ListGroupe}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutDerog"
          component={AjoutDerog}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ConsAvis"
          component={ConsAvis}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutNote"
          component={AjoutNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutNote"
          component={AjoutNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutDeclaration"
          component={AjouDeclaration}
          layout={StatiquePage}
        ></AppRoute>
        <Route path="/ImprDeclarationEns" component={ImpDeclarationEns}layout={StatiquePage}></Route>
        <Route path="/ImpDecla" component={ImpDecla}layout={StatiquePage}></Route>
        <AppRoute
          path="/AjoutJury"
          component={AjoutJury}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ChequeEns"
          component={ChequeEns}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Visiteurs"
          component={Visiteurs}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifUser"
          component={ModifUser}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutUser"
          component={AjoutUser}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MenuTrois"
          component={MenuAnalyse}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AffichRes"
          component={AffichRes}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/AjoutAvis"
          component={AjoutAvis}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ModifJury"
          component={ModifJury}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Filieres"
          component={Filieres}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Matieres"
          component={Matieres}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MenuDeux"
          component={MenuDonneeBase}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ListNote"
          component={ListNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/ExplJury"
          component={ExplJury}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/RecuEmis"
          component={RecuEmis}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MenuCinq"
          component={MenuNote}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Papiers"
          component={Papiers}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/NivBase"
          component={NivBase}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Groupes"
          component={Groupes}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Niveaux"
          component={Niveaux}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Absence"
          component={Absence}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MenuSix"
          component={MenuCal}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/MenuUn"
          component={MenuParametrage}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Cycles"
          component={Cycles}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Roles"
          component={Roles}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Users"
          component={Users}
          layout={StatiquePage}
        ></AppRoute>
        <AppRoute
          path="/Menu"
          component={Menu}
          layout={StatiquePage}
        ></AppRoute>
 <AppRoute
          path="/AjoutAnnee"
          component={AjoutAnnee}
          layout={StatiquePage}
        ></AppRoute>
        {/* Impression */}
        <Route
          path="/ImprMoyenneAdmissionBTS"
          component={ImprMoyenneAdmissionBTS}
        ></Route>
        <Route
          path="/ImprDemandeAttestation"
          component={ImprDemandeAttestation}
        ></Route>
        <Route
          path="/ImprConseilAssistance"
          component={ImprConseilAssistance}
        ></Route>
        <Route
          path="/ImprMoyenneAdmission"
          component={ImprMoyenneAdmission}
        ></Route>
        <Route
          path="/ImprAttestationPres"
          component={ImprAttestationPres}
        ></Route>
        <Route path="/ImprResultatPrBTS" component={ImprResultatPrBTS}></Route>
      
        <Route path="/ImprReleveNoteBTS" component={ImprReleveNoteBTS}></Route>
        <Route path="/ImprRattrapageBTS" component={ImprRattrapageBTS}></Route>
        <Route path="/ImprNoteStageBTS" component={ImprNoteStageBTS}></Route>
        <Route path="/ImprRapportCycle" component={ImprRapportCycle}></Route>
        <Route path="/ImprDetailRegEns" component={ImprDetailRegEns}></Route>
        <Route path="/ImprConsNoteExam" component={ImprConsNoteExam}></Route>
        <Route path="/ImprDetailCycle" component={ImprDetailCycle}></Route>
        <Route path="/ImprPayAppCycle" component={ImprPayAppCycle}></Route>
        <Route path="/ImprEnsNonPayer" component={ImprEnsNonPayer}></Route>
        <Route path="/ImpFrais" component={Imprfrais}></Route>
        <Route path="/ImprRemboursement" component={ImprRemboursement}></Route>
        <Route path="/ImprListNonPaye" component={ImprListNonPaye}></Route>
        <Route path="/ImprListeCycle" component={ImprListeCycle}></Route>
        <Route path="/ImprRapportFor" component={ImprRapportFor}></Route>
        <Route path="/ImprListVierge" component={ImprListVierge}></Route>
        <Route path="/ImprMotifEleve" component={ImprMotifEleve}></Route>
        <Route path="/ImprReleveNote" component={ImprReleveNote}></Route>
        <Route path="/ImprSouRattrapage" component={impSouRatrappage}></Route>
        <Route path="/ImprficheEnseignant" component={ImprficheEnseignant}></Route>
        <Route path="/ImprListVis" component={ImprVisit}></Route>
        <Route path="/ImprApprIns" component={ImprApprIns}></Route>
        <Route path= "/ImprAppr" component={ImprAppr}>  </Route>
        <Route path= "/ImpPresenceDetail" component={ImpPresenceDetail}></Route>
        <Route path="/ImprRattrapage" component={ImprRattrapage}></Route>
        <Route path="/ImprTrimestre" component={ImprTrimestre}></Route>
        <Route path="/ImprProfilApp" component={ImprProfilApp}></Route>
        <Route path="/ImprAbsence" component={ImprAbsence}></Route>
        <Route path="/ImprCheqEns" component={ImprCheqEns}></Route>
        <Route path="/ImprListEns" component={ImprListEns}></Route>
        <Route path="/ImprCalEFF" component={ImprCalEFF}></Route>
        <Route path="/AjoutAnnee" component={AjoutAnnee}></Route>
        <Route path="/ImprMotif" component={ImprMotif}></Route>
        <Route path="/Imprecu" component={Imprecu}></Route>
        
        <Route path="/ImpReleveNoteFinale" component={ImpFinal}></Route>
        <Route path="/" render={() => <Login />} />
      </Switch>
    </Router>
  );
}

export default App;
