import React, { Component } from "react";
import jQuery from "jquery";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class ImpPresenceDetail extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        data: [],
   
        nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      idEnseignant: "",
      pending: true,
      detailEnseign: [],
      nomEnseign: "",
      mois: "",
      nbrPratique: "",
      nbrTheorique: "",
      nbrCycle: "",
      modalModif: false,
      ligneModif: [],
      groupeEnseign: "",
      departSeance: "",
      finSeance: "",
      dateSeance: "",
      idEnteteAbs: [],
      nbrHeureParMois:0,
      tab: [],
      tabs: [],
     
      x:1,

    };
  };


  componentDidMount() {
    
        var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var url = window.location.href;
      var array = url.split("=");
      var params = array[1];
      var arrayInfo = params.split("-");
      var nomEnseign= arrayInfo[0];
      var moisPresence = arrayInfo[1];
      var idEns = arrayInfo[2];
      var idMois = arrayInfo[3];
      var anneeScolaire = arrayInfo[4];
      var nb = arrayInfo[5];
  

     
      //var anneeScolaire = userPermissions[0].anneeScolaire;
      this.state.nomEnseign=nomEnseign ;
      this.state.moisPresence=moisPresence;
      this.state.idEns=idEns;
      this.state.idMois=idMois;
      this.state.anneeScolaire=anneeScolaire;
      this.state.nb=nb;
    
            
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/impPresenceDetails.php',
                type: 'POST',
                data: {
                    query: '',
                    nomEnseign:this.state.nomEnseign ,
                    moisPresence:this.state.moisPresence,
                    idEns:this.state.idEns,
                    idMois:this.state.idMois,
                    anneeScolaire:this.state.anneeScolaire,
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: "json",
                success: (code_html, statut) => {
                  console.log(code_html);
                  this.setState(
                    {
                      detailEnseign: code_html,
                      nomEnseign: code_html[0].nomComplet,
                      mois: code_html[0].mois,
                      anneeScolaire: code_html[0].anneeScol,
                      monthNum: code_html[0].monthNum,
                    },
                    () => {
                      var seancePr = 0;
                      var seanceTh = 0;
                      var seanceCy = 0;
                      var seanceEg = 0;
                      for (var i = 0; i < this.state.detailEnseign.length; i++) {
                        var ensType = this.state.detailEnseign[i].typeEnseign;

                         if(i>0){
              if (ensType == "EP" && this.state.detailEnseign[i].dateDebut != this.state.detailEnseign[i-1].dateDebut && this.state.detailEnseign[i].dateFin!=this.state.detailEnseign[i-1].dateFin) {
                seancePr++;
              
              } else if (ensType == "ET" && this.state.detailEnseign[i].dateDebut != this.state.detailEnseign[i-1].dateDebut && this.state.detailEnseign[i].dateFin!=this.state.detailEnseign[i-1].dateFin){
                seanceTh++;
              } else if (ensType == "EG" && this.state.detailEnseign[i].dateDebut != this.state.detailEnseign[i-1].dateDebut && this.state.detailEnseign[i].dateFin!=this.state.detailEnseign[i-1].dateFin){
                seanceEg++;
              
              }else if (ensType == "Cycle" && this.state.detailEnseign[i].dateDebut != this.state.detailEnseign[i-1].dateDebut && this.state.detailEnseign[i].dateFin!=this.state.detailEnseign[i-1].dateFin){
                seanceCy++;
              }
            }else{
                if (ensType == "EP") {
                  seancePr++;
                
                } else if (ensType == "ET"){
                  seanceTh++;
                } else if (ensType == "EG"){
                  seanceEg++;
                
                }else if (ensType == "Cycle"){
                  seanceCy++;
                }}

            }
                        this.state.nbrtotal=seanceCy + seanceEg + seanceTh + seancePr
                        for( var j = 0; j< this.state.nbrtotal-1;j++)
                        {
                          this.state.tabs[j] = this.state.nbrtotal-j
                          }
                        console.log("fff")
                        var s=1
                        for(var t = 0; t< this.state.detailEnseign.length;t++){
          
                          if(t>0){
                         if (this.state.detailEnseign[t].typeEnseign!=this.state.detailEnseign[t-1].typeEnseign && this.state.detailEnseign[t].dateDebut != this.state.detailEnseign[t-1].dateDebut && this.state.detailEnseign[t].dateFin!=this.state.detailEnseign[t-1].dateFin){
                          this.state.tab[t]= s
                          s++
                         }else if(this.state.detailEnseign[t].typeEnseign==this.state.detailEnseign[t-1].typeEnseign && this.state.detailEnseign[t].dateDebut == this.state.detailEnseign[t-1].dateDebut && this.state.detailEnseign[t].dateFin==this.state.detailEnseign[t-1].dateFin){
                          
                          this.state.tab[t]=" "
                          this.state.test="false"
                          
                        }else{
                          this.state.tab[t]=s
                          s++
                          
                        
                          }
                         
                        }else{
                          this.state.tab[t]=s
                          s++
                        }
                         console.log(this.state.tab)
                        }
                      this.setState(
                        {
                          nbrPratique: seancePr,
                          nbrTheorique: seanceTh,
                          nbrEg: seanceEg,
                          nbrCycle: seanceCy,
                        },
                       
                      );
                    }
                  );
                },
            });
        
   
}

  impr = () => {
    window.print();
  };    

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",marginTop: "4%"
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
           
                       
            

                          
                       
          </div>
        </div>
      
          <p style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}>
        <strong> Détails séances de l'enseignant(e) : {this.state.nomEnseign.replace(/%20/g, " ")} </strong></p>
                       <p style={{ display: "flex", justifyContent: "center", marginTop: "1%"}}>
                        <strong> du mois {this.state. mois}  {this.state.anneeScolaire}</strong>
        </p>
        
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
        >
     
         <table style={{ textAlign: "center", width :"80%",border: "solid", marginBottom: "0",}} >
                <thead style={{ backgroundColor:"#A9CDDB"}}>
                  <tr>
                    <th style={{ textAlign: "center", width :"10px", border: "solid", color: "black"}} scope="col">
                      Numéro
                    </th>
                    <th style={{ textAlign: "center" ,width :"20px",border: "solid", color: "black" }} scope="col">
                      Date
                    </th>
                    <th style={{ textAlign: "center",width :"30px",border: "solid", color: "black" }} scope="col">
                      Matiére
                    </th>
                    <th style={{ textAlign: "center",width :"10px",border: "solid", color: "black" }} scope="col">
                      Groupe
                    </th>
                    
                    <th style={{ textAlign: "center" ,width :"10px",border: "solid", color: "black"}} scope="col">
                      Debut
                    </th>
                    <th style={{ textAlign: "center",width :"10px",border: "solid", color: "black"}} scope="col">
                      Fin
                    </th>
                    <th style={{ textAlign: "center" ,width :"10px",border: "solid", color: "black"}} scope="col">
                      Type
                    </th>
                   
                  </tr>
                </thead>
                
                {this.state.detailEnseign.map((el, index) => (
                
                <tbody>
                  
                        {index >0 ?( 
                      el.date == this.state.detailEnseign[index -1].date && el.dateDebut==this.state.detailEnseign[index-1].dateDebut  ?(
                  <tr style={{width :"5px" ,border:"solid",color:"black"}}>
                   <td className="col-md-1" style={{ textAlign: 'center' ,border:"solid",color:"black"}}><p> {this.state.tab[index]}</p></td>
                    <td className="col-md-3" style={{ textAlign: "center",width :"20px",border:"solid",color:"black" }}>
                      <p>{this.state.date=el.date}</p>
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center",width :"30px" ,border:"solid",color:"black"}}>
                      <p>{el.nomMatiere}</p>
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center",width :"10px",border:"solid",color:"black" }}>
                      {el.nomGroupe.map((ele) => (
                        <p>{ele}</p>
                      ))}
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center",border:"solid",color:"black" }}>
                      <p>{el.heureDebut}</p>
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center" ,border:"solid",color:"black" }}>
                      <p>{el.heureFin}</p>
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center" ,width :"10px",border:"solid",color:"black"}}>
                    <p>{el.typeEnseign}</p>
                    </td>
                    
                   
                  </tr>
                      ):(
                  <tr style={{backgroundColor:"#D5DCDF",border:"solid",color:"black"}}>
                   <td className="col-md-1" style={{ textAlign: 'center',width :"5px",border:"solid",color:"black" }}><p>{this.state.tab[index]}</p></td>
                  <td className="col-md-3" style={{ textAlign: "center",width :"20px" ,border:"solid",color:"black"}}>
                    <p>{el.date}</p>
                  </td>
                  <td className="col-md-1" style={{ textAlign: "center" ,width :"30px",border:"solid",color:"black"}}>
                    <p>{el.nomMatiere}</p>
                  </td>
                  <td className="col-md-2" style={{ textAlign: "center",width :"10px" ,border:"solid",color:"black"}}>
                    {el.nomGroupe.map((ele) => (
                      <p>{ele}</p>
                    ))}
                  </td>
                  <td className="col-md-1" style={{ textAlign: "center" ,border:"solid",color:"black"}}>
                  <p>{el.heureDebut}</p>
                  </td>
                  <td className="col-md-1" style={{ textAlign: "center" ,border:"solid",color:"black"}}>
                    <p>{el.heureFin}</p>
                  </td>
                  <td className="col-md-1" style={{ textAlign: "center",width :"10px",border:"solid",color:"black" }}>
                    <p>{el.typeEnseign}</p>
                  </td>
                  
                
                  </tr>
                      )):(   <tr style={{backgroundColor:"#D5DCDF",border:"solid",color:"black"}}>
                               <td className="col-md-1" style={{ textAlign: 'center',width :"10px" ,border:"solid",color:"black"}}><p>{this.state.tab[index]}</p></td>
                                           <td className="col-md-3" style={{ textAlign: "center",width :"20px" ,border:"solid",color:"black" }}>
                                             <p>{el.date}</p>
                                           </td>
                                           <td className="col-md-1" style={{ textAlign: "center",width :"30px" ,border:"solid",color:"black" }}>
                                             <p>{el.nomMatiere}</p>
                                           </td>
                                           <td className="col-md-2" style={{ textAlign: "center" ,width :"10px",border:"solid",color:"black"}}>
                                             {el.nomGroupe.map((ele) => (
                                               <p>{ele}</p>
                                             ))}
                                           </td>
                                           <td className="col-md-1" style={{ textAlign: "center",border:"solid",color:"black" }}>
                                             <p>{el.heureDebut}</p>
                                           </td>
                                           <td className="col-md-1" style={{ textAlign: "center" ,border:"solid",color:"black"}}>
                                             <p>{el.heureFin}</p>
                                           </td>
                                           <td className="col-md-1" style={{ textAlign: "center" ,width :"10px",border:"solid",color:"black"}}>
                                           <p>{el.typeEnseign}</p>
                                           </td>
                                           
                                         
                                           </tr>)}
                                           
                </tbody>
              ))}
            
                {this.state.nbrPratique > 0 ? (
                  <tr>
                  <td style={{ border: "solid", color: "black",color: "black",textAlign: "right" ,paddingRight:"20px"}} colspan="6">
                   <strong> Nombre de séance pratique </strong>
                  </td>
                  <td style={{ color: "black" ,border: "solid", color: "black"}}><strong>{this.state.nbrPratique}</strong></td>
                  </tr>
                ) : null}
                {this.state.nbrTheorique > 0 ? (
                  <tr>
                  <td style={{color: "black",border: "solid", color: "black",textAlign: "right",paddingRight:"20px" }} colspan="6">
                  <strong>Nombre de séance théorique </strong>
                  </td>
                  <td style={{ color: "black" ,border: "solid", color: "black"}}><strong>{this.state.nbrTheorique}</strong></td>
                  </tr>
                ) : null}

                 {this.state.nbrEg > 0 ? (
                  <tr>
                  <td style={{ color: "black",border: "solid", color: "black" ,textAlign: "right",paddingRight:"20px"}} colspan="6">
                  <strong>Nombre de séance EG </strong> 
                  </td>
                  
                  <td style={{ color: "black" ,border: "solid", color: "black" }}>
                  <strong>{this.state.nbrEg}</strong>
                  </td>
                  
                  </tr>
                ) : null}
                 {this.state.nbrCycle > 0 ? (
                  <tr>
                  <td style={{ color: "black" ,border: "solid", color: "black" ,textAlign: "right",paddingRight:"20px"}} colspan="6">
                  <strong> Nombre de séance Cycles </strong>
                  </td>
                  <td style={{ color: "black" ,border: "solid", color: "black"}}>
                  <strong> {this.state.nbrCycle}</strong>
                  </td>
                  </tr>
                ) : null}
                 
                  
                 {this.state.nbrtotal > 0 ? (
                  <tr>
                  <td style={{ color: "black",border: "solid", color: "black" }} colspan="7">
                  <p style={{fontSize: "25px"}}>Nombre du séance :{this.state.nbrtotal } </p>
                  </td>
                  </tr>
                ) : null}

                 {this.state.nbrtotal > 0 ? (
                  <tr>
                  <td style={{ color: "black",border: "solid", color: "black" }} colspan="7">
                  <p style={{fontSize: "25px"}}>Nombre d'heure :{this.state.nb } h </p>
                  </td>
                  </tr>
                ) : null}
                 
                 
              </table>
             
        </div>
       
        
        <div style={{ display: "flex", justifyContent: "center"  ,padding:"20px"}}>
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter (ImpPresenceDetail);



