import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import { withRouter } from "react-router-dom";

class AjoutJuryBts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listJury: [],
      selectedJury: "",
      coefficient: "",
      listEntetExam: [],
      selectedEntetExam: "",
      liaison: [],
      liaison1: [],
      selectedLiaison: "",
      selectedLiaison1: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectEntetExamBts.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listEntetExam: code_html,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectJury.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listJury: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }
  addEntetExam = (selectedEntetExam) => {
    this.setState({
      selectedEntetExam: selectedEntetExam,
    });
  };

  addCoeff = (event) => {
    this.setState(
      {
        coefficient: event.target.value,
      },
      () => {
        if (
          this.state.selectedEntetExam.label ==
            "Traveaux préliminaires / Préparation culinaires" &&
          this.state.coefficient == 3
        ) {
          var el = [{ value: 1, label: "M8 : Dresser des mets" }];
          var l = [{ value: 1, label: "Gout et cuisson" }];
          this.setState({
            liaison: el,
            liaison1: l,
          });
        } else if (
          this.state.selectedEntetExam.label == "M8 : Dresser des mets" &&
          this.state.coefficient == 3
        ) {
          var ele = [
            {
              value: 2,
              label: "Traveaux préliminaires / Préparation culinaires",
            },
          ];
          var le = [{ value: 1, label: "Gout et cuisson" }];
          this.setState({
            liaison: ele,
            liaison1: le,
          });
        } else if (
          this.state.selectedEntetExam.label == "Gout et cuisson" &&
          this.state.coefficient == 3
        ) {
          var element = [
            {
              value: 1,
              label: "Traveaux préliminaires / Préparation culinaires",
            },
          ];
          var lai = [{ value: 1, label: "M8 : Dresser des mets" }];
          this.setState({
            liaison: element,
            liaison1: lai,
          });
        } else {
          this.setState({
            selectedLiaison: "",
          });
        }
      }
    );
  };

  addJury = (selectedJury) => {
    this.setState({ selectedJury: selectedJury });
  };

  addLiaison = (selectedLiaison) => {
    this.setState({
      selectedLiaison: selectedLiaison,
    });
  };
  addLiaison1 = (selectedLiaison1) => {
    this.setState({
      selectedLiaison1: selectedLiaison1,
    });
  };

  ajout = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedLiaison == "") {
      var liaison = 0;
    } else {
      var liaison = 1;
    }
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/AddJuryBts.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        jury: this.state.selectedJury,
        enteteExam: this.state.selectedEntetExam.value,
        coefficient: this.state.coefficient,
        liaison: liaison,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
        //   this.props.history.push("/ConsulterNoteExam");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Ajout jury BTS</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Entete Exam </label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addEntetExam}
                        options={this.state.listEntetExam}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>coefficient</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="coefficient"
                        onChange={this.addCoeff}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Jury</label>
                      <Select
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addJury}
                        options={this.state.listJury}
                      />
                    </div>
                  </div>
                  {this.state.selectedEntetExam.label ==
                    "Traveaux préliminaires / Préparation culinaires" &&
                  this.state.coefficient == 3 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Entente Exam</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addLiaison}
                          options={this.state.liaison}
                        />
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addLiaison1}
                          options={this.state.liaison1}
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.state.selectedEntetExam.label ==
                    "M8 : Dresser des mets" && this.state.coefficient == 3 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Entente Exam</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addLiaison}
                          options={this.state.liaison}
                        />
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addLiaison1}
                          options={this.state.liaison1}
                        />
                      </div>
                    </div>
                  ) : null}
                  {this.state.selectedEntetExam.label ==
                    "Gout et cuisson" && this.state.coefficient == 3 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Entente Exam</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addLiaison}
                          options={this.state.liaison}
                        />
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addLiaison1}
                          options={this.state.liaison1}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    style={{ borderRadius: "5px" }}
                    type="button"
                    className="btn btn-success"
                    onClick={this.ajout}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AjoutJuryBts);
