import React, { Component } from 'react'
import { Link} from 'react-router-dom'
import axios from 'axios'
import jQuery from 'jquery'
import Select from 'react-select';
import './paginate.css'


export class ModifVisiteurs extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            idVisiteur: '',
            niveaux: [],
            selectedNivBase: '',
            nom: '',
            prenom: '',
            dateNaissance : '',
            mobile : '' ,
            
        }
    }


    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var idRoleUser = userPermissions[0].idRoleUser
            if (idRoleUser == 2) {
                var idVisiteur = this.props.location.state.idVisiteur;
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetvisiteurById.php?id=' + idVisiteur).then(res => {
                    console.log(res)
                    this.setState({
                        idVisiteur: res.data.idVisiteur,
                        nom: res.data.nom,
                        prenom: res.data.prenom,
                        dateNaissance : res.data.dateNaissance, 
                        selectedNivBase: res.data.nivBase,
                        mobile: res.data.mobile
                    })
                })
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectNivBase.php').then(res => {
                    console.log(res)
                    this.setState({
                        niveaux: res.data
                    })
                })
            }
            else {
                localStorage.clear();
                this.props.history.push('/');
            }
        }
        else {
            document.location = '/'
        }
    }
  
    modifNom = (event) => {
        this.setState({
            nom: event.target.value
        });
    }
    
    modifPrenom = (event) => {
        this.setState({
            prenom: event.target.value
        });
    }

    modifDate = (event) => {
        this.setState({
            dateNaissance: event.target.value
        });
    }

    modifNiveau = selectedNivBase => {
        this.setState({ selectedNivBase });
        console.log(`NiveauBase selected:`, selectedNivBase);
    };

    modifMobile = (event) => {
        this.setState({
            mobile: event.target.value
        });
    }



    Modifier = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdVisiteur.php?id=' + this.state.idVisiteur,
            type: 'POST',
            data: {
                nom: this.state.nom,
                prenom: this.state.prenom,
                dateNaissance : this.state.dateNaissance,
                nivBaseId: this.state.selectedNivBase.value,
                mobile: this.state.mobile,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    document.location = '/Visiteurs'
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }
  
    render() {
    return (
        <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification Visiteur</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Visiteurs" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Prénom</label>
                                            <input type="text" className="form-control" value={this.state.prenom} onChange={this.modifPrenom} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label> Nom </label>
                                            <input type="text" className="form-control" value={this.state.nom} onChange={this.modifNom} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label> Date de naissance  </label>
                                            <input type="text" className="form-control" value={this.state.dateNaissance} onChange={this.modifDate} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label> Niveau de base </label>
                                            <Select
                                                value={this.state.selectedNivBase}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.modifNiveau}
                                                options={this.state.niveaux}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mobile</label>
                                            <input type="text" className="form-control" value={this.state.mobile} onChange={this.modifMobile} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.Modifier}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
    )
  }
}

export default ModifVisiteurs