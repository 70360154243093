import React, { Component } from "react";
import jQuery from "jquery";
import "./paginate.css";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

class ImprMotifEleve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomElv: "",
      scolAnnee: "",
      naissance: "",
      cin: "",
      nationalite: "",
      ins: "",
      niveau: "",
      today: "",
      motif: "",
      selectedMotif: "",
      codeTranstu: "",
      anneeScolaire: "",
      disabled: false,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var anneeScolaireNv = parseInt(anneeScolaire) + 1;
      var scolAnnee = anneeScolaire + "/" + anneeScolaireNv;
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var idAttestation = arrayUrl[1];
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoAttestation.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          idAttestation: idAttestation,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            selectedMotif: code_html.motifId,
            motif: code_html.motif,
            today: code_html.today,
            nomElv: code_html.nomElv,
            naissance: code_html.naissance,
            cin: code_html.cin,
            nationalite: code_html.nationalite,
            ins: code_html.idIns,
            niveau: code_html.niveau,
            scolAnnee: scolAnnee,
            codeTranstu: code_html.code,
            anneeScolaire: anneeScolaire,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  impr = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div style={{ margin: "0px" }} className="row">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                textAlign: "left",
              }}
            >
              <p style={{ fontWeight: "bold" }}>Arts & Métiers</p>
              <p style={{ fontSize: "15px" }}>
                N° d'enregistrement : 111-159-01
              </p>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <p style={{ fontWeight: "bold" }}>Date:</p>
                <p style={{ paddingLeft: "5px" }}>{this.state.today}</p>
              </div>
              {this.state.selectedMotif == 5 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>Code TRANSTU</p>
                  <p style={{ paddingLeft: "5px", fontWeight: "bold" }}>
                    {this.state.codeTranstu}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <h4
            style={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              fontSize: "30px",
              textDecoration: "underline",
              marginTop: "5%",
            }}
          >
            Attestation de présence
          </h4>
          <p
            style={{
              fontSize: "20px",
              marginTop: "10%",
              textAlign: "left",
            }}
          >
            Je soussigné, Taoufik CHAIRI, Directeur Général du centre de
            formation
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "left",
            }}
          >
            <strong> " Arts & Métiers " </strong> , sis au 8 rue borj Bourguiba
            , 1001 Tunis , atteste par la
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "left",
            }}
          >
            présente que l'apprenant(e):
          </p>
          <div style={{ display: "flex", textAlign: "left", fontSize: "20px" }}>
            <p style={{ fontWeight: "bold" }}>Nom et Prénom :</p>
            <p style={{ paddingLeft: "5px" }}>{this.state.nomElv}</p>
          </div>
          <div style={{ display: "flex", textAlign: "left", fontSize: "20px" }}>
            <p style={{ fontWeight: "bold" }}>Né(e) le :</p>
            <p style={{ paddingLeft: "5px" }}>{this.state.naissance}</p>
          </div>
          <div style={{ display: "flex", textAlign: "left", fontSize: "20px" }}>
            <p style={{ fontWeight: "bold" }}>CIN N ° OU Passeport :</p>
            <p style={{ paddingLeft: "5px" }}>{this.state.cin}</p>
          </div>
          <div style={{ display: "flex", textAlign: "left", fontSize: "20px" }}>
            <p style={{ fontWeight: "bold" }}>Nationalité :</p>
            <p style={{ paddingLeft: "5px" }}>{this.state.nationalite}</p>
          </div>
          <p
            style={{
              fontSize: "20px",
              textAlign: "left",
            }}
          >
            Poursuit sa formation régulièrement pendant l'année de formation{" "}
            {this.state.scolAnnee}
          </p>
          <p
            style={{
              fontSize: "20px",
              textAlign: "left",
            }}
          >
             Session Juillet {this.state.anneeScolaire}
          </p>
          <div
            style={{
              display: "flex",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              {" "}
              Spécialité :{" "}
            </p>
            <p
              style={{
                paddingLeft: "5px",
                fontSize: "20px",
                textAlign: "left",
              }}
            >
              {" "}
              {this.state.niveau}{" "}
            </p>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width: "10%",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Type :{" "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "80%",
              }}
            >
              <div style={{ paddingLeft: "3%", display: "flex" }}>
                <label>Homologué</label>
                <input type="checkbox" />
              </div>
              <div style={{ paddingLeft: "3%", display: "flex" }}>
                <label> Non Homologué</label>
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width: "20%",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Diplome :{" "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "80%",
              }}
            >
              <div style={{ paddingLeft: "3%", display: "flex" }}>
                <label>CAP</label>
                <input type="checkbox" />
              </div>
              <div style={{ paddingLeft: "3%", display: "flex" }}>
                <label> BTP </label>
                <input type="checkbox" />
              </div>
              <div style={{ paddingLeft: "3%", display: "flex" }}>
                <label> BTS </label>
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width: "20%",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Niveau :{" "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "80%",
                marginLeft: "5%",
              }}
            >
              <div style={{ paddingLeft: "3%", display: "flex" }}>
                <label> 1ére année </label>
                <input type="checkbox" />
              </div>
              <div style={{ paddingLeft: "3%", display: "flex" }}>
                <label> 2éme année </label>
                <input type="checkbox" />
              </div>
            </div>
          </div>
          <p
            style={{
              fontSize: "20px",
              textAlign: "left",
            }}
          >
            Cette Attestation est délivrée à l'intéressée(e) pour constituer un
            dossier de {this.state.motif} .
          </p>
          <p
            style={{
              textAlign: "right",
              fontWeight: "bold",
              marginTop: "20px",
              marginRight: "20%",
            }}
          >
            Le Directeur Général
          </p>
          <p
            style={{
              textAlign: "right",
              fontWeight: "bold",
              marginTop: "20px",
              marginRight: "20%",
            }}
          >
            Taoufik CHAIRI
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default ImprMotifEleve;
