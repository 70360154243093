import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { RadioGroup, Radio } from 'react-radio-group'
import Switch from "react-switch";
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



class ajoutAnnee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            testann:0,
            ann:"",
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            modal: false,
            idMatiere: '',
            pending: true,
            formation:[],
            matier:[],
            sousmatier:[],
            filier:[],
            matier:[],
            niveau:[],
            disbut: true,
            specialite:[],
            groupe:[],
            frais:[],
            test:false,
            aff:false,
            
        }
        //this.newElement = this.newElement.bind(this);
        //this.filterSearch = this.filterSearch.bind(this);
    }
    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
            var anneeScolaire = userPermissions[0].anneeScolaire;
            this.state.ann=anneeScolaire-1
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Ajoutannee.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire,
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        formation: code_html.forma,
                        matier: code_html.mat,
                        sousmatier: code_html.soumat,
                        filier: code_html.fil,
                        specialite: code_html.spec,
                        niveau:code_html.niveau,
                        groupe:code_html.groupe,
                        frais:code_html.Affrais,
                        pending: false
                    });
                   
                    console.log(this.state.data)
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    Add = () => {
        this.setState({ disbut: false }, () => {
           
        jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL + 'Back_mediatraining/Add.php',
    
            type: "POST",
            data: {
                formations:this.state.formation,
                matier:this.state. matier,
                filiere:this.state.filier,
                specialite:this.state.specialite,
                groupe:this.state.groupe,
                niveau:this.state.niveau,
                frais:this.state.frais,
                soutmatier:this.state.sousmatier,
                anneeScolaire:this.state.ann+2,
            },
            
            dataType: "json",
            
            success: (code_html, statut) => {
              if (code_html.Result == "OK") {
                this.setState({ disbut: true })
                this.setState({aff:true})
                alert("Ajout avec succes!");
              } else if (code_html.Result == "KO") {
                alert("impossible d'ajouter, annee existant!!!");
                this.state.testann=1;
                document.location = '/AjoutAnnee'
              }
            },
          });
        }) 
    }
    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div >
                        
                        <div style={{display :"none"}} >
                            
                                
                            
                              
                                       
                                
                        </div>
                        <h2 align='center'><strong> Ajouter Nouvelle Annee {this.state.annee}</strong>  </h2>
                        <button 
                                          style={{ borderRadius: "5px",align:"center",width:"120px"}}
                                          type="button"
                                          className="btn btn-success"
                                          disabled={!this.state.disbut}
                                          onClick={this.Add}>Ajout annee {this.state.annee}</button>
                        <div/>
                        

                        
                        {this.state.disbut==false? (
                            <h4 style={{align:'center',padding:'60px'}}>  Création du nouvelle annee est en cours... </h4>
                        ):(null)}
                        {this.state.aff==true?(
                           <strong><p style={{align:'center',padding:'60px'}}>  Création terminer </p> </strong>  
                        ):null}
                        </div>
                        
                        </div>
                      
                    </div>
                
        );
    }
}
export default withRouter(ajoutAnnee);