import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";

class ImprReleveNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomEleve: "",
      naissance: "",
      filiere: "",
      niveau: "",
      diplome: "",
      groupe: "",
      annee: "",
      anneeScolaire: "",
      stage: "",
      specialite:"",
      nom:'',
      prenom:'',
      pfe: "",
      moyenneGen: "",
      nivEleve: "",
      selectTrimestre: "",
      notes:[],
      blockNote: false,
      scolaire:'',
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
 
    
      var url = window.location.href;
      var arrayUrl = url.split("=");
      
            var eleveIds = arrayUrl[1]
            var arrayeleveId = eleveIds.split("/");
            var eleveId = arrayeleveId[0];
           var selectTrimestre= arrayeleveId[1];
           var annee=arrayeleveId[2];

      this.state.selectTrimestre=selectTrimestre
      this.state.anneeScolaire=annee;
      this.state.eleveId= eleveId
  
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ReleveNote.php",
        type: "POST",
        data: {
          
          anneeScolaire: this.state.anneeScolaire-1,
          eleveId: this.state.eleveId,
          trimestre: this.state.selectTrimestre,
          
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            notes: code_html,
          });
        },
        
      });
      
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoByIdEleve.php",
        type: "POST",
        data: {
          anneeScolaire: this.state.anneeScolaire-1,
          eleveId: this.state.eleveId,
          trimestre: this.state.selectTrimestre,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            inscriptionId: code_html.inscriptionId,
            niveau: code_html.niveau,
            nomFiliere: code_html.nomFiliere,
            groupe: code_html.groupe,
            specialite: code_html.nom,
            nom: code_html.nome,
            prenom: code_html.prenomeleve,
            scolaire: code_html.anneescolaire,
            

          });
        },
      });
      
      this.setState({
        blockNote: true,
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            paddingTop:"20px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p style={{width:"200px" ,paddingLeft:"50px"}}> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
          </div>
        </div>
        <div style={{ paddingTop: "20px" }}>
          
          <table
            border={0}
            align="center"
            cellSpacing={1}
            cellPadding={4}
            width={900}
          >
            {this.state.blockNote == true ? (
            <tbody>
              <tr>
                <td width="100%" align height={54}>
                  <b>
                    <font
                      style={{ fontSize: "14px", textAlign: "center" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      
                      <h3>{"Relevé  de  notes Trimestre  N°  " + this.state.selectTrimestre}</h3>
                    </font>
                    
                  </b>
                 
                </td>
              </tr>
              <tr>
                <td width="100%" align={"center"}>
                  <b>
                    <font
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      <h4 style={{ fontWeight: "bold" }}>
                        {" "}
                        Annee Scolaire : {this.state.scolaire-1} /{this.state.scolaire}&nbsp;
                      </h4>
                    </font>
                  </b>
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Apprenant:</u>
                    </b>
                    &nbsp; {this.state.nom} {this.state.prenom}
                  </font>{" "}
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Numéro Inscription :</u>
                    </b>
                    &nbsp;  {this.state.inscriptionId}
                  </font>{" "}
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Formation:</u>
                    </b>
                    &nbsp;De Octobre {this.state.scolaire-1} à Juillet {this.state.scolaire}

                  </font>{" "}
                
                </td>
               
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Spécialité:</u>
                    </b>
                    &nbsp;{this.state.specialite}
                  </font>{" "}
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Niveau:</u>
                    </b>
                    &nbsp; {this.state.niveau}
                  </font>{" "}
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Groupe:</u>
                    </b>
                    &nbsp; {this.state.groupe}
                  </font>{" "}
                </td>
              </tr>
            </tbody>
            ):null}
          </table>
        </div>
        <div>
          {" "}
          <div style={{ display: "flex", justifyContent: "center" }}>
          <table
            border={2}
            align="center"
            cellSpacing={1}
            cellPadding={4}
            width={900}
          >
                      <thead>
                        <tr>
                          <th style={{ color: "#ac3939",border:'1' }}>Enseignement</th>
                          <th style={{ color: "#ac3939" , display: "flex"}}>
                          <p style={{paddingLeft :"100px"}}>Matiére</p>  <p style={{paddingLeft :"300px"}}>Moyenne</p> 
                          </th>
                          
                        
                          
                          <th style={{ color: "#ac3939" }}>
                            Moyenne Enseignement
                          </th>
                        
                       
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.notes.map((el) => (
                          <tr>
                            <td>{el.notes.nameEP}</td>
                            <td>
                              {el.notes.noteEP.map((elem) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{ width: "20vw" }}>
                                    {" "}
                                    {elem.matiere}{" "}
                                  </p>
                                 
                                  <p> {elem.matiereNote} </p>
                          
                                  
                                </div>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.notes.moyEP} </p>
                            </td>
                          </tr>
                        ))}
                        {this.state.notes.map((el) => (
                          <tr>
                            <td>{el.notes.nameET}</td>
                            <td>
                              {el.notes.noteET.map((elem) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{ width: "20vw" }}>
                                    {" "}
                                    {elem.matiere}{" "}
                                  </p>
                                  <p> {elem.matiereNote} </p>
                                </div>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.notes.moyET} </p>
                            </td>
                          </tr>
                        ))}
                        {this.state.notes.map((el) => (
                          <tr>
                            <td>{el.notes.nameEG}</td>
                            <td>
                              {el.notes.noteEG.map((elem) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{ width: "20vw" }}>
                                    {" "}
                                    {elem.matiere}{" "}
                                  </p>
                                  <p> {elem.matiereNote} </p>
                                </div>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.notes.moyEG} </p>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                              Moyenne Trimestrielle {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {this.state.notes.map((element) => (
                              <p> {element.moyGen} </p>
                            ))}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
          </div>{" "}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprReleveNote);
