import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class AjoutMatiere extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matieres: [{ code: '', nomMatiere: '', abrev: '', typeEns: '' }],
            indexLignMatiereASupprimer: '',
            modal: false,
            ListTypeEns: []
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectTypeEns.php').then(res => {
                console.log(res)
                this.setState({
                    ListTypeEns: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    additionLignMatiere = () => {
        let element = { code: '', nomMatiere: '', abrev: '', typeEns: '' }
        this.setState({
            matieres: [...this.state.matieres, element]
        })
    }

    addCode = (event, indexCode) => {
        let code = event.target.value;
        this.setState({
            matieres: this.state.matieres.map((el, id) => (id === indexCode ? Object.assign(el, { code: code }) : el))
        });
    }

    addNomMatiere = (event, indexNomMatiere) => {
        let nomMatiere = event.target.value;
        this.setState({
            matieres: this.state.matieres.map((el, id) => (id === indexNomMatiere ? Object.assign(el, { nomMatiere: nomMatiere }) : el))
        });
    }

    addAbrev = (event, indexAbrev) => {
        let abrev = event.target.value;
        this.setState({
            matieres: this.state.matieres.map((el, id) => (id === indexAbrev ? Object.assign(el, { abrev: abrev }) : el))
        });
    }

    addTypeEns = (selectedTypeEns, indexTypeEns) => {
        let typeEns = selectedTypeEns;
        this.setState({
            matieres: this.state.matieres.map((el, id) => (id === indexTypeEns ? Object.assign(el, { typeEns: typeEns }) : el))
        });
    }

    toggleLignMatiere = (indexLignMatiere) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            indexLignMatiereASupprimer: indexLignMatiere
        }));
    }

    deleteLignMatiere = () => {
        this.state.matieres.splice(this.state.indexLignMatiereASupprimer, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteLignMatiere = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    ajout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddMatiere.php',
            type: 'POST',
            data: {
                matieres: this.state.matieres,
                anneeScolaire: anneeScolaire,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Matieres');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout matiére</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Matieres"> <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <table style={{ width: '100%', marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Code</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Abrev</th>
                                                <th style={{ textAlign: 'center' }} scope="col">TypeEns</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                            </tr>
                                        </thead>
                                        {this.state.matieres.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" placeholder='Code' value={el.code} onChange={(e) => this.addCode(e, index)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" placeholder='Nom matiére' value={el.nomMatiere} onChange={(e) => this.addNomMatiere(e, index)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" placeholder='Abréviation' value={el.abrev} onChange={(e) => this.addAbrev(e, index)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                    <td className="col-md-4" ><Select
                                                        value={el.typeEns}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.addTypeEns(e, index)}
                                                        options={this.state.ListTypeEns}
                                                    />  </td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleLignMatiere(index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                        <Button style={{ borderRadius: '5px' }} onClick={this.additionLignMatiere} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                    </div>
                                </div>
                                <Modal isOpen={this.state.modal} toggle={this.nonDeleteLignMatiere} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteLignMatiere}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer cette matiére ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteLignMatiere()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteLignMatiere()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AjoutMatiere);