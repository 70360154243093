import React, { Component } from 'react'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RadioGroup, Radio } from 'react-radio-group'
import axios, { post } from 'axios';

class AjoutAvis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateAvis: new Date(),
            avis: '',
            file: null,
            selectedApp: -1,
            blockApp: false,
            apprenant: [],
            selectedAppPart: [],
            enseignant: [],
            selectedEnsPart: [],
            selectedEns: -1,
            blockEns: false,
            groupe: [],
            selectedGroupe: [],
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetGroupe.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        groupe: code_html
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEleves.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        apprenant: code_html
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEnsByAnneeScol.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        enseignant: code_html
                    })
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    addDateAvis = (d) => {
        this.setState({
            dateAvis: d,
        });
    }

    addAvis = (e) => {
        this.setState({
            avis: e.target.value,
        });
    };

    addFile(e) {
        this.setState({ file: e.target.files[0] })
    }

    addGroupe = selectedGroupe => {
        this.setState({ selectedGroupe: selectedGroupe });
    };

    addApp = (e) => {
        console.log(e)
        this.setState({
            selectedApp: e
        }, () => {
            if (e == 2) {
                this.setState({
                    blockApp: true
                });
            }
            else {
                this.setState({
                    blockApp: false
                });
            }
        });
    };

    addEns = (e) => {
        this.setState({
            selectedEns: e
        }, () => {
            if (e == 2) {
                this.setState({
                    blockEns: true
                });
            }
            else {
                this.setState({
                    blockEns: false
                });
            }
        });
    };

    addAppPart = selectedAppPart => {
        if (selectedAppPart != null) {
            if (this.state.blockApp == true) {
                this.setState({ selectedAppPart: selectedAppPart });
            }
            else {
                this.setState({ selectedAppPart: [] });
            }
        }
        else {
            this.setState({ selectedAppPart: [] });
        }
    };

    addEnsPart = selectedEnsPart => {
        if (selectedEnsPart != null) {
            if (this.state.blockEns == true) {
                this.setState({ selectedEnsPart: selectedEnsPart });
            }
            else {
                this.setState({ selectedEnsPart: [] });
            }
        }
        else {
            this.setState({ selectedEnsPart: [] });
        }
    };

    ajout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        // convertir date avis
        var dateAvis = this.state.dateAvis
        let month = '' + (dateAvis.getMonth() + 1);
        let day = '' + dateAvis.getDate();
        let year = dateAvis.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        var convertDate = [year, month, day].join('-');
        //Test si file vide
        var pathFile = '';
        if (this.state.file != null) {
            pathFile = this.state.file.name
        }
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddAvis.php',
            type: 'POST',
            data: {
                anneeScolaire: anneeScolaire,
                dateAvis: convertDate,
                avis: this.state.avis,
                filePath: pathFile,
                groupe: this.state.selectedGroupe,
                apprenant: this.state.selectedAppPart,
                enseignant: this.state.selectedEnsPart
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    if (pathFile != '') {
                        const url = process.env.REACT_APP_API_URL + 'Back_mediatraining/UploadDoc.php';
                        const formData = new FormData();
                        formData.append('file', this.state.file)
                        const config = {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }
                        post(url, formData, config);
                        this.props.history.push('/ConsulterAvis');
                    }
                    this.props.history.push('/ConsulterAvis');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout avis</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/ConsulterAvis" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.dateAvis}
                                                onChange={this.addDateAvis}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Avis</label>
                                            <input type="text" className="form-control" placeholder="Avis" onChange={this.addAvis} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Fichier</label>
                                            <input type="file" className="form-control" name="file" onChange={(e) => this.addFile(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Groupe</label>
                                            <Select
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addGroupe}
                                                options={this.state.groupe}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: '10px' }} >
                                    <div className="col-md-6">
                                        <label>Apprenant</label>
                                        <RadioGroup
                                            name="Apprenant"
                                            selectedValue={this.state.selectedApp}
                                            onChange={this.addApp}
                                        >
                                            <label style={{ paddingRight: "5px" }}>
                                                <Radio value="1" />
                                                Tous les apprenants
                                    </label>
                                            <label style={{ paddingRight: "5px" }}>
                                                <Radio value="2" />
                                                Apprenants Particuliers
                                    </label>
                                        </RadioGroup>
                                    </div>
                                    {this.state.blockApp == true ? (<div className="col-md-6">
                                        <div className="form-group">
                                            <label>Choisir apprenant</label>
                                            <Select
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addAppPart}
                                                options={this.state.apprenant}
                                            />
                                        </div>
                                    </div>) : (null)}
                                </div>
                                <div className="row" style={{ marginBottom: '10px' }} >
                                    <div className="col-md-6">
                                        <label>Enseignant</label>
                                        <RadioGroup
                                            name="Enseignant"
                                            selectedValue={this.state.selectedEns}
                                            onChange={this.addEns}
                                        >
                                            <label style={{ paddingRight: "5px" }}>
                                                <Radio value="1" />
                                                Tous les enseignants
                                    </label>
                                            <label style={{ paddingRight: "5px" }}>
                                                <Radio value="2" />
                                                Enseignants Particuliers
                                    </label>
                                        </RadioGroup>
                                    </div>
                                    {this.state.blockEns == true ? (<div className="col-md-6">
                                        <div className="form-group">
                                            <label>Choisir enseignant</label>
                                            <Select
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addEnsPart}
                                                options={this.state.enseignant}
                                            />
                                        </div>
                                    </div>) : (null)}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                </div>
            </div>
        );
    }
}

export default withRouter(AjoutAvis);