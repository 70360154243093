import React, { Component } from "react";
import jQuery from "jquery";
import Table from "reactstrap/lib/Table";
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class ModifNoteStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eleve: "",
      arrayNote: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var idEleve = this.props.location.state.idEleve;
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/GetEleveById.php",
        type: "POST",
        data: {
          idEleve: idEleve,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            eleve: code_html,
            arrayNote: code_html.note,
          });
        },
      });
    }
  }

  changeNote = (indexOfColunm, event) => {
    if (parseFloat(event) <= 20) {
      var arrayNote = this.state.arrayNote;
      arrayNote[indexOfColunm].noteStage = event;
      this.setState({ arrayNote: arrayNote });
    } else if (event == "") {
      var arrayNote = this.state.arrayNote;
      arrayNote[indexOfColunm].noteStage = "";
      this.setState({ arrayNote: arrayNote });
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      });
    }
  };

  modif = () => {
    var idEleve = this.props.location.state.idEleve;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/ModifNoteStage.php",
      type: "POST",
      data: {
        arrayNote: this.state.arrayNote,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/ConsulterNoteStage");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Modification notes de stage</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Eleve</th>
                        <th style={{ textAlign: 'center' }}>Note Stage</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td style={{ textAlign: 'center' }}>{this.state.eleve.nomEleve}</td>
                      <td style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {this.state.arrayNote.map((el, indexOfColunm) => (
                          <p>
                            <input
                              type="text"
                              value={el.noteStage}
                              onChange={(e) =>
                                this.changeNote(indexOfColunm, e.target.value)
                              }
                            />
                          </p>
                        ))}
                      </td>
                    </tbody>
                  </Table>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
      </div>
    );
  }
}

export default withRouter(ModifNoteStage);
