import React, { Component, Fragment } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

class ModifReglementEnseignant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enseignant: [],
            selectedEns: '',
            regEnsNv: [],
            regEnsAnc: [],
            disabledForAnc: false,
            disabledCycleAnc: false,
            disabledSurveilAnc: false,
            disabledForNv: false,
            disabledCycleNv: false,
            disabledSurveilNv: false,
           // datePaiement: new Date(),
            tabModPay: [],
            modal: false,
            indexLignASupprimer: '',
            tabIdPieceRegDelete: [],
            idPieceRegDelete: '',
            idReg: '',
            modalSup: false,
            disbut: true
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var idRoleUser = userPermissions[0].idRoleUser
            if (idRoleUser == 1) {
                var anneeScolaire = userPermissions[0].anneeScolaire
                var idReg = this.props.location.state.idReg;
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetRegEnsById.php',
                    type: 'POST',
                    data: {
                        idReg: idReg,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            idReg: idReg,
                            regEnsAnc: code_html[0],
                            regEnsNv: code_html[1],
                            tabModPay: code_html[2],
                            datePaiement: new Date(code_html[2][0].dateReg)
                        }, () => {
                            for (var i = 0; i < this.state.tabModPay.length; i++) {
                                if (this.state.tabModPay[i].echeanceChec != "0000-00-00") {
                                    var echChec = new Date(this.state.tabModPay[i].echeanceChec)
                                    this.setState({
                                        tabModPay: this.state.tabModPay.map((el, id) => (id === i ? Object.assign(el, { echeanceChec: echChec }) : el))
                                    });
                                }
                            }
                        })
                    }
                });
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectModePaiement.php').then(res => {
                    console.log(res)
                    this.setState({
                        listModPay: res.data
                    })
                })
            }
            else {
                localStorage.clear();
                this.props.history.push('/');
            }
        }
        else {
            document.location = '/'
        }
    }

    // Année précédente
    mntPayerForAnc = (event, index, indice) => {
        var mntPayFor = event.target.value
        if (this.state.regEnsAnc[index].formation[indice].mntTotal >= parseInt(mntPayFor) + this.state.regEnsAnc[index].formation[indice].totalPayer) {
            var arrayFor = this.state.regEnsAnc
            var elFor = arrayFor[index].formation[indice]
            elFor: Object.assign(elFor, { mntAPayer: mntPayFor })
            this.setState({
                regEnsAnc: arrayFor
            }, () => {
                var mntRest = this.state.regEnsAnc[index].formation[indice].mntTotal - (this.state.regEnsAnc[index].formation[indice].totalPayer + parseInt(this.state.regEnsAnc[index].formation[indice].mntAPayer))
                var arrayFor = this.state.regEnsAnc
                var elFor = arrayFor[index].formation[indice]
                elFor: Object.assign(elFor, { reste: mntRest })
                this.setState({
                    regEnsAnc: arrayFor
                });
            });
        }
        else {
            toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
        }
    }

    mntPayerCycleAnc = (event, index, indice) => {
        var mntPayCycle = event.target.value
        if (this.state.regEnsAnc[index].cycles[indice].mntTotal >= parseInt(mntPayCycle) + this.state.regEnsAnc[index].cycles[indice].totalPayer) {
            var arrayCycle = this.state.regEnsAnc
            var elCycle = arrayCycle[index].cycles[indice]
            elCycle: Object.assign(elCycle, { mntAPayer: mntPayCycle })
            this.setState({
                regEnsAnc: arrayCycle
            }, () => {
                var mntRest = this.state.regEnsAnc[index].cycles[indice].mntTotal - (this.state.regEnsAnc[index].cycles[indice].totalPayer + parseInt(this.state.regEnsAnc[index].cycles[indice].mntAPayer))
                var arrayCycle = this.state.regEnsAnc
                var elCycle = arrayCycle[index].cycles[indice]
                elCycle: Object.assign(elCycle, { reste: mntRest })
                this.setState({
                    regEnsAnc: arrayCycle
                });
            });
        }
        else {
            toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
        }
    }

    mntPayerSurveilAnc = (event, index, indice) => {
        var mntPaySurveil = event.target.value
        if (this.state.regEnsAnc[index].surveillances[indice].mntTotal >= parseInt(mntPaySurveil) + this.state.regEnsAnc[index].surveillances[indice].totalPayer) {
            var arraySurveil = this.state.regEnsAnc
            var elSurveil = arraySurveil[index].surveillances[indice]
            elSurveil: Object.assign(elSurveil, { mntAPayer: mntPaySurveil })
            this.setState({
                regEnsAnc: arraySurveil
            }, () => {
                var mntRest = this.state.regEnsAnc[index].surveillances[indice].mntTotal - (this.state.regEnsAnc[index].surveillances[indice].totalPayer + parseInt(this.state.regEnsAnc[index].surveillances[indice].mntAPayer))
                var arraySurveil = this.state.regEnsAnc
                var elSurveil = arraySurveil[index].surveillances[indice]
                elSurveil: Object.assign(elSurveil, { reste: mntRest })
                this.setState({
                    regEnsAnc: arraySurveil
                });
            });
        }
        else {
            toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
        }
    }

    addPayTotForAnc = (e, index, indice) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayFor = this.state.regEnsAnc
            var mntPayFor = arrayFor[index].formation[indice].reste
            var elFor = arrayFor[index].formation[indice]
            elFor: Object.assign(elFor, { mntAPayer: mntPayFor })
            this.setState({
                regEnsAnc: arrayFor
            }, () => {
                var mntRest = this.state.regEnsAnc[index].formation[indice].mntTotal - (this.state.regEnsAnc[index].formation[indice].totalPayer + parseInt(this.state.regEnsAnc[index].formation[indice].mntAPayer))
                var arrayFor = this.state.regEnsAnc
                var elFor = arrayFor[index].formation[indice]
                elFor: Object.assign(elFor, { reste: mntRest })
                this.setState({
                    regEnsAnc: arrayFor
                });
            });
        }
        else {
            var arrayFor = this.state.regEnsAnc
            var mntPayFor = 0
            var elFor = arrayFor[index].formation[indice]
            elFor: Object.assign(elFor, { mntAPayer: mntPayFor })
            this.setState({
                regEnsAnc: arrayFor
            }, () => {
                var mntRest = this.state.regEnsAnc[index].formation[indice].mntTotal - (this.state.regEnsAnc[index].formation[indice].totalPayer + parseInt(this.state.regEnsAnc[index].formation[indice].mntAPayer))
                var arrayFor = this.state.regEnsAnc
                var elFor = arrayFor[index].formation[indice]
                elFor: Object.assign(elFor, { reste: mntRest })
                this.setState({
                    regEnsAnc: arrayFor
                });
            });
        }
    }

    addPayTotCycleAnc = (e, index, indice) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayCycle = this.state.regEnsAnc
            var mntPayCycle = arrayCycle[index].cycles[indice].reste
            var elCycle = arrayCycle[index].cycles[indice]
            elCycle: Object.assign(elCycle, { mntAPayer: mntPayCycle })
            this.setState({
                regEnsAnc: arrayCycle
            }, () => {
                var mntRest = this.state.regEnsAnc[index].cycles[indice].mntTotal - (this.state.regEnsAnc[index].cycles[indice].totalPayer + parseInt(this.state.regEnsAnc[index].cycles[indice].mntAPayer))
                var arrayCycle = this.state.regEnsAnc
                var elCycle = arrayCycle[index].cycles[indice]
                elCycle: Object.assign(elCycle, { reste: mntRest })
                this.setState({
                    regEnsAnc: arrayCycle
                });
            });
        }
        else {
            var arrayCycle = this.state.regEnsAnc
            var mntPayCycle = 0
            var elCycle = arrayCycle[index].cycles[indice]
            elCycle: Object.assign(elCycle, { mntAPayer: mntPayCycle })
            this.setState({
                regEnsAnc: arrayCycle
            }, () => {
                var mntRest = this.state.regEnsAnc[index].cycles[indice].mntTotal - (this.state.regEnsAnc[index].cycles[indice].totalPayer + parseInt(this.state.regEnsAnc[index].cycles[indice].mntAPayer))
                var arrayCycle = this.state.regEnsAnc
                var elCycle = arrayCycle[index].cycles[indice]
                elCycle: Object.assign(elCycle, { reste: mntRest })
                this.setState({
                    regEnsAnc: arrayCycle
                });
            });
        }
    }

    addPayTotSurveilAnc = (e, index, indice) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arraySurveil = this.state.regEnsAnc
            var mntPaySurveil = arraySurveil[index].surveillances[indice].reste
            var elSurveil = arraySurveil[index].surveillances[indice]
            elSurveil: Object.assign(elSurveil, { mntAPayer: mntPaySurveil })
            this.setState({
                regEnsAnc: arraySurveil
            }, () => {
                var mntRest = this.state.regEnsAnc[index].surveillances[indice].mntTotal - (this.state.regEnsAnc[index].surveillances[indice].totalPayer + parseInt(this.state.regEnsAnc[index].surveillances[indice].mntAPayer))
                var arraySurveil = this.state.regEnsAnc
                var elSurveil = arraySurveil[index].surveillances[indice]
                elSurveil: Object.assign(elSurveil, { reste: mntRest })
                this.setState({
                    regEnsAnc: arraySurveil
                });
            });
        }
        else {
            var arraySurveil = this.state.regEnsAnc
            var mntPaySurveil = 0
            var elSurveil = arraySurveil[index].surveillances[indice]
            elSurveil: Object.assign(elSurveil, { mntAPayer: mntPaySurveil })
            this.setState({
                regEnsAnc: arraySurveil
            }, () => {
                var mntRest = this.state.regEnsAnc[index].surveillances[indice].mntTotal - (this.state.regEnsAnc[index].surveillances[indice].totalPayer + parseInt(this.state.regEnsAnc[index].surveillances[indice].mntAPayer))
                var arraySurveil = this.state.regEnsAnc
                var elSurveil = arraySurveil[index].surveillances[indice]
                elSurveil: Object.assign(elSurveil, { reste: mntRest })
                this.setState({
                    regEnsAnc: arraySurveil
                });
            });
        }
    }

    // Année recente 
    mntPayerForNv = (event, index, indice) => {
        var mntPayFor = event.target.value
        if (this.state.regEnsNv[index].formation[indice].mntTotal >= parseInt(mntPayFor) + this.state.regEnsNv[index].formation[indice].totalPayer) {
            var arrayFor = this.state.regEnsNv
            var elFor = arrayFor[index].formation[indice]
            elFor: Object.assign(elFor, { mntAPayer: mntPayFor })
            this.setState({
                regEnsNv: arrayFor
            }, () => {
                var mntRest = this.state.regEnsNv[index].formation[indice].mntTotal - (this.state.regEnsNv[index].formation[indice].totalPayer + parseInt(this.state.regEnsNv[index].formation[indice].mntAPayer))
                var arrayFor = this.state.regEnsNv
                var elFor = arrayFor[index].formation[indice]
                elFor: Object.assign(elFor, { reste: mntRest })
                this.setState({
                    regEnsNv: arrayFor
                });
            });
        }
        else {
            toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
        }
    }

    mntPayerCycleNv = (event, index, indice) => {
        var mntPayCycle = event.target.value
        if (this.state.regEnsNv[index].cycles[indice].mntTotal >= parseInt(mntPayCycle) + this.state.regEnsNv[index].cycles[indice].totalPayer) {
            var arrayCycle = this.state.regEnsNv
            var elCycle = arrayCycle[index].cycles[indice]
            elCycle: Object.assign(elCycle, { mntAPayer: mntPayCycle })
            this.setState({
                regEnsNv: arrayCycle
            }, () => {
                var mntRest = this.state.regEnsNv[index].cycles[indice].mntTotal - (this.state.regEnsNv[index].cycles[indice].totalPayer + parseInt(this.state.regEnsNv[index].cycles[indice].mntAPayer))
                var arrayCycle = this.state.regEnsNv
                var elCycle = arrayCycle[index].cycles[indice]
                elCycle: Object.assign(elCycle, { reste: mntRest })
                this.setState({
                    regEnsNv: arrayCycle
                });
            });
        }
        else {
            toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
        }
    }

    mntPayerSurveilNv = (event, index, indice) => {
        var mntPaySurveil = event.target.value
        if (this.state.regEnsNv[index].surveillances[indice].mntTotal >= parseInt(mntPaySurveil) + this.state.regEnsNv[index].surveillances[indice].totalPayer) {
            var arraySurveil = this.state.regEnsNv
            var elSurveil = arraySurveil[index].surveillances[indice]
            elSurveil: Object.assign(elSurveil, { mntAPayer: mntPaySurveil })
            this.setState({
                regEnsNv: arraySurveil
            }, () => {
                var mntRest = this.state.regEnsNv[index].surveillances[indice].mntTotal - (this.state.regEnsNv[index].surveillances[indice].totalPayer + parseInt(this.state.regEnsNv[index].surveillances[indice].mntAPayer))
                var arraySurveil = this.state.regEnsNv
                var elSurveil = arraySurveil[index].surveillances[indice]
                elSurveil: Object.assign(elSurveil, { reste: mntRest })
                this.setState({
                    regEnsNv: arraySurveil
                });
            });
        }
        else {
            toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
        }
    }

    addPayTotForNv = (e, index, indice) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayFor = this.state.regEnsNv
            var mntPayFor = arrayFor[index].formation[indice].reste
            var elFor = arrayFor[index].formation[indice]
            elFor: Object.assign(elFor, { mntAPayer: mntPayFor })
            this.setState({
                regEnsNv: arrayFor
            }, () => {
                var mntRest = this.state.regEnsNv[index].formation[indice].mntTotal - (this.state.regEnsNv[index].formation[indice].totalPayer + parseInt(this.state.regEnsNv[index].formation[indice].mntAPayer))
                var arrayFor = this.state.regEnsNv
                var elFor = arrayFor[index].formation[indice]
                elFor: Object.assign(elFor, { reste: mntRest })
                this.setState({
                    regEnsNv: arrayFor
                });
            });
        }
        else {
            var arrayFor = this.state.regEnsNv
            var mntPayFor = 0
            var elFor = arrayFor[index].formation[indice]
            elFor: Object.assign(elFor, { mntAPayer: mntPayFor })
            this.setState({
                regEnsNv: arrayFor
            }, () => {
                var mntRest = this.state.regEnsNv[index].formation[indice].mntTotal - (this.state.regEnsNv[index].formation[indice].totalPayer + parseInt(this.state.regEnsNv[index].formation[indice].mntAPayer))
                var arrayFor = this.state.regEnsNv
                var elFor = arrayFor[index].formation[indice]
                elFor: Object.assign(elFor, { reste: mntRest })
                this.setState({
                    regEnsNv: arrayFor
                });
            });
        }
    }

    addPayTotCycleNv = (e, index, indice) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayCycle = this.state.regEnsNv
            var mntPayCycle = arrayCycle[index].cycles[indice].reste
            var elCycle = arrayCycle[index].cycles[indice]
            elCycle: Object.assign(elCycle, { mntAPayer: mntPayCycle })
            this.setState({
                regEnsNv: arrayCycle
            }, () => {
                var mntRest = this.state.regEnsNv[index].cycles[indice].mntTotal - (this.state.regEnsNv[index].cycles[indice].totalPayer + parseInt(this.state.regEnsNv[index].cycles[indice].mntAPayer))
                var arrayCycle = this.state.regEnsNv
                var elCycle = arrayCycle[index].cycles[indice]
                elCycle: Object.assign(elCycle, { reste: mntRest })
                this.setState({
                    regEnsNv: arrayCycle
                });
            });
        }
        else {
            var arrayCycle = this.state.regEnsNv
            var mntPayCycle = 0
            var elCycle = arrayCycle[index].cycles[indice]
            elCycle: Object.assign(elCycle, { mntAPayer: mntPayCycle })
            this.setState({
                regEnsNv: arrayCycle
            }, () => {
                var mntRest = this.state.regEnsNv[index].cycles[indice].mntTotal - (this.state.regEnsNv[index].cycles[indice].totalPayer + parseInt(this.state.regEnsNv[index].cycles[indice].mntAPayer))
                var arrayCycle = this.state.regEnsNv
                var elCycle = arrayCycle[index].cycles[indice]
                elCycle: Object.assign(elCycle, { reste: mntRest })
                this.setState({
                    regEnsNv: arrayCycle
                });
            });
        }
    }

    addPayTotSurveilNv = (e, index, indice) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arraySurveil = this.state.regEnsNv
            var mntPaySurveil = arraySurveil[index].surveillances[indice].reste
            var elSurveil = arraySurveil[index].surveillances[indice]
            elSurveil: Object.assign(elSurveil, { mntAPayer: mntPaySurveil })
            this.setState({
                regEnsNv: arraySurveil
            }, () => {
                var mntRest = this.state.regEnsNv[index].surveillances[indice].mntTotal - (this.state.regEnsNv[index].surveillances[indice].totalPayer + parseInt(this.state.regEnsNv[index].surveillances[indice].mntAPayer))
                var arraySurveil = this.state.regEnsNv
                var elSurveil = arraySurveil[index].surveillances[indice]
                elSurveil: Object.assign(elSurveil, { reste: mntRest })
                this.setState({
                    regEnsNv: arraySurveil
                });
            });
        }
        else {
            var arraySurveil = this.state.regEnsNv
            var mntPaySurveil = 0
            var elSurveil = arraySurveil[index].surveillances[indice]
            elSurveil: Object.assign(elSurveil, { mntAPayer: mntPaySurveil })
            this.setState({
                regEnsNv: arraySurveil
            }, () => {
                var mntRest = this.state.regEnsNv[index].surveillances[indice].mntTotal - (this.state.regEnsNv[index].surveillances[indice].totalPayer + parseInt(this.state.regEnsNv[index].surveillances[indice].mntAPayer))
                var arraySurveil = this.state.regEnsNv
                var elSurveil = arraySurveil[index].surveillances[indice]
                elSurveil: Object.assign(elSurveil, { reste: mntRest })
                this.setState({
                    regEnsNv: arraySurveil
                });
            });
        }
    }

    //

    addDatePaiement = d => {
        this.setState({
            datePaiement: d
        });
    };

    additionLign = () => {
        let element = { idPieceReg: -1, modePay: '', mntModPay: '', numPiece: '', echeance: new Date() }
        this.setState({
            tabModPay: [...this.state.tabModPay, element]
        })
    }

    addModPay = (selectedModPay, indexModPay) => {
        if (selectedModPay != null) {
            let modePay = selectedModPay;
            this.setState({
                tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { modePay: modePay }) : el))
            });
        }
    }

    addMntModPay = (event, indexModPay) => {
        let mntModPay = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { mntModPay: mntModPay }) : el))
        });
    }

    addNumChec = (event, indexModPay) => {
        let numPiece = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { numPiece: numPiece }) : el))
        });
    }

    addEcheanceChec = (d, indexModPay) => {
        let dateChec = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { echeanceChec: dateChec }) : el))
        });
    }

    toggleLign = (idPieceReg, index) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            indexLignASupprimer: index,
            idPieceRegDelete: idPieceReg
        }));
    }

    deleteLign = () => {
        this.state.tabModPay.splice(this.state.indexLignASupprimer, 1)
        var idPieceRegDelete = this.state.idPieceRegDelete
        this.setState({
            tabIdPieceRegDelete: [...this.state.tabIdPieceRegDelete, idPieceRegDelete]
        });
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteLign = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    modif = () => {
        this.setState({ disbut: false }, () => {
            // verifier la somme payer par rapport a la somme de mode de paiement
            var sommeForAnc = 0
            var sommeCycleAnc = 0
            var sommeSurveilAnc = 0
            var sommeForNv = 0
            var sommeCycleNv = 0
            var sommeSurveilNv = 0
            var sommePaiement = 0
            // Ancienne année 
            if (this.state.regEnsAnc.length > 0) {
                for (var i = 0; i < this.state.regEnsAnc.length; i++) {
                    // Formation 
                    if (this.state.regEnsAnc[i].formation.length > 0) {
                        sommeForAnc += parseInt(this.state.regEnsAnc[i].formation[0].mntAPayer)
                    }
                    // Cycles et Surveillances
                    var tab = this.state.regEnsAnc[i]
                    for (var j = 0; j < tab.cycles.length; j++) {
                        if (tab.cycles.length > 0) {
                            sommeCycleAnc += parseInt(tab.cycles[j].mntAPayer)
                        }
                    }
                    for (var j = 0; j < tab.surveillances.length; j++) {
                        if (tab.surveillances.length > 0) {
                            sommeSurveilAnc += parseInt(tab.surveillances[j].mntAPayer)
                        }
                    }
                }
            }
            // Année recente 
            for (var i = 0; i < this.state.regEnsNv.length; i++) {
                // Formation 
                if (this.state.regEnsNv[i].formation.length > 0) {
                    sommeForNv += parseInt(this.state.regEnsNv[i].formation[0].mntAPayer)
                }
                // Cycles et Surveillances
                var tab = this.state.regEnsNv[i]
                for (var j = 0; j < tab.cycles.length; j++) {
                    if (tab.cycles.length > 0) {
                        sommeCycleNv += parseInt(tab.cycles[j].mntAPayer)
                    }
                }
                for (var j = 0; j < tab.surveillances.length; j++) {
                    if (tab.surveillances.length > 0) {
                        sommeSurveilNv += parseInt(tab.surveillances[j].mntAPayer)
                    }
                }
            }
            // somme total (ancienne et recente)
            sommePaiement = sommeForNv + sommeCycleNv + sommeSurveilNv + sommeForAnc + sommeCycleAnc + sommeSurveilAnc
            var sommeReg = 0
            for (var i = 0; i < this.state.tabModPay.length; i++) {
                sommeReg += parseInt(this.state.tabModPay[i].mntModPay)
            }
            if (sommePaiement !== sommeReg) {
                toast.error('⛔ Veuillez vérifier les sommes saisie !', { containerId: 'A' });
            }
            else {
                var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                var anneeScolaire = userPermissions[0].anneeScolaire
                var userId = userPermissions[0].idUser
                // convert date paiement
                let datePaiement = this.state.datePaiement
                let month = '' + (datePaiement.getMonth() + 1);
                let day = '' + datePaiement.getDate();
                let year = datePaiement.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var convertDatePaiement = [year, month, day].join('-');
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdReglementEnseignant.php',
                    type: 'POST',
                    data: {
                        idReg: this.state.idReg,
                        dateReg: convertDatePaiement,
                        sommeTot: sommePaiement,
                        regEnsAnc: this.state.regEnsAnc,
                        regEnsNv: this.state.regEnsNv,
                        modPay: this.state.tabModPay,
                        // echeanceChec: convertDateEcheChec,
                        arrayPieceDelete: this.state.tabIdPieceRegDelete,
                        userId: userId,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        if (code_html.Result == "OK") {
                            this.props.history.push('/ReglementEnseignant');
                        }
                        else if (code_html.Result == "KO") {
                            alert("erreur,l'un de vos champs est vide!!");
                        }
                    }
                });
            }
        })
    }

    toggleDelete = () => {
        this.setState(prevState => ({
            modalSup: !prevState.modalSup
        }));
    }

    deleteReg = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/DeleteRegEns.php',
            type: 'POST',
            data: {
                idReg: this.state.idReg,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/ReglementEnseignant');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    toggleDeleteFermer = () => {
        this.setState(prevState => ({
            modalSup: !prevState.modalSup
        }));
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification Reglement enseignant</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/ReglementEnseignant"> <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date paiement</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.datePaiement}
                                                onChange={this.addDatePaiement}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {this.state.regEnsAnc.length > 0 ?
                                        (<Fragment>
                                            <h3 style={{ paddingLeft: '10px' }}>Reste a payer année scolaire précedente</h3>
                                            <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: 'center', color: '#FF00FF', fontWeight: 'bold' }} scope="col">Reste mois<i style={{ paddingLeft: '5px' }} className="fas fa-calendar-day"></i></th>
                                                    </tr>
                                                </thead>
                                                {this.state.regEnsAnc.map((el, index) =>
                                                    <tbody>
                                                        <td style={{ paddingLeft: '10px', color: 'salmon', fontWeight: 'bold' }}>{el.MMAA}
                                                            <table style={{ width: '100%' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Nom</th>
                                                                        <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Horaires</th>
                                                                        <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Total</th>
                                                                        <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Total Payer</th>
                                                                        <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Reste</th>
                                                                        <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Mnt a payer</th>
                                                                        <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Cocher</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.formation.map((ele, indice) =>
                                                                                <p style={{ textAlign: 'center', color: '#565656' }}>{ele.nom}</p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>{el.formation.map((ele, indice) =>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                                {ele.nbrHeureGeneral > 0 ?
                                                                                    (<p style={{ color: '#565656' }}>{"EG: " + ele.nbrHeureGeneral}</p>) : (null)}
                                                                                {ele.nbrHeurePratique > 0 ?
                                                                                    (<p style={{ color: '#565656' }}>{"EP: " + ele.nbrHeurePratique}</p>) : (null)}
                                                                                {ele.nbrHeureTheorique > 0 ?
                                                                                    (<p style={{ color: '#565656' }}>{"ET: " + ele.nbrHeureTheorique}</p>) : (null)}
                                                                                    {ele.nbrHeureCycles > 0 ?
                                                                                    (<p style={{ color: '#565656' }}>{"CY: " + ele.nbrHeureCycles}</p>) : (null)}
                                                                            </div>
                                                                        )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.formation.map((ele, indice) =>
                                                                                <p style={{ color: '#565656' }}>{Number.parseFloat(ele.mntTotal).toFixed(3)}</p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.formation.map((ele, indice) =>
                                                                                <p style={{ color: '#565656' }}>{Number.parseFloat(ele.totalPayer).toFixed(3)}</p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.formation.map((ele, indice) =>
                                                                                 <p style={{ color: '#565656' }}>
                                                                                 {ele.reste > 0 ?(
                                                                                 Number.parseFloat(ele.reste).toFixed(3)):(0)}
                                                                                 </p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center',width:'150px' }}>
                                                                            {el.formation.map((ele, indice) =>
                                                                                <input type="number" onChange={(e) => this.mntPayerForAnc(e, index, indice)} value={ele.mntAPayer} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {el.formation.map((ele, indice) =>
                                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                    <Checkbox
                                                                                        onChange={(e) => this.addPayTotForAnc(e, index, indice)}
                                                                                       defaultChecked={true}
                                                                                        disabled={this.state.disabledForAnc}
                                                                                    />
                                                                                </div>)}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.cycles.map((ele, indice) =>
                                                                                <p style={{ textAlign: 'center', color: '#565656' }}>{ele.nom}</p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}><p style={{ color: '#565656' }}>{''}</p></td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.cycles.map((ele, indice) =>
                                                                                <p style={{ color: '#565656' }}>{Number.parseFloat(ele.mntTotal).toFixed(3)}</p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.cycles.map((ele, indice) =>
                                                                                <p style={{ color: '#565656' }}>{Number.parseFloat(ele.totalPayer).toFixed(3)}</p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.cycles.map((ele, indice) =>
                                                                                 <p style={{ color: '#565656' }}>
                                                                                 {ele.reste > 0 ?(
                                                                                 Number.parseFloat(ele.reste).toFixed(3)):(0)}
                                                                                 </p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' ,width:'150px'}}>
                                                                            {el.cycles.map((ele, indice) =>
                                                                                <input type="number" onChange={(e) => this.mntPayerCycleAnc(e, index, indice)} value={ele.mntAPayer} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {el.cycles.map((ele, indice) =>
                                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                    <Checkbox
                                                                                        onChange={(e) => this.addPayTotCycleAnc(e, index, indice)}
                                                                                        defaultChecked={true}
                                                                                        disabled={this.state.disabledCycleAnc}
                                                                                    />
                                                                                </div>)}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.surveillances.map((ele, indice) =>
                                                                                <p style={{ textAlign: 'center', color: '#565656' }}>{ele.nom}</p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}><p style={{ color: '#565656' }}>{''}</p></td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.surveillances.map((ele, indice) =>
                                                                                <p style={{ color: '#565656' }}>{Number.parseFloat(ele.mntTotal).toFixed(3)}</p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.surveillances.map((ele, indice) =>
                                                                                <p style={{ color: '#565656' }}>{Number.parseFloat(ele.totalPayer).toFixed(3)}</p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            {el.surveillances.map((ele, indice) =>
                                                                                 <p style={{ color: '#565656' }}>
                                                                                 {ele.reste > 0 ?(
                                                                                 Number.parseFloat(ele.reste).toFixed(3)):(0)}
                                                                                 </p>
                                                                            )}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' ,width:'150px'}}>
                                                                            {el.surveillances.map((ele, indice) =>
                                                                                <input type="number" onChange={(e) => this.mntPayerSurveilAnc(e, index, indice)} value={ele.mntAPayer} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {el.surveillances.map((ele, indice) =>
                                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                    <Checkbox
                                                                                        onChange={(e) => this.addPayTotSurveilAnc(e, index, indice)}
                                                                                        defaultChecked={true}
                                                                                        disabled={this.state.disabledSurveilAnc}
                                                                                    />
                                                                                </div>)}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tbody>
                                                )}
                                            </table> </Fragment>) : (null)}
                                    {this.state.regEnsNv.length > 0 ?
                                        (
                                            <Fragment>
                                                <h3 style={{ paddingLeft: '10px' }}>Année scolaire récente</h3>
                                                <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center', color: '#FF00FF', fontWeight: 'bold' }} scope="col">Mois<i style={{ paddingLeft: '5px' }} className="fas fa-calendar-alt"></i></th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.regEnsNv.map((el, index) =>
                                                        <tbody>
                                                            <td style={{ paddingLeft: '10px', color: 'salmon', fontWeight: 'bold' }}>{el.MMAA}
                                                                {el.formation.length > 0 || el.cycles.length > 0 || el.surveillances.length > 0 ?
                                                                    (<table style={{ width: '100%' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Nom</th>
                                                                                <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Horaires</th>
                                                                                <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Total</th>
                                                                                <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Total Payer</th>
                                                                                <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Reste</th>
                                                                                <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Mnt a payer</th>
                                                                                <th style={{ textAlign: 'center', color: '#00CED1', fontWeight: 'bold' }} scope="col">Cocher</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.formation.map((ele, indice) =>
                                                                                        <p style={{ textAlign: 'center', color: '#565656' }}>{ele.nom}</p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>{el.formation.map((ele, indice) =>
                                                                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                                                        {ele.nbrHeureGeneral > 0 ?
                                                                                            (<p style={{ color: '#565656' }}>{"EG: " + ele.nbrHeureGeneral}</p>) : (null)}
                                                                                        {ele.nbrHeurePratique > 0 ?
                                                                                            (<p style={{ color: '#565656' }}>{"EP: " + ele.nbrHeurePratique}</p>) : (null)}
                                                                                        {ele.nbrHeureTheorique > 0 ?
                                                                                            (<p style={{ color: '#565656' }}>{"ET: " + ele.nbrHeureTheorique}</p>) : (null)}
                                                                                            {ele.nbrHeureCycles > 0 ?
                                                                                            (<p style={{ color: '#565656' }}>{"CY: " + ele.nbrHeureCycles}</p>) : (null)}
                                                                                    </div>
                                                                                )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.formation.map((ele, indice) =>
                                                                                 ele.mntTotal!=null ?(
                                                                                        <p style={{ color: '#565656' }}>
                                                                                            {Number.parseFloat(ele.mntTotal).toFixed(3)}</p>):(<p>0</p>)
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.formation.map((ele, indice) =>
                                                                                        <p style={{ color: '#565656' }}>{Number.parseFloat(ele.totalPayer).toFixed(3)}</p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.formation.map((ele, indice) =>
                                                                                         <p style={{ color: '#565656' }}>
                                                                                         {ele.reste > 0 ?(
                                                                                         Number.parseFloat(ele.reste).toFixed(3)):(0)}
                                                                                         </p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center',width:'150px' }}>
                                                                                    {el.formation.map((ele, indice) =>
                                                                                        <input type="number" onChange={(e) => this.mntPayerForNv(e, index, indice)} value={ele.mntAPayer} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {el.formation.map((ele, indice) =>
                                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                            
                                                                                            <Checkbox
                                                                                                onChange={(e) => this.addPayTotForNv(e, index, indice)}
                                                                                               defaultChecked={true}
                                                                                                disabled={this.state.disabledForNv}
                                                                                            />
                                                                                        </div>)}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.cycles.map((ele, indice) =>
                                                                                        <p style={{ textAlign: 'center', color: '#565656' }}>{ele.nom}</p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}><p style={{ color: '#565656' }}>{''}</p></td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.cycles.map((ele, indice) =>
                                                                                        <p style={{ color: '#565656' }}>{Number.parseFloat(ele.mntTotal).toFixed(3)}</p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.cycles.map((ele, indice) =>
                                                                                        <p style={{ color: '#565656' }}>{Number.parseFloat(ele.totalPayer).toFixed(3)}</p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.cycles.map((ele, indice) =>
                                                                                         <p style={{ color: '#565656' }}>
                                                                                         {ele.reste > 0 ?(
                                                                                         Number.parseFloat(ele.reste).toFixed(3)):(0)}
                                                                                         </p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' ,width:'150px'}}>
                                                                                    {el.cycles.map((ele, indice) =>
                                                                                        <input type="number" onChange={(e) => this.mntPayerCycleNv(e, index, indice)} value={ele.mntAPayer} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {el.cycles.map((ele, indice) =>
                                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                            <Checkbox
                                                                                                onChange={(e) => this.addPayTotCycleNv(e, index, indice)}
                                                                                                defaultChecked={true}
                                                                                                disabled={this.state.disabledCycleNv}
                                                                                            />
                                                                                        </div>)}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.surveillances.map((ele, indice) =>
                                                                                        <p style={{ textAlign: 'center', color: '#565656' }}>{ele.nom}</p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}><p style={{ color: '#565656' }}>{''}</p></td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.surveillances.map((ele, indice) =>
                                                                                        <p style={{ color: '#565656' }}>{Number.parseFloat(ele.mntTotal).toFixed(3)}</p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.surveillances.map((ele, indice) =>
                                                                                        <p style={{ color: '#565656' }}>{Number.parseFloat(ele.totalPayer).toFixed(3)}</p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    {el.surveillances.map((ele, indice) =>
                                                                                        <p style={{ color: '#565656' }}>
                                                                                            {ele.reste > 0 ?(
                                                                                            Number.parseFloat(ele.reste).toFixed(3)):(0)}
                                                                                            </p>
                                                                                    )}
                                                                                </td>
                                                                                <td style={{ textAlign: 'center',width:'150px' }}>
                                                                                    {el.surveillances.map((ele, indice) =>
                                                                                        <input type="number" onChange={(e) => this.mntPayerSurveilNv(e, index, indice)} value={ele.mntAPayer} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {el.surveillances.map((ele, indice) =>
                                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                            
                                                                                            <Checkbox
                                                                                                onChange={(e) => this.addPayTotSurveilNv(e, index, indice)}
                                                                                                defaultChecked={true}
                                                                                                disabled={this.state.disabledSurveilNv}
                                                                                            />
                                                                                        </div>)}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>) : (null)}
                                                            </td>
                                                        </tbody>
                                                    )}
                                                </table> </Fragment>) : (null)}
                                </div>

                                <h3 style={{ paddingLeft: '15px' }}>Mode de paiement :</h3>
                                <table style={{ marginTop: '10px' }} className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }} scope="col">Mode paiement</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Montant Paiement</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Numéro cheque</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Echéance</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                        </tr>
                                    </thead>
                                    {this.state.tabModPay.map((el, index) =>
                                        <tbody>
                                            <tr>
                                                <td className="col-md-3"><Select
                                                    value={el.modePay}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => this.addModPay(e, index)}
                                                    options={this.state.listModPay}
                                                />  </td>
                                                <td className="col-md-3" style={{ textAlign: 'center' }}><input type="number" value={el.mntModPay} onChange={(e) => this.addMntModPay(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                {this.state.tabModPay[index].modePay.value == 2 ?
                                                    (<Fragment>
                                                        <td className="col-md-3" style={{ textAlign: 'center' }}><input type="number" value={el.numPiece} onChange={(e) => this.addNumChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} selected={new Date(el.echeanceChec)} onChange={(e) => this.addEcheanceChec(e, index)} /></td>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLign(el.idPieceReg, index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                    </Fragment>) :
                                                    (<Fragment>
                                                        <td className="col-md-3" style={{ textAlign: 'center' }}><input type="number" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLign(el.idPieceReg, index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                    </Fragment>)}
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                    <Button style={{ borderRadius: '5px' }} onClick={this.additionLign} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                </div>
                                <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                    <div><button disabled={!this.state.disbut} onClick={this.modif} style={{ backgroundColor: '#337AB7', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white', paddingRight: '5px' }} className="fas fa-pencil-alt"></i>Modification</button></div>
                                    <div><button onClick={this.toggleDelete} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white', paddingRight: '5px' }} className="fas fa-trash-alt"></i>Suppression</button></div>
                                </div>
                                <Modal isOpen={this.state.modal} toggle={this.nonDeleteLign} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteLign}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer ce mode de paiement ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteLign()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteLign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={this.state.modalSup} toggle={this.toggleDeleteFermer} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.toggleDeleteFermer}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer ce reglement ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteReg()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.toggleDeleteFermer()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(ModifReglementEnseignant);