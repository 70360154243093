import React, { Component, Fragment } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class MiseAJourReglFor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fraisReglEleve: [],
            reglEleve: [],
            tabModPay: [],
            disabledFrais: false,
            mntFraisTotalPayer: 0,
            mntIncripTotalPayer: 0,
            indexLignASupprimer: '',
            modalModePay: false,
            idPieceRegSup: '',
            arrayIdPieceRegDelete: [],
            modalSupReg: false,
            idRegFor: '',
            nomApp: '',
            groupeApp: '',
            niveauApp: '',
            formationApp: '',
            specialiteApp: '',
            modPayApp: '',
            elvId: '',
            datePaiement: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            var idRoleUser = userPermissions[0].idRoleUser
            if (idRoleUser == 1) {
                var idRegFor = this.props.location.state.idRegFor;
                this.setState({
                    idRegFor: idRegFor
                });
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetReglById.php',
                    type: 'POST',
                    data: {
                        idRegFor: idRegFor,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            nomApp: code_html[0][0].nomComplet,
                            groupeApp: code_html[0][0].groupe,
                            niveauApp: code_html[0][0].niveau,
                            formationApp: code_html[0][0].formation,
                            specialiteApp: code_html[0][0].nomSpecialite,
                            modPayApp: code_html[0][0].mode,
                            elvId: code_html[0][0].idElv,
                            datePaiement: new Date(code_html[0][0].dateReg),
                            fraisReglEleve: code_html[1],
                            reglEleve: code_html[2],
                            tabModPay: code_html[3]
                        }, () => {
                            console.log(this.state.infoFormatEleve)
                            for (var i = 0; i < this.state.tabModPay.length; i++) {
                                var echChec = new Date(this.state.tabModPay[i].echeanceChec)
                                this.setState({
                                    tabModPay: this.state.tabModPay.map((el, id) => (id === i ? Object.assign(el, { echeanceChec: echChec }) : el))
                                });
                            }
                            // Total frais
                            var sommeTotFraisPayer = 0
                            for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                                sommeTotFraisPayer += parseFloat(this.state.fraisReglEleve[i].mntFraisPayer)
                            }
                            // Total tranche
                            var sommeTotTrchPayer = 0
                            for (var i = 0; i < this.state.reglEleve.length; i++) {
                                sommeTotTrchPayer += parseFloat(this.state.reglEleve[i].mntPayer)
                            }
                            // Mise a jour
                            this.setState({
                                mntFraisTotalPayer: Number.parseFloat(sommeTotFraisPayer).toFixed(3),
                                mntIncripTotalPayer: Number.parseFloat(sommeTotTrchPayer).toFixed(3)
                            });
                        });
                    }
                });
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectModePaiement.php').then(res => {
                    console.log(res)
                    this.setState({
                        listModPay: res.data
                    })
                })
            }
            else {
                localStorage.clear();
                this.props.history.push('/');
            }
        }
        else {
            document.location = '/'
        }
    }

    modifDatePaiement = d => {
        this.setState({
            datePaiement: d
        });
    };

    modifMntFraisPayer = (event, indexMntFraisPayer) => {
        var mntFraisPay = event.target.value
        var mntFrais = parseInt(this.state.fraisReglEleve[indexMntFraisPayer].mntFrais)
        if (mntFrais >= mntFraisPay) {
            this.setState({
                fraisReglEleve: this.state.fraisReglEleve.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntFraisPayer: Number.parseFloat(mntFraisPay).toFixed(3) }) : el))
            }, () => {
                var mntRestNv = mntFrais - mntFraisPay
                this.setState({
                    fraisReglEleve: this.state.fraisReglEleve.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntFraisRestant: Number.parseFloat(mntRestNv).toFixed(3) }) : el))
                });
                var sommeMntFraisPayer = 0;
                for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                    if (this.state.fraisReglEleve[i].mntFraisPayer != "") {
                        sommeMntFraisPayer += parseInt(this.state.fraisReglEleve[i].mntFraisPayer)
                    }
                    else {
                        sommeMntFraisPayer += 0
                    }
                }
                this.setState({
                    mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                });
            })
        }
        else {
            toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
        }
    }

    modifPayTotFrais = (e, index) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayFrais = this.state.fraisReglEleve
            var mntFraisPayer = arrayFrais[index].mntFraisRestant
            var elFrais = arrayFrais[index]
            elFrais: Object.assign(elFrais, { mntFraisPayer: mntFraisPayer })
            this.setState({
                fraisReglEleve: arrayFrais
            }, () => {
                var mntFraisRestant = this.state.fraisReglEleve[index].mntFrais - this.state.fraisReglEleve[index].mntFraisPayer
                var arrayFrais = this.state.fraisReglEleve
                var elFrais = arrayFrais[index]
                elFrais: Object.assign(elFrais, { mntFraisRestant: Number.parseFloat(mntFraisRestant).toFixed(3) })
                this.setState({
                    fraisReglEleve: arrayFrais
                }, () => {
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                        if (this.state.fraisReglEleve[i].mntFraisPayer != "") {
                            sommeMntFraisPayer += parseInt(this.state.fraisReglEleve[i].mntFraisPayer)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                });
            });
        }
        else {
            var arrayFrais = this.state.fraisReglEleve
            var mntFraisPayer = 0
            var elFrais = arrayFrais[index]
            elFrais: Object.assign(elFrais, { mntFraisPayer: mntFraisPayer })
            this.setState({
                fraisReglEleve: arrayFrais
            }, () => {
                var mntFraisRestant = this.state.fraisReglEleve[index].mntFrais - this.state.fraisReglEleve[index].mntFraisPayer
                var arrayFrais = this.state.fraisReglEleve
                var elFrais = arrayFrais[index]
                elFrais: Object.assign(elFrais, { mntFraisRestant: Number.parseFloat(mntFraisRestant).toFixed(3) })
                this.setState({
                    fraisReglEleve: arrayFrais
                }, () => {
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                        if (this.state.fraisReglEleve[i].mntFraisPayer != "") {
                            sommeMntFraisPayer += parseInt(this.state.fraisReglEleve[i].mntFraisPayer)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                });
            });
        }
    }

    modifMntInscripPayer = (event, indexMntInscripPayer) => {
        let mntPaiement = event.target.value
        let mntAPayer = this.state.reglEleve[indexMntInscripPayer].mntAPayer
        if (parseInt(mntAPayer) >= parseInt(mntPaiement)) {
            this.setState({
                reglEleve: this.state.reglEleve.map((el, id) => (id === indexMntInscripPayer ? Object.assign(el, { mntPayer: mntPaiement }) : el))
            }, () => {
                let mntInscripRestNv = mntAPayer - mntPaiement
                this.setState({
                    reglEleve: this.state.reglEleve.map((el, id) => (id === indexMntInscripPayer ? Object.assign(el, { mntRestant: Number.parseFloat(mntInscripRestNv).toFixed(3) }) : el))
                });
                let mntIncripTotalPayer = 0
                for (var i = 0; i < this.state.reglEleve.length; i++) {
                    mntIncripTotalPayer += parseInt(this.state.reglEleve[i].mntPayer)
                }
                this.setState({
                    mntIncripTotalPayer: Number.parseFloat(mntIncripTotalPayer).toFixed(3)
                });
            })
        }
        else {
            toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
        }
    }

    modifPayTotReg = (e, index) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayReg = this.state.reglEleve
            var mnt = arrayReg[index].mntRestant
            var elReg = arrayReg[index]
            elReg: Object.assign(elReg, { mntPayer: mnt })
            this.setState({
                reglEleve: arrayReg
            }, () => {
                var mntRestant = parseInt(this.state.reglEleve[index].mntAPayer) - parseInt(this.state.reglEleve[index].mntPayer)
                var arrayReg = this.state.reglEleve
                var elReg = arrayReg[index]
                elReg: Object.assign(elReg, { mntRestant: Number.parseFloat(mntRestant).toFixed(3) })
                this.setState({
                    reglEleve: arrayReg
                }, () => {
                    var mntIncripTotalPayer = 0
                    for (var i = 0; i < this.state.reglEleve.length; i++) {
                        mntIncripTotalPayer += parseInt(this.state.reglEleve[i].mntPayer)
                    }
                    this.setState({
                        mntIncripTotalPayer: Number.parseFloat(mntIncripTotalPayer).toFixed(3)
                    });
                });
            });
        }
        else {
            var arrayReg = this.state.reglEleve
            var mnt = 0
            var elReg = arrayReg[index]
            elReg: Object.assign(elReg, { mntPayer: mnt })
            this.setState({
                reglEleve: arrayReg
            }, () => {
                var mntRestant = parseInt(this.state.reglEleve[index].mntAPayer) - parseInt(this.state.reglEleve[index].mntPayer)
                var arrayReg = this.state.reglEleve
                var elReg = arrayReg[index]
                elReg: Object.assign(elReg, { mntRestant: Number.parseFloat(mntRestant).toFixed(3) })
                this.setState({
                    reglEleve: arrayReg
                }, () => {
                    var mntIncripTotalPayer = 0
                    for (var i = 0; i < this.state.reglEleve.length; i++) {
                        mntIncripTotalPayer += parseInt(this.state.reglEleve[i].mntPayer)
                    }
                    this.setState({
                        mntIncripTotalPayer: Number.parseFloat(mntIncripTotalPayer).toFixed(3)
                    });
                });
            });
        }
    }

    additionLign = () => {
        let element = { modPay: '', mntModPay: '', numCheque: '', echeanceChec: new Date(), dateEncaiss: new Date(), titulaireChec: '', banque: '', idPieceReg: -1, ancModePay: -1 }
        this.setState({
            tabModPay: [...this.state.tabModPay, element]
        })
    }

    modifModPay = (selectedModPay, indexModPay) => {
        if (selectedModPay != null) {
            var modPay = selectedModPay;
            this.setState({
                tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { modPay: modPay }) : el))
            }, () => {
                if (modPay.value == 1) {
                    this.setState({
                        tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { numCheque: '' }) : el)),
                        tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { titulaireChec: '' }) : el)),
                        tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { banque: '' }) : el))
                    });
                }
            });
        }
    }

    modifMntModPay = (event, indexModPay) => {
        let mntModPay = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { mntModPay: mntModPay }) : el))
        });
    }

    modifNumChec = (event, indexModPay) => {
        let numCheque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { numCheque: numCheque }) : el))
        });
    }

    modifEcheanceChec = (d, indexModPay) => {
        let dateChec = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { echeanceChec: dateChec }) : el))
        });
    }

    modifDateEncaiss = (d, indexModPay) => {
        let dateEncaiss = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { dateEncaiss: dateEncaiss }) : el))
        });
    }

    modifTitulaireChec = (event, indexModPay) => {
        let titulaireChec = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { titulaireChec: titulaireChec }) : el))
        });
    }

    modifBanque = (event, indexModPay) => {
        let banque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { banque: banque }) : el))
        });
    }

    toggleLign = (index, idPieceRegSup) => {
        this.setState(prevState => ({
            modalModePay: !prevState.modalModePay,
            indexLignASupprimer: index,
            idPieceRegSup: idPieceRegSup
        }));
    }

    deleteLign = () => {
        this.state.tabModPay.splice(this.state.indexLignASupprimer, 1)
        this.setState(prevState => ({
            modalModePay: !prevState.modalModePay
        }));
        this.setState({
            arrayIdPieceRegDelete: [...this.state.arrayIdPieceRegDelete, this.state.idPieceRegSup]
        }, () => {
            console.log(this.state.arrayIdPieceRegDelete)
        });
    }

    nonDeleteLign = () => {
        this.setState(prevState => ({
            modalModePay: !prevState.modalModePay
        }));
    }

    toggleDeleteReg = () => {

        this.setState(prevState => ({
            modalSupReg: !prevState.modalSupReg
        }));
    }

    deleteRegFor() {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + "Back_mediatraining/DeleteRegFor.php",
            type: 'POST',
            dataType: 'json',
            data: {
                idRegFor: this.state.idRegFor,
                userId: userId
            },
            success: (code_html, statut) => {
                this.props.history.push('/ReglApprenant');
            }
        });
    }

    toggleNonDeleteReg = () => {
        this.setState(prevState => ({
            modalSupReg: !prevState.modalSupReg
        }));
    }

    modifReg = () => {
        var sommePaiement = 0
        sommePaiement = parseInt(this.state.mntFraisTotalPayer) + parseInt(this.state.mntIncripTotalPayer)
        var sommeReg = 0
        for (var i = 0; i < this.state.tabModPay.length; i++) {
            sommeReg += parseInt(this.state.tabModPay[i].mntModPay)
        }
        if (sommePaiement !== sommeReg) {
            toast.error('⛔ Veuillez vérifier les sommes saisie !', { containerId: 'A' });
        }
        else {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            var userId = userPermissions[0].idUser
            // convert date paiement
            let datePaiement = this.state.datePaiement
            let month = '' + (datePaiement.getMonth() + 1);
            let day = '' + datePaiement.getDate();
            let year = datePaiement.getFullYear();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            var convertDatePaiement = [year, month, day].join('-');
            //
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdateReglFor.php',
                type: 'POST',
                data: {
                    eleveId: this.state.elvId,
                    idRegFor: this.state.idRegFor,
                    regFrais: this.state.fraisReglEleve,
                    regInscrip: this.state.reglEleve,
                    modPay: this.state.tabModPay,
                    anneeScolaire: anneeScolaire,
                    userId: userId,
                    dateReg: convertDatePaiement,
                    montantTotal: sommeReg,
                    idPieceRegSup: this.state.arrayIdPieceRegDelete
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    if (code_html.Result == "OK") {
                        this.props.history.push('/ReglApprenant');
                    }
                    else {
                        alert("prbl lors de l'insertion")
                    }
                }
            });
        }
    }

    render() {
        if (this.state.reglEleve.length > 0) {
            var x = this.state.reglEleve[0].typePay
            if (x == "Annuel") {
                var paiementInscrip = (
                    <Fragment>
                        <h3 style={{ paddingLeft: '15px' }}>Paiement reglement :</h3>
                        <table style={{ marginTop: '10px' }} className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant payé</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                </tr>
                            </thead>
                            {this.state.reglEleve.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td className="col-md-4" style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.typePay}</p></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntAPayer).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.mntPayer} onChange={(e) => this.modifMntInscripPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntRestant).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2">
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Checkbox
                                                    onChange={(e) => this.modifPayTotReg(e, index)}
                                                    disabled={this.state.disabledReg}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </Fragment>)
            }
            else if (x == "Annuel avec réduction") {
                var paiementInscrip = (
                    <Fragment>
                        <h3 style={{ paddingLeft: '15px' }}>Paiement reglement :</h3>
                        <table style={{ marginTop: '10px' }} className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant payé</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                </tr>
                            </thead>
                            {this.state.reglEleve.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td className="col-md-4" style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.typePay}</p></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntAPayer).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.mntPayer} onChange={(e) => this.modifMntInscripPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntRestant).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2">
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Checkbox
                                                    onChange={(e) => this.modifPayTotReg(e, index)}
                                                    disabled={this.state.disabledReg}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </Fragment>)
            }
            else if (x == "Mensuelle") {
                var paiementInscrip = (
                    <Fragment>
                        <h3 style={{ paddingLeft: '15px' }}>Paiement reglement :</h3>
                        <table style={{ marginTop: '10px' }} className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant payé</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                </tr>
                            </thead>
                            {this.state.reglEleve.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td className="col-md-4" style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.numeroTrch + " tranche"}</p></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntAPayer).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.mntPayer} onChange={(e) => this.modifMntInscripPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntRestant).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2">
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Checkbox
                                                    onChange={(e) => this.modifPayTotReg(e, index)}
                                                    disabled={this.state.disabledReg}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </Fragment>)
            }
            else if (x === "2 Tranche") {
                var paiementInscrip = (
                    <Fragment>
                        <h3 style={{ paddingLeft: '15px' }}>Paiement reglement :</h3>
                        <table style={{ marginTop: '10px' }} className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant payé</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                </tr>
                            </thead>
                            {this.state.reglEleve.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td className="col-md-4" style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.numeroTrch + " tranche"}</p></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntAPayer).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.mntPayer} onChange={(e) => this.modifMntInscripPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntRestant).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2">
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Checkbox
                                                    onChange={(e) => this.modifPayTotReg(e, index)}
                                                    disabled={this.state.disabledReg}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </Fragment>)
            }
            else {
                var paiementInscrip = (
                    <Fragment>
                        <h3 style={{ paddingLeft: '15px' }}>Paiement reglement :</h3>
                        <table style={{ marginTop: '10px' }} className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant payé</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                </tr>
                            </thead>
                            {this.state.reglEleve.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td className="col-md-4" style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.numeroTrch + " tranche"}</p></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntAPayer).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.mntPayer} onChange={(e) => this.modifMntInscripPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntRestant).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                        <td className="col-md-2">
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Checkbox
                                                    onChange={(e) => this.modifPayTotReg(e, index)}
                                                    disabled={this.state.disabledReg}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </Fragment>)
            }
        }
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification reglement frais</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/ReglApprenant" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Date paiement</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.datePaiement}
                                                onChange={this.modifDatePaiement}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Apprenant</label>
                                            <input type="text" disabled value={this.state.nomApp} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Groupe</label>
                                            <input type="text" disabled value={this.state.groupeApp} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Niveau</label>
                                            <input type="text" disabled value={this.state.niveauApp} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>formation</label>
                                            <input type="text" disabled value={this.state.formationApp} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Specialite</label>
                                            <input type="text" disabled value={this.state.specialiteApp} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Modalité de paiement</label>
                                            <input type="text" disabled value={this.state.modPayApp} className="form-control" style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                </div>
                                {this.state.fraisReglEleve.length > 0 ?
                                    (
                                        <Fragment>
                                            <h3>Paiement Frais Inscription :</h3>
                                            <div className="row">
                                                <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant payé</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.fraisReglEleve.map((el, index) =>
                                                        <tbody>
                                                            <tr>
                                                                <td className="col-md-4" style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.frais}</p></td>
                                                                <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" className='mntFrais' disabled value={el.mntFrais} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" className='mntFraisPayer' value={el.mntFraisPayer} onChange={(e) => this.modifMntFraisPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled className='mntFraisRestant' value={el.mntFraisRestant} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                <td className="col-md-2">
                                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <Checkbox
                                                                            onChange={(e) => this.modifPayTotFrais(e, index)}
                                                                            disabled={this.state.disabledFrais}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </table>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                            <td className="col-md-4" style={{ textAlign: 'center', fontWeight: 'bold' }}>Montant total frais payer</td>
                                                            <td className="col-md-2"></td>
                                                            <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.state.mntFraisTotalPayer}</td>
                                                            <td className="col-md-2"></td>
                                                            <td className="col-md-2"></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Fragment>
                                    ) : (null)}

                                {this.state.reglEleve.length > 0 ?
                                    (<Fragment>
                                        {paiementInscrip}
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                    <td className="col-md-4" style={{ textAlign: 'center', fontWeight: 'bold' }}>Montant total reglement payer</td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.state.mntIncripTotalPayer}</td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2"></td>
                                                </tr>
                                            </tbody>
                                        </table> </Fragment>) : (null)}

                                <h3 style={{ paddingLeft: '15px' }}>Mode de paiement :</h3>
                                <table style={{ marginTop: '10px' }} className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }} scope="col">Mode paiement</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Montant Paiement</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Numéro cheque</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Echéance cheque</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Date encaissement</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Titulaire cheque</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Banque</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                        </tr>
                                    </thead>
                                    {this.state.tabModPay.map((el, index) =>
                                        <tbody>
                                            <tr>
                                                <td className="col-md-3"><Select
                                                    value={el.modPay}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => this.modifModPay(e, index)}
                                                    options={this.state.listModPay}
                                                />  </td>
                                                <td className="col-md-1" style={{ textAlign: 'center' }}><input type="number" value={el.mntModPay} onChange={(e) => this.modifMntModPay(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                {this.state.tabModPay[index].modPay.value == 2 ?
                                                    (<Fragment>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="number" value={el.numCheque} onChange={(e) => this.modifNumChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} selected={new Date(el.echeanceChec)} onChange={(e) => this.modifEcheanceChec(e, index)} /></td>
                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} selected={new Date(el.dateEncaiss)} onChange={(e) => this.modifDateEncaiss(e, index)} /></td>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.titulaireChec} onChange={(e) => this.modifTitulaireChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.banque} onChange={(e) => this.modifBanque(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLign(index, el.idPieceReg)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                    </Fragment>) :
                                                    (<Fragment>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="number" disabled style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLign(index, el.idPieceReg)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                    </Fragment>)}
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                    <Button style={{ borderRadius: '5px' }} onClick={this.additionLign} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                </div>
                                <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                    <div><button onClick={this.modifReg} style={{ backgroundColor: '#337AB7', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white', paddingRight: '5px' }} className="fas fa-pencil-alt"></i>Modification</button></div>
                                    <div><button onClick={this.toggleDeleteReg} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white', paddingRight: '5px' }} className="fas fa-trash-alt"></i>Suppression</button></div>
                                </div>
                                <Modal isOpen={this.state.modalModePay} toggle={this.nonDeleteLign} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteLign}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer ce mode de paiement ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteLign()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteLign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={this.state.modalSupReg} toggle={this.toggleNonDeleteReg} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.toggleNonDeleteReg}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer ce reglement ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteRegFor()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.toggleNonDeleteReg()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MiseAJourReglFor);