import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import './paginate.css'

class ImprResultatPrBTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockNote: false,
      listEleves: [],
      listMatieres: [],
      listEG: [],
      listET: [],
      listEP: [],
      filiere: "",
      niveau: "",
      groupe: "",
      annee: "",
      anneeScolaire: "",
      count: "",
      lengthTot: 0,
      typeET: [],
      typeEG: [],
      typeEP: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var groupeId = arrayUrl[1];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprResultatPrBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          groupeId: groupeId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState(
            {
              listEG: code_html[0][0],
              listET: code_html[0][1],
              listEP: code_html[0][2],
              typeEG: code_html[0][3],
              typeET: code_html[0][4],
              typeEP: code_html[0][5],
              listEleves: code_html[1],
            },
            () => {
              var lengthEg = this.state.listEG.length * 3;
              var lengthEt = this.state.listET.length * 3;
              var lengthEp = this.state.listEP.length * 3;
              this.setState({
                spanEg: lengthEg,
                spanEt: lengthEt,
                spanEp: lengthEp,
              });
            }
          );
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoGroupe.php",
        type: "POST",
        data: {
          groupeId: groupeId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            filiere: code_html.nomFiliere,
            niveau: code_html.niveau,
            groupe: code_html.groupe,
            annee: code_html.annee,
            anneeScolaire: code_html.anneeScolaire,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        {/*  <div className="white-box"> */}
        <div className="pagebreak">
          <table
            border={0}
            align="center"
            cellSpacing={1}
            cellPadding={4}
            width={1100}
          >
            <tbody>
              <tr>
                <td width={358} height={54}>
                  &nbsp;
                </td>
                <td width={452} align height={54}>
                  <center>
                    <b>
                      <font
                        style={{ fontSize: "14px" }}
                        face="Verdana, Arial, Helvetica, sans-serif"
                      >
                        <h3>Relévé de notes</h3>
                      </font>
                    </b>
                  </center>
                  <b>
                    <font
                      style={{ fontSize: "14px", color: "black" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      L'année Scolaire&nbsp; {this.state.anneeScolaire}
                    </font>
                  </b>
                </td>
                <td width={262} align height={54}>
                  {" "}
                  <img src="images/art_metier.png" />
                </td>
              </tr>
              <tr>
                <td height={42}>
                  {" "}
                  <p>
                    <font
                      style={{ fontSize: "14px", color: "black" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      &nbsp;
                      <font
                        style={{ fontSize: "14px" }}
                        face="Verdana, Arial, Helvetica, sans-serif"
                      >
                        <b>
                          <u>Niveau: </u>
                        </b>
                        &nbsp;{this.state.niveau}
                      </font>
                    </font>
                  </p>
                  <p>
                    <font
                      style={{ fontSize: "14px", color: "black" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      <b>
                        <u>Groupe : </u>
                      </b>
                      &nbsp; {this.state.groupe}
                    </font>
                  </p>
                  <p>
                    <font
                      style={{ fontSize: "14px", color: "black" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      <b>
                        <u>Session : </u>
                      </b>
                      &nbsp;Juillet {this.state.annee}
                    </font>
                  </p>
                  <p>
                    <font
                      style={{ fontSize: "14px", color: "black" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      {" "}
                      <b>
                        <u>Spécialité:</u>
                      </b>
                      &nbsp;{this.state.filiere}
                    </font>
                  </p>
                </td>
                <td width={452} align="left">
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr style={{ backgroundColor: "#FFCCCC" }}>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  N°
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Apprenant
                </th>
                <th
                  colSpan={this.state.spanEg}
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Enseignement Général
                </th>
                <th
                  colSpan={this.state.spanEt}
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Enseignement Technique
                </th>
              </tr>
              <tr style={{ backgroundColor: "#FFCC99" }}>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                ></th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Module
                </th>
                {this.state.listEG.map((el) => (
                  <th
                    colSpan="3"
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {el}
                  </th>
                ))}
                {this.state.listET.map((el) => (
                  <th
                    colSpan="3"
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {el}
                  </th>
                ))}
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                ></th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Module
                </th>
                {this.state.typeEG.map((el, index) =>
                  el == "NFM" ? (
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                        fontSize: "10px",
                        backgroundColor: "rgb(204, 255, 204)",
                      }}
                    >
                      {el}
                    </th>
                  ) : (
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontSize: "10px",
                        color: "black",
                      }}
                    >
                      {el}
                    </th>
                  )
                )}
                {this.state.typeET.map((el) =>
                  el == "NFM" ? (
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                        fontSize: "10px",
                        backgroundColor: "rgb(204, 255, 204)",
                      }}
                    >
                      {el}
                    </th>
                  ) : (
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        fontSize: "10px",
                        color: "black",
                      }}
                    >
                      {el}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {this.state.listEleves.map((elem, index) => (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {elem.nomEleve}
                  </td>
                  {elem.EG.map((ele, index) =>
                    (index + 1) % 3 == 0 ? (
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                          backgroundColor: "rgb(204, 255, 204)",
                        }}
                      >
                        {ele.map((x) => (
                          <p>{x}</p>
                        ))}
                      </td>
                    ) : (
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {ele.map((x) => (
                          <p>{x}</p>
                        ))}
                      </td>
                    )
                  )}
                  {elem.ET.map((ele, index) =>
                    (index + 1) % 3 == 0 ? (
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                          backgroundColor: "rgb(204, 255, 204)",
                        }}
                      >
                        {ele.map((x) => (
                          <p>{x}</p>
                        ))}
                      </td>
                    ) : (
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {ele.map((x) => (
                          <p>{x}</p>
                        ))}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagebreak">
          <table style={{ marginTop: "5%" }}>
            <thead>
              <tr style={{ backgroundColor: "#FFCCCC" }}>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  N°
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Apprenant
                </th>
                <th
                  colSpan={this.state.spanEp}
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Enseignement Pratique (Réalisation)
                </th>
              </tr>
              <tr style={{ backgroundColor: "#FFCC99", fontSize: '11px' }}>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                ></th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Module
                </th>
                {this.state.listEP.map((el) => (
                  <th
                    colSpan="3"
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {el}
                  </th>
                ))}
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                ></th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Module
                </th>
                {this.state.typeEP.map((el) =>
                  el == "NFM" ? (
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                        fontSize: "10px",
                        backgroundColor: "rgb(204, 255, 204)",
                      }}
                    >
                      {el}
                    </th>
                  ) : (
                    <th
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                        fontSize: "10px",
                      }}
                    >
                      {el}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {this.state.listEleves.map((elem, index) => (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {elem.nomEleve}
                  </td>
                  {elem.EP.map((ele, index) =>
                    (index + 1) % 3 == 0 ? (
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                          backgroundColor: "rgb(204, 255, 204)",
                        }}
                      >
                        {ele.map((x) => (
                          <p>{x}</p>
                        ))}
                      </td>
                    ) : (
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {ele.map((x) => (
                          <p>{x}</p>
                        ))}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <i id="print_me_not"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={this.print}
                class="fas fa-print"
              ></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprResultatPrBTS);
