import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import Table from "reactstrap/lib/Table";
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'

class AjoutNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNiveau: [],
      listGroupes: [],
      listMatieres: [],
      listSousMat: [],
      listNatureNote: [],
      listTrimestre: [
        { value: 1, label: "Trimestre 1" },
        { value: 2, label: "Trimestre 2" },
        { value: 3, label: "Trimestre 3" },
      ],
      listEleves: [],
      selectedGroupe: "",
      selectedNiveau: "",
      selectedMatiere: "",
      selectedSousMat: "",
      selectedTrimestre: "",
      blockListEleve: false,
      blockSousMatiere: false,
      dateAjoutNote: new Date(),
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectNiveauNonBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listNiveau: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addNiveau = (selectedNiveau) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedNiveau != null) {
      this.setState({ selectedNiveau }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectedGroupe.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listGroupes: code_html,
            });
          },
        });
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectedMatieres.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listMatieres: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        listGroupes: [],
        listMatieres: []
      });
    }
  };

  addGroup = (selectedGroupe) => {
    if (selectedGroupe != null) {
      this.setState({ selectedGroupe }, () => { });
    } else {
      this.setState({
        selectedGroupe: "",
      });
    }
  };

  addMatiere = (selectedMatiere) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedMatiere != null) {
      this.setState({ selectedMatiere: selectedMatiere }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectSousMatByMat.php",
          type: "POST",
          data: {
            matiereId: selectedMatiere.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listSousMat: code_html,
            }, () => {
              if (this.state.listSousMat.length == 0) {
                if (this.state.selectedSousMat != "") {
                  var sousMat = "";
                } else {
                  var sousMat = -1;
                }
                jQuery.ajax({
                  url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/SelectedEleveByIdGroupe.php",
                  type: "POST",
                  data: {
                    niveauId: this.state.selectedNiveau.value,
                    sousMat: sousMat,
                    groupeId: this.state.selectedGroupe.value,
                    matiereId: selectedMatiere.value,
                    anneeScolaire: anneeScolaire,
                  },
                  dataType: "json",
                  success: (code_html, statut) => {
                    this.setState({
                      listEleves: code_html[1],
                      listNatureNote: code_html[0],
                    });
                  },
                });
              }
            });
          },
        });
      });
    } else {
      this.setState({
        listSousMat: [],
        listEleves: [],
        listNatureNote: []
      });
    }
  };

  addTrimestre = (selectedTrimestre) => {
    this.setState({ selectedTrimestre }, () => {
      this.setState({
        blockListEleve: true,
      });
    });
  };

  changeNote = (indexOfLigne, indexOfColumn, event) => {
    if (parseFloat(event) <= 20) {
      var arrayEleve = this.state.listEleves;
      arrayEleve[indexOfLigne].note[indexOfColumn].noteMatiere = event;
      this.setState({ listEleves: arrayEleve });
    } else if (event == "") {
      var arrayEleve = this.state.listEleves;
      arrayEleve[indexOfLigne].note[indexOfColumn].noteMatiere = "";
      this.setState({ listEleves: arrayEleve });
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      });
    }
  };

  addSousMat = (selectedSousMat) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedSousMat != null) {
      this.setState({ selectedSousMat: selectedSousMat }, () => {
        if (this.state.selectedSousMat != "") {
          var sousMat = this.state.selectedSousMat.value;
        } else {
          var sousMat = -1;
        }
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectedEleveByIdGroupe.php",
          type: "POST",
          data: {
            niveauId: this.state.selectedNiveau.value,
            sousMat: sousMat,
            groupeId: this.state.selectedGroupe.value,
            matiereId: this.state.selectedMatiere.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listEleves: code_html[1],
              listNatureNote: code_html[0],
            });
          },
        });
      });
    }
    else {
      this.setState({ selectedSousMat: '' });
    }
  };

  ajoutNoteMatiere = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.dateAjoutNote != "") {
      let dateAjoutNote = this.state.dateAjoutNote;
      let month_three = "" + (dateAjoutNote.getMonth() + 1);
      let day_three = "" + dateAjoutNote.getDate();
      let year_three = dateAjoutNote.getFullYear();
      if (month_three.length < 2) month_three = "0" + month_three;
      if (day_three.length < 2) day_three = "0" + day_three;
      var convertDateAjoutNote = [year_three, month_three, day_three].join("-");
    } else {
      var convertDateAjoutNote = "";
    }
    if (this.state.selectedSousMat != "") {
      var sousMat = this.state.selectedSousMat.value;
    } else {
      var sousMat = -1;
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/AjoutNoteMatiere.php",
      type: "POST",
      data: {
        niveauId: this.state.selectedNiveau.value,
        anneeScolaire: anneeScolaire,
        selectedTrimestre: this.state.selectedTrimestre.value,
        listEleves: this.state.listEleves,
        specialite: this.state.selectedNiveau.Specialite,
        dateAjoutNote: convertDateAjoutNote,
        groupeId: this.state.selectedGroupe.value,
        matiereId: this.state.selectedMatiere.value,
        sousMatiere: sousMat
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/ListNote");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Les Notes</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Niveau</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addNiveau}
                        options={this.state.listNiveau}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroup}
                        options={this.state.listGroupes}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Matière</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addMatiere}
                        options={this.state.listMatieres}
                      />
                    </div>
                  </div>
                  {this.state.listSousMat.length > 0 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Sous Matière</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addSousMat}
                          options={this.state.listSousMat}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Trimestre</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addTrimestre}
                        options={this.state.listTrimestre}
                      />
                    </div>
                  </div>
                </div>
                {this.state.blockListEleve == true && this.state.listEleves.length > 0 ? (
                  <div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center' }}>Eleve</th>
                          {this.state.listNatureNote.map((elem) => (
                            <th style={{ textAlign: 'center' }}>{elem.natNot}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listEleves.map((el, indexOfLigne) => (
                          <tr>
                            <td style={{ textAlign: 'left' }}> {el.nomEleve} </td>{" "}
                            {el.note.map((element, indexOfColumn) => (
                              <td style={{ textAlign: 'center' }}>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.changeNote(
                                      indexOfLigne,
                                      indexOfColumn,
                                      e.target.value
                                    )
                                  }
                                  value={element.noteMatiere}
                                  style={{ textAlign: "center" }}
                                ></input>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <button
                      style={{ borderRadius: "5px", marginLeft: '620px' }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ajoutNoteMatiere}
                    >
                      Confirmer
                    </button>
                  </div>
                ) : (null)}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
      </div>
    );
  }
}

export default withRouter(AjoutNote);
