import React, { Component, Fragment } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import jQuery from "jquery";
import "./paginate.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import Select from "react-select";

class Enseignants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Prénom-Nom",
          selector: "prenomNom",
          sortable: false,
          center: true,
          style: { width: "30%" },
        },
        {
          name: "Modifier",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ModifEnseignant",
                state: { idEnseignant: row.idEnseignant },
              }}
            >
              <button className="buttonModifier">
                <i style={{ color: "white" }} className="fas fa-pencil-alt"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
        {
          name: "Fiche",
          cell: (row) =>
            this.state.role == 1 ? (
              <button
                style={{
                  backgroundColor: "#87B87F",
                  borderColor: "#87B87F",
                  borderRadius: "5px",
                }}
                onClick={() => this.ficheEnseignant(row)}
              >
                <i
                  style={{ color: "white" }}
                  className="fas fa-address-card"
                ></i>
              </button>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "15%",
        },
        {
          name: "Groupes",
          cell: (row) => (
            <Link
              to={{
                pathname: "/EnseignantsGroupes",
                state: { idEnseignant: row.idEnseignant },
              }}
            >
              <button
                style={{ backgroundColor: "#8A2BE2", borderRadius: "5px" }}
              >
                <i style={{ color: "white" }} className="fas fa-users-cog"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
        {
          name: "Paiements",
          cell: (row) =>
            this.state.role == 1 ? (
              <Link
                to={{
                  pathname: "/PaiementsEnseignant",
                  state: { idEnseignant: row.idEnseignant },
                }}
              >
                <button
                  style={{ backgroundColor: "#EFAD0C", borderRadius: "5px" }}
                >
                  <i
                    style={{ color: "white" }}
                    className="fas fa-money-bill-wave"
                  ></i>
                </button>
              </Link>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
        {
          name: "",
          cell: (row) =>
            this.state.role == 1 ? (
              row.actif == 0 || row.actif == 1 ? (
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => this.toggleDes(row.idEnseignant)}
                >
                  {" "}
                  Désactiver cet Enseignant{" "}
                </p>
              ) : (
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => this.toggleAct(row.idEnseignant)}
                >
                  {" "}
                  Activer cet Enseignant{" "}
                </p>
              )
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
        {
          name: "",
          cell: (row) =>
            row.actif == 1 ? (
              <button
                style={{
                  backgroundCcolor: "#b3ccff",
                  borderRadius: "5px",
                  borderColor: "#b3ccff",
                }}
                className="buttonAbondant"
                onClick={() => this.toggleAbon(row.idEnseignant)}
              >
                <i
                  style={{ color: "white" }}
                  className="fas fa-plus-circle"
                ></i>
              </button>
            ) : (
              <button
                className="buttonSupprimer"
                onClick={() => this.toggletwo(row.idEnseignant)}
              >
                <i style={{ color: "white" }} className="fas fa-trash-alt"></i>
              </button>
            ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      modalDes: false,
      modalAct: false,
      modalAbon: false,
      idEnseignant: "",
      pending: true,
      role: "",
      // search
      nom: "",
      prenom: "",
      cin: "",
      etat: [
        { value: 0, label: "Actiif" },
        { value: 1, label: "Abondant" },
        { value: 2, label: "Non Actif" },
      ],
      selectEtat: "",
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var role = userPermissions[0].idRoleUser;
      this.setState(
        {
          role: role,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/Enseignants.php",
            type: "POST",
            data: {
              query: "",
              limit: this.state.nbrEltperPage,
              page: 1,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }

  newElement(page, totalRows) {
    this.setState(
      {
        pending: true,
      },
      function () {
        if (this.state.prenom != "") {
          var prenom = this.state.prenom;
        } else {
          var prenom = "";
        }
        if (this.state.nom != "") {
          var nom = this.state.nom;
        } else {
          var nom = "";
        }
        if (this.state.cin != "") {
          var cin = this.state.cin;
        } else {
          var cin = "";
        }
        if (this.state.selectEtat != "") {
          var etat = this.state.selectEtat.value;
        } else {
          var etat = 0;
        }
        console.log(page);
        console.log(totalRows);
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/EnseignantsSearch.php",
          type: "POST",
          data: {
            prenom: prenom,
            nom: nom,
            cin: cin,
            etat: etat,
            param: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: page,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }.bind(this)
    );
  }

  perPage(currentRowsPerPage, currentPage) {
    this.setState(
      {
        nbrEltperPage: currentRowsPerPage,
        pending: true,
      },
      function () {
        if (this.state.prenom != "") {
          var prenom = this.state.prenom;
        } else {
          var prenom = "";
        }
        if (this.state.nom != "") {
          var nom = this.state.nom;
        } else {
          var nom = "";
        }
        if (this.state.cin != "") {
          var cin = this.state.cin;
        } else {
          var cin = "";
        }
        if (this.state.selectEtat != "") {
          var etat = this.state.selectEtat.value;
        } else {
          var etat = 0;
        }
        console.log(currentRowsPerPage);
        console.log(currentPage);
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/EnseignantsSearch.php",
          type: "POST",
          data: {
            prenom: prenom,
            nom: nom,
            cin: cin,
            etat: etat,
            param: this.state.filterElement,
            limit: currentRowsPerPage,
            page: currentPage,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  filterSearch(event) {
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      function () {
        if (this.state.prenom != "") {
          var prenom = this.state.prenom;
        } else {
          var prenom = "";
        }
        if (this.state.nom != "") {
          var nom = this.state.nom;
        } else {
          var nom = "";
        }
        if (this.state.cin != "") {
          var cin = this.state.cin;
        } else {
          var cin = "";
        }
        if (this.state.selectEtat != "") {
          var etat = this.state.selectEtat.value;
        } else {
          var etat = 0;
        }
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/EnseignantsSearch.php",
          type: "POST",
          data: {
            prenom: prenom,
            nom: nom,
            cin: cin,
            etat: etat,
            param: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  ficheEnseignant = (el) => {
    var idEns = el.idEnseignant;
    window.open(
      "http://artsmetiers.mtd-app.com/ImprficheEnseignant?idEns=" +idEns
    );

  }
    
  toggletwo = (idEnseignant) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      idEnseignant: idEnseignant,
    }));
  };

  toggleAbon = (idEnseignant) => {
    this.setState((prevState) => ({
      modalAbon: !prevState.modalAbon,
      idEnseignant: idEnseignant,
    }));
  };

  toggleDes = (idEnseignant) => {
    this.setState((prevState) => ({
      modalDes: !prevState.modalDes,
      idEnseignant: idEnseignant,
    }));
  };

  toggleAct = (idEnseignant) => {
    this.setState((prevState) => ({
      modalAct: !prevState.modalAct,
      idEnseignant: idEnseignant,
    }));
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleDesactiver = () => {
    this.setState((prevState) => ({
      modalDes: !prevState.modalDes,
    }));
  };

  toggleActiver = () => {
    this.setState((prevState) => ({
      modalAct: !prevState.modalAct,
    }));
  };

  toggleAbondant = () => {
    this.setState((prevState) => ({
      modalAbon: !prevState.modalAbon,
    }));
  };

  addNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  addPrenom = (event) => {
    this.setState({
      prenom: event.target.value,
    });
  };

  addCin = (event) => {
    this.setState({
      cin: event.target.value,
    });
  };

  addEtat = (selectEtat) => {
    this.setState({
      selectEtat: selectEtat,
    });
  };

  deleteSearch = () => {
    this.state.search.splice(0, 1);
  };

  recherche = () => {
    if (this.state.prenom != "") {
      var prenom = this.state.prenom;
    } else {
      var prenom = "";
    }
    if (this.state.nom != "") {
      var nom = this.state.nom;
    } else {
      var nom = "";
    }
    if (this.state.cin != "") {
      var cin = this.state.cin;
    } else {
      var cin = "";
    }
    if (this.state.selectEtat != "") {
      var etat = this.state.selectEtat.value;
    } else {
      var etat = 0;
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/EnseignantsSearch.php",
      type: "POST",
      data: {
        param: this.state.filterElement,
        prenom: prenom,
        nom: nom,
        cin: cin,
        etat: etat,
        limit: this.state.nbrEltperPage,
        page: 1,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  afficherTout = () => {
    this.setState({
      nom: "",
      prenom: "",
      cin: "",
    });
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/Enseignants.php",
      type: "POST",
      data: {
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  desactiver = () => {
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/DesactiverEns.php",
      type: "POST",
      data: {
        idEnseignant: this.state.idEnseignant,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modalDes: false,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Enseignants.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  };

  activer = () => {
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/ActiverEns.php",
      type: "POST",
      data: {
        idEnseignant: this.state.idEnseignant,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modalAct: false,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Enseignants.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  };

  abondanner = () => {
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/AbondannerEns.php",
      type: "POST",
      data: {
        idEnseignant: this.state.idEnseignant,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modalAbon: false,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Enseignants.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  };

  deleteEnseignant() {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var userId = userPermissions[0].idUser;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/DeleteEnseignant.php?id=" +
        this.state.idEnseignant +
        "&userId=" +
        userId,
      type: "POST",
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modal: false,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Enseignants.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  }

  imprListeEns = () => {
    if (this.state.selectEtat != "") {
      var etat = this.state.selectEtat.value;
    } else {
      var etat = 0;
    }
    window.open("http://artsmetiers.mtd-app.com/ImprListEns?etat=" + etat);
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Enseignants</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/AjoutEnseignant">
                    {" "}
                    <button
                      style={{
                        background: "#3A3F51",
                        border: "1px solid #3A3F51",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i className="fas fa-plus"></i>Enseignant
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>
                  <Fragment>
                    <div className="col-md-6">
                      <label>Prénom</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addPrenom(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Nom</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addNom(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>CIN</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addCin(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Etat Enseignant</label>
                      <div className="form-group">
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addEtat}
                          options={this.state.etat}
                        />
                      </div>
                    </div>
                  </Fragment>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.afficherTout}
                    >
                      Afficher tout
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.imprListeEns}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression Liste
                    </button>
                  </div>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                  noHeader={true}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggle}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir supprimer cet enseignant ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.deleteEnseignant()}
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggle()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalDes}
          toggle={this.toggleDesactiver}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggleDesactiver}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir désactiver cet enseignant ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.desactiver()}
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggleDesactiver()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalAct}
          toggle={this.toggleActiver}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggleActiver}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir activer cet enseignant ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.activer()}
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggleActiver()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalAbon}
          toggle={this.toggleAbondant}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggleAbondant}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir abondanner cet enseignant ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.abondanner()}
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggleAbondant()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    );
  }
}

export default Enseignants;
