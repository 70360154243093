import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import { Link, BrowserRouter as Router } from "react-router-dom";

class ExplJuryBTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listJury: [],
      enteteExam: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var juryExamId = this.props.location.state.juryExamId;
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/ExplJuryBTS.php",
        type: "POST",
        data: {
          juryExamId: juryExamId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listJury: code_html.jury,
            enteteExam: code_html.enteteExam,
          });
        },
      });
    }
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Exploration Jury BTS</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/ConsulterJuryBTS">
                    {" "}
                    <button
                      style={{
                        background: "#DC143C",
                        border: "1px solid #DC143C",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i
                        style={{ paddingRight: "5px" }}
                        className="fas fa-arrow-circle-left"
                      ></i>
                      Retour
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Entete Exam :</label>
                      <input
                        type="text"
                        disabled
                        className="form-control"
                        value={this.state.enteteExam}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Jury :</label>
                      <Select
                        disabled
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        value={this.state.listJury}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExplJuryBTS;
