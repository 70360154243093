import React, { Component, Fragment } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios'
import jQuery from 'jquery'
import './paginate.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Collapsible from 'react-collapsible';
import Select from 'react-select';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

class ReglApprenantCycle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Num inscrip',
                    selector: 'numInscrip',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Carte AD',
                    selector: 'carte',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Nom et prénom',
                    selector: 'nomComplet',
                    sortable: false,
                    center: true,
                    style: { width: '25%' }
                },
                {
                    name: 'Connaissance',
                    selector: 'connaissance',
                    sortable: false,
                    center: true,
                    style: { width: '15%' }
                },
                {
                    name: "Fiche d'inscription",
                    cell: row => <div onClick={() => this.ficheIns(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="far fa-id-card"></i></div><div style={{ marginLeft: '5px' }}><p>Fiche inscription</p></div></div>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Frais cycle',
                    cell: row => row.reglFrais.length > 0 ? (<div onClick={() => this.reglFrais(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px' }}><p>Frais</p></div></div>) : (null),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Reglement inscription',
                    cell: row => <div onClick={() => this.reglApprenant(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px' }}><p>Reglement</p></div></div>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Recu',
                    cell: row => row.affichRecuPay == 1 ? (<i style={{ color: '#2192FF', fontSize: '20px' }} onClick={() => this.attPay(row)} className="fas fa-file-invoice"></i>) : (null),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Modifier',
                    cell: row => this.state.idRoleUser == 1 ? (<Link to={{
                        pathname: '/ModifInsAppCycle',
                        state: { idEleve: row.idEleve }
                    }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link>) : (null),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                }
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            modal: false,
            pending: true,
            regCycle: [],
            modalFrais: false,
            reglFraisCycle: [],
            // search
            prenom: '',
            nom: '',
            cin: '',
            lieuResid: [],
            seletedLieuResid: '',
            listNivBase: [],
            selectedNivBase: '',
            listEleve: [],
            selectedIdEleve: '',
            selectedTypeFormat: '',
            listCycle: [],
            selectedCycle: [],
            modalSupRegCycle: false,
            idRegCycleSup: '',
            // Condition session
            idRoleUser: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            var idRoleUser = userPermissions[0].idRoleUser
            this.setState({
                idRoleUser: idRoleUser
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppCycle.php',
                type: 'POST',
                data: {
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectResidCycle.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        lieuResid: code_html
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectNivBaseCycle.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listNivBase: code_html
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectTypeFormCycle.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        typeFormation: code_html
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectDetCycle.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listCycle: code_html
                        
                    })
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    ficheIns = (row) => {
        var idEleve = row.idEleve
        window.open("http://artsmetiers.mtd-app.com/ImprDetailCycle?idEleve=" + idEleve)
        // jQuery.ajax({
        //     url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuFicheInsCycle.php',
        //     type: 'POST',
        //     data: {
        //         idIns: idIns
        //     },
        //     dataType: 'json',
        //     success: function (code_html, statut) {
        //         var server_path = code_html.server_path;
        //         var file_path = code_html.file_path
        //         var path = server_path + file_path;
        //         window.open(path, "_blank")
        //     }
        // });
    }

    reglFrais = (row) => {
        this.setState({
            reglFraisCycle: row.reglFrais
        }, () => {
            this.setState(prevState => ({
                modalFrais: !prevState.modalFrais
            }));
        });
    }

    toggleFrais = () => {
        this.setState(prevState => ({
            modalFrais: !prevState.modalFrais
        }));
    }

    reglApprenant = (row) => {
        this.setState({
            regCycle: row.reglInscrip
        }, () => {
            console.log(this.state.regCycle.detailRegl)
            this.setState(prevState => ({
                modal: !prevState.modal,
            }));
        });
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    attPay = (row) => {
        var idIns = row.idEleve
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuPayCycle.php',
            type: 'POST',
            data: {
                idIns: idIns
            },
            dataType: 'json',
            success: function (code_html, statut) {
                var server_path = code_html.server_path;
                var file_path = code_html.file_path
                var path = server_path + file_path;
                window.open(path, "_blank")
            }
        });
    }

    recuRegl = (ele) => {
        var idRegCycle = ele
        var incluEspCycle = false
        var incluChecCycle = false
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ApprenantsTypePayCycle.php',
            type: 'POST',
            data: {
                idRegCycle: idRegCycle
            },
            dataType: 'json',
            success: (code_html, statut) => {
                this.setState({
                    arrayModePaiementCycle: code_html
                }, () => {
                    if (this.state.arrayModePaiementCycle.includes('1') || this.state.arrayModePaiementCycle.includes('3')) {
                        incluEspCycle = true
                    }
                    if (this.state.arrayModePaiementCycle.includes('2')) {
                        incluChecCycle = true
                    }
                    if (incluChecCycle == true) {
                        jQuery.ajax({
                            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegCycleChec.php',
                            type: 'POST',
                            data: {
                                idRegCycle: idRegCycle
                            },
                            dataType: 'json',
                            success: (code_html, statut) => {
                                var server_path = code_html.server_path;
                                var file_path = code_html.file_path
                                var path = server_path + file_path;
                                window.open(path, "_blank")
                            }
                        });
                    }
                    else if (incluEspCycle = true) {
                        jQuery.ajax({
                            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegCycleEsp.php',
                            type: 'POST',
                            data: {
                                idRegCycle: idRegCycle
                            },
                            dataType: 'json',
                            success: (code_html, statut) => {
                                var server_path = code_html.server_path;
                                var file_path = code_html.file_path
                                var path = server_path + file_path;
                                window.open(path, "_blank")
                            }
                        });
                    }
                })
            }
        });
    }

    toggleSupRegCycle = (el) => {
        this.setState({
            idRegCycleSup: el
        }, () => {
            this.setState(prevState => ({
                modalSupRegCycle: !prevState.modalSupRegCycle
            }));
        });
    }

    deleteRegCycle() {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + "Back_mediatraining/DeleteRegCycle.php",
            type: 'POST',
            dataType: 'json',
            data: {
                idRegCycle: this.state.idRegCycleSup,
                userId: userId
            },
            success: (code_html, statut) => {
                this.setState({
                    modal: false,
                    modalSupRegCycle: false
                }, () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppCycle.php',
                        type: 'POST',
                        data: {
                            query: '',
                            limit: this.state.nbrEltperPage,
                            page: 1
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data
                            });
                        }
                    });
                })
            }
        });
    }

    newElement = (page, totalRows) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.seletedLieuResid != '') {
            var idLieuResid = this.state.seletedLieuResid.value
        }
        else {
            var idLieuResid = ''
        }
        if (this.state.selectedNivBase != '') {
            var idNivBase = this.state.selectedNivBase.value
        }
        else {
            var idNivBase = ''
        }
        if (this.state.selectedTypeFormat != '') {
            var idTypeFormat = this.state.selectedTypeFormat.value
        }
        else {
            var idTypeFormat = ''
        }
        if (this.state.selectedCycle != '') {
            var idCycle = this.state.selectedCycle.value
        }
        else {
            var idCycle = ''
        }
        if ((this.state.prenom == '') && (this.state.nom == '') && (this.state.cin == '') && (idLieuResid == '') && (idNivBase == '') && (idTypeFormat == '') && (idCycle == '')) {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppCycle.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
        else {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppCycleSearch.php',
                        type: 'POST',
                        data: {
                            prenom: this.state.prenom,
                            nom: this.state.nom,
                            cin: this.state.cin,
                            idLieuResid: idLieuResid,
                            idNivBase: idNivBase,
                            idTypeFormat: idTypeFormat,
                            idCycle: idCycle,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    perPage = (currentRowsPerPage, currentPage) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.seletedLieuResid != '') {
            var idLieuResid = this.state.seletedLieuResid.value
        }
        else {
            var idLieuResid = ''
        }
        if (this.state.selectedNivBase != '') {
            var idNivBase = this.state.selectedNivBase.value
        }
        else {
            var idNivBase = ''
        }
        if (this.state.selectedTypeFormat != '') {
            var idTypeFormat = this.state.selectedTypeFormat.value
        }
        else {
            var idTypeFormat = ''
        }
        if (this.state.selectedCycle != '') {
            var idCycle = this.state.selectedCycle.value
        }
        else {
            var idCycle = ''
        }
        if ((this.state.prenom == '') && (this.state.nom == "") && (this.state.cin == '') && (idLieuResid == '') && (idNivBase == "") && (idTypeFormat == '') && (idCycle == '')) {
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppCycle.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            );
        }
        else {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppCycleSearch.php',
                        type: 'POST',
                        data: {
                            prenom: this.state.prenom,
                            nom: this.state.nom,
                            cin: this.state.cin,
                            idLieuResid: idLieuResid,
                            idNivBase: idNivBase,
                            idTypeFormat: idTypeFormat,
                            idCycle: idCycle,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }


    filterSearch = (event) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppCycle.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    searchPrenom = (event) => {
        this.setState({
            prenom: event.target.value
        });
    }

    searchNom = (event) => {
        this.setState({
            nom: event.target.value
        });
    }

    searchCin = (event) => {
        this.setState({
            cin: event.target.value
        });
    }

    searchLieuResid = (seletedLieuResid) => {
        if (seletedLieuResid != null) {
            this.setState({ seletedLieuResid: seletedLieuResid });
        }
        else {
            this.setState({ seletedLieuResid: '' });
        }
    }

    searchNivBase = selectedNivBase => {
        if (selectedNivBase != null) {
            this.setState({ selectedNivBase });
        }
        else {
            this.setState({ selectedNivBase: '' });
        }
    };

    searchTypeFormat = selectedTypeFormat => {
        if (selectedTypeFormat != null) {
            this.setState({ selectedTypeFormat });
        }
        else {
            this.setState({ selectedTypeFormat: '' });
        }
    };

    searchCycle = selectedCycle => {
        if (selectedCycle != null) {
            this.setState({ selectedCycle });
        }
        else {
            this.setState({ selectedCycle: '' });
        }
    };

    recherche = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.seletedLieuResid != '') {
            var idLieuResid = this.state.seletedLieuResid.value
        }
        else {
            var idLieuResid = ''
        }
        if (this.state.selectedNivBase != '') {
            var idNivBase = this.state.selectedNivBase.value
        }
        else {
            var idNivBase = ''
        }
        if (this.state.selectedTypeFormat != '') {
            var idTypeFormat = this.state.selectedTypeFormat.value
        }
        else {
            var idTypeFormat = ''
        }
        if (this.state.selectedCycle != '') {
            var idCycle = this.state.selectedCycle.value
        }
        else {
            var idCycle = ''
        }
        this.setState({
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppCycleSearch.php',
                    type: 'POST',
                    data: {
                        prenom: this.state.prenom,
                        nom: this.state.nom,
                        cin: this.state.cin,
                        idLieuResid: idLieuResid,
                        idNivBase: idNivBase,
                        idTypeFormat: idTypeFormat,
                        idCycle: idCycle,
                        anneeScolaire: anneeScolaire,
                        limit: this.state.nbrEltperPage,
                        page: 1
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    afficherTout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppCycle.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false
                });
            }
        });
    }

    impr = () => {
        if (this.state.seletedLieuResid != '') {
            var lieu = this.state.seletedLieuResid.value
        }
        else {
            var lieu = ''
        }
        if (this.state.selectedNivBase != '') {
            var niveau = this.state.selectedNivBase.value
        }
        else {
            var niveau = ''
        }
        if (this.state.selectedTypeFormat != '') {
            var typeFor = this.state.selectedTypeFormat.value
        }
        else {
            var typeFor = ''
        }
        if (this.state.selectedCycle != '') {
            var detCycle = this.state.selectedCycle.value
        }
        else {
            var detCycle = ''
        }
        var params = this.state.nom + '-' + this.state.prenom + '-' + this.state.cin + '-' + lieu + '-' + niveau + '-' + typeFor + '-' + detCycle
        window.open("http://artsmetiers.mtd-app.com/ImprPayAppCycle?params=" + params)
       // window.open("http://localhost:3000/ImprPayAppCycle?params=" + params)
    }

    imprList = () => {
        if (this.state.seletedLieuResid != '') {
            var lieu = this.state.seletedLieuResid.value
        }
        else {
            var lieu = ''
        }
        if (this.state.selectedNivBase != '') {
            var niveau = this.state.selectedNivBase.value
        }
        else {
            var niveau = ''
        }
        if (this.state.selectedTypeFormat != '') {
            var typeFor = this.state.selectedTypeFormat.value
        }
        else {
            var typeFor = ''
        }
        if (this.state.selectedCycle != '') {
            var detCycle = this.state.selectedCycle.value
        }
        else {
            var detCycle = ''
        }
        var params = this.state.nom + '-' + this.state.prenom + '-' + this.state.cin + '-' + lieu + '-' + niveau + '-' + typeFor + '-' + detCycle
        window.open("http://artsmetiers.mtd-app.com/ImprListeCycle?params=" + params)
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Reglement cycle</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="row">
                                    <h4 style={{ paddingLeft: '10px' }}>Critères de recherche</h4>
                                    <Fragment>
                                        <div className="col-md-6">
                                            <label>Prénom</label>
                                            <div className="form-group">
                                                <input type="text" onChange={(e) => this.searchPrenom(e)} placeholder={'Prénom'} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', width: '100%', border: 'solid 1px #B3B3B3' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Nom</label>
                                            <div className="form-group">
                                                <input type="text" onChange={(e) => this.searchNom(e)} placeholder={'Nom'} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', width: '100%', border: 'solid 1px #B3B3B3' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>CIN</label>
                                            <div className="form-group">
                                                <input type="text" onChange={(e) => this.searchCin(e)} placeholder={'CIN'} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', width: '100%', border: 'solid 1px #B3B3B3' }} />
                                            </div>
                                        </div>
                                    </Fragment>
                                    <Fragment>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Lieu résidence</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchLieuResid}
                                                    options={this.state.lieuResid}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Niveau de base</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchNivBase}
                                                    options={this.state.listNivBase}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Type formation</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchTypeFormat}
                                                    options={this.state.typeFormation}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Cycles</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchCycle}
                                                    options={this.state.listCycle}
                                                    formatGroupLabel={formatGroupLabel}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.recherche}>Rechercher</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.afficherTout}>Afficher tout</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.impr}><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Impression</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.imprList}><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Imprimer liste</button>
                                    </div>
                                </div>
                                <div className="mb-0 form-group">
                                    <div className="input-group-alternative input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input onChange={(currentRowsPerPage, currentPage) => this.filterSearch(currentRowsPerPage, currentPage)} placeholder="Search" type="text" className="search" />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} fade={false}>
                    <ModalHeader toggle={this.toggle}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Reglement eleve cycle</p></div></div></ModalHeader>
                    <ModalBody>
                        <div>
                            <table className="table">
                                <thead >
                                    <tr>
                                        <th style={{ textAlign: 'center' }} scope="col">Etat</th>
                                    </tr>
                                </thead>
                                {this.state.regCycle.map((el, index) =>
                                    <tbody>
                                        <tr>
                                            {el.etat !== '' ?
                                                (<td style={{ textAlign: 'center' }}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>{el.etat}</p><p style={{ paddingLeft: '5px' }}>{el.mntNonRegle}</p></div>
                                                    <Collapsible trigger={<button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }}><i style={{ color: 'white' }} className="fas fa-info"></i></button>}>
                                                        <Fragment>
                                                            <table className="table table-bordered">
                                                                <thead >
                                                                    <tr>
                                                                        <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Date</th>
                                                                        <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Montant</th>
                                                                        <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Reglement</th>
                                                                        <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Piéce</th>
                                                                        {this.state.idRoleUser == 1 ? (
                                                                            <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Supp</th>) : (null)}
                                                                    </tr>
                                                                </thead>
                                                                {el.detailRegl.map(ele =>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ textAlign: 'center', fontSize: '10px' }}><p>{ele.date}</p></td>
                                                                            <td style={{ textAlign: 'center', fontSize: '10px' }}><p>{ele.totalPay}</p></td>
                                                                            <td style={{ textAlign: 'center', fontSize: '10px' }}>
                                                                                {ele.infoPay.map(elem =>
                                                                                    elem.typePay == "Chéque" ?
                                                                                        (<Fragment><p>{elem.typePay}</p><p style={{ fontSize: '9px' }}>{'Echéance : ' + elem.echeance}</p></Fragment>) : (<p>{elem.typePay}</p>)
                                                                                )}
                                                                            </td>
                                                                            <td><i onClick={() => this.recuRegl(ele.idCycleReg)} className="fas fa-print"></i></td>
                                                                            {this.state.idRoleUser == 1 ? (
                                                                                <td style={{ textAlign: 'center', fontSize: '10px' }}><button className='buttonSupprimer' onClick={() => this.toggleSupRegCycle(ele.idCycleReg)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>) : (null)}
                                                                        </tr>
                                                                    </tbody>
                                                                )}
                                                            </table>
                                                        </Fragment>
                                                    </Collapsible>
                                                </td>) : (<td></td>)}
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <div><button onClick={() => this.toggle()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modalFrais} toggle={this.toggleFrais} className={this.props.className} fade={false}>
                    <ModalHeader toggle={this.toggleFrais}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Reglement frais</p></div></div></ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} scope="col">Frais</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant Payer</th>
                                </tr>
                            </thead>
                            {this.state.reglFraisCycle.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'center' }}><p>{el.frais}</p></td>
                                        <td style={{ textAlign: 'center' }}><p>{el.mntPayer}</p></td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                            <div><button onClick={() => this.toggleFrais()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modalSupRegCycle} toggle={this.toggleSupRegCycle} className={this.props.className} fade={false}>
                    <ModalHeader toggle={this.toggleSupRegCycle}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                    <ModalBody>
                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <p>Êtes-Vous sûr de vouloir supprimer ce reglement ?</p>
                            </div>
                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <div><button onClick={() => this.deleteRegCycle()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                <div><button onClick={() => this.toggleSupRegCycle()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ReglApprenantCycle;