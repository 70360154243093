import React, { Component, Fragment } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import jQuery from "jquery";
import "./paginate.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import YearPicker from "react-year-picker";

class Apprenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "CIN",
          selector: "cin",
          sortable: false,
          center: true,
          style: { width: "10%" },
        },
        {
          name: "Prénom-Nom",
          selector: "prenomNom",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "Mobile",
          selector: "mobile",
          sortable: false,
          center: true,
          style: { width: "10%" },
        },
        {
          name: "Profil",
          cell: (row) => (
            <button
              style={{
                backgroundColor: "#F0E68C",
                borderColor: "#F0E68C",
                borderRadius: "5px",
              }}
              onClick={() => this.profil(row.idApprenant)}
            >
              <i class="fas fa-id-badge"></i>
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "15%",
        },
        {
          name: "Attest INS",
          cell: (row) => (
            <button
              style={{
                backgroundColor: "#87B87F",
                borderColor: "#87B87F",
                borderRadius: "5px",
              }}
              onClick={() => this.attIns(row)}
            >
              <i style={{ color: "white" }} className="fas fa-address-card"></i>
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "15%",
        },
        {
          name: "Modif",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ModifApprenant",
                state: { idApprenant: row.idApprenant },
              }}
            >
              <button className="buttonModifier">
                <i style={{ color: "white" }} className="fas fa-pencil-alt"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
        {
          name: "abondant/non abondant",
          cell: (row) =>
            this.state.idRoleUser == 1 ? (
              row.etat == "0" ? (
              <button
                className="button"
                onClick={() => this.toggletwo(row.idApprenant)}
              >
                <i class='fas fa-ban'></i>

              </button>
              ):( <button
                className="button"
                onClick={() => this.toggletwoactif(row.idApprenant)}
              >
                 <i class='fas fa-ban'></i>
               
              </button>)
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      idApprenant: "",
      pending: true,
      // recherche
      nom: "",
      prenom: "",
      cin: "",
      villeResid: "",
      anneeNaiss: "",
      listNivBase: [],
      selectedNivBase: "",
      ageMin: "",
      ageMax: "",
      idRoleUser: "",
      selectEtat : "",
      etat: [
        { value: 0, label: "Non Abondant" },
        { value: 2, label: " Abondant" },
      ],
      selectEtat: "",
      modalAbon: false,
      modalDesAbon:false,
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var idRoleUser = userPermissions[0].idRoleUser;
      this.setState({
        idRoleUser: idRoleUser,
      });
      axios
        .get(
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectNivBase.php"
        )
        .then((res) => {
          console.log(res);
          this.setState({
            listNivBase: res.data,
          });
        });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/Apprenants.php",
        type: "POST",
        data: {
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  newElement(page, totalRows) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (
      this.state.prenom == "" &&
      this.state.nom == "" &&
      this.state.cin == "" &&
      this.state.villeResid == "" &&
      this.state.dateNaiss == "" &&
      this.state.selectedNivBase == "" &&
      this.state.ageMin == "" &&
      this.state.ageMax == ""
    ) {
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/Apprenants.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      if (this.state.selectedNivBase == "") {
        var idNiveau = "";
      } else {
        var idNiveau = this.state.selectedNivBase.value;
      }
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ApprenantSearchap.php",
            type: "POST",
            data: {
              prenom: this.state.prenom,
              nom: this.state.nom,
              cin: this.state.cin,
              villeResid: this.state.villeResid,
              anneeNaiss: this.state.anneeNaiss,
              idNiveau: idNiveau,
              ageMin: this.state.ageMin,
              ageMax: this.state.ageMax,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }

  perPage(currentRowsPerPage, currentPage) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (
      this.state.prenom == "" &&
      this.state.nom == "" &&
      this.state.cin == "" &&
      this.state.villeResid == "" &&
      //this.state.dateNaiss == "" &&
      this.state.anneeNaiss == "" &&
      this.state.selectedNivBase == "" &&
      this.state.ageMin == "" &&
      this.state.ageMax == ""
    ) {
      this.setState(
        {
          nbrEltperPage: currentRowsPerPage,
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/Apprenants.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: currentRowsPerPage,
              page: currentPage,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      if (this.state.selectedNivBase == "") {
        var idNiveau = "";
      } else {
        var idNiveau = this.state.selectedNivBase.value;
      }
     
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ApprenantSearchap.php",
            type: "POST",
            data: {
              prenom: this.state.prenom,
              nom: this.state.nom,
              cin: this.state.cin,
              villeResid: this.state.villeResid,
              anneeNaiss: this.state.anneeNaiss,
              //dateNaiss: convertDateNaiss,
              idNiveau: idNiveau,
              ageMin: this.state.ageMin,
              ageMax: this.state.ageMax,
              limit: currentRowsPerPage,
              page: currentPage,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }

  filterSearch(event) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/Apprenants.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  profil = (el) => {
    var eleveId = el;
    window.open(
      "http://artsmetiers.mtd-app.com/ImprProfilApp?eleveId=" + eleveId
    );
  };

  attIns = (el) => {
    var eleveId = el.idApprenant;
    window.open(
      "http://artsmetiers.mtd-app.com/ImprAttestationPres?eleveId=" + eleveId
    );
  };

  ImprApprIns = () => {
    var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
    var anneeScolaire = userPermissions[0].anneeScolaire
    
    jQuery.ajax({
        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ImprApprIns.php',
        type: 'POST',
        data: {
            query: '',
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire
        },
        dataType: 'json',
        success: function (code_html, statut) {
    window.open("http://artsmetiers.mtd-app.com/ImprApprIns")
        }
    });
}
 

  attPres = (el) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    var idApp = el.idApprenant;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/AttPresenceApp.php",
      type: "POST",
      data: {
        idApp: idApp,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: function (code_html, statut) {
        var server_path = code_html.server_path;
        var file_path = code_html.file_path;
        var path = server_path + file_path;
        window.open(path, "_blank");
      },
    });
  };

  toggletwo = (idApprenant) => {
    this.setState((prevState) => ({
      modalDesAbon: !prevState.modalDesAbon,
      idApprenant: idApprenant,
    }));
  };
  
  toggletwoactif = (idApprenant) => {
    this.setState((prevState) => ({
      modalAbon: !prevState.modalAbon,
      idApprenant: idApprenant,
    }));
  };
  // toggleAbondant = () => {
  //   this.setState((prevState) => ({
  //     modalAbon: !prevState.modalAbon,
  //   }));
  // };
  // toggleDesAbondant = () => {
  //   this.setState((prevState) => ({
  //     modalDesAbon: !prevState.modalDesAbon,
  //   }));
  // };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  addEtat = (selectEtat) => {
    this.setState({
      selectEtat: selectEtat,
    });
  };

  deleteSearch = () => {
    this.state.search.splice(0, 1);
  };

  addPrenom = (event) => {
    this.setState({
      prenom: event.target.value,
    });
  };

  addNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  addCin = (event) => {
    this.setState({
      cin: event.target.value,
    });
  };

  addVilleResid = (event) => {
    this.setState({
      villeResid: event.target.value,
    });
  };

  addAnneeNaiss = (d) => {
    this.setState({
      anneeNaiss: d,
    });
  };

  addNivBase = (selectedNivBase) => {
    if (selectedNivBase != null) {
      this.setState({ selectedNivBase });
    } else {
      this.setState({ selectedNivBase: "" });
    }
  };

  addAgeMin = (event) => {
    this.setState({
      ageMin: event.target.value,
    });
  };

  addAgeMax = (event) => {
    this.setState({
      ageMax: event.target.value,
    });
  };
  abondanner = () => {
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/actifapprenant.php",
      type: "POST",
      data: {
        idApprenant: this.state. idApprenant,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modalDesAbon: false,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Enseignants.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  };
  desabondonner = () => {
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/desactifapprenant.php",
      type: "POST",
      data: {
        idApprenant: this.state. idApprenant,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modalAbon: false,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Enseignants.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  };
  recherche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        if (this.state.selectedNivBase == "") {
          var idNiveau = "";
        } else {
          var idNiveau = this.state.selectedNivBase.value;
        }
        if (this.state.selectEtat != "") {
          var etat = this.state.selectEtat.value;
        } else {
          var etat = 0;
        }
        
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ApprenantSearchap.php",
          type: "POST",
          data: {
            prenom: this.state.prenom,
            nom: this.state.nom,
            cin: this.state.cin,
            villeResid: this.state.villeResid,
            anneeNaiss: this.state.anneeNaiss,
            idNiveau: idNiveau,
            ageMin: this.state.ageMin,
            ageMax: this.state.ageMax,
            etat: etat,
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  afficherTout = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState({
      prenom: "",
      nom: "",
      cin: "",
      villeResid: "",
      anneeNaiss: "",
      selectedNivBase: "",
      ageMin: "",
      ageMax: "",
    });
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/Apprenants.php",
      type: "POST",
      data: {
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  deleteApprenant() {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var userId = userPermissions[0].idUser;
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/DeleteApprenant.php?id=" +
        this.state.idApprenant +
        "&userId=" +
        userId,
      type: "POST",
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modal: false,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Apprenants.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Apprenants</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>
                  <Fragment>
                    <div className="col-md-6">
                      <label>Prénom</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addPrenom(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Nom</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addNom(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>CIN</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addCin(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Résidence</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addVilleResid(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Niveau</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={(e) => this.addNivBase(e)}
                          options={this.state.listNivBase}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Age min</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addAgeMin(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Age max</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addAgeMax(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                      <div className="col-md-6" >
                      <div className="form-group">
                      <label>Etat Apprenant</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addEtat}
                        options={this.state.etat}
                      />
                    
                    </div></div>
                  
                    <div className="col-lg-8" >
                      <div className="form-group">
                        <label>Annee de naissance</label>
                        <YearPicker 
                  
                          style={{ border: "1" , width: "100%"}}
                          selected={this.state.dateNaiss}
                          onChange={this.addAnneeNaiss}
                          
                        />
                      </div>
                    </div>
                    
                  </Fragment>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" ,paddingTop:"25px"}}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.afficherTout}
                    >
                      Afficher tout
                    </button>
                  </div>
                  <div>
                                <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ImprApprIns}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Imprimer
                    </button>
                  </div>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalDesAbon}
          toggle={this.toggletwo}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggletwo}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir abondonner cet apprenant ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.abondanner()} 
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggletwo()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "green" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalAbon}
          toggle={this.toggletwoactif}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggletwoactif}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir desabondonner cet apprenant ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.desabondonner()}
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggletwoactif()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    );
  }
}

export default Apprenants;
