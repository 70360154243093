import React, { Component } from "react";
import jQuery from "jquery";

import "./paginate.css";
import DataTable, { createTheme } from 'react-data-table-component';

class ImprRemboursement extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        data: [],
    columns: [
        {
            name: 'Date',
            selector: 'date',
            sortable: false,
            center: true,
            style: { width: '15%' }
        },
        {
            name: 'Eléve',
            selector: 'nomComplet',
            sortable: false,
            center: true,
            style: { width: '20%' }
        },
       
        {
            name: 'Numéro recu',
            selector: 'numRecu',
            sortable: false,
            center: true,
            style: { width: '15%' }
        },
        {
            name: 'Montant',
            selector: 'mnt',
            sortable: false,
            center: true,
            style: { width: '10%' }
        },
        {
            name: 'Mode',
            selector: 'mode',
            sortable: false,
            center: true,
            style: { width: '10%' }
        },
       
    ],
      moisExist: "",
      nomMois: "",
      arrayEnsNonPayer: [],
      disabledEspece: false,
      disabledCheque: false,
      totalAPayer: 0,
      totalACompte: 0,
      totalEsp: 0,
      totalChec: 0,
      totalReste: 0,
      today: "",
    };
  }


  componentDidMount() {
    if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var idRoleUser = userPermissions[0].idRoleUser
        if (idRoleUser == 1) {
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/imRemboursement.php',
                type: 'POST',
                data: {
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
        }
        else {
            localStorage.clear();
            document.location = '/'
        }
    }
    else {
        document.location = '/'
    }
}

  impr = () => {
    window.print();
  };    

  render() {
    return (
      <div className="white-box">
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          Liste des eleves {" "}
        </h4>
        <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    //pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
       
        
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default ImprRemboursement;



