import React, { Component } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import "./paginate.css";

class StatiquePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Role selon l'utilisateur (soit admin ou scolarite)
      roleId: "",
      annee: "",
      anneeScolaire: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var roleId = userPermissions[0].idRoleUser;
      var anneeScolaire = userPermissions[0].anneeScolaire;
      this.setState({
        roleId: roleId,
        annee: anneeScolaire,
        anneeScolaire: parseFloat(anneeScolaire) + 1,
      });
    } else {
      this.props.history.push("/");
    }
  }

  clearStorage = () => {
    localStorage.clear();
    this.props.history.push("/");
  };

  render() {
    return (
      <div id="wrapper">
        {/* Navigation */}
        <nav
          className="navbar navbar-default navbar-static-top"
          style={{ marginBottom: 0 }}
        >
          <div className="navbar-header" style={{ backgroundColor: "#3a3f51" }}>
            {" "}
            <a
              className="navbar-toggle hidden-sm hidden-md hidden-lg "
              href="javascript:void(0)"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <i className="ti-menu" />
            </a>
            <div className="top-left-part">
              <a href="/Menu" className="logo">
                <img
                  style={{ backgroundColor: "white" }}
                  className="img-circle"
                  width="30px"
                  height="30px"
                  src="images/art.jpg"
                  alt="Art_Metier"
                />
                &nbsp;
                <span style={{ fontSize: "13px" }} className="hidden-xs">
                  ARTSMETIERS
                </span>
              </a>
            </div>
            {this.state.roleId == 1 ? (
              <ul className="nav navbar-top-links navbar-left hidden-xs">
                <li className="dropdown">
                  {" "}
                  <a href="/MenuUn" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-align-left"
                    ></i>
                    Paramétrage
                  </a>
                </li>
                <li className="dropdown">
                  {" "}
                  <a href="/MenuDeux" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-graduation-cap"
                    ></i>
                    Gestion apprenants
                  </a>
                </li>
                <li className="dropdown">
                  {" "}
                  <a href="/MenuQuatre" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-chalkboard-teacher"
                    ></i>
                    Gestion enseignants
                  </a>
                </li>
                <li className="dropdown">
                  {" "}
                  <a href="/MenuTrois" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-vr-cardboard"
                    ></i>
                    Tableau de bord
                  </a>
                </li>
              </ul>
            ) : (
              <ul className="nav navbar-top-links navbar-left hidden-xs">
                <li className="dropdown">
                  {" "}
                  <a href="/MenuUn" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-balance-scale"
                    ></i>
                    Scolarité
                  </a>
                </li>
                <li className="dropdown">
                  {" "}
                  <a href="/MenuDeux" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-address-card"
                    ></i>
                    Formation intiale
                  </a>
                </li>
                <li className="dropdown">
                  {" "}
                  <a href="/MenuQuatre" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-list-alt"
                    ></i>
                    Assiduité
                  </a>
                </li>
                <li className="dropdown">
                  {" "}
                  <a href="/MenuCinq" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-align-left"
                    ></i>
                    Note
                  </a>
                </li>
                <li className="dropdown">
                  {" "}
                  <a href="/MenuSix" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-calendar-week"
                    ></i>
                    Calendrier
                  </a>
                </li>
                <li className="dropdown">
                  {" "}
                  <a href="/MenuTrois" style={{ color: "white" }}>
                    <i
                      style={{ marginRight: "5px", color: "white" }}
                      className="fas fa-bookmark"
                    ></i>
                    Formation accéléré
                  </a>
                </li>
              </ul>
            )}
            <ul className="nav navbar-top-links navbar-right pull-right">
              <li>
                <div onClick={this.clearStorage} className="hvr-shrink">
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="profile-pic"
                  >
                    {" "}
                    <img
                      src="images/users/logout.png"
                      alt="user-img"
                      width={25}
                      className="img-circle"
                    />
                    <b style={{ color: "white" }} className="hidden-xs">
                      Déconnexion
                    </b>{" "}
                  </span>
                </div>
              </li>
            </ul>
            <ul
              className="nav navbar-top-links navbar-right pull-right"
            >
              <li>
                <div>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="profile-pic"
                  >
                    {" "}
                    <b style={{ color: "white" }} className="hidden-xs">
                      Année Scolaire : {this.state.annee} /{" "}
                      {this.state.anneeScolaire}
                    </b>{" "}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(StatiquePage);
