import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { RadioGroup, Radio } from 'react-radio-group'
import Switch from "react-switch";
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class Inscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            prenom: '',
            nom: '',
            selectedType: 'CIN',
            numSelonType: '',
            nationalite: '',
            dateDelivSelonType: new Date(),
            villeDelivreSelonType: '',
            dateNaissance: '',
            lieuNaissance: '',
            email: '',
            selectedGender: 'Masculin',
            mobile: '',
            tel: '',
            adresseOfficiel: '',
            adresseTun: '',
            listNivBase: [],
            selectedNivBase: '',
            imageVerso: null,
            numInscrip: '',
            listApprenants: [],
            selectedApprenant: '',
            disabled: false,
            listPapiers: [],
            listTypePay: [],
            selectedTypePay: '',
            listFilieres: [],
            selectedFiliere: '',
            listSpecialites: [],
            selectedSpecialite: '',
            listFormations: [],
            selectedFormation: '',
            listNiveaux: [],
            selectedNiveau: '',
            listGroupes: [],
            selectedGroup: '',
            papiersChoisi: [],
            nomPere: '',
            adresseParents: '',
            profession: '',
            telBureau: '',
            fb: '',
            dateIns: new Date(),
            //
            blockReglement: false,
            listFrais: [],
            listTypePaiement: '',
            selectedTypePayFrais: '',
            mntFraisPayer: 0,
            mntFraisRestant: '',
            mntFraisTotalPayer: 0,
            payFormation: [],
            listModPay: [],
            tabModPay: [{ modPay: '', mntModPay: '', numCheque: '', echeanceChec: new Date(), dateEncaiss: new Date(), titulaireChec: '', banque: '' }],
            mntInscripPayer: 0,
            mntInscripRestant: '',
            mntIncripTotalPayer: 0,
            ajoutInscrip: false,
            modal: false,
            indexLignASupprimer: '',
            eleveId: '',
            indexTranchePay: [],
            disabledFrais: false,
            disabledReg: false,
            arrayModePaiement: [],
            arrayPaiement: [],
            disbutIns: true,
            disbutReg: true
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectNivBase.php').then(res => {
                console.log(res)
                this.setState({
                    listNivBase: res.data
                })
            })
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFilieres.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listFilieres: code_html
                    });
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectApprenants.php').then(res => {
                console.log(res)
                this.setState({
                    listApprenants: res.data
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/PapiersDemandee.php').then(res => {
                console.log(res)
                this.setState({
                    listPapiers: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    handleChange = (checked) => {
        this.setState({ checked });
        console.log(this.state.checked)
    }

    addApprenant = selectedApprenant => {
        if (selectedApprenant != null) {
            this.setState({ selectedApprenant }, () => {
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetInfoEleveById.php?id=' + selectedApprenant.value).then(res => {
                    console.log(res)
                    if (res.data.dateNum != "0000-00-00") {
                        var dateType = new Date(res.data.dateNum)
                    }
                    else {
                        var dateType = ''
                    }
                    this.setState({
                        selectedType: res.data.type,
                        numSelonType: res.data.numero,
                        numInscrip: res.data.numIns,
                        nationalite: res.data.nationalite,
                        dateDelivSelonType: dateType,
                        villeDelivreSelonType: res.data.villeNum,
                        dateNaissance:(res.data.naissance),
                        lieuNaissance: res.data.villeNaiss,
                        mobile: res.data.mobile,
                        tel: res.data.tel,
                        adresseTun: res.data.adresse,
                        selectedNivBase: res.data.nivBase,
                        profession: res.data.profession,
                        adresseParents: res.data.adr_pere,
                        telBureau: res.data.tel_pere,
                        nomPere: res.data.nom_pere,
                         fb: res.data.fb
                    })
                })
            })
        }
        else {
            this.setState({
                type: '',
                numSelonType: '',
                nationalite: '',
                dateDelivSelonType: '',
                villeDelivreSelonType: '',
                dateNaissance: '',
                lieuNaissance: '',
                mobile: '',
                tel: '',
                adresseTun: '',
                selectedNivBase: '',
                fb: '',
                profession: '',
                adresseParents: '',
                telBureau: '',
                nomPere: ''
            });
        }
    };

    addPrenom = (event) => {
        this.setState({
            prenom: event.target.value
        });
    }

    addNom = (event) => {
        this.setState({
            nom: event.target.value
        });
    }

    addNumIns = (event) => {
        this.setState({
            numInscrip: event.target.value
        });
    }

    addType = (selectedType) => {
        this.setState({ selectedType: selectedType });
    }

    addNumSelonType = (event) => {
        this.setState({
            numSelonType: event.target.value
        });
    }

    addDateLivraison = d => {
        this.setState({
            dateDelivSelonType: d
        });
    };

    addVilleDelivreSelonType = (event) => {
        this.setState({
            villeDelivreSelonType: event.target.value
        });
    }

    addNationalite = (event) => {
        this.setState({
            nationalite: event.target.value
        });
    }

    addDateNaissance = (e) => {
        this.setState({
            dateNaissance: e.target.value
        });
       
     
    };

    addLieuNaissance = (event) => {
        this.setState({
            lieuNaissance: event.target.value
        });
    }

    addEmail = (event) => {
        this.setState({
            email: event.target.value
        });
    }

    addGender = (selectedGender) => {
        this.setState({ selectedGender: selectedGender });
    }

    addMobile = (event) => {
        this.setState({
            mobile: event.target.value
        });
    }

    addTel = (event) => {
        this.setState({
            tel: event.target.value
        });
    }

    addAdresseOfficiel = (event) => {
        this.setState({
            adresseOfficiel: event.target.value
        });
    }

    addAdresseTun = (event) => {
        this.setState({
            adresseTun: event.target.value
        });
    }

    addNivBase = selectedNivBase => {
        this.setState({ selectedNivBase });
        console.log(`nivBase selected:`, selectedNivBase);
    };

    addFb = (event) => {
        this.setState({
            fb: event.target.value
        });
    }

    addImageVerso(e) {
        this.setState({ imageVerso: e.target.files })
    }

    addNomPere = (event) => {
        this.setState({
            nomPere: event.target.value
        });
    }

    addAdresseParents = (event) => {
        this.setState({
            adresseParents: event.target.value
        });
    }

    addProfession = (event) => {
        this.setState({
            profession: event.target.value
        });
    }

    addTelBureau = (event) => {
        this.setState({
            telBureau: event.target.value
        });
    }

    addFiliere = selectedFiliere => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (selectedFiliere != null) {
            this.setState({ selectedFiliere }, () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectSpecByFiliere.php',
                    type: 'POST',
                    data: {
                        filiereId: selectedFiliere.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listSpecialites: code_html
                        });
                    }
                });
            })
        }
        else {
            this.setState({
                listSpecialites: []
            })
        }
    };

    addSpecialite = selectedSpecialite => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (selectedSpecialite != null) {
            this.setState({ selectedSpecialite }, () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFormBySpecialite.php',
                    type: 'POST',
                    data: {
                        specialiteId: selectedSpecialite.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listFormations: code_html
                        });
                    }
                });
            })
        }
        else {
            this.setState({
                listFormations: []
            })
        }
    };

    addFormation = selectedFormation => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (selectedFormation != null) {
            this.setState({ selectedFormation }, () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectNivByFormation.php',
                    type: 'POST',
                    data: {
                        formationId: selectedFormation.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listNiveaux: code_html
                        });
                    }
                });
            })
        }
        else {
            this.setState({
                listNiveaux: []
            })
        }
    };

    addNiveau = selectedNiveau => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (selectedNiveau != null) {
            this.setState({ selectedNiveau }, () => {
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectGroupByNiv.php?id=' + selectedNiveau.value).then(res => {
                    console.log(res)
                    this.setState({
                        listGroupes: res.data
                    })
                })
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectModPayByNiveau.php',
                    type: 'POST',
                    data: {
                        niveauId: selectedNiveau.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listTypePay: code_html
                        });
                    }
                });
            })
        }
        else {
            this.setState({
                listGroupes: [],
                listTypePay: []
            })
        }
    };

    addGroup = selectedGroup => {
        this.setState({ selectedGroup })
    };

    addDateIns = d => {
        this.setState({
            dateIns: d
        });
    };

    addPapiers = (e, el, index) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            let element = el.id
            this.setState({
                papiersChoisi: [...this.state.papiersChoisi, element]
            });
        }
        else {
            let element = el.id
            let indice = this.state.papiersChoisi.indexOf(element)
            this.state.papiersChoisi.splice(indice, 1)
        }
    }

    addTypePay = (selectedTypePay) => {
        this.setState({ selectedTypePay: selectedTypePay });
        console.log(selectedTypePay)
    }

    ajoutInscrip = () => {
        
        if (this.state.numInscrip != '' && this.state.selectedFormation != '' && this.state.selectedNiveau != '' && this.state.selectedGroup != '' && this.state.selectedTypePay != '') {
            this.setState({ disbutIns: false }, () => {
                // Année scolaire 
                var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                var anneeScolaire = userPermissions[0].anneeScolaire
                // convert dateInscription
                let dateInscrip = this.state.dateIns
                let month = '' + (dateInscrip.getMonth() + 1);
                let day = '' + dateInscrip.getDate();
                let year = dateInscrip.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var convertInscrip = [year, month, day].join('-');
                // convert dateNum
                let dateDelivSelonType = this.state.dateDelivSelonType
                let month_one = '' + (dateDelivSelonType.getMonth() + 1);
                let day_one = '' + dateDelivSelonType.getDate();
                let year_one = dateDelivSelonType.getFullYear();
                if (month_one.length < 2)
                    month_one = '0' + month_one;
                if (day_one.length < 2)
                    day_one = '0' + day_one;
                var convertDateDelivSelonType = [year_one, month_one, day_one].join('-');
                // convert dateNaiss
               /* let dateNaissance = this.state.dateNaissance
                let month_two = '' + (dateNaissance.getMonth() + 1);
                let day_two = '' + dateNaissance.getDate();
                let year_two = dateNaissance.getFullYear();
                if (month_two.length < 2)
                    month_two = '0' + month_two;
                if (day_two.length < 2)
                    day_two = '0' + day_two;
                var convertDateNaissance = [year_two, month_two, day_two].join('-');*/
                // recuperer id user
                var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                var userId = userPermissions[0].idUser
                // Convert array papier to string
                if (this.state.papiersChoisi.length > 0) {
                    var papier = this.state.papiersChoisi.join(',')
                }
                else {
                    var papier = ''
                }
                //
                if (this.state.checked === true) {
                   
                     
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddInscription.php',
                        type: 'POST',
                        data: {
                            // Ajout nv eleve
                            prenom: this.state.prenom,
                            nom: this.state.nom,
                            type: this.state.selectedType,
                            numero: this.state.numSelonType,
                            nationalite: this.state.nationalite,
                            dateType: convertDateDelivSelonType,
                            villeType: this.state.villeDelivreSelonType,
                            dateNaissance:this.state.dateNaissance,
                            lieuNaissance: this.state.lieuNaissance,
                            adresseOfficiel: this.state.adresseOfficiel,
                            adresseTun: this.state.adresseTun,
                            email: this.state.email,
                            sexe: this.state.selectedGender,
                            mobile: this.state.mobile,
                            tel: this.state.tel,
                            nivBaseId: this.state.selectedNivBase.value,
                            fb: this.state.fb,
                            checked: true,
                            // Inscription eleve
                            numInscrip: this.state.numInscrip,
                            dateInscrip: convertInscrip,
                            nomPere: this.state.nomPere,
                            adresseParents: this.state.adresseParents,
                            profession: this.state.profession,
                            telBureau: this.state.telBureau,
                            niveauId: this.state.selectedNiveau.value,
                            groupId: this.state.selectedGroup.value,
                            papier: papier,
                            typePaiement: this.state.selectedTypePay,
                            anneeScolaire: anneeScolaire,
                            userId: userId
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            if (code_html.Result == "OK") {
                                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectModePaiement.php').then(res => {
                                    console.log(res)
                                    this.setState({
                                        listModPay: res.data
                                    })
                                })
                                var eleveId = code_html.eleveId
                                this.setState({
                                    blockReglement: true,
                                    ajoutInscrip: true,
                                    eleveId: eleveId
                                }, () => {
                                    this.setState({
                                        listFrais: code_html.listFrais
                                    });
                                });
                            }
                            else if (code_html.Result == "KO") {
                                alert("erreur,l'un de vos champs est vide!!");
                            }
                        }
                    });
                
                }
                else {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddInscription.php',
                        type: 'POST',
                        data: {
                            // Mise a jour ancien eleve
                            type: this.state.selectedType,
                            numero: this.state.numSelonType,
                            nationalite: this.state.nationalite,
                            dateType: convertDateDelivSelonType,
                            villeType: this.state.villeDelivreSelonType,
                            dateNaissance: this.state.dateNaissance,
                            lieuNaissance: this.state.lieuNaissance,
                            adresseTun: this.state.adresseTun,
                            mobile: this.state.mobile,
                            tel: this.state.tel,
                            nivBaseId: this.state.selectedNivBase.value,
                            fb: this.state.fb,
                            checked: false,
                            // Inscription eleve
                            numInscrip: this.state.numInscrip,
                            dateInscrip: convertInscrip,
                            eleveId: this.state.selectedApprenant.value,
                            nomPere: this.state.nomPere,
                            adresseParents: this.state.adresseParents,
                            profession: this.state.profession,
                            telBureau: this.state.telBureau,
                            niveauId: this.state.selectedNiveau.value,
                            groupId: this.state.selectedGroup.value,
                            papier: papier,
                            typePaiement: this.state.selectedTypePay,
                            anneeScolaire: anneeScolaire,
                            userId: userId
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            if (code_html.Result == "OK") {
                                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectModePaiement.php').then(res => {
                                    console.log(res)
                                    this.setState({
                                        listModPay: res.data
                                    })
                                })
                                var eleveId = code_html.eleveId
                                this.setState({
                                    blockReglement: true,
                                    ajoutInscrip: true,
                                    eleveId: eleveId
                                }, () => {
                                    this.setState({
                                        listFrais: code_html.listFrais
                                    });
                                });
                            }
                            else if (code_html.Result == "KO") {
                                alert("erreur,l'un de vos champs est vide!!");
                            }
                        }
                    });
                }
            });
        }
        else {
            toast.error("⛔ Veuillez vérifier les champs N° inscription,formation,niveau,groupe et paiement !", { containerId: 'A' });
        }
    }

    addTypePayFrais = selectedTypePayFrais => {
        this.setState({ selectedTypePayFrais });
        console.log(`typePayFrais selected:`, selectedTypePayFrais);
    };

    addMntFraisPayer = (event, indexMntFraisPayer) => {
        var mntFraisPayer = event.target.value
        var mntFrais = parseInt(this.state.listFrais[indexMntFraisPayer].mntFrais)
        if (mntFrais >= mntFraisPayer) {
            this.setState({
                listFrais: this.state.listFrais.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntFraisPayer: mntFraisPayer }) : el))
            }, () => {
                var mntFraisRestant = mntFrais - mntFraisPayer
                this.setState({
                    listFrais: this.state.listFrais.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntFraisRestant: Number.parseFloat(mntFraisRestant).toFixed(3) }) : el))
                });
                var sommeMntFraisPayer = 0;
                for (var i = 0; i < this.state.listFrais.length; i++) {
                    if (this.state.listFrais[i].mntFraisPayer != "") {
                        sommeMntFraisPayer += parseInt(this.state.listFrais[i].mntFraisPayer)
                    }
                    else {
                        sommeMntFraisPayer += 0
                    }
                }
                this.setState({
                    mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                });
            })
        }
        else {
            toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
        }
    }

    addPayTotFrais = (e, index) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayFrais = this.state.listFrais
            var mntFraisPayer = arrayFrais[index].mntFraisRestant
            var elFrais = arrayFrais[index]
            elFrais: Object.assign(elFrais, { mntFraisPayer: mntFraisPayer })
            this.setState({
                listFrais: arrayFrais
            }, () => {
                var mntFraisRestant = this.state.listFrais[index].mntFrais - this.state.listFrais[index].mntFraisPayer
                var arrayFrais = this.state.listFrais
                var elFrais = arrayFrais[index]
                elFrais: Object.assign(elFrais, { mntFraisRestant: Number.parseFloat(mntFraisRestant).toFixed(3) })
                this.setState({
                    listFrais: arrayFrais
                }, () => {
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.listFrais.length; i++) {
                        if (this.state.listFrais[i].mntFraisPayer != "") {
                            sommeMntFraisPayer += parseInt(this.state.listFrais[i].mntFraisPayer)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                });
            });
        }
        else {
            var arrayFrais = this.state.listFrais
            var mntFraisPayer = 0
            var elFrais = arrayFrais[index]
            elFrais: Object.assign(elFrais, { mntFraisPayer: mntFraisPayer })
            this.setState({
                listFrais: arrayFrais
            }, () => {
                var mntFraisRestant = this.state.listFrais[index].mntFrais - this.state.listFrais[index].mntFraisPayer
                var arrayFrais = this.state.listFrais
                var elFrais = arrayFrais[index]
                elFrais: Object.assign(elFrais, { mntFraisRestant: Number.parseFloat(mntFraisRestant).toFixed(3) })
                this.setState({
                    listFrais: arrayFrais
                }, () => {
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.listFrais.length; i++) {
                        if (this.state.listFrais[i].mntFraisPayer != "") {
                            sommeMntFraisPayer += parseInt(this.state.listFrais[i].mntFraisPayer)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                });
            });
        }
    }

    additionLign = () => {
        let element = { modPay: '', mntModPay: '', numCheque: '', echeanceChec: new Date(), dateEncaiss: new Date(), titulaireChec: '', banque: '' }
        this.setState({
            tabModPay: [...this.state.tabModPay, element]
        })
    }

    addModPay = (selectedModPay, indexModPay) => {
        if (selectedModPay != null) {
            let modPay = selectedModPay;
            this.setState({
                tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { modPay: modPay }) : el))
            });
        }
    }

    addMntModPay = (event, indexModPay) => {
        let mntModPay = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { mntModPay: mntModPay }) : el))
        });
    }

    addNumChec = (event, indexModPay) => {
        let numCheque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { numCheque: numCheque }) : el))
        });
    }

    addEcheanceChec = (d, indexModPay) => {
        let dateChec = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { echeanceChec: dateChec }) : el))
        });
    }

    addDateEncaiss = (d, indexModPay) => {
        let dateEncaiss = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { dateEncaiss: dateEncaiss }) : el))
        });
    }

    addTitulaireChec = (event, indexModPay) => {
        let titulaireChec = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { titulaireChec: titulaireChec }) : el))
        });
    }

    addBanque = (event, indexModPay) => {
        let banque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { banque: banque }) : el))
        });
    }

    toggleLign = (index) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            indexLignASupprimer: index
        }));
    }

    deleteLign = () => {
        this.state.tabModPay.splice(this.state.indexLignASupprimer, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteLign = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    ajoutRegInscrip = () => {
        var sommePaiement = 0
        sommePaiement = parseInt(this.state.mntFraisTotalPayer)
        var sommeReg = 0
        for (var i = 0; i < this.state.tabModPay.length; i++) {
            sommeReg += parseInt(this.state.tabModPay[i].mntModPay)
        }
        if (sommePaiement !== sommeReg) {
            toast.error('⛔ Veuillez vérifier les sommes saisie !', { containerId: 'A' });
        }
        else {
            this.setState({ disbutReg: false }, () => {
                var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                var anneeScolaire = userPermissions[0].anneeScolaire
                var userId = userPermissions[0].idUser
                var arrayModPay = []
                for (var i = 0; i < this.state.tabModPay.length; i++) {
                    arrayModPay.push(this.state.tabModPay[i].modPay.value)
                }
                // convert dateInscription
                let dateInscrip = this.state.dateIns
                let month = '' + (dateInscrip.getMonth() + 1);
                let day = '' + dateInscrip.getDate();
                let year = dateInscrip.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var convertInscrip = [year, month, day].join('-');
                // Test si photo vide
                var pathImageVerso = '';
                if (this.state.imageVerso != null) {
                    pathImageVerso = this.state.eleveId
                }
                //
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddRegInscrip.php',
                    type: 'POST',
                    data: {
                        idInscrip: this.state.numInscrip,
                        eleveId: this.state.eleveId,
                        photo: pathImageVerso,
                        regFrais: this.state.listFrais,
                        modPay: this.state.tabModPay,
                        groupId: this.state.selectedGroup.value,
                        typePaiement: this.state.selectedTypePay,
                        anneeScolaire: anneeScolaire,
                        userId: userId,
                        dateReg: convertInscrip,
                        montantTotal: sommePaiement
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        if (code_html.Result == "OK") {
                            var idRegFor = code_html.idRegFor
                            //
                            var incluEsp = false
                            var incluChec = false
                            // selon le type de paiement 
                            jQuery.ajax({
                                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ApprenantsTypePayFor.php',
                                type: 'POST',
                                data: {
                                    idRegFor: idRegFor
                                },
                                dataType: 'json',
                                success: (code_html, statut) => {
                                    this.setState({
                                        arrayModePaiement: code_html
                                    }, () => {
                                        if (this.state.arrayModePaiement.includes('1') || this.state.arrayModePaiement.includes('3')) {
                                            incluEsp = true
                                        }
                                        if (this.state.arrayModePaiement.includes('2')) {
                                            incluChec = true
                                        }
                                        if (incluChec == true) {
                                            // Recu frais
                                            jQuery.ajax({
                                                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegForFraisChec.php',
                                                type: 'POST',
                                                data: {
                                                    idRegFor: idRegFor
                                                },
                                                dataType: 'json',
                                                success: (code_html, statut) => {
                                                    var server_path = code_html.server_path;
                                                    var file_path = code_html.file_path
                                                    var path = server_path + file_path;
                                                    window.location.href = path
                                                }
                                            });
                                            // Upload photo
                                            if (this.state.imageVerso != null) {
                                                let files = this.state.imageVerso;
                                                console.warn('data files', files)
                                                let reader = new FileReader();
                                                reader.readAsDataURL(files[0])
                                                reader.onload = (e) => {
                                                    console.log(e)
                                                    jQuery.ajax({
                                                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UploadphotoAppr.php',
                                                        type: 'POST',
                                                        data: {
                                                            file: e.target.result,
                                                            nomfile: this.state.eleveId
                                                        },
                                                        dataType: 'json',
                                                        success: (code_html, statut) => {
                                                        }
                                                    }
                                                    )
                                                }
                                                this.props.history.push('/ReglApprenant');
                                            }
                                            else {
                                                this.props.history.push('/ReglApprenant');
                                            }
                                        }
                                        else if (incluEsp == true) {
                                            // Recu frais
                                            jQuery.ajax({
                                                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegForFraisEsp.php',
                                                type: 'POST',
                                                data: {
                                                    idRegFor: idRegFor
                                                },
                                                dataType: 'json',
                                                success: (code_html, statut) => {
                                                    var server_path = code_html.server_path;
                                                    var file_path = code_html.file_path
                                                    var path = server_path + file_path;
                                                    window.location.href = path
                                                }
                                            });
                                            // Upload photo
                                            if (this.state.imageVerso != null) {
                                                let files = this.state.imageVerso;
                                                console.warn('data files', files)
                                                let reader = new FileReader();
                                                reader.readAsDataURL(files[0])
                                                reader.onload = (e) => {
                                                    console.log(e)
                                                    jQuery.ajax({
                                                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UploadphotoAppr.php',
                                                        type: 'POST',
                                                        data: {
                                                            file: e.target.result,
                                                            nomfile: this.state.eleveId
                                                        },
                                                        dataType: 'json',
                                                        success: (code_html, statut) => {
                                                        }
                                                    }
                                                    )
                                                }
                                                this.props.history.push('/ReglApprenant');
                                            }
                                            else {
                                                this.props.history.push('/ReglApprenant');
                                            }
                                        }
                                    });
                                }
                            })
                        }
                        else {
                            alert("prbl lors de l'insertion")
                        }
                    }


                    
                });
            })
        }
    
       
    

    }
    

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Inscription formation</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="row">
                                    <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                                        <span style={{ marginLeft: '15px', marginRight: '10px' }}>Apprenant introuvable dans la liste!!!! Ajouter un Apprenant</span>
                                        <Switch offColor='#FF0000' onChange={this.handleChange} checked={this.state.checked} />
                                    </label>
                                </div>
                                {this.state.checked === false ?
                                    (<Fragment>
                                        <h3>Informations apprenant :</h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Apprenant</label>
                                                    <Select
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={this.addApprenant}
                                                        options={this.state.listApprenants}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Date naissance</label>
                                                <div className="form-group">
                                                <input type='text' class="form-control " style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }}
                                                        value={this.state.dateNaissance}
                                                       
                                                        onChange={this.addDateNaissance}
                                                        placeholder="2022-01-31"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Lieu de naissance</label>
                                                    <input type="text" className="form-control" value={this.state.lieuNaissance} onChange={this.addLieuNaissance} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Nationalité</label>
                                                    <input type="text" className="form-control" value={this.state.nationalite} onChange={this.addNationalite} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Type</label>
                                                <RadioGroup
                                                    name="type"
                                                    selectedValue={this.state.selectedType}
                                                    onChange={this.addType}>
                                                    <label style={{ paddingRight: '5px' }}>
                                                        <Radio value="CIN" />CIN
                                                    </label>
                                                    <label style={{ paddingRight: '5px' }}>
                                                        <Radio value="Passeport" />Passeport
                                                    </label>
                                                </RadioGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Numero</label>
                                                    <input type="text" className="form-control" value={this.state.numSelonType} onChange={this.addNumSelonType} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Delivré le</label>
                                                <div className="form-group">
                                                    <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                        value={this.state.dateDelivSelonType}
                                                        selected={this.state.dateDelivSelonType}
                                                        onChange={this.addDateLivraison}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Delivré ville de</label>
                                                    <input type="text" className="form-control" value={this.state.villeDelivreSelonType} onChange={this.addVilleDelivreSelonType} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Mobile</label>
                                                    <input type="text" className="form-control" value={this.state.mobile} onChange={this.addMobile} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Tel</label>
                                                    <input type="text" className="form-control" value={this.state.tel} onChange={this.addTel} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Adresse en tunisie</label>
                                                    <input type="text" className="form-control" value={this.state.adresseTun} onChange={this.addAdresseTun} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Niveau de base</label>
                                                    <Select
                                                        value={this.state.selectedNivBase}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={this.addNivBase}
                                                        options={this.state.listNivBase}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Facebook</label>
                                                    <input type="text" className="form-control" value={this.state.fb} onChange={this.addFb} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>) : (null)}
                                {this.state.checked === true ?
                                    (<Fragment>
                                        <h3>Informations apprenant :</h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Prénom</label>
                                                    <input type="text" className="form-control" placeholder="Prénom" onChange={this.addPrenom} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Nom</label>
                                                    <input type="text" className="form-control" placeholder="Nom" onChange={this.addNom} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Num d'inscription</label>
                                                    <input type="text" className="form-control" placeholder="Numero d'inscription" onChange={this.addNumIns} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Photo verso </label>
                                                    <input type="file" className="form-control" name="file" onChange={(e) => this.addImageVerso(e)} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Sexe</label>
                                                <RadioGroup
                                                    name="sexe"
                                                    selectedValue={this.state.selectedGender}
                                                    onChange={this.addGender}>
                                                    <label style={{ paddingRight: '5px' }}>
                                                        <Radio value="Masculin" />Masculin
                                                    </label>
                                                    <label style={{ paddingRight: '5px' }}>
                                                        <Radio value="Feminin" />Feminin
                                                    </label>
                                                </RadioGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Type</label>
                                                <RadioGroup
                                                    name="typePap"
                                                    selectedValue={this.state.selectedType}
                                                    onChange={this.addType}>
                                                    <label style={{ paddingRight: '5px' }}>
                                                        <Radio value="CIN" />CIN
                                                    </label>
                                                    <label style={{ paddingRight: '5px' }}>
                                                        <Radio value="Passeport" />Passeport
                                                    </label>
                                                </RadioGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Numero</label>
                                                    <input type="text" className="form-control" placeholder="Numero selon le type" onChange={this.addNumSelonType} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Nationalité</label>
                                                    <input type="text" className="form-control" placeholder="Nationalité" onChange={this.addNationalite} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Delivré le</label>
                                                <div className="form-group">
                                                    <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                        selected={this.state.dateDelivSelonType}
                                                        onChange={this.addDateLivraison}
                                                        dateFormat="dd/MM/yyy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Delivré ville de</label>
                                                    <input type="text" className="form-control" placeholder="Delivré dans ville de" onChange={this.addVilleDelivreSelonType} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Date naissance</label>
                                                <div className="form-group">
                                                <input type='text' class="form-control " style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }}
                                                       value={this.state.dateNaissance}
                                                        onChange={this.addDateNaissance}
                                                        placeholder="2022-01-31"
                                                       
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Lieu de naissance</label>
                                                    <input type="text" className="form-control" placeholder="Lieu de naissance" onChange={this.addLieuNaissance} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Adresse officiel</label>
                                                    <input type="text" className="form-control" placeholder="Adresse officiel" onChange={this.addAdresseOfficiel} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Adresse en tunisie</label>
                                                    <input type="text" className="form-control" placeholder="Adresse en tunisie" onChange={this.addAdresseTun} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="text" className="form-control" placeholder="Email" onChange={this.addEmail} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Mobile</label>
                                                    <input type="text" className="form-control" placeholder="Mobile" onChange={this.addMobile} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Tel</label>
                                                    <input type="text" className="form-control" placeholder="Tel" onChange={this.addTel} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Niveau de base</label>
                                                    <Select
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={this.addNivBase}
                                                        options={this.state.listNivBase}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Facebook</label>
                                                    <input type="text" className="form-control" placeholder="Facebook" onChange={this.addFb} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>) : (null)}
                                <h3>Informations tuteur :</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom complet du père</label>
                                            <input type="text" className="form-control" placeholder="Nom pére" value={this.state.nomPere} onChange={this.addNomPere} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Adresse des parents</label>
                                            <input type="text" className="form-control" placeholder="Adresse parents" value={this.state.adresseParents} onChange={this.addAdresseParents} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Profession</label>
                                            <input type="text" className="form-control" placeholder="Profession" value={this.state.profession} onChange={this.addProfession} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tél.Bur</label>
                                            <input type="text" className="form-control" placeholder="Tel bureau" value={this.state.telBureau} onChange={this.addTelBureau} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                </div>
                                <h3>Informations formation :</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Filiére</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addFiliere}
                                                options={this.state.listFilieres}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Specialité</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addSpecialite}
                                                options={this.state.listSpecialites}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Formation</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addFormation}
                                                options={this.state.listFormations}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addNiveau}
                                                options={this.state.listNiveaux}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Groupe</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addGroup}
                                                options={this.state.listGroupes}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date inscription</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.dateIns}
                                                onChange={this.addDateIns}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: '20px' }} >
                                    <div className="col-md-6">
                                        <label>Papiers Apportées</label>
                                        <div className="form-group">
                                            {this.state.listPapiers.map((el, index) =>
                                                <label style={{ paddingRight: '5px' }}>
                                                    <Checkbox
                                                        onChange={(e) => this.addPapiers(e, el, index)}
                                                        disabled={this.state.disabled}
                                                    />
                                                    {el.papier}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Paiement</label>
                                        <div className="form-group">
                                            <RadioGroup
                                                name="typePay"
                                                selectedValue={this.state.selectedTypePay}
                                                onChange={this.addTypePay}>
                                                <div style={{ display: 'flex' }}>
                                                    {this.state.listTypePay.map(el =>
                                                        <label style={{ paddingRight: '5px' }}>
                                                            <Radio value={el.id} />{el.type}
                                                        </label>
                                                    )}
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div>
                                {this.state.blockReglement == true ?
                                    (
                                        <Fragment>
                                            <h3>Paiement Frais Inscription :</h3>
                                            <div className="row">
                                                <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant payé</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.listFrais.map((el, index) =>
                                                        <tbody>
                                                            <tr>
                                                                <td className="col-md-4" style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.frais}</p></td>
                                                                <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" className='mntFrais' disabled value={el.mntFrais} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" className='mntFraisPayer' value={el.mntFraisPayer} onChange={(e) => this.addMntFraisPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled className='mntFraisRestant' value={el.mntFraisRestant} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                <td className="col-md-2">
                                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <Checkbox
                                                                            onChange={(e) => this.addPayTotFrais(e, index)}
                                                                            disabled={this.state.disabledFrais}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </table>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                            <td className="col-md-4" style={{ textAlign: 'center', fontWeight: 'bold' }}>Montant total frais payer</td>
                                                            <td className="col-md-2"></td>
                                                            <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.state.mntFraisTotalPayer}</td>
                                                            <td className="col-md-2"></td>
                                                            <td className="col-md-2"></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <h3 style={{ paddingLeft: '15px' }}>Mode de paiement :</h3>
                                                <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }} scope="col">Mode paiement</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant Paiement</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Numéro cheque</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Echéance cheque</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Date encaissement</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Titulaire cheque</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Banque</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.tabModPay.map((el, index) =>
                                                        <tbody>
                                                            <tr>
                                                                <td className="col-md-2"><Select
                                                                    value={el.modPay}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    onChange={(e) => this.addModPay(e, index)}
                                                                    options={this.state.listModPay}
                                                                />  </td>
                                                                <td className="col-md-1" style={{ textAlign: 'center' }}><input type="number" value={el.mntModPay} onChange={(e) => this.addMntModPay(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                {this.state.tabModPay[index].modPay.value == 2 ?
                                                                    (<Fragment>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.numCheque} onChange={(e) => this.addNumChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} dateFormat="dd/MM/yyy" selected={el.echeanceChec} onChange={(e) => this.addEcheanceChec(e, index)} /></td>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} dateFormat="dd/MM/yyy" selected={el.dateEncaiss} onChange={(e) => this.addDateEncaiss(e, index)} /></td>
                                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.titulaireChec} onChange={(e) => this.addTitulaireChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.banque} onChange={(e) => this.addBanque(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLign(index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                                    </Fragment>) :
                                                                    (<Fragment>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                        <td className="col-md-1" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} onClick={() => this.toggleLign(index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                                    </Fragment>)}
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </table>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                                    <Button style={{ borderRadius: '5px' }} onClick={this.additionLign} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                                </div>
                                            </div> </Fragment>) : (null)}
                                {this.state.ajoutInscrip === false ?
                                    (<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                        <button disabled={!this.state.disbutIns} style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajoutInscrip}>Confirmer</button>
                                    </div>) : (<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                     
                                      <button disabled={!this.state.disbutReg} style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajoutRegInscrip}>Confirmer</button>
                                    
                                    </div>)}
                                <Modal isOpen={this.state.modal} toggle={this.nonDeleteLign} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteLign}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer ce mode de paiement ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteLign()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteLign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Inscription);