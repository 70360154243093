import React, { Component, Fragment } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

class ModifReglCycleApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initStatus: 1,
            listAppCycle: [],
            selectedAppCycle: '',
            blockRegl: false,
            fraisCycle: [],
            regCycle: '',
            societeCoch: false,
            listModPay: [],
            tabModPay: [{ modPay: '', mntModPay: '', numCheque: '', echeanceChec: new Date(), dateEncaiss: new Date(), titulaireChec: '', banque: '' }],
            indexLignASupprimer: '',
            modal: false,
            datePaiement: '',
            nomComplet: '',
            restant: 1,
            mntFraisCycleTotalPayer: 0,
            nomCycle: '',
            dateDCycle: '',
            dateFCycle: '',
            horaire: '',
            inscripCycle: '',
            disabledReg: false,
            arrayModePaiementCycle: [],
            disbut: true
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectAppInscripCycle.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listAppCycle: code_html
                    })
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    addApp = (selectedAppCycle) => {
        if (selectedAppCycle != null) {
            this.setState(
                {
                    selectedAppCycle: selectedAppCycle,
                    blockRegl: true
                }, () => {
                    axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectModePaiement.php').then(res => {
                        console.log(res)
                        this.setState({
                            listModPay: res.data
                        })
                    })
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetReglCycleByApp.php',
                        type: 'POST',
                        data: {
                            idInsCycle: selectedAppCycle.value
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            this.setState({
                                fraisCycle: code_html[0],
                                regCycle: code_html[1],
                                initStatus: 0
                            }, () => {
                                var nomComplet = code_html[2][0].nomComplet
                                var nomCycle = code_html[2][0].nomCycle
                                var dateDCycle = code_html[2][0].dateDebutCycle
                                var dateFCycle = code_html[2][0].dateFinCycle
                                var horaire = code_html[2][0].horaireCours
                                var inscripCycle = code_html[2][0].idInsCycle
                                this.setState({
                                    nomComplet: nomComplet,
                                    nomCycle: nomCycle,
                                    dateDCycle: dateDCycle,
                                    dateFCycle: dateFCycle,
                                    horaire: horaire,
                                    inscripCycle: inscripCycle
                                });
                            })
                        }
                    });
                });
        }
        else {
            this.setState(
                {
                    selectedAppCycle: '',
                    blockRegl: false,
                    initStatus: 1
                });
        }
    }

    addDatePaiement = d => {
        this.setState({
            datePaiement: d
        });
    };

    updMntFraisPayer = (event, indexMntFraisPayer) => {
        var mntFraisPay = event.target.value
        var mntTotal = parseInt(this.state.fraisCycle[indexMntFraisPayer].mntTotal)
        var mntDejaPayer = parseInt(this.state.fraisCycle[indexMntFraisPayer].mntPay)
        if (mntDejaPayer == 0) {
            if (mntTotal >= mntFraisPay) {
                this.setState({
                    fraisCycle: this.state.fraisCycle.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mnt: mntFraisPay }) : el))
                }, () => {
                    var mntRestNv = mntTotal - mntFraisPay
                    this.setState({
                        fraisCycle: this.state.fraisCycle.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntRest: Number.parseFloat(mntRestNv).toFixed(3) }) : el))
                    });
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.fraisCycle.length; i++) {
                        if (this.state.fraisCycle[i].mnt != "") {
                            sommeMntFraisPayer += parseInt(this.state.fraisCycle[i].mnt)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisCycleTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                })
            }
            else {
                toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
            }
        }
        else if (mntDejaPayer > 0) {
            var restMnt = mntTotal - mntDejaPayer
            if (parseInt(restMnt) >= parseInt(mntFraisPay)) {
                this.setState({
                    fraisCycle: this.state.fraisCycle.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mnt: mntFraisPay }) : el))
                }, () => {
                    if (mntFraisPay > 0) {
                        var mntRestNv = restMnt - mntFraisPay
                        this.setState({
                            fraisCycle: this.state.fraisCycle.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntRest: Number.parseFloat(mntRestNv).toFixed(3) }) : el))
                        });
                    }
                    else if (mntFraisPay == 0) {
                        var mntRestNv = mntTotal - mntDejaPayer
                        this.setState({
                            fraisCycle: this.state.fraisCycle.map((el, id) => (id === indexMntFraisPayer ? Object.assign(el, { mntRest: Number.parseFloat(mntRestNv).toFixed(3) }) : el))
                        });
                    }
                    var sommeMntFraisPayer = 0;
                    for (var i = 0; i < this.state.fraisCycle.length; i++) {
                        if (this.state.fraisCycle[i].mnt != "") {
                            sommeMntFraisPayer += parseInt(this.state.fraisCycle[i].mnt)
                        }
                        else {
                            sommeMntFraisPayer += 0
                        }
                    }
                    this.setState({
                        mntFraisCycleTotalPayer: Number.parseFloat(sommeMntFraisPayer).toFixed(3)
                    });
                })
            }
            else {
                toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
            }
        }
    }

    updMntInscripPayer = (event, indexMntInscripPayer) => {
        let mntPaiement = event.target.value
        let mntAPayer = this.state.regCycle[indexMntInscripPayer].mntAPayer
        let mntDejaPayer = this.state.regCycle[indexMntInscripPayer].mntPayer
        if (mntDejaPayer == 0) {
            if (parseInt(mntAPayer) >= parseInt(mntPaiement)) {
                this.setState({
                    regCycle: this.state.regCycle.map((el, id) => (id === indexMntInscripPayer ? Object.assign(el, { mnt: mntPaiement }) : el))
                }, () => {
                    let mntInscripRestNv = mntAPayer - mntPaiement
                    this.setState({
                        regCycle: this.state.regCycle.map((el, id) => (id === indexMntInscripPayer ? Object.assign(el, { mntRestant: Number.parseFloat(mntInscripRestNv).toFixed(3) }) : el))
                    });
                    let mntIncripTotalPayer = 0
                    for (var i = 0; i < this.state.regCycle.length; i++) {
                        mntIncripTotalPayer += parseInt(this.state.regCycle[i].mnt)
                    }
                    this.setState({
                        mntIncripTotalPayer: Number.parseFloat(mntIncripTotalPayer).toFixed(3)
                    });
                })
            }
            else {
                toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
            }
        }
        else if (mntDejaPayer > 0) {
            var restMnt = mntAPayer - mntDejaPayer
            if (parseInt(restMnt) >= parseInt(mntPaiement)) {
                this.setState({
                    regCycle: this.state.regCycle.map((el, id) => (id === indexMntInscripPayer ? Object.assign(el, { mnt: mntPaiement }) : el))
                }, () => {
                    if (mntPaiement > 0) {
                        var mntInscripRestNv = restMnt - mntPaiement
                        this.setState({
                            regCycle: this.state.regCycle.map((el, id) => (id === indexMntInscripPayer ? Object.assign(el, { mntRestant: Number.parseFloat(mntInscripRestNv).toFixed(3) }) : el))
                        });
                    }
                    else if (mntPaiement == 0) {
                        var mntInscripRestNv = mntAPayer - mntDejaPayer
                        this.setState({
                            regCycle: this.state.regCycle.map((el, id) => (id === indexMntInscripPayer ? Object.assign(el, { mntRestant: Number.parseFloat(mntInscripRestNv).toFixed(3) }) : el))
                        });
                    }
                    let mntIncripTotalPayer = 0
                    for (var i = 0; i < this.state.regCycle.length; i++) {
                        mntIncripTotalPayer += parseInt(this.state.regCycle[i].mnt)
                    }
                    this.setState({
                        mntIncripTotalPayer: Number.parseFloat(mntIncripTotalPayer).toFixed(3)
                    });
                })
            }
            else {
                toast.error('⛔ Le montant saisie est supérieur !!', { containerId: 'A' });
            }
        };
    }

    addPayTotReg = (e, index) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayReg = this.state.regCycle
            var mnt = arrayReg[index].mntRestant
            var elReg = arrayReg[index]
            elReg: Object.assign(elReg, { mnt: mnt })
            this.setState({
                regCycle: arrayReg
            }, () => {
                var mntRestant = this.state.regCycle[index].mntAPayer - (parseInt(this.state.regCycle[index].mntPayer) + parseInt(this.state.regCycle[index].mnt))
                var arrayReg = this.state.regCycle
                var elReg = arrayReg[index]
                elReg: Object.assign(elReg, { mntRestant: Number.parseFloat(mntRestant).toFixed(3) })
                this.setState({
                    regCycle: arrayReg
                });
            });
        }
        else {
            var arrayReg = this.state.regCycle
            var mnt = 0
            var elReg = arrayReg[index]
            elReg: Object.assign(elReg, { mnt: mnt })
            this.setState({
                regCycle: arrayReg
            }, () => {
                var mntRestant = this.state.regCycle[index].mntAPayer - (parseInt(this.state.regCycle[index].mntPayer) + parseInt(this.state.regCycle[index].mnt))
                var arrayReg = this.state.regCycle
                var elReg = arrayReg[index]
                elReg: Object.assign(elReg, { mntRestant: Number.parseFloat(mntRestant).toFixed(3) })
                this.setState({
                    regCycle: arrayReg
                });
            });
        }
    }

    addDatePaiement = d => {
        this.setState({
            datePaiement: d
        });
    };

    additionLign = () => {
        let element = { modPay: '', mntModPay: '', numCheque: '', echeanceChec: new Date(), dateEncaiss: new Date(), titulaireChec: '', banque: '' }
        this.setState({
            tabModPay: [...this.state.tabModPay, element]
        })
    }

    addModPay = (selectedModPay, indexModPay) => {
        if (selectedModPay != null) {
            let modPay = selectedModPay;
            this.setState({
                tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { modPay: modPay }) : el))
            });
        }
    }

    addMntModPay = (event, indexModPay) => {
        let mntModPay = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { mntModPay: mntModPay }) : el))
        });
    }

    addNumChec = (event, indexModPay) => {
        let numCheque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { numCheque: numCheque }) : el))
        });
    }

    addEcheanceChec = (d, indexModPay) => {
        let dateChec = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { echeanceChec: dateChec }) : el))
        });
    }

    addDateEncaiss = (d, indexModPay) => {
        let dateEncaiss = d;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { dateEncaiss: dateEncaiss }) : el))
        });
    }

    addTitulaireChec = (event, indexModPay) => {
        let titulaireChec = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { titulaireChec: titulaireChec }) : el))
        });
    }

    addBanque = (event, indexModPay) => {
        let banque = event.target.value;
        this.setState({
            tabModPay: this.state.tabModPay.map((el, id) => (id === indexModPay ? Object.assign(el, { banque: banque }) : el))
        });
    }

    toggleLign = (index) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            indexLignASupprimer: index
        }));
    }

    deleteLign = () => {
        this.state.tabModPay.splice(this.state.indexLignASupprimer, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteLign = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    ajoutRegCycle = () => {
        if (this.state.datePaiement != '') {
            var sommeReg = 0
            for (var i = 0; i < this.state.tabModPay.length; i++) {
                sommeReg += parseInt(this.state.tabModPay[i].mntModPay)
            }
            var sommePaiement = 0
            var sommeFrais = 0
            for (var j = 0; j < this.state.fraisCycle.length; j++) {
                sommeFrais += parseInt(this.state.fraisCycle[j].mnt)
            }
            var sommeCycle = 0
            for (var w = 0; w < this.state.regCycle.length; w++) {
                sommeCycle += parseInt(this.state.regCycle[w].mnt)
            }
            sommePaiement = sommeFrais + sommeCycle
            if (sommePaiement !== sommeReg) {
                toast.error('⛔ Veuillez vérifier la somme saisie !', { containerId: 'A' });
            }
            else {
                this.setState({ disbut: false }, () => {
                    var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                    var anneeScolaire = userPermissions[0].anneeScolaire
                    var userId = userPermissions[0].idUser
                    // convert date paiement
                    let datePaiement = this.state.datePaiement
                    let month = '' + (datePaiement.getMonth() + 1);
                    let day = '' + datePaiement.getDate();
                    let year = datePaiement.getFullYear();
                    if (month.length < 2)
                        month = '0' + month;
                    if (day.length < 2)
                        day = '0' + day;
                    var convertDatePaiement = [year, month, day].join('-');
                    //
                    var arrayModPay = []
                    for (var i = 0; i < this.state.tabModPay.length; i++) {
                        arrayModPay.push(this.state.tabModPay[i].modPay.value)
                    }
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdRegAppCycl.php',
                        type: 'POST',
                        data: {
                            dateReg: convertDatePaiement,
                            idInscrip: this.state.inscripCycle,
                            fraisCycle: this.state.fraisCycle,
                            regCycle: this.state.regCycle,
                            modPay: this.state.tabModPay,
                            // dateEcheChec: convertDateEcheChec,
                            // dateEncaissChec: convertDateEncaissChec,
                            anneeScolaire: anneeScolaire,
                            userId: userId,
                            montantTotal: sommeReg
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            // Recu reglement cycle
                            var idRegCycle = code_html.idRegCycle
                            var incluEspCycle = false
                            var incluChecCycle = false
                            jQuery.ajax({
                                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ApprenantsTypePayCycle.php',
                                type: 'POST',
                                data: {
                                    idRegCycle: idRegCycle
                                },
                                dataType: 'json',
                                success: (code_html, statut) => {
                                    this.setState({
                                        arrayModePaiementCycle: code_html
                                    }, () => {
                                        if (this.state.arrayModePaiementCycle.includes('1') || this.state.arrayModePaiementCycle.includes('3')) {
                                            incluEspCycle = true
                                        }
                                        if (this.state.arrayModePaiementCycle.includes('2')) {
                                            incluChecCycle = true
                                        }
                                        if (incluChecCycle == true) {
                                            jQuery.ajax({
                                                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegCycleChec.php',
                                                type: 'POST',
                                                data: {
                                                    idRegCycle: idRegCycle
                                                },
                                                dataType: 'json',
                                                success: (code_html, statut) => {
                                                    var server_path = code_html.server_path;
                                                    var file_path = code_html.file_path
                                                    var path = server_path + file_path;
                                                    window.open(path, "_blank")
                                                    this.props.history.push('/ReglApprenantCycle');
                                                }
                                            });
                                        }
                                        else if (incluEspCycle = true) {
                                            jQuery.ajax({
                                                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegCycleEsp.php',
                                                type: 'POST',
                                                data: {
                                                    idRegCycle: idRegCycle
                                                },
                                                dataType: 'json',
                                                success: (code_html, statut) => {
                                                    var server_path = code_html.server_path;
                                                    var file_path = code_html.file_path
                                                    var path = server_path + file_path;
                                                    window.open(path, "_blank")
                                                    this.props.history.push('/ReglApprenantCycle');
                                                }
                                            })
                                        }
                                    })
                                }
                            });
                        }
                    });
                })
            }
        }
        else {
            toast.error('⛔ Veuillez saisir le champs date de paiement !!', { containerId: 'A' });
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Mise à jour du reglement apprenant cycle</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/ReglApprenantCycle" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Apprenant</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addApp}
                                                options={this.state.listAppCycle}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date paiement</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.datePaiement}
                                                onChange={this.addDatePaiement}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {this.state.fraisCycle.length > 0 || this.state.regCycle.length > 0 ?
                                    (<Fragment>
                                        {this.state.fraisCycle.length > 0 ?
                                            (<Fragment>
                                                <h3 style={{ marginTop: '10px' }}>Paiement Frais cycle :</h3>
                                                <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant deja payé</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant a payé</th>
                                                            <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                                        </tr>
                                                    </thead>
                                                    {this.state.fraisCycle.map((el, index) =>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.nomFrais}</p></td>
                                                                <td style={{ textAlign: 'center' }}><input type="number" disabled value={el.mntTotal} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                <td style={{ textAlign: 'center' }}><input type="number" disabled value={el.mntPay} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                <td style={{ textAlign: 'center' }}><input type="number" value={el.mnt} onChange={(e) => this.updMntFraisPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                <td style={{ textAlign: 'center' }}><input type="number" disabled value={el.mntRest} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </table>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                            <td className="col-md-4" style={{ textAlign: 'center', fontWeight: 'bold' }}>Montant total frais payer</td>
                                                            <td className="col-md-2"></td>
                                                            <td className="col-md-2"></td>
                                                            <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.state.mntFraisCycleTotalPayer}</td>
                                                            <td className="col-md-2"></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Fragment>) : (<Fragment>
                                                <h3>Paiement frais cycle :</h3>
                                                <p style={{ paddingLeft: '15px' }}>{"L'eléve " + this.state.nomComplet + " a payé la totalité des frais du cycle"}</p>
                                            </Fragment>)}

                                        {this.state.regCycle.length > 0 ?
                                            (<Fragment>
                                                <h3 style={{ marginTop: '10px' }}>Paiement cycle :</h3>
                                                <div className='row'>
                                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'center' }} scope="col">Infos Cycle</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Montant</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Montant deja payé</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Montant a payé</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                                                <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                                            </tr>
                                                        </thead>
                                                        {this.state.regCycle.map((elm, index) =>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="col-md-3" style={{ textAlign: 'center' }}><p>{"cycle: " + this.state.nomCycle}</p><p>{"Période: " + this.state.dateDCycle + "---" + this.state.dateFCycle + " " + this.state.horaire}</p></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" className="form-control" disabled value={Number.parseFloat(elm.mntAPayer).toFixed(3)} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3', textAlign: 'center' }} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" className="form-control" disabled value={Number.parseFloat(elm.mntPayer).toFixed(3)} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3', textAlign: 'center' }} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={elm.mnt} onChange={(e) => this.updMntInscripPayer(e, index)} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3', textAlign: 'center' }} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={Number.parseFloat(elm.mntRestant).toFixed(3)} style={{ borderRadius: '5px', border: 'solid 1px', borderColor: '#B3B3B3', textAlign: 'center' }} /></td>
                                                                    <td className="col-md-1">
                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <Checkbox
                                                                                onChange={(e) => this.addPayTotReg(e, index)}
                                                                                disabled={this.state.disabledReg}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </div>
                                            </Fragment>) : (<Fragment>
                                                <h3>Paiement cycle :</h3>
                                                <p style={{ paddingLeft: '15px' }}>{"L'eléve " + this.state.nomComplet + " a payé la totalité du montant du cycle"}</p>
                                            </Fragment>)}

                                        <div className='row'>
                                            <h3 style={{ paddingLeft: '15px' }}>Mode de paiement :</h3>
                                            <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: 'center' }} scope="col">Mode paiement</th>
                                                        <th style={{ textAlign: 'center' }} scope="col">Montant Paiement</th>
                                                        <th style={{ textAlign: 'center' }} scope="col">Numéro cheque</th>
                                                        <th style={{ textAlign: 'center' }} scope="col">Echéance cheque</th>
                                                        <th style={{ textAlign: 'center' }} scope="col">Date encaissement</th>
                                                        <th style={{ textAlign: 'center' }} scope="col">Titulaire cheque</th>
                                                        <th style={{ textAlign: 'center' }} scope="col">Banque</th>
                                                        <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                                    </tr>
                                                </thead>
                                                {this.state.tabModPay.map((el, index) =>
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-md-2"><Select
                                                                value={el.modPay}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={(e) => this.addModPay(e, index)}
                                                                options={this.state.listModPay}
                                                            />  </td>
                                                            <td className="col-md-1" style={{ textAlign: 'center' }}><input type="number" value={el.mntModPay} onChange={(e) => this.addMntModPay(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                            {this.state.tabModPay[index].modPay.value == 2 ?
                                                                (<Fragment>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.numCheque} onChange={(e) => this.addNumChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} dateFormat="dd/MM/yyy" selected={el.echeanceChec} onChange={(e) => this.addEcheanceChec(e, index)} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} dateFormat="dd/MM/yyy" selected={el.dateEncaiss} onChange={(e) => this.addDateEncaiss(e, index)} /></td>
                                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.titulaireChec} onChange={(e) => this.addTitulaireChec(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" value={el.banque} onChange={(e) => this.addBanque(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleLign(index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                                </Fragment>) :
                                                                (<Fragment>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><input type="text" disabled style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                    <td className="col-md-1" style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleLign(index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                                </Fragment>)}
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </table>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                            <Button style={{ borderRadius: '5px' }} onClick={this.additionLign} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                            <button disabled={!this.state.disbut} style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajoutRegCycle}>Confirmer</button>
                                        </div>
                                    </Fragment>) : this.state.initStatus == 1 ? (<p>Veuillez choisir un apprenant</p>) : (<Fragment>
                                        <h3 style={{ paddingLeft: '15px' }}>Paiement cycle :</h3>
                                        <p style={{ paddingLeft: '15px' }}>{"L'eléve " + this.state.nomComplet + " a payé la totalité du cycle"}</p>
                                    </Fragment>)}
                            </div>
                        </div>
                    </div>
                    <Modal isOpen={this.state.modal} toggle={this.nonDeleteLign} className={this.props.className} fade={false}>
                        <ModalHeader toggle={this.nonDeleteLign}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                        <ModalBody>
                            <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <p>Êtes-Vous sûr de vouloir supprimer ce mode de paiement ?</p>
                                </div>
                                <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                    <div><button onClick={() => this.deleteLign()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                    <div><button onClick={() => this.nonDeleteLign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                </div>
            </div>
        );
    }
}
export default withRouter(ModifReglCycleApp);