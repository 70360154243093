import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { RadioGroup, Radio } from "react-radio-group";

class ConsAppInscri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apprenantId: "",
      prenom: "",
      nom: "",
      selectedType: "CIN",
      numSelonType: "",
      nationalite: "",
      dateDelivSelonType: "",
      villeDelivreSelonType: "",
      dateNaissance: "",
      lieuNaissance: "",
      email: "",
      selectedGender: "Masculin",
      mobile: "",
      tel: "",
      adresseOfficiel: "",
      adresseTun: "",
      listNivBase: [],
      selectedNivBase: "",
      imageVerso: null,
      selectNiveau: "",
      selectGroupe: "",
      selectSpecialite: "",
      selectFormation: "",
      selectFiliere: "",
      date: "",
      nomPere: "",
      adrPere: "",
      profession: "",
      telPere: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      let idApprenant = this.props.location.state.idApprenant;
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetApprenantById.php?id=" +
            idApprenant
        )
        .then((res) => {
          console.log(res);
          if (res.data.dateNum != "0000-00-00") {
            var dateType = new Date(res.data.dateNum);
          } else {
            var dateType = "";
          }
          this.setState({
            apprenantId: res.data.idEleve,
            prenom: res.data.prenom,
            nom: res.data.nom,
            selectedType: res.data.type,
            numSelonType: res.data.numero,
            nationalite: res.data.nationalite,
            dateDelivSelonType: dateType,
            villeDelivreSelonType: res.data.villeNum,
            dateNaissance: new Date(res.data.naissance),
            lieuNaissance: res.data.villeNaissance,
            adresseOfficiel: res.data.adrOfficiel,
            adresseTun: res.data.adresseTun,
            email: res.data.email,
            selectedGender: res.data.sex,
            mobile: res.data.mobile,
            selectedNivBase: res.data.nivBase,
            tel: res.data.tel,
          });
        });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoAppInscri.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          idApprenant: idApprenant,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            selectNiveau: code_html.niveau,
            selectGroupe: code_html.groupe,
            selectSpecialite: code_html.specialite,
            selectFormation: code_html.formation,
            selectFiliere: code_html.filiere,
            date: new Date(code_html.dateIns),
            nomPere: code_html.nom,
            adrPere: code_html.adrPere,
            profession: code_html.profession,
            telPere: code_html.telPere,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Consultation apprenant</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/ListAppInscri">
                    {" "}
                    <button
                      style={{
                        background: "#DC143C",
                        border: "1px solid #DC143C",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i
                        style={{ paddingRight: "5px" }}
                        className="fas fa-arrow-circle-left"
                      ></i>
                      Retour
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Prénom</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.prenom}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.nom}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Sexe</label>
                    <RadioGroup
                      name="sexe"
                      selectedValue={this.state.selectedGender}
                    >
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="Masculin" />
                        Masculin
                      </label>
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="Feminin" />
                        Feminin
                      </label>
                    </RadioGroup>
                  </div>
                  <div className="col-md-6">
                    <label>Type</label>
                    <RadioGroup
                      name="type"
                      selectedValue={this.state.selectedType}
                    >
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="CIN" />
                        CIN
                      </label>
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="Passeport" />
                        Passeport
                      </label>
                    </RadioGroup>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Numero</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.numSelonType}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nationalité</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.nationalite}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Delivré le </label>
                    <div className="form-group">
                      <DatePicker
                        class="form-control ddate"
                        value={this.state.dateDelivSelonType}
                        style={{ border: "none" }}
                        selected={this.state.dateDelivSelonType}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Delivré ville de</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.villeDelivreSelonType}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Date naissance</label>
                    <div className="form-group">
                      <DatePicker
                        class="form-control ddate"
                        value={this.state.dateNaissance}
                        style={{ border: "none" }}
                        selected={this.state.dateNaissance}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Lieu de naissance</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.lieuNaissance}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Adresse officiel</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.adresseOfficiel}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Adresse en tunisie</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.adresseTun}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.email}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mobile</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.mobile}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tel</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.tel}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Niveau de base</label>
                      <Select
                        value={this.state.selectedNivBase}
                        isClearable={true}
                        isSearchable={true}
                        disabled
                        options={this.state.listNivBase}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Photo verso </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => this.updImageVerso(e)}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h3>Informations tuteur :</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nom complet du père</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nom pére"
                        value={this.state.nomPere}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Adresse des parents</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Adresse parents"
                        value={this.state.adrPere}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Profession</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Profession"
                        value={this.state.profession}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tél.Bur</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tel bureau"
                        value={this.state.telPere}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h3>Informations formation :</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Filiére</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        disabled
                        value={this.state.selectFiliere}
                        options={this.state.listFilieres}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Specialité</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        disabled
                        value={this.state.selectSpecialite}
                        options={this.state.listSpecialites}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Formation</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        disabled
                        value={this.state.selectFormation}
                        options={this.state.listFormations}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Niveau</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        disabled
                        value={this.state.selectNiveau}
                        options={this.state.listNiveaux}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        disabled
                        value={this.state.selectGroupe}
                        options={this.state.listGroupes}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date inscription</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        selected={this.state.date}
                        value={this.state.date}
                        disabled
                        dateFormat="dd/MM/yyy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConsAppInscri);
