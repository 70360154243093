import React, { Component } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class NiveauNatureNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Niveau",
          selector: "niveau",
          sortable: false,
          center: true,
        },
        {
          name: "Explorer",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ExplNivNateNote",
                state: { niveauId: row.niveauNoteId },
              }}
            >
              <button className="buttonExplorer">
                <i style={{ color: "white" }} className="far fa-eye"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: "Modifier",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ModifNivNateNote",
                state: { niveauId: row.niveauNoteId },
              }}
            >
              <button className="buttonModifier">
                <i style={{ color: "white" }} className="fas fa-pencil-alt"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: "Desactiver",
          cell: (row) => (
            <button
              className="buttonSupprimer"
              onClick={() => this.toggletwo(row.niveauNoteId)}
            >
              <i style={{ color: "white" }} className="fas fa-trash-alt"></i>
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      pending: true,
      niveauNoteId: "",
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/NiveauNatureNote.php",
        type: "POST",
        data: {
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
          anneeScolaire: anneeScolaire
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
    }
  }

  newElement(page, totalRows) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/NiveauNatureNote.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: page,
            anneeScolaire: anneeScolaire
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  perPage(currentRowsPerPage, currentPage) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        nbrEltperPage: currentRowsPerPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/NiveauNatureNote.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: currentRowsPerPage,
            page: currentPage,
            anneeScolaire: anneeScolaire
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  filterSearch(event) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/NiveauNatureNote.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  toggletwo = (niveauNoteId) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      niveauNoteId: niveauNoteId,
    }));
    console.log("niveauNoteId", niveauNoteId);
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  deleteNiveauNatureNote() {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var userId = userPermissions[0].idUser;
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/DeleteNiveauNatureNote.php",
      type: "POST",
      data: {
        NiveauNatureNoteId: this.state.niveauNoteId,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modal: false,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/NiveauNatureNote.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Niveau nature note</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/NoteNiveau">
                    {" "}
                    <button
                      style={{
                        background: "#3A3F51",
                        border: "1px solid #3A3F51",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i className="fas fa-plus"></i>Nature note par matiére
                    </button>
                  </Link>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggle}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir supprimer cette filiére ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.deleteNiveauNatureNote()}
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggle()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    );
  }
}

export default NiveauNatureNote;
