import React, { Component, Fragment } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable, { createTheme } from 'react-data-table-component';
import jQuery from 'jquery'
import './paginate.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { RadioGroup, Radio } from 'react-radio-group'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Remboursement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Date',
                    selector: 'date',
                    sortable: false,
                    center: true,
                    style: { width: '15%' }
                },
                {
                    name: 'Eléve',
                    selector: 'nomComplet',
                    sortable: false,
                    center: true,
                    style: { width: '20%' }
                },
                {
                    name: 'Description',
                    cell: row => <div onClick={() => this.toggleFrais(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#FFB6C1', fontSize: '20px' }} className="fas fa-info"></i></div><div style={{ marginLeft: '5px' }}></div>Description</div>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Numéro recu',
                    selector: 'numRecu',
                    sortable: false,
                    center: true,
                    style: { width: '15%' }
                },
                {
                    name: 'Montant',
                    selector: 'mnt',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Mode',
                    selector: 'mode',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                { name: 'Imprimer',
                cell: row => < i style={{ color: '#87B87F', fontSize: '20px' }} className="fas fa-file-alt"  onClick={() => this.imprecu(row)} ></i>
                ,
                   
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Modification',
                    cell: row => <Link to={{
                        pathname: '/ModifRemboursement',
                        state: { idRemboursement: row.idRemboursement }
                    }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Suppression',
                    cell: row => <button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggletwo(row.idRemboursement)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                }
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            modal: false,
            pending: true,
            typeRembours: '',
            descripRembours: '',
            // search
            selectedScol: '',
            listEleve: [],
            selectedEleve: '',
            dateD: '',
            dateF: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var idRoleUser = userPermissions[0].idRoleUser
            if (idRoleUser == 1) {
                var anneeScolaire = userPermissions[0].anneeScolaire
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Remboursement.php',
                    type: 'POST',
                    data: {
                        query: '',
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }
            else {
                localStorage.clear();
                document.location = '/'
            }
        }
        else {
            document.location = '/'
        }
    }

    newElement = (page, totalRows) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedEleve != '') {
            var eleveId = this.state.selectedEleve.value
        }
        else {
            var eleveId = ''
        }
        if ((eleveId == '') && (this.state.dateD == '') && (this.state.dateF == '')) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Remboursement.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
        else {
            if (this.state.dateD != '') {
                // convert date debut 
                let dateD = this.state.dateD
                let month = '' + (dateD.getMonth() + 1);
                let day = '' + dateD.getDate();
                let year = dateD.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var convertDateD = [year, month, day].join('-');
            }
            else {
                var convertDateD = ''
            }
            if (this.state.dateF != '') {
                // convert date fin
                let dateF = this.state.dateF
                let monthOne = '' + (dateF.getMonth() + 1);
                let dayOne = '' + dateF.getDate();
                let yearOne = dateF.getFullYear();
                if (monthOne.length < 2)
                    monthOne = '0' + monthOne;
                if (dayOne.length < 2)
                    dayOne = '0' + dayOne;
                var convertDateF = [yearOne, monthOne, dayOne].join('-');
            }
            else {
                var convertDateF = ''
            }
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RemboursementSearch.php',
                        type: 'POST',
                        data: {
                            eleveId: eleveId,
                            dateD: convertDateD,
                            dateF: convertDateF,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    perPage = (currentRowsPerPage, currentPage) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedEleve != '') {
            var eleveId = this.state.selectedEleve.value
        }
        else {
            var eleveId = ''
        }
        if ((eleveId == '') && (this.state.dateD == '') && (this.state.dateF == '')) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Remboursement.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            );
        }
        else {
            if (this.state.dateD != '') {
                // convert date debut 
                let dateD = this.state.dateD
                let month = '' + (dateD.getMonth() + 1);
                let day = '' + dateD.getDate();
                let year = dateD.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var convertDateD = [year, month, day].join('-');
            }
            else {
                var convertDateD = ''
            }
            if (this.state.dateF != '') {
                // convert date fin
                let dateF = this.state.dateF
                let monthOne = '' + (dateF.getMonth() + 1);
                let dayOne = '' + dateF.getDate();
                let yearOne = dateF.getFullYear();
                if (monthOne.length < 2)
                    monthOne = '0' + monthOne;
                if (dayOne.length < 2)
                    dayOne = '0' + dayOne;
                var convertDateF = [yearOne, monthOne, dayOne].join('-');
            }   
            else {
                var convertDateF = ''
            }
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RemboursementSearch.php',
                        type: 'POST',
                        data: {
                            eleveId: eleveId,
                            dateD: convertDateD,
                            dateF: convertDateF,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    filterSearch = (event) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Remboursement.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }
    
    impr = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ImprRemboursement.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: function (code_html, statut) {
        window.open("http://artsmetiers.mtd-app.com/ImprRemboursement")
            }
        });
    }
   
   
    imprecu = (row) => {
        var idRemboursement= row.idRemboursement
        this.state.id=idRemboursement
        
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
       
          
        var id =
        this.state.id +
        "/" +
        anneeScolaire ;
       
              
                window.open("http://artsmetiers.mtd-app.com/Imprecu?id="+id);
        }     
    

    addType = (selectedScol) => {
        if (selectedScol != null) {
            this.setState({ selectedScol: selectedScol }, () => {
                var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                var anneeScolaire = userPermissions[0].anneeScolaire
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEleveByScolRembours.php',
                    type: 'POST',
                    data: {
                        selectedScol: selectedScol,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            listEleve: code_html
                        });
                    }
                });
            });
        }
    }

    searchEleve = selectedEleve => {
        if (selectedEleve != null) {
            this.setState({ selectedEleve });
        }
        else {
            var el = { value: '', label: '' }
            this.setState({ selectedEleve: el });
        }
    }

    searchDateD = d => {
        this.setState({
            dateD: d
        });
    }

    searchDateF = d => {
        this.setState({
            dateF: d
        });
    }

    recherche = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedEleve != '') {
            var eleveId = this.state.selectedEleve.value
        }
        else {
            var eleveId = ''
        }
        // convert date debut 
        if (this.state.dateD != '') {
            let dateD = this.state.dateD
            let month = '' + (dateD.getMonth() + 1);
            let day = '' + dateD.getDate();
            let year = dateD.getFullYear();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            var convertDateD = [year, month, day].join('-');
        }
        else {
            var convertDateD = ''
        }
        // convert date fin
        if (this.state.dateF != '') {
            let dateF = this.state.dateF
            let monthOne = '' + (dateF.getMonth() + 1);
            let dayOne = '' + dateF.getDate();
            let yearOne = dateF.getFullYear();
            if (monthOne.length < 2)
                monthOne = '0' + monthOne;
            if (dayOne.length < 2)
                dayOne = '0' + dayOne;
            var convertDateF = [yearOne, monthOne, dayOne].join('-');
        }
        else {
            var convertDateF = ''
        }
        this.setState({
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RemboursementSearch.php',
                    type: 'POST',
                    data: {
                        eleveId: eleveId,
                        dateD: convertDateD,
                        dateF: convertDateF,
                        anneeScolaire: anneeScolaire,
                        limit: this.state.nbrEltperPage,
                        page: 1
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    afficherTout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Remboursement.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false,
                    // reinitialiser 
                    selectedScol: '',
                    listEleve: [],
                    searchEleve: '',
                    searchDateD: '',
                    searchDateF: ''
                });
            }
        });
    }

    toggleFrais = (row) => {
        var type = row.type
        var description = row.description
        this.setState(prevState => ({
            modalFrais: !prevState.modalFrais,
            typeRembours: type,
            descripRembours: description
        }));
    }
    
    toggleFermerFrais = () => {
        this.setState(prevState => ({
            modalFrais: !prevState.modalFrais,
        }));
    }

   

    toggletwo = (idRemboursement) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            idRemboursement: idRemboursement
        }));
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    delete = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        //
        var idRemboursement = this.state.idRemboursement
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/DeleteRemboursement.php',
            type: 'POST',
            data: {
                idRemboursement: idRemboursement,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                this.setState(prevState => ({
                    modal: !prevState.modal,
                }));
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Remboursement.php',
                    type: 'POST',
                    data: {
                        query: '',
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Remboursement</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/AjoutRemboursement" > <button style={{ background: '#3A3F51', border: '1px solid #3A3F51', borderRadius: '5px' }} type="button" className="btn btn-info"> <i className="fas fa-plus"></i>Remboursement</button></Link>
                                </div>
                                <h4 style={{ paddingLeft: '10px' }}>Critères de recherche</h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        <RadioGroup
                                            name="typeScol"
                                            selectedValue={this.state.selectedScol}
                                            onChange={this.addType}>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value='1' />Formation
                                                    </label>
                                            <label style={{ paddingRight: '5px' }}>
                                                <Radio value='2' />Cycle
                                                    </label>
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Apprenant</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.searchEleve(e)}
                                                options={this.state.listEleve}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date debut</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.dateD}
                                                onChange={this.searchDateD}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date fin</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.dateF}
                                                onChange={this.searchDateF}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.recherche}>Rechercher</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.afficherTout}>Afficher tout</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.impr}>Imprimer</button>
                                    </div>
                                </div>
                                <div className="mb-0 form-group">
                                    <div className="input-group-alternative input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input onChange={(currentRowsPerPage, currentPage) => this.filterSearch(currentRowsPerPage, currentPage)} placeholder="Search" type="text" className="search" />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
                            </div>
                            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} fade={false}>
                                <ModalHeader toggle={this.toggle}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                <ModalBody>
                                    <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <p>Êtes-Vous sûr de vouloir supprimer ce remboursement ?</p>
                                        </div>
                                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                            <div><button onClick={() => this.delete()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                            <div><button onClick={() => this.toggle()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                            <Modal isOpen={this.state.modalFrais} toggle={this.toggleFermerFrais} className={this.props.className} fade={false}>
                                <ModalHeader toggle={this.toggleFermerFrais}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#FFB6C1' }} className="fas fa-info"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Description du remboursement</p></div></div></ModalHeader>
                                <ModalBody>
                                    <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                        <table className="table table-bordered">
                                            <thead >
                                                <tr>
                                                    <th style={{ textAlign: 'center', fontSize: '12px' }} scope="col">Type remboursement</th>
                                                    <th style={{ textAlign: 'center', fontSize: '12px' }} scope="col">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: 'center', fontSize: '11px' }}><p>{this.state.typeRembours}</p></td>
                                                    <td style={{ textAlign: 'center', fontSize: '11px' }}><p>{this.state.descripRembours}</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                            <div><button onClick={() => this.toggleFermerFrais()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Remboursement;