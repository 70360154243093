import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Admin
      barreParam: [
        {
          titre: "Paramétrage",
          element: [
            {
              nomElement: "Gestion utilisateur",
              linkElement: "/Users",
              sousPermission: 0,
              iconElement: "fas fa-users",
              arraySousElement: [],
            },
            {
              nomElement: "Ajouter Année",
              linkElement: "/AjoutAnnee",
              sousPermission: 0,
              iconElement: "fas fa-edit",
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-align-left",
          permission: 0,
        },
      ],
      barreGesApp: [
        {
          titre: "Gestion Apprenants",
          element: [
            {
              nomElement: "Avis",
              iconElement: "fas fa-info-circle",
              linkElement: "/ConsulterAvis",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Assiduité",
              iconElement: "fas fa-calendar-alt",
              linkElement: "/Absence",
              sousPermission: 0,
              arraySousElement: [],
            },
            
            {
              nomElement: "Dérogation",
              iconElement: "fas fa-book",
              linkElement: "/Derogation",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Apprenants",
              iconElement: "fas fa-child",
              linkElement: "/Apprenants",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Apprenants Inscrits",
              iconElement: "fas fa-user-secret",
              linkElement: "/ListAppInscri",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Apprenants Anciens",
              iconElement: "fas fa-user-secret",
              linkElement: "/ApprAnciens",
              sousPermission: 0,
              arraySousElement: [],
            },
            
            {
              nomElement: "Calendrier EFF",
              iconElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Calendrier",
                  linkSousElement: "/AjouterCalendrier",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-calendar-alt",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Ajout groupe",
                  linkSousElement: "/ListGroupe",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-check",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Consulter groupe",
                  linkSousElement: "/ConsulterListGroupe",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-friends",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Resultat scolaire",
              iconElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Affiche resultat apprenant",
                  linkSousElement: "/AffichRes",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-check",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Resultat trimestrielle",
                  linkSousElement: "/ResultatTrimestriel",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-list-alt",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Releve de notes de fin formation",
                  iconSousElement: "fas fa-paste",
                  linkSousElement: "/relevefinformation",
                  sousSousPermission: 0,
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Moyenne d'admission",
                  linkSousElement: "/MoyenneAdmission",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-graduation-cap",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Moyenne d'admission BTS/BTP",
                  linkSousElement: "/MoyenneAdmissionBTS",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-graduation-cap",
                  arraySousSousElement: [],
                },
                
              ],
            },
          ],
          iconTitre: "fas fa-graduation-cap",
          permission: 0,
        },
      ],
      barreGesEns: [
        {
          titre: "Gestion Enseignants",
          element: [
            {
              nomElement: "Liste enseignants",
              iconElement: "fas fa-chalkboard-teacher",
              linkElement: "/Enseignants",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Présence enseignant",
              iconElement: "fas fa-user-clock",
              linkElement: "/PresenceEnseignant",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Gestion enseignant",
              iconElement: "fas fa-users",
              linkElement: "/GestionEnseignant",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Conseils et assitances",
              linkElement: "/ConseilAssistance",
              sousPermission: 0,
              iconElement: "fas fa-handshake",
              arraySousElement: [],
            },
            {
              nomElement: "Honoraires formateurs",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Consulter reglement",
                  linkSousElement: "/ReglementEnseignant",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-eye",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Solder compte",
                  linkSousElement: "/PayReglementEnseignant",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Chéques enseignants",
              linkElement: "/ChequeEns",
              sousPermission: 0,
              iconElement: "far fa-credit-card",
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-chalkboard-teacher",
          permission: 0,
        },
      ],
      barreTabBord: [
        {
          titre: "Tableau de Bord",
          element: [
            {
              nomElement: "Recette annuelle",
              iconElement: "fab fa-think-peaks",
              linkElement: "/Statistique",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Rapport journalier",
              iconElement: "fas fa-calculator",
              linkElement: "/RapportJournalier",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Reglement formation",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Reglement tranche",
                  linkSousElement: "",
                  sousSousPermission: 0,
                  iconSousElement: "",
                  arraySousSousElement: [
                    {
                      nomSousSousElement: "Consulter",
                      linkSousSousElement: "/ReglApprenant",
                      sousSousSousPermission: 0,
                      iconSousSousElement: "fas fa-eye",
                    },
                    {
                      nomSousSousElement: "Régler",
                      linkSousSousElement: "/ModifReglTrchApp",
                      sousSousSousPermission: 0,
                      iconSousSousElement: "fas fa-edit",
                    },
                  ],
                },
                {
                  nomSousElement: "Reglement frais",
                  linkSousElement: "/",
                  sousSousPermission: 0,
                  iconSousElement: "",
                  arraySousSousElement: [
                    {
                      nomSousSousElement: "Consulter",
                      linkSousSousElement: "/ReglApprenantFrais",
                      sousSousSousPermission: 0,
                      iconSousSousElement: "fas fa-eye",
                    },
                    {
                      nomSousSousElement: "Régler",
                      linkSousSousElement: "/ModifReglFraisApp",
                      sousSousSousPermission: 0,
                      iconSousSousElement: "fas fa-edit",
                    },
                  ],
                },
              ],
            },
            {
              nomElement: "Reglement cycle",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Cycles",
                  linkSousElement: "/Cycles",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-university",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ReglApprenantCycle",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-eye",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Regler",
                  linkSousElement: "/ModifReglCycleApp",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Autre Frais",
              iconElement: "fas fa-money-check",
              linkElement: "/AutreFrais",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Reboursement",
              iconElement: "fas fa-coins",
              linkElement: "/Remboursement",
              sousPermission: 0,
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-vr-cardboard",
          permission: 0,
        },
      ],
      // Scolarite
      barreScol: [
        {
          titre: "Scolarité",
          element: [
            {
              nomElement: "Visiteurs",
              iconElement: "fas fa-walking",
              linkElement: "/Visiteurs",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Apprenants",
              iconElement: "fas fa-child",
              linkElement: "/Apprenants",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Enseignants",
              iconElement: "fas fa-chalkboard-teacher",
              linkElement: "/Enseignants",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Scolarite",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Filiéres",
                  linkSousElement: "/Filieres",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-bookmark",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Specialités",
                  linkSousElement: "/Specialites",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-chalkboard",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Formations",
                  linkSousElement: "/Formations",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-scroll",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Niveaux",
                  linkSousElement: "/Niveaux",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-list-ol",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Groupes",
                  linkSousElement: "/Groupes",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-friends",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Education",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Type enseignement",
                  linkSousElement: "/TypeEnseign",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Matieres",
                  linkSousElement: "/Matieres",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-book-open",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Matieres par niveau",
                  linkSousElement: "/MatieresFormat",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-atlas",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Matiére pratique(Sous-matiére)",
                  linkSousElement: "/MatSousMat",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-file",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Types paiement enseignants",
              iconElement: "fas fa-hand-holding-usd",
              linkElement: "/ModPaiEnseignants",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Dossier d'inscription",
              iconElement: "fas fa-paste",
              linkElement: "/Papiers",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Niveau de base",
              iconElement: "fas fa-book",
              linkElement: "/NivBase",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Avis",
              iconElement: "fas fa-info-circle",
              linkElement: "/ConsulterAvis",
              sousPermission: 0,
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-balance-scale",
          permission: 0,
        },
      ],
      barreForInit: [
        {
          titre: "Formation initiale",
          element: [
            {
              nomElement: "Inscription",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/Inscription",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-box-tissue",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Apprenants Inscrits",
                  linkSousElement: "/ListAppInscri",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-secret",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Reglement tranche",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ReglApprenant",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-eye",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Regler",
                  linkSousElement: "/ModifReglTrchApp",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Reglement frais",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ReglApprenantFrais",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-eye",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Regler",
                  linkSousElement: "/ModifReglFraisApp",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Reçu émis",
              iconElement: "fas fa-scroll",
              linkElement: "/RecuEmis",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Autre Frais",
              iconElement: "fas fa-money-check",
              linkElement: "/AutreFrais",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Frais formation",
              linkElement: "/FraisFormation",
              sousPermission: 0,
              iconElement: "fas fa-money-check-alt",
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-address-card",
          permission: 0,
        },
      ],
      barreAssed: [
        {
          titre: "Assiduité",
          element: [
            {
              nomElement: "Ajout séance",
              iconElement: "far fa-calendar",
              linkElement: "/AjoutSeance",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Absence apprenants",
              iconElement: "fas fa-calendar-day",
              linkElement: "/Absence",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Présences enseignants",
              iconElement: "fas fa-calendar-check",
              linkElement: "/PresenceEnseignant",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Attestation",
              iconElement: "fas fa-paste",
              linkElement: "/Attestation",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Demande d'attestation",
              iconElement: "fas fa-sticky-note",
              linkElement: "/DemandeAttestation",
              sousPermission: 0,
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-list-alt",
          permission: 0,
        },
      ],
      barreNavNote: [
        {
          titre: " Note",
          element: [
            {
              nomElement: "Nature Note",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajout test",
                  linkSousElement: "/NatureNote",
                  permission: 0,
                  iconSousElement: "fas fa-atlas",
                },
                {
                  nomSousElement: "Recap note par niveau",
                  linkSousElement: "/NiveauNatureNote",
                  permission: 0,
                  iconSousElement: "fas fa-book",
                },
              ],
            },
            {
              nomElement: "Rapport Note",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajout Note ",
                  linkSousElement: "/AjoutNote",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ListNote",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "Rapport Note BTS",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajout Note BTS",
                  linkSousElement: "/AjoutNoteBTS",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ListNoteBTS",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "Releve Note ",
              iconElement: "fas fa-clipboard-list",
              linkElement: "/ReleveNote",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Releve Note BTS ",
              iconElement: "fas fa-clipboard-list",
              linkElement: "/ReleveNoteBTS",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Resultat BTS ",
              iconElement: "fas fa-sticky-note",
              linkElement: "/ResultatBTS",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Resultat ACC ",
              iconElement: "fas fa-book-open",
              linkElement: "/ResultatPizzaiolo",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Resultat Trimestriel",
              iconElement: "fas fa-book-reader",
              linkElement: "/ResultatTrimestriel",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "List Vierge",
              iconElement: "fas fa-sticky-note",
              linkElement: "/ListVierge",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Stage",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/AjoutNoteStage",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ConsulterNoteStage",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
                {
                  nomSousElement: "Consulter BTS",
                  linkSousElement: "/ConsulterNoteStageBts",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                  
                },
              ],
            },
            {
              nomElement: "Note PFE",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/AjoutNotePFE",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ConsulterNotePFE",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "Jury",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Jury",
                  linkSousElement: "/ConsulterJury",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
                {
                  nomSousElement: "Jury bTS",
                  linkSousElement: "/ConsulterJuryBTS",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "EFF ",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/AjoutNoteExamen",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ConsNoteExam",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Examen Pratique",
                  linkSousElement: "/ConsulterNoteExam",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
                {
                  nomSousElement: "Examen Pratique BTS",
                  linkSousElement: "/ConsulterNoteExamBts",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "Releve de notes de fin formation",
              iconElement: "fas fa-scroll",
              linkElement: "/relevefinformation",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Moyenne d'admission",
              iconElement: "fas fa-paste",
              linkElement: "/MoyenneAdmission",
              sousPermission: 0,
              arraySousElement: [],
            },
            
            {
              nomElement: "Moyenne d'admission BTS",
              iconElement: "fas fa-scroll",
              linkElement: "/MoyenneAdmissionBTS",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Rattrapage",
              iconElement: "fas fa-clone",
              linkElement: "/Rattrapage",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Rattrapage BTS",
              iconElement: "fas fa-clone",
              linkElement: "/RattrapageBTS",
              sousPermission: 0,
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-align-left",
        },
      ],
      barreNavNote2: [
        {
          titre: " Note",
          element: [
            {
              nomElement: "Nature Note",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajout test",
                  linkSousElement: "/NatureNote",
                  permission: 0,
                  iconSousElement: "fas fa-atlas",
                },
                {
                  nomSousElement: "Recap note par niveau",
                  linkSousElement: "/NiveauNatureNote",
                  permission: 0,
                  iconSousElement: "fas fa-book",
                },
              ],
            },
            {
              nomElement: "Rapport Note",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajout Note ",
                  linkSousElement: "/AjoutNote",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ListNote",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "Rapport Note BTS / BTP",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajout Note BTS / BTP",
                  linkSousElement: "/AjoutNoteBTS",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ListNoteBTS",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "Releve Note ",
              iconElement: "fas fa-clipboard-list",
              linkElement: "/ReleveNote",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Releve Note BTS / BTP ",
              iconElement: "fas fa-clipboard-list",
              linkElement: "/ReleveNoteBTS",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Resultat BTS / BTP ",
              iconElement: "fas fa-sticky-note",
              linkElement: "/ResultatBTS",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Resultat ACC ",
              iconElement: "fas fa-book-open",
              linkElement: "/ResultatPizzaiolo",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Resultat Trimestriel",
              iconElement: "fas fa-book-reader",
              linkElement: "/ResultatTrimestriel",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "List Vierge",
              iconElement: "fas fa-sticky-note",
              linkElement: "/ListVierge",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Stage",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/AjoutNoteStage",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ConsulterNoteStage",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
                {
                  nomSousElement: "Consulter BTS / BTP",
                  linkSousElement: "/ConsulterNoteStageBts",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "Note PFE",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/AjoutNotePFE",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ConsulterNotePFE",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "Jury",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Jury",
                  linkSousElement: "/ConsulterJury",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
                {
                  nomSousElement: "Jury BTS / BTP",
                  linkSousElement: "/ConsulterJuryBTS",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              ],
            },
            {
              nomElement: "EFF ",
              iconElement: "",
              linkElement: "",
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/AjoutNoteExamen",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ConsNoteExam",
                  permission: 0,
                  iconSousElement: "fas fa-plus-circle",
                },
                {
                  nomSousElement: "Examen Pratique",
                  linkSousElement: "/ConsulterNoteExam",
                  permission: 0,
                  iconSousElement: "fas fa-eye",
                },
              
                
              ],
            },
            
            {
              nomElement: "Releve de notes de fin formation",
              iconElement: "fas fa-paste",
              linkElement: "/relevefinformation",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Moyenne d'admission",
              iconElement: "fas fa-paste",
              linkElement: "/MoyenneAdmission",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Moyenne d'admission BTS / BTP",
              iconElement: "fas fa-scroll",
              linkElement: "/MoyenneAdmissionBTS",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Rattrapage",
              iconElement: "fas fa-clone",
              linkElement: "/Rattrapage",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Rattrapage BTS / BTP",
              iconElement: "fas fa-clone",
              linkElement: "/RattrapageBTS",
              sousPermission: 0,
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-align-left",
        },
      ],
      barreCalendrier: [
        {
          titre: "Calendrier",
          element: [
            {
              nomElement: "Calendrier",
              linkElement: "/AjouterCalendrier",
              sousPermission: 0,
              iconElement: "fas fa-calendar-alt",
              arraySousElement: [],
            },
            {
              nomElement: "Ajout groupe",
              linkElement: "/ListGroupe",
              sousPermission: 0,
              iconElement: "fas fa-user-check",
              arraySousElement: [],
            },
            {
              nomElement: "Consulter groupe",
              linkElement: "/ConsulterListGroupe",
              sousPermission: 0,
              iconElement: "fas fa-user-friends",
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-calendar-week",
          permission: 0,
        },
      ],
      barreForCycle: [
        {
          titre: "Formation accélére",
          element: [
            {
              nomElement: "Cycles",
              linkElement: "/Cycles",
              sousPermission: 0,
              iconElement: "fab fa-leanpub",
              arraySousElement: [],
            },
            {
              nomElement: "Inscription",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/InscripCycle",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-box-tissue",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Apprenants Inscrits",
                  linkSousElement: "/ListAppCycle",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-secret",
                  arraySousSousElement: [],
                },
              ],
            },
            // {
            //   nomElement: "Inscription",
            //   linkElement: "/InscripCycle",
            //   sousPermission: 0,
            //   iconElement: "fas fa-box-tissue",
            //   arraySousElement: [],
            // },
            {
              nomElement: "Consulter",
              iconElement: "fas fa-eye",
              linkElement: "/ReglApprenantCycle",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Regler",
              iconElement: "fas fa-edit",
              linkElement: "/ModifReglCycleApp",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Frais cycle",
              linkElement: "/FraisCycle",
              sousPermission: 0,
              iconElement: "fas fa-money-check-alt",
              arraySousElement: [],
            },
            {
              nomElement: "Horaire cours",
              linkElement: "/HoraireCours",
              sousPermission: 0,
              iconElement: "fas fa-clock",
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-bookmark",
          permission: 0,
        },
      ],
      // Role selon l'utilisateur (soit admin ou scolarite)
      roleId: "",
      anneeScolaire: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var roleId = userPermissions[0].idRoleUser;
      var anneeScolaire = userPermissions[0].anneeScolaire;
      this.setState({
        roleId: roleId,
        anneeScolaire: parseInt(anneeScolaire),
      });
    } else {
      document.location = "/";
    }
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Menu Général</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                {this.state.roleId == 1 ? (
                  <div className="row">
                    {this.state.barreParam.map((el, index) => (
                      <div className="col-md-3">
                        <div
                          className="white-box"
                          style={{ border: "solid 1px", borderRadius: "5px" }}
                        >
                          <h3
                            style={{
                              border: "solid 1px",
                              backgroundColor: "black",
                              color: "white",
                            }}
                          >
                            <i
                              style={{
                                paddingRight: "5px",
                                paddingLeft: "5px",
                              }}
                              className={el.iconTitre}
                            ></i>
                            {el.titre}
                          </h3>
                          <div className="myadmin-dd dd">
                            <ol className="dd-list">
                              {el.element.map((ele, indice) => (
                                <div>
                                  {ele.arraySousElement.length > 0 ? (
                                    // Noeud Parent Ayant des Fils Feuilles
                                    <Fragment>
                                      <li className="dd-item" data-id={index}>
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                            color: "#2b2b2b",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                        <ol className="dd-list">
                                          {ele.arraySousElement.map((elm) => (
                                            <li
                                              className="dd-item"
                                              data-id={index + 1}
                                            >
                                              <Link
                                                style={{ color: "#2b2b2b" }}
                                                to={elm.linkSousElement}
                                              >
                                                <div
                                                  style={{
                                                    border:
                                                      "1px solid rgba(120,130,140,.13)",
                                                    padding: "8px 16px",
                                                    height: "auto",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  <i
                                                    style={{
                                                      paddingRight: "5px",
                                                    }}
                                                    className={
                                                      elm.iconSousElement
                                                    }
                                                  ></i>
                                                  {elm.nomSousElement}
                                                </div>
                                              </Link>
                                              {elm.arraySousSousElement.length >
                                              0 ? (
                                                <ol className="dd-list">
                                                  {elm.arraySousSousElement.map(
                                                    (ellm) => (
                                                      <li
                                                        className="dd-item"
                                                        data-id={index + 1}
                                                      >
                                                        <Link
                                                          style={{
                                                            color: "#2b2b2b",
                                                          }}
                                                          to={
                                                            ellm.linkSousSousElement
                                                          }
                                                        >
                                                          <div
                                                            style={{
                                                              border:
                                                                "1px solid rgba(120,130,140,.13)",
                                                              padding:
                                                                "8px 16px",
                                                              height: "auto",
                                                              fontWeight: "600",
                                                            }}
                                                          >
                                                            <i
                                                              style={{
                                                                paddingRight:
                                                                  "5px",
                                                              }}
                                                              className={
                                                                ellm.iconSousSousElement
                                                              }
                                                            ></i>
                                                            {
                                                              ellm.nomSousSousElement
                                                            }
                                                          </div>
                                                        </Link>
                                                      </li>
                                                    )
                                                  )}
                                                </ol>
                                              ) : null}
                                            </li>
                                          ))}
                                        </ol>
                                      </li>{" "}
                                    </Fragment>
                                  ) : (
                                    // Noeud Feuille (Terminaison)
                                    <Link
                                      style={{ color: "#2b2b2b" }}
                                      to={ele.linkElement}
                                    >
                                      <div
                                        style={{
                                          border:
                                            "1px solid rgba(120,130,140,.13)",
                                          padding: "8px 16px",
                                          height: "auto",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <i
                                          style={{ paddingRight: "5px" }}
                                          className={ele.iconElement}
                                        ></i>
                                        {ele.nomElement}
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </ol>
                          </div>
                        </div>
                      </div>
                    ))}
                    {this.state.barreGesApp.map((el, index) => (
                      <div className="col-md-3">
                        <div
                          className="white-box"
                          style={{ border: "solid 1px", borderRadius: "5px" }}
                        >
                          <h3
                            style={{
                              border: "solid 1px",
                              backgroundColor: "black",
                              color: "white",
                            }}
                          >
                            <i
                              style={{
                                paddingRight: "5px",
                                paddingLeft: "5px",
                              }}
                              className={el.iconTitre}
                            ></i>
                            {el.titre}
                          </h3>
                          <div className="myadmin-dd dd" id="nestable1">
                            <ol className="dd-list">
                              {el.element.map((ele, indice) => (
                                <div>
                                  {ele.arraySousElement.length > 0 ? (
                                    // Noeud Parent Ayant des Fils Feuilles
                                    <Fragment>
                                      <li className="dd-item" data-id={index}>
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                            color: "#2b2b2b",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                        <ol className="dd-list">
                                          {ele.arraySousElement.map((elm) => (
                                            <li
                                              className="dd-item"
                                              data-id={index + 1}
                                            >
                                              <Link
                                                style={{ color: "#2b2b2b" }}
                                                to={elm.linkSousElement}
                                              >
                                                <div
                                                  style={{
                                                    border:
                                                      "1px solid rgba(120,130,140,.13)",
                                                    padding: "8px 16px",
                                                    height: "auto",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  <i
                                                    style={{
                                                      paddingRight: "5px",
                                                    }}
                                                    className={
                                                      elm.iconSousElement
                                                    }
                                                  ></i>
                                                  {elm.nomSousElement}
                                                </div>
                                              </Link>
                                              {elm.arraySousSousElement.length >
                                              0 ? (
                                                <ol className="dd-list">
                                                  {elm.arraySousSousElement.map(
                                                    (ellm) => (
                                                      <li
                                                        className="dd-item"
                                                        data-id={index + 1}
                                                      >
                                                        <Link
                                                          style={{
                                                            color: "#2b2b2b",
                                                          }}
                                                          to={
                                                            ellm.linkSousSousElement
                                                          }
                                                        >
                                                          <div
                                                            style={{
                                                              border:
                                                                "1px solid rgba(120,130,140,.13)",
                                                              padding:
                                                                "8px 16px",
                                                              height: "auto",
                                                              fontWeight: "600",
                                                            }}
                                                          >
                                                            <i
                                                              style={{
                                                                paddingRight:
                                                                  "5px",
                                                              }}
                                                              className={
                                                                ellm.iconSousSousElement
                                                              }
                                                            ></i>
                                                            {
                                                              ellm.nomSousSousElement
                                                            }
                                                          </div>
                                                        </Link>
                                                      </li>
                                                    )
                                                  )}
                                                </ol>
                                              ) : null}
                                            </li>
                                          ))}
                                        </ol>
                                      </li>{" "}
                                    </Fragment>
                                  ) : (
                                    // Noeud Feuille (Terminaison)
                                    <Link
                                      style={{ color: "#2b2b2b" }}
                                      to={ele.linkElement}
                                    >
                                      <div
                                        style={{
                                          border:
                                            "1px solid rgba(120,130,140,.13)",
                                          padding: "8px 16px",
                                          height: "auto",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <i
                                          style={{ paddingRight: "5px" }}
                                          className={ele.iconElement}
                                        ></i>
                                        {ele.nomElement}
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </ol>
                          </div>
                        </div>
                      </div>
                    ))}
                    {this.state.barreGesEns.map((el, index) => (
                      <div className="col-md-3">
                        <div
                          className="white-box"
                          style={{ border: "solid 1px", borderRadius: "5px" }}
                        >
                          <h3
                            style={{
                              border: "solid 1px",
                              backgroundColor: "black",
                              color: "white",
                            }}
                          >
                            <i
                              style={{
                                paddingRight: "5px",
                                paddingLeft: "5px",
                              }}
                              className={el.iconTitre}
                            ></i>
                            {el.titre}
                          </h3>
                          <div className="myadmin-dd dd" id="nestable2">
                            <ol className="dd-list">
                              {el.element.map((ele, indice) => (
                                <div>
                                  {ele.arraySousElement.length > 0 ? (
                                    // Noeud Parent Ayant des Fils Feuilles
                                    <Fragment>
                                      <li className="dd-item" data-id={index}>
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                            color: "#2b2b2b",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                        <ol className="dd-list">
                                          {ele.arraySousElement.map((elm) => (
                                            <li
                                              className="dd-item"
                                              data-id={index + 1}
                                            >
                                              <Link
                                                style={{ color: "#2b2b2b" }}
                                                to={elm.linkSousElement}
                                              >
                                                <div
                                                  style={{
                                                    border:
                                                      "1px solid rgba(120,130,140,.13)",
                                                    padding: "8px 16px",
                                                    height: "auto",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  <i
                                                    style={{
                                                      paddingRight: "5px",
                                                    }}
                                                    className={
                                                      elm.iconSousElement
                                                    }
                                                  ></i>
                                                  {elm.nomSousElement}
                                                </div>
                                              </Link>
                                              {elm.arraySousSousElement.length >
                                              0 ? (
                                                <ol className="dd-list">
                                                  {elm.arraySousSousElement.map(
                                                    (ellm) => (
                                                      <li
                                                        className="dd-item"
                                                        data-id={index + 1}
                                                      >
                                                        <Link
                                                          style={{
                                                            color: "#2b2b2b",
                                                          }}
                                                          to={
                                                            ellm.linkSousSousElement
                                                          }
                                                        >
                                                          <div
                                                            style={{
                                                              border:
                                                                "1px solid rgba(120,130,140,.13)",
                                                              padding:
                                                                "8px 16px",
                                                              height: "auto",
                                                              fontWeight: "600",
                                                            }}
                                                          >
                                                            <i
                                                              style={{
                                                                paddingRight:
                                                                  "5px",
                                                              }}
                                                              className={
                                                                ellm.iconSousSousElement
                                                              }
                                                            ></i>
                                                            {
                                                              ellm.nomSousSousElement
                                                            }
                                                          </div>
                                                        </Link>
                                                      </li>
                                                    )
                                                  )}
                                                </ol>
                                              ) : null}
                                            </li>
                                          ))}
                                        </ol>
                                      </li>{" "}
                                    </Fragment>
                                  ) : (
                                    // Noeud Feuille (Terminaison)
                                    <Link
                                      style={{ color: "#2b2b2b" }}
                                      to={ele.linkElement}
                                    >
                                      <div
                                        style={{
                                          border:
                                            "1px solid rgba(120,130,140,.13)",
                                          padding: "8px 16px",
                                          height: "auto",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <i
                                          style={{ paddingRight: "5px" }}
                                          className={ele.iconElement}
                                        ></i>
                                        {ele.nomElement}
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </ol>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div></div>
                    {this.state.barreTabBord.map((el, index) => (
                      <div
                        className="col-md-3"
                        style={{ color: "rgb(43, 43, 43)" }}
                      >
                        <div
                          className="white-box"
                          style={{ border: "solid 1px", borderRadius: "5px" }}
                        >
                          <h3
                            style={{
                              border: "solid 1px",
                              backgroundColor: "black",
                              color: "white",
                            }}
                          >
                            <i
                              style={{
                                paddingRight: "5px",
                                paddingLeft: "5px",
                              }}
                              className={el.iconTitre}
                            ></i>
                            {el.titre}
                          </h3>
                          <div className="myadmin-dd dd" id="nestable">
                            <ol className="dd-list">
                              {el.element.map((ele, indice) => (
                                <div>
                                  {ele.arraySousElement.length > 0 ? (
                                    // Noeud Parent Ayant des Fils Feuilles
                                    <Fragment>
                                      <li className="dd-item" data-id={index}>
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                            color: "#2b2b2b",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                        <ol className="dd-list">
                                          {ele.arraySousElement.map((elm) => (
                                            <li
                                              className="dd-item"
                                              data-id={index + 1}
                                            >
                                              {elm.linkSousElement != "" ? (
                                                <Link
                                                  style={{ color: "#2b2b2b" }}
                                                  to={elm.linkSousElement}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                </Link>
                                              ) : (
                                                <div
                                                  style={{
                                                    border:
                                                      "1px solid rgba(120,130,140,.13)",
                                                    padding: "8px 16px",
                                                    height: "auto",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  <i
                                                    style={{
                                                      paddingRight: "5px",
                                                    }}
                                                    className={
                                                      elm.iconSousElement
                                                    }
                                                  ></i>
                                                  {elm.nomSousElement}
                                                </div>
                                              )}
                                              {elm.arraySousSousElement.length >
                                              0 ? (
                                                <ol className="dd-list">
                                                  {elm.arraySousSousElement.map(
                                                    (ellm) => (
                                                      <li
                                                        className="dd-item"
                                                        data-id={index + 1}
                                                      >
                                                        <Link
                                                          style={{
                                                            color: "#2b2b2b",
                                                          }}
                                                          to={
                                                            ellm.linkSousSousElement
                                                          }
                                                        >
                                                          <div
                                                            style={{
                                                              border:
                                                                "1px solid rgba(120,130,140,.13)",
                                                              padding:
                                                                "8px 16px",
                                                              height: "auto",
                                                              fontWeight: "600",
                                                            }}
                                                          >
                                                            <i
                                                              style={{
                                                                paddingRight:
                                                                  "5px",
                                                              }}
                                                              className={
                                                                ellm.iconSousSousElement
                                                              }
                                                            ></i>
                                                            {
                                                              ellm.nomSousSousElement
                                                            }
                                                          </div>
                                                        </Link>
                                                      </li>
                                                    )
                                                  )}
                                                </ol>
                                              ) : null}
                                            </li>
                                          ))}
                                        </ol>
                                      </li>{" "}
                                    </Fragment>
                                  ) : (
                                    // Noeud Feuille (Terminaison)
                                    <Link
                                      style={{ color: "#2b2b2b" }}
                                      to={ele.linkElement}
                                    >
                                      <div
                                        style={{
                                          border:
                                            "1px solid rgba(120,130,140,.13)",
                                          padding: "8px 16px",
                                          height: "auto",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <i
                                          style={{ paddingRight: "5px" }}
                                          className={ele.iconElement}
                                        ></i>
                                        {ele.nomElement}
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </ol>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      {this.state.barreScol.map((el, index) => (
                        <div className="col-md-4">
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd" id="nestable">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                <Link
                                                  style={{ color: "#2b2b2b" }}
                                                  to={elm.linkSousElement}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                </Link>
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))}
                      {this.state.barreForInit.map((el, index) => (
                        <div className="col-md-4">
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd" id="nestable1">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                <Link
                                                  style={{ color: "#2b2b2b" }}
                                                  to={elm.linkSousElement}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                </Link>
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))}
                      {this.state.barreAssed.map((el, index) => (
                        <div
                          className="col-md-4"
                          style={{ color: "rgb(43, 43, 43)" }}
                        >
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                {elm.linkSousElement != "" ? (
                                                  <Link
                                                    style={{ color: "#2b2b2b" }}
                                                    to={elm.linkSousElement}
                                                  >
                                                    <div
                                                      style={{
                                                        border:
                                                          "1px solid rgba(120,130,140,.13)",
                                                        padding: "8px 16px",
                                                        height: "auto",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      <i
                                                        style={{
                                                          paddingRight: "5px",
                                                        }}
                                                        className={
                                                          elm.iconSousElement
                                                        }
                                                      ></i>
                                                      {elm.nomSousElement}
                                                    </div>
                                                  </Link>
                                                ) : (
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                )}
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="row">
                      {this.state.anneeScolaire < 2020 ? (
                        <div>
                          {this.state.barreNavNote.map((el, index) => (
                            <div className="col-md-4">
                              <div
                                className="white-box"
                                style={{
                                  border: "solid 1px",
                                  borderRadius: "5px",
                                }}
                              >
                                <h3
                                  style={{
                                    border: "solid 1px",
                                    backgroundColor: "black",
                                    color: "white",
                                  }}
                                >
                                  <i
                                    style={{
                                      paddingRight: "5px",
                                      paddingLeft: "5px",
                                    }}
                                    className={el.iconTitre}
                                  ></i>
                                  {el.titre}
                                </h3>
                                <div className="myadmin-dd dd" id="nestable2">
                                  <ol className="dd-list">
                                    {el.element.map((ele, indice) => (
                                      <div>
                                        {ele.arraySousElement.length > 0 ? (
                                          // Noeud Parent Ayant des Fils Feuilles
                                          <Fragment>
                                            <li
                                              className="dd-item"
                                              data-id={index}
                                            >
                                              <div
                                                style={{
                                                  border:
                                                    "1px solid rgba(120,130,140,.13)",
                                                  padding: "8px 16px",
                                                  height: "auto",
                                                  fontWeight: "600",
                                                  color: "#2b2b2b",
                                                }}
                                              >
                                                <i
                                                  style={{
                                                    paddingRight: "5px",
                                                  }}
                                                  className={ele.iconElement}
                                                ></i>
                                                {ele.nomElement}
                                              </div>
                                              <ol className="dd-list">
                                                {ele.arraySousElement.map(
                                                  (elm) => (
                                                    <li
                                                      className="dd-item"
                                                      data-id={index + 1}
                                                    >
                                                      <Link
                                                        style={{
                                                          color: "#2b2b2b",
                                                        }}
                                                        to={elm.linkSousElement}
                                                      >
                                                        <div
                                                          style={{
                                                            border:
                                                              "1px solid rgba(120,130,140,.13)",
                                                            padding: "8px 16px",
                                                            height: "auto",
                                                            fontWeight: "600",
                                                          }}
                                                        >
                                                          <i
                                                            style={{
                                                              paddingRight:
                                                                "5px",
                                                            }}
                                                            className={
                                                              elm.iconSousElement
                                                            }
                                                          ></i>
                                                          {elm.nomSousElement}
                                                        </div>
                                                      </Link>
                                                    </li>
                                                  )
                                                )}
                                              </ol>
                                            </li>{" "}
                                          </Fragment>
                                        ) : (
                                          // Noeud Feuille (Terminaison)
                                          <Link
                                            style={{ color: "#2b2b2b" }}
                                            to={ele.linkElement}
                                          >
                                            <div
                                              style={{
                                                border:
                                                  "1px solid rgba(120,130,140,.13)",
                                                padding: "8px 16px",
                                                height: "auto",
                                                fontWeight: "600",
                                              }}
                                            >
                                              <i
                                                style={{ paddingRight: "5px" }}
                                                className={ele.iconElement}
                                              ></i>
                                              {ele.nomElement}
                                            </div>
                                          </Link>
                                        )}
                                      </div>
                                    ))}
                                  </ol>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          {this.state.barreNavNote2.map((el, index) => (
                            <div className="col-md-4">
                              <div
                                className="white-box"
                                style={{
                                  border: "solid 1px",
                                  borderRadius: "5px",
                                }}
                              >
                                <h3
                                  style={{
                                    border: "solid 1px",
                                    backgroundColor: "black",
                                    color: "white",
                                  }}
                                >
                                  <i
                                    style={{
                                      paddingRight: "5px",
                                      paddingLeft: "5px",
                                    }}
                                    className={el.iconTitre}
                                  ></i>
                                  {el.titre}
                                </h3>
                                <div className="myadmin-dd dd" id="nestable2">
                                  <ol className="dd-list">
                                    {el.element.map((ele, indice) => (
                                      <div>
                                        {ele.arraySousElement.length > 0 ? (
                                          // Noeud Parent Ayant des Fils Feuilles
                                          <Fragment>
                                            <li
                                              className="dd-item"
                                              data-id={index}
                                            >
                                              <div
                                                style={{
                                                  border:
                                                    "1px solid rgba(120,130,140,.13)",
                                                  padding: "8px 16px",
                                                  height: "auto",
                                                  fontWeight: "600",
                                                  color: "#2b2b2b",
                                                }}
                                              >
                                                <i
                                                  style={{
                                                    paddingRight: "5px",
                                                  }}
                                                  className={ele.iconElement}
                                                ></i>
                                                {ele.nomElement}
                                              </div>
                                              <ol className="dd-list">
                                                {ele.arraySousElement.map(
                                                  (elm) => (
                                                    <li
                                                      className="dd-item"
                                                      data-id={index + 1}
                                                    >
                                                      <Link
                                                        style={{
                                                          color: "#2b2b2b",
                                                        }}
                                                        to={elm.linkSousElement}
                                                      >
                                                        <div
                                                          style={{
                                                            border:
                                                              "1px solid rgba(120,130,140,.13)",
                                                            padding: "8px 16px",
                                                            height: "auto",
                                                            fontWeight: "600",
                                                          }}
                                                        >
                                                          <i
                                                            style={{
                                                              paddingRight:
                                                                "5px",
                                                            }}
                                                            className={
                                                              elm.iconSousElement
                                                            }
                                                          ></i>
                                                          {elm.nomSousElement}
                                                        </div>
                                                      </Link>
                                                    </li>
                                                  )
                                                )}
                                              </ol>
                                            </li>{" "}
                                          </Fragment>
                                        ) : (
                                          // Noeud Feuille (Terminaison)
                                          <Link
                                            style={{ color: "#2b2b2b" }}
                                            to={ele.linkElement}
                                          >
                                            <div
                                              style={{
                                                border:
                                                  "1px solid rgba(120,130,140,.13)",
                                                padding: "8px 16px",
                                                height: "auto",
                                                fontWeight: "600",
                                              }}
                                            >
                                              <i
                                                style={{ paddingRight: "5px" }}
                                                className={ele.iconElement}
                                              ></i>
                                              {ele.nomElement}
                                            </div>
                                          </Link>
                                        )}
                                      </div>
                                    ))}
                                  </ol>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {this.state.barreCalendrier.map((el, index) => (
                        <div
                          className="col-md-4"
                          style={{ color: "rgb(43, 43, 43)" }}
                        >
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                {elm.linkSousElement != "" ? (
                                                  <Link
                                                    style={{ color: "#2b2b2b" }}
                                                    to={elm.linkSousElement}
                                                  >
                                                    <div
                                                      style={{
                                                        border:
                                                          "1px solid rgba(120,130,140,.13)",
                                                        padding: "8px 16px",
                                                        height: "auto",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      <i
                                                        style={{
                                                          paddingRight: "5px",
                                                        }}
                                                        className={
                                                          elm.iconSousElement
                                                        }
                                                      ></i>
                                                      {elm.nomSousElement}
                                                    </div>
                                                  </Link>
                                                ) : (
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                )}
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))}
                      {this.state.barreForCycle.map((el, index) => (
                        <div
                          className="col-md-4"
                          style={{ color: "rgb(43, 43, 43)" }}
                        >
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                {elm.linkSousElement != "" ? (
                                                  <Link
                                                    style={{ color: "#2b2b2b" }}
                                                    to={elm.linkSousElement}
                                                  >
                                                    <div
                                                      style={{
                                                        border:
                                                          "1px solid rgba(120,130,140,.13)",
                                                        padding: "8px 16px",
                                                        height: "auto",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      <i
                                                        style={{
                                                          paddingRight: "5px",
                                                        }}
                                                        className={
                                                          elm.iconSousElement
                                                        }
                                                      ></i>
                                                      {elm.nomSousElement}
                                                    </div>
                                                  </Link>
                                                ) : (
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                )}
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
