import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class ImprAbsence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayInfo: [],
      s:0,
      t:0,
      annee2:0,
annee:0

        };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var array = url.split("=");
      var params = array[1];
      var arrayInfo = params.split("/");
      var eleveId = arrayInfo[0];
      var groupeId = arrayInfo[1];
      var matiereId = arrayInfo[2];
      var moisId = arrayInfo[3];
      var dateD = arrayInfo[4];
      var dateF = arrayInfo[5];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      this.state.annee=anneeScolaire;
      this.state.annee2=this.state.annee++
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/ImprAbsence.php",
        type: "POST",
        data: {
          eleveId: eleveId,
          groupeId: groupeId,
          matiereId: matiereId,
          moisId: moisId,
          dateD: dateD,
          dateF: dateF,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            arrayInfo: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
          </div>
        </div>
  
        <div style={{ textAlign: "center" }} className="row">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>Liste d'absence des apprenants (Année scolaire  { this.state.annee2} / {this.state.annee })</label>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "1%" }}
        >
            
          <table style={{ border: "1", width: "75%" }}>
            <thead>
              <th
                style={{ textAlign: "center", border: "solid",color:"black" }}
              >
                {" "}
                N°{" "}
              </th>
              <th
                style={{ textAlign: "center", border: "solid", color: "black", width:"25%"}}
              >
                {" "}
                Apprenant{" "}
              </th>
              <th
                style={{ textAlign: "center", border: "solid", color: "black" }}
              >
                {" "}
                Total{" "}
              </th>
              
              <th
                style={{ textAlign: "center", border: "solid", color: "black" }}
              >
                {" "}
               
              </th>
            </thead>
            <tbody>
              {this.state.arrayInfo.map((el, index) => (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {el.nomComplet}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {el.absTotal}
                  </td>
                  <td
                    style={{
                     
                      border: "solid",
                      color: "black",
                    }}
                  >
                    <table style={{ border: "dashed",margin:"8px" ,width:"90%" }}>
                    <th  style={{
                      textAlign: "center",
                      border: "dashed",
                     width:"60%"
                    }}><strong>Matiére</strong></th>
                    <th  style={{
                      textAlign: "center",
                      border: "dashed",
                     
                    }}><strong>Nbre d'absence</strong></th>
                    {el.detAbs.map((elem)=>
                    
                    <tbody>
                    <tr>
                      <td  style={{

                      textAlign: "left",
                      paddingLeft:"5px",
                      border: "dashed",
                      
                      width:"60%"
                    }}>
                      {elem.matNom}
                      </td>
                      
                      <td  style={{
                      textAlign: "center",
                      border: "dashed",
                      
                    }}>{elem.nbrAbs}</td>
                    
                    
                  
                      </tr>
                   
                       </tbody>
                    )}
                    <tr>
                    <td><strong><p style={{display:"flex",justifyContent:"flex-end"}}>TOTAL Absences:</p></strong></td>
                    {el.detAbs.map((elem)=>
                    {
                    this.state.s+= elem.nbrAbs}
                    
                    )}
                    <strong><p style={{display:"flex",justifyContent:"center" }}>{this.state.s}</p></strong>
                    
                    
                   </tr>
                    </table> <p style={{visibility: "hidden"}}>
                    {this.state.t+=this.state.s}
                      {this.state.s=0}
                    </p>
                  </td>

                  
                </tr>
              ))}
             <tr>
              <td style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}></td>
              <td style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}></td>
              <td style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}></td>
             <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
             <strong><p style={{display:"flex",justifyContent:"center"}}>TOTAL Absences : {this.state.t}</p></strong></td>
             </tr>
            </tbody>
             
            
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprAbsence);
