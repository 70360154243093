import React, { Component, Fragment } from "react";
import axios from "axios";
import jQuery from "jquery";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import Select from "react-select";
import { RadioGroup, Radio } from "react-radio-group";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

class InscripCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTypeApp: [
        { value: 1, label: "Eléve deja inscrit a une formation" },
        { value: 2, label: "Nouveau éleve" },
      ],
      selectedTypeApp: 2,
      listApprenant: [],
      selectedApprenant: "",
      listConnaissance: [],
      numInscripCycle: "",
      numCarteAdh: "",
      listTypeFormat: [
        { value: 1, label: "Societé" },
        { value: 2, label: "Particulier" },
      ],
      selectedTypeFiche: 2,
      nbrPersonne: 0,
      matFisc: "",
      nbrPersTenu: 0,
      societe: "",
      responsable: "",
      mobileResp: "",
      age: "",
      lieuResid: [],
      seletedLieuResid: "",
      listNivBase: [],
      selectedNivBase: "",
      facebook: "",
      fonction: "",
      selectedTypeFormat: 0,
      motivation: "",
      numIdentite: "",
      prenom: "",
      nom: "",
      disabled: false,
      connaissChoisi: [],
      listCycle: [],
      selectedCycle: "",
      email: "",
      tel: "",
      fax: "",
      photoVerso: null,
      nbrHeure: 0,
      blockReglement: false,
      carteFid: "",
      tenu: "",
      propCycle: "",
      reglInsCycle: [],
      societeCoch: false,
      tva: "",
      numCarteFid: "",
      mntCarte: "",
      mntTenu: "",
      nomCycle: "",
      dateDCycle: "",
      dateFCycle: "",
      horaire: "",
      mntTotalPayer: 0,
      listModPay: [],
      tabModPayFormat: [
        {
          modPay: "",
          mntModPay: 0,
          numCheque: "",
          echeanceChec: new Date(),
          dateEncaiss: new Date(),
          titulaireChec: "",
          banque: "",
        },
      ],
      tabModPayCycle: [
        {
          modPay: "",
          mntModPay: 0,
          numCheque: "",
          echeanceChec: new Date(),
          dateEncaiss: new Date(),
          titulaireChec: "",
          banque: "",
        },
      ],
      indexLignASupprimer: "",
      modalFormat: false,
      modalCycle: false,
      datePaiement: new Date(),
      mntCycleSansTva: "",
      selectedPayCycle: 2,
      arrayRegl: [],
      reglEleve: [],
      mntFraisTotalPayer: 0,
      mntIncripTotalPayer: 0,
      selectedBolPayFormat: 2,
      inforEleve: "",
      fraisReglEleve: [],
      modPayform: "",
      idInscriptionCycle: "",
      fraisCycle: [],
      mntFraisCycleTotalPayer: 0,
      elvId: "",
      idInsFormation: "",
      modePaiementFormation: "",
      disabledFraisFor: false,
      disabledRegFor: false,
      disabledFraisCycle: false,
      disabledRegCycle: false,
      arrayModePaiementFor: [],
      arrayPaiementFor: [],
      arrayModePaiementCycle: [],
      disbutIns: true,
      disbutReg: true,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/NumInscripCycle.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            numInscripCycle: code_html,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/NumAdheCycle.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            numCarteAdh: code_html,
          });
        },
      });
      axios
        .get(
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectResid.php"
        )
        .then((res) => {
          this.setState({
            lieuResid: res.data,
          });
        });
      axios
        .get(
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectNivBase.php"
        )
        .then((res) => {
          console.log(res);
          this.setState({
            listNivBase: res.data,
          });
        });
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectConnaissance.php"
        )
        .then((res) => {
          console.log(res);
          this.setState({
            listConnaissance: res.data,
          });
        });
    } else {
      document.location = "/";
    }
  }

  addTypeApp = (selectedTypeApp) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedTypeApp) {
      this.setState({ selectedTypeApp: selectedTypeApp }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectEleves.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listApprenant: code_html,
            });
          },
        });
      });
    }
  };

  addApprenant = (selectedApprenant) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState({ selectedApprenant: selectedApprenant }, () => {
      if (selectedApprenant != null) {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetInforApprenant.php?id=" +
            selectedApprenant.value,
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              numIdentite: code_html.numIdentite,
              nom: code_html.nom,
              prenom: code_html.prenom,
              age: code_html.age,
              selectedNivBase: code_html.niveau,
              email: code_html.email,
              tel: code_html.tel,
            });
          },
        });
      }
    });
  };

  addType = (selectedTypeFiche) => {
    this.setState({ selectedTypeFiche: selectedTypeFiche });
  };

  addCarteFid = (e) => {
    console.log("Checkbox checked:", e.target.checked);
    if (e.target.checked === true) {
      this.setState({
        carteFid: this.state.numCarteAdh,
      });
    } else {
      this.setState({
        carteFid: "",
      });
    }
  };

  addTenu = (e) => {
    console.log("Checkbox checked:", e.target.checked);
    if (e.target.checked === true) {
      this.setState({
        tenu: true,
      });
    } else {
      this.setState({
        tenu: false,
      });
    }
  };

  addNbrPersonne = (event) => {
    this.setState({
      nbrPersonne: event.target.value,
    });
  };

  addMatFisc = (event) => {
    this.setState({
      matFisc: event.target.value,
    });
  };

  addNbrPersTenu = (event) => {
    this.setState({
      nbrPersTenu: event.target.value,
    });
  };

  addSociete = (event) => {
    this.setState({
      societe: event.target.value,
    });
  };

  addResponsable = (event) => {
    this.setState({
      responsable: event.target.value,
    });
  };

  addMobile = (event) => {
    this.setState({
      mobileResp: event.target.value,
    });
  };

  addAge = (event) => {
    this.setState({
      age: event.target.value,
    });
  };

  addLieuResid = (seletedLieuResid) => {
    this.setState({ seletedLieuResid: seletedLieuResid });
  };

  addNivBase = (selectedNivBase) => {
    this.setState({ selectedNivBase });
  };

  addEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  addTel = (event) => {
    this.setState({
      tel: event.target.value,
    });
  };

  addFax = (event) => {
    this.setState({
      fax: event.target.value,
    });
  };

  addFacebook = (event) => {
    this.setState({
      facebook: event.target.value,
    });
  };

  addFct = (event) => {
    this.setState({
      fonction: event.target.value,
    });
  };

  addPhotoVerso(e) {
    this.setState({ photoVerso: e.target.files });
  }

  addTypeFormat = (selectedTypeFormat) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState({ selectedTypeFormat: selectedTypeFormat }, () => {
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectDetailCycleByTypeForCycle.php",
        type: "POST",
        data: {
          typeForCycle: selectedTypeFormat,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listCycle: code_html,
          });
        },
      });
    });
  };

  addMotivation = (event) => {
    this.setState({
      motivation: event.target.value,
    });
  };

  addNumIdentite = (event) => {
    this.setState({
      numIdentite: event.target.value,
    });
  };

  addPrenom = (event) => {
    this.setState({
      prenom: event.target.value,
    });
  };

  addNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  addConnaiss = (e, el) => {
    console.log("Checkbox checked:", e.target.checked);
    if (e.target.checked === true) {
      let element = el.value;
      this.setState({
        connaissChoisi: [...this.state.connaissChoisi, element],
      });
      console.log(this.state.connaissChoisi);
    } else {
      let element = el.value;
      let indice = this.state.connaissChoisi.indexOf(element);
      this.state.connaissChoisi.splice(indice, 1);
    }
  };

  addCycle = (selectedCycle) => {
    this.setState({ selectedCycle });
  };

  ajoutInscripCycle = () => {
    if (
      this.state.seletedLieuResid != "" &&
      this.state.selectedNivBase != "" &&
      this.state.selectedTypeFormat != 0 &&
      this.state.selectedCycle != 0
    ) {
      this.setState({ disbutIns: false }, () => {
        var lieuResid = this.state.seletedLieuResid.value;
        var niveauDeBase = this.state.selectedNivBase.value;
        var userPermissions = JSON.parse(
          localStorage.getItem("userPermissions")
        );
        var anneeScolaire = userPermissions[0].anneeScolaire;
        var userId = userPermissions[0].idUser;
        // Test si photo vide
        var pathPhotoVerso = "";
        if (this.state.photoVerso != null) {
          pathPhotoVerso = this.state.numIdentite;
        }
        // var detailCycles = []
        // for (var y = 0; y < this.state.selectedCycle.length; y++) {
        //     detailCycles.push(this.state.selectedCycle[y].value)
        // }
        // detailCycles = detailCycles.toString()
        var conaissanceChoix = this.state.connaissChoisi.toString();
        if (this.state.selectedTypeFiche == 1) {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/AddInscripCycle.php",
            type: "POST",
            data: {
              selectedApprenant: "",
              numIdentite: this.state.numIdentite,
              prenom: this.state.prenom,
              numInscripCycle: this.state.numInscripCycle,
              numCarteFid: this.state.carteFid,
              matFisc: this.state.matFisc,
              nbrPersonne: this.state.nbrPersonne,
              tenuBol: this.state.tenu,
              nbrPersTenu: this.state.nbrPersTenu,
              nom: this.state.societe,
              responsable: this.state.responsable,
              mobileResp: this.state.mobileResp,
              age: this.state.age,
              lieuResidId: lieuResid,
              niveauBase: niveauDeBase,
              email: this.state.email,
              tel: this.state.tel,
              fax: this.state.fax,
              facebook: this.state.facebook,
              fctActu: this.state.fonction,
              pathPhoto: pathPhotoVerso,
              typeFormat: this.state.selectedTypeFormat,
              typeFiche: this.state.selectedTypeFiche,
              nbrHeure: this.state.nbrHeure,
              motivation: this.state.motivation,
              connaissChoisi: conaissanceChoix,
              cycleChoisi: this.state.selectedCycle.value,
              anneeScolaire: anneeScolaire,
              userId: userId,
            },
            dataType: "json",
            success: (code_html, statut) => {
              if (code_html.Result == "OK") {
                axios
                  .get(
                    process.env.REACT_APP_API_URL +
                      "Back_mediatraining/SelectModePaiement.php"
                  )
                  .then((res) => {
                    console.log(res);
                    this.setState({
                      listModPay: res.data,
                    });
                  });
                this.setState(
                  {
                    blockReglement: true,
                    fraisCycle: code_html.fraisCycl,
                    reglInsCycle: code_html.regCycl,
                    societeCoch: code_html.regCycl.societeCoch,
                    tva: code_html.regCycl.tva,
                    numCarteFid: code_html.regCycl.numCarteFid,
                    mntCarte: code_html.regCycl.carte,
                    mntTenu: code_html.regCycl.tenu,
                    nomCycle: code_html.regCycl.cycle,
                    dateDCycle: code_html.regCycl.dateD,
                    dateFCycle: code_html.regCycl.dateF,
                    horaire: code_html.regCycl.horaire,
                    idInscriptionCycle: code_html.regCycl.idInscripCycle,
                  },
                  () => {
                    console.log(this.state.modPayform);
                    var mntCycleSansTva = this.state.reglInsCycle.mnt;
                    this.setState({
                      mntCycleSansTva: mntCycleSansTva,
                    });
                  }
                );
              } else {
                toast.error("⛔ Probléme lors de l'insertion !!", {
                  containerId: "A",
                });
              }
            },
          });
        } else if (this.state.selectedTypeFiche == 2) {
          var persAvecTenu = 0;
          if (this.state.tenu == true) {
            var persAvecTenu = 1;
          }
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/AddInscripCycle.php",
            type: "POST",
            data: {
              selectedApprenant: this.state.selectedApprenant,
              responsable: this.state.responsable,
              mobileResp: this.state.mobileResp,
              nbrHeure: this.state.nbrHeure,
              numInscripCycle: this.state.numInscripCycle,
              numCarteFid: this.state.carteFid,
              numIdentite: this.state.numIdentite,
              matFisc: this.state.matFisc,
              prenom: this.state.prenom,
              nom: this.state.nom,
              age: this.state.age,
              tenuBol: this.state.tenu,
              nbrPersonne: 1,
              nbrPersTenu: persAvecTenu,
              lieuResidId: lieuResid,
              niveauBase: niveauDeBase,
              email: this.state.email,
              tel: this.state.tel,
              fax: this.state.fax,
              facebook: this.state.facebook,
              fctActu: this.state.fonction,
              pathPhoto: pathPhotoVerso,
              typeFormat: this.state.selectedTypeFormat,
              typeFiche: this.state.selectedTypeFiche,
              motivation: this.state.motivation,
              connaissChoisi: conaissanceChoix,
              cycleChoisi: this.state.selectedCycle.value,
              anneeScolaire: anneeScolaire,
              userId: userId,
            },
            dataType: "json",
            success: (code_html, statut) => {
              if (code_html.Result == "OK") {
                axios
                  .get(
                    process.env.REACT_APP_API_URL +
                      "Back_mediatraining/SelectModePaiement.php"
                  )
                  .then((res) => {
                    console.log(res);
                    this.setState({
                      listModPay: res.data,
                    });
                  });
                if (this.state.selectedApprenant != "") {
                  this.setState(
                    {
                      arrayRegl: code_html.regFormat,
                      inforEleve: code_html.regFormat[0],
                      fraisReglEleve: code_html.regFormat[1],
                      reglEleve: code_html.regFormat[2],
                    },
                    () => {
                      console.log(this.state.inforEleve);
                      var idEleve = this.state.inforEleve[0].eleveId;
                      var idInsFormation = this.state.inforEleve[0].idIns;
                      var modePaiementFormation = this.state.inforEleve[0].mode;
                      this.setState({
                        elvId: idEleve,
                        idInsFormation: idInsFormation,
                        modePaiementFormation: modePaiementFormation,
                      });
                    }
                  );
                }
                this.setState(
                  {
                    blockReglement: true,
                    fraisCycle: code_html.fraisCycl,
                    reglInsCycle: code_html.regCycl,
                    societeCoch: code_html.regCycl.societeCoch,
                    tva: code_html.regCycl.tva,
                    numCarteFid: code_html.regCycl.numCarteFid,
                    mntCarte: code_html.regCycl.carte,
                    mntTenu: code_html.regCycl.tenu,
                    nomCycle: code_html.regCycl.cycle,
                    dateDCycle: code_html.regCycl.dateD,
                    dateFCycle: code_html.regCycl.dateF,
                    horaire: code_html.regCycl.horaire,
                    idInscriptionCycle: code_html.regCycl.idInscripCycle,
                  },
                  () => {
                    console.log(this.state.modPayform);
                    var mntCycleSansTva = this.state.reglInsCycle.mnt;
                    this.setState({
                      mntCycleSansTva: mntCycleSansTva,
                    });
                  }
                );
              } else {
                toast.error("⛔ Probléme lors de l'insertion !!", {
                  containerId: "A",
                });
              }
            },
          });
        }
      });
    } else {
      toast.error(
        "⛔ Veuillez verifier les champs lieu de residence,niveau d'inscription,type formation,cycle !!",
        { containerId: "A" }
      );
    }
  };

  addMntFraisPayer = (event, indexMntFraisPayer) => {
    var mntFraisPayer = event.target.value;
    var mntFrais = parseInt(
      this.state.fraisCycle[indexMntFraisPayer].mntFraisCycle
    );
    if (mntFrais >= mntFraisPayer) {
      this.setState(
        {
          fraisCycle: this.state.fraisCycle.map((el, id) =>
            id === indexMntFraisPayer
              ? Object.assign(el, { mntFraisCyclePayer: mntFraisPayer })
              : el
          ),
        },
        () => {
          var mntFraisRestant = mntFrais - mntFraisPayer;
          this.setState({
            fraisCycle: this.state.fraisCycle.map((el, id) =>
              id === indexMntFraisPayer
                ? Object.assign(el, {
                    mntFraisCycleRest:
                      Number.parseFloat(mntFraisRestant).toFixed(3),
                  })
                : el
            ),
          });
          var sommeMntFraisPayer = 0;
          for (var i = 0; i < this.state.fraisCycle.length; i++) {
            if (this.state.fraisCycle[i].mntFraisCyclePayer != "") {
              sommeMntFraisPayer += parseInt(
                this.state.fraisCycle[i].mntFraisCyclePayer
              );
            } else {
              sommeMntFraisPayer += 0;
            }
          }
          this.setState({
            mntFraisCycleTotalPayer:
              Number.parseFloat(sommeMntFraisPayer).toFixed(3),
          });
        }
      );
    } else {
      toast.error("⛔ Le montant saisie est supérieur !!", {
        containerId: "A",
      });
    }
  };

  addPayTotFraisCycle = (e, index) => {
    console.log("Checkbox checked:", e.target.checked);
    if (e.target.checked === true) {
      var arrayFrais = this.state.fraisCycle;
      var mntFraisCyclePayer = arrayFrais[index].mntFraisCycleRest;
      var elFrais = arrayFrais[index];
      elFrais: Object.assign(elFrais, {
        mntFraisCyclePayer: mntFraisCyclePayer,
      });
      this.setState(
        {
          fraisCycle: arrayFrais,
        },
        () => {
          var mntFraisCycleRest =
            this.state.fraisCycle[index].mntFraisCycle -
            this.state.fraisCycle[index].mntFraisCyclePayer;
          var arrayFrais = this.state.fraisCycle;
          var elFrais = arrayFrais[index];
          elFrais: Object.assign(elFrais, {
            mntFraisCycleRest: Number.parseFloat(mntFraisCycleRest).toFixed(3),
          });
          this.setState(
            {
              fraisCycle: arrayFrais,
            },
            () => {
              var sommeMntFraisPayer = 0;
              for (var i = 0; i < this.state.fraisCycle.length; i++) {
                if (this.state.fraisCycle[i].mntFraisCyclePayer != "") {
                  sommeMntFraisPayer += parseInt(
                    this.state.fraisCycle[i].mntFraisCyclePayer
                  );
                } else {
                  sommeMntFraisPayer += 0;
                }
              }
              this.setState({
                mntFraisCycleTotalPayer:
                  Number.parseFloat(sommeMntFraisPayer).toFixed(3),
              });
            }
          );
        }
      );
    } else {
      var arrayFrais = this.state.fraisCycle;
      var mntFraisCyclePayer = 0;
      var elFrais = arrayFrais[index];
      elFrais: Object.assign(elFrais, {
        mntFraisCyclePayer: mntFraisCyclePayer,
      });
      this.setState(
        {
          fraisCycle: arrayFrais,
        },
        () => {
          var mntFraisCycleRest =
            this.state.fraisCycle[index].mntFraisCycle -
            this.state.fraisCycle[index].mntFraisCyclePayer;
          var arrayFrais = this.state.fraisCycle;
          var elFrais = arrayFrais[index];
          elFrais: Object.assign(elFrais, {
            mntFraisCycleRest: Number.parseFloat(mntFraisCycleRest).toFixed(3),
          });
          this.setState(
            {
              fraisCycle: arrayFrais,
            },
            () => {
              var sommeMntFraisPayer = 0;
              for (var i = 0; i < this.state.fraisCycle.length; i++) {
                if (this.state.fraisCycle[i].mntFraisCyclePayer != "") {
                  sommeMntFraisPayer += parseInt(
                    this.state.fraisCycle[i].mntFraisCyclePayer
                  );
                } else {
                  sommeMntFraisPayer += 0;
                }
              }
              this.setState({
                mntFraisCycleTotalPayer:
                  Number.parseFloat(sommeMntFraisPayer).toFixed(3),
              });
            }
          );
        }
      );
    }
  };

  addMntCyclePay = (event) => {
    var mntCyclePay = event.target.value;
    var montantCycle = this.state.reglInsCycle.montantDuCycle;
    if (
      parseInt(mntCyclePay) <= parseInt(this.state.reglInsCycle.mntCycleRestant)
    ) {
      this.setState(
        {
          reglInsCycle: Object.assign(this.state.reglInsCycle, {
            mntCyclePay: mntCyclePay,
          }),
        },
        () => {
          var mntRestant = montantCycle - mntCyclePay;
          this.setState({
            reglInsCycle: Object.assign(this.state.reglInsCycle, {
              mntCycleRestant: Number.parseFloat(mntRestant).toFixed(3),
            }),
          });
        }
      );
    } else {
      toast.error("⛔ Le montant saisie est supérieur !!", {
        containerId: "A",
      });
    }
  };

  addPayTotRegCycle = (e) => {
    console.log("Checkbox checked:", e.target.checked);
    if (e.target.checked === true) {
      var arrayReg = this.state.reglInsCycle;
      var mntCyclePay = arrayReg.mntCycleRestant;
      var elReg = arrayReg;
      elReg: Object.assign(elReg, { mntCyclePay: mntCyclePay });
      this.setState(
        {
          reglInsCycle: arrayReg,
        },
        () => {
          var mntCycleRestant =
            this.state.reglInsCycle.montantDuCycle -
            this.state.reglInsCycle.mntCyclePay;
          var arrayReg = this.state.reglInsCycle;
          var elReg = arrayReg;
          elReg: Object.assign(elReg, {
            mntCycleRestant: Number.parseFloat(mntCycleRestant).toFixed(3),
          });
          this.setState({
            reglInsCycle: arrayReg,
          });
        }
      );
    } else {
      var arrayReg = this.state.reglInsCycle;
      var mntCyclePay = 0;
      var elReg = arrayReg;
      elReg: Object.assign(elReg, { mntCyclePay: mntCyclePay });
      this.setState(
        {
          reglInsCycle: arrayReg,
        },
        () => {
          var mntCycleRestant =
            this.state.reglInsCycle.montantDuCycle -
            this.state.reglInsCycle.mntCyclePay;
          var arrayReg = this.state.reglInsCycle;
          var elReg = arrayReg;
          elReg: Object.assign(elReg, {
            mntCycleRestant: Number.parseFloat(mntCycleRestant).toFixed(3),
          });
          this.setState({
            reglInsCycle: arrayReg,
          });
        }
      );
    }
  };

  addTva = (e) => {
    console.log("Checkbox checked:", e.target.checked);
    if (e.target.checked === true) {
      var mntCycle = parseInt(this.state.reglInsCycle.mnt);
      var tva = parseInt(this.state.tva);
      var mntCycleAvecTva = mntCycle + (mntCycle * tva) / 100;
      this.setState({
        reglInsCycle: Object.assign(this.state.reglInsCycle, {
          mnt: mntCycleAvecTva,
        }),
        reglInsCycle: Object.assign(this.state.reglInsCycle, {
          mntCycleRestant: mntCycleAvecTva,
        }),
        reglInsCycle: Object.assign(this.state.reglInsCycle, {
          montantDuCycle: mntCycleAvecTva,
        }),
      });
    } else {
      var mntCycleSansTva = this.state.mntCycleSansTva;
      this.setState({
        reglInsCycle: Object.assign(this.state.reglInsCycle, {
          mnt: mntCycleSansTva,
        }),
        reglInsCycle: Object.assign(this.state.reglInsCycle, {
          mntCycleRestant: mntCycleSansTva,
        }),
        reglInsCycle: Object.assign(this.state.reglInsCycle, {
          montantDuCycle: mntCycleSansTva,
        }),
      });
    }
  };

  addDatePaiement = (d) => {
    this.setState({
      datePaiement: d,
    });
  };

  // mode paiement formation
  additionLignFormat = () => {
    let element = {
      modPay: "",
      mntModPay: "",
      numCheque: "",
      echeanceChec: new Date(),
      dateEncaiss: new Date(),
      titulaireChec: "",
      banque: "",
    };
    this.setState({
      tabModPayFormat: [...this.state.tabModPayFormat, element],
    });
  };

  addModPayFormat = (selectedModPay, indexModPay) => {
    if (selectedModPay != null) {
      let modPay = selectedModPay;
      this.setState({
        tabModPayFormat: this.state.tabModPayFormat.map((el, id) =>
          id === indexModPay ? Object.assign(el, { modPay: modPay }) : el
        ),
      });
    }
  };

  addMntModPayFormat = (event, indexModPay) => {
    let mntModPay = event.target.value;
    this.setState({
      tabModPayFormat: this.state.tabModPayFormat.map((el, id) =>
        id === indexModPay ? Object.assign(el, { mntModPay: mntModPay }) : el
      ),
    });
  };

  addNumChecFormat = (event, indexModPay) => {
    let numCheque = event.target.value;
    this.setState({
      tabModPayFormat: this.state.tabModPayFormat.map((el, id) =>
        id === indexModPay ? Object.assign(el, { numCheque: numCheque }) : el
      ),
    });
  };

  addEcheanceChecFormat = (d, indexModPay) => {
    let dateChec = d;
    this.setState({
      tabModPayFormat: this.state.tabModPayFormat.map((el, id) =>
        id === indexModPay ? Object.assign(el, { echeanceChec: dateChec }) : el
      ),
    });
  };

  addDateEncaissFormat = (d, indexModPay) => {
    let dateEncaiss = d;
    this.setState({
      tabModPayFormat: this.state.tabModPayFormat.map((el, id) =>
        id === indexModPay
          ? Object.assign(el, { dateEncaiss: dateEncaiss })
          : el
      ),
    });
  };

  addTitulaireChecFormat = (event, indexModPay) => {
    let titulaireChec = event.target.value;
    this.setState({
      tabModPayFormat: this.state.tabModPayFormat.map((el, id) =>
        id === indexModPay
          ? Object.assign(el, { titulaireChec: titulaireChec })
          : el
      ),
    });
  };

  addBanqueFormat = (event, indexModPay) => {
    let banque = event.target.value;
    this.setState({
      tabModPayFormat: this.state.tabModPayFormat.map((el, id) =>
        id === indexModPay ? Object.assign(el, { banque: banque }) : el
      ),
    });
  };

  toggleLignFormat = (index) => {
    this.setState((prevState) => ({
      modalFormat: !prevState.modalFormat,
      indexLignASupprimer: index,
    }));
  };

  deleteLignFormat = () => {
    this.state.tabModPayFormat.splice(this.state.indexLignASupprimer, 1);
    this.setState((prevState) => ({
      modalFormat: !prevState.modalFormat,
    }));
  };

  nonDeleteLignFormat = () => {
    this.setState((prevState) => ({
      modalFormat: !prevState.modalFormat,
    }));
  };

  // mode paiement cycle
  additionLignCycle = () => {
    let element = {
      modPay: "",
      mntModPay: "",
      numCheque: "",
      echeanceChec: new Date(),
      dateEncaiss: new Date(),
      titulaireChec: "",
      banque: "",
    };
    this.setState({
      tabModPayCycle: [...this.state.tabModPayCycle, element],
    });
  };

  addModPayCycle = (selectedModPay, indexModPay) => {
    if (selectedModPay != null) {
      let modPay = selectedModPay;
      this.setState({
        tabModPayCycle: this.state.tabModPayCycle.map((el, id) =>
          id === indexModPay ? Object.assign(el, { modPay: modPay }) : el
        ),
      });
    }
  };

  addMntModPayCycle = (event, indexModPay) => {
    let mntModPay = event.target.value;
    this.setState({
      tabModPayCycle: this.state.tabModPayCycle.map((el, id) =>
        id === indexModPay ? Object.assign(el, { mntModPay: mntModPay }) : el
      ),
    });
  };

  addNumChecCycle = (event, indexModPay) => {
    let numCheque = event.target.value;
    this.setState({
      tabModPayCycle: this.state.tabModPayCycle.map((el, id) =>
        id === indexModPay ? Object.assign(el, { numCheque: numCheque }) : el
      ),
    });
  };

  addEcheanceChecCycle = (d, indexModPay) => {
    let dateChec = d;
    this.setState({
      tabModPayCycle: this.state.tabModPayCycle.map((el, id) =>
        id === indexModPay ? Object.assign(el, { echeanceChec: dateChec }) : el
      ),
    });
  };

  addDateEncaissCycle = (d, indexModPay) => {
    let dateEncaiss = d;
    this.setState({
      tabModPayCycle: this.state.tabModPayCycle.map((el, id) =>
        id === indexModPay
          ? Object.assign(el, { dateEncaiss: dateEncaiss })
          : el
      ),
    });
  };

  addTitulaireChecCycle = (event, indexModPay) => {
    let titulaireChec = event.target.value;
    this.setState({
      tabModPayCycle: this.state.tabModPayCycle.map((el, id) =>
        id === indexModPay
          ? Object.assign(el, { titulaireChec: titulaireChec })
          : el
      ),
    });
  };

  addBanqueCycle = (event, indexModPay) => {
    let banque = event.target.value;
    this.setState({
      tabModPayCycle: this.state.tabModPayCycle.map((el, id) =>
        id === indexModPay ? Object.assign(el, { banque: banque }) : el
      ),
    });
  };

  toggleLignCycle = (index) => {
    this.setState((prevState) => ({
      modalCycle: !prevState.modalCycle,
      indexLignASupprimer: index,
    }));
  };

  deleteLignCycle = () => {
    this.state.tabModPayCycle.splice(this.state.indexLignASupprimer, 1);
    this.setState((prevState) => ({
      modalCycle: !prevState.modalCycle,
    }));
  };

  nonDeleteLignCycle = () => {
    this.setState((prevState) => ({
      modalCycle: !prevState.modalCycle,
    }));
  };

  //

  addBolPayFormat = (selectedBolPayFormat) => {
    this.setState({ selectedBolPayFormat: selectedBolPayFormat });
  };

  updMntFraisPayer = (event, indexMntFraisPayer) => {
    var mntFraisPay = event.target.value;
    var mntTotal = parseInt(
      this.state.fraisReglEleve[indexMntFraisPayer].mntTotal
    );
    var mntDejaPayer = parseInt(
      this.state.fraisReglEleve[indexMntFraisPayer].mntPay
    );
    if (mntDejaPayer == 0) {
      if (mntTotal >= mntFraisPay) {
        this.setState(
          {
            fraisReglEleve: this.state.fraisReglEleve.map((el, id) =>
              id === indexMntFraisPayer
                ? Object.assign(el, { mnt: mntFraisPay })
                : el
            ),
          },
          () => {
            var mntRestNv = mntTotal - mntFraisPay;
            this.setState({
              fraisReglEleve: this.state.fraisReglEleve.map((el, id) =>
                id === indexMntFraisPayer
                  ? Object.assign(el, {
                      mntRest: Number.parseFloat(mntRestNv).toFixed(3),
                    })
                  : el
              ),
            });
            var sommeMntFraisPayer = 0;
            for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
              if (this.state.fraisReglEleve[i].mnt != "") {
                sommeMntFraisPayer += parseInt(
                  this.state.fraisReglEleve[i].mnt
                );
              } else {
                sommeMntFraisPayer += 0;
              }
            }
            this.setState({
              mntFraisTotalPayer:
                Number.parseFloat(sommeMntFraisPayer).toFixed(3),
            });
          }
        );
      } else {
        toast.error("⛔ Le montant saisie est supérieur !!", {
          containerId: "A",
        });
      }
    } else if (mntDejaPayer > 0) {
      var restMnt = mntTotal - mntDejaPayer;
      if (parseInt(restMnt) >= parseInt(mntFraisPay)) {
        this.setState(
          {
            fraisReglEleve: this.state.fraisReglEleve.map((el, id) =>
              id === indexMntFraisPayer
                ? Object.assign(el, { mnt: mntFraisPay })
                : el
            ),
          },
          () => {
            if (mntFraisPay > 0) {
              var mntRestNv = restMnt - mntFraisPay;
              this.setState({
                fraisReglEleve: this.state.fraisReglEleve.map((el, id) =>
                  id === indexMntFraisPayer
                    ? Object.assign(el, {
                        mntRest: Number.parseFloat(mntRestNv).toFixed(3),
                      })
                    : el
                ),
              });
            } else if (mntFraisPay == 0) {
              var mntRestNv = mntTotal - mntDejaPayer;
              this.setState({
                fraisReglEleve: this.state.fraisReglEleve.map((el, id) =>
                  id === indexMntFraisPayer
                    ? Object.assign(el, {
                        mntRest: Number.parseFloat(mntRestNv).toFixed(3),
                      })
                    : el
                ),
              });
            }
            var sommeMntFraisPayer = 0;
            for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
              if (this.state.fraisReglEleve[i].mnt != "") {
                sommeMntFraisPayer += parseInt(
                  this.state.fraisReglEleve[i].mnt
                );
              } else {
                sommeMntFraisPayer += 0;
              }
            }
            this.setState({
              mntFraisTotalPayer:
                Number.parseFloat(sommeMntFraisPayer).toFixed(3),
            });
          }
        );
      } else {
        toast.error("⛔ Le montant saisie est supérieur !!", {
          containerId: "A",
        });
      }
    }
  };

  addPayTotFraisFor = (e, index) => {
    console.log("Checkbox checked:", e.target.checked);
    if (e.target.checked === true) {
      var arrayFrais = this.state.fraisReglEleve;
      var mnt = arrayFrais[index].mntRest;
      var elFrais = arrayFrais[index];
      elFrais: Object.assign(elFrais, { mnt: mnt });
      this.setState(
        {
          fraisReglEleve: arrayFrais,
        },
        () => {
          var mntRest =
            this.state.fraisReglEleve[index].mntTotal -
            (parseInt(this.state.fraisReglEleve[index].mntPay) +
              parseInt(this.state.fraisReglEleve[index].mnt));
          var arrayFrais = this.state.fraisReglEleve;
          var elFrais = arrayFrais[index];
          elFrais: Object.assign(elFrais, {
            mntRest: Number.parseFloat(mntRest).toFixed(3),
          });
          this.setState(
            {
              fraisReglEleve: arrayFrais,
            },
            () => {
              var sommeMntFraisPayer = 0;
              for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                if (this.state.fraisReglEleve[i].mnt != "") {
                  sommeMntFraisPayer += parseInt(
                    this.state.fraisReglEleve[i].mnt
                  );
                } else {
                  sommeMntFraisPayer += 0;
                }
              }
              this.setState({
                mntFraisTotalPayer:
                  Number.parseFloat(sommeMntFraisPayer).toFixed(3),
              });
            }
          );
        }
      );
    } else {
      var arrayFrais = this.state.fraisReglEleve;
      var mnt = 0;
      var elFrais = arrayFrais[index];
      elFrais: Object.assign(elFrais, { mnt: mnt });
      this.setState(
        {
          fraisReglEleve: arrayFrais,
        },
        () => {
          var mntRest =
            this.state.fraisReglEleve[index].mntTotal -
            (parseInt(this.state.fraisReglEleve[index].mntPay) +
              parseInt(this.state.fraisReglEleve[index].mnt));
          var arrayFrais = this.state.fraisReglEleve;
          var elFrais = arrayFrais[index];
          elFrais: Object.assign(elFrais, {
            mntRest: Number.parseFloat(mntRest).toFixed(3),
          });
          this.setState(
            {
              fraisReglEleve: arrayFrais,
            },
            () => {
              var sommeMntFraisPayer = 0;
              for (var i = 0; i < this.state.fraisReglEleve.length; i++) {
                if (this.state.fraisReglEleve[i].mnt != "") {
                  sommeMntFraisPayer += parseInt(
                    this.state.fraisReglEleve[i].mnt
                  );
                } else {
                  sommeMntFraisPayer += 0;
                }
              }
              this.setState({
                mntFraisTotalPayer:
                  Number.parseFloat(sommeMntFraisPayer).toFixed(3),
              });
            }
          );
        }
      );
    }
  };

  updMntInscripPayer = (event, indexMntInscripPayer) => {
    let mntPaiement = event.target.value;
    let mntAPayer = this.state.reglEleve[indexMntInscripPayer].mntAPayer;
    let mntDejaPayer = this.state.reglEleve[indexMntInscripPayer].mntPayer;
    if (mntDejaPayer == 0) {
      if (parseInt(mntAPayer) >= parseInt(mntPaiement)) {
        this.setState(
          {
            reglEleve: this.state.reglEleve.map((el, id) =>
              id === indexMntInscripPayer
                ? Object.assign(el, { mnt: mntPaiement })
                : el
            ),
          },
          () => {
            let mntInscripRestNv = mntAPayer - mntPaiement;
            this.setState({
              reglEleve: this.state.reglEleve.map((el, id) =>
                id === indexMntInscripPayer
                  ? Object.assign(el, {
                      mntRestant:
                        Number.parseFloat(mntInscripRestNv).toFixed(3),
                    })
                  : el
              ),
            });
            let mntIncripTotalPayer = 0;
            for (var i = 0; i < this.state.reglEleve.length; i++) {
              mntIncripTotalPayer += parseInt(this.state.reglEleve[i].mnt);
            }
            this.setState({
              mntIncripTotalPayer:
                Number.parseFloat(mntIncripTotalPayer).toFixed(3),
            });
          }
        );
      } else {
        toast.error("⛔ Le montant saisie est supérieur !!", {
          containerId: "A",
        });
      }
    } else if (mntDejaPayer > 0) {
      var restMnt = mntAPayer - mntDejaPayer;
      if (parseInt(restMnt) >= parseInt(mntPaiement)) {
        this.setState(
          {
            reglEleve: this.state.reglEleve.map((el, id) =>
              id === indexMntInscripPayer
                ? Object.assign(el, { mnt: mntPaiement })
                : el
            ),
          },
          () => {
            if (mntPaiement > 0) {
              var mntInscripRestNv = restMnt - mntPaiement;
              this.setState({
                reglEleve: this.state.reglEleve.map((el, id) =>
                  id === indexMntInscripPayer
                    ? Object.assign(el, {
                        mntRestant:
                          Number.parseFloat(mntInscripRestNv).toFixed(3),
                      })
                    : el
                ),
              });
            } else if (mntPaiement == 0) {
              var mntInscripRestNv = mntAPayer - mntDejaPayer;
              this.setState({
                reglEleve: this.state.reglEleve.map((el, id) =>
                  id === indexMntInscripPayer
                    ? Object.assign(el, {
                        mntRestant:
                          Number.parseFloat(mntInscripRestNv).toFixed(3),
                      })
                    : el
                ),
              });
            }
            let mntIncripTotalPayer = 0;
            for (var i = 0; i < this.state.reglEleve.length; i++) {
              mntIncripTotalPayer += parseInt(this.state.reglEleve[i].mnt);
            }
            this.setState({
              mntIncripTotalPayer:
                Number.parseFloat(mntIncripTotalPayer).toFixed(3),
            });
          }
        );
      } else {
        toast.error("⛔ Le montant saisie est supérieur !!", {
          containerId: "A",
        });
      }
    }
  };

  addPayTotRegFor = (e, index) => {
    console.log("Checkbox checked:", e.target.checked);
    if (e.target.checked === true) {
      var arrayReg = this.state.reglEleve;
      var mnt = arrayReg[index].mntRestant;
      var elReg = arrayReg[index];
      elReg: Object.assign(elReg, { mnt: mnt });
      this.setState(
        {
          reglEleve: arrayReg,
        },
        () => {
          var mntRestant =
            this.state.reglEleve[index].mntAPayer -
            (parseInt(this.state.reglEleve[index].mntPayer) +
              parseInt(this.state.reglEleve[index].mnt));
          var arrayReg = this.state.reglEleve;
          var elReg = arrayReg[index];
          elReg: Object.assign(elReg, {
            mntRestant: Number.parseFloat(mntRestant).toFixed(3),
          });
          this.setState(
            {
              reglEleve: arrayReg,
            },
            () => {
              var mntIncripTotalPayer = 0;
              for (var i = 0; i < this.state.reglEleve.length; i++) {
                mntIncripTotalPayer += parseInt(this.state.reglEleve[i].mnt);
              }
              this.setState({
                mntIncripTotalPayer:
                  Number.parseFloat(mntIncripTotalPayer).toFixed(3),
              });
            }
          );
        }
      );
    } else {
      var arrayReg = this.state.reglEleve;
      var mnt = 0;
      var elReg = arrayReg[index];
      elReg: Object.assign(elReg, { mnt: mnt });
      this.setState(
        {
          reglEleve: arrayReg,
        },
        () => {
          var mntRestant =
            this.state.reglEleve[index].mntAPayer -
            (parseInt(this.state.reglEleve[index].mntPayer) +
              parseInt(this.state.reglEleve[index].mnt));
          var arrayReg = this.state.reglEleve;
          var elReg = arrayReg[index];
          elReg: Object.assign(elReg, {
            mntRestant: Number.parseFloat(mntRestant).toFixed(3),
          });
          this.setState(
            {
              reglEleve: arrayReg,
            },
            () => {
              var mntIncripTotalPayer = 0;
              for (var i = 0; i < this.state.reglEleve.length; i++) {
                mntIncripTotalPayer += parseInt(this.state.reglEleve[i].mnt);
              }
              this.setState({
                mntIncripTotalPayer:
                  Number.parseFloat(mntIncripTotalPayer).toFixed(3),
              });
            }
          );
        }
      );
    }
  };

  ajoutRegInscripCycle = () => {
    if (this.state.selectedBolPayFormat == 1) {
      var sommePaiement = 0;
      sommePaiement =
        parseInt(this.state.mntFraisTotalPayer) +
        parseInt(this.state.mntIncripTotalPayer) +
        parseInt(this.state.mntFraisCycleTotalPayer) +
        parseInt(this.state.reglInsCycle.mntCyclePay);
      var sommeRegFormat = 0;
      for (var i = 0; i < this.state.tabModPayFormat.length; i++) {
        sommeRegFormat += parseInt(this.state.tabModPayFormat[i].mntModPay);
      }
      var sommeRegCycle = 0;
      for (var i = 0; i < this.state.tabModPayCycle.length; i++) {
        sommeRegCycle += parseInt(this.state.tabModPayCycle[i].mntModPay);
      }
      var sommeTot = sommeRegFormat + sommeRegCycle;
      if (sommePaiement !== sommeTot) {
        toast.error("⛔ Veuillez vérifier les sommes saisie !", {
          containerId: "A",
        });
      } else {
        this.setState({ disbutReg: false }, () => {
          var userPermissions = JSON.parse(
            localStorage.getItem("userPermissions")
          );
          var anneeScolaire = userPermissions[0].anneeScolaire;
          var userId = userPermissions[0].idUser;
          // convert date paiement
          let datePaiement = this.state.datePaiement;
          let month = "" + (datePaiement.getMonth() + 1);
          let day = "" + datePaiement.getDate();
          let year = datePaiement.getFullYear();
          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;
          var convertDatePaiement = [year, month, day].join("-");
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/AddRegInscripCycl.php",
            type: "POST",
            data: {
              bolPayFormat: this.state.selectedBolPayFormat,
              inforEleve: this.state.inforEleve,
              eleveId: this.state.elvId,
              idInsFormation: this.state.idInsFormation,
              fraisFormation: this.state.fraisReglEleve,
              reglFormation: this.state.reglEleve,
              modPayFormat: this.state.tabModPayFormat,
              modePaiementFormation: this.state.modePaiementFormation,
              // echeChec: formatDateEcheChec,
              // encaissChec: formatDateEncaissChec,
              idInscriptionCycle: this.state.idInscriptionCycle,
              fraisCycle: this.state.fraisCycle,
              reglInsCycle: this.state.reglInsCycle,
              modPayCycle: this.state.tabModPayCycle,
              // dateEcheChec: convertDateEcheChec,
              // dateEncaissChec: convertDateEncaissChec,
              anneeScolaire: anneeScolaire,
              userId: userId,
              datePaiement: convertDatePaiement,
              montantTotalFormat: sommeRegFormat,
              montantTotalCycle: sommeRegCycle,
            },
            dataType: "json",
            success: (code_html, statut) => {
              // Recu reglement formation
              var idRegFor = code_html.idRegFor;
              if (idRegFor != 0) {
                var incluEsp = false;
                var incluChec = false;
                // selon le type de paiement
                jQuery.ajax({
                  url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/ApprenantsTypePayFor.php",
                  type: "POST",
                  data: {
                    idRegFor: idRegFor,
                  },
                  dataType: "json",
                  success: (code_html, statut) => {
                    this.setState(
                      {
                        arrayModePaiement: code_html,
                      },
                      () => {
                        if (
                          this.state.arrayModePaiement.includes("1") ||
                          this.state.arrayModePaiement.includes("3")
                        ) {
                          incluEsp = true;
                        }
                        if (this.state.arrayModePaiement.includes("2")) {
                          incluChec = true;
                        }
                        console.log(incluEsp);
                        console.log(incluChec);
                        if (incluChec == true) {
                          alert("Chéque");
                          // si le paiement contient un paiement du frais ou du niveau ou du deux
                          jQuery.ajax({
                            url:
                              process.env.REACT_APP_API_URL +
                              "Back_mediatraining/PartiePayerFor.php",
                            type: "POST",
                            data: {
                              idRegFor: idRegFor,
                            },
                            dataType: "json",
                            success: (code_html, statut) => {
                              console.log(code_html);
                              this.setState(
                                {
                                  arrayPaiement: code_html,
                                },
                                () => {
                                  if (
                                    this.state.arrayPaiement.includes("1") &&
                                    this.state.arrayPaiement.includes("2")
                                  ) {
                                    // Recu frais
                                    jQuery.ajax({
                                      url:
                                        process.env.REACT_APP_API_URL +
                                        "Back_mediatraining/RecuRegForFraisChec.php",
                                      type: "POST",
                                      data: {
                                        idRegFor: idRegFor,
                                      },
                                      dataType: "json",
                                      success: (code_html, statut) => {
                                        var server_path = code_html.server_path;
                                        var file_path = code_html.file_path;
                                        var path = server_path + file_path;
                                        window.open(path, "_blank");
                                      },
                                    });
                                    // Recu tranche
                                    jQuery.ajax({
                                      url:
                                        process.env.REACT_APP_API_URL +
                                        "Back_mediatraining/RecuRegForTrchChec.php",
                                      type: "POST",
                                      data: {
                                        idRegFor: idRegFor,
                                      },
                                      dataType: "json",
                                      success: (code_html, statut) => {
                                        var server_path = code_html.server_path;
                                        var file_path = code_html.file_path;
                                        var path = server_path + file_path;
                                        window.open(path, "_blank");
                                      },
                                    });
                                  } else if (
                                    this.state.arrayPaiement.includes("1")
                                  ) {
                                    // Recu frais
                                    jQuery.ajax({
                                      url:
                                        process.env.REACT_APP_API_URL +
                                        "Back_mediatraining/RecuRegForFraisChec.php",
                                      type: "POST",
                                      data: {
                                        idRegFor: idRegFor,
                                      },
                                      dataType: "json",
                                      success: (code_html, statut) => {
                                        var server_path = code_html.server_path;
                                        var file_path = code_html.file_path;
                                        var path = server_path + file_path;
                                        window.open(path, "_blank");
                                      },
                                    });
                                  } else if (
                                    this.state.arrayPaiement.includes("2")
                                  ) {
                                    // Recu tranche
                                    jQuery.ajax({
                                      url:
                                        process.env.REACT_APP_API_URL +
                                        "Back_mediatraining/RecuRegForTrchChec.php",
                                      type: "POST",
                                      data: {
                                        idRegFor: idRegFor,
                                      },
                                      dataType: "json",
                                      success: (code_html, statut) => {
                                        var server_path = code_html.server_path;
                                        var file_path = code_html.file_path;
                                        var path = server_path + file_path;
                                        window.open(path, "_blank");
                                      },
                                    });
                                  }
                                }
                              );
                            },
                          });
                        } else if (incluEsp == true) {
                          alert("espéce");
                          // si le paiement contient un paiement du frais ou du niveau ou du deux
                          jQuery.ajax({
                            url:
                              process.env.REACT_APP_API_URL +
                              "Back_mediatraining/PartiePayerFor.php",
                            type: "POST",
                            data: {
                              idRegFor: idRegFor,
                            },
                            dataType: "json",
                            success: (code_html, statut) => {
                              console.log(code_html);
                              this.setState(
                                {
                                  arrayPaiement: code_html,
                                },
                                () => {
                                  if (
                                    this.state.arrayPaiement.includes("1") &&
                                    this.state.arrayPaiement.includes("2")
                                  ) {
                                    // Recu frais
                                    jQuery.ajax({
                                      url:
                                        process.env.REACT_APP_API_URL +
                                        "Back_mediatraining/RecuRegForFraisEsp.php",
                                      type: "POST",
                                      data: {
                                        idRegFor: idRegFor,
                                      },
                                      dataType: "json",
                                      success: (code_html, statut) => {
                                        var server_path = code_html.server_path;
                                        var file_path = code_html.file_path;
                                        var path = server_path + file_path;
                                        window.open(path, "_blank");
                                      },
                                    });
                                    // Recu tranche
                                    jQuery.ajax({
                                      url:
                                        process.env.REACT_APP_API_URL +
                                        "Back_mediatraining/RecuRegForTrchEsp.php",
                                      type: "POST",
                                      data: {
                                        idRegFor: idRegFor,
                                      },
                                      dataType: "json",
                                      success: (code_html, statut) => {
                                        var server_path = code_html.server_path;
                                        var file_path = code_html.file_path;
                                        var path = server_path + file_path;
                                        window.open(path, "_blank");
                                      },
                                    });
                                  } else if (
                                    this.state.arrayPaiement.includes("1")
                                  ) {
                                    // Recu frais
                                    jQuery.ajax({
                                      url:
                                        process.env.REACT_APP_API_URL +
                                        "Back_mediatraining/RecuRegForFraisEsp.php",
                                      type: "POST",
                                      data: {
                                        idRegFor: idRegFor,
                                      },
                                      dataType: "json",
                                      success: (code_html, statut) => {
                                        var server_path = code_html.server_path;
                                        var file_path = code_html.file_path;
                                        var path = server_path + file_path;
                                        window.open(path, "_blank");
                                      },
                                    });
                                  } else if (
                                    this.state.arrayPaiement.includes("2")
                                  ) {
                                    // Recu tranche
                                    jQuery.ajax({
                                      url:
                                        process.env.REACT_APP_API_URL +
                                        "Back_mediatraining/RecuRegForTrchEsp.php",
                                      type: "POST",
                                      data: {
                                        idRegFor: idRegFor,
                                      },
                                      dataType: "json",
                                      success: (code_html, statut) => {
                                        var server_path = code_html.server_path;
                                        var file_path = code_html.file_path;
                                        var path = server_path + file_path;
                                        window.open(path, "_blank");
                                      },
                                    });
                                  }
                                }
                              );
                            },
                          });
                        }
                      }
                    );
                  },
                });
              }
              // Recu reglement cycle
              var idRegCycle = code_html.idRegCycle;

              var incluEspCycle = false;
              var incluChecCycle = false;
              jQuery.ajax({
                url:
                  process.env.REACT_APP_API_URL +
                  "Back_mediatraining/ApprenantsTypePayCycle.php",
                type: "POST",
                data: {
                  idRegCycle: idRegCycle,
                },
                dataType: "json",
                success: (code_html, statut) => {
                  this.setState(
                    {
                      arrayModePaiementCycle: code_html,
                    },
                    () => {
                      if (
                        this.state.arrayModePaiementFor.includes("1") ||
                        this.state.arrayModePaiementFor.includes("3")
                      ) {
                        incluEspCycle = true;
                      }
                      if (this.state.arrayModePaiementFor.includes("2")) {
                        incluChecCycle = true;
                      }
                      if (incluChecCycle == true) {
                        jQuery.ajax({
                          url:
                            process.env.REACT_APP_API_URL +
                            "Back_mediatraining/RecuRegCycleChec.php",
                          type: "POST",
                          data: {
                            idRegCycle: idRegCycle,
                          },
                          dataType: "json",
                          success: (code_html, statut) => {
                            var server_path = code_html.server_path;
                            var file_path = code_html.file_path;
                            var path = server_path + file_path;
                            window.open(path, "_blank");
                          },
                        });
                        //Test si photo vide
                        if (this.state.photoVerso != null) {
                          let files = this.state.photoVerso;
                          let reader = new FileReader();
                          reader.readAsDataURL(files[0]);
                          reader.onload = (e) => {
                            console.log(e);
                            jQuery.ajax({
                              url:
                                process.env.REACT_APP_API_URL +
                                "Back_mediatraining/UploadphotoInscripCycle.php",
                              type: "POST",
                              data: {
                                file: e.target.result,
                                nomfile: this.state.numIdentite,
                              },
                              dataType: "json",
                              success: (code_html, statut) => {
                                this.props.history.push("/ReglApprenantCycle");
                              },
                            });
                          };
                        } else {
                          this.props.history.push("/ReglApprenantCycle");
                        }
                      } else if ((incluEspCycle = true)) {
                        jQuery.ajax({
                          url:
                            process.env.REACT_APP_API_URL +
                            "Back_mediatraining/RecuRegCycleEsp.php",
                          type: "POST",
                          data: {
                            idRegCycle: idRegCycle,
                          },
                          dataType: "json",
                          success: (code_html, statut) => {
                            var server_path = code_html.server_path;
                            var file_path = code_html.file_path;
                            var path = server_path + file_path;
                            window.open(path, "_blank");
                          },
                        });
                        //Test si photo vide
                        if (this.state.photoVerso != null) {
                          let files = this.state.photoVerso;
                          let reader = new FileReader();
                          reader.readAsDataURL(files[0]);
                          reader.onload = (e) => {
                            console.log(e);
                            jQuery.ajax({
                              url:
                                process.env.REACT_APP_API_URL +
                                "Back_mediatraining/UploadphotoInscripCycle.php",
                              type: "POST",
                              data: {
                                file: e.target.result,
                                nomfile: this.state.numIdentite,
                              },
                              dataType: "json",
                              success: (code_html, statut) => {
                                this.props.history.push("/ReglApprenantCycle");
                              },
                            });
                          };
                        } else {
                          this.props.history.push("/ReglApprenantCycle");
                        }
                      }
                    }
                  );
                },
              });
            },
          });
        });
      }
    } else {
      var sommeFraisCycle = 0;
      for (var j = 0; j < this.state.fraisCycle.length; j++) {
        sommeFraisCycle += parseInt(
          this.state.fraisCycle[j].mntFraisCyclePayer
        );
      }
      var sommePaiement = 0;
      sommePaiement =
        parseInt(this.state.reglInsCycle.mntCyclePay) + sommeFraisCycle;
      var sommeReg = 0;
      for (var i = 0; i < this.state.tabModPayCycle.length; i++) {
        sommeReg += parseInt(this.state.tabModPayCycle[i].mntModPay);
      }
      if (sommePaiement !== sommeReg) {
        toast.error("⛔ Veuillez vérifier les sommes saisie !", {
          containerId: "A",
        });
      } else {
        this.setState({ disbutReg: false }, () => {
          var userPermissions = JSON.parse(
            localStorage.getItem("userPermissions")
          );
          var anneeScolaire = userPermissions[0].anneeScolaire;
          var userId = userPermissions[0].idUser;
          // convert date paiement
          let datePaiement = this.state.datePaiement;
          let month = "" + (datePaiement.getMonth() + 1);
          let day = "" + datePaiement.getDate();
          let year = datePaiement.getFullYear();
          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;
          var convertDatePaiement = [year, month, day].join("-");
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/AddRegInscripCycl.php",
            type: "POST",
            data: {
              bolPayFormat: this.state.selectedBolPayFormat,
              fraisCycle: this.state.fraisCycle,
              reglInsCycle: this.state.reglInsCycle,
              idInscriptionCycle: this.state.idInscriptionCycle,
              modPayCycle: this.state.tabModPayCycle,
              // dateEcheChec: convertDateEcheChec,
              // dateEncaissChec: convertDateEncaissChec,
              anneeScolaire: anneeScolaire,
              userId: userId,
              datePaiement: convertDatePaiement,
              montantTotalCycle: sommeReg,
            },
            dataType: "json",
            success: (code_html, statut) => {
              // Recu reglement cycle
              var idRegCycle = code_html.idRegCycle;
              var incluEspCycle = false;
              var incluChecCycle = false;
              jQuery.ajax({
                url:
                  process.env.REACT_APP_API_URL +
                  "Back_mediatraining/ApprenantsTypePayCycle.php",
                type: "POST",
                data: {
                  idRegCycle: idRegCycle,
                },
                dataType: "json",
                success: (code_html, statut) => {
                  this.setState(
                    {
                      arrayModePaiementCycle: code_html,
                    },
                    () => {
                      if (
                        this.state.arrayModePaiementCycle.includes("1") ||
                        this.state.arrayModePaiementCycle.includes("3")
                      ) {
                        incluEspCycle = true;
                      }
                      if (this.state.arrayModePaiementCycle.includes("2")) {
                        incluChecCycle = true;
                      }
                      if (incluChecCycle == true) {
                        jQuery.ajax({
                          url:
                            process.env.REACT_APP_API_URL +
                            "Back_mediatraining/RecuRegCycleChec.php",
                          type: "POST",
                          data: {
                            idRegCycle: idRegCycle,
                          },
                          dataType: "json",
                          success: (code_html, statut) => {
                            var server_path = code_html.server_path;
                            var file_path = code_html.file_path;
                            var path = server_path + file_path;
                            window.open(path, "_blank");
                          },
                        });
                        //Test si photo vide
                        if (this.state.photoVerso != null) {
                          let files = this.state.photoVerso;
                          let reader = new FileReader();
                          reader.readAsDataURL(files[0]);
                          reader.onload = (e) => {
                            console.log(e);
                            jQuery.ajax({
                              url:
                                process.env.REACT_APP_API_URL +
                                "Back_mediatraining/UploadphotoInscripCycle.php",
                              type: "POST",
                              data: {
                                file: e.target.result,
                                nomfile: this.state.numIdentite,
                              },
                              dataType: "json",
                              success: (code_html, statut) => {
                                this.props.history.push("/ReglApprenantCycle");
                              },
                            });
                          };
                        } else {
                          this.props.history.push("/ReglApprenantCycle");
                        }
                      } else if ((incluEspCycle = true)) {
                        jQuery.ajax({
                          url:
                            process.env.REACT_APP_API_URL +
                            "Back_mediatraining/RecuRegCycleEsp.php",
                          type: "POST",
                          data: {
                            idRegCycle: idRegCycle,
                          },
                          dataType: "json",
                          success: (code_html, statut) => {
                            var server_path = code_html.server_path;
                            var file_path = code_html.file_path;
                            var path = server_path + file_path;
                            window.open(path, "_blank");
                          },
                        });
                        //Test si photo vide
                        if (this.state.photoVerso != null) {
                          let files = this.state.photoVerso;
                          let reader = new FileReader();
                          reader.readAsDataURL(files[0]);
                          reader.onload = (e) => {
                            console.log(e);
                            jQuery.ajax({
                              url:
                                process.env.REACT_APP_API_URL +
                                "Back_mediatraining/UploadphotoInscripCycle.php",
                              type: "POST",
                              data: {
                                file: e.target.result,
                                nomfile: this.state.numIdentite,
                              },
                              dataType: "json",
                              success: (code_html, statut) => {
                                this.props.history.push("/ReglApprenantCycle");
                              },
                            });
                          };
                        } else {
                          this.props.history.push("/ReglApprenantCycle");
                        }
                      }
                    }
                  );
                },
              });
            },
          });
        });
      }
    }
  };

  render() {
    if (this.state.reglEleve.length > 0) {
      var x = this.state.reglEleve[0].typePay;
      if (x == "Annuel") {
        var paiementFormation = (
          <Fragment>
            <h3>Paiement formation :</h3>
            <table
              style={{ marginTop: "10px" }}
              className="table table-bordered"
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Nom
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant total
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant deja payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant a payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant restant
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Cocher
                  </th>
                </tr>
              </thead>
              {this.state.reglEleve.map((el, index) => (
                <tbody>
                  <tr>
                    <td className="col-md-3" style={{ textAlign: "center" }}>
                      <p style={{ textAlign: "center", borderRadius: "5px" }}>
                        {el.typePay}
                      </p>
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntAPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        value={el.mnt}
                        onChange={(e) => this.updMntInscripPayer(e, index)}
                        style={{
                          textAlign: "center",
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntRestant).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-1">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          onChange={(e) => this.addPayTotRegFor(e, index)}
                          disabled={this.state.disabledReg}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Fragment>
        );
      } else if (x == "Annuel avec réduction") {
        var paiementFormation = (
          <Fragment>
            <h3>Paiement formation :</h3>
            <table
              style={{ marginTop: "10px" }}
              className="table table-bordered"
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Nom
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant total
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant deja payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant a payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant restant
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Cocher
                  </th>
                </tr>
              </thead>
              {this.state.reglEleve.map((el, index) => (
                <tbody>
                  <tr>
                    <td className="col-md-3" style={{ textAlign: "center" }}>
                      <p style={{ textAlign: "center", borderRadius: "5px" }}>
                        {el.typePay}
                      </p>
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntAPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        value={el.mnt}
                        onChange={(e) => this.updMntInscripPayer(e, index)}
                        style={{
                          textAlign: "center",
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntRestant).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-1">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          onChange={(e) => this.addPayTotRegFor(e, index)}
                          disabled={this.state.disabledReg}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Fragment>
        );
      } else if (x == "Mensuelle") {
        var paiementFormation = (
          <Fragment>
            <h3>Paiement formation :</h3>
            <table
              style={{ marginTop: "10px" }}
              className="table table-bordered"
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Nom
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant total
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant deja payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant a payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant restant
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Cocher
                  </th>
                </tr>
              </thead>
              {this.state.reglEleve.map((el, index) => (
                <tbody>
                  <tr>
                    <td className="col-md-3" style={{ textAlign: "center" }}>
                      <p style={{ textAlign: "center", borderRadius: "5px" }}>
                        {el.numeroTrch + " tranche"}
                      </p>
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntAPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        value={el.mnt}
                        onChange={(e) => this.updMntInscripPayer(e, index)}
                        style={{
                          textAlign: "center",
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntRestant).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-1">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          onChange={(e) => this.addPayTotRegFor(e, index)}
                          disabled={this.state.disabledReg}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Fragment>
        );
      } else if (x === "2 Tranche") {
        var paiementFormation = (
          <Fragment>
            <h3>Paiement formation :</h3>
            <table
              style={{ marginTop: "10px" }}
              className="table table-bordered"
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Nom
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant total
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant deja payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant a payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant restant
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Cocher
                  </th>
                </tr>
              </thead>
              {this.state.reglEleve.map((el, index) => (
                <tbody>
                  <tr>
                    <td className="col-md-3" style={{ textAlign: "center" }}>
                      <p style={{ textAlign: "center", borderRadius: "5px" }}>
                        {el.numeroTrch + " tranche"}
                      </p>
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntAPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        value={el.mnt}
                        onChange={(e) => this.updMntInscripPayer(e, index)}
                        style={{
                          textAlign: "center",
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntRestant).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-1">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          onChange={(e) => this.addPayTotRegFor(e, index)}
                          disabled={this.state.disabledReg}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Fragment>
        );
      } else {
        var paiementFormation = (
          <Fragment>
            <h3>Paiement formation :</h3>
            <table
              style={{ marginTop: "10px" }}
              className="table table-bordered"
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Nom
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant total
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant deja payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant a payé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Montant restant
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Cocher
                  </th>
                </tr>
              </thead>
              {this.state.reglEleve.map((el, index) => (
                <tbody>
                  <tr>
                    <td className="col-md-3" style={{ textAlign: "center" }}>
                      <p style={{ textAlign: "center", borderRadius: "5px" }}>
                        {el.numeroTrch + " tranche"}
                      </p>
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntAPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntPayer).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        value={el.mnt}
                        onChange={(e) => this.updMntInscripPayer(e, index)}
                        style={{
                          textAlign: "center",
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <input
                        type="number"
                        disabled
                        value={Number.parseFloat(el.mntRestant).toFixed(3)}
                        style={{ textAlign: "center", borderRadius: "5px" }}
                      />
                    </td>
                    <td className="col-md-1">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Checkbox
                          onChange={(e) => this.addPayTotRegFor(e, index)}
                          disabled={this.state.disabledReg}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Fragment>
        );
      }
    }
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Inscription cycle</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/Cycles">
                    {" "}
                    <button
                      style={{
                        background: "#DC143C",
                        border: "1px solid #DC143C",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i
                        style={{ paddingRight: "5px" }}
                        className="fas fa-arrow-circle-left"
                      ></i>
                      Retour
                    </button>
                  </Link>
                </div>
                {/* <div className="row">
                                    <div className="col-md-6">
                                        <RadioGroup
                                            name="apprenant"
                                            selectedValue={this.state.selectedTypeApp}
                                            onChange={this.addTypeApp}>
                                            <div>
                                                {this.state.listTypeApp.map(el =>
                                                    <label style={{ paddingRight: '10px' }}>
                                                        <Radio value={el.value} />{el.label}
                                                    </label>
                                                )}
                                            </div>
                                        </RadioGroup>
                                    </div>
                                    {this.state.selectedTypeApp == 1 ?
                                        (<div className="col-md-6">
                                            <div className="form-group">
                                                <label>Apprenant</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.addApprenant}
                                                    options={this.state.listApprenant}
                                                />
                                            </div>
                                        </div>) : (null)}
                                </div> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Inscription N°</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={this.state.numInscripCycle}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px",
                          borderColor: "#B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  {this.state.carteFid != "" ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Carte d'adhesion N°</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={this.state.numCarteAdh}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <RadioGroup
                      name="typeFiche"
                      selectedValue={this.state.selectedTypeFiche}
                      onChange={this.addType}
                    >
                      <div>
                        {this.state.listTypeFormat.map((el) => (
                          <label style={{ paddingRight: "10px" }}>
                            <Radio value={el.value} />
                            {el.label}
                          </label>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date inscription</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        selected={this.state.datePaiement}
                        onChange={this.addDatePaiement}
                        dateFormat="dd/MM/yyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Carte fidélité</label>
                      <span
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      >
                        <label>
                          <Checkbox
                            onChange={(e) => this.addCarteFid(e)}
                            disabled={this.state.disabled}
                          />
                          Avec carte fidélité
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tenu</label>
                      <span
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      >
                        <label>
                          <Checkbox
                            onChange={(e) => this.addTenu(e)}
                            disabled={this.state.disabled}
                          />
                          Avec tenu
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
                {this.state.selectedTypeFiche == 1 ? (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Matricule fiscale</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Matricule Fiscale"}
                          onChange={this.addMatFisc}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Nombre de personne</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.addNbrPersonne}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Nombre de personne avec tenu</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.addNbrPersTenu}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Société</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Société"}
                          onChange={this.addSociete}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Responsable</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Responsable"}
                          onChange={this.addResponsable}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Mobile</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.addMobile}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Age</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.addAge}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Lieu résidence</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addLieuResid}
                          options={this.state.lieuResid}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Niveau inscription</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addNivBase}
                          options={this.state.listNivBase}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          onChange={this.addEmail}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tel</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.addTel}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Fax</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.addFax}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Facebook</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Facebook"}
                          onChange={this.addFacebook}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Fonction actuelle</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Fonction actuelle"}
                          onChange={this.addFct}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Photo verso </label>
                        <input
                          type="file"
                          className="form-control"
                          name="file"
                          onChange={(e) => this.addPhotoVerso(e)}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Motivation</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.addMotivation}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Type Formation</label>
                      <RadioGroup
                        name="typeFormat"
                        selectedValue={this.state.selectedTypeFormat}
                        onChange={this.addTypeFormat}
                      >
                        <div>
                          <label style={{ paddingRight: "5px" }}>
                            <Radio value={1} />
                            Indviduelle
                          </label>
                          <label style={{ paddingRight: "5px" }}>
                            <Radio value={2} />
                            Cycle
                          </label>
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Cycles</label>
                        <Select
                         // isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addCycle}
                          options={this.state.listCycle}
                          formatGroupLabel={formatGroupLabel}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Numéro Identité</label>
                        <input
                          type="number"
                          value={this.state.numIdentite}
                          className="form-control"
                          placeholder={"Numéro identité"}
                          onChange={this.addNumIdentite}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Prénom</label>
                        <input
                          type="text"
                          value={this.state.prenom}
                          className="form-control"
                          placeholder={"Prenom"}
                          onChange={this.addPrenom}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Nom</label>
                        <input
                          type="text"
                          value={this.state.nom}
                          className="form-control"
                          placeholder={"Nom"}
                          onChange={this.addNom}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Age</label>
                        <input
                          type="number"
                          value={this.state.age}
                          className="form-control"
                          onChange={this.addAge}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Lieu résidence</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addLieuResid}
                          options={this.state.lieuResid}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Niveau inscription</label>
                        <Select
                          value={this.state.selectedNivBase}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addNivBase}
                          options={this.state.listNivBase}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          value={this.state.email}
                          className="form-control"
                          placeholder="Email"
                          onChange={this.addEmail}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tel</label>
                        <input
                          type="text"
                          value={this.state.tel}
                          className="form-control"
                          onChange={this.addTel}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Fax</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.addFax}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Facebook</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.facebook}
                          onChange={this.addFacebook}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Fonction actuelle</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={"Fonction actuelle"}
                          onChange={this.addFct}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Photo verso </label>
                        <input
                          type="file"
                          className="form-control"
                          name="file"
                          onChange={(e) => this.addPhotoVerso(e)}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Motivation</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={this.addMotivation}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px",
                            borderColor: "#B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Type Formation</label>
                      <RadioGroup
                        name="typeFormat"
                        selectedValue={this.state.selectedTypeFormat}
                        onChange={this.addTypeFormat}
                      >
                        <div>
                          <label style={{ paddingRight: "5px" }}>
                            <Radio value={1} />
                            Indviduelle
                          </label>
                          <label style={{ paddingRight: "5px" }}>
                            <Radio value={2} />
                            Cycle
                          </label>
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Cycles</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addCycle}
                          options={this.state.listCycle}
                          formatGroupLabel={formatGroupLabel}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-6">
                    <label>
                      Comment avez-vous connu notre centre de formation ?
                    </label>
                    <div
                      style={{
                        border: "solid 1px #B3B3B3",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      {this.state.listConnaissance.map((el) => (
                        <label style={{ paddingRight: "10px" }}>
                          <Checkbox
                            onChange={(e) => this.addConnaiss(e, el)}
                            disabled={this.state.disabled}
                          />
                          {el.label}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>

                {this.state.arrayRegl.length > 0 ? (
                  <div style={{ paddingTop: "10px" }} className="row">
                    <div className="col-md-6">
                      <label>
                        Voulez vous payer aussi une partie ou la somme total de
                        l'inscription de la formation?
                      </label>
                      <RadioGroup
                        name="bolFormat"
                        selectedValue={this.state.selectedBolPayFormat}
                        onChange={this.addBolPayFormat}
                      >
                        <div>
                          <label style={{ paddingRight: "10px" }}>
                            <Radio value={1} />
                            Oui
                          </label>
                          <label>
                            <Radio value={2} />
                            Non
                          </label>
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                ) : null}

                {this.state.selectedBolPayFormat == 1 ? (
                  this.state.fraisReglEleve.length > 0 ||
                  this.state.reglEleve.length > 0 ? (
                    <Fragment>
                      {/* {this.state.fraisReglEleve.length > 0 ?
                                                (<Fragment>
                                                    <h3>Paiement Frais formation:</h3>
                                                    <div className="row">
                                                        <table style={{ marginTop: '10px' }} className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                                    <th style={{ textAlign: 'center' }} scope="col">Montant total</th>
                                                                    <th style={{ textAlign: 'center' }} scope="col">Montant deja payé</th>
                                                                    <th style={{ textAlign: 'center' }} scope="col">Montant a payé</th>
                                                                    <th style={{ textAlign: 'center' }} scope="col">Montant restant</th>
                                                                    <th style={{ textAlign: 'center' }} scope="col">Cocher</th>
                                                                </tr>
                                                            </thead>
                                                            {this.state.fraisReglEleve.map((el, index) =>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="col-md-3" style={{ textAlign: 'center' }}><p style={{ textAlign: 'center', borderRadius: '5px' }}>{el.nomFrais}</p></td>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntTotal).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntPay).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" value={el.mnt} onChange={(e) => this.updMntFraisPayer(e, index)} style={{ textAlign: 'center', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                                        <td className="col-md-2" style={{ textAlign: 'center' }}><input type="number" disabled value={Number.parseFloat(el.mntRest).toFixed(3)} style={{ textAlign: 'center', borderRadius: '5px' }} /></td>
                                                                        <td className="col-md-1">
                                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                <Checkbox
                                                                                    onChange={(e) => this.addPayTotFraisFor(e, index)}
                                                                                    disabled={this.state.disabledRegFor}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )}
                                                        </table>
                                                        <table className="table table-bordered">
                                                            <tbody>
                                                                <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                                    <td className="col-md-3" style={{ textAlign: 'center', fontWeight: 'bold' }}>Montant total frais payer</td>
                                                                    <td className="col-md-2"></td>
                                                                    <td className="col-md-2"></td>
                                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.state.mntFraisTotalPayer}</td>
                                                                    <td className="col-md-2"></td>
                                                                    <td className="col-md-1"></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Fragment>) : (<Fragment><h3>Paiement frais formation :</h3><p>{"L'eléve " + this.state.nom + ' ' + this.state.prenom + " a payé la totalité des frais de la formation"}</p></Fragment>)} */}
                      {this.state.reglEleve.length > 0 ? (
                        <Fragment>
                          {paiementFormation}
                          <table className="table table-bordered">
                            <tbody>
                              <tr style={{ backgroundColor: "#f2f2f2" }}>
                                <td
                                  className="col-md-3"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Montant total reglement payer
                                </td>
                                <td className="col-md-2"></td>
                                <td className="col-md-2"></td>
                                <td
                                  className="col-md-2"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.state.mntIncripTotalPayer}
                                </td>
                                <td className="col-md-2"></td>
                                <td className="col-md-1"></td>
                              </tr>
                            </tbody>
                          </table>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <h3>Paiement formation :</h3>
                          <p>
                            {"L'eléve " +
                              this.state.nom +
                              " " +
                              this.state.prenom +
                              " a payé la totalité des tranches de la formation"}
                          </p>
                        </Fragment>
                      )}

                      {this.state.fraisReglEleve.length > 0 ||
                      this.state.reglEleve.length > 0 ? (
                        <Fragment>
                          <h3 style={{ paddingLeft: "15px" }}>
                            Mode de paiement :
                          </h3>
                          <table
                            style={{ marginTop: "10px" }}
                            className="table table-bordered"
                          >
                            <thead>
                              <tr>
                                <th style={{ textAlign: "center" }} scope="col">
                                  Mode paiement
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                  Montant Paiement
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                  Numéro cheque
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                  Echéance cheque
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                  Date encaissement
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                  Titulaire cheque
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                  Banque
                                </th>
                                <th style={{ textAlign: "center" }} scope="col">
                                  Suppression
                                </th>
                              </tr>
                            </thead>
                            {this.state.tabModPayFormat.map((el, index) => (
                              <tbody>
                                <tr>
                                  <td className="col-md-2">
                                    <Select
                                      value={el.modPay}
                                      isClearable={true}
                                      isSearchable={true}
                                      onChange={(e) =>
                                        this.addModPayFormat(e, index)
                                      }
                                      options={this.state.listModPay}
                                    />{" "}
                                  </td>
                                  <td
                                    className="col-md-1"
                                    style={{ textAlign: "center" }}
                                  >
                                    <input
                                      type="number"
                                      value={el.mntModPay}
                                      onChange={(e) =>
                                        this.addMntModPayFormat(e, index)
                                      }
                                      style={{
                                        textAlign: "center",
                                        borderRadius: "5px",
                                        border: "solid 1px #B3B3B3",
                                      }}
                                    />
                                  </td>
                                  {this.state.tabModPayFormat[index].modPay
                                    .value == 2 ? (
                                    <Fragment>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="number"
                                          value={el.numCheque}
                                          onChange={(e) =>
                                            this.addNumChecFormat(e, index)
                                          }
                                          style={{
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            border: "solid 1px #B3B3B3",
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <DatePicker
                                          class="form-control ddate"
                                          dateFormat="dd/MM/yyy"
                                          style={{ border: "none" }}
                                          selected={el.echeanceChec}
                                          onChange={(e) =>
                                            this.addEcheanceChecFormat(e, index)
                                          }
                                        />
                                      </td>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <DatePicker
                                          class="form-control ddate"
                                          dateFormat="dd/MM/yyy"
                                          style={{ border: "none" }}
                                          selected={el.dateEncaiss}
                                          onChange={(e) =>
                                            this.addDateEncaissFormat(e, index)
                                          }
                                        />
                                      </td>
                                      <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="text"
                                          value={el.titulaireChec}
                                          onChange={(e) =>
                                            this.addTitulaireChecFormat(
                                              e,
                                              index
                                            )
                                          }
                                          style={{
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            border: "solid 1px #B3B3B3",
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="text"
                                          value={el.banque}
                                          onChange={(e) =>
                                            this.addBanqueFormat(e, index)
                                          }
                                          style={{
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            border: "solid 1px #B3B3B3",
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                      >
                                        <button
                                          className="buttonSupprimer"
                                          onClick={() =>
                                            this.toggleLignFormat(index)
                                          }
                                        >
                                          <i
                                            style={{ color: "white" }}
                                            className="fas fa-trash-alt"
                                          ></i>
                                        </button>
                                      </td>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="number"
                                          disabled
                                          style={{
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            border: "solid 1px #B3B3B3",
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="text"
                                          disabled
                                          style={{
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            border: "solid 1px #B3B3B3",
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="col-md-2"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="text"
                                          disabled
                                          style={{
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            border: "solid 1px #B3B3B3",
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="text"
                                          disabled
                                          style={{
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            border: "solid 1px #B3B3B3",
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                      >
                                        <input
                                          type="text"
                                          disabled
                                          style={{
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            border: "solid 1px #B3B3B3",
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="col-md-1"
                                        style={{ textAlign: "center" }}
                                      >
                                        <button
                                          className="buttonSupprimer"
                                          onClick={() =>
                                            this.toggleLignFormat(index)
                                          }
                                        >
                                          <i
                                            style={{ color: "white" }}
                                            className="fas fa-trash-alt"
                                          ></i>
                                        </button>
                                      </td>
                                    </Fragment>
                                  )}
                                </tr>
                              </tbody>
                            ))}
                          </table>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginLeft: "15px",
                              paddingBottom: "10px",
                            }}
                          >
                            <Button
                              style={{ borderRadius: "5px" }}
                              onClick={this.additionLignFormat}
                              color="secondary"
                            >
                              <i className="fas fa-plus-circle"></i>Ajouter
                              ligne
                            </Button>
                          </div>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h3>Paiement reglement formation :</h3>
                      <p style={{ paddingLeft: "15px" }}>
                        {"L'eléve " +
                          this.state.nom +
                          " " +
                          this.state.prenom +
                          " a payé la totalité de l'inscription"}
                      </p>
                    </Fragment>
                  )
                ) : null}

                {this.state.blockReglement == true ? (
                  <Fragment>
                    {this.state.fraisCycle.length > 0 ? (
                      <Fragment>
                        <h3 style={{ marginTop: "10px" }}>
                          Paiement frais cycle :
                        </h3>
                        <table
                          style={{ marginTop: "10px" }}
                          className="table table-bordered"
                        >
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }} scope="col">
                                Nom
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Montant total
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Montant payé
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Montant restant
                              </th>
                              <th style={{ textAlign: "center" }} scope="col">
                                Cocher
                              </th>
                            </tr>
                          </thead>
                          {this.state.fraisCycle.map((el, index) => (
                            <tbody>
                              <tr>
                                <td
                                  className="col-md-4"
                                  style={{ textAlign: "center" }}
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {el.nomFraisCycle}
                                  </p>
                                </td>
                                <td
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="number"
                                    disabled
                                    value={Number.parseFloat(
                                      el.mntFraisCycle
                                    ).toFixed(3)}
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="number"
                                    value={el.mntFraisCyclePayer}
                                    onChange={(e) =>
                                      this.addMntFraisPayer(e, index)
                                    }
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      border: "solid 1px #B3B3B3",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="number"
                                    disabled
                                    value={Number.parseFloat(
                                      el.mntFraisCycleRest
                                    ).toFixed(3)}
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </td>
                                <td className="col-md-2">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Checkbox
                                      onChange={(e) =>
                                        this.addPayTotFraisCycle(e, index)
                                      }
                                      disabled={this.state.disabledFrais}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                        <table className="table table-bordered">
                          <tbody>
                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                              <td
                                className="col-md-4"
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                Montant total frais payer
                              </td>
                              <td className="col-md-2"></td>
                              <td
                                className="col-md-2"
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.state.mntFraisCycleTotalPayer}
                              </td>
                              <td className="col-md-2"></td>
                              <td className="col-md-2"></td>
                            </tr>
                          </tbody>
                        </table>
                      </Fragment>
                    ) : null}
                    <h3>Paiement cycle:</h3>
                    <table
                      style={{ marginTop: "10px" }}
                      className="table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }} scope="col">
                            Infos Cycle
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Montant
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Montant payé
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Montant restant
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Cocher
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            className="col-md-4"
                            style={{ textAlign: "center" }}
                          >
                            <p>{"cycle: " + this.state.nomCycle}</p>
                            <p>
                              {"Période: " +
                                this.state.dateDCycle +
                                "---" +
                                this.state.dateFCycle +
                                " " +
                                this.state.horaire}
                            </p>
                          </td>
                          <td
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="number"
                              className="form-control"
                              disabled
                              value={Number.parseFloat(
                                this.state.reglInsCycle.mnt
                              ).toFixed(3)}
                              style={{
                                borderRadius: "5px",
                                border: "solid 1px",
                                borderColor: "#B3B3B3",
                                textAlign: "center",
                              }}
                            />
                          </td>
                          <td
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="number"
                              value={this.state.reglInsCycle.mntCyclePay}
                              onChange={(e) => this.addMntCyclePay(e)}
                              style={{
                                borderRadius: "5px",
                                border: "solid 1px",
                                borderColor: "#B3B3B3",
                                textAlign: "center",
                              }}
                            />
                          </td>
                          <td
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="number"
                              value={Number.parseFloat(
                                this.state.reglInsCycle.mntCycleRestant
                              ).toFixed(3)}
                              style={{
                                borderRadius: "5px",
                                border: "solid 1px",
                                borderColor: "#B3B3B3",
                                textAlign: "center",
                              }}
                            />
                          </td>
                          <td className="col-md-2">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Checkbox
                                onChange={(e) => this.addPayTotRegCycle(e)}
                                disabled={this.state.disabledFrais}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {this.state.societeCoch == true ? (
                      <label
                        style={{
                          paddingRight: "5px",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        Tva
                        <Checkbox
                          onChange={(e) => this.addTva(e)}
                          disabled={this.state.disabled}
                        />
                        {this.state.tva}%
                      </label>
                    ) : null}
                    <h3 style={{ paddingLeft: "15px" }}>Mode de paiement :</h3>
                    <table
                      style={{ marginTop: "10px" }}
                      className="table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }} scope="col">
                            Mode paiement
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Montant Paiement
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Numéro cheque
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Echéance cheque
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Date encaissement
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Titulaire cheque
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Banque
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Suppression
                          </th>
                        </tr>
                      </thead>
                      {this.state.tabModPayCycle.map((el, index) => (
                        <tbody>
                          <tr>
                            <td className="col-md-2">
                              <Select
                                value={el.modPay}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(e) => this.addModPayCycle(e, index)}
                                options={this.state.listModPay}
                              />{" "}
                            </td>
                            <td
                              className="col-md-1"
                              style={{ textAlign: "center" }}
                            >
                              <input
                                type="number"
                                value={el.mntModPay}
                                onChange={(e) =>
                                  this.addMntModPayCycle(e, index)
                                }
                                style={{
                                  textAlign: "center",
                                  borderRadius: "5px",
                                  border: "solid 1px #B3B3B3",
                                }}
                              />
                            </td>
                            {this.state.tabModPayCycle[index].modPay.value ==
                            2 ? (
                              <Fragment>
                                <td
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="number"
                                    value={el.numCheque}
                                    onChange={(e) =>
                                      this.addNumChecCycle(e, index)
                                    }
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      border: "solid 1px #B3B3B3",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <DatePicker
                                    class="form-control ddate"
                                    style={{ border: "none" }}
                                    dateFormat="dd/MM/yyy"
                                    selected={el.echeanceChec}
                                    onChange={(e) =>
                                      this.addEcheanceChecCycle(e, index)
                                    }
                                  />
                                </td>
                                <td
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <DatePicker
                                    class="form-control ddate"
                                    style={{ border: "none" }}
                                    dateFormat="dd/MM/yyy"
                                    selected={el.dateEncaiss}
                                    onChange={(e) =>
                                      this.addDateEncaissCycle(e, index)
                                    }
                                  />
                                </td>
                                <td
                                  className="col-md-1"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="text"
                                    value={el.titulaireChec}
                                    onChange={(e) =>
                                      this.addTitulaireChecCycle(e, index)
                                    }
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      border: "solid 1px #B3B3B3",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-1"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="text"
                                    value={el.banque}
                                    onChange={(e) =>
                                      this.addBanqueCycle(e, index)
                                    }
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      border: "solid 1px #B3B3B3",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-1"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    className="buttonSupprimer"
                                    onClick={() => this.toggleLignCycle(index)}
                                  >
                                    <i
                                      style={{ color: "white" }}
                                      className="fas fa-trash-alt"
                                    ></i>
                                  </button>
                                </td>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <td
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="number"
                                    disabled
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      border: "solid 1px #B3B3B3",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="text"
                                    disabled
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      border: "solid 1px #B3B3B3",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="text"
                                    disabled
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      border: "solid 1px #B3B3B3",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-1"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="text"
                                    disabled
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      border: "solid 1px #B3B3B3",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-1"
                                  style={{ textAlign: "center" }}
                                >
                                  <input
                                    type="text"
                                    disabled
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      border: "solid 1px #B3B3B3",
                                    }}
                                  />
                                </td>
                                <td
                                  className="col-md-1"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    className="buttonSupprimer"
                                    onClick={() => this.toggleLignCycle(index)}
                                  >
                                    <i
                                      style={{ color: "white" }}
                                      className="fas fa-trash-alt"
                                    ></i>
                                  </button>
                                </td>
                              </Fragment>
                            )}
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginLeft: "15px",
                      }}
                    >
                      <Button
                        style={{ borderRadius: "5px" }}
                        onClick={this.additionLignCycle}
                        color="secondary"
                      >
                        <i className="fas fa-plus-circle"></i>Ajouter ligne
                      </Button>
                    </div>
                  </Fragment>
                ) : null}

                {this.state.blockReglement == false ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      // disabled={!this.state.disbutIns}
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ajoutInscripCycle}
                    >
                      Confirmer
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      //disabled={!this.state.disbutReg}
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ajoutRegInscripCycle}
                    >
                      Confirmer
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal
            isOpen={this.state.modalFormat}
            toggle={this.nonDeleteLignFormat}
            className={this.props.className}
            fade={false}
          >
            <ModalHeader toggle={this.nonDeleteLignFormat}>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "465px",
                }}
              >
                <img width="30%" src="./images/deleteModal.png" alt=".." />
              </div>
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>
                    Êtes-Vous sûr de vouloir supprimer ce mode de paiement ?
                  </p>
                </div>
                <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
                >
                  <div>
                    <button
                      onClick={() => this.deleteLignFormat()}
                      style={{
                        backgroundColor: "#00FF7F",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-up"
                      ></i>
                      Oui
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => this.nonDeleteLignFormat()}
                      style={{
                        backgroundColor: "#D9534F",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-down"
                      ></i>
                      Non
                    </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.modalCycle}
            toggle={this.nonDeleteLignCycle}
            className={this.props.className}
            fade={false}
          >
            <ModalHeader toggle={this.nonDeleteLignCycle}>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "465px",
                }}
              >
                <img width="30%" src="./images/deleteModal.png" alt=".." />
              </div>
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>
                    Êtes-Vous sûr de vouloir supprimer ce mode de paiement ?
                  </p>
                </div>
                <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
                >
                  <div>
                    <button
                      onClick={() => this.deleteLignCycle()}
                      style={{
                        backgroundColor: "#00FF7F",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-up"
                      ></i>
                      Oui
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => this.nonDeleteLignCycle()}
                      style={{
                        backgroundColor: "#D9534F",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-down"
                      ></i>
                      Non
                    </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <ToastContainer
            transition={Flip}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(InscripCycle);
