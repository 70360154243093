import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";

class MenuParametrage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Admin
      barreNavAdmin: [
        {
          titre: "Paramétrage",
          element: [
            {
              nomElement: "Gestion utilisateur",
              linkElement: "/Users",
              sousPermission: 0,
              iconElement: "fas fa-users",
              arraySousElement: [],
            },
            {
              nomElement: "Historique",
              linkElement: "/HistoriqueConnexion",
              sousPermission: 0,
              iconElement: "fas fa-calendar-alt",
              arraySousElement: [],
            },
            
            {
              nomElement: "Ajouter Année",
              linkElement: "/AjoutAnnee",
              sousPermission: 0,
              iconElement: "fas fa-edit",
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-align-left",
          permission: 0,
        },
      ],
      // Scolarite
      barreNavScol: [
        {
          titre: "Scolarité",
          element: [
            {
              nomElement: "Visiteurs",
              iconElement: "fas fa-walking",
              linkElement: "/Visiteurs",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Apprenants",
              iconElement: "fas fa-child",
              linkElement: "/Apprenants",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Apprenants Inscrits",
              iconElement: "fas fa-user-secret",
              linkElement: "/ListAppInscri",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Enseignants",
              iconElement: "fas fa-chalkboard-teacher",
              linkElement: "/Enseignants",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Scolarite",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Filiéres",
                  linkSousElement: "/Filieres",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-bookmark",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Specialités",
                  linkSousElement: "/Specialites",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-chalkboard",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Formations",
                  linkSousElement: "/Formations",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-scroll",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Niveaux",
                  linkSousElement: "/Niveaux",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-list-ol",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Groupes",
                  linkSousElement: "/Groupes",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-friends",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Education",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Type enseignement",
                  linkSousElement: "/TypeEnseign",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Matieres",
                  linkSousElement: "/Matieres",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-book-open",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Matieres par niveau",
                  linkSousElement: "/MatieresFormat",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-atlas",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Matiére pratique(Sous-matiére)",
                  linkSousElement: "/MatSousMat",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-file",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Types paiement enseignants",
              iconElement: "fas fa-hand-holding-usd",
              linkElement: "/ModPaiEnseignants",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Dossier d'inscription",
              iconElement: "fas fa-paste",
              linkElement: "/Papiers",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Niveau de base",
              iconElement: "fas fa-book",
              linkElement: "/NivBase",
              sousPermission: 0,
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-balance-scale",
          permission: 0,
        },
      ],
      // Role selon l'utilisateur (soit admin ou scolarite)
      roleId: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var roleId = userPermissions[0].idRoleUser;
      this.setState({
        roleId: roleId,
      });
    } else {
      document.location = "/";
    }
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              {this.state.roleId == 1 ? (
                <h4 className="page-title">Paramétrage</h4>
              ) : (
                <h4 className="page-title">Scolarité</h4>
              )}
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  {this.state.roleId == 1
                    ? this.state.barreNavAdmin.map((el, index) => (
                        <div className="col-md-4">
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd" id="nestable">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          {/* <button data-action="collapse" type="button">Collapse</button><button data-action="expand" type="button" style={{display: 'none'}}>Expand</button> */}
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                <Link
                                                  style={{ color: "#2b2b2b" }}
                                                  to={elm.linkSousElement}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                </Link>
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))
                    : this.state.barreNavScol.map((el, index) => (
                        <div className="col-md-4">
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd" id="nestable">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          {/* <button data-action="collapse" type="button">Collapse</button><button data-action="expand" type="button" style={{display: 'none'}}>Expand</button> */}
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                <Link
                                                  style={{ color: "#2b2b2b" }}
                                                  to={elm.linkSousElement}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                </Link>
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuParametrage;
