import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";

class ModifJuryBTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listJury: [],
      enteteExam: "",
      jury: [],
      selectedJury: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var juryExamId = this.props.location.state.juryExamId;
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetJuryBTS.php",
        type: "POST",
        data: {
          juryExamId: juryExamId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            selectedJury: code_html.jury,
            enteteExam: code_html.enteteExam,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectJury.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            jury: code_html,
          });
        },
      });
    }
  }

  modifJuryEntet = (selectedJury) => {
    this.setState({ selectedJury: selectedJury });
  };

  modif = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    var juryExamId = this.props.location.state.juryExamId;
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/ModifjuryBTS.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        jury: this.state.selectedJury,
        juryExamId: juryExamId,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/ConsulterJuryBTS");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Modification Jury BTS</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/ConsulterJuryBTS">
                    {" "}
                    <button
                      style={{
                        background: "#DC143C",
                        border: "1px solid #DC143C",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i
                        style={{ paddingRight: "5px" }}
                        className="fas fa-arrow-circle-left"
                      ></i>
                      Retour
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Entete Exam :</label>
                      <Select
                        disabled
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        value={this.state.enteteExam}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Jury :</label>
                      <Select
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        value={this.state.selectedJury}
                        onChange={this.modifJuryEntet}
                        options={this.state.jury}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    style={{ borderRadius: "5px" }}
                    type="button"
                    className="btn btn-success"
                    onClick={this.modif}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModifJuryBTS);
