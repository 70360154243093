import React, { Component } from "react";
import axios from 'axios'
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import Table from "reactstrap/lib/Table";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class AjoutNotePFE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listEns: [],
      selectedEns: '',
      listGroupes: [],
      listEleves: [],
      selectGroupe: "",
      date: new Date(),
      blockListEleve: false,
      disbut: true,
      tabIdDetPfeSup: []
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/GetGroupeBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listGroupes: code_html,
          });
        },
      });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEnseignants.php',
        type: 'POST',
        data: {
          anneeScolaire: anneeScolaire
        },
        dataType: 'json',
        success: (code_html, statut) => {
          this.setState({
            listEns: code_html
          })
        }
      });
    } else {
      document.location = "/";
    }
  }

  addDate = d => {
    this.setState({
      date: d
    });
  };

  addGroupe = (selectGroupe) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectGroupe != null) {
      this.setState({ selectGroupe }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/SelectNotePfeBts.php",
          type: "POST",
          data: {
            groupeId: selectGroupe.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listEleves: code_html,
              blockListEleve: true
            });
          },
        });
      });
    } else {
      this.setState({
        selectGroupe: "",
        blockListEleve: false
      });
    }
  };

  additionLigne = (index) => {
    var allArray = this.state.listEleves
    var arrayIndex = this.state.listEleves[index].pfeNote
    var el = {
      selectedEns: "",
      note1: "",
      note2: "",
      note3: "",
      note4: "",
      note5: "",
      theme: "",
      total: "",
      remarque: "",
      idDetPfe: -1
    }
    arrayIndex.push(el)
    allArray[index].pfeNote = arrayIndex
    this.setState({
      listEleves: allArray
    });
  }

  deleteLigne = (ele, index, indice) => {
    var listEleves = this.state.listEleves
    listEleves[index].pfeNote.splice(indice, 1)
    //
    var idDetPfe = ele.idDetPfe
    if (idDetPfe != -1) {
      this.setState({
        tabIdDetPfeSup: [...this.state.tabIdDetPfeSup, idDetPfe]
      });
    }
    //
    this.setState({
      listEleves: listEleves
    }, () => {
      // Calcul moyenne
      var array = this.state.listEleves[index]
      var sommeTot = 0
      var count = 0
      for (var i = 0; i < array.pfeNote.length; i++) {
        var tot = array.pfeNote[i].total
        if (tot != '') {
          sommeTot += tot
          count++
        }
      }
      if (count > 0) {
        var moyennePfe = (sommeTot / count)
      }
      else {
        var moyennePfe = ''
      }
      var arrayChange = this.state.listEleves
      arrayChange[index].moyPfe = moyennePfe
      this.setState({
        listEleves: arrayChange
      });
    });
  }

  addTheme = (e, index) => {
    var theme = e.target.value;
    this.setState({
      listEleves: this.state.listEleves.map((el, id) => (id === index ? Object.assign(el, { theme: theme }) : el))
    });
  }

  addEns = (selectedEns, index, indice) => {
    if (selectedEns != null) {
      var selectedEns = selectedEns;
      var arrayChange = this.state.listEleves
      arrayChange[index].pfeNote[indice].selectedEns = selectedEns
      this.setState({
        listEleves: arrayChange
      });
    }
  }

  addNote1 = (event, index, indice) => {
    var note1 = event.target.value;
    if (note1 <= 5) {
      var arrayChange = this.state.listEleves
      arrayChange[index].pfeNote[indice].note1 = note1
      //
      if (arrayChange[index].pfeNote[indice].note1 != '') {
        var totNote1 = parseFloat(arrayChange[index].pfeNote[indice].note1);
      }
      else {
        var totNote1 = 0
      }
      if (arrayChange[index].pfeNote[indice].note2 != '') {
        var totNote2 = parseFloat(arrayChange[index].pfeNote[indice].note2);
      }
      else {
        var totNote2 = 0
      }
      if (arrayChange[index].pfeNote[indice].note3 != '') {
        var totNote3 = parseFloat(arrayChange[index].pfeNote[indice].note3);
      }
      else {
        var totNote3 = 0
      }
      if (arrayChange[index].pfeNote[indice].note4 != '') {
        var totNote4 = parseFloat(arrayChange[index].pfeNote[indice].note4);
      }
      else {
        var totNote4 = 0
      }
      if (arrayChange[index].pfeNote[indice].note5 != '') {
        var totNote5 = parseFloat(arrayChange[index].pfeNote[indice].note5);
      }
      else {
        var totNote5 = 0
      }
      var total = totNote1 + totNote2 + totNote3 + totNote4 + totNote5
      arrayChange[index].pfeNote[indice].total = total
      this.setState({
        listEleves: arrayChange
      }, () => {
        // Calcul moyenne
        var array = this.state.listEleves[index]
        var sommeTot = 0
        var count = 0
        for (var i = 0; i < array.pfeNote.length; i++) {
          var tot = parseFloat(array.pfeNote[i].total)
          if (tot == '' || isNaN(tot)) {
            tot = 0
          } else {
            sommeTot += tot
            count++
          }
        }
        var moyennePfe = (sommeTot / count)
        var arrayChange = this.state.listEleves
        arrayChange[index].moyPfe = moyennePfe
        this.setState({
          listEleves: arrayChange
        });
      });
    }
    else {
      toast.error('⛔ La note saisie est supérieur !!', { containerId: 'A' });
    }
  }

  addNote2 = (event, index, indice) => {
    var note2 = event.target.value;
    if (note2 <= 8) {
      var arrayChange = this.state.listEleves
      arrayChange[index].pfeNote[indice].note2 = note2
      //
      if (arrayChange[index].pfeNote[indice].note1 != '') {
        var totNote1 = parseFloat(arrayChange[index].pfeNote[indice].note1);
      }
      else {
        var totNote1 = 0
      }
      if (arrayChange[index].pfeNote[indice].note2 != '') {
        var totNote2 = parseFloat(arrayChange[index].pfeNote[indice].note2);
      }
      else {
        var totNote2 = 0
      }
      if (arrayChange[index].pfeNote[indice].note3 != '') {
        var totNote3 = parseFloat(arrayChange[index].pfeNote[indice].note3);
      }
      else {
        var totNote3 = 0
      }
      if (arrayChange[index].pfeNote[indice].note4 != '') {
        var totNote4 = parseFloat(arrayChange[index].pfeNote[indice].note4);
      }
      else {
        var totNote4 = 0
      }
      if (arrayChange[index].pfeNote[indice].note5 != '') {
        var totNote5 = parseFloat(arrayChange[index].pfeNote[indice].note5);
      }
      else {
        var totNote5 = 0
      }
      var total = totNote1 + totNote2 + totNote3 + totNote4 + totNote5
      arrayChange[index].pfeNote[indice].total = total
      this.setState({
        listEleves: arrayChange
      }, () => {
        // Calcul moyenne
        var array = this.state.listEleves[index]
        var sommeTot = 0
        var count = 0
        for (var i = 0; i < array.pfeNote.length; i++) {
          var tot = parseFloat(array.pfeNote[i].total)
          if (tot == '' || isNaN(tot)) {
            tot = 0
          } else {
            sommeTot += tot
            count++
          }
        }
        var moyennePfe = (sommeTot / count)
        var arrayChange = this.state.listEleves
        arrayChange[index].moyPfe = moyennePfe
        this.setState({
          listEleves: arrayChange
        });
      });
    }
    else {
      toast.error('⛔ La note saisie est supérieur !!', { containerId: 'A' });
    }
  }

  addNote3 = (event, index, indice) => {
    var note3 = event.target.value;
    if (note3 <= 2) {
      var arrayChange = this.state.listEleves
      arrayChange[index].pfeNote[indice].note3 = note3
      //
      if (arrayChange[index].pfeNote[indice].note1 != '') {
        var totNote1 = parseFloat(arrayChange[index].pfeNote[indice].note1);
      }
      else {
        var totNote1 = 0
      }
      if (arrayChange[index].pfeNote[indice].note2 != '') {
        var totNote2 = parseFloat(arrayChange[index].pfeNote[indice].note2);
      }
      else {
        var totNote2 = 0
      }
      if (arrayChange[index].pfeNote[indice].note3 != '') {
        var totNote3 = parseFloat(arrayChange[index].pfeNote[indice].note3);
      }
      else {
        var totNote3 = 0
      }
      if (arrayChange[index].pfeNote[indice].note4 != '') {
        var totNote4 = parseFloat(arrayChange[index].pfeNote[indice].note4);
      }
      else {
        var totNote4 = 0
      }
      if (arrayChange[index].pfeNote[indice].note5 != '') {
        var totNote5 = parseFloat(arrayChange[index].pfeNote[indice].note5);
      }
      else {
        var totNote5 = 0
      }
      var total = totNote1 + totNote2 + totNote3 + totNote4 + totNote5
      arrayChange[index].pfeNote[indice].total = total
      this.setState({
        listEleves: arrayChange
      }, () => {
        // Calcul moyenne
        var array = this.state.listEleves[index]
        var sommeTot = 0
        var count = 0
        for (var i = 0; i < array.pfeNote.length; i++) {
          var tot = parseFloat(array.pfeNote[i].total)
          if (tot == '' || isNaN(tot)) {
            tot = 0
          } else {
            sommeTot += tot
            count++
          }
        }
        var moyennePfe = (sommeTot / count)
        var arrayChange = this.state.listEleves
        arrayChange[index].moyPfe = moyennePfe
        this.setState({
          listEleves: arrayChange
        });
      });
    }
    else {
      toast.error('⛔ La note saisie est supérieur !!', { containerId: 'A' });
    }
  }

  addNote4 = (event, index, indice) => {
    var note4 = event.target.value;
    if (note4 <= 1) {
      var arrayChange = this.state.listEleves
      arrayChange[index].pfeNote[indice].note4 = note4
      //
      if (arrayChange[index].pfeNote[indice].note1 != '') {
        var totNote1 = parseFloat(arrayChange[index].pfeNote[indice].note1);
      }
      else {
        var totNote1 = 0
      }
      if (arrayChange[index].pfeNote[indice].note2 != '') {
        var totNote2 = parseFloat(arrayChange[index].pfeNote[indice].note2);
      }
      else {
        var totNote2 = 0
      }
      if (arrayChange[index].pfeNote[indice].note3 != '') {
        var totNote3 = parseFloat(arrayChange[index].pfeNote[indice].note3);
      }
      else {
        var totNote3 = 0
      }
      if (arrayChange[index].pfeNote[indice].note4 != '') {
        var totNote4 = parseFloat(arrayChange[index].pfeNote[indice].note4);
      }
      else {
        var totNote4 = 0
      }
      if (arrayChange[index].pfeNote[indice].note5 != '') {
        var totNote5 = parseFloat(arrayChange[index].pfeNote[indice].note5);
      }
      else {
        var totNote5 = 0
      }
      var total = totNote1 + totNote2 + totNote3 + totNote4 + totNote5
      arrayChange[index].pfeNote[indice].total = total
      this.setState({
        listEleves: arrayChange
      }, () => {
        // Calcul moyenne
        var array = this.state.listEleves[index]
        var sommeTot = 0
        var count = 0
        for (var i = 0; i < array.pfeNote.length; i++) {
          var tot = parseFloat(array.pfeNote[i].total)
          if (tot == '' || isNaN(tot)) {
            tot = 0
          } else {
            sommeTot += tot
            count++
          }
        }
        var moyennePfe = (sommeTot / count)
        var arrayChange = this.state.listEleves
        arrayChange[index].moyPfe = moyennePfe
        this.setState({
          listEleves: arrayChange
        });
      });
    }
    else {
      toast.error('⛔ La note saisie est supérieur !!', { containerId: 'A' });
    }
  }

  addNote5 = (event, index, indice) => {
    var note5 = event.target.value;
    if (note5 <= 4) {
      var arrayChange = this.state.listEleves
      arrayChange[index].pfeNote[indice].note5 = note5
      //
      if (arrayChange[index].pfeNote[indice].note1 != '') {
        var totNote1 = parseFloat(arrayChange[index].pfeNote[indice].note1);
      }
      else {
        var totNote1 = 0
      }
      if (arrayChange[index].pfeNote[indice].note2 != '') {
        var totNote2 = parseFloat(arrayChange[index].pfeNote[indice].note2);
      }
      else {
        var totNote2 = 0
      }
      if (arrayChange[index].pfeNote[indice].note3 != '') {
        var totNote3 = parseFloat(arrayChange[index].pfeNote[indice].note3);
      }
      else {
        var totNote3 = 0
      }
      if (arrayChange[index].pfeNote[indice].note4 != '') {
        var totNote4 = parseFloat(arrayChange[index].pfeNote[indice].note4);
      }
      else {
        var totNote4 = 0
      }
      if (arrayChange[index].pfeNote[indice].note5 != '') {
        var totNote5 = parseFloat(arrayChange[index].pfeNote[indice].note5);
      }
      else {
        var totNote5 = 0
      }
      var total = totNote1 + totNote2 + totNote3 + totNote4 + totNote5
      arrayChange[index].pfeNote[indice].total = total
      this.setState({
        listEleves: arrayChange
      }, () => {
        // Calcul moyenne
        var array = this.state.listEleves[index]
        var sommeTot = 0
        var count = 0
        for (var i = 0; i < array.pfeNote.length; i++) {
          var tot = parseFloat(array.pfeNote[i].total)
          if (tot == '' || isNaN(tot)) {
            tot = 0
          } else {
            sommeTot += tot
            count++
          }
        }
        var moyennePfe = (sommeTot / count)
        var arrayChange = this.state.listEleves
        arrayChange[index].moyPfe = moyennePfe
        this.setState({
          listEleves: arrayChange
        });
      });
    }
    else {
      toast.error('⛔ La note saisie est supérieur !!', { containerId: 'A' });
    }
  }

  addRemarque = (event, index, indice) => {
    let remarque = event.target.value;
    var arrayChange = this.state.listEleves
    arrayChange[index].pfeNote[indice].remarque = remarque
    this.setState({
      listEleves: arrayChange
    });
  }

  ajout = () => {
    var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.date != null) {
      var convertDate = this.state.date.toISOString().split("T")[0];
    } else {
      var convertDate = "";
    }
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AjoutNotePFE.php',
      type: 'POST',
      data: {
        groupeId: this.state.selectGroupe.value,
        anneeScolaire: anneeScolaire,
        dateAjoutNote: convertDate,
        listEleves: this.state.listEleves,
        tabIdDetPfeSup: this.state.tabIdDetPfeSup
      },
      dataType: 'json',
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push('/ConsulterNotePFE');
        }
        else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      }
    });
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Ajout note PFE</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroupe}
                        options={this.state.listGroupes}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        // value={this.state.date}
                        selected={this.state.date}
                        onChange={this.addDate}
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                  </div>
                </div>
                {this.state.blockListEleve == true ?
                  (<div style={{ marginTop: '10px' }} className="row">
                    <table className="table table-bordered">
                      <thead >
                        <tr>
                          <th style={{ textAlign: 'center', fontWeight: 'bold' }} scope="col"><i style={{ padding: '5px' }} class="fas fa-users"></i>Apprenants</th>
                          <th style={{ textAlign: 'center', fontWeight: 'bold' }} scope="col"><i style={{ padding: '5px' }} class="fas fa-scroll"></i>Note-PFE</th>
                          <th style={{ textAlign: 'center', fontWeight: 'bold' }} scope="col"><i style={{ padding: '5px' }} class="fas fa-edit"></i>Moy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listEleves.map((el, index) =>
                          <tr>
                            <td style={{ textAlign: 'center' }}>
                              {el.nomElv}
                              <h4 style={{ fontWeight: 'bold', padding: '5px' }}>Théme</h4>
                              <input onChange={(e) => this.addTheme(e, index)} style={{ whiteSpace: 'normal' }} type="text" value={el.theme} />
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <div>
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th style={{ textAlign: 'center' }} scope="col">Enseignant</th>
                                      <th style={{ textAlign: 'center' }} scope="col">Elaboration /5</th>
                                      <th style={{ textAlign: 'center' }} scope="col">Le contenu /8</th>
                                      <th style={{ textAlign: 'center' }} scope="col">Créativités /2</th>
                                      <th style={{ textAlign: 'center' }} scope="col">Grammaire et orthographe /1</th>
                                      <th style={{ textAlign: 'center' }} scope="col">Organisation et présentation /4</th>
                                      <th style={{ textAlign: 'center' }} scope="col">Total</th>
                                      <th style={{ textAlign: 'center' }} scope="col">Remarque</th>
                                      <th style={{ textAlign: 'center' }} scope="col">Supp</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.listEleves[index].pfeNote.map((ele, indice) =>
                                      <tr>
                                        <td style={{ textAlign: 'center', width: '20%' }}>
                                          <div>
                                            <Select
                                              value={ele.selectedEns}
                                              isClearable={true}
                                              isSearchable={true}
                                              onChange={(e) => this.addEns(e, index, indice)}
                                              options={this.state.listEns}
                                            />
                                          </div>
                                        </td>
                                        <td><input onChange={(e) => this.addNote1(e, index, indice)} style={{ width: '50%', textAlign: 'center' }} type="text" value={ele.note1} /> </td>
                                        <td><input onChange={(e) => this.addNote2(e, index, indice)} style={{ width: '50%', textAlign: 'center' }} type="text" value={ele.note2} /> </td>
                                        <td><input onChange={(e) => this.addNote3(e, index, indice)} style={{ width: '50%', textAlign: 'center' }} type="text" value={ele.note3} /> </td>
                                        <td><input onChange={(e) => this.addNote4(e, index, indice)} style={{ width: '50%', textAlign: 'center' }} type="text" value={ele.note4} /> </td>
                                        <td><input onChange={(e) => this.addNote5(e, index, indice)} style={{ width: '50%', textAlign: 'center' }} type="text" value={ele.note5} /> </td>
                                        <td><input disabled style={{ width: '75%', textAlign: 'center' }} type="text" value={ele.total} /> </td>
                                        <td><input onChange={(e) => this.addRemarque(e, index, indice)} type="text" value={ele.remarque} /> </td>
                                        <td>
                                          <button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.deleteLigne(ele, index, indice)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            Ajout
                              <i onClick={() => this.additionLigne(index)} className="fas fa-plus-circle"></i>
                            </td>
                            <td style={{ display: 'flex', justifyContent: 'center' }}><input style={{ width: '50%', textAlign: 'center', fontWeight: 'bold', color: 'crimson' }} type="text" disabled value={el.moyPfe} /></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                      <button style={{ borderRadius: '5px' }} disabled={!this.state.disbut} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                    </div>
                  </div>) : (null)}
              </div>
              <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AjoutNotePFE);
