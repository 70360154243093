import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { RadioGroup, Radio } from 'react-radio-group'

class AjoutCycle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nomCycle: '',
            nbrHeure: '',
            tarifCycle: '',
            tarifAvecCarte: '',
            selectedType: '',
            anneScol: '',
            mois: [{ label: 'Janvier', value: '1' }, { label: 'Février', value: '2' }, { label: 'Mars', value: '3' }, { label: 'Avril', value: '4' }, { label: 'Mai', value: '5' }, { label: 'Juin', value: '6' }, { label: 'Juillet', value: '7' }, { label: 'Aout', value: '8' }, { label: 'Septembre', value: '9' }, { label: 'Octobre', value: '10' }, { label: 'November', value: '11' }, { label: 'Decembre', value: '12' }],
            enseignants: [],
            selectedEnseignent: '',
            arrayCycle: [{ selectedMois: '', dateD: '', dateF: '', selectedCour: '' }],
            indexLignCycleASupprimer: '',
            modal: false,
            selectedTypeFormat: 2
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetAnneeScolaire.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        anneScol: code_html.scolaire
                    });
                }
            });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectCours.php').then(res => {
                console.log(res)
                this.setState({
                    cours: res.data
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEnseignants.php').then(res => {
                console.log(res)
                this.setState({
                    enseignants: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    addNomCycle = (event) => {
        this.setState({
            nomCycle: event.target.value
        });
    }

    addNbrHeure = (event) => {
        this.setState({
            nbrHeure: event.target.value
        });
    }

    addTarif = (event) => {
        this.setState({
            tarifCycle: event.target.value
        });
    }

    addTarifAvecCarte = (event) => {
        this.setState({
            tarifAvecCarte: event.target.value
        });
    }

    addTypeFormat = (selectedTypeFormat) => {
        this.setState({ selectedTypeFormat: selectedTypeFormat });
    }

    addMois = (selectedMois, index) => {
        let selectedMonth = selectedMois;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { selectedMois: selectedMonth }) : el))
        });
    }

    addDateD = (d, index) => {
        let dateD = d;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { dateD: dateD }) : el))
        });
    }

    addDateF = (d, index) => {
        let dateF = d;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { dateF: dateF }) : el))
        });
    }

    addHorraireCours = (selectedCour, index) => {
        let selectedHorraireCour = selectedCour;
        this.setState({
            arrayCycle: this.state.arrayCycle.map((el, id) => (id === index ? Object.assign(el, { selectedCour: selectedHorraireCour }) : el))
        });
    }

    additionLign = () => {
        let element = { selectedMois: '', dateD: '', dateF: '', selectedCour: '' }
        this.setState({
            arrayCycle: [...this.state.arrayCycle, element]
        })
    }

    toggleLign = (index) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            indexLignCycleASupprimer: index
        }));
    }

    deleteLign = () => {
        this.state.arrayCycle.splice(this.state.indexLignCycleASupprimer, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteLign = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    ajout = () => {
        // Convert date debut et date fin
        var longeur = this.state.arrayCycle.length
        for (var i = 0; i < longeur; i++) {
            // Convert date debut
            let dateDebut = this.state.arrayCycle[i].dateD
            let monthD = '' + (dateDebut.getMonth() + 1);
            let dayD = '' + dateDebut.getDate();
            let yearD = dateDebut.getFullYear();
            if (monthD.length < 2)
                monthD = '0' + monthD;
            if (dayD.length < 2)
                dayD = '0' + dayD;
            var convertDateD = [yearD, monthD, dayD].join('-');
            this.state.arrayCycle[i].dateD = convertDateD
            // Convert date fin
            let dateFin = this.state.arrayCycle[i].dateF
            let monthF = '' + (dateFin.getMonth() + 1);
            let dayF = '' + dateFin.getDate();
            let yearF = dateFin.getFullYear();
            if (monthF.length < 2)
                monthF = '0' + monthF;
            if (dayF.length < 2)
                dayF = '0' + dayF;
            var convertDateF = [yearF, monthF, dayF].join('-');
            this.state.arrayCycle[i].dateF = convertDateF
        }
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddCycle.php',
            type: 'POST',
            data: {
                nomCycle: this.state.nomCycle,
                nbrHeureCycle: this.state.nbrHeure,
                tarifCycle: this.state.tarifCycle,
                tarifCycleAvecCarte: this.state.tarifAvecCarte,
                typeEduc: this.state.selectedTypeFormat,
                arrayCycle: this.state.arrayCycle,
                userId: userId,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Cycles');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout cycle</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Cycles" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Cycle</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" className="form-control" placeholder='Nom cycle' onChange={(e) => this.addNomCycle(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Heure</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" className="form-control" onChange={(e) => this.addNbrHeure(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tarif cycle</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" className="form-control" onChange={(e) => this.addTarif(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Tarif avec carte</label>
                                            <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="number" className="form-control" onChange={(e) => this.addTarifAvecCarte(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Type Formation</label>
                                        <RadioGroup
                                            name="typeFormat"
                                            selectedValue={this.state.selectedTypeFormat}
                                            onChange={this.addTypeFormat}>
                                            <div>
                                                <label style={{ paddingRight: '5px' }}>
                                                    <Radio value={1} />Indviduelle
                                                </label>
                                                <label style={{ paddingRight: '5px' }}>
                                                    <Radio value={2} />Cycle
                                                </label>
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <h3 style={{ paddingLeft: '15px' }}>{'Planning Cycle Année Scolaire ' + this.state.anneScol}</h3>
                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Mois</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Date Debut</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Date Fin</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Horraires</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Confirmation</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Enseignent</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Supprimer</th>
                                            </tr>
                                        </thead>
                                        {this.state.arrayCycle.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><Select
                                                        value={el.selectedMois}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.addMois(e, index)}
                                                        options={this.state.mois}
                                                    /></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} selected={el.dateD} onChange={(e) => this.addDateD(e, index)} /></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><DatePicker class="form-control ddate" style={{ border: 'none' }} selected={el.dateF} onChange={(e) => this.addDateF(e, index)} /></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><Select
                                                        value={el.selectedCour}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.addHorraireCours(e, index)}
                                                        options={this.state.cours}
                                                    />
                                                    </td>
                                                    <td className="col-md-1"></td>
                                                    <td className="col-md-1"></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleLign(index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                        <Button style={{ borderRadius: '5px' }} onClick={this.additionLign} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal isOpen={this.state.modal} toggle={this.nonDeleteLign} className={this.props.className} fade={false}>
                        <ModalHeader toggle={this.nonDeleteLign}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                        <ModalBody>
                            <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                                </div>
                                <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                    <div><button onClick={() => this.deleteLign()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                    <div><button onClick={() => this.nonDeleteLign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default withRouter(AjoutCycle);