import React, { Component } from "react";
import jQuery from "jquery";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RadioGroup, Radio } from "react-radio-group";
import axios, { post } from "axios";
import { getQueriesForElement } from "@testing-library/react";

class AjoutDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateAvis: new Date(),
      avis: "",
      file: null,
      selectedApp: -1,
      blockApp: false,
      apprenant: [],
      selectedAppPart: [],
      test: [],
      selectedEnsPart: [],
      selectedEns: -1,
      nbrEltperPage: 100,
      groupe: [],
      selectedGroupe: [],
      mois: [
        { value: 1, label: "Janvier" },
        { value: 2, label: "Férvier" },
        { value: 3, label: "Mars" },
        { value: 4, label: "Avril" },
        { value: 5, label: "Mai" },
        { value: 6, label: "Juin" },
        { value: 7, label: "Juillet" },
        { value: 8, label: "Aout" },
        { value: 9, label: "Septembre" },
        { value: 10, label: "Octobre" },
        { value: 11, label: "Novembre" },
        { value: 12, label: "Decembre" },
      ],
    };
  }

 
  render() {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.state.an1=anneeScolaire;
    this.state.an2=anneeScolaire;
    return (
         <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Déclaration enseignant</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                {/*  <Link to="/GestionEnseignant">
                    {" "}
                    <button
                      style={{
                        background: "#DC143C",
                        border: "1px solid #DC143C",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i
                        style={{ paddingRight: "5px" }}
                        className="fas fa-arrow-circle-left"
                      ></i>
                      Retour
                    </button>
                  </Link>*/}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      
                   
                      <div style={{width:"100%",height:"550px"}} ><iframe style={{width:"100%",height:"550px",border: "none"}} src={"https://artsmetiers.mtd-app.com/Back_mediatraining/imp_recettean.php?"+"an1="+this.state.an1+'&'+"an2="+this.state.an2} ></iframe></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AjoutDeclaration);
