import React, { Component, Fragment } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios'
import jQuery from 'jquery'
import './paginate.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Collapsible from 'react-collapsible';
import Select from 'react-select';

class ReglApprenant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Num inscrip',
                    selector: 'numInscrip',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Apprenant',
                    selector: 'apprenant',
                    sortable: false,
                    center: true,
                    style: { width: '22%' }
                },
                {
                    name: 'Type paiement',
                    selector: 'typePaiement',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Frais réglé',
                    cell: row => row.reglFrais.length > 0 ? (<div onClick={() => this.reglFrais(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px' }}><p>Frais</p></div></div>) : (null),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '25%'
                },
                {
                    name: 'Reglement inscription',
                    cell: row => row.reglInscrip.length > 0 ? (<div onClick={() => this.reglApprenant(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px' }}><p>Reglement</p></div></div>) : (null),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '23%'
                },
                {
                    name: 'Recu',
                    cell: row => row.affichRecuPay == 1 ? (<i style={{ color: '#2192FF', fontSize: '20px' }} onClick={() => this.attPay(row)} className="fas fa-file-invoice"></i>) : (null),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                }
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            modal: false,
            idRegl: '',
            pending: true,
            reglInscrip: [],
            modalFrais: false,
            reglFraisFormat: [],
            // search
            listEleve: [],
            selectedIdEleve: '',
            listEtat: [{ value: 0, label: 'Non réglé' }, { value: 1, label: 'réglé' }],
            selectedEtat: '',
            listModPay: [],
            selectedIdModPay: '',
            listGroup: [],
            selectedIdGroup: '',
            arrayModePaiement: [],
            // Condition session
            idRoleUser: ''
        }
        this.newElement = this.newElement.bind(this);
        this.filterSearch = this.filterSearch.bind(this);
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            var idRoleUser = userPermissions[0].idRoleUser
            this.setState({
                idRoleUser: idRoleUser
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsApp.php',
                type: 'POST',
                data: {
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEleves.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        listEleve: code_html
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectTypesPayEleveIns.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        listModPay: code_html
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectGroupesInscrip.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        listGroup: code_html
                    });
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    reglFrais = (row) => {
        this.setState({
            reglFraisFormat: row.reglFrais
        }, () => {
            this.setState(prevState => ({
                modalFrais: !prevState.modalFrais
            }));
        });
    }

    toggleFrais = () => {
        this.setState(prevState => ({
            modalFrais: !prevState.modalFrais
        }));
    }

    reglApprenant = (row) => {
        this.setState({
            reglInscrip: row.reglInscrip
        }, () => {
            console.log(this.state.reglInscrip.detailRegl)
            this.setState(prevState => ({
                modal: !prevState.modal,
            }));
        });
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    attPay = (row) => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        var idIns = row.numInscrip
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuPayFor.php',
            type: 'POST',
            data: {
                idIns: idIns,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: function (code_html, statut) {
                var server_path = code_html.server_path;
                var file_path = code_html.file_path
                var path = server_path + file_path;
                window.open(path, "_blank")
            }
        });
    }

    recuRegl = (ele) => {
        var idRegFor = ele.idRegFor
        var incluEsp = false
        var incluChec = false
        // selon le type de paiement
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ApprenantsTypePayFor.php',
            type: 'POST',
            data: {
                idRegFor: idRegFor
            },
            dataType: 'json',
            success: (code_html, statut) => {
                this.setState({
                    arrayModePaiement: code_html
                }, () => {
                    if (this.state.arrayModePaiement.includes('1') || this.state.arrayModePaiement.includes('3')) {
                        incluEsp = true
                    }
                    if (this.state.arrayModePaiement.includes('2')) {
                        incluChec = true
                    }
                    if (incluChec == true) {
                        // Recu tranche
                        jQuery.ajax({
                            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegForTrchChec.php',
                            type: 'POST',
                            data: {
                                idRegFor: idRegFor
                            },
                            dataType: 'json',
                            success: (code_html, statut) => {
                                var server_path = code_html.server_path;
                                var file_path = code_html.file_path
                                var path = server_path + file_path;
                                window.open(path, "_blank")

                            }
                        });
                    }
                    else if (incluEsp == true) {
                        // Recu tranche
                        jQuery.ajax({
                            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegForTrchEsp.php',
                            type: 'POST',
                            data: {
                                idRegFor: idRegFor
                            },
                            dataType: 'json',
                            success: (code_html, statut) => {
                                var server_path = code_html.server_path;
                                var file_path = code_html.file_path
                                var path = server_path + file_path;
                                window.open(path, "_blank")
                            }
                        });
                    }
                })
            }
        })
        // jQuery.ajax({
        //     url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegFor.php',
        //     type: 'POST',
        //     data: {
        //         idRegFor: idRegFor
        //     },
        //     dataType: 'json',
        //     success: function (code_html, statut) {
        //         var server_path = code_html.server_path;
        //         var file_path = code_html.file_path
        //         var path = server_path + file_path;
        //         window.open(path, "_blank")
        //     }
        // });
    }

    recuFraisFor = (el) => {
        var idRegFor = el.idRegFor
        var incluEsp = false
        var incluChec = false
        // selon le type de paiement
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ApprenantsTypePayFor.php',
            type: 'POST',
            data: {
                idRegFor: idRegFor
            },
            dataType: 'json',
            success: (code_html, statut) => {
                this.setState({
                    arrayModePaiement: code_html
                }, () => {
                    if (this.state.arrayModePaiement.includes('1') || this.state.arrayModePaiement.includes('3')) {
                        incluEsp = true
                    }
                    if (this.state.arrayModePaiement.includes('2')) {
                        incluChec = true
                    }
                    if (incluChec == true) {
                        // Recu tranche
                        jQuery.ajax({
                            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegForFraisChec.php',
                            type: 'POST',
                            data: {
                                idRegFor: idRegFor
                            },
                            dataType: 'json',
                            success: (code_html, statut) => {
                                var server_path = code_html.server_path;
                                var file_path = code_html.file_path
                                var path = server_path + file_path;
                                window.open(path, "_blank")

                            }
                        });
                    }
                    else if (incluEsp == true) {
                        // Recu tranche
                        jQuery.ajax({
                            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuRegForFraisEsp.php',
                            type: 'POST',
                            data: {
                                idRegFor: idRegFor
                            },
                            dataType: 'json',
                            success: (code_html, statut) => {
                                var server_path = code_html.server_path;
                                var file_path = code_html.file_path
                                var path = server_path + file_path;
                                window.open(path, "_blank")
                            }
                        });
                    }
                })
            }
        })
    }

    newElement(page, totalRows) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedIdEleve != '') {
            var idEleve = this.state.selectedIdEleve.value
        }
        else {
            var idEleve = ''
        }
        if (this.state.selectedEtat != '') {
            var idEtat = this.state.selectedEtat.value
            var idEtatVerif = 'NOT'
        }
        else {
            var idEtat = ''
            var idEtatVerif = ''
        }
        if (this.state.selectedIdModPay != '') {
            var idModPay = this.state.selectedIdModPay.value
        }
        else {
            var idModPay = ''
        }
        if (this.state.selectedIdGroup != '') {
            var idGroup = this.state.selectedIdGroup.value
        }
        else {
            var idGroup = ''
        }
        if ((idEleve == '') && (idEtatVerif == '') && (idModPay == '') && (idGroup == '')) {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsApp.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
        else {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppSearch.php',
                        type: 'POST',
                        data: {
                            idEleve: idEleve,
                            idEtat: idEtat,
                            idModPay: idModPay,
                            idGroup: idGroup,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    perPage(currentRowsPerPage, currentPage) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedIdEleve != '') {
            var idEleve = this.state.selectedIdEleve.value
        }
        else {
            var idEleve = ''
        }
        if (this.state.selectedEtat != '') {
            var idEtat = this.state.selectedEtat.value
            var idEtatVerif = 'NOT'
        }
        else {
            var idEtat = ''
            var idEtatVerif = ''
        }
        if (this.state.selectedIdModPay != '') {
            var idModPay = this.state.selectedIdModPay.value
        }
        else {
            var idModPay = ''
        }
        if (this.state.selectedIdGroup != '') {
            var idGroup = this.state.selectedIdGroup.value
        }
        else {
            var idGroup = ''
        }
        if ((idEleve == '') && (idEtatVerif == '') && (idModPay == '') && (idGroup == '')) {
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsApp.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            );
        }
        else {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppSearch.php',
                        type: 'POST',
                        data: {
                            idEleve: idEleve,
                            idEtat: idEtat,
                            idModPay: idModPay,
                            idGroup: idGroup,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    filterSearch(event) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsApp.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    searchEleve = selectedIdEleve => {
        if (selectedIdEleve !== null) {
            this.setState({ selectedIdEleve });
        }
        else {
            this.setState({
                selectedIdEleve: ''
            });
        }
    };

    searchEtat = selectedEtat => {
        if (selectedEtat !== null) {
            this.setState({ selectedEtat });
        }
        else {
            this.setState({
                selectedEtat: ''
            });
        }
    };

    searchModPay = selectedIdModPay => {
        if (selectedIdModPay !== null) {
            this.setState({ selectedIdModPay });
        }
        else {
            this.setState({
                selectedIdModPay: ''
            });
        }
    };

    searchGroup = selectedIdGroup => {
        if (selectedIdGroup !== null) {
            this.setState({ selectedIdGroup });
        }
        else {
            this.setState({
                selectedIdGroup: ''
            });
        }
    };

    recherche = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedIdEleve != '') {
            var idEleve = this.state.selectedIdEleve.value
           

        }
        else {
            var idEleve = ''
            
        }
        if (this.state.selectedEtat != '') {
            var idEtat = this.state.selectedEtat.value
        }
        else {
            var idEtat = ''
        }
        if (this.state.selectedIdModPay != '') {
            var idModPay = this.state.selectedIdModPay.value
        }
        else {
            var idModPay = ''
        }
        if (this.state.selectedIdGroup != '') {
            var idGroup = this.state.selectedIdGroup.value
           
        }
        else {
            var idGroup = ''
         
        }
        this.setState({
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                if ( ((idEtat ==0 )||(idEtat ==1) ) && (idGroup == '')&& (idEleve == '') && (idModPay == '')) {
                    alert("SVP.. choisire un groupe")
                    document.location = '/ReglApprenant'
                  
                    
                }
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppSearch.php',
                    type: 'POST',
                    data: {
                        idEleve: idEleve,
                        idEtat: idEtat,
                        idModPay: idModPay,
                        idGroup: idGroup,
                      
                        anneeScolaire: anneeScolaire,
                        limit: this.state.nbrEltperPage,
                        page: 1
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    afficherTout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsApp.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false
                });
            }
        });
    }

    impr = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedIdEleve != '') {
            var idEleve = this.state.selectedIdEleve.value
            var nomEleve = this.state.selectedIdEleve.label
        }
        else {
            var idEleve = ''
            nomEleve=''
        }
        if (this.state.selectedEtat != '') {
            var idEtat = this.state.selectedEtat.value
        }
        else {
            var idEtat = ''
        }
        if (this.state.selectedIdModPay != '') {
            var idModPay = this.state.selectedIdModPay.value
        }
        else {
            var idModPay = ''
        }
        if (this.state.selectedIdGroup != '') {
            var idGroup = this.state.selectedIdGroup.value
            var  nomGroup=  this.state.selectedIdGroup.label
        }
        else {
            var idGroup = ''
            nomGroup=''
        }
        var id =
        idEleve + "/" +
        idEtat + "/" +
        idModPay + "/" +
        idGroup + "/" +
        nomEleve +"/"+
        nomGroup;

        window.open("http://artsmetiers.mtd-app.com/ImprListNonPaye?id=" + id);
       // window.open("http://localhost:3000/ImprListNonPaye?id=" + id);
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Reglement formation intiale</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="row">
                                    <h4 style={{ paddingLeft: '10px' }}>Critères de recherche</h4>
                                    <Fragment>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Apprenant</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchEleve}
                                                    options={this.state.listEleve}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Etat</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchEtat}
                                                    options={this.state.listEtat}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Mode paiement</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchModPay}
                                                    options={this.state.listModPay}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Groupe</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchGroup}
                                                    options={this.state.listGroup}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.recherche}>Rechercher</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.afficherTout}>Afficher tout</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.impr}><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Impression</button>
                                    </div>
                                </div>
                                <div className="mb-0 form-group">
                                    <div className="input-group-alternative input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input onChange={(currentRowsPerPage, currentPage) => this.filterSearch(currentRowsPerPage, currentPage)} placeholder="Search" type="text" className="search" />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} fade={false}>
                    <ModalHeader toggle={this.toggle}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Reglement eleve</p></div></div></ModalHeader>
                    <ModalBody>
                        <div>
                            <table className="table">
                                <thead >
                                    <tr>
                                        <th style={{ textAlign: 'center' }} scope="col">Tranche</th>
                                        <th style={{ textAlign: 'center' }} scope="col">Etat</th>
                                    </tr>
                                </thead>
                                {this.state.reglInscrip.map((el, index) =>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'center' }}><p>{index + 1 + ' tranche'}</p></td>
                                            {el.etat !== '' ?
                                                (<td style={{ textAlign: 'center' }}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>{el.etat}</p><p style={{ paddingLeft: '5px' }}>{el.mntNonRegle}</p></div>
                                                    <Collapsible trigger={<button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }}><i style={{ color: 'white' }} className="fas fa-info"></i></button>}>
                                                        <Fragment>
                                                            <table className="table table-bordered">
                                                                <thead >
                                                                    <tr>
                                                                        <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Date</th>
                                                                        <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Montant</th>
                                                                        <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Reglement</th>
                                                                        <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Piéce</th>
                                                                        {this.state.idRoleUser == 1 ? (
                                                                            <Fragment>
                                                                                <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Modif</th>
                                                                                <th style={{ textAlign: 'center', fontSize: '11px' }} scope="col">Supp</th>
                                                                            </Fragment>) : (null)}
                                                                    </tr>
                                                                </thead>
                                                                {el.detailRegl.map(ele =>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ textAlign: 'center', fontSize: '10px' }}><p>{ele.date}</p></td>
                                                                            <td style={{ textAlign: 'center', fontSize: '10px' }}><p>{ele.totalPay}</p></td>
                                                                            <td style={{ textAlign: 'center', fontSize: '10px' }}>
                                                                                {ele.infoPay.map(elem =>
                                                                                    elem.typePay == "Chéque" ?
                                                                                        (<Fragment><p>{elem.typePay}</p><p style={{ fontSize: '9px' }}>{'Echéance : ' + elem.echeance}</p></Fragment>) : (<p>{elem.typePay}</p>)
                                                                                )}
                                                                            </td>
                                                                            <td><i onClick={() => this.recuRegl(ele)} className="fas fa-print"></i></td>
                                                                            {this.state.idRoleUser == 1 ? (
                                                                                <Fragment>
                                                                                    <td style={{ textAlign: 'center' }}><Link to={{
                                                                                        pathname: '/MiseAJourReglFor',
                                                                                        state: { idRegFor: ele.idRegFor }
                                                                                    }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link></td>
                                                                                    <td style={{ textAlign: 'center' }}><Link to={{
                                                                                        pathname: '/MiseAJourReglFor',
                                                                                        state: { idRegFor: ele.idRegFor }
                                                                                    }}><button className='buttonSupprimer'><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></Link></td>
                                                                                </Fragment>
                                                                            ) : (null)}
                                                                        </tr>
                                                                    </tbody>
                                                                )}
                                                            </table>
                                                        </Fragment>
                                                    </Collapsible>
                                                </td>) : (<td></td>)}
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <div><button onClick={() => this.toggle()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modalFrais} toggle={this.toggleFrais} className={this.props.className} fade={false}>
                    <ModalHeader toggle={this.toggleFrais}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Reglement frais</p></div></div></ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} scope="col">Frais</th>
                                    <th style={{ textAlign: 'center' }} scope="col">Montant payer</th>
                                    {/* <th style={{ textAlign: 'center' }} scope="col">Piéce</th>
                                    {this.state.idRoleUser == 1 ? (
                                        <Fragment>
                                            <th style={{ textAlign: 'center' }} scope="col">Modif</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Supp</th>
                                        </Fragment>
                                    ) : (null)} */}
                                </tr>
                            </thead>
                            {this.state.reglFraisFormat.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'center' }}><p>{el.frais}</p></td>
                                        <td style={{ textAlign: 'center' }}><p>{el.mntPayer}</p></td>
                                        {/* <td style={{ textAlign: 'center' }}><i onClick={() => this.recuFraisFor(el)} className="fas fa-print"></i></td>
                                        {this.state.idRoleUser == 1 ? (
                                            <Fragment>
                                                <td style={{ textAlign: 'center' }}><Link to={{
                                                    pathname: '/MiseAJourReglFor',
                                                    state: { idRegFor: el.idRegFor }
                                                }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link></td>
                                                <td style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleSupFrais(el.idLigneRegFor)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                            </Fragment>) : (null)} */}
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                            <div><button onClick={() => this.toggleFrais()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ReglApprenant;