import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";


class ImprListVierge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listEns: [],
      scolaire : "", 
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var etat = arrayUrl[1]
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetEns.php",
        type: "POST",
        data: {
          etat : etat,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json", 
        success: (code_html, statut) => {
          this.setState({
            scolaire: code_html.sco,
            listEns: code_html.ens,
           
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
           
                       
                      
                          <strong>Année Scolaire : </strong>
                          {this.state.scolaire}
                       
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
        >
          <table style={{ border: "solid" }}>
            <thead>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  width: "10%",
                }}
              >
                N°
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  width: "70%",
                }}
              >
                Enseignant
              </th>
              <th
                style={{ textAlign: "center", border: "solid", color: "black", width: "80%" }}
              >
                Email
              </th>
              <th
                style={{ textAlign: "center", border: "solid", color: "black" }}
              >
                Téléphone
              </th>
            </thead>
            <tbody>
              {this.state.listEns.map((elem, index) => (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    <p>
                    {elem.nomEns}</p>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {" "}
                    {elem.email}{" "}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  ><p style={{padding:'5px'}}>
                    {" "}
                    {elem.tel}{" "}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
        
      </div>
    );
  }
}

export default withRouter(ImprListVierge);
