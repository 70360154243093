import React, { Component } from "react";
import jQuery from "jquery";
import Table from "reactstrap/lib/Table";
import { withRouter } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class ModifNoteBTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNomNatureNote: [],
      noteEleve: [],
      nomEleve: "",
      nomMatiere: "",
      niveau: "",
      formation: "",
      groupe: "",
      arrayInfoNote: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var idNote = this.props.location.state.idNote;
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetElvBtsById.php",
        type: "POST",
        data: {
          idNote: idNote,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listNomNatureNote: code_html[0],
            noteEleve: code_html[1],
            nomEleve: code_html[1][0].nomEleve,
            arrayInfoNote: code_html[1][0].infoNote,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoElevesBtsById.php",
        type: "POST",
        data: {
          idNote: idNote,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nomMatiere: code_html.nomMatiere,
            niveau: code_html.niveau,
            formation: code_html.formation,
            groupe: code_html.groupe,
          });
        },
      });
    }
  }

  changeNote = (indexOfColumn, event) => {
    if (parseFloat(event) <= 20) {
      var arrayEleve = this.state.arrayInfoNote;
      arrayEleve[indexOfColumn][0].note = event;
      this.setState({ arrayInfoNote: arrayEleve });
    } else if (event == "") {
      var arrayEleve = this.state.arrayInfoNote;
      arrayEleve[indexOfColumn][0].note = "";
      this.setState({ arrayInfoNote: arrayEleve });
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      });
    }
  };

  modif = () => {
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/ModifNoteEleveBts.php",
      type: "POST",
      data: {
        arrayInfoNote: this.state.arrayInfoNote,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/ListNoteBts");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Rapports des notes</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div style={{ marginLeft: "35vw", marginTop: "10vh" }}>
                    <p>
                      {" "}
                      Rapport de note pour l'apprenant{" "}
                      <strong>{this.state.nomEleve}</strong>{" "}
                    </p>
                    <p style={{ marginLeft: "3vw" }}>
                      de la matiere <strong>{this.state.nomMatiere}</strong>
                    </p>
                    <p>
                      <strong>{this.state.niveau}</strong> -{" "}
                      <strong>{this.state.formation}</strong> (groupe :{" "}
                      <strong>{this.state.groupe}</strong>){" "}
                    </p>
                  </div>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Eleve</th>
                        {this.state.listNomNatureNote.map((elem) => (
                          <th style={{ textAlign: 'center' }}>{elem}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <td style={{ textAlign: 'center' }}>{this.state.nomEleve}</td>
                      {this.state.arrayInfoNote.map(
                        (element, indexOfColumn) => (
                          <td style={{ textAlign: 'center' }}>
                            {element.map((elem) => (
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.changeNote(indexOfColumn, e.target.value)
                                }
                                value={elem.note}
                                style={{ textAlign: "center" }}
                              ></input>
                            ))}
                          </td>
                        )
                      )}
                    </tbody>
                  </Table>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
      </div>
    );
  }
}

export default withRouter(ModifNoteBTS);
