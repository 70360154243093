import React, { Component, Fragment } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import "./paginate.css";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Flip } from "react-toastify";
import Select from "react-select";
import "./paginate.css";
import axios from "axios";
import YearPicker from "react-year-picker";

class ListAppInscri extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "CIN",
          selector: "cin",
          sortable: false,
          center: true,
          style: { width: "10%" },
        },
        {
          name: "Prénom-Nom",
          selector: "prenomNom",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "Mobile",
          selector: "mobile",
          sortable: false,
          center: true,
          style: { width: "10%" },
        },
        {
          name: "Attest INS",
          cell: (row) => (
            <button
              style={{
                backgroundColor: "#87B87F",
                borderColor: "#87B87F",
                borderRadius: "5px",
              }}
              onClick={() => this.attIns(row)}
            >
              <i style={{ color: "white" }} className="fas fa-address-card"></i>
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "15%",
        },
        {
          name: "Expl",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ConsAppInscri",
                state: { idApprenant: row.idApprenant },
              }}
            >
              <button className="buttonExplorer">
                <i style={{ color: "white" }} className="far fa-eye"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "5%",
        },
        {
          name: "Modif",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ModifApprenantInscri",
                state: { idApprenant: row.idApprenant },
              }}
            >
              <button className="buttonModifier">
                <i style={{ color: "white" }} className="fas fa-pencil-alt"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "5%",
        },
        {
          name: "Supp",
          cell: (row) =>
            this.state.idRoleUser == 1 ? (
              row.etat == 2 ? (
                <button
                  className="buttonSupprimer"
                  onClick={() => this.toggletwo(row.idApprenant)}
                >
                  <i
                    style={{ color: "white" }}
                    className="fas fa-trash-alt"
                  ></i>
                </button>
              ) : (
                <button
                  style={{
                    backgroundCcolor: "#b3ccff",
                    borderRadius: "5px",
                    borderColor: "#b3ccff",
                  }}
                  className="buttonAbondant"
                  onClick={() => this.toggleAbon(row.idApprenant)}
                >
                  <i
                    style={{ color: "white" }}
                    className="fas fa-plus-circle"
                  ></i>
                </button>
              )
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "5%",
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      idApprenant: "",
      pending: true,
      // recherche
      nom: "",
      prenom: "",
      cin: "",
      villeResid: "",
      anneeNaiss: "",
      selectedgroupe:"",
      listNivBase: [],
      selectedNivBase: "",
      ageMin: "",
      ageMax: "",
      idRoleUser: "",
      etat: [
        { value: 2, label: "Abondant" },
        { value: 0, label: "Non Abondant" },
      ],
      selectEtat: "",
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var idRoleUser = userPermissions[0].idRoleUser;
      this.setState({
        idRoleUser: idRoleUser,
      });
      axios
        .get(
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectNivBase.php"
        )
        .then((res) => {
          console.log(res);
          this.setState({
            listNivBase: res.data,
          });
        });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/Apprenants.php",
        type: "POST",
        data: {
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectGroupesInscrip.php',
        type: 'POST',
        data: {
            anneeScolaire: anneeScolaire
        },
        dataType: 'json',
        success: (code_html, statut) => {
            console.log(code_html)
            this.setState({
                listGroup: code_html
            });
        }
    });
    } else {
      document.location = "/";
    }
  }

  newElement(page, totalRows) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (
      this.state.prenom == "" &&
      this.state.nom == "" &&
      this.state.cin == "" &&
      this.state.villeResid == "" &&
      this.state.anneeNaiss == "" &&
      this.state.selectedNivBase == "" &&
      this.state.selectedgroupe==""&&
      this.satate.selectedetat==""&&
      this.state.ageMin == "" &&
      this.state.ageMax == ""
    ) {
      this.setState(
        {
          pending: true,
        },
        () =>{
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/Apprenants.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      if (this.state.selectedNivBase == "") {
        var idNiveau = "";
      } else {
        var idNiveau = this.state.selectedNivBase.value;
      }
      if (this.state.selectedgroupe != '') {
        var idgroupe = this.state.selectedgroupe.value
    }
    else {
        var idgroupe = ''
    }
    if (this.state.selectEtat == "") {
      var etat = "";
    } else {
      var etat = this.state.selectEtat.value;
    }
      
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ApprenantSearch.php",
            type: "POST",
            data: {
              prenom: this.state.prenom,
              nom: this.state.nom,
              cin: this.state.cin,
              villeResid: this.state.villeResid,
              anneeNaiss: this.state.anneeNaiss,
              idNiveau: idNiveau,
              idgroupe:idgroupe,
              etat:etat,
              ageMin: this.state.ageMin,
              ageMax: this.state.ageMax,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }
  perPage(currentRowsPerPage, currentPage) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (
      this.state.prenom == "" &&
      this.state.nom == "" &&
      this.state.cin == "" &&
      this.state.villeResid == "" &&
      this.state.anneeNaiss == "" &&
      this.state.selectedNivBase == "" &&
      this.state.selectedgroupe =="" &&
      this.state.selectEtat == "" &&
      this.state.ageMin == "" &&
      this.state.ageMax == ""
    ) {
      this.setState(
        {
          nbrEltperPage: currentRowsPerPage,
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ApprenantSearch.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: currentRowsPerPage,
              page: currentPage,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      if (this.state.selectedNivBase == "") {
        var idNiveau = "";
      } else {
        var idNiveau = this.state.selectedNivBase.value;
      }
      if (this.state.selectedgroupe != '') {
        var idgroupe = this.state.selectedgroupe.value
    }
    else {
        var idgroupe = ''
    }
    if (this.state.selectEtat == "") {
      var etat = "";
    } else {
      var etat = this.state.selectEtat.value;
    }
   
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ApprenantSearch.php",
            type: "POST",
            data: {
              anneeScolaire: anneeScolaire,
              prenom: this.state.prenom,
              nom: this.state.nom,
              cin: this.state.cin,
              villeResid: this.state.villeResid,
              anneeNaiss: this.state.anneeNaiss,
              idNiveau: idNiveau,
              idgroupe:idgroupe,
              etat:etat,
            
              ageMin: this.state.ageMin,
              ageMax: this.state.ageMax,
              limit: currentRowsPerPage,
              page: currentPage,
              
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }

  filterSearch(event) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ApprenantsInscri.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  toggletwo = (idApprenant) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      idApprenant: idApprenant,
    }));
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };


  toggleAbon = (idApprenant) => {
    this.setState((prevState) => ({
      modalAbon: !prevState.modalAbon,
      idApprenant: idApprenant,
    }));
  };

  toggleAbondant = () => {
    this.setState((prevState) => ({
      modalAbon: !prevState.modalAbon,
    }));
  };

  addPrenom = (event) => {
    this.setState({
      prenom: event.target.value,
    });
  };

  addNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  addCin = (event) => {
    this.setState({
      cin: event.target.value,
    });
  };

  addVilleResid = (event) => {
    this.setState({
      villeResid: event.target.value,
    });
  };

  addAnneeNaiss = (d) => {
    this.setState({
      anneeNaiss: d,
    });
  };

  addNivBase = (selectedNivBase) => {
    if (selectedNivBase != null) {
      this.setState({ selectedNivBase });
    } else {
      this.setState({ selectedNivBase: "" });
    }
  };

  addEtat = (selectEtat) => {
    this.setState({
      selectEtat: selectEtat,
    });
  };
  addgroupe = (selectedgroupe) => {
    if (selectedgroupe != null) {
      this.setState({ selectedgroupe });
    } else {
      this.setState({ selectedgroupe: "" });
    }
  };
  addAgeMin = (event) => {
    this.setState({
      ageMin: event.target.value,
    });
  };

  addAgeMax = (event) => {
    this.setState({
      ageMax: event.target.value,
    });
  };

  attIns = (el) => {
    var eleveId = el.idApprenant;
    window.open(
      "http://artsmetiers.mtd-app.com/ImprAttestationPres?eleveId=" + eleveId
    );
  };

  ImprApprIns = () => {
    var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
    var anneeScolaire = userPermissions[0].anneeScolaire
    
    if (this.state.selectedNivBase == "") {
      var idNiveau = "";
    } else {
      var idNiveau = this.state.selectedNivBase.value;
    }
    if (this.state.selectedgroupe != '') {
      var idgroupe = this.state.selectedgroupe.value;
	   var namegroupe = this.state.selectedgroupe.label;
  }
  else {
      var idgroupe = '';
	   var namegroupe ='';
  }
  if (this.state.selectEtat == "") {
    var etat = "";
	 var nometat = "";
  } else {
    var etat = this.state.selectEtat.value;
	 var nometat = this.state.selectEtat.label;
  }
  if( this.state.prenom ==""){
    var prenom =""
  }else{
    var prenom=this.state.prenom
  }
  if( this.state.nom ==""){
    var nom =""
  }else{
    var nom=this.state.nom
  }
  if( this.state.cin ==""){
    var cin =""
  }else{
    var cin=this.state.cin
  }
  if( this.state.villeResid ==""){
    var villeResid =""
  }else{
    var villeResid=this.state.villeResid
  }
  if( this.state.anneeNaiss ==""){
    var anneeNaiss =""
  }else{
    var anneeNaiss=this.state.anneeNaiss
  }
  if( this.state.ageMax ==""){
    var ageMax =""
  }else{
    var ageMax=this.state.ageMax
  }
  if( this.state.ageMin ==""){
    var ageMin =""
  }else{
    var ageMin=this.state.ageMin
  }
  var id =   
       idNiveau + "/" +
       idgroupe + "/" +
       etat + "/" +
       prenom + "/" +
       nom + "/" +
       cin + "/" +
       villeResid + "/" +
       anneeNaiss + "/" +
       ageMin + "/" +
       ageMax 

    window.open("http://artsmetiers.mtd-app.com/ImprApprIns?id=" +id+'&etat='+nometat+'&groupe='+namegroupe)
      
    
}
abondanner = () => {
  var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
  var anneeScolaire = userPermissions[0].anneeScolaire;
  jQuery.ajax({
    url:
      process.env.REACT_APP_API_URL + "Back_mediatraining/AbondannerApp.php",
    type: "POST",
    data: {
      idApprenant: this.state.idApprenant,
    },
    dataType: "json",
    success: (code_html, statut) => {
      this.setState(
        {
          modalAbon: false,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ApprenantsInscri.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: this.state.nbrEltperPage,
              page: 1,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    },
  });
};


  recherche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        if (this.state.selectedNivBase == "") {
          var idNiveau = "";
        } else {
          var idNiveau = this.state.selectedNivBase.value;
        }
        if (this.state.selectedgroupe != '') {
          var idgroupe = this.state.selectedgroupe.value
      }
      else {
          var idgroupe = ''
      }
      if (this.state.selectEtat == "") {
        var etat = "";
      } else {
        var etat = this.state.selectEtat.value;
      }
            
    
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ApprenantSearch.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
              limit: this.state.nbrEltperPage,
              page: 1,
            prenom: this.state.prenom,
            nom: this.state.nom,
            cin: this.state.cin,
            villeResid: this.state.villeResid,
            anneeNaiss: this.state.anneeNaiss,
            idNiveau: idNiveau,
            idgroupe:idgroupe,
            etat:etat,
            ageMin: this.state.ageMin,
            ageMax: this.state.ageMax,
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  afficherTout = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState({
      prenom: "",
      nom: "",
      cin: "",
      villeResid: "",
      anneeNaiss: "",
      selectedNivBase: "",
      ageMin: "",
      ageMax: "",
    });
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/ApprenantsInscri.php",
      type: "POST",
      data: {
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  deleteApprenant() {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var userId = userPermissions[0].idUser;
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/DeleteApprenantInscri.php?id=" +
        this.state.idApprenant +
        "&userId=" +
        userId,
      type: "POST",
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modal: false,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/ApprenantsInscri.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Apprenants</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>
                  <Fragment>
                    <div className="col-md-6">
                      <label>Prénom</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addPrenom(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Nom</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addNom(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>CIN</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addCin(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Résidence</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addVilleResid(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Niveau</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={(e) => this.addNivBase(e)}
                          options={this.state.listNivBase}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Groupe</label>
                      <div className="form-group">
                      <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this. addgroupe}
                                                    options={this.state.listGroup}
                                                />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Etat</label>
                      <div className="form-group">
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addEtat}
                          options={this.state.etat}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Age min</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addAgeMin(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Age max</label>
                      <div className="form-group">
                        <input
                          type="text"
                          onChange={(e) => this.addAgeMax(e)}
                          style={{
                            textAlign: "center",
                            height: "38px",
                            borderRadius: "5px",
                            width: "100%",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Annee de naissance</label>
                        <YearPicker
                  
                          style={{ border: "none" ,   width: "100%",  }}
                          selected={this.state.anneeNaiss}
                          onChange={this.addAnneeNaiss}
                          
                        />
                      </div>
                    </div>
                  </Fragment>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ImprApprIns}
                    >
                      Imprimer
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.afficherTout}
                    >
                      Afficher tout
                    </button>
                  </div>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                  noHeader={true}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggle}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir supprimer cet apprenant ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.deleteApprenant()}
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggle()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalAbon}
          toggle={this.toggleAbondant}
          className={this.props.className}
          fade={false}
        >
          <ModalHeader toggle={this.toggleAbondant}>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "465px",
              }}
            >
              <img width="30%" src="./images/deleteModal.png" alt=".." />
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Êtes-Vous sûr de vouloir abondanner cet apprenant ?</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.abondanner()}
                    style={{
                      backgroundColor: "#00FF7F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggleAbondant()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    );
  }
}

export default ListAppInscri;
