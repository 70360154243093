import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";

class MenuAnalyse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Admin
      barreNavAdmin: [
        {
          titre: "III. Tableau de Bord",
          element: [
            {
              nomElement: "Recette annuelle",
              iconElement: "fab fa-think-peaks",
              linkElement: "/Statistique",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Rapport journalier",
              iconElement: "fas fa-calculator",
              linkElement: "/RapportJournalier",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Reglement formation",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Reglement tranche",
                  linkSousElement: "",
                  sousSousPermission: 0,
                  iconSousElement: "",
                  arraySousSousElement: [
                    {
                      nomSousSousElement: "Consulter",
                      linkSousSousElement: "/ReglApprenant",
                      sousSousSousPermission: 0,
                      iconSousSousElement: "fas fa-eye",
                    },
                    {
                      nomSousSousElement: "Régler",
                      linkSousSousElement: "/ModifReglTrchApp",
                      sousSousSousPermission: 0,
                      iconSousSousElement: "fas fa-edit",
                    },
                  ],
                },
                {
                  nomSousElement: "Reglement frais",
                  linkSousElement: "/",
                  sousSousPermission: 0,
                  iconSousElement: "",
                  arraySousSousElement: [
                    {
                      nomSousSousElement: "Consulter",
                      linkSousSousElement: "/ReglApprenantFrais",
                      sousSousSousPermission: 0,
                      iconSousSousElement: "fas fa-eye",
                    },
                    {
                      nomSousSousElement: "Régler",
                      linkSousSousElement: "/ModifReglFraisApp",
                      sousSousSousPermission: 0,
                      iconSousSousElement: "fas fa-edit",
                    },
                  ],
                },
              ],
            },
            {
              nomElement: "Reglement cycle",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Cycle",
                  linkSousElement: "/Cycles",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-university",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ReglApprenantCycle",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-eye",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Regler",
                  linkSousElement: "/ModifReglCycleApp",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Autre Frais",
              iconElement: "fas fa-money-check",
              linkElement: "/AutreFrais",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Reboursement",
              iconElement: "fas fa-coins",
              linkElement: "/Remboursement",
              sousPermission: 0,
              arraySousElement: [],
            },
          ],
          iconTitre: "",
          permission: 0,
        },
      ],
      // Scolarite
      barreNavScol: [
        {
          titre: "Formation accélére",
          element: [
            {
              nomElement: "Cycles",
              linkElement: "/Cycles",
              sousPermission: 0,
              iconElement: "fab fa-leanpub",
              arraySousElement: [],
            },
            {
              nomElement: "Inscription",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/InscripCycle",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-box-tissue",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Apprenants Inscrits",
                  linkSousElement: "/ListAppCycle",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-secret",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Reglements",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Consulter reglement",
                  linkSousElement: "/ReglApprenantCycle",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-eye",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Mise a jour reglement",
                  linkSousElement: "/ModifReglCycleApp",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Frais cycle",
              linkElement: "/FraisCycle",
              sousPermission: 0,
              iconElement: "fas fa-money-check-alt",
              arraySousElement: [],
            },
            {
              nomElement: "Horaire cours",
              linkElement: "/HoraireCours",
              sousPermission: 0,
              iconElement: "fas fa-clock",
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-balance-scale",
          permission: 0,
        },
      ],
      // Role selon l'utilisateur (soit admin ou scolarite)
      roleId: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var roleId = userPermissions[0].idRoleUser;
      this.setState({
        roleId: roleId,
      });
    } else {
      document.location = "/";
    }
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              {this.state.roleId == 1 ? (
                <h4 className="page-title">Tableau de bord</h4>
              ) : (
                <h4 className="page-title">Formation accéléré</h4>
              )}
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  {this.state.roleId == 1
                    ? this.state.barreNavAdmin.map((el, index) => (
                        <div className="col-md-4">
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd" id="nestable">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          {/* <button data-action="collapse" type="button">Collapse</button><button data-action="expand" type="button" style={{display: 'none'}}>Expand</button> */}
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                <Link
                                                  style={{ color: "#2b2b2b" }}
                                                  to={elm.linkSousElement}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                </Link>
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))
                    : this.state.barreNavScol.map((el, index) => (
                        <div className="col-md-4">
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd" id="nestable">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          {/* <button data-action="collapse" type="button">Collapse</button><button data-action="expand" type="button" style={{display: 'none'}}>Expand</button> */}
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                <Link
                                                  style={{ color: "#2b2b2b" }}
                                                  to={elm.linkSousElement}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                </Link>
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuAnalyse;
