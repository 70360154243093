import React, { Component, Fragment } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import jQuery from "jquery";
import "./paginate.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Collapsible from "react-collapsible";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Absence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Apprenant",
          selector: "nomComplet",
          sortable: false,
          center: true,
          style: { width: "30%" },
        },
        {
          name: "Total absence",
          selector: "absTotal",
          sortable: false,
          center: true,
          style: { width: "20%" },
        },
        {
          name: "Detail absence",
          cell: (row) => (
            <div
              onClick={() => this.detAbs(row)}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <i
                  style={{ color: "#87B87F" }}
                  className="fas fa-calendar-alt"
                ></i>
              </div>
              <div style={{ marginLeft: "5px" }}>
                <p>Detail absence par éleve</p>
              </div>
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "50%",
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      pending: true,
      // search
      listEleve: [],
      selectedEleve: "",
      listGroupe: [],
      selectedGroupe: "",
      mois: [
        { value: 1, label: "Janvier" },
        { value: 2, label: "Férvier" },
        { value: 3, label: "Mars" },
        { value: 4, label: "Avril" },
        { value: 5, label: "Mai" },
        { value: 6, label: "Juin" },
        { value: 7, label: "Juillet" },
        { value: 8, label: "Aout" },
        { value: 9, label: "Septembre" },
        { value: 10, label: "Octobre" },
        { value: 11, label: "Novembre" },
        { value: 12, label: "Decembre" },
      ],
      selectedMois: "",
      listMatiere: [],
      selectedMatiere: "",
      dateD: "",
      dateF: "",
      detAbsEleve: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/Absence.php",
        type: "POST",
        data: {
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectGroupesAbs.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listGroupe: code_html,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectMatieresAbsence.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listMatiere: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  newElement = (page, totalRows) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEleve != "") {
      var eleveId = this.state.selectedEleve.value;
    } else {
      var eleveId = "";
    }
    if (this.state.selectedGroupe != "") {
      var groupId = this.state.selectedGroupe.value;
    } else {
      var groupId = "";
    }
    if (this.state.selectedMois != "") {
      var moisId = this.state.selectedMois.value;
    } else {
      var moisId = "";
    }
    if (this.state.selectedMatiere != "") {
      var matiereId = this.state.selectedMatiere.value;
    } else {
      var matiereId = "";
    }
    // convert date debut
    if (this.state.dateD != "") {
      let dateD = this.state.dateD;
      let month = "" + (dateD.getMonth() + 1);
      let day = "" + dateD.getDate();
      let year = dateD.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      var convertDateD = [year, month, day].join("-");
    } else {
      var convertDateD = "";
    }
    // convert date fin
    if (this.state.dateF != "") {
      let dateF = this.state.dateF;
      let monthOne = "" + (dateF.getMonth() + 1);
      let dayOne = "" + dateF.getDate();
      let yearOne = dateF.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateF = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateF = "";
    }
    this.setState(
      {
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/AbsenceSearch.php",
          type: "POST",
          data: {
            nomPrenom: this.state.filterElement,
            eleveId: eleveId,
            groupId: groupId,
            moisId: moisId,
            matiereId: matiereId,
            dateD: convertDateD,
            dateF: convertDateF,
            limit: this.state.nbrEltperPage,
            page: page,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  perPage = (currentRowsPerPage, currentPage) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEleve != "") {
      var eleveId = this.state.selectedEleve.value;
    } else {
      var eleveId = "";
    }
    if (this.state.selectedGroupe != "") {
      var groupId = this.state.selectedGroupe.value;
    } else {
      var groupId = "";
    }
    if (this.state.selectedMois != "") {
      var moisId = this.state.selectedMois.value;
    } else {
      var moisId = "";
    }
    if (this.state.selectedMatiere != "") {
      var matiereId = this.state.selectedMatiere.value;
    } else {
      var matiereId = "";
    }
    // convert date debut
    if (this.state.dateD != "") {
      let dateD = this.state.dateD;
      let month = "" + (dateD.getMonth() + 1);
      let day = "" + dateD.getDate();
      let year = dateD.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      var convertDateD = [year, month, day].join("-");
    } else {
      var convertDateD = "";
    }
    // convert date fin
    if (this.state.dateF != "") {
      let dateF = this.state.dateF;
      let monthOne = "" + (dateF.getMonth() + 1);
      let dayOne = "" + dateF.getDate();
      let yearOne = dateF.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateF = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateF = "";
    }
    this.setState(
      {
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/AbsenceSearch.php",
          type: "POST",
          data: {
            nomPrenom: this.state.filterElement,
            eleveId: eleveId,
            groupId: groupId,
            moisId: moisId,
            matiereId: matiereId,
            dateD: convertDateD,
            dateF: convertDateF,
            limit: currentRowsPerPage,
            page: currentPage,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  filterSearch = (event) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEleve != "") {
      var eleveId = this.state.selectedEleve.value;
    } else {
      var eleveId = "";
    }
    if (this.state.selectedGroupe != "") {
      var groupId = this.state.selectedGroupe.value;
    } else {
      var groupId = "";
    }
    if (this.state.selectedMois != "") {
      var moisId = this.state.selectedMois.value;
    } else {
      var moisId = "";
    }
    if (this.state.selectedMatiere != "") {
      var matiereId = this.state.selectedMatiere.value;
    } else {
      var matiereId = "";
    }
    if (this.state.dateD != "") {
      let dateD = this.state.dateD;
      let month = "" + (dateD.getMonth() + 1);
      let day = "" + dateD.getDate();
      let year = dateD.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      var convertDateD = [year, month, day].join("-");
    } else {
      var convertDateD = "";
    }
    // convert date fin
    if (this.state.dateF != "") {
      let dateF = this.state.dateF;
      let monthOne = "" + (dateF.getMonth() + 1);
      let dayOne = "" + dateF.getDate();
      let yearOne = dateF.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateF = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateF = "";
    }
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/AbsenceSearch.php",
          type: "POST",
          data: {
            nomPrenom: this.state.filterElement,
            eleveId: eleveId,
            groupId: groupId,
            moisId: moisId,
            matiereId: matiereId,
            dateD: convertDateD,
            dateF: convertDateF,
            limit: this.state.nbrEltperPage,
            page: this.state.mycurrentPage,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  searchEleve = (selectedEleve) => {
    if (selectedEleve != null) {
      this.setState({ selectedEleve });
    } else {
      this.setState({ selectedEleve: "" });
    }
  };

  searchGroupe = (selectedGroupe) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedGroupe != null) {
      this.setState({ selectedGroupe }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectElevesAbs.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
            groupeId: this.state.selectedGroupe.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listEleve: code_html,
            });
          },
        });
      });
    } else {
      this.setState({ selectedGroupe: "" });
    }
  };

  searchMois = (selectedMois) => {
    if (selectedMois != null) {
      this.setState({ selectedMois });
    } else {
      this.setState({ selectedMois: "" });
    }
  };

  searchMatiere = (selectedMatiere) => {
    if (selectedMatiere != null) {
      this.setState({ selectedMatiere });
    } else {
      this.setState({ selectedMatiere: "" });
    }
  };

  searchDateD = (d) => {
    this.setState({
      dateD: d,
    });
  };

  searchDateF = (d) => {
    this.setState({
      dateF: d,
    });
  };

  recherche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEleve != "") {
      var eleveId = this.state.selectedEleve.value;
    } else {
      var eleveId = "";
    }
    if (this.state.selectedGroupe != "") {
      var groupId = this.state.selectedGroupe.value;
    } else {
      var groupId = "";
    }
    if (this.state.selectedMois != "") {
      var moisId = this.state.selectedMois.value;
    } else {
      var moisId = "";
    }
    if (this.state.selectedMatiere != "") {
      var matiereId = this.state.selectedMatiere.value;
    } else {
      var matiereId = "";
    }
    // convert date debut
    if (this.state.dateD != "") {
      let dateD = this.state.dateD;
      let month = "" + (dateD.getMonth() + 1);
      let day = "" + dateD.getDate();
      let year = dateD.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      var convertDateD = [year, month, day].join("-");
    } else {
      var convertDateD = "";
    }
    // convert date fin
    if (this.state.dateF != "") {
      let dateF = this.state.dateF;
      let monthOne = "" + (dateF.getMonth() + 1);
      let dayOne = "" + dateF.getDate();
      let yearOne = dateF.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateF = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateF = "";
    }
    this.setState(
      {
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/AbsenceSearch.php",
          type: "POST",
          data: {
            nomPrenom: this.state.filterElement,
            eleveId: eleveId,
            groupId: groupId,
            moisId: moisId,
            matiereId: matiereId,
            dateD: convertDateD,
            dateF: convertDateF,
            anneeScolaire: anneeScolaire,
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  afficherTout = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/Absence.php",
      type: "POST",
      data: {
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  //   imprimer = () => {
  //     if (this.state.selectedEleve != "") {
  //       var eleveId = this.state.selectedEleve.value;
  //     } else {
  //       var eleveId = "";
  //     }
  //     if (this.state.selectedGroupe != "") {
  //       var groupeId = this.state.selectedGroupe.value;
  //     } else {
  //       var groupeId = "";
  //     }
  //     if (this.state.selectedMois != "") {
  //       var moisId = this.state.selectedMois.value;
  //     } else {
  //       var moisId = "";
  //     }
  //     if (this.state.selectedMatiere != "") {
  //       var matiereId = this.state.selectedMatiere.value;
  //     } else {
  //       var matiereId = "";
  //     }
  //     if (this.state.dateD != "") {
  //       var dateDebut = this.state.dateD;
  //       let monthOne = "" + (dateDebut.getMonth() + 1);
  //       let dayOne = "" + dateDebut.getDate();
  //       let yearOne = dateDebut.getFullYear();
  //       if (monthOne.length < 2) monthOne = "0" + monthOne;
  //       if (dayOne.length < 2) dayOne = "0" + dayOne;
  //       var dateD = [yearOne, monthOne, dayOne].join("-");
  //     } else {
  //       var dateD = "";
  //     }
  //     if (this.state.dateF != "") {
  //       var dateFin = this.state.dateF;
  //       let monthTwo = "" + (dateFin.getMonth() + 1);
  //       let dayTwo = "" + dateFin.getDate();
  //       let yearTwo = dateFin.getFullYear();
  //       if (monthTwo.length < 2) monthTwo = "0" + monthTwo;
  //       if (dayTwo.length < 2) dayTwo = "0" + dayTwo;
  //       var dateF = [yearTwo, monthTwo, dayTwo].join("-");
  //     } else {
  //       var dateF = "";
  //     }
  //     var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
  //     var anneeScolaire = userPermissions[0].anneeScolaire;
  //     jQuery.ajax({
  //       url: process.env.REACT_APP_API_URL + "Back_mediatraining/RecuAbsence.php",
  //       type: "POST",
  //       data: {
  //         eleveId: eleveId,
  //         groupeId: groupeId,
  //         moisId: moisId,
  //         matiereId: matiereId,
  //         dateD: dateD,
  //         dateF: dateF,
  //         anneeScolaire: anneeScolaire,
  //       },
  //       dataType: "json",
  //       success: function (code_html, statut) {
  //         var server_path = code_html.server_path;
  //         var file_path = code_html.file_path;
  //         var path = server_path + file_path;
  //         window.open(path, "_blank");
  //       },
  //     });
  //   };

  imprimer = () => {
    if (this.state.dateD != "") {
      var dateDebut = this.state.dateD;
      let monthOne = "" + (dateDebut.getMonth() + 1);
      let dayOne = "" + dateDebut.getDate();
      let yearOne = dateDebut.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var dateD = [yearOne, monthOne, dayOne].join("-");
    } else {
      var dateD = "";
    }
    if (this.state.dateF != "") {
      var dateFin = this.state.dateF;
      let monthTwo = "" + (dateFin.getMonth() + 1);
      let dayTwo = "" + dateFin.getDate();
      let yearTwo = dateFin.getFullYear();
      if (monthTwo.length < 2) monthTwo = "0" + monthTwo;
      if (dayTwo.length < 2) dayTwo = "0" + dayTwo;
      var dateF = [yearTwo, monthTwo, dayTwo].join("-");
    } else {
      var dateF = "";
    }
    if (this.state.selectedEleve != "") {
      var eleveId = this.state.selectedEleve.value;
    } else {
      var eleveId = "";
    }
    if (this.state.selectedGroupe != "") {
      var groupeId = this.state.selectedGroupe.value;
    } else {
      var groupeId = "";
    }
    if (this.state.selectedMois != "") {
      var moisId = this.state.selectedMois.value;
    } else {
      var moisId = "";
    }
    if (this.state.selectedMatiere != "") {
      var matiereId = this.state.selectedMatiere.value;
    } else {
      var matiereId = "";
    }
    var id =
      eleveId +
      "/" +
      groupeId +
      "/" +
      matiereId +
      "/" +
      moisId +
      "/" +
      dateD +
      "/" +
      dateF;
    window.open("http://artsmetiers.mtd-app.com/ImprAbsence?id=" + id);
  };

  detAbs = (element) => {
    var detAbsEleve = element.detAbs;
    this.setState(
      {
        detAbsEleve: detAbsEleve,
      },
      () => {
        this.setState((prevState) => ({
          modal: !prevState.modal,
        }));
      }
    );
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  deleteAbsence = (index, indice, ele) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var userId = userPermissions[0].idUser;
    var anneeScolaire = userPermissions[0].anneeScolaire;
    //
    var idLignAbs = ele.idLignAbs;
    var arrayDetAbsEleve = this.state.detAbsEleve;
    arrayDetAbsEleve[index].detMatAbs.splice(indice, 1);
    this.setState(
      {
        detAbsEleve: arrayDetAbsEleve,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/DeleteAbsence.php",
          type: "POST",
          data: {
            idLignAbs: idLignAbs,
            userId: userId,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState((prevState) => ({
              modal: !prevState.modal,
            }));
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/Absence.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                  pending: false,
                });
              },
            });
          },
        });
      }
    );
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Absence</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/AjoutSeance">
                    {" "}
                    <button
                      style={{
                        background: "#3A3F51",
                        border: "1px solid #3A3F51",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i className="fas fa-plus"></i>Séance
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>
                  <Fragment>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Groupe</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={(e) => this.searchGroupe(e)}
                          options={this.state.listGroupe}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Apprenant</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={(e) => this.searchEleve(e)}
                          options={this.state.listEleve}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Mois</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={(e) => this.searchMois(e)}
                          options={this.state.mois}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Matiére</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={(e) => this.searchMatiere(e)}
                          options={this.state.listMatiere}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Date debut</label>
                        <DatePicker
                          class="form-control ddate"
                          style={{ border: "none" }}
                          selected={this.state.dateD}
                          onChange={this.searchDateD}
                          dateFormat="dd/MM/yyy"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Date fin</label>
                        <DatePicker
                          class="form-control ddate"
                          style={{ border: "none" }}
                          selected={this.state.dateF}
                          onChange={this.searchDateF}
                          dateFormat="dd/MM/yyy"
                        />
                      </div>
                    </div>
                  </Fragment>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.afficherTout}
                    >
                      Afficher tout
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.imprimer}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Imprimer
                    </button>
                  </div>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                />
              </div>
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className={this.props.className}
                fade={false}
              >
                <ModalHeader toggle={this.toggle}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <i
                        style={{ color: "#87B87F" }}
                        className="fas fa-calendar-alt"
                      ></i>
                    </div>
                    <div style={{ marginLeft: "5px", fontWeight: "bold" }}>
                      <p>Detail absence eleve</p>
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }} scope="col">
                            Matiére
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Nombre d'absence
                          </th>
                        </tr>
                      </thead>
                      {this.state.detAbsEleve.map((el, index) => (
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              <p>{el.matNom}</p>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p>{el.etat}</p>
                                <p style={{ paddingLeft: "5px" }}>
                                  {el.nbrAbs}
                                </p>
                              </div>
                              <Collapsible
                                trigger={
                                  <button
                                    style={{
                                      backgroundColor: "#D9534F",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <i
                                      style={{ color: "white" }}
                                      className="fas fa-info"
                                    ></i>
                                  </button>
                                }
                              >
                                <Fragment>
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            textAlign: "center",
                                            fontSize: "11px",
                                          }}
                                          scope="col"
                                        >
                                          Date
                                        </th>
                                        <th
                                          style={{
                                            textAlign: "center",
                                            fontSize: "11px",
                                          }}
                                          scope="col"
                                        >
                                          Enseignant
                                        </th>
                                        <th
                                          style={{
                                            textAlign: "center",
                                            fontSize: "11px",
                                          }}
                                          scope="col"
                                        >
                                          Supprimer
                                        </th>
                                      </tr>
                                    </thead>
                                    {el.detMatAbs.map((ele, indice) => (
                                      <tbody>
                                        <tr>
                                          <td
                                            className="col-md-7"
                                            style={{
                                              textAlign: "center",
                                              fontSize: "10px",
                                            }}
                                          >
                                            <p>{ele.dateAbs}</p>
                                          </td>
                                          <td
                                            className="col-md-4"
                                            style={{
                                              textAlign: "center",
                                              fontSize: "10px",
                                            }}
                                          >
                                            <p>{ele.nomEns}</p>
                                          </td>
                                          <td
                                            className="col-md-1"
                                            style={{
                                              textAlign: "center",
                                              fontSize: "10px",
                                            }}
                                          >
                                            <button
                                              onClick={() =>
                                                this.deleteAbsence(
                                                  index,
                                                  indice,
                                                  ele
                                                )
                                              }
                                              style={{
                                                backgroundColor: "#D9534F",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <i
                                                style={{ color: "white" }}
                                                className="fas fa-eraser"
                                              ></i>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </table>
                                </Fragment>
                              </Collapsible>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <div
                      className="hvr-push"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginBottom: "15px",
                      }}
                    >
                      <div>
                        <button
                          onClick={() => this.toggle()}
                          style={{
                            backgroundColor: "#D9534F",
                            borderRadius: "5px",
                            color: "white",
                            width: "40px ",
                          }}
                        >
                          <i
                            style={{ color: "white" }}
                            className="fas fa-sign-out-alt"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Absence;
