import React, { Component, Fragment } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import Table from "reactstrap/lib/Table";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { withRouter } from "react-router";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class AjouterCalendrier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listGroupe: [],
      selectGroupe: "",
      dateD: new Date(),
      dateF: new Date(),
      listMatieres: [],
      listJours: [],
      disabled: false,
      modal: false,
      heureDebut: "",
      heureFin: "",
      listG: [
        { value: 1, label: "G1" },
        { value: 2, label: "G2" },
        { value: 3, label: "G3" },
        { value: 4, label: "G4" },
        { value: 5, label: "G5" },
        { value: 6, label: "G6" },
        { value: 7, label: "G IFORMATIQUE 1" },
        { value: 8, label: "G IFORMATIQUE 2" },
        { value: 9, label: "G IFORMATIQUE 3" },
      ],
      listSalle: [
        { value: 1, label: "Labo A" },
        { value: 2, label: "Labo B" },
        { value: 3, label: "Ateliers cuisine" },
      ],
      selectSalle: "",
      selectG: "",
      matiere: "",
      jour: "",
      type: "",
      nbrEleve: "",
      modalDelete: false,
      idCal: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/CalendarToday.php",
        type: "POST",
        data: {},
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listMatieres: code_html[1],
            listJours: code_html[0],
          });
        },
      });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetGroupe.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listGroupe: code_html,
          });
        },
      });
    }
  }

  addDateD = (d) => {
    this.setState({
      dateD: d,
    });
  };
  addDateF = (d) => {
    this.setState({
      dateF: d,
    });
  };

  affiche = () => {
    let dateD = this.state.dateD;
    let month = "" + (dateD.getMonth() + 1);
    let day = "" + dateD.getDate();
    let year = dateD.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var convertDateD = [year, month, day].join("-");
    //
    let dateF = this.state.dateF;
    let mois = "" + (dateF.getMonth() + 1);
    let jour = "" + dateF.getDate();
    let annee = dateF.getFullYear();
    if (mois.length < 2) mois = "0" + mois;
    if (jour.length < 2) jour = "0" + jour;
    var convertDateF = [annee, mois, jour].join("-");
    //
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/AjouterCalendrier.php",
      type: "POST",
      data: {
        dateDebut: convertDateD,
        dateFin: convertDateF,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          listMatieres: code_html[1],
          listJours: code_html[0],
        });
      },
    });
  };

  toggle = (elem, obj) => {
    var matiere = elem.matiere;
    var type = elem.type;
    var jour = obj.jour;
    this.setState((prevState) => ({
      modal: !prevState.modal,
      matiere: matiere,
      jour: jour,
      type: type,
    }));
  };

  toggleFermer = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  addDateDebutSeance = (d) => {
    this.setState({
      heureDebut: d,
    });
  };

  addDateFinSeance = (d) => {
    this.setState({
      heureFin: d,
    });
  };

  nbrEleve = (event) => {
    this.setState({
      nbrEleve: event.target.value,
    });
  };

  addGroupe = (selectGroupe) => {
    if (selectGroupe != null) {
      this.setState({ selectGroupe });
    } else {
      this.setState({
        selectGroupe: "",
      });
    }
  };

  addG = (selectG) => {
    if (selectG != null) {
      this.setState({ selectG });
    } else {
      this.setState({
        selectG: "",
      });
    }
  };

  addSalle = (selectSalle) => {
    if (selectSalle != null) {
      this.setState({ selectSalle });
    } else {
      this.setState({
        selectSalle: "",
      });
    }
  };

  ajout = () => {
    //  date debut
    let dateD = this.state.dateD;
    let month = "" + (dateD.getMonth() + 1);
    let day = "" + dateD.getDate();
    let year = dateD.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var convertDateD = [year, month, day].join("-");
    // date fin
    let dateF = this.state.dateF;
    let mois = "" + (dateF.getMonth() + 1);
    let jour = "" + dateF.getDate();
    let annee = dateF.getFullYear();
    if (mois.length < 2) mois = "0" + mois;
    if (jour.length < 2) jour = "0" + jour;
    var convertDateF = [annee, mois, jour].join("-");
    //
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    var hd = this.state.heureDebut.toTimeString();
    hd = hd.split(" ")[0];
    var hf = this.state.heureFin.toTimeString();
    hf = hf.split(" ")[0];
    var jourA = this.state.jour;
    var arrayDate = jourA.split(" ");
    var nomJour = arrayDate[0];
    var date = arrayDate[1];
    var convDate = date.split("/");
    var dayA = convDate[2] + "-" + convDate[1] + "-" + convDate[0];
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/AddCalendrier.php",
      type: "POST",
      data: {
        day: dayA,
        jour: nomJour,
        hd: hd,
        hf: hf,
        listGroupe: this.state.selectGroupe,
        matiere: this.state.matiere,
        type: this.state.type,
        salle: this.state.selectSalle,
        nbrGroupe: this.state.selectG,
        nbrEleve: this.state.nbrEleve,
        anneeScolaire: anneeScolaire,
        dateDebut: convertDateD,
        dateFin: convertDateF,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.setState({
            modal: !this.state.modal,
            listMatieres: code_html.data[1],
            listJours: code_html.data[0],
          });
        }
      },
    });
  };

  toggleSupp = (id) => {
    this.setState((prevState) => ({
      modalDelete: !prevState.modalDelete,
      idCal: id,
    }));
  };

  fermerModal = () => {
    this.setState((prevState) => ({
      modalDelete: !prevState.modalDelete,
    }));
  };

  deleteCal = () => {
    //  date debut
    let dateD = this.state.dateD;
    let month = "" + (dateD.getMonth() + 1);
    let day = "" + dateD.getDate();
    let year = dateD.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var convertDateD = [year, month, day].join("-");
    // date fin
    let dateF = this.state.dateF;
    let mois = "" + (dateF.getMonth() + 1);
    let jour = "" + dateF.getDate();
    let annee = dateF.getFullYear();
    if (mois.length < 2) mois = "0" + mois;
    if (jour.length < 2) jour = "0" + jour;
    var convertDateF = [annee, mois, jour].join("-");
    //
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/DeleteCal.php",
      type: "POST",
      data: {
        idCal: this.state.idCal,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modalDelete: !this.state.modalDelete,
          },
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/AjouterCalendrier.php",
              type: "POST",
              data: {
                dateDebut: convertDateD,
                dateFin: convertDateF,
              },
              dataType: "json",
              success: (code_html, statut) => {
                this.setState({
                  listMatieres: code_html[1],
                  listJours: code_html[0],
                });
              },
            });
          }
        );
      },
    });
  };

  render() {
    return (
      <div
        id="page-wrapper"
        style={{ minHeight: "263px", marginLeft: "0px", zoom: "90%" }}
      >
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Planification Des examens</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div style={{ width: "100%" }} className="form-group">
                      <label>Date Début</label>
                      <CalendarContainer style={{ width: "100%" }} />
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        value={this.state.dateD}
                        selected={this.state.dateD}
                        onChange={this.addDateD}
                        dateFormat="yyyy/MM/dd"
                        width={"500px"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div style={{ width: "100%" }} className="form-group">
                      <label>Date Fin</label>
                      <CalendarContainer style={{ width: "100%" }} />
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        value={this.state.dateF}
                        selected={this.state.dateF}
                        onChange={this.addDateF}
                        dateFormat="yyyy/MM/dd"
                        width={"500px"}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Afficher
                    </button>
                  </div>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Matiere</th>
                      {this.state.listJours.map((el) => (
                        <th> {el} </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.listMatieres.map((elem) => (
                      <tr>
                        <td> {elem.matiere} </td>
                        {elem.infoMatiere.map((obj) =>
                          obj.groupe == "" ? (
                            <td>
                              {" "}
                              <Checkbox
                                onChange={() => this.toggle(elem, obj)}
                                disabled={this.state.disabled}
                              />
                            </td>
                          ) : (
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div>
                                  <p>
                                    {obj.hd} -- {obj.hf}
                                  </p>
                                  <p> {obj.groupe} </p>
                                  <Checkbox
                                    onChange={() => this.toggle(elem, obj)}
                                    disabled={this.state.disabled}
                                  />
                                </div>
                                <div>
                                  <i
                                    onClick={() => this.toggleSupp(obj.idCal)}
                                    style={{ color: "red", cursor: "pointer" }}
                                    class="fas fa-times"
                                  ></i>
                                </div>
                              </div>
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggleFermer}
              className={this.props.className}
              style={{ maxWidth: "700px", width: "100%" }}
              fade={false}
            >
              {this.state.type == 1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10%",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>
                    {"Examen fin de formation Théorique "}
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10%",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>
                    {"Examen fin de formation Pratique "}
                  </p>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}> Matiere :</p>
                  <p style={{ paddingLeft: "5px" }}>{this.state.matiere}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}> Jour :</p>
                  <p style={{ paddingLeft: "5px" }}>{this.state.jour}</p>
                </div>
              </div>
              <ModalBody>
                <div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Depart séance</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        selected={this.state.heureDebut}
                        onChange={this.addDateDebutSeance}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Fin séance</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        selected={this.state.heureFin}
                        onChange={this.addDateFinSeance}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </div>
                  </div>
                  {this.state.type == 2 ? (
                    <div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>N°Eleve</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Nature note"
                            onChange={this.nbrEleve}
                            style={{
                              borderRadius: "5px",
                              border: "solid 1px #B3B3B3",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Salle</label>
                          <Select
                            isClearable={true}
                            isSearchable={true}
                            onChange={this.addSalle}
                            options={this.state.listSalle}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>N°Groupe</label>
                          <Select
                            isClearable={true}
                            isSearchable={true}
                            onChange={this.addG}
                            options={this.state.listG}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroupe}
                        options={this.state.listGroupe}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <button
                  style={{ borderRadius: "5px" }}
                  type="button"
                  className="btn btn-success"
                  onClick={this.ajout}
                >
                  Confirmer
                </button>
              </div>
            </Modal>
            <Modal
              isOpen={this.state.modalDelete}
              toggle={this.fermerModal}
              className={this.props.className}
              fade={false}
            >
              <ModalHeader toggle={this.fermerModal}>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "465px",
                  }}
                >
                  <img width="30%" src="./images/deleteModal.png" alt=".." />
                </div>
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    FontSize: "14px",
                    FontWeight: "700",
                    LineHeight: "18.375px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>Êtes-Vous sûr de vouloir supprimer cette date ?</p>
                  </div>
                  <div
                    className="hvr-push"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "15px",
                    }}
                  >
                    <div>
                      <button
                        onClick={() => this.deleteCal()}
                        style={{
                          backgroundColor: "#00FF7F",
                          borderRadius: "5px",
                          color: "white",
                        }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-up"
                        ></i>
                        Oui
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => this.fermerModal()}
                        style={{
                          backgroundColor: "#D9534F",
                          borderRadius: "5px",
                          color: "white",
                        }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-down"
                        ></i>
                        Non
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AjouterCalendrier);
