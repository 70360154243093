import React, { Component, Fragment } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

class AffichRes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listSpec: [
        { value: "1", label: "BTS" },
        { value: "2", label: "Autres" },
      ],
      listSpec2020: [
        { value: "1", label: "BTS/BTP" },
        { value: "2", label: "Autres" },
      ],
      selectSpec: "",
      prTrim: "",
      deuxTrim: "",
      trTrim: "",
      stage: "",
      adm: "",
      listGroupe: [],
      selectGroupe: [],
      releveBts: "",
      pfe: "",
      stageBts: "",
      moyAdmBts: "",
      listGroupeBTS: [],
      selectGroupeBTS: [],
      listAppAutre: [],
      selectAppAutre: [],
      listAppBTS: [],
      selectAppBTS: [],
      idResAffiche: "",
      etat: "",
      anneeScolaire: ""
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetGroupeNonBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listGroupe: code_html,
            anneeScolaire: parseInt(anneeScolaire)
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/GetGroupeBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listGroupeBTS: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addSpec = (selectSpec) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire
    this.setState(
      {
        selectSpec: selectSpec,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetInfoResAffich.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
            selectSpec: this.state.selectSpec.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            if (code_html.spec == 1) {
              this.setState({
                idResAffiche: code_html.idResAffiche,
                releveBts: code_html.releveBts,
                pfe: code_html.pfe,
                stageBts: code_html.stage,
                moyAdmBts: code_html.moyAdm,
                selectGroupeBTS: code_html.idGroupe,
                selectAppBTS: code_html.idElv,
                etat: code_html.etat
              });
            } else {
              this.setState({
                idResAffiche: code_html.idResAffiche,
                prTrim: code_html.tr1,
                deuxTrim: code_html.tr2,
                trTrim: code_html.tr3,
                stage: code_html.stage,
                adm: code_html.moyAdm,
                selectGroupe: code_html.idGroupe,
                selectAppAutre: code_html.idElv,
                etat: code_html.etat
              });
            }
          },
        });
      }
    );
  };

  addGroupe = (selectGroupe) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire
    this.setState(
      {
        selectGroupe: selectGroupe,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetApprenantByGroupeAutre.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
            arrayGroupe: this.state.selectGroupe,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listAppAutre: code_html,
            });
          },
        });
      }
    );
  };

  addGroupeBTS = (selectGroupeBTS) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire
    this.setState(
      {
        selectGroupeBTS: selectGroupeBTS,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetApprenantByGroupeBTS.php",
          type: "POST",
          data: {
            anneeScolaire: anneeScolaire,
            arrayGroupe: this.state.selectGroupeBTS,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listAppBTS: code_html,
            });
          },
        });
      }
    );
  };

  addReleveBTS = (e) => {
    if (e.target.checked === true) {
      this.setState({
        releveBts: 1,
      });
    } else {
      this.setState({
        releveBts: 0,
      });
    }
  };

  addStageBTS = (e) => {
    if (e.target.checked === true) {
      this.setState({
        stageBts: 1,
      });
    } else {
      this.setState({
        stageBts: 0,
      });
    }
  };

  addPFE = (e) => {
    if (e.target.checked === true) {
      this.setState({
        pfe: 1,
      });
    } else {
      this.setState({
        pfe: 0,
      });
    }
  };

  addMoyAdmBts = (e) => {
    if (e.target.checked === true) {
      this.setState({
        moyAdmBts: 1,
      });
    } else {
      this.setState({
        moyAdmBts: 0,
      });
    }
  };

  addTr1 = (e) => {
    if (e.target.checked === true) {
      this.setState({
        prTrim: 1,
      });
    } else {
      this.setState({
        prTrim: 0,
      });
    }
  };

  addTr2 = (e) => {
    if (e.target.checked === true) {
      this.setState({
        deuxTrim: 1,
      });
    } else {
      this.setState({
        deuxTrim: 0,
      });
    }
  };

  addTr3 = (e) => {
    if (e.target.checked === true) {
      this.setState({
        trTrim: 1,
      });
    } else {
      this.setState({
        trTrim: 0,
      });
    }
  };

  addStage = (e) => {
    if (e.target.checked === true) {
      this.setState({
        stage: 1,
      });
    } else {
      this.setState({
        stage: 0,
      });
    }
  };

  addEleveBTS = (selectAppBTS) => {
    this.setState({
      selectAppBTS: selectAppBTS,
    });
  };

  addEleve = (selectAppAutre) => {
    this.setState({
      selectAppAutre: selectAppAutre,
    });
  };
  addADM = (e) => {
    if (e.target.checked === true) {
      this.setState({
        adm: 1,
      });
    } else {
      this.setState({
        adm: 0,
      });
    }
  };

  ajout = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire
    if (this.state.etat == 0) {
      if (this.state.selectSpec.value == 2) {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/AjoutAffichResult.php",
          type: "POST",
          data: {
            prTrim: this.state.prTrim,
            deuxTrim: this.state.deuxTrim,
            trTrim: this.state.trTrim,
            stage: this.state.stage,
            adm: this.state.adm,
            anneeScolaire: anneeScolaire,
            arrayGroupe: this.state.selectGroupe,
            arrayEleve: this.state.selectAppAutre,
          },
          dataType: "json",
          success: (code_html, statut) => {
            if (code_html.Result == "OK") {
              this.props.history.push("/Menu");
            } else if (code_html.Result == "KO") {
              alert("erreur,l'un de vos champs est vide!!");
            }
          },
        });
      } else {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/AjoutAffichResultBTS.php",
          type: "POST",
          data: {
            releveBts: this.state.releveBts,
            pfe: this.state.pfe,
            stageBts: this.state.stageBts,
            moyAdmBts: this.state.moyAdmBts,
            anneeScolaire: anneeScolaire,
            arrayGroupe: this.state.selectGroupeBTS,
            arrayEleve: this.state.selectAppBTS,
          },
          dataType: "json",
          success: (code_html, statut) => {
            if (code_html.Result == "OK") {
              this.props.history.push("/Menu");
            } else if (code_html.Result == "KO") {
              alert("erreur,l'un de vos champs est vide!!");
            }
          },
        });
      }
    }
    else {
      if (this.state.selectSpec.value == 2) {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ModifAffichResult.php",
          type: "POST",
          data: {
            prTrim: this.state.prTrim,
            deuxTrim: this.state.deuxTrim,
            trTrim: this.state.trTrim,
            stage: this.state.stage,
            adm: this.state.adm,
            anneeScolaire: anneeScolaire,
            arrayGroupe: this.state.selectGroupe,
            arrayEleve: this.state.selectAppAutre,
            idResAffiche: this.state.idResAffiche,
          },
          dataType: "json",
          success: (code_html, statut) => {
            if (code_html.Result == "OK") {
              this.props.history.push("/Menu");
            } else if (code_html.Result == "KO") {
              alert("erreur,l'un de vos champs est vide!!");
            }
          },
        });
      } else {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ModifAffichResultBTS.php",
          type: "POST",
          data: {
            releveBts: this.state.releveBts,
            pfe: this.state.pfe,
            stageBts: this.state.stageBts,
            moyAdmBts: this.state.moyAdmBts,
            anneeScolaire: anneeScolaire,
            arrayGroupe: this.state.selectGroupeBTS,
            arrayEleve: this.state.selectAppBTS,
            idResAffiche: this.state.idResAffiche,
          },
          dataType: "json",
          success: (code_html, statut) => {
            if (code_html.Result == "OK") {
              this.props.history.push("/Menu");
            } else if (code_html.Result == "KO") {
              alert("erreur,l'un de vos champs est vide!!");
            }
          },
        });
      }
    }
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Afficher Resultat</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="white-box">
            <div className="row">
              {this.state.anneeScolaire >= 2020 ? (<div className="col-md-6">
                <div className="form-group">
                  <label>Specialité</label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    onChange={this.addSpec}
                    options={this.state.listSpec2020}
                  />
                </div>
              </div>) : (<div className="col-md-6">
                <div className="form-group">
                  <label>Specialité</label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    onChange={this.addSpec}
                    options={this.state.listSpec}
                  />
                </div>
              </div>)}

            </div>
            <div style={{ display: "flex" }}>
              {this.state.selectSpec.value == 2 ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <div>
                      <div className="form-group">
                        <p>
                          {" "}
                          Trimestre 1{" "}
                          {this.state.prTrim == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) => this.addTr1(e)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => this.addTr1(e)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <p>
                          {" "}
                          Trimestre 2{" "}
                          {this.state.deuxTrim == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) => this.addTr2(e)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => this.addTr2(e)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <p>
                          {" "}
                          Trimestre 3{" "}
                          {this.state.trTrim == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) => this.addTr3(e)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => this.addTr3(e)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <p>
                          {" "}
                          Stage{" "}
                          {this.state.stage == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) => this.addStage(e)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => this.addStage(e)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                    </div>
                    <div>
                      <div className="form-group">
                        <p>
                          {" "}
                          Moyenne d'admission{" "}
                          {this.state.adm == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) => this.addADM(e)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => this.addADM(e)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Groupe</label>
                        <Select
                          value={this.state.selectGroupe}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addGroupe}
                          options={this.state.listGroupe}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Eleve</label>
                        <Select
                          value={this.state.selectAppAutre}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addEleve}
                          options={this.state.listAppAutre}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ajout}
                    >
                      Confirmer
                    </button>
                  </div>
                </div>
              ) : null}
              {this.state.selectSpec.value == 1 ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <div>
                      <div className="form-group">
                        <p>
                          {" "}
                          Relevé BTS{" "}
                          {this.state.releveBts == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) => this.addReleveBTS(e)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => this.addReleveBTS(e)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <p>
                          {" "}
                          Stage{" "}
                          {this.state.stageBts == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) => this.addStageBTS(e)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => this.addStageBTS(e)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <p>
                          {" "}
                          PFE{" "}
                          {this.state.pfe == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) => this.addPFE(e)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => this.addPFE(e)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <p>
                          {" "}
                          Moyenne d'admission{" "}
                          {this.state.moyAdmBts == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) => this.addMoyAdmBts(e)}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) => this.addMoyAdmBts(e)}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Groupe</label>
                        <Select
                          value={this.state.selectGroupeBTS}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addGroupeBTS}
                          options={this.state.listGroupeBTS}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Eleve</label>
                        <Select
                          value={this.state.selectAppBTS}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addEleveBTS}
                          options={this.state.listAppBTS}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ajout}
                    >
                      Confirmer
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AffichRes);
