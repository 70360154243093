import React, { Component } from "react";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ConsAvis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avis: "",
      date: "",
      selectGroupe: "",
      apprenant: "",
      enseignant: "",
      app: "",
      ens: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      let idAvis = this.props.location.state.idAvis;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/GetAvisById.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          idAvis: idAvis,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            avis: code_html.avis,
            date: code_html.date,
            selectGroupe: code_html.groupe,
            apprenant: code_html.apprenant,
            enseignant: code_html.enseignant,
            app: code_html.app,
            ens: code_html.ens,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Consultation avis</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/ConsulterAvis">
                    {" "}
                    <button
                      style={{
                        background: "#DC143C",
                        border: "1px solid #DC143C",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i
                        style={{ paddingRight: "5px" }}
                        className="fas fa-arrow-circle-left"
                      ></i>
                      Retour
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        disbaled
                        selected={this.state.dateAvis}
                        value={this.state.date}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Avis</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Avis"
                        value={this.state.avis}
                        disabled
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Fichier</label>
                      <input
                        type="file"
                        className="form-control"
                        name="file"
                        onChange={(e) => this.addFile(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isMulti={true}
                        isClearable={true}
                        disabled
                        isSearchable={true}
                        value={this.state.selectGroupe}
                      />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-md-6">
                    <label>Apprenant</label>
                    {this.state.apprenant == 2 ? (
                      <div style={{ paddingRight: "10px" }}>
                        <label style={{ paddingRight: "5px" }}>
                          Tous les apprenants
                        </label>
                        <input type="radio" defaultChecked />
                      </div>
                    ) : (
                      <div style={{ paddingRight: "10px" }}>
                        <label style={{ paddingRight: "5px" }}>
                          Apprenants Particuliers
                        </label>
                        <input type="radio" defaultChecked />
                      </div>
                    )}
                  </div>
                  {this.state.apprenant == 1 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Apprenant</label>
                        <Select
                          isMulti={true}
                          isClearable={true}
                          disabled
                          isSearchable={true}
                          value={this.state.app}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="row" style={{ marginBottom: "10px" }}>
                  <div className="col-md-6">
                    <label>Enseignant</label>
                    {this.state.enseignant == 2 ? (
                      <div style={{ paddingRight: "10px" }}>
                        <label style={{ paddingRight: "5px" }}>
                          Tous les enseignants
                        </label>
                        <input type="radio" defaultChecked />
                      </div>
                    ) : (
                      <div style={{ paddingRight: "10px" }}>
                        <label style={{ paddingRight: "5px" }}>
                          {" "}
                          Enseignants Particuliers
                        </label>
                        <input type="radio" defaultChecked />
                      </div>
                    )}
                  </div>
                  {this.state.enseignant == 1 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Enseignant</label>
                        <Select
                          isMulti={true}
                          isClearable={true}
                          disabled
                          isSearchable={true}
                          value={this.state.ens}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConsAvis);
