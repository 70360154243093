import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'

class NoteNiveau extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNiveau: [],
      listNatureNote: [],
      selectedNiveau: "",
      selectedNatureNote: [],
      listMatieresPratiques: [],
      selectedMatieresPratiques: "",
      selectedMatieres: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectNiveau.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listNiveau: code_html,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectNatureNote.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listNatureNote: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }
  addNiveau = (selectedNiveau) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectedNiveau != null) {
      this.setState({ selectedNiveau }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/SelectMatiereByNiv.php",
          type: "POST",
          data: {
            niveauId: selectedNiveau.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listMatieres: code_html,
            });
          },
        });
      });
    } else {
      this.setState({
        selectedGroupe: "",
        selectedNiveau: "",
        selectedMatieres: "",
        selectedMatieresPratiques: "",
      });
    }
  };

  addNatureNote = (selectedNatureNote) => {
    this.setState({
      selectedNatureNote: selectedNatureNote,
    });
  };

  ajout = () => {
    if (
      this.state.selectedNiveau != "" &&
      this.state.selectedNatureNote != ""
    ) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      if (this.state.selectedMatieresPratiques != "") {
        var selectedMatPrat = this.state.selectedMatieresPratiques.value;
      } else {
        var selectedMatPrat = "";
      }
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/AddNiveauNatureNote.php",
        type: "POST",
        data: {
          natureNote: this.state.selectedNatureNote,
          idNiveau: this.state.selectedNiveau.value,
          anneeScolaire: anneeScolaire,
          selectedMatieres: this.state.selectedMatieres.value,
          selectedMatPrat: selectedMatPrat,
        },
        dataType: "json",
        success: (code_html, statut) => {
          if (code_html.Result == "OK") {
            this.props.history.push("/NiveauNatureNote");
          } else if (code_html.Result == "KO") {
            alert("erreur,l'un de vos champs est vide!!");
          }
        },
      });
    }
  };
  addMatiere = (selectedMatieres) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState({ selectedMatieres: selectedMatieres }, () => {
      console.log(
        "this.state.selectedMatieres.value",
        this.state.listMatieres
      );
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectedMatieresPratiques.php",
        type: "POST",
        data: {
          matiereId: selectedMatieres.value,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listMatieresPratiques: code_html,
          });
        },
      });
    });
  };
  addMatierePratique = (selectedMatieresPratiques) => {
    this.setState({ selectedMatieresPratiques: selectedMatieresPratiques });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Ajout note niveau</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                  <Link to="/NiveauNatureNote"> <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Niveau</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addNiveau}
                        options={this.state.listNiveau}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Matiere</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addMatiere}
                        options={this.state.listMatieres}
                      />
                    </div>
                  </div>
                  {this.state.listMatieresPratiques.length > 0 ? (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Sous Matière</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addMatierePratique}
                          options={this.state.listMatieresPratiques}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nature Note</label>
                      <Select
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addNatureNote}
                        options={this.state.listNatureNote}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    style={{ borderRadius: "5px" }}
                    type="button"
                    className="btn btn-success"
                    onClick={this.ajout}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NoteNiveau);
