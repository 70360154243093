import React, { Component, Fragment } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

class AjoutDerog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listEleve: [],
            selectedElv: '',
            matEg: [],
            matEt: [],
            matEp: [],
            disabledEg: false,
            disabledEt: false,
            disabledEp: false,
            arrayDerogEg: [],
            arrayDerogEt: [],
            arrayDerogEp: []
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectAppGroupes.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listEleve: code_html
                    });
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    selectEleve = selectedElv => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (selectedElv != null) {
            this.setState({ selectedElv: selectedElv }, () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/GetMatByEleve.php',
                    type: 'POST',
                    data: {
                        anneeScolaire: anneeScolaire,
                        eleveId: selectedElv.value
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            matEg: code_html[0],
                            matEt: code_html[1],
                            matEp: code_html[2]
                        });
                    }
                });
            });
        }
        else {
            this.setState({
                matEg: [],
                matEt: [],
                matEp: []
            });
        }
    };

    addDerogEg = (e, idMat) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayEg = this.state.arrayDerogEg
            arrayEg.push(idMat)
            this.setState({
                arrayDerogEg: arrayEg
            });
        }
        else {
            var arrayEg = this.state.arrayDerogEg
            var arrayEg = arrayEg.filter(e => e != idMat);
            this.setState({
                arrayDerogEg: arrayEg
            });
        }
    }

    addDerogEt = (e, idMat) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayEt = this.state.arrayDerogEt
            arrayEt.push(idMat)
            this.setState({
                arrayDerogEt: arrayEt
            });
        }
        else {
            var arrayEt = this.state.arrayDerogEt
            var arrayEt = arrayEt.filter(e => e != idMat);
            this.setState({
                arrayDerogEt: arrayEt
            });
        }
    }

    addDerogEp = (e, idMat) => {
        console.log('Checkbox checked:', (e.target.checked));
        if (e.target.checked === true) {
            var arrayEp = this.state.arrayDerogEp
            arrayEp.push(idMat)
            this.setState({
                arrayDerogEp: arrayEp
            });
        }
        else {
            var arrayEp = this.state.arrayDerogEp
            var arrayEp = arrayEp.filter(e => e != idMat);
            this.setState({
                arrayDerogEp: arrayEp
            });
        }
    }

    ajout = () => {
        var arrayMatDerog = []
        var array = arrayMatDerog.concat(this.state.arrayDerogEg, this.state.arrayDerogEt, this.state.arrayDerogEp);
        //
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddDerog.php',
            type: 'POST',
            data: {
                anneeScolaire: anneeScolaire,
                eleveId: this.state.selectedElv.value,
                arrayMatDerog: array
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Derogation');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout dérogation</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Derogation"><button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Eléves</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => this.selectEleve(e)}
                                                options={this.state.listEleve}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {this.state.matEg.length > 0 || this.state.matEt.length > 0 || this.state.matEp.length > 0 ? (
                                        <Fragment>
                                            <h4 style={{ padding: '5px', color: '#a0171b', fontWeight: 'bold' }}>Enseignement Géneral</h4>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        {this.state.matEg.map((el, index) =>
                                                            <td style={{ textAlign: 'center' }}>{el.matiere} <Checkbox
                                                                onChange={(e) => this.addDerogEg(e, el.idMat)}
                                                                disabled={this.state.disabledEg}
                                                            /></td>

                                                        )}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h4 style={{ padding: '5px', color: '#a0171b', fontWeight: 'bold' }}>Enseignement Technique</h4>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        {this.state.matEt.map((el, index) =>
                                                            <td style={{ textAlign: 'center' }}>{el.matiere} <Checkbox
                                                                onChange={(e) => this.addDerogEt(e, el.idMat)}
                                                                disabled={this.state.disabledEt}
                                                            /></td>
                                                        )}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h4 style={{ padding: '5px', color: '#a0171b', fontWeight: 'bold' }}>Enseignement Pratique</h4>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        {this.state.matEp.map((el, index) =>
                                                            <td style={{ textAlign: 'center' }}>{el.matiere} <Checkbox
                                                                onChange={(e) => this.addDerogEp(e, el.idMat)}
                                                                disabled={this.state.disabledEp}
                                                            /></td>
                                                        )}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                                <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                            </div>
                                        </Fragment>
                                    ) : (null)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                </div>
            </div>
        );
    }
}
export default withRouter(AjoutDerog);