import React, { Component, Fragment } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import jQuery from "jquery";
import "./paginate.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ChequeEns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "CIN",
          selector: "cin",
          sortable: false,
          center: true,
        },
        {
          name: "Nom",
          selector: "nomComplet",
          sortable: false,
          center: true,
        },
        {
          name: "Numéro piéce",
          selector: "numPiece",
          sortable: false,
          center: true,
        },
        {
          name: "Echeance",
          selector: "echeance",
          sortable: false,
          center: true,
        },
        {
          name: "Montant",
          selector: "montant",
          sortable: false,
          center: true,
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      idEnseignant: "",
      pending: true,
      permissionEnseignants: "",
      permissionPayEnseign: "",
      permissionEnseignGroup: "",
      // search
      enseignants: [],
      selectedEns: "",
      dateDebut: "",
      dateFin: "",
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/ChequeEns.php",
        type: "POST",
        data: {
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectEnsReg.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            enseignants: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  newElement(page, totalRows) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEns != "") {
      var ensId = this.state.selectedEns.value;
    } else {
      var ensId = "";
    }
    // convertir date debut
    if (this.state.dateDebut != "") {
      var dateDebut = this.state.dateDebut;
      let monthOne = "" + (dateDebut.getMonth() + 1);
      let dayOne = "" + dateDebut.getDate();
      let yearOne = dateDebut.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateDebut = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateDebut = "";
    }
    // convertir date fin
    if (this.state.dateFin != "") {
      var dateFin = this.state.dateFin;
      let monthTwo = "" + (dateFin.getMonth() + 1);
      let dayTwo = "" + dateFin.getDate();
      let yearTwo = dateFin.getFullYear();
      if (monthTwo.length < 2) monthTwo = "0" + monthTwo;
      if (dayTwo.length < 2) dayTwo = "0" + dayTwo;
      var convertDateFin = [yearTwo, monthTwo, dayTwo].join("-");
    } else {
      var convertDateFin = "";
    }
    if (ensId == "" && convertDateDebut == "" && convertDateFin == "") {
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ChequeEns.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ChequeEnsSearch.php",
            type: "POST",
            data: {
              ensId: ensId,
              dateDebut: convertDateDebut,
              dateFin: convertDateFin,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }

  perPage(currentRowsPerPage, currentPage) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEns != "") {
      var ensId = this.state.selectedEns.value;
    } else {
      var ensId = "";
    }
    // convertir date debut
    if (this.state.dateDebut != "") {
      var dateDebut = this.state.dateDebut;
      let monthOne = "" + (dateDebut.getMonth() + 1);
      let dayOne = "" + dateDebut.getDate();
      let yearOne = dateDebut.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateDebut = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateDebut = "";
    }
    // convertir date fin
    if (this.state.dateFin != "") {
      var dateFin = this.state.dateFin;
      let monthTwo = "" + (dateFin.getMonth() + 1);
      let dayTwo = "" + dateFin.getDate();
      let yearTwo = dateFin.getFullYear();
      if (monthTwo.length < 2) monthTwo = "0" + monthTwo;
      if (dayTwo.length < 2) dayTwo = "0" + dayTwo;
      var convertDateFin = [yearTwo, monthTwo, dayTwo].join("-");
    } else {
      var convertDateFin = "";
    }
    if (ensId == "" && convertDateDebut == "" && convertDateFin == "") {
      this.setState(
        {
          nbrEltperPage: currentRowsPerPage,
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ChequeEns.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: currentRowsPerPage,
              page: currentPage,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      this.setState(
        {
          nbrEltperPage: currentRowsPerPage,
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/ChequeEnsSearch.php",
            type: "POST",
            data: {
              ensId: ensId,
              dateDebut: convertDateDebut,
              dateFin: convertDateFin,
              limit: currentRowsPerPage,
              page: currentPage,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }

  filterSearch(event) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/ChequeEns.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  addEnseignant = (selectedEns) => {
    if (selectedEns != null) {
      this.setState({ selectedEns });
    } else {
      this.setState({ selectedEns: "" });
    }
  };

  addDateDebut = (d) => {
    this.setState({
      dateDebut: d,
    });
  };

  addDateFin = (d) => {
    this.setState({
      dateFin: d,
    });
  };

  recherche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEns != "") {
      var ensId = this.state.selectedEns.value;
    } else {
      var ensId = "";
    }
    // convertir date debut
    if (this.state.dateDebut != "") {
      var dateDebut = this.state.dateDebut;
      let monthOne = "" + (dateDebut.getMonth() + 1);
      let dayOne = "" + dateDebut.getDate();
      let yearOne = dateDebut.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateDebut = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateDebut = "";
    }
    // convertir date fin
    if (this.state.dateFin != "") {
      var dateFin = this.state.dateFin;
      let monthTwo = "" + (dateFin.getMonth() + 1);
      let dayTwo = "" + dateFin.getDate();
      let yearTwo = dateFin.getFullYear();
      if (monthTwo.length < 2) monthTwo = "0" + monthTwo;
      if (dayTwo.length < 2) dayTwo = "0" + dayTwo;
      var convertDateFin = [yearTwo, monthTwo, dayTwo].join("-");
    } else {
      var convertDateFin = "";
    }
    this.setState(
      {
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ChequeEnsSearch.php",
          type: "POST",
          data: {
            ensId: ensId,
            dateDebut: convertDateDebut,
            dateFin: convertDateFin,
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  afficherTout = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState({
      selectedEns: "",
      dateDebut: "",
      dateFin: "",
    });
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/ChequeEns.php",
      type: "POST",
      data: {
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  impr = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEns != "") {
      var ensId = this.state.selectedEns.value;
    } else {
      var ensId = "";
    }
    // convertir date debut
    if (this.state.dateDebut != "") {
      var dateDebut = this.state.dateDebut;
      let monthOne = "" + (dateDebut.getMonth() + 1);
      let dayOne = "" + dateDebut.getDate();
      let yearOne = dateDebut.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateDebut = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateDebut = "";
    }
    // convertir date fin
    if (this.state.dateFin != "") {
      var dateFin = this.state.dateFin;
      let monthTwo = "" + (dateFin.getMonth() + 1);
      let dayTwo = "" + dateFin.getDate();
      let yearTwo = dateFin.getFullYear();
      if (monthTwo.length < 2) monthTwo = "0" + monthTwo;
      if (dayTwo.length < 2) dayTwo = "0" + dayTwo;
      var convertDateFin = [yearTwo, monthTwo, dayTwo].join("-");
    } else {
      var convertDateFin = "";
    }
    //
    if (this.state.data.length != 0 ) {
        
    
        var date = convertDateDebut + "/" + convertDateFin +"/" +ensId ;
        window.open("http://artsmetiers.mtd-app.com/ImprCheqEns?date=" + date);
      } else {
        alert("choisir date")
    }
    
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Cheques</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>
                  <Fragment>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Date debut</label>
                        <DatePicker
                          class="form-control ddate"
                          style={{ border: "none" }}
                          selected={this.state.dateDebut}
                          onChange={this.addDateDebut}
                          dateFormat="dd/MM/yyy"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Date fin</label>
                        <DatePicker
                          class="form-control ddate"
                          style={{ border: "none" }}
                          selected={this.state.dateFin}
                          onChange={this.addDateFin}
                          dateFormat="dd/MM/yyy"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Enseignants</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={(e) => this.addEnseignant(e)}
                          options={this.state.enseignants}
                        />
                      </div>
                    </div>
                  </Fragment>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.afficherTout}
                    >
                      Afficher tout
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.impr}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChequeEns;
