import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class ReleveNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listEleves: [],
      selectEleve: "",
      listTrimestre: [
        { value: 1, label: "Trimestre 1" },
        { value: 2, label: "Trimestre 2" },
        { value: 3, label: "Trimestre 3" },
      ],
      selectTrimestre: "",
      inscriptionId: "",
      niveau: "",
      nomFiliere: "",
      groupe: "",
      moduleEG: [],
      moduleET: [],
      moduleEP: [],
      blockNote: false,
      arrayAllData: [],
      moyenneModule: "",
      notes: [],
      annee:'',
      scolaire:0,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      
     
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetElevesInscri.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listEleves: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addEleve = (selectEleve) => {
    if (selectEleve != null) {
      this.setState({ selectEleve });
    } else {
      this.setState({
        selectEleve: "",
      });
    }
  };

  addTrimestre = (selectTrimestre) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectTrimestre != null) {
      this.setState({ selectTrimestre }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetInfoByIdEleve.php",
          type: "POST",
          data: {
            eleveId: this.state.selectEleve.value,
            trimestre: selectTrimestre.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              inscriptionId: code_html.inscriptionId,
              niveau: code_html.niveau,
              nomFiliere: code_html.nomFiliere,
              groupe: code_html.groupe,
              scolaire: code_html.anneescolaire,
            });
          },
        });
      });
    } else {
      this.setState({
        selectTrimestre: "",
      });
    }
  };

  affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.state.annee=anneeScolaire;
   
   
    if (this.state.selectEleve != "" && this.state.selectTrimestre != "") {
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/ReleveNote.php",
        type: "POST",
        data: {
          eleveId: this.state.selectEleve.value,
          anneeScolaire: anneeScolaire,
          trimestre: this.state.selectTrimestre.value,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            notes: code_html,
          });
        },
      });
      this.setState({
        blockNote: true,
      });
    } else {
      toast.error("⛔ Veuillez vérifier Eleve,Trimestre !", {
        containerId: "A",
      });
    }
  };

  impr = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.state.annee=anneeScolaire;
   
    
    if (this.state.selectEleve != "" && this.state.selectTrimestre != "") {
      if (this.state.notes[0].moyGen != "0.00") {
        
        var eleveId = this.state.selectEleve.value + "/" + this.state.selectTrimestre.value + "/"+this.state.scolaire;
        window.open(
          "http://artsmetiers.mtd-app.com/ImprReleveNote?date=" + eleveId
        );

      }
    } else {
      toast.error("⛔ Veuillez vérifier Eleve,Trimestre !", {
        containerId: "A",
      });
    }
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Relevé De Notes </h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Eleve</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addEleve}
                        options={this.state.listEleves}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Trimestre</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addTrimestre}
                        options={this.state.listTrimestre}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Afficher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.impr}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
                {this.state.blockNote == true ? (
                  <div>
                    <div style={{ margin: "5vh" }}>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Numéro Inscription : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          {this.state.inscriptionId}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Formation : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          De October {this.state.annee} à Juillet {this.state.scolaire}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Spécialité : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          {this.state.nomFiliere}{" "}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Niveau : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          {this.state.niveau}{" "}
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p style={{ textDecoration: "underline" }}>
                          <strong>Groupe : </strong>
                        </p>
                        <p style={{ paddingLeft: "5px" }}>
                          {this.state.groupe}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h3>Relevé de notes</h3>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h3>{"Trimestre" + this.state.selectTrimestre.value}</h3>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th style={{ color: "#ac3939" }}>Enseignement</th>
                          <th></th>
                          <th style={{ color: "#ac3939" }}>
                            Moyenne Enseignement
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.notes.map((el) => (
                          <tr>
                            <td>{el.notes.nameEP}</td>
                            <td>
                              {el.notes.noteEP.map((elem) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{ width: "20vw" }}>
                                    {" "}
                                    {elem.matiere}{" "}
                                  </p>
                                  <p> {elem.matiereNote} </p>
                                </div>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.notes.moyEP} </p>
                            </td>
                          </tr>
                        ))}
                        {this.state.notes.map((el) => (
                          <tr>
                            <td>{el.notes.nameET}</td>
                            <td>
                              {el.notes.noteET.map((elem) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{ width: "20vw" }}>
                                    {" "}
                                    {elem.matiere}{" "}
                                  </p>
                                  <p> {elem.matiereNote} </p>
                                </div>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.notes.moyET} </p>
                            </td>
                          </tr>
                        ))}
                        {this.state.notes.map((el) => (
                          <tr>
                            <td>{el.notes.nameEG}</td>
                            <td>
                              {el.notes.noteEG.map((elem) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{ width: "20vw" }}>
                                    {" "}
                                    {elem.matiere}{" "}
                                  </p>
                                  <p> {elem.matiereNote} </p>
                                </div>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.notes.moyEG} </p>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                              Moyenne Trimestrielle :{" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {this.state.notes.map((element) => (
                              <p> {element.moyGen} </p>
                            ))}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    );
  }
}

export default withRouter(ReleveNote);
