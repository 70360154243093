import React, { Component } from "react";
import jQuery from "jquery";
import './paginate.css'

class ImprMotif extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nomElv: '',
            scolAnnee: '',
            naissance: '',
            cin: '',
            ins: '',
            niveau: '',
            today: '',
            motif: '',
            selectedMotif: '',
            codeTranstu: ''
        };
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
            var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
            var anneeScolaire = userPermissions[0].anneeScolaire;
            var anneeScolaireNv = parseInt(anneeScolaire) + 1
            var scolAnnee = anneeScolaire + '/' + anneeScolaireNv
            var url = window.location.href
            var arrayUrl = url.split("=");
            var params = arrayUrl[1]
            var arrayParams = params.split("-");
            var idElv = arrayParams[0]
            var idMotif = arrayParams[1]
            jQuery.ajax({
                url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/GetInformationEleve.php",
                type: "POST",
                data: {
                    anneeScolaire: anneeScolaire,
                    eleveId: idElv,
                    motifId: idMotif
                },
                dataType: "json",
                success: (code_html, statut) => {
                    this.setState({
                        selectedMotif: idMotif,
                        today: code_html.today,
                        nomElv: code_html.nomElv,
                        naissance: code_html.naissance,
                        cin: code_html.cin,
                        ins: code_html.idIns,
                        niveau: code_html.niveau,
                        scolAnnee: scolAnnee,
                        codeTranstu: code_html.code
                    });
                }
            });
            jQuery.ajax({
                url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/GetMotifById.php",
                type: "POST",
                data: {
                    idMotif: idMotif
                },
                dataType: "json",
                success: (code_html, statut) => {
                    this.setState({
                        motif: code_html
                    });
                }
            });
        } else {
            document.location = "/";
        }
    }

    impr = () => {
        window.print();
    }

    render() {
        return (
            <div className="white-box">
                <div style={{ margin: '50px' }} className="row">
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '100px' }}><p style={{ fontWeight: 'bold' }}>Date:</p><p style={{ paddingLeft: '5px' }}>{this.state.today}</p></div>
                    {this.state.selectedMotif == 5 ? (<div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '100px' }}><p style={{ fontWeight: 'bold' }}>Code TRANSTU</p><p style={{ paddingLeft: '5px', fontWeight: 'bold' }}>{this.state.codeTranstu}</p></div>) : (null)}
                    <h4 style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>Attestation de présence</h4>
                    <p style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>Je soussigné, Taoufik CHAIRI, Directeur Général du centre de formation</p>
                    <p style={{ display: 'flex', justifyContent: 'center' }}>professionnelle Arts & Métiers,certifie par le présent que l'apprenant :</p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}><p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Nom et Prénom :</p><p style={{ paddingLeft: '5px' }}>{this.state.nomElv}</p></div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}><p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Né(e) le :</p><p style={{ paddingLeft: '5px' }}>{this.state.naissance}</p></div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}><p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>CIN  N ° :</p><p style={{ paddingLeft: '5px' }}>{this.state.cin}</p></div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}><p>Est inscrit(e) dans notre centre sous le</p><p style={{ paddingLeft: '5px', fontWeight: 'bold' }}>N°</p> <p style={{ paddingLeft: '5px' }}>{this.state.ins}</p><p style={{ paddingLeft: '5px' }}>en vue de poursuivre sa</p></div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}><p>scolarité en</p><p style={{ paddingLeft: '5px', fontWeight: 'bold' }}>{this.state.niveau}</p><p style={{ paddingLeft: '5px' }}>pour l'année scolaire</p><p style={{ paddingLeft: '5px' }}>{this.state.scolAnnee}</p></div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}><p>Motif de l'attestation : Constituer un dossier de </p><p style={{ paddingLeft: '5px' }}>{this.state.motif}</p></div>
                    <p style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '310px', marginTop: '20px' }}>Le Directeur Général</p>
                    <p style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '310px' }}>Taoufik CHAIRI</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}><i id="print_me_not" style={{ fontSize: '30px', cursor: 'pointer' }} onClick={this.impr} class="fas fa-print"></i></div>
            </div>
        );
    }
}

export default ImprMotif;

