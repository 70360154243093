import React, { Component, Fragment } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable, { createTheme } from 'react-data-table-component';
import jQuery from 'jquery'
import './paginate.css'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class RecuEmis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'N°',
                    selector: 'num',
                    sortable: false,
                    center: true,
                    style: { width: '5%' }
                },
                {
                    name: 'N° Recu',
                    selector: 'numRecu',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: "N° d'inscription",
                    selector: 'numIns',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Apprenant',
                    selector: 'nomComplet',
                    sortable: false,
                    center: true,
                    style: { width: '30%' }
                },
                {
                    name: 'Réglement',
                    selector: 'reglement',
                    sortable: false,
                    center: true,
                    style: { width: '15%' }
                },
                {
                    name: 'Tranche',
                    selector: 'numTrch',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                },
                {
                    name: 'Mode paiement',
                    cell: row => <div onClick={() => this.modePay(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px' }}><p>Mode</p></div></div>
                    ,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Montant',
                    selector: 'montant',
                    sortable: false,
                    center: true,
                    style: { width: '10%' }
                }
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            modal: false,
            pending: true,
            infoPay: [],
            // search
            eleve: [],
            selectedElv: '',
            dateDebut: '',
            dateFin: ''
        }
        this.newElement = this.newElement.bind(this);
        this.filterSearch = this.filterSearch.bind(this);
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuEmis.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire,
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEleves.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        eleve: code_html
                    });
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    modePay = (row) => {
        this.setState({
            infoPay: row.infoPay
        }, () => {
            console.log(this.state.infoPay)
            this.setState(prevState => ({
                modal: !prevState.modal,
            }));
        });
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    newElement(page, totalRows) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedElv != '') {
            var idElv = this.state.selectedElv.value
        }
        else {
            var idElv = ''
        }
        // convertir date debut
        if (this.state.dateDebut != '') {
            var dateDebut = this.state.dateDebut
            let monthOne = '' + (dateDebut.getMonth() + 1);
            let dayOne = '' + dateDebut.getDate();
            let yearOne = dateDebut.getFullYear();
            if (monthOne.length < 2)
                monthOne = '0' + monthOne;
            if (dayOne.length < 2)
                dayOne = '0' + dayOne;
            var convertDateDebut = [yearOne, monthOne, dayOne].join('-');
        }
        else {
            var convertDateDebut = '';
        }
        // convertir date fin
        if (this.state.dateFin != '') {
            var dateFin = this.state.dateFin
            let monthTwo = '' + (dateFin.getMonth() + 1);
            let dayTwo = '' + dateFin.getDate();
            let yearTwo = dateFin.getFullYear();
            if (monthTwo.length < 2)
                monthTwo = '0' + monthTwo;
            if (dayTwo.length < 2)
                dayTwo = '0' + dayTwo;
            var convertDateFin = [yearTwo, monthTwo, dayTwo].join('-');
        }
        else {
            var convertDateFin = '';
        }
        if (idElv == '' && convertDateDebut == '' && convertDateFin == '') {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuEmis.php',
                        type: 'POST',
                        data: {
                            anneeScolaire: anneeScolaire,
                            query: this.state.filterElement,
                            limit: this.state.nbrEltperPage,
                            page: page
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
        else {
            this.setState({
                pending: true
            },
                () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuEmisSearch.php',
                        type: 'POST',
                        data: {
                            elvId: idElv,
                            dateDebut: convertDateDebut,
                            dateFin: convertDateFin,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    perPage(currentRowsPerPage, currentPage) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedElv != '') {
            var idElv = this.state.selectedElv.value
        }
        else {
            var idElv = ''
        }
        // convertir date debut
        if (this.state.dateDebut != '') {
            var dateDebut = this.state.dateDebut
            let monthOne = '' + (dateDebut.getMonth() + 1);
            let dayOne = '' + dateDebut.getDate();
            let yearOne = dateDebut.getFullYear();
            if (monthOne.length < 2)
                monthOne = '0' + monthOne;
            if (dayOne.length < 2)
                dayOne = '0' + dayOne;
            var convertDateDebut = [yearOne, monthOne, dayOne].join('-');
        }
        else {
            var convertDateDebut = '';
        }
        // convertir date fin
        if (this.state.dateFin != '') {
            var dateFin = this.state.dateFin
            let monthTwo = '' + (dateFin.getMonth() + 1);
            let dayTwo = '' + dateFin.getDate();
            let yearTwo = dateFin.getFullYear();
            if (monthTwo.length < 2)
                monthTwo = '0' + monthTwo;
            if (dayTwo.length < 2)
                dayTwo = '0' + dayTwo;
            var convertDateFin = [yearTwo, monthTwo, dayTwo].join('-');
        }
        else {
            var convertDateFin = '';
        }
        if (idElv == '' && convertDateDebut == '' && convertDateFin == '') {
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuEmis.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            );
        }
        else {
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuEmisSearch.php',
                        type: 'POST',
                        data: {
                            elvId: idElv,
                            dateDebut: convertDateDebut,
                            dateFin: convertDateFin,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            );
        }
    }

    filterSearch(event) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuEmis.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    recherche = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedElv != '') {
            var idElv = this.state.selectedElv.value
        }
        else {
            var idElv = ''
        }
        // convertir date debut
        if (this.state.dateDebut != '') {
            var dateDebut = this.state.dateDebut
            let monthOne = '' + (dateDebut.getMonth() + 1);
            let dayOne = '' + dateDebut.getDate();
            let yearOne = dateDebut.getFullYear();
            if (monthOne.length < 2)
                monthOne = '0' + monthOne;
            if (dayOne.length < 2)
                dayOne = '0' + dayOne;
            var convertDateDebut = [yearOne, monthOne, dayOne].join('-');
        }
        else {
            var convertDateDebut = '';
        }
        // convertir date fin
        if (this.state.dateFin != '') {
            var dateFin = this.state.dateFin
            let monthTwo = '' + (dateFin.getMonth() + 1);
            let dayTwo = '' + dateFin.getDate();
            let yearTwo = dateFin.getFullYear();
            if (monthTwo.length < 2)
                monthTwo = '0' + monthTwo;
            if (dayTwo.length < 2)
                dayTwo = '0' + dayTwo;
            var convertDateFin = [yearTwo, monthTwo, dayTwo].join('-');
        }
        else {
            var convertDateFin = '';
        }
        this.setState({
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuEmisSearch.php',
                    type: 'POST',
                    data: {
                        elvId: idElv,
                        dateDebut: convertDateDebut,
                        dateFin: convertDateFin,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    afficherTout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            selectedElv: '',
            dateDebut: '',
            dateFin: ''
        });
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/RecuEmis.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false
                });
            }
        });
    }

    searchElv = (selectedElv) => {
        if (selectedElv != null) {
            this.setState({ selectedElv: selectedElv });
        }
        else {
            this.setState({ selectedElv: '' });
        }
    }

    searchDateDebut = d => {
        this.setState({
            dateDebut: d
        });
    };

    searchDateFin = d => {
        this.setState({
            dateFin: d
        });
    };

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Récu emis</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="row">
                                    <h4 style={{ paddingLeft: '10px' }}>Critères de recherche</h4>
                                    <Fragment>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Etudiant</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={this.searchElv}
                                                    options={this.state.eleve}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Date debut</label>
                                                <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                    selected={this.state.dateDebut}
                                                    onChange={this.searchDateDebut}
                                                    dateFormat="dd/MM/yyy"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Date fin</label>
                                                <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                    selected={this.state.dateFin}
                                                    onChange={this.searchDateFin}
                                                    dateFormat="dd/MM/yyy"
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.recherche}>Rechercher</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.afficherTout}>Afficher tout</button>
                                    </div>
                                </div>
                                <div className="mb-0 form-group">
                                    <div className="input-group-alternative input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input onChange={(currentRowsPerPage, currentPage) => this.filterSearch(currentRowsPerPage, currentPage)} placeholder="Search" type="text" className="search" />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
                            </div>
                        </div>
                        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} fade={false}>
                            <ModalHeader toggle={this.toggle}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="fas fa-money-check-alt"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Mode de paiement</p></div></div></ModalHeader>
                            <ModalBody>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }} scope="col">Mode</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Montant</th>
                                            <th style={{ textAlign: 'center' }} scope="col">Numéro piéce</th>
                                        </tr>
                                    </thead>
                                    {this.state.infoPay.map((el, index) =>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>{el.mode}</td>
                                                <td style={{ textAlign: 'center' }}>{el.mnt}</td>
                                                <td style={{ textAlign: 'center' }}>{el.numPiece}</td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                                <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                    <div><button onClick={() => this.toggle()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                                </div>
                            </ModalBody>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}
export default RecuEmis;