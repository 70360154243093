import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class ImprListVierge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listEleves: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var arrayGroupe = url.split("=");
      var eleveId = arrayGroupe[1];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprDemandeAttestation.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          eleveId: eleveId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listEleves: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
          </div>
        </div>
        <div style={{ textAlign: "center" }} className="row">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>Liste des Apprenants</label>
          </div>
        </div>
        <div style={{ textAlign: "center" }} className="row">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>Motif de l'attestation</label>
          </div>
        </div>
        <table style={{ border: "solid", marginBottom: "0", width: "100%" }}>
          <thead>
            <th
              style={{ textAlign: "center", border: "solid", color: "black" }}
            >
              {" "}
              Numéro{" "}
            </th>
            <th
              style={{ textAlign: "center", border: "solid", color: "black" }}
            >
              {" "}
              Apprenant{" "}
            </th>
            <th
              style={{ textAlign: "center", border: "solid", color: "black" }}
            >
              {" "}
              Motif{" "}
            </th>
            <th
              style={{ textAlign: "center", border: "solid", color: "black" }}
            >
              {" "}
              Date{" "}
            </th>
            
          </thead>
          <tbody>
            {this.state.listEleves.map((el) => (
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {" "}
                  {el.numero}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {" "}
                  {el.nomEleve}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {" "}
                  {el.attestation}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {" "}
                  {el.date}{" "}
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprListVierge);
