import React, { Component, Fragment } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import DataTable from "react-data-table-component";

import jQuery from "jquery";
import "./paginate.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import "react-toastify/dist/ReactToastify.css";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
  var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
  var anneeScolaire = userPermissions[0].anneeScolaire;
  var nvAnneeScolaire = parseInt(anneeScolaire) + 1;
}

class PresenceEnseignant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: <p>{"Nom"}</p>,
          selector: "nomComplet",
          sortable: false,
          center: true,
          style: { width: "16%" },
        },
        {
          name: <p>{"Sep " + anneeScolaire}</p>,
          cell: (row) =>
            row.detail[0][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[0][0])}>
                {row.detail[0][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Oct " + anneeScolaire}</p>,
          cell: (row) =>
            row.detail[1][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[1][0])}>
                {row.detail[1][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Nov " + anneeScolaire}</p>,
          cell: (row) =>
            row.detail[2][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[2][0])}>
                {row.detail[2][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Dec " + anneeScolaire}</p>,
          cell: (row) =>
            row.detail[3][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[3][0])}>
                {row.detail[3][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Jan " + nvAnneeScolaire}</p>,
          cell: (row) =>
            row.detail[4][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[4][0])}>
                {row.detail[4][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Fev " + nvAnneeScolaire}</p>,
          cell: (row) =>
            row.detail[5][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[5][0])}>
                {row.detail[5][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Mar " + nvAnneeScolaire}</p>,
          cell: (row) =>
            row.detail[6][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[6][0])}>
                {row.detail[6][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Avr " + nvAnneeScolaire}</p>,
          cell: (row) =>
            row.detail[7][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[7][0])}>
                {row.detail[7][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Mai " + nvAnneeScolaire}</p>,
          cell: (row) =>
            row.detail[8][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[8][0])}>
                {row.detail[8][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Juin " + nvAnneeScolaire}</p>,
          cell: (row) =>
            row.detail[9][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[9][0])}>
                {row.detail[9][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Juil " + nvAnneeScolaire}</p>,
          cell: (row) =>
            row.detail[10][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[10][0])}>
                {row.detail[10][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
        {
          name: <p>{"Aou " + nvAnneeScolaire}</p>,
          cell: (row) =>
            row.detail[11][0].nbrHeureParMois > 0 ? (
              <p onClick={() => this.detail(row.detail[11][0])}>
                {row.detail[11][0].nbrHeureParMois}
              </p>
            ) : null,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "7%",
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      idEnseignant: "",
      pending: true,
      detailEnseign: [],
      nomEnseign: "",
      mois: "",
      nbrPratique: "",
      nbrTheorique: "",
      nbrCycle: "",
      nbrtotal:"",
      modalModif: false,
      ligneModif: [],
      groupeEnseign: "",
      departSeance: "",
      tabs:[],
      finSeance: "",
      dateSeance: "",
      idEnteteAbs: [],
      tab: [],
      nbrHeureParMois:0,
      x:1,
      test:"false",
      // search
      listMois: [
        { value: 1, label: "Janvier" },
        { value: 2, label: "Férvier" },
        { value: 3, label: "Mars" },
        { value: 4, label: "Avril" },
        { value: 5, label: "Mai" },
        { value: 6, label: "Juin" },
        { value: 7, label: "Juillet" },
        { value: 8, label: "Aout" },
        { value: 9, label: "Septembre" },
        { value: 10, label: "Octobre" },
        { value: 11, label: "Novembre" },
        { value: 12, label: "Decembre" },
      ],
      selectedMois: "",
      enseignant: [],
      selectedEnseign: "",
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/PresenceEnseignants.php",
        type: "POST",
        data: {
          query: "",
          limit: this.state.nbrEltperPage,
          anneeScolaire: anneeScolaire,
          page: 1,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectEnseignants.php",
        type: "POST",
        data: {
          query: "",
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            enseignant: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  newElement(page, totalRows) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEnseign != "") {
      var enseignId = this.state.selectedEnseign.value;
    } else {
      var enseignId = "";
    }
    if (this.state.selectedMois != "") {
      var mois = this.state.selectedMois.value;
    } else {
      var mois = "";
    }
    if (enseignId == "" && mois == "") {
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/PresenceEnseignants.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/PresenceSearch.php",
            type: "POST",
            data: {
              enseignId: enseignId,
              mois: mois,
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }

  perPage(currentRowsPerPage, currentPage) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEnseign != "") {
      var enseignId = this.state.selectedEnseign.value;
    } else {
      var enseignId = "";
    }
    if (this.state.selectedMois != "") {
      var mois = this.state.selectedMois.value;
    } else {
      var mois = "";
    }
    if (enseignId == "" && mois == "") {
      this.setState(
        {
          nbrEltperPage: currentRowsPerPage,
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/PresenceEnseignants.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: currentRowsPerPage,
              page: currentPage,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } else {
      this.setState(
        {
          nbrEltperPage: currentRowsPerPage,
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/PresenceSearch.php",
            type: "POST",
            data: {
              enseignId: enseignId,
              mois: mois,
              limit: currentRowsPerPage,
              page: currentPage,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }

  filterSearch(event) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/PresenceEnseignants.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  afficherTout = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/PresenceEnseignants.php",
      type: "POST",
      data: {
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  detail = (row) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    var idEnseign = row.idEnseign;
    var nomEnseign = row.nomEnseign;
    this.state.nbrHeureParMois=row.nbrHeureParMois
    var mois = row.mois;
    this.setState({
      idEnseignant: idEnseign,
      idMois: mois,
    });
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/PresenceDetail.php",
      type: "POST",
      data: {
        idEnseign: idEnseign,
        nomEnseign: nomEnseign,
        mois: mois,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState(
          {
            detailEnseign: code_html,
            nomEnseign: code_html[0].nomComplet,
            mois: code_html[0].mois,
            anneeScolaire: code_html[0].anneeScol,
            monthNum: code_html[0].monthNum,
          },
          () => {
            var seancePr = 0;
            var seanceTh = 0;
            var seanceCy = 0;
            var seanceEg = 0;
            for (var i = 0; i < this.state.detailEnseign.length; i++) {
              var ensType = this.state.detailEnseign[i].typeEnseign;
              if(i>0){
              if (ensType == "EP" && this.state.detailEnseign[i].dateDebut != this.state.detailEnseign[i-1].dateDebut && this.state.detailEnseign[i].dateFin!=this.state.detailEnseign[i-1].dateFin) {
                seancePr++;
              
              } else if (ensType == "ET" && this.state.detailEnseign[i].dateDebut != this.state.detailEnseign[i-1].dateDebut && this.state.detailEnseign[i].dateFin!=this.state.detailEnseign[i-1].dateFin){
                seanceTh++;
              } else if (ensType == "EG" && this.state.detailEnseign[i].dateDebut != this.state.detailEnseign[i-1].dateDebut && this.state.detailEnseign[i].dateFin!=this.state.detailEnseign[i-1].dateFin){
                seanceEg++;
              
              }else if (ensType == "Cycle" && this.state.detailEnseign[i].dateDebut != this.state.detailEnseign[i-1].dateDebut && this.state.detailEnseign[i].dateFin!=this.state.detailEnseign[i-1].dateFin){
                seanceCy++;
              }
            }else{
                if (ensType == "EP") {
                  seancePr++;
                
                } else if (ensType == "ET"){
                  seanceTh++;
                } else if (ensType == "EG"){
                  seanceEg++;
                
                }else if (ensType == "Cycle"){
                  seanceCy++;
                }}

            }
              this.state.nbrtotal=seanceCy + seanceEg + seanceTh + seancePr
              for( var j = 0; j< this.state.nbrtotal-1;j++)
              {
                this.state.tabs[j] = this.state.nbrtotal-j
                }
              console.log("fff")
              var s=1
              for(var t = 0; t< this.state.detailEnseign.length;t++){

                if(t>0){
               if (this.state.detailEnseign[t].typeEnseign!=this.state.detailEnseign[t-1].typeEnseign && this.state.detailEnseign[t].dateDebut != this.state.detailEnseign[t-1].dateDebut && this.state.detailEnseign[t].dateFin!=this.state.detailEnseign[t-1].dateFin){
                this.state.tab[t]= s
                s++
               }else if(this.state.detailEnseign[t].typeEnseign==this.state.detailEnseign[t-1].typeEnseign && this.state.detailEnseign[t].dateDebut == this.state.detailEnseign[t-1].dateDebut && this.state.detailEnseign[t].dateFin==this.state.detailEnseign[t-1].dateFin){
                
                this.state.tab[t]=" "
                this.state.test="false"
                
              }else{
                this.state.tab[t]=s
                s++
                
              
                }
               
              }else{
                this.state.tab[t]=s
                s++
              }
               console.log(this.state.tab)
              }
            this.setState(
              {
                nbrPratique: seancePr,
                nbrTheorique: seanceTh,
                nbrEg: seanceEg,
                nbrCycle: seanceCy,
              
                
              },
              
              () => {
                this.setState((prevState) => ({
                  modal: !prevState.modal,
                }));
              }
              
            );
          }
        );
      },
    });
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  searchEnseignant = (selectedEnseign) => {
    if (selectedEnseign != null) {
      this.setState({ selectedEnseign });
    } else {
      var el = { value: "", label: "" };
      this.setState({ selectedEnseign: el });
    }
  };

  searchMois = (selectedMois) => {
    if (selectedMois != null) {
      this.setState({ selectedMois });
    } else {
      var el = { value: "", label: "" };
      this.setState({ selectedMois: el });
    }
  };

  recherche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEnseign != "") {
      var enseignId = this.state.selectedEnseign.value;
    } else {
      var enseignId = "";
    }
    if (this.state.selectedMois != "") {
      var mois = this.state.selectedMois.value;
    } else {
      var mois = "";
    }
    this.setState(
      {
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/PresenceSearch.php",
          type: "POST",
          data: {
            enseignId: enseignId,
            mois: mois,
            anneeScolaire: anneeScolaire,
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  modif = (el) => {
    var groupe = el.nomGroupe;
    var concatGroupe = groupe.toString();
    var idEnteteAbs = el.idEnteteAbs;
    this.setState(
      {
        ligneModif: el,
        groupeEnseign: concatGroupe,
        departSeance: new Date(el.dateDebut),
        finSeance: new Date(el.dateFin),
        dateSeance: new Date(el.date),
        idEnteteAbs: idEnteteAbs,
      },
      () => {
        this.setState((prevState) => ({
          modalModif: !prevState.modalModif,
        }));
      }
    );
  };

  updDateDebutSeance = (d) => {
    this.setState({
      departSeance: d,
    });
  };

  updDateFinSeance = (d) => {
    this.setState({
      finSeance: d,
    });
  };

  updDateSeance = (d) => {
    this.setState({
      dateSeance: d,
    });
  };

  modification = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    var userId = userPermissions[0].idUser;
    // convert date seance
    let dateSeance = this.state.dateSeance;
    let month = "" + (dateSeance.getMonth() + 1);
    let day = "" + dateSeance.getDate();
    let year = dateSeance.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var convertDateSeance = [year, month, day].join("-");
    //**** Date debut *******/
    // convert heure debut pour hd
    var hd = this.state.departSeance.toTimeString();
    hd = hd.split(" ")[0];
    // convert heure debut => ymd hms
    let date_obOne = this.state.departSeance;
    let dateOne = ("0" + date_obOne.getDate()).slice(-2);
    let monthOne = ("0" + (date_obOne.getMonth() + 1)).slice(-2);
    let yearOne = date_obOne.getFullYear();
    let hoursOne = date_obOne.getHours();
    let minutesOne = date_obOne.getMinutes();
    let secondsOne = date_obOne.getSeconds();
    var dateDebut =
      convertDateSeance + " " + hoursOne + ":" + minutesOne + ":" + secondsOne;
    //**** Date fin *******/
    // convert heure fin pour hf
    var hf = this.state.finSeance.toTimeString();
    hf = hf.split(" ")[0];
    // convert heure fin => ymd hms
    let date_obTwo = this.state.finSeance;
    let dateTwo = ("0" + date_obTwo.getDate()).slice(-2);
    let monthTwo = ("0" + (date_obTwo.getMonth() + 1)).slice(-2);
    let yearTwo = date_obTwo.getFullYear();
    let hoursTwo = date_obTwo.getHours();
    let minutesTwo = date_obTwo.getMinutes();
    let secondsTwo = date_obTwo.getSeconds();
    var dateFin =
      convertDateSeance + " " + hoursTwo + ":" + minutesTwo + ":" + secondsTwo;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/UpdPresenceEnseignant.php",
      type: "POST",
      data: {
        idEnteteAbs: this.state.idEnteteAbs,
        departSeance: hd,
        dateDebut: dateDebut,
        finSeance: hf,
        dateFin: dateFin,
        dateSeance: convertDateSeance,
        userId: userId,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          (prevState) => ({
            modalModif: !prevState.modalModif,
            modal: !prevState.modal,
          }),
          () => {
            jQuery.ajax({
              url:
                process.env.REACT_APP_API_URL +
                "Back_mediatraining/PresenceEnseignants.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                  pending: false,
                });
              },
            });
          }
        );
      },
    });
  };

  toggleModif = () => {
    this.setState((prevState) => ({
      modalModif: !prevState.modalModif,
    }));
  };

  supp = (el) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    var idEnteteAbs = el.idEnteteAbs;
    console.log(idEnteteAbs);
    var userId = userPermissions[0].idUser;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/DeletePresenceProf.php",
      type: "POST",
      data: {
        idEnteteAbs: idEnteteAbs,
        userId: userId,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState((prevState) => ({
          modal: !prevState.modal,
        }));
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/PresenceEnseignants.php",
          type: "POST",
          data: {
            query: "",
            limit: this.state.nbrEltperPage,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      },
    });
  };

  imprimer = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEnseign != "") {
      var idEns = this.state.selectedEnseign.value;
    } else {
      var idEns = "";
    }
    if (this.state.selectedMois != "") {
      var mois = this.state.selectedMois.value;
    } else {
      var mois = "";
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/ImprPresenceEns.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        idEns: idEns,
        mois: mois,
      },
      dataType: "json",
      success: function (code_html, statut) {
        var server_path = code_html.server_path;
        var file_path = code_html.file_path;
        var path = server_path + file_path;
        window.open(path, "_blank");
      },
    });
  };

  imprDetail = (row) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    
       var nomEnseign= this.state.nomEnseign;
       var moisPresence= this.state.mois;
       var anneeScolaire= anneeScolaire;
       var idEns= this.state.idEnseignant;
       var idMois= this.state.idMois;
       var nb=this.state.nbrHeureParMois
    
        var params = nomEnseign + '-' + moisPresence + '-' + idEns + '-' + idMois + '-' + anneeScolaire + '-' + nb
       
      
           window.open("http://artsmetiers.mtd-app.com/ImpPresenceDetail?params=" + params)
        // window.open("http://localhost:3000/ImpPresenceDetail?params=" + params)
    
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Présence enseignants</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Enseignant</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => this.searchEnseignant(e)}
                        options={this.state.enseignant}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mois</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => this.searchMois(e)}
                        options={this.state.listMois}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.afficherTout}
                    >
                      Afficher tout
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.imprimer}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Imprimer
                    </button>
                  </div>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                />
              </div>
            </div>
          </div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            style={{ maxWidth: "700px", width: "100%" }}
            fade={false}
          >
            <ModalHeader toggle={this.toggle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <i
                    style={{ color: "#87B87F", paddingRight: "5px" }}
                    className="fas fa-money-check-alt"
                  ></i>
                </div>
                <div style={{ fontWeight: "bold" }}>
                  <p>
                    {"Detail séance de l'enseignant " + this.state.nomEnseign}
                  </p>{" "}
                  {this.state.monthNum >= 9 ? (
                    <p>
                      {"Du mois de " +
                        this.state.mois +
                        " " +
                        this.state.anneeScolaire}
                    </p>
                  ) : (
                    <p>
                      {"Du mois de " +
                        this.state.mois +
                        " " +
                        (parseInt(this.state.anneeScolaire) + 1)}
                    </p>
                  )}
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.imprDetail}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <table className="table table-bordered">
                <thead>
                  <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                      Numéro
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Date
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Matiére
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Groupe
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Type
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Debut
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Fin
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Modif
                    </th>
                    <th style={{ textAlign: "center" }} scope="col">
                      Suppr
                    </th>
                  </tr>
                </thead>

                    
                
                {this.state.detailEnseign.map((el, index) => (
                
                  <tbody>
                    
                          {index > 0  ?( 
                        el.date == this.state.detailEnseign[index -1].date && el.dateDebut==this.state.detailEnseign[index-1].dateDebut  ?(
                    <tr >
                     <td className="col-md-1" style={{ textAlign: 'center' }}><p> {this.state.tab[index]}</p></td>
                      <td className="col-md-3" style={{ textAlign: "center" }}>
                        <p>{this.state.date=el.date}</p>
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <p>{el.nomMatiere}</p>
                      </td>
                      <td className="col-md-2" style={{ textAlign: "center" }}>
                        {el.nomGroupe.map((ele) => (
                          <p>{ele}</p>
                        ))}
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <p>{el.typeEnseign}</p>
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <p>{el.heureDebut}</p>
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <p>{el.heureFin}</p>
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <button
                          onClick={() => this.modif(el)}
                          className="buttonModifier"
                        >
                          <i
                            style={{ color: "white" }}
                            className="fas fa-pencil-alt"
                          ></i>
                        </button>
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <button
                          onClick={() => this.supp(el)}
                          className="buttonSupprimer"
                        >
                          <i
                            style={{ color: "white" }}
                            className="fas fa-trash-alt"
                          ></i>
                        </button>
                      </td>
                    </tr>
                        ):(
                    <tr style={{backgroundColor:"#D5DCDF"}}>
                     <td className="col-md-1" style={{ textAlign: 'center' }}><p>{this.state.tab[index]}</p></td>
                    <td className="col-md-3" style={{ textAlign: "center" }}>
                      <p>{el.date}</p>
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center" }}>
                      <p>{el.nomMatiere}</p>
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      {el.nomGroupe.map((ele) => (
                        <p>{ele}</p>
                      ))}
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center" }}>
                      <p>{el.typeEnseign}</p>
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center" }}>
                      <p>{el.heureDebut}</p>
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center" }}>
                      <p>{el.heureFin}</p>
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center" }}>
                      <button
                        onClick={() => this.modif(el)}
                        className="buttonModifier"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fas fa-pencil-alt"
                        ></i>
                      </button>
                    </td>
                    <td className="col-md-1" style={{ textAlign: "center" }}>
                      <button
                        onClick={() => this.supp(el)}
                        className="buttonSupprimer"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fas fa-trash-alt"
                        ></i>
                      </button>
                    </td>
                    </tr>
                        )):(   <tr style={{backgroundColor:"#D5DCDF"}}>
                                 <td className="col-md-1" style={{ textAlign: 'center' }}><p>{this.state.tab[index]}</p></td>
                                             <td className="col-md-3" style={{ textAlign: "center" }}>
                                               <p>{el.date}</p>
                                             </td>
                                             <td className="col-md-1" style={{ textAlign: "center" }}>
                                               <p>{el.nomMatiere}</p>
                                             </td>
                                             <td className="col-md-2" style={{ textAlign: "center" }}>
                                               {el.nomGroupe.map((ele) => (
                                                 <p>{ele}</p>
                                               ))}
                                             </td>
                                             <td className="col-md-1" style={{ textAlign: "center" }}>
                                               <p>{el.typeEnseign}</p>
                                             </td>
                                             <td className="col-md-1" style={{ textAlign: "center" }}>
                                               <p>{el.heureDebut}</p>
                                             </td>
                                             <td className="col-md-1" style={{ textAlign: "center" }}>
                                               <p>{el.heureFin}</p>
                                             </td>
                                             <td className="col-md-1" style={{ textAlign: "center" }}>
                                               <button
                                                 onClick={() => this.modif(el)}
                                                 className="buttonModifier"
                                               >
                                                 <i
                                                   style={{ color: "white" }}
                                                   className="fas fa-pencil-alt"
                                                 ></i>
                                               </button>
                                             </td>
                                             <td className="col-md-1" style={{ textAlign: "center" }}>
                                               <button
                                                 onClick={() => this.supp(el)}
                                                 className="buttonSupprimer"
                                               >
                                                 <i
                                                   style={{ color: "white" }}
                                                   className="fas fa-trash-alt"
                                                 ></i>
                                               </button>
                                             </td>
                                             </tr>)}
                                             
                  </tbody>
                ))}
              </table>
              <div style={{ textAlign: "center" }}>
                {this.state.nbrPratique > 0 ? (
                  <p style={{ color: "black" }}>
                    {"Nombre de séance pratique : " + this.state.nbrPratique}
                  </p>
                ) : null}
                {this.state.nbrTheorique > 0 ? (
                  <p style={{ color: "black" }}>
                    {"Nombre de séance théorique : " + this.state.nbrTheorique}
                  </p>
                ) : null}
                 {this.state.nbrEg > 0 ? (
                  <p style={{ color: "black" }}>
                    {"Nombre de séance EG : " + this.state.nbrEg}
                  </p>
                ) : null}
                 {this.state.nbrCycle > 0 ? (
                  <p style={{ color: "black" }}>
                    {"Nombre de séance Cycles : " + this.state.nbrCycle}
                  </p>
                ) : null}

                 {this.state.nbrHeureParMois > 0 ? (
                  <p style={{ color: "red" ,fontSize: '24px'}}>
                    {"Nombre d'heure : " + this.state.nbrHeureParMois}
                  </p>
                ) : null}
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.toggle()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                      width: "40px ",
                    }}
                  >
                    <i
                      style={{ color: "white" }}
                      className="fas fa-sign-out-alt"
                    ></i>
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.modalModif}
            toggle={this.toggleModif}
            className={this.props.className}
            style={{ maxWidth: "700px", width: "100%" }}
            fade={false}
          >
            <ModalHeader toggle={this.toggleModif}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <i
                    style={{ color: "#337AB7", paddingRight: "5px" }}
                    className="fas fa-pencil-alt"
                  ></i>
                </div>
                <div style={{ fontWeight: "bold" }}>Modification</div>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Enseignant</label>
                    <p>{this.state.ligneModif.nomComplet}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Mois</label>
                    <p>{this.state.ligneModif.mois}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Matiére</label>
                    <p>{this.state.ligneModif.nomMatiere}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Groupe</label>
                    <p>{this.state.groupeEnseign}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Depart séance</label>
                    <DatePicker
                      class="form-control ddate"
                      style={{ border: "none" }}
                      selected={this.state.departSeance}
                      onChange={this.updDateDebutSeance}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="H:mm aa"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Fin séance</label>
                    <DatePicker
                      class="form-control ddate"
                      style={{ border: "none" }}
                      selected={this.state.finSeance}
                      onChange={this.updDateFinSeance}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="H:mm aa"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date séance</label>
                    <DatePicker
                      class="form-control ddate"
                      style={{ border: "none" }}
                      selected={this.state.dateSeance}
                      onChange={this.updDateSeance}
                      dateFormat="dd/MM/yyy"
                    />
                  </div>
                </div>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <button
                    onClick={() => this.modification()}
                    style={{
                      backgroundColor: "#337AB7",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white", paddingRight: "5px" }}
                      className="fas fa-pencil-alt"
                    ></i>
                    Modifier
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.toggleModif()}
                    style={{
                      backgroundColor: "#D9534F",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    <i
                      style={{ color: "white", paddingRight: "5px" }}
                      className="fas fa-sign-out-alt"
                    ></i>
                    Fermer
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default PresenceEnseignant;
