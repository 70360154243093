import React, { Component } from "react";
import jQuery from "jquery";

import "./paginate.css";
import DataTable, { createTheme } from 'react-data-table-component';

class ImprApprIns extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        data: [],
        columns: [
          {
            name: "N°",
            selector: "n",
            sortable: true,
            center: true,
            style: { width: "3%" },
          },
          {
            name: "N°Ins",
            selector: "idApprenant",
            sortable: false,
            center: true,
            style: { width: "5%" },
          },
            {
              name: "CIN",
              selector: "cin",
              sortable: false,
              center: true,
              style: { width: "10%" },
            },
            {
              name: "Prénom-Nom",
              selector: "prenomNom",
              sortable: false,
              center: true,
              style: { width: "40%" },
            },
            {
              name: "Mobile",
              selector: "mobile",
              sortable: false,
              center: true,
              style: { width: "10%" },
            },
          
        
    ],
    etat:"" ,
    groupe:"",
    apprenant:"",
    prenom:"",
   nom:"",
    cin:"",
   villeResid:"",
   anneeNaiss:"",
    ageMax:"",
    ageMin:"",
    annsco:"",
    };
  }


  componentDidMount() {
    if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
      var url = window.location.href;
      var array = url.split("=");
      var params = array[1];
      var arrayInfo = params.split("/");
      var idNiveau= arrayInfo[0];
      var idgroupe = arrayInfo[1];
      var etat = arrayInfo[2];
      var prenom = arrayInfo[3];
      var nom = arrayInfo[4]
      var cin = arrayInfo[5];
      var villeResid = arrayInfo[6];
      var anneeNaiss = arrayInfo[7];
      var ageMin = arrayInfo[8];
      var ageMax = arrayInfo[9].split('&etat')[0];
	  
	  var nometat=url.split('&etat=')[1].split('&groupe=')[0];
	   nometat= nometat.replace("%20", " ");
var nomgroupe=url.split('&groupe=')[1];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      this.state.etat=etat ;
      this.state.idgroupe=idgroupe;
      this.state.idNiveau=idNiveau;
      this.state.prenom=prenom;
      this.state.nom=nom;
      this.state.cin=cin;
      this.state.villeResid=villeResid;
      this.state.anneeNaiss=anneeNaiss;
      this.state.ageMax=ageMax;
	  if(nometat!='')
	  nometat=nometat+'s';
      this.state.nometat=nometat;
	
 this.state.nomgroupe=nomgroupe;
      this.state.ageMin=ageMin;
        var idRoleUser = userPermissions[0].idRoleUser
        if (idRoleUser == 1) {
            var anneeScolaire = userPermissions[0].anneeScolaire
            this.state.annsco= anneeScolaire
            
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ImprApprIns.php',
                type: 'POST',
                data: {
                    query: '',
                    anneeScolaire: anneeScolaire,
              prenom: this.state.prenom,
              nom: this.state.nom,
              cin: this.state.cin,
              villeResid: this.state.villeResid,
              anneeNaiss: this.state.anneeNaiss,
              etat:this.state.etat,
              idgroupe:this.state.idgroupe,
              idNiveau:this.state.idNiveau,
              ageMin: this.state.ageMin,
              ageMax: this.state.ageMax,
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
        }
        else {
            localStorage.clear();
            document.location = '/'
        }
    }
    else {
        document.location = '/'
    }
}

  impr = () => {
    window.print();
  };    

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
           
                       
                      
                          <strong>Année Scolaire : </strong>
                          {this.state.annsco }
                       
          </div>
        </div>
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          Liste des apprenants {this.state.nometat}   {/*" "*/}  
        </h4>
		{this.state.nomgroupe !='' ? (
		<h4
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >du groupe {this.state.nomgroupe} </h4>
		):(<h4
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        ></h4>)
		}
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
        >
          <table style={{ border: "solid" ,width:"800px" }}>
            <thead>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  width: "10px",
                }}
              >
                N°
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  width: "10px",
                }}
              >
                N°Ins
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  width: "10px",
                }}
              >
                Carte identité
              </th>
              <th
                style={{ textAlign: "center", border: "solid", color: "black", width: "50px" }}
              >
              Apprenant
              </th>
              <th
                style={{ textAlign: "center", border: "solid", color: "black" , width: "50px" }}
              >
                Téléphone
              </th>
            </thead>
            <tbody>
             {this.state.data.map((el, index) => (
             
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {el.n}
            
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                   {el.idApprenant}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    <p>{el.cin}

                    </p>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {el.prenomNom}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  ><p style={{padding:'5px'}}>
                   {el.mobile}
                    </p>
                  </td>
                </tr>
             ))}
            </tbody>
          </table>
        </div>
       
        
        <div style={{ display: "flex", justifyContent: "center"  ,padding:"20px"}}>
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default ImprApprIns;



