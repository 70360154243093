import React, { Component, Fragment } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

class DeclarationEmpl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            annee: [],
            selectedAnnee: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectAnnee.php').then(res => {
                console.log(res)
                this.setState({
                    annee: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    addAnnee = selectedAnnee => {
        this.setState({ selectedAnnee: selectedAnnee })
    };

    impr = () => {
        if (this.state.selectedAnnee != '') {
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ImprDeclEmpl.php',
                type: 'POST',
                data: {
                    annee: this.state.selectedAnnee.label
                },
                dataType: 'json',
                success: function (code_html, statut) {
                    var server_path = code_html.server_path;
                    var file_path = code_html.file_path
                    var path = server_path + file_path;
                    window.open(path, "_blank")
                }
            });
        }
        else {
            toast.error("⛔ Veuillez selectionner l'année correspondante !", { containerId: 'A' });
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Déclaration employeur</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="row">
                                    <div class="form-group">
                                        <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                                            <label>Année scolaire</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addAnnee}
                                                options={this.state.annee}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.impr}><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Impression</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                </div>
            </div>
        );
    }
}
export default withRouter(DeclarationEmpl);