import React, { Component, Fragment } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { RadioGroup, Radio } from 'react-radio-group'

class ModifConseilAssist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idConseilAssist: '',
            date: new Date(),
            enseignant: [],
            selectedEnseignant: '',
            service: '',
            nbrHeure: 0,
            typeSurveil: [],
            selectedTypeSurveil: '',
            mnt: 0,
            disbut: true
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var idConseilAssist = this.props.location.state.idConseilAssist;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetConseilAssistById.php?id=' + idConseilAssist).then(res => {
                console.log(res)
                this.setState({
                    idConseilAssist: idConseilAssist,
                    date: new Date(res.data.date),
                    selectedEnseignant: res.data.enseignant,
                    service: res.data.service,
                    nbrHeure: res.data.nbrHeure,
                    selectedTypeSurveil: res.data.typeSurveil,
                    mnt: res.data.mnt
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectEnseignants.php').then(res => {
                console.log(res)
                this.setState({
                    enseignant: res.data
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectTypeSurveil.php').then(res => {
                console.log(res)
                this.setState({
                    typeSurveil: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    updDate = d => {
        this.setState({
            date: d
        });
    }

    updEnseignant = selectedEnseignant => {
        if (selectedEnseignant != null) {
            this.setState({ selectedEnseignant: selectedEnseignant });
        }
        else {
            this.setState({
                selectedEnseignant: ''
            });
        }
    }

    updService = (event) => {
        this.setState({
            service: event.target.value
        });
    }

    updNbrHeure = (event) => {
        this.setState({
            nbrHeure: event.target.value
        });
    }

    addTypeSurveil = selectedTypeSurveil => {
        if (selectedTypeSurveil != null) {
            this.setState({ selectedTypeSurveil: selectedTypeSurveil });
        }
        else {
            this.setState({
                selectedTypeSurveil: ''
            });
        }
    }

    updMnt = (event) => {
        this.setState({
            mnt: event.target.value
        });
    }

    modif = () => {
        if (this.state.selectedEnseignant != '' && this.state.selectedTypeSurveil != '') {
            this.setState({ disbut: false }, () => {
                var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                var anneeScolaire = userPermissions[0].anneeScolaire
                var userId = userPermissions[0].idUser
                // convert date
                let datePay = this.state.date
                let month = '' + (datePay.getMonth() + 1);
                let day = '' + datePay.getDate();
                let year = datePay.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var convertDate = [year, month, day].join('-');
                // 
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdConseilAssist.php?id=' + this.state.idConseilAssist,
                    type: 'POST',
                    data: {
                        date: convertDate,
                        ensId: this.state.selectedEnseignant.value,
                        service: this.state.service,
                        nbrHeure: this.state.nbrHeure,
                        idTypeSurveil: this.state.selectedTypeSurveil.value,
                        mnt: this.state.mnt,
                        anneeScolaire: anneeScolaire,
                        userId: userId
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        if (code_html.Result == "OK") {
                            this.props.history.push('/ConseilAssistance');
                        }
                        else if (code_html.Result == "KO") {
                            alert("erreur,l'un de vos champs est vide!!");
                        }
                    }
                });
            })
        }
        else {
            toast.error("⛔ Veuillez saisir l'enseignant et le type de paiement !!", { containerId: 'A' });
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification conseil et assistance</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/ConseilAssistance" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <DatePicker class="form-control ddate" style={{ border: 'none' }}
                                                selected={this.state.date}
                                                onChange={this.updDate}
                                                dateFormat="dd/MM/yyy"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Enseignant</label>
                                            <Select
                                                value={this.state.selectedEnseignant}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updEnseignant}
                                                options={this.state.enseignant}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Service</label>
                                            <input type="text" className="form-control" value={this.state.service} onChange={this.updService} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nombre d'heure</label>
                                            <input type="number" className="form-control" value={this.state.nbrHeure} onChange={this.updNbrHeure} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Type paiement</label>
                                            <Select
                                                value={this.state.selectedTypeSurveil}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addTypeSurveil}
                                                options={this.state.typeSurveil}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Honoraire</label>
                                            <input type="number" className="form-control" value={this.state.mnt} onChange={this.updMnt} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', marginTop: '10px' }}>
                                    <button disabled={!this.state.disbut} style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                        <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifConseilAssist);