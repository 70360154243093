import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";
import "./paginate.css";

class ImprMoyenneAdmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockNote: false,
      releveeByGroupe: [],
      filiere: "",
      niveau: "",
      groupe: "",
      annee: "",
      anneeScolaire: "",
      nbrCan: "    ",
      anneeScolaireFin: "",
      date: new Date(),

    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href
      var arrayUrl = url.split("=");
      var params = arrayUrl[1]
      var arrayParams = params.split("-");
 
      var groupeId=arrayParams[0];
      var mois = arrayParams[1];
      var anneimp = arrayParams[2];

      if (mois == 1) {
        var nomMois = "Janvier"
    }
    else if (mois == 2) {
        var nomMois = "Février"
    }
    else if (mois == 3) {
        var nomMois = "Mars"
    }
    else if (mois == 4) {
        var nomMois = "Avril"
    }
    else if (mois == 5) {
        var nomMois = "Mai"
    }
    else if (mois == 6) {
        var nomMois = "Juin"
    }
    else if (mois == 7) {
        var nomMois = "Juillet"
    }
    else if (mois == 8) {
        var nomMois = "Aout"
    }
    else if (mois == 9) {
        var nomMois = "Septembre"
    }
    else if (mois == 10) {
        var nomMois = "Octobre"
    }
    else if (mois == 11) {
        var nomMois = "Novembre"
    }
    else if (mois == 12) {
        var nomMois = "Decembre"
    }
    this.state.moisimp=nomMois
    this.state.anneimp =anneimp 
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprRattrapage.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          groupeId: groupeId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState(
            {
              releveeByGroupe: code_html,
            },
            () => {
              var count = 0;
              for (var i = 0; i < this.state.releveeByGroupe.length; i++) {
                var moyenne = this.state.releveeByGroupe[i].moyenne;
                if (moyenne >= 10) {
                  count++;
                }
              }
              this.setState({
                count: count,
              });
            }
          );
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoGroupe.php",
        type: "POST",
        data: {
          groupeId: groupeId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState(
            {
              filiere: code_html.nomFiliere,
              niveau: code_html.niveau,
              groupe: code_html.groupe,
              annee: code_html.annee,
              anneeScolaire: code_html.anneeScolaire,
            },
            () => {
              var anneeS = this.state.anneeScolaire;
              var arrayUrl = anneeS.split("/");
              var anneeFin = arrayUrl[1];
              var date = this.state.date;
              var year = date.getFullYear();
              this.setState({
                anneeScolaireFin: year,
              });
            }
          );
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div style={{ zoom: "90%" }} className="white-box">
        <table
          border={0}
          align="center"
          cellSpacing={1}
          cellPadding={4}
          width={900}
        >
          <tbody>
            <tr>
              <td width={546} height={54}>
                <font
                  style={{ fontSize: "14px" }}
                  face="Verdana, Arial, Helvetica, sans-serif"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b>
                    <u>Centre : </u>
                  </b>
                  &nbsp;Arts métiers
                </font>
              </td>
              <td width={535} align height={54}>
                <b>
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <h3> Session de rattrapage CAP &nbsp;</h3>
                  </font>
                </b>
              </td>
              <td width={535}>
                <b>
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <u>Annee Scolaire : </u>
                    </b>
                    &nbsp;{this.state.anneeScolaire}
                  </font>
                </b>
              </td>
            </tr>
            <tr>
              <td width={546} height={54}>
                <font
                  style={{ fontSize: "14px" }}
                  face="Verdana, Arial, Helvetica, sans-serif"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b>
                    <u>Session : </u>
                  </b>
                  <span> {this.state.moisimp}</span> <span>{this.state.anneimp }</span>
                </font>
              </td>
              <td width={535}>
                <b>
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <h3 style={{ textAlign: "center" }}>
                      {" "}
                      Examen fin de formation &nbsp;
                    </h3>
                  </font>
                </b>
              </td>
              <td height={37}>
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <font
                  style={{ fontSize: "14px" }}
                  face="Verdana, Arial, Helvetica, sans-serif"
                >
                  <b>
                    <u>Niveau:</u>
                  </b>
                  &nbsp;{this.state.niveau}
                </font>{" "}
              </td>
            </tr>
            <tr>
              <td height={42}>
                {" "}
                <font
                  style={{ fontSize: "14px" }}
                  face="Verdana, Arial, Helvetica, sans-serif"
                >
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b>
                    <u>Spécialité:</u>
                  </b>
                  &nbsp;{this.state.filiere}
                </font>
              </td>
              <td height={42}>
                {" "}
                <font
                  style={{ fontSize: "14px" }}
                  face="Verdana, Arial, Helvetica, sans-serif"
                >
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b>
                    <u> Nombre de candidats:</u>
                  </b>
                  &nbsp;{this.state.releveeByGroupe.length}
                </font>
              </td>
              <td height={42}>
                {" "}
                <font
                  style={{ fontSize: "14px" }}
                  face="Verdana, Arial, Helvetica, sans-serif"
                >
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b>
                    <u> Groupe:</u>
                  </b>
                  &nbsp;{this.state.groupe}
                </font>
              </td>
            </tr>
          </tbody>
        </table>
        <Table>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                N°
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                Apprenant
              </th>
              <th
                colspan="4"
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                {" "}
                Moy Trimestre 1{" "}
              </th>
              <th
                colspan="4"
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                {" "}
                Moy Trimestre 2{" "}
              </th>
              <th
                colspan="4"
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                {" "}
                Moy Trimestre 3{" "}
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                {" "}
                Moy Ann{" "}
              </th>
              <th
                colspan="4"
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                {" "}
                Moy EFF{" "}
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                {" "}
                Stage{" "}
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                {" "}
                Moy.Gén{" "}
              </th>
              {/* <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                {" "}
                Rang{" "}
              </th> */}
              <th
                style={{
                  textAlign: "center",
                  border: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#C0C0C0",
                  color: "black",
                }}
              >
                {" "}
                Décision{" "}
              </th>
            </tr>
          </thead>
          <tbody style={{ border: "solid", borderTop: "none" }}>
            <tr>
              <td
                style={{ textAlign: "center", border: "solid", color: "black" }}
              ></td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Module{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                EG{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                ET{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                EP{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  backgroundColor: "#C0C0C0",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Moy T1{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                EG{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                ET{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                EP{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  backgroundColor: "#C0C0C0",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Moy T2{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                EG{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                ET{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                EP{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  backgroundColor: "#C0C0C0",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Moy T3{" "}
              </td>
              <td
                style={{ textAlign: "center", border: "solid", color: "black" }}
              ></td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                EG{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                ET{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                EP{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Moy EFF{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Moy.Stage{" "}
              </td>
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Moy.Ge{" "}
              </td>
              {/* <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Rang{" "}
              </td> */}
              <td
                style={{
                  textAlign: "center",
                  border: "solid",
                  color: "black",
                  width: "10px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Décision{" "}
              </td>
            </tr>
            {this.state.releveeByGroupe.map((el, indexOfLigne) => (
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {indexOfLigne + 1}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {" "}
                  {el.nomEleve}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.eg1}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.et1}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.ep1}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    backgroundColor: "#C0C0C0",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.tr1}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.eg2}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.et2}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.ep2}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    backgroundColor: "#C0C0C0",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.tr2}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.eg3}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.et3}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.ep3}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    color: "black",
                    border: "solid",
                    backgroundColor: "#C0C0C0",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.tr3}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    color: "black",
                    border: "solid",
                    backgroundColor: "#C0C0C0",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.moyAnnuelle}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.EG}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.ET}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.EP}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    color: "black",
                    border: "solid",
                    backgroundColor: "#C0C0C0",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                      fontWeight: "bold",
                    }}
                  >
                    {el.MoyEFF}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.stage}
                  </p>
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {el.moyenne}
                  </p>
                </td>
                {/* <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {indexOfLigne + 1}
                </td> */}
                <td
                  style={{
                    textAlign: "center",
                    color: "black",
                    border: "solid",
                    width: "10px",
                  }}
                >
                  <input
                    style={{ border: "none", whiteSpace: "normal" }}
                    type="text"
                    defaultValue={el.decision}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ textAlign: "center" }} className="row">
           <h3>
            Après avoir pris connaissances des notes de la liste récapitulative
            ci-jointe déclarons admis sur un total de &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; candidats présents à
            l'examen les &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; dont les noms suivent par ordre de classement
          </h3> 
          <div style={{ display: "flex", justifyContent: "center" }}>
             <div>
              <h2 style={{ fontWeight: "bold" }}>Signature</h2>
              <h2 style={{ fontWeight: "bold" }}>Président Jury</h2>
            </div> 
            <div style={{ paddingLeft: "30%" }}>
              <h2 style={{ fontWeight: "bold" }}>Signature</h2>
              <h2 style={{ fontWeight: "bold" }}>Directeur Général</h2>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprMoyenneAdmission);
