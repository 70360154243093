import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./paginate.css";
import jQuery from "jquery";

class ImprRapportFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateD: "",
      dateF: "",
      data:[],
      somme:"",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var array = url.split("=");
      var params = array[1];
      var arrayDate = params.split("/");
      var dateD = arrayDate[0];
      var dateF = arrayDate[1];
      var ensId=arrayDate[2];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      this.setState({
        dateD: dateD,
        dateF: dateF,
      
      });
      
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ChequeEnsimpr.php",
          type: "POST",
          data: {
            ensId: ensId,
            dateDebut:dateD ,
            dateFin:dateF,
            limit:20,
            page: 1,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              somme:code_html.somme,
              pending: false,
            });
          },
        });
      
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "2%" }} className="row">
          <div className="form-group">
            <h4 style={{ fontWeight: "bold" }}>
              Liste des chéques : Honoraire 
            </h4>
            <h4 style={{ fontWeight: "bold" }}>
              Entre  { this.state.dateD }  et {" "}
              { this.state.dateF }
            </h4>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
        >
          <table style={{ border: "solid", marginBottom: "0", width: "90%" }}>
            <thead>
              <tr>
                <th style={{ border: "solid", color: "black" }}></th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  CIN
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    margin: "2%",
                  }}
                >
                  Enseignant
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    margin: "2%",
                  }}
                >
                  Num chéque
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    margin: "2%",
                  }}
                >
                  Echéance
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    margin: "2%",
                  }}
                >
                  Montant
                </th>
              </tr>
            </thead>
            <tbody>
            
              {this.state.data.map((el,index)=>(

              
              <tr>
                     <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    padding: "5px",
                  }}
                > {index+1}</td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    padding: "5px",
                  }}
                >
                 {el.cin}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    padding: "5px",
                  }}
                >
                   {el.nomComplet}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    padding: "5px",
                  }}
                >
                {el.numPiece}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    padding: "5px",
                  }}
                >
                   {el.echeance}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    padding: "5px",
                  }}
                >{el.montant}</td>
           
              </tr>
            
              ))}
             

              
                <tr>
                
                <td colspan="5"
                  style={{
                    textAlign: "right",
                    border: "solid",
                    color: "black",
                   
                    padding: "1%",
                  }}
                ><strong>Total Chéque Formateur</strong>
                </td>
                <td c
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    padding: "1%",
                  }}
                ><strong>{this.state.somme}</strong>
                </td>
                </tr>
                
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprRapportFor);
