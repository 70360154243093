import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModifGroupe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listFilieres: [],
            selectedFiliere: '',
            listSpecialites: [],
            selectedSpecialite: '',
            listFormations: [],
            selectedFormation: '',
            listNiveaux: [],
            selectedNiveau: '',
            formatGroupe: [],
            indexLignFormatGroupeASupprimer: '',
            idFormatGroupeASupprimer: '',
            tabIdFormatGroupeASupprimer: [],
            modal: false
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            let idNiveau = this.props.location.state.idNiveau;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetGroupeByNivId.php?id=' + idNiveau).then(res => {
                console.log(res)
                this.setState({
                    selectedFiliere: res.data[0].filiere,
                    selectedSpecialite: res.data[0].specialite,
                    selectedFormation: res.data[0].formation,
                    selectedNiveau: res.data[0].niveau,
                    formatGroupe: res.data[1]
                })
            })
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFilieres.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        listFilieres: code_html
                    })
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    additionLign = () => {
        let element = { idGroup: -1, groupe: '' }
        this.setState({
            formatGroupe: [...this.state.formatGroupe, element]
        })
    }

    updFiliere = selectedFiliere => {
        if (selectedFiliere != null) {
            this.setState({ selectedFiliere }, () => {
                var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                var anneeScolaire = userPermissions[0].anneeScolaire
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectSpecByFiliere.php',
                    type: 'POST',
                    data: {
                        filiereId: selectedFiliere.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listSpecialites: code_html
                        })
                    }
                });
            })
        }
        else {
            this.setState({
                listSpecialites: []
            })
        }
    };

    updSpecialite = selectedSpecialite => {
        if (selectedSpecialite != null) {
            this.setState({ selectedSpecialite }, () => {
                var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                var anneeScolaire = userPermissions[0].anneeScolaire
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFormBySpecialite.php',
                    type: 'POST',
                    data: {
                        specialiteId: selectedSpecialite.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listFormations: code_html
                        })
                    }
                });
            })
        }
        else {
            this.setState({
                listFormations: []
            })
        }
    };

    updFormation = selectedFormation => {
        if (selectedFormation != null) {
            this.setState({ selectedFormation }, () => {
                var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
                var anneeScolaire = userPermissions[0].anneeScolaire
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectNivByFormation.php',
                    type: 'POST',
                    data: {
                        formationId: selectedFormation.value,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        this.setState({
                            listNiveaux: code_html
                        })
                    }
                });
            })
        }
        else {
            this.setState({
                listNiveaux: []
            })
        }
    };

    updNiveau = selectedNiveau => {
        this.setState({ selectedNiveau })
    };

    updGroupe = (event, indexGroupe) => {
        let groupe = event.target.value;
        this.setState({
            formatGroupe: this.state.formatGroupe.map((el, id) => (id === indexGroupe ? Object.assign(el, { groupe: groupe }) : el))
        });
    }

    toggleGroupe = (idGroup, indexGroupe) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            idFormatGroupeASupprimer: idGroup,
            indexLignFormatGroupeASupprimer: indexGroupe
        }));
    }

    deleteGroupe = () => {
        let idFormatGroupeASupprimer = this.state.idFormatGroupeASupprimer
        this.setState({
            tabIdFormatGroupeASupprimer: [...this.state.tabIdFormatGroupeASupprimer, idFormatGroupeASupprimer]
        });
        this.state.formatGroupe.splice(this.state.indexLignFormatGroupeASupprimer, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteGroupe = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    modif = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdGroupe.php',
            type: 'POST',
            data: {
                specialiteId: this.state.selectedSpecialite.value,
                formationId: this.state.selectedFormation.value,
                niveauId: this.state.selectedNiveau.value,
                formatGroupe: this.state.formatGroupe,
                year: anneeScolaire,
                idGroupeDelete: this.state.tabIdFormatGroupeASupprimer,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Groupes');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification Groupe</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Groupes" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Filiére</label>
                                            <Select
                                                value={this.state.selectedFiliere}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updFiliere}
                                                options={this.state.ListFilieres}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Spécialité</label>
                                            <Select
                                                value={this.state.selectedSpecialite}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updSpecialite}
                                                options={this.state.listSpecialites}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Formation</label>
                                            <Select
                                                value={this.state.selectedFormation}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updFormation}
                                                options={this.state.listFormations}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Niveau</label>
                                            <Select
                                                value={this.state.selectedNiveau}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.updNiveau}
                                                options={this.state.listNiveaux}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <table style={{ marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Matiére</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                            </tr>
                                        </thead>
                                        {this.state.formatGroupe.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-10" style={{ textAlign: 'center' }}><input type="text" value={el.groupe} onChange={(e) => this.updGroupe(e, index)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleGroupe(el.idGroup, index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                        <Button style={{ borderRadius: '5px' }} onClick={this.additionLign} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                    </div>
                                </div>
                                <Modal isOpen={this.state.modal} toggle={this.nonDeleteGroupe} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteGroupe}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer ce groupe ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteGroupe()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteGroupe()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifGroupe);