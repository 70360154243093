import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import Table from "reactstrap/lib/Table";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class AjoutNoteStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listGroupes: [],
      listEleves: [],
      selectGroupe: "",
      date: new Date(),
      blockListEleve: false,
      dateAjoutNote: new Date(),
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/GetGroupe.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listGroupes: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addDate = (d) => {
    this.setState({
      date: d,
    });
  };

  addGroupe = (selectGroupe) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectGroupe != null) {
      this.setState({ selectGroupe }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/GetEleves.php",
          type: "POST",
          data: {
            groupeId: selectGroupe.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              listEleves: code_html,
              blockListEleve: true,
            });
          },
        });
      });
    } else {
      this.setState({
        selectGroupe: "",
      });
    }
  };

  changeNote = (indexOfLigne, event) => {
    if (parseFloat(event) <= 20) {
      var arrayEleve = this.state.listEleves;
      arrayEleve[indexOfLigne].note = event;
      this.setState({ listEleves: arrayEleve });
    } else if (event == "") {
      var arrayEleve = this.state.listEleves;
      arrayEleve[indexOfLigne].note = "";
      this.setState({ listEleves: arrayEleve });
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      });
    }
  };

  ajoutNoteStage = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.date != "") {
      var date = this.state.date;
      var convertDate = date.toISOString().split("T")[0];
    } else {
      var convertDate = "";
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/AjoutNoteStage.php",
      type: "POST",
      data: {
        groupeId: this.state.selectGroupe.value,
        anneeScolaire: anneeScolaire,
        dateAjoutNote: convertDate,
        listEleves: this.state.listEleves,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Menu");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Ajout notes de Stage</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroupe}
                        options={this.state.listGroupes}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        value={this.state.date}
                        selected={this.state.date}
                        onChange={this.addDate}
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                  </div>
                  {this.state.blockListEleve == true ? (
                    <div>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'center' }}>Eleve</th>
                            <th style={{ textAlign: 'center' }}>Note Saisie</th>
                            <th style={{ textAlign: 'center' }}>Note Stage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.listEleves.map((el, indexOfLigne) => (
                            <tr>
                              <td style={{ textAlign: 'left' }}> {el.nomEleve} </td>{" "}
                              <td style={{ textAlign: 'center' }}>
                                {el.stageNote.map((element) => (
                                  <p>{element.noteStage}</p>
                                ))}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <input
                                  type="text"
                                  style={{ textAlign: "center" }}
                                  onChange={(e) =>
                                    this.changeNote(
                                      indexOfLigne,
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                        <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajoutNoteStage}>Confirmer</button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
      </div>
    );
  }
}

export default withRouter(AjoutNoteStage);
