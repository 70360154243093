import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";

class MenuDonneeBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Admin
      barreNavAdmin: [
        {
          titre: "Gestion Apprenants",
          element: [
            {
              nomElement: "Avis",
              iconElement: "fas fa-info-circle",
              linkElement: "/ConsulterAvis",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Assiduité",
              iconElement: "fas fa-calendar-alt",
              linkElement: "/Absence",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Dérogation",
              iconElement: "fas fa-book",
              linkElement: "/Derogation",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Apprenants",
              iconElement: "fas fa-child",
              linkElement: "/Apprenants",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Apprenants Inscrits",
              iconElement: "fas fa-user-secret",
              linkElement: "/ListAppInscri",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Apprenants Anciens",
              iconElement: "fas fa-user-secret",
              linkElement: "/ApprAnciens",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Calendrier EFF",
              iconElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Calendrier",
                  linkSousElement: "/AjouterCalendrier",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-calendar-alt",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Ajout groupe",
                  linkSousElement: "/ListGroupe",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-check",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Consulter groupe",
                  linkSousElement: "/ConsulterListGroupe",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-friends",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Resultat scolaire",
              iconElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Affiche resultat apprenant",
                  linkSousElement: "/AffichRes",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-check",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Resultat trimestrielle",
                  linkSousElement: "/ResultatTrimestriel",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-list-alt",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Moyenne d'admission",
                  linkSousElement: "/MoyenneAdmission",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-graduation-cap",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Moyenne d'admission BTS/BTP",
                  linkSousElement: "/MoyenneAdmissionBTS",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-graduation-cap",
                  arraySousSousElement: [],
                },
              ],
            },
          ],
          iconTitre: "fas fa-graduation-cap",
          permission: 0,
        },
      ],
      // Scolarite
      barreNavScol: [
        {
          titre: "Formation initiale",
          element: [
            {
              nomElement: "Inscription",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Ajouter",
                  linkSousElement: "/Inscription",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-box-tissue",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Apprenants Inscrits",
                  linkSousElement: "/ListAppInscri",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-user-secret",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Reglement tranche",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ReglApprenant",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-eye",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Regler",
                  linkSousElement: "/ModifReglTrchApp",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Reglement frais",
              iconElement: "",
              linkElement: "",
              sousPermission: 0,
              arraySousElement: [
                {
                  nomSousElement: "Consulter",
                  linkSousElement: "/ReglApprenantFrais",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-eye",
                  arraySousSousElement: [],
                },
                {
                  nomSousElement: "Regler",
                  linkSousElement: "/ModifReglFraisApp",
                  sousSousPermission: 0,
                  iconSousElement: "fas fa-edit",
                  arraySousSousElement: [],
                },
              ],
            },
            {
              nomElement: "Reçu émis",
              iconElement: "fas fa-scroll",
              linkElement: "/RecuEmis",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Autre Frais",
              iconElement: "fas fa-money-check",
              linkElement: "/AutreFrais",
              sousPermission: 0,
              arraySousElement: [],
            },
            {
              nomElement: "Frais formation",
              linkElement: "/FraisFormation",
              sousPermission: 0,
              iconElement: "fas fa-money-check-alt",
              arraySousElement: [],
            },
          ],
          iconTitre: "fas fa-address-card",
          permission: 0,
        },
      ],
      // Role selon l'utilisateur (soit admin ou scolarite)
      roleId: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var roleId = userPermissions[0].idRoleUser;
      this.setState({
        roleId: roleId,
      });
    } else {
      document.location = "/";
    }
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              {this.state.roleId == 1 ? (
                <h4 className="page-title">Gestion apprenants</h4>
              ) : (
                <h4 className="page-title">Formation initiale</h4>
              )}
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  {this.state.roleId == 1
                    ? this.state.barreNavAdmin.map((el, index) => (
                        <div className="col-md-4">
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd" id="nestable">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          {/* <button data-action="collapse" type="button">Collapse</button><button data-action="expand" type="button" style={{display: 'none'}}>Expand</button> */}
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                <Link
                                                  style={{ color: "#2b2b2b" }}
                                                  to={elm.linkSousElement}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                </Link>
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))
                    : this.state.barreNavScol.map((el, index) => (
                        <div className="col-md-3">
                          <div
                            className="white-box"
                            style={{ border: "solid 1px", borderRadius: "5px" }}
                          >
                            <h3
                              style={{
                                border: "solid 1px",
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              <i
                                style={{
                                  paddingRight: "5px",
                                  paddingLeft: "5px",
                                }}
                                className={el.iconTitre}
                              ></i>
                              {el.titre}
                            </h3>
                            <div className="myadmin-dd dd" id="nestable">
                              <ol className="dd-list">
                                {el.element.map((ele, indice) => (
                                  <div>
                                    {ele.arraySousElement.length > 0 ? (
                                      // Noeud Parent Ayant des Fils Feuilles
                                      <Fragment>
                                        <li className="dd-item" data-id={index}>
                                          <div
                                            style={{
                                              border:
                                                "1px solid rgba(120,130,140,.13)",
                                              padding: "8px 16px",
                                              height: "auto",
                                              fontWeight: "600",
                                              color: "#2b2b2b",
                                            }}
                                          >
                                            <i
                                              style={{ paddingRight: "5px" }}
                                              className={ele.iconElement}
                                            ></i>
                                            {ele.nomElement}
                                          </div>
                                          <ol className="dd-list">
                                            {ele.arraySousElement.map((elm) => (
                                              <li
                                                className="dd-item"
                                                data-id={index + 1}
                                              >
                                                <Link
                                                  style={{ color: "#2b2b2b" }}
                                                  to={elm.linkSousElement}
                                                >
                                                  <div
                                                    style={{
                                                      border:
                                                        "1px solid rgba(120,130,140,.13)",
                                                      padding: "8px 16px",
                                                      height: "auto",
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        paddingRight: "5px",
                                                      }}
                                                      className={
                                                        elm.iconSousElement
                                                      }
                                                    ></i>
                                                    {elm.nomSousElement}
                                                  </div>
                                                </Link>
                                                {elm.arraySousSousElement
                                                  .length > 0 ? (
                                                  <ol className="dd-list">
                                                    {elm.arraySousSousElement.map(
                                                      (ellm) => (
                                                        <li
                                                          className="dd-item"
                                                          data-id={index + 1}
                                                        >
                                                          <Link
                                                            style={{
                                                              color: "#2b2b2b",
                                                            }}
                                                            to={
                                                              ellm.linkSousSousElement
                                                            }
                                                          >
                                                            <div
                                                              style={{
                                                                border:
                                                                  "1px solid rgba(120,130,140,.13)",
                                                                padding:
                                                                  "8px 16px",
                                                                height: "auto",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              <i
                                                                style={{
                                                                  paddingRight:
                                                                    "5px",
                                                                }}
                                                                className={
                                                                  ellm.iconSousSousElement
                                                                }
                                                              ></i>
                                                              {
                                                                ellm.nomSousSousElement
                                                              }
                                                            </div>
                                                          </Link>
                                                        </li>
                                                      )
                                                    )}
                                                  </ol>
                                                ) : null}
                                              </li>
                                            ))}
                                          </ol>
                                        </li>{" "}
                                      </Fragment>
                                    ) : (
                                      // Noeud Feuille (Terminaison)
                                      <Link
                                        style={{ color: "#2b2b2b" }}
                                        to={ele.linkElement}
                                      >
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgba(120,130,140,.13)",
                                            padding: "8px 16px",
                                            height: "auto",
                                            fontWeight: "600",
                                          }}
                                        >
                                          <i
                                            style={{ paddingRight: "5px" }}
                                            className={ele.iconElement}
                                          ></i>
                                          {ele.nomElement}
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                ))}
                              </ol>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuDonneeBase;
