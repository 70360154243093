import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class ImprListVierge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockNote: false,
      listEleves: [],
      listNatureNote: [],
      listEleves: [],
      filiere: "",
      niveau: "",
      groupe: "",
      annee: "",
      anneeScolaire: "",
      nomMatiere: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var arrayGroupe = url.split("=");
      var params = arrayGroupe[1];
      var arrayMatiere = params.split("/");
      var groupeId = arrayMatiere[0];
      var matiereId = arrayMatiere[1];
      var niveauId = arrayMatiere[2];
      var idSousMat = arrayMatiere[3];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SelectedEleveByIdGroupe.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          niveauId: niveauId,
          groupeId: groupeId,
          matiereId: matiereId,
          sousMatId: idSousMat,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listEleves: code_html[1],
            listNatureNote: code_html[0],
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoListVierge.php",
        type: "POST",
        data: {
          groupeId: groupeId,
          anneeScolaire: anneeScolaire,
          matiereId: matiereId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            filiere: code_html.nomFiliere,
            niveau: code_html.niveau,
            groupe: code_html.groupe,
            annee: code_html.annee,
            anneeScolaire: code_html.anneeScolaire,
            nomMatiere: code_html.nomMatiere,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group">
            <p> 8, rue borj Bourguiba, le Passage 1001- Tunis </p>
            <p> Tél. 71 240 204 /52 875 028</p>
          </div>
        </div>
        <div style={{ textAlign: "center" }} className="row">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              Rapport de note pour la matière {this.state.nomMatiere}{" "}
            </label>
          </div>
        </div>
        <div style={{ textAlign: "center" }} className="row">
          <div className="form-group">
            <label style={{ textDecoration: "underline", fontWeight: "bold" }}>
              {this.state.niveau} - {this.state.groupe} {this.state.filiere}{" "}
              (Groupe : {this.state.groupe})
            </label>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
        >
          <table style={{ border: "solid", marginBottom: "0", width: "100%" }}>
            <thead>
              <th
                style={{ textAlign: "center", border: "solid", color: "black" }}
              >
                {" "}
                Apprenant{" "}
              </th>
              {this.state.listNatureNote.map((el) => (
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {" "}
                  {el.natNot}{" "}
                </th>
              ))}
            </thead>
            <tbody>
              {this.state.listEleves.map((elem) => (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      width: "30%",
                      color: "black",
                    }}
                  >
                    {" "}
                    {elem.nomEleve}{" "}
                  </td>
                  {this.state.listNatureNote.map((obj) => (
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        width: "20%",
                        color: "black",
                      }}
                    ></td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprListVierge);
