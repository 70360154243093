import React, { Component } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import jQuery from 'jquery'
import './paginate.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { RadioGroup, Radio } from 'react-radio-group'
import Select from 'react-select';

class Cycles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Cycle',
                    selector: 'cycle',
                    sortable: false,
                    center: true,
                    width: '15%'
                },
                {
                    name: 'Type',
                    selector: 'typeForCycle',
                    sortable: false,
                    center: true,
                    width: '%10'
                },
                {
                    name: 'Heure',
                    selector: 'heure',
                    sortable: false,
                    center: true,
                    width: '%10'
                },
                {
                    name: 'Tarif',
                    selector: 'tarif',
                    sortable: false,
                    center: true,
                    width: '15%'
                },
                {
                    name: 'Tarif avec carte',
                    selector: 'carte',
                    sortable: false,
                    center: true,
                    width: '15%'
                },
                {
                    name: 'Session',
                    cell: row => <div onClick={() => this.dateCycle(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="far fa-calendar-alt"></i></div><div style={{ marginLeft: '5px' }}><p>Date</p></div></div>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Enseignant',
                    cell: row => <div onClick={() => this.infoEnseign(row)} style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#40E0D0' }} className="fas fa-chalkboard-teacher"></i></div><div style={{ marginLeft: '5px' }}><p>Information</p></div></div>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '10%'
                },
                {
                    name: 'Confirm',
                    cell: row =>this.state.idRoleUser == 1 ?( <Link to={{ pathname: '/ConfirmationCycle', state: { idCycle: row.idCycle } }}><button style={{ backgroundColor: '#FF69B4', borderRadius: '5px' }}><i style={{ color: 'white' }} className="fas fa-check-circle"></i></button></Link>):null,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '5%'
                },
                {
                    name: 'Modif',
                    cell: row => this.state.idRoleUser == 2 ? (<Link to={{
                        pathname: '/ModifCycle',
                        state: { idCycle: row.idCycle }
                    }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link>):(<Link to={{
                        pathname: '/ModifHonoraireEnsCycle',
                        state: { idCycle: row.idCycle }
                    }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link>),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '5%'
                },
                {
                    name: 'Supp',
                    cell: row => <button style={{ backgroundColor: '#D9534F', borderRadius: '5px' }} onClick={() => this.toggleDelete(row.idCycle)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                    width: '5%'
                },
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 5,
            pending: true,
            permissionGestionRoles: '',
            detailCycle: [],
            infoEnseign: [],
            listChoix: ['Oui', 'Non'],
            selectedChoix: 'Non',
            modalSession: false,
            modalEnseign: false,
            modalConfirm: false,
            modalDelete: false,
            listCycle: [],
            selectedCycle: '',
            listMois: [{ label: 'Janvier', value: '01' }, { label: 'Février', value: '02' }, { label: 'Mars', value: '03' }, { label: 'Avril', value: '04' }, { label: 'Mai', value: '05' }, { label: 'Juin', value: '06' }, { label: 'Juillet', value: '07' }, { label: 'Aout', value: '08' }, { label: 'Septembre', value: '09' }, { label: 'Octobre', value: '10' }, { label: 'November', value: '11' }, { label: 'Decembre', value: '12' }],
            selectedMois: '',
            idCycleDelete: '',
            idRoleUser: ''
        }
        this.newElement = this.newElement.bind(this);
        this.filterSearch = this.filterSearch.bind(this);
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            var idRoleUser = userPermissions[0].idRoleUser
            this.setState({
                idRoleUser: idRoleUser
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Cycles.php',
                type: 'POST',
                data: {
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1,
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectCycle.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        listCycle: code_html
                    })
                }
            })
        }
        else {
            document.location = '/'
        }
    }

    newElement(page, totalRows) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedCycle == "") {
            var idCycle = ""
        }
        else {
            var idCycle = this.state.selectedCycle.value
        }
        if (this.state.selectedMois == "") {
            var numMois = ""
        }
        if (idCycle == '' && numMois == "") {
            this.setState({
                pending: true
            },
                function () {
                    console.log(page)
                    console.log(totalRows)
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Cycles.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }.bind(this)
            )
        }
        else {
            this.setState({
                pending: true
            },
                function () {
                    console.log(page)
                    console.log(totalRows)
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/CyclesSearch.php',
                        type: 'POST',
                        data: {
                            idCycle: idCycle,
                            numMois: numMois,
                            limit: this.state.nbrEltperPage,
                            page: page,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }.bind(this)
            )
        }
    }

    perPage(currentRowsPerPage, currentPage) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        if (this.state.selectedCycle == "") {
            var idCycle = ""
        }
        else {
            var idCycle = this.state.selectedCycle.value
        }
        if (this.state.selectedMois == "") {
            var numMois = ""
        }
        if (idCycle == '' && numMois == "") {
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , function () {
                    console.log(currentRowsPerPage)
                    console.log(currentPage)
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Cycles.php',
                        type: 'POST',
                        data: {
                            query: this.state.filterElement,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
        else {
            this.setState(
                {
                    nbrEltperPage: currentRowsPerPage,
                    pending: true
                }
                , function () {
                    console.log(currentRowsPerPage)
                    console.log(currentPage)
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/CyclesSearch.php',
                        type: 'POST',
                        data: {
                            idCycle: idCycle,
                            numMois: numMois,
                            limit: currentRowsPerPage,
                            page: currentPage,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data,
                                pending: false
                            });
                        }
                    });
                }
            )
        }
    }

    filterSearch(event) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            function () {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Cycles.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    dateCycle = (row) => {
        var x = row.detailCycle
        this.setState({
            detailCycle: x,
        }, () => {
            console.log(this.state.detailCycle)
            this.setState(prevState => ({
                modalSession: !prevState.modalSession,
            }));
        });
    }

    infoEnseign = (row) => {
        var x = row.detailCycle
        var tab = []
        for (var i = 0; i < x.length; i++) {
            tab.push(x[i].infoEnseignant)
        }
        console.log(tab)
        this.setState({
            infoEnseign: tab
        }, () => {
            console.log(this.state.infoEnseign)
            this.setState(prevState => ({
                modalEnseign: !prevState.modalEnseign,
            }));
        });
    }

    addCycle = selectedCycle => {
        this.setState({ selectedCycle });
    };

    addMois = selectedMois => {
        this.setState({ selectedMois });
    };

    toggleSession = () => {
        this.setState(prevState => ({
            modalSession: !prevState.modalSession
        }));
    }

    toggleEnseign = () => {
        this.setState(prevState => ({
            modalEnseign: !prevState.modalEnseign
        }));
    }

    toggleDelete = (idCycle) => {
        this.setState({
            idCycleDelete: idCycle,
        }, () => {
            this.setState(prevState => ({
                modalDelete: !prevState.modalDelete,
            }));
        });
    }

    toggleNonDelete = () => {
        this.setState(prevState => ({
            modalDelete: !prevState.modalDelete
        }));
    }

    recherche = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            () => {
                if (this.state.selectedCycle == "") {
                    var idCycle = ""
                }
                else {
                    var idCycle = this.state.selectedCycle.value
                }
                if (this.state.selectedMois == "") {
                    var numMois = ""
                }
                else {
                    var numMois = this.state.selectedMois.value
                }
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mediatraining/CyclesSearch.php',
                    type: 'POST',
                    data: {
                        idCycle: idCycle,
                        numMois: numMois,
                        limit: this.state.nbrEltperPage,
                        page: 1,
                        anneeScolaire: anneeScolaire
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                }
                )
            }
        )
    }

    afficherTout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        this.setState({
            selectedCycle: '',
            selectedMois: ''
        });
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Cycles.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false
                });
            }
        });
    }

    deleteCycle() {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + "Back_mediatraining/DeleteCycle.php?id=" + this.state.idCycleDelete + "&userId=" + userId,
            type: 'POST',
            dataType: 'json',
            success: (code_html, statut) => {
                this.setState({
                    modalDelete: false
                }, () => {
                    jQuery.ajax({
                        url: process.env.REACT_APP_API_URL + 'Back_mediatraining/Cycles.php',
                        type: 'POST',
                        data: {
                            query: '',
                            limit: this.state.nbrEltperPage,
                            page: 1,
                            anneeScolaire: anneeScolaire
                        },
                        dataType: 'json',
                        success: (code_html, statut) => {
                            console.log(code_html)
                            this.setState({
                                nombreTotalElement: code_html.total,
                                data: code_html.data
                            });
                        }
                    });
                })
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Cycles</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/AjoutCycle" > <button style={{ background: '#3A3F51', border: '1px solid #3A3F51', borderRadius: '5px' }} type="button" className="btn btn-info"> <i className="fas fa-plus"></i>Cycle</button></Link>
                                </div>
                                <div className="row">
                                    <h4 style={{ paddingLeft: '10px' }}>Critères de recherche</h4>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Cycle</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addCycle}
                                                options={this.state.listCycle}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mois</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addMois}
                                                options={this.state.listMois}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.recherche}>Rechercher</button>
                                    </div>
                                    <div>
                                        <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.afficherTout}>Afficher tout</button>
                                    </div>
                                </div>
                                <div style={{ padding: '10px' }} className="mb-0 form-group">
                                    <div className="input-group-alternative input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                            <input onChange={(currentRowsPerPage, currentPage) => this.filterSearch(currentRowsPerPage, currentPage)} placeholder="Search" type="text" className="search" />
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.perPage(currentRowsPerPage, currentPage)}
                                    paginationResetDefaultPage={this.state.resetFirstPage}
                                    paginationComponentOptions={{ rowsPerPageText: 'Elements par page:', rangeSeparatorText: 'de', noRowsPerPage: false }}
                                    progressPending={this.state.pending}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Modal isOpen={this.state.modalDelete} toggle={this.toggleNonDelete} className={this.props.className} fade={false}>
                    <ModalHeader toggle={this.toggleNonDelete}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                    <ModalBody>
                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <p>Êtes-Vous sûr de vouloir supprimer ce cycle ?</p>
                            </div>
                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <div><button onClick={() => this.deleteCycle()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                <div><button onClick={() => this.toggleNonDelete()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modalSession} toggle={this.toggleSession} className={this.props.className} fade={false}>
                    <ModalHeader toggle={this.toggleSession}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#87B87F' }} className="far fa-calendar-alt"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Date Cycle</p></div></div></ModalHeader>
                    <ModalBody>
                        <div>
                            <table style={{ marginTop: '10px' }} className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center' }} scope="col">Session</th>
                                        <th style={{ textAlign: 'center' }} scope="col">Horraire</th>
                                        <th style={{ textAlign: 'center' }} scope="col">Date debut</th>
                                        <th style={{ textAlign: 'center' }} scope="col">Date fin</th>
                                    </tr>
                                </thead>
                                {this.state.detailCycle.map(el =>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'center' }}>{el.mois}</td>
                                            <td style={{ textAlign: 'center' }}>{el.horaire}</td>
                                            <td style={{ textAlign: 'center' }}>{el.dateD}</td>
                                            <td style={{ textAlign: 'center' }}>{el.dateF}</td>
                                        </tr>
                                    </tbody>)}
                            </table>
                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <div><button onClick={() => this.toggleSession()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modalEnseign} toggle={this.toggleEnseign} className={this.props.className} fade={false}>
                    <ModalHeader toggle={this.toggleEnseign}><div style={{ display: 'flex', justifyContent: 'space-between' }}><div><i style={{ color: '#40E0D0' }} className="fas fa-chalkboard-teacher"></i></div><div style={{ marginLeft: '5px', fontWeight: 'bold' }}><p>Information enseignant</p></div></div></ModalHeader>
                    <ModalBody>
                        <div>
                            <table style={{ marginTop: '10px' }} className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center' }} scope="col">Session</th>
                                        <th style={{ textAlign: 'center' }} scope="col">Enseignant</th>
                                        <th style={{ textAlign: 'center' }} scope="col">Nombre d'heure</th>
                                        {this.state.idRoleUser == 1 ? (<th style={{ textAlign: 'center' }} scope="col">Honoraire</th>) : (null)}
                                    </tr>
                                </thead>
                                {this.state.infoEnseign.map(x =>
                                    <tbody>
                                        {x.map(el =>
                                            <tr>
                                                <td style={{ textAlign: 'center' }}>{el.session}</td>
                                                <td style={{ textAlign: 'center' }}>{el.selectedEnseignent}</td>
                                                <td style={{ textAlign: 'center' }}>{el.nbrHeureParEnseign}</td>
                                                {this.state.idRoleUser == 1 ? (<td style={{ textAlign: 'center' }}>{el.honoraireParEnseign}</td>) : (null)}
                                            </tr>
                                        )}
                                    </tbody>)}
                            </table>
                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                <div><button onClick={() => this.toggleEnseign()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white', width: '40px ' }}><i style={{ color: 'white' }} className="fas fa-sign-out-alt"></i></button></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default Cycles;




