import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModifMatSousMat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matId: '',
            matSousMat: [],
            selectedMat: '',
            indexSousMatASupp: '',
            idSousMatASupp: '',
            arraySousMatDelete: []
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var idMatiere = this.props.location.state.idMatiere;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetMatSousMat.php?id=' + idMatiere).then(res => {
                console.log(res)
                this.setState({
                    matId: idMatiere,
                    selectedMat: res.data[0],
                    matSousMat: res.data[1]
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    additionLignSousMat = () => {
        let element = { idSousMat: -1, sousMat: '' }
        this.setState({
            matSousMat: [...this.state.matSousMat, element]
        })
    }

    updSousMat = (event, indexSousMat) => {
        var sousMat = event.target.value;
        this.setState({
            matSousMat: this.state.matSousMat.map((el, id) => (id === indexSousMat ? Object.assign(el, { sousMat: sousMat }) : el))
        });
    }

    toggleSousMat = (idSousMat, indexSousMat) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            idSousMatASupp: idSousMat,
            indexSousMatASupp: indexSousMat
        }));
    }

    deleteSousMat = () => {
        let idSousMatASupp = this.state.idSousMatASupp
        this.setState({
            arraySousMatDelete: [...this.state.arraySousMatDelete, idSousMatASupp]
        });
        this.state.matSousMat.splice(this.state.indexSousMatASupp, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteSousMat = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    modif = () => {
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdMatSousMat.php',
            type: 'POST',
            data: {
                matId: this.state.matId,
                matSousMat: this.state.matSousMat,
                arraySousMatDelete: this.state.arraySousMatDelete
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/MatSousMat');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification sous matiére par matiére</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/MatSousMat"> <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Matiere</label>
                                            <Select
                                                value={this.state.selectedMat}
                                                isClearable={true}
                                                isSearchable={true}
                                                isDisabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <table style={{ width: '100%', marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Sous-matiére</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                            </tr>
                                        </thead>
                                        {this.state.matSousMat.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-10"> <input style={{ width: '100%' }} type="text" value={el.sousMat} onChange={(e) => this.updSousMat(e, index)} /> </td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleSousMat(el.idSousMat, index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                        <Button style={{ borderRadius: '5px' }} onClick={this.additionLignSousMat} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                    </div>
                                </div>
                                <Modal isOpen={this.state.modal} toggle={this.nonDeleteMatFor} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteMatFor}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer cette sous-matiére ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteSousMat()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteSousMat()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifMatSousMat);