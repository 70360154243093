import React, { Component } from "react";
import jQuery from "jquery";
import "./paginate.css";

class ImprAttestationPres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today: "",
      nomComplet : "" ,
      cin: "",
      adresse: "",
      naissance: "",
      type: "",
      etat: "",
      spec: "",
      filiere: "",
      nationalite : '' ,
      anneeScolaire: "",
      anneeScolaireAfter: "",
      diplome : '' ,
      formation : '', 
      niveau : '' ,

    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var eleveId = arrayUrl[1];
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoAttesPres.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          eleveId: eleveId
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            today: code_html.today,
            nomComplet : code_html.nomComplet , 
            cin: code_html.cin,
            adresse: code_html.adresse,
            naissance: code_html.naissance,
            type: code_html.type,
            etat: code_html.etat,
            spec: code_html.spec,
            filiere: code_html.filiere,
            nationalite : code_html.nationalite ,
            diplome : code_html.diplome,
            formation : code_html.formation ,
            niveau : code_html.niveau , 
            anneeScolaire: anneeScolaire,
            anneeScolaireAfter: parseInt(anneeScolaire) + 1,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  impr = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div>
          <p  style={{ marginBottom : "5px"  , fontWeight: "bold" , fontSize : "17px" }} > Arts & Métiers</p>  
          <p style={{  fontSize : "17px" }}    >   N° d'enregistrement : 111-159-01  </p>
        </div>
        <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10%",
            }}
          >
            <p style={{ fontWeight: "bold" }}>Tunis le :</p>
            <p style={{ paddingLeft: "5px" }}>{this.state.today}</p>
          </div>
        <div style={{ margin: "0px" }} className="row">
          <h4
            style={{
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              fontSize: "22px",
              marginTop: "3%",
              textDecoration :"underline",
            }}
          >
          Attestation d'inscription
          </h4>
         
          <p
            style={{
              fontSize: "17px",
              marginTop: "10%",
              textAlign: "left",
            }}
          >
            Je soussigné , Taoufik CHAIRI , Directeur Général du centre de formation
           <span style={{ fontWeight: "bold" }}   >  Arts et Métiers</span> sis au 8 borj Bourguiba , 1001 Tunis , atteste par la présente que l'apprenant(e) :   
          </p>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                textAlign: "left",
                fontSize: "17px",
              
              }}
            >
              <p style={{ fontWeight: "bold" }}>Nom et Prénom :</p>
              <p style={{ paddingLeft: "5px" }}>{this.state.nomComplet}</p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                textAlign: "left",
                fontSize: "17px",
                
              }}
            >
              <p style={{ fontWeight: "bold" }}> Date de naissance :</p>
              <p style={{ paddingLeft: "5px" }}>
                {this.state.naissance} / {this.state.adresse}
              </p>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                textAlign: "left",
                fontSize: "17px",
            
              }}
            >
              {this.state.type == 0 ? (
                <p style={{ fontWeight: "bold" }}> N° CIN :</p>
              ) : (
                <p style={{ fontWeight: "bold" }}> N° de C.I.N ou Passeport :</p>
              )}
              <p style={{ paddingLeft: "5px" }}>{this.state.cin}</p>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                textAlign: "left",
                fontSize: "17px",
                
              }}
            >
              <p style={{ fontWeight: "bold" }}> Nationalité :</p>
              <p style={{ paddingLeft: "5px" }}> {this.state.nationalite}  </p>
            </div>
          </div>
          <p
            style={{
              fontSize: "17px",
              textAlign: "left",
            }}
          >
            Est inscrit(e) à notre établissement pendant l'année de formation  {this.state.anneeScolaire} / {this.state.anneeScolaireAfter} :
          </p>
          <div   style={{ display: "flex" }}      >
          <div
              style={{
                display: "flex",
                textAlign: "left",
                fontSize: "17px",
              
              }}
            >
              <p style={{ fontWeight: "bold" }}> Session  :</p>
              <p style={{ paddingLeft: "5px" }}> Juillet {this.state.anneeScolaire} </p>
            </div>
          </div>
       
          <div   style={{ display: "flex" }}      >
          <div
              style={{
                display: "flex",
                textAlign: "left",
                fontSize: "17px",
              
              }}
            >
              <p style={{ fontWeight: "bold" }}> Spécialité  :</p>
              <p style={{ paddingLeft: "5px" }}> {this.state.filiere} </p>
            </div>
          </div>
       
       



<div style={{ display: "block" }}>
            <div style={{ fontSize: "17px", }}
            >
              <p style={{ fontWeight: "bold" }} > Type :</p>  

              <label  >
            homologué
              </label>
              { this.state.formation == 'BTP homologue' || 'formation homologué' || 'BTP Homologué' ? (

<input
type="checkbox" checked={true}  style={{  width: "10%"
}}
/>)
:(<input
  type="checkbox" checked={false} style={{ width: "10%"
  }}
  /> )

}  
   
   
   <label  >
     non homologué
              </label>
              { this.state.formation == 'BTP homologue' || 'formation homologué' || 'BTP Homologué' ? (

<input
type="checkbox" checked={false}  style={{  width: "10%"
}}
/>)
:(<input
  type="checkbox" checked={true} style={{ width: "10%"
  }}
  /> )

}   
      </div>
</div>


<br></br>

<div style={{ display: "block"   }}>
            <div style={{ fontSize: "17px", }} >
              <p style={{ fontWeight: "bold" }}> Diplôme :</p>
              <label     >
            CAP   
              </label>
              { this.state.diplome == 'CAP'? (

<input
type="checkbox" checked={true}  style={{  width: "10%"
}}
/>)
:(<input
  type="checkbox" checked={false} style={{ width: "10%"
  }}
  /> )

}  


<label  style={{marginLeft : "25px"}} >BTP </label>
{ this.state.diplome == 'BTP'? (

<input
type="checkbox" checked={true}  style={{  width: "10%"
}}
/>)
:(<input
  type="checkbox" checked={false} style={{ width: "10%"
  }}
  /> )

}  
   
   <label  style={{marginLeft : "35px"}} >
           BTS 
              </label>
              { this.state.diplome == 'BTS'? (

<input
type="checkbox" checked={true}  style={{  width: "10%"
}}
/>)
:(<input
  type="checkbox" checked={false} style={{ width: "10%"
  }}
  /> )

}   
             </div>
</div>


<br></br>

<div style={{ display: "block"   }}>
            <div style={{ fontSize: "17px" }}>
              <p style={{ fontWeight: "bold" }}> Niveau :</p>
  
              <label >
           1ère année  
              </label>
              
{ this.state.niveau == '1 ére année'? (

<input
type="checkbox" checked={true}  style={{  width: "10%"
}}
/>)
:(<input
  type="checkbox" checked={false} style={{ width: "10%"
  }}
  /> )

}           
 
 <label style={{marginLeft : "35px"}}>
           2ème  année 
              </label>
 
              { this.state.niveau == '2 éme année'? (

<input
type="checkbox" checked={true}  style={{  width: "10%"
}}
/>)
:(<input
  type="checkbox" checked={false} style={{ width: "10%"
  }}
  /> )

}                 </div>
</div>

          <p
            style={{
              fontSize: "17px",
              textAlign: "left",
            }}
          >
            Cette Attestation est délivrée à l’intéressée(e) pour servir et valoir ce que de droit.
          </p>
          <br></br>
          <p
            style={{
              textAlign: "right",
              fontWeight: "bold",
              marginTop: "20px",
              marginRight: "20%",
              fontSize : "17px" , 
            }}
          >
            Le directeur du centre
          </p>
          <p
            style={{
              textAlign: "right",
              fontWeight: "bold",
              marginTop: "20px",
              marginRight: "20%",
              fontSize : "17px" , 
            }}
          >
            Taoufik CHAIRI
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            id="print_me_not"
            style={{ fontSize: "25px", cursor: "pointer" }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default ImprAttestationPres;
