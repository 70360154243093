import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./paginate.css";
import jQuery from "jquery";

class ImprRapportFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateD: "",
      dateF: "",
      encaissFormat: [],
      sommeEs:0,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var array = url.split("=");
      var params = array[1];
      var arrayDate = params.split("/");
      var dateD = arrayDate[0];
      var dateF = arrayDate[1];
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      this.setState({
        dateD: dateD,
        dateF: dateF,
      });
      
              jQuery.ajax({
                url:
                  process.env.REACT_APP_API_URL +
                  "Back_mediatraining/SearchRapportJour.php",
                type: "POST",
                data: {
                  dateDebut:dateD,
                  dateFin: dateF,
                  anneeScolaire: anneeScolaire,
                },
                dataType: "json",
                success: (code_html, statut) => {
                  console.log(code_html);
                  this.setState(
                    {
                     
                      decaissFormat: code_html[1],
                      espFormat: code_html[2][0].espFormat,
                      checFormat: code_html[2][0].checFormat,
                      virFormat: code_html[2][0].virFormat,
                      totalFormat: code_html[2][1].totalResteFormat,
                      encaissCycle: code_html[3],
                      decaissCycle: code_html[4],
                      espCycle: code_html[5][0].espCycle,
                      checCycle: code_html[5][0].checCycle,
                      virCycle: code_html[5][0].virCycle,
                      totalCycle: code_html[5][1].totalResteCycle,
                    },
                    ()=>{
                      jQuery.ajax({
                        url:
                          process.env.REACT_APP_API_URL +
                          "Back_mediatraining/RapportForJour.php",
                        type: "POST",
                        data: {
                          dateDebut:dateD,
                          dateFin: dateF,
                          anneeScolaire: anneeScolaire,
                        },
                        dataType: "json",
                        success: (code_html, statut) => {
                          console.log(code_html);
                          this.setState(
                            {
                              encaissFormat: code_html.data,
                             
                            }
                          )}})
                    }
                    )
}})
          
          
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-around" ,
          }}
          className="row"
        >
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group" style={{width:"135px", textAlign: "left"}}>
          <p style={{ fontWeight: "bold" }}>

          8,rue borj
          Bourguiba,le Passage
          1001-Tunis
          Tél. 71 240 204 /52
          875 028</p>

          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "1%"}} className="row">
          <div className="form-group">
            <label style={{width:"250px",fontSize: "17px"}}>
            Rapport Recette Formation entre {this.state.dateD} et
              {this.state.dateF}
            </label>
          </div>
        </div>
        
        <div
          style={{  justifyContent: "center", marginLeft: "15%" }}
        >
          
          <table style={{ border: "1px solid ", width: "85%" }}>
          <thead>
                        <tr  style={{
                    textAlign: "center",
                    border: "1px solid black",
                    background: "#73dff5",
                    width: "20px",
                  }}>
                          
                          <th
                            style={{
                              textAlign: "center",
                              border: "solid",
                              color: "black", 
                              width: "10%",
                              fontSize: "15px",
                              
                              
                              
                            }}
                          >
                            N° ins
                          </th>
                          <th
                           style={{
                            
                            textAlign: "center",
                            border: "solid",
                            color: "black",
                            width: "10%",
                            fontSize: "15px",
                            
                            
                          }}
                          >
                            Date
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              border: "solid",
                              color: "black",
                              width: "20%",
                              fontSize: "15px",
                              
                            }}
                          >
                            Reglement
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              border: "solid",
                              color: "black",
                              width: "10%",
                              fontSize: "15px",
                              
                            }}
                          >
                            Montant
                          </th>
                          
                        </tr>
                      </thead>
                      {this.state.encaissFormat.map((el) => (
                        <tbody>
                          <tr>
                            
                            <td
                              style={{
                                textAlign: "center",
                                border: "solid",
                                color: "black",
                              }}
                            >
                              <p>{el.numIns}</p>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                border: "solid",
                                color: "black",
                              }}
                            >
                              <p>{el.date}</p>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                border: "solid",
                                color: "black",
                              }}
                            >
                              <p>{el.numRecu}</p>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                border: "solid",
                                color: "black",
                              }}
                            >
                              <p>{el.montant}</p>
                            </td>
                            
                            
                          </tr>
                          
                        </tbody>
                      ))}
                      <tr style={{
                        background: "#73dff5",
                                textAlign: "center",
                                border: "solid",
                                color: "black",
                              }} >
                        <td ></td>
                        <td colspan="1"></td><td style={{
                              textAlign: "right",
                              border: "solid",
                              color: "black",
                              fontStyle: "italic",
                              fontSize:"17px",
                            }}>Total Recettes</td>
                              <td style={{
                              textAlign: "center",
                              border: "solid",
                              color: "black",
                            }}>{this.state.totalFormat}</td> 
                            </tr>
                            
                              
            <tbody>
              <tr>
                
              </tr>
              <tr>
                <td   colspan="3"style={{ border: "solid", color: "black" ,}}>
                  Total de Recette en Espèces
                </td>

                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    width:"20px"
                    
                  }}
                >
                {Number.parseFloat(this.state.espFormat).toFixed(3)}
                </td>
              </tr>
              <tr>
                <td colspan="3" style={{ border: "solid", color: "black" }}>
                  Total de Recette en Virement
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                   {Number.parseFloat(this.state.virFormat).toFixed(3)}
                
                </td>
              </tr>
              <tr>
                <td colspan="3" style={{ border: "solid", color: "black" }}>
                  Total de Recette par Chèque
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
              {Number.parseFloat(this.state.checFormat).toFixed(3)}
                
                </td>
              </tr>
              <tr>
                <td colspan="3" style={{ border: "solid", color: "black" }}>
                  Total recette comptable
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  { parseFloat(this.state.checFormat) + parseFloat(this.state.espFormat)}
                </td>
              </tr>
              <tr>
                
              </tr>
              <tr>
                
                <td colspan="3"
                  style={{
                    textAlign: "right",
                    border: "solid",
                    color: "black",
                    fontSize: "20px",
                   
                  }}
                >
                  Total
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  {this.state.totalFormat}
                </td>
              </tr>
            
            </tbody>
            
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprRapportFor);
