import React, { Component } from 'react'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'

class AjoutFraisCycle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fraisCycle: '',
            mntFraisCycle: '',
            anneeFraisCycle: ''
        }
    }

    addFraisCycle = (event) => {
        this.setState({
            fraisCycle: event.target.value
        });
    }

    addMntFraisCycle = (event) => {
        this.setState({
            mntFraisCycle: event.target.value
        });
    }

    addAnneeFraisCycle = (event) => {
        this.setState({
            anneeFraisCycle: event.target.value
        });
    }

    ajout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddFraisCycle.php',
            type: 'POST',
            data: {
                fraisCycle: this.state.fraisCycle,
                mntFraisCycle: this.state.mntFraisCycle,
                anneeFraisCycle: this.state.anneeFraisCycle,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/FraisCycle');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout frais cycle</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/FraisCycle"><button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Frais</label>
                                            <input type="text" className="form-control" placeholder="Frais" onChange={this.addFraisCycle} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Montant</label>
                                            <input type="number" className="form-control" placeholder='Montant' onChange={this.addMntFraisCycle} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Annee</label>
                                            <input type="number" className="form-control" placeholder='Annee' onChange={this.addAnneeFraisCycle} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AjoutFraisCycle);