import React, { Component, Fragment } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import jQuery from "jquery";
import "./paginate.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";



export class ApprAnciens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [

        {
          name: "Numero d'inscription",
          selector: "insId",
          sortable: false,
          center: true,
          style: { width: "10%" },
        },
       
        {
          name: "CIN",
          selector: "cin",
          sortable: false,
          center: true,
          style: { width: "10%" },
        },
        {
          name: "Prénom-Nom",
          selector: "prenomNom",
          sortable: false,
          center: true,
          style: { width: "40%" },
        },
        {
          name: "Annee Scolaire",
          selector: "anneeScol",
          sortable: false,
          center: true,
          style: { width: "10%" },
        },
        {
          name: "Profil",
          cell: (row) => (
            <button
              style={{
                backgroundColor: "#F0E68C",
                borderColor: "#F0E68C",
                borderRadius: "5px",
              }}
              onClick={() => this.profil(row.idApprenant)}
            >
              <i class="fas fa-id-badge"></i>
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "15%",
        },
          
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      idApprenant: "",
      pending: true,
      // recherche
      anneeNaiss: "",
      listEleves: [],
      listGroupes : [],
      selectedEleve: "",
      selectedGroupe : "" , 
      idRoleUser: "",
      insId: "",
      idEleve : "" ,
      //
      selectEtat : "",
    };
    this.newElement = this.newElement.bind(this);
   
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var idRoleUser = userPermissions[0].idRoleUser;
    
      this.setState({
        idRoleUser: idRoleUser,
      });
      axios
        .get(
          process.env.REACT_APP_API_URL + "Back_mediatraining/SelectApprenants.php"
        )
        .then((res) => {
          console.log(res);
          this.setState({
            listEleves: res.data,
          });
        });

      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/AnciensAppr.php",
        type: "POST",
        data: {
          query: "" , 
          limit: this.state.nbrEltperPage,
          page: 1,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }



  newElement(page, totalRows) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    {
      this.setState(
        {
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/AnciensAppr.php",
            type: "POST",
            data: {
              limit: this.state.nbrEltperPage,
              page: page,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } 
  }


  perPage(currentRowsPerPage, currentPage) {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    {
      this.setState(
        {
          nbrEltperPage: currentRowsPerPage,
          pending: true,
        },
        () => {
          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/Apprenants.php",
            type: "POST",
            data: {
              query: this.state.filterElement,
              limit: currentRowsPerPage,
              page: currentPage,
              anneeScolaire: anneeScolaire,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                nombreTotalElement: code_html.total,
                data: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    } 
  }



  profil = (el) => {
    var eleveId = el;
    window.open(
      "http://artsmetiers.mtd-app.com/ImprProfilApp?eleveId=" + eleveId
    );
  };

  recherche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectedEleve != "") {
      var idEleve = this.state.selectedEleve.value;
    } else {
      var idEleve = ''
    }
    this.setState(
      {
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {

        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetApprInscri.php",
          type: "POST",
          data: {
            idEleve : idEleve , 
            anneeScolaire: anneeScolaire,
            limit: this.state.nbrEltperPage,
            page: 1
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };


  
  addEleve = (selectedEleve) => {
    if (selectedEleve != null) {
      this.setState({ selectedEleve }, () => {});
    } else {
      this.setState({
        selectedEleve: "",
      });
    }
  }



 



  afficherTout = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mediatraining/AnciensAppr.php",
      type: "POST",
      data: {
        query: "",
        limit: this.state.nbrEltperPage,
        page: 1,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Apprenants</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <Fragment>
                  
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>  Eleve  </label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={(e) => this.addEleve (e)}
                          options={this.state.listEleves}
                        />
                      </div>
                    </div>
                  </Fragment>
                </div> 
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.afficherTout}
                    >
                      Afficher tout
                    </button>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApprAnciens