import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class ImprListeCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listEleves: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var params = arrayUrl[1];
      var arrayParams = params.split("-");
      var nom = arrayParams[0];
      var prenom = arrayParams[1];
      var cin = arrayParams[2];
      var lieu = arrayParams[3];
      var niveau = arrayParams[4];
      var typeFor = arrayParams[5];
      var detailCycle = arrayParams[6];
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprListeCycle.php",
        type: "POST",
        data: {
          nom: nom,
          prenom: prenom,
          cin: cin,
          lieu: lieu,
          niveau: niveau,
          typeFor: typeFor,
          detailCycle: detailCycle,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listEleves: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className>
        <div style={{ textAlign: "center" }} className="row">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              Fiche Formation Accéléré
            </label>
          </div>
        </div>
        <table style={{ border: "solid", marginBottom: "0", width: "100%" }}>
          <thead>
            <th
              style={{ textAlign: "center", border: "solid", color: "black" }}
            ></th>
            <th
              style={{ textAlign: "center", border: "solid", color: "black" }}
            >
              {" "}
              N°Ins{" "}
            </th>
            <th
              style={{ textAlign: "center", border: "solid", color: "black" }}
            >
              {" "}
              Nom & Prenom{" "}
            </th>
            <th
              style={{ textAlign: "center", border: "solid", color: "black" }}
            >
              {" "}
              Tél{" "}
            </th>
            <th
              style={{ textAlign: "center", border: "solid", color: "black" }}
            >
              {" "}
              Mail{" "}
            </th>
          </thead>
          <tbody>
            {this.state.listEleves.map((elem, indexOfLigne) => (
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    width: "5%",
                    color: "black",
                  }}
                >
                  {" "}
                  {indexOfLigne + 1}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    width: "15%",
                    color: "black",
                  }}
                >
                  {" "}
                  {elem.insNum}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    width: "30%",
                    color: "black",
                  }}
                >
                  {" "}
                  {elem.nomEleve}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    width: "20%",
                    color: "black",
                  }}
                >
                  {" "}
                  {elem.tel}{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    width: "30%",
                    color: "black",
                  }}
                >
                  {" "}
                  {elem.email}{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprListeCycle);
