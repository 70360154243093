import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';

class MenuNote extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            // Scolarite
            barreNavNote: [{
                titre: " Note", element: [{ nomElement: "Nature Note", iconElement: "", linkElement: "", arraySousElement: [{ nomSousElement: "Ajout test", linkSousElement: "/NatureNote", permission: 0, iconSousElement: "fas fa-atlas", }, { nomSousElement: "Recap note par niveau", linkSousElement: "/NiveauNatureNote", permission: 0, iconSousElement: "fas fa-book", },], }, { nomElement: "Rapport Note", iconElement: "", linkElement: "", arraySousElement: [{ nomSousElement: "Ajout Note ", linkSousElement: "/AjoutNote", permission: 0, iconSousElement: "fas fa-plus-circle", }, { nomSousElement: "Consulter", linkSousElement: "/ListNote", permission: 0, iconSousElement: "fas fa-eye", },], }, { nomElement: "Rapport Note BTS", iconElement: "", linkElement: "", arraySousElement: [{ nomSousElement: "Ajout Note BTS", linkSousElement: "/AjoutNoteBTS", permission: 0, iconSousElement: "fas fa-plus-circle", }, { nomSousElement: "Consulter", linkSousElement: "/ListNoteBTS", permission: 0, iconSousElement: "fas fa-eye", }] }, { nomElement: "Releve Note ", iconElement: "fas fa-clipboard-list", linkElement: "/ReleveNote", sousPermission: 0, arraySousElement: [], }, { nomElement: "Releve Note BTS ", iconElement: "fas fa-clipboard-list", linkElement: "/ReleveNoteBTS", sousPermission: 0, arraySousElement: [], }, { nomElement: "Resultat BTS ", iconElement: "fas fa-sticky-note", linkElement: "/ResultatBTS", sousPermission: 0, arraySousElement: [], }, { nomElement: "Resultat ACC ", iconElement: "fas fa-book-open", linkElement: "/ResultatPizzaiolo", sousPermission: 0, arraySousElement: [], }, { nomElement: "Resultat Trimestriel", iconElement: "fas fa-book-reader", linkElement: "/ResultatTrimestriel", sousPermission: 0, arraySousElement: [], }, { nomElement: "List Vierge", iconElement: "fas fa-sticky-note", linkElement: "/ListVierge", sousPermission: 0, arraySousElement: [], }, {
                    nomElement: "Stage", iconElement: "", linkElement: "", arraySousElement: [{
                        nomSousElement: "Ajouter", linkSousElement: "/AjoutNoteStage", permission: 0, iconSousElement: "fas fa-plus-circle",
                    },
                    {
                        nomSousElement: "Consulter",
                        linkSousElement: "/ConsulterNoteStage",
                        permission: 0,
                        iconSousElement: "fas fa-eye",
                    },
                    {
                        nomSousElement: "Consulter BTS",
                        linkSousElement: "/ConsulterNoteStageBts",
                        permission: 0,
                        iconSousElement: "fas fa-eye",
                    },
                    ],
                },
                {
                    nomElement: "Note PFE",
                    iconElement: "",
                    linkElement: "",
                    arraySousElement: [
                        {
                            nomSousElement: "Ajouter",
                            linkSousElement: "/AjoutNotePFE",
                            permission: 0,
                            iconSousElement: "fas fa-plus-circle",
                        },
                        {
                            nomSousElement: "Consulter",
                            linkSousElement: "/ConsulterNotePFE",
                            permission: 0,
                            iconSousElement: "fas fa-eye",
                        },
                    ],
                },
                {
                    nomElement: "Jury",
                    iconElement: "",
                    linkElement: "",
                    arraySousElement: [
                        {
                            nomSousElement: "Jury",
                            linkSousElement: "/ConsulterJury",
                            permission: 0,
                            iconSousElement: "fas fa-eye",
                        },
                        {
                            nomSousElement: "Jury bTS",
                            linkSousElement: "/ConsulterJuryBTS",
                            permission: 0,
                            iconSousElement: "fas fa-eye",
                        },
                    ],
                },
                {
                    nomElement: "EFF ",
                    iconElement: "",
                    linkElement: "",
                    arraySousElement: [
                        {
                            nomSousElement: "Ajouter",
                            linkSousElement: "/AjoutNoteExamen",
                            permission: 0,
                            iconSousElement: "fas fa-plus-circle",
                        },
                        {
                            nomSousElement: "Consulter",
                            linkSousElement: "/ConsNoteExam",
                            permission: 0,
                            iconSousElement: "fas fa-plus-circle",
                        },
                        {
                            nomSousElement: "Examen Pratique",
                            linkSousElement: "/ConsulterNoteExam",
                            permission: 0,
                            iconSousElement: "fas fa-eye",
                        },
                        
                        {
                            nomSousElement: "Examen Pratique BTS",
                            linkSousElement: "/ConsulterNoteExamBts",
                            permission: 0,
                            iconSousElement: "fas fa-eye",
                        },
                    ],
                },
                {
                    nomElement: "Releve de notes de fin formation",
                    iconElement: "fas fa-eye",
                    linkElement:  "/relevefinformation",
                    sousPermission: 0,
                    arraySousElement: [],
                },
                {
                    nomElement: "Moyenne d'admission",
                    iconElement: "fas fa-paste",
                    linkElement: "/MoyenneAdmission",
                    sousPermission: 0,
                    arraySousElement: [],
                },
                {
                    nomElement: "Moyenne d'admission BTS",
                    iconElement: "fas fa-scroll",
                    linkElement: "/MoyenneAdmissionBTS",
                    sousPermission: 0,
                    arraySousElement: [],
                },
                {
                    nomElement: "Rattrapage",
                    iconElement: "fas fa-clone",
                    linkElement: "/Rattrapage",
                    sousPermission: 0,
                    arraySousElement: [],
                },
                {
                    nomElement: "Rattrapage BTS",
                    iconElement: "fas fa-clone",
                    linkElement: "/RattrapageBTS",
                    sousPermission: 0,
                    arraySousElement: [],
                }
                ],
                iconTitre: "fas fa-align-left",
            },
            ],
            barreNavNote2: [
                {
                    titre: " Note",
                    element: [
                        {
                            nomElement: "Nature Note",
                            iconElement: "",
                            linkElement: "",
                            arraySousElement: [
                                {
                                    nomSousElement: "Ajout test",
                                    linkSousElement: "/NatureNote",
                                    permission: 0,
                                    iconSousElement: "fas fa-atlas",
                                },
                                {
                                    nomSousElement: "Recap note par niveau",
                                    linkSousElement: "/NiveauNatureNote",
                                    permission: 0,
                                    iconSousElement: "fas fa-book",
                                },
                            ],
                        },
                        {
                            nomElement: "Rapport Note",
                            iconElement: "",
                            linkElement: "",
                            arraySousElement: [
                                {
                                    nomSousElement: "Ajout Note ",
                                    linkSousElement: "/AjoutNote",
                                    permission: 0,
                                    iconSousElement: "fas fa-plus-circle",
                                },
                                {
                                    nomSousElement: "Consulter",
                                    linkSousElement: "/ListNote",
                                    permission: 0,
                                    iconSousElement: "fas fa-eye",
                                },
                            ],
                        },
                        {
                            nomElement: "Rapport Note BTS / BTP",
                            iconElement: "",
                            linkElement: "",
                            arraySousElement: [
                                {
                                    nomSousElement: "Ajout Note BTS / BTP",
                                    linkSousElement: "/AjoutNoteBTS",
                                    permission: 0,
                                    iconSousElement: "fas fa-plus-circle",
                                },
                                {
                                    nomSousElement: "Consulter",
                                    linkSousElement: "/ListNoteBTS",
                                    permission: 0,
                                    iconSousElement: "fas fa-eye",
                                },
                            ],
                        },
                        {
                            nomElement: "Releve Note ",
                            iconElement: "fas fa-clipboard-list",
                            linkElement: "/ReleveNote",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                            nomElement: "Releve Note BTS / BTP ",
                            iconElement: "fas fa-clipboard-list",
                            linkElement: "/ReleveNoteBTS",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                            nomElement: "Resultat BTS / BTP ",
                            iconElement: "fas fa-sticky-note",
                            linkElement: "/ResultatBTS",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                            nomElement: "Resultat ACC ",
                            iconElement: "fas fa-book-open",
                            linkElement: "/ResultatPizzaiolo",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                            nomElement: "Resultat Trimestriel",
                            iconElement: "fas fa-book-reader",
                            linkElement: "/ResultatTrimestriel",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                            nomElement: "List Vierge",
                            iconElement: "fas fa-sticky-note",
                            linkElement: "/ListVierge",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                            nomElement: "Stage",
                            iconElement: "",
                            linkElement: "",
                            arraySousElement: [
                                {
                                    nomSousElement: "Ajouter",
                                    linkSousElement: "/AjoutNoteStage",
                                    permission: 0,
                                    iconSousElement: "fas fa-plus-circle",
                                },
                                {
                                    nomSousElement: "Consulter",
                                    linkSousElement: "/ConsulterNoteStage",
                                    permission: 0,
                                    iconSousElement: "fas fa-eye",
                                },
                                {
                                    nomSousElement: "Consulter BTS / BTP",
                                    linkSousElement: "/ConsulterNoteStageBts",
                                    permission: 0,
                                    iconSousElement: "fas fa-eye",
                                },
                            ],
                        },
                        {
                            nomElement: "Note PFE",
                            iconElement: "",
                            linkElement: "",
                            arraySousElement: [
                                {
                                    nomSousElement: "Ajouter",
                                    linkSousElement: "/AjoutNotePFE",
                                    permission: 0,
                                    iconSousElement: "fas fa-plus-circle",
                                },
                                {
                                    nomSousElement: "Consulter",
                                    linkSousElement: "/ConsulterNotePFE",
                                    permission: 0,
                                    iconSousElement: "fas fa-eye",
                                },
                            ],
                        },
                        {
                            nomElement: "Jury",
                            iconElement: "",
                            linkElement: "",
                            arraySousElement: [
                                {
                                    nomSousElement: "Jury",
                                    linkSousElement: "/ConsulterJury",
                                    permission: 0,
                                    iconSousElement: "fas fa-eye",
                                },
                                {
                                    nomSousElement: "Jury BTS / BTP",
                                    linkSousElement: "/ConsulterJuryBTS",
                                    permission: 0,
                                    iconSousElement: "fas fa-eye",
                                },
                            ],
                        },
                        {
                            nomElement: "EFF ",
                            iconElement: "",
                            linkElement: "",
                            arraySousElement: [
                                {
                                    nomSousElement: "Ajouter",
                                    linkSousElement: "/AjoutNoteExamen",
                                    permission: 0,
                                    iconSousElement: "fas fa-plus-circle",
                                },
                                {
                                    nomSousElement: "Consulter",
                                    linkSousElement: "/ConsNoteExam",
                                    permission: 0,
                                    iconSousElement: "fas fa-plus-circle",
                                },
                                {
                                    nomSousElement: "Examen Pratique",
                                    linkSousElement: "/ConsulterNoteExam",
                                    permission: 0,
                                    iconSousElement: "fas fa-eye",
                                },
                                
                                {
                                    nomSousElement: "Examen Pratique BTS / BTP",
                                    linkSousElement: "/ConsulterNoteExamBts",
                                    permission: 0,
                                    iconSousElement: "fas fa-eye",
                                },
                                
                            ],
                        },
                        {
                        
                            nomElement: "Releve de notes de fin formation",
                            iconElement: "fas fa-eye",
                            linkElement:  "/relevefinformation",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                        
                            nomElement: "Moyenne d'admission",
                            iconElement: "fas fa-paste",
                            linkElement: "/MoyenneAdmission",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                            nomElement: "Moyenne d'admission BTS / BTP",
                            iconElement: "fas fa-scroll",
                            linkElement: "/MoyenneAdmissionBTS",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                            nomElement: "Rattrapage",
                            iconElement: "fas fa-clone",
                            linkElement: "/Rattrapage",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                        {
                            nomElement: "Rattrapage BTS / BTP",
                            iconElement: "fas fa-clone",
                            linkElement: "/RattrapageBTS",
                            sousPermission: 0,
                            arraySousElement: [],
                        },
                    ],
                    iconTitre: "fas fa-align-left",
                },
            ],

            // Role selon l'utilisateur (soit admin ou scolarite)
            roleId: '',
            anneeScolaire: ""
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var roleId = userPermissions[0].idRoleUser
            var anneeScolaire = userPermissions[0].anneeScolaire;
            this.setState({
                roleId: roleId,
                anneeScolaire: parseInt(anneeScolaire),

            });
            if (roleId == 1) {
                document.location = '/'
            }
        }
        else {
            document.location = '/'
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Note</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box" >
                                <div className="row">
                                    {this.state.anneeScolaire < 2020 ? (
                                        <div>
                                            {this.state.barreNavNote.map((el, index) => (
                                                <div className="col-md-4">
                                                    <div
                                                        className="white-box"
                                                        style={{
                                                            border: "solid 1px",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <h3
                                                            style={{
                                                                border: "solid 1px",
                                                                backgroundColor: "black",
                                                                color: "white",
                                                            }}
                                                        >
                                                            <i
                                                                style={{
                                                                    paddingRight: "5px",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                className={el.iconTitre}
                                                            ></i>
                                                            {el.titre}
                                                        </h3>
                                                        <div className="myadmin-dd dd" id="nestable2">
                                                            <ol className="dd-list">
                                                                {el.element.map((ele, indice) => (
                                                                    <div>
                                                                        {ele.arraySousElement.length > 0 ? (
                                                                            // Noeud Parent Ayant des Fils Feuilles
                                                                            <Fragment>
                                                                                <li
                                                                                    className="dd-item"
                                                                                    data-id={index}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            border:
                                                                                                "1px solid rgba(120,130,140,.13)",
                                                                                            padding: "8px 16px",
                                                                                            height: "auto",
                                                                                            fontWeight: "600",
                                                                                            color: "#2b2b2b",
                                                                                        }}
                                                                                    >
                                                                                        <i
                                                                                            style={{
                                                                                                paddingRight: "5px",
                                                                                            }}
                                                                                            className={ele.iconElement}
                                                                                        ></i>
                                                                                        {ele.nomElement}
                                                                                    </div>
                                                                                    <ol className="dd-list">
                                                                                        {ele.arraySousElement.map(
                                                                                            (elm) => (
                                                                                                <li
                                                                                                    className="dd-item"
                                                                                                    data-id={index + 1}
                                                                                                >
                                                                                                    <Link
                                                                                                        style={{
                                                                                                            color: "#2b2b2b",
                                                                                                        }}
                                                                                                        to={elm.linkSousElement}
                                                                                                    >
                                                                                                        <div
                                                                                                            style={{
                                                                                                                border:
                                                                                                                    "1px solid rgba(120,130,140,.13)",
                                                                                                                padding: "8px 16px",
                                                                                                                height: "auto",
                                                                                                                fontWeight: "600",
                                                                                                            }}
                                                                                                        >
                                                                                                            <i
                                                                                                                style={{
                                                                                                                    paddingRight:
                                                                                                                        "5px",
                                                                                                                }}
                                                                                                                className={
                                                                                                                    elm.iconSousElement
                                                                                                                }
                                                                                                            ></i>
                                                                                                            {elm.nomSousElement}
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    </ol>
                                                                                </li>{" "}
                                                                            </Fragment>
                                                                        ) : (
                                                                            // Noeud Feuille (Terminaison)
                                                                            <Link
                                                                                style={{ color: "#2b2b2b" }}
                                                                                to={ele.linkElement}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        border:
                                                                                            "1px solid rgba(120,130,140,.13)",
                                                                                        padding: "8px 16px",
                                                                                        height: "auto",
                                                                                        fontWeight: "600",
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        style={{ paddingRight: "5px" }}
                                                                                        className={ele.iconElement}
                                                                                    ></i>
                                                                                    {ele.nomElement}
                                                                                </div>
                                                                            </Link>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>
                                            {this.state.barreNavNote2.map((el, index) => (
                                                <div className="col-md-4">
                                                    <div
                                                        className="white-box"
                                                        style={{
                                                            border: "solid 1px",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <h3
                                                            style={{
                                                                border: "solid 1px",
                                                                backgroundColor: "black",
                                                                color: "white",
                                                            }}
                                                        >
                                                            <i
                                                                style={{
                                                                    paddingRight: "5px",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                className={el.iconTitre}
                                                            ></i>
                                                            {el.titre}
                                                        </h3>
                                                        <div className="myadmin-dd dd" id="nestable2">
                                                            <ol className="dd-list">
                                                                {el.element.map((ele, indice) => (
                                                                    <div>
                                                                        {ele.arraySousElement.length > 0 ? (
                                                                            // Noeud Parent Ayant des Fils Feuilles
                                                                            <Fragment>
                                                                                <li
                                                                                    className="dd-item"
                                                                                    data-id={index}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            border:
                                                                                                "1px solid rgba(120,130,140,.13)",
                                                                                            padding: "8px 16px",
                                                                                            height: "auto",
                                                                                            fontWeight: "600",
                                                                                            color: "#2b2b2b",
                                                                                        }}
                                                                                    >
                                                                                        <i
                                                                                            style={{
                                                                                                paddingRight: "5px",
                                                                                            }}
                                                                                            className={ele.iconElement}
                                                                                        ></i>
                                                                                        {ele.nomElement}
                                                                                    </div>
                                                                                    <ol className="dd-list">
                                                                                        {ele.arraySousElement.map(
                                                                                            (elm) => (
                                                                                                <li
                                                                                                    className="dd-item"
                                                                                                    data-id={index + 1}
                                                                                                >
                                                                                                    <Link
                                                                                                        style={{
                                                                                                            color: "#2b2b2b",
                                                                                                        }}
                                                                                                        to={elm.linkSousElement}
                                                                                                    >
                                                                                                        <div
                                                                                                            style={{
                                                                                                                border:
                                                                                                                    "1px solid rgba(120,130,140,.13)",
                                                                                                                padding: "8px 16px",
                                                                                                                height: "auto",
                                                                                                                fontWeight: "600",
                                                                                                            }}
                                                                                                        >
                                                                                                            <i
                                                                                                                style={{
                                                                                                                    paddingRight:
                                                                                                                        "5px",
                                                                                                                }}
                                                                                                                className={
                                                                                                                    elm.iconSousElement
                                                                                                                }
                                                                                                            ></i>
                                                                                                            {elm.nomSousElement}
                                                                                                        </div>
                                                                                                    </Link>
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    </ol>
                                                                                </li>{" "}
                                                                            </Fragment>
                                                                        ) : (
                                                                            // Noeud Feuille (Terminaison)
                                                                            <Link
                                                                                style={{ color: "#2b2b2b" }}
                                                                                to={ele.linkElement}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        border:
                                                                                            "1px solid rgba(120,130,140,.13)",
                                                                                        padding: "8px 16px",
                                                                                        height: "auto",
                                                                                        fontWeight: "600",
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        style={{ paddingRight: "5px" }}
                                                                                        className={ele.iconElement}
                                                                                    ></i>
                                                                                    {ele.nomElement}
                                                                                </div>
                                                                            </Link>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MenuNote;