import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class AjoutUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nom: '',
            roles: [],
            selectedRole: '',
            login: '',
            password: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var idRoleUser = userPermissions[0].idRoleUser
            if (idRoleUser == 1) {
                axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectRoles.php').then(res => {
                    console.log(res)
                    this.setState({
                        roles: res.data
                    })
                })
            }
            else {
                localStorage.clear();
                this.props.history.push('/');
            }
        }
        else {
            document.location = '/'
        }
    }

    addNom = (event) => {
        this.setState({
            nom: event.target.value
        });
    }

    addRole = selectedRole => {
        this.setState({ selectedRole });
        console.log(`Role selected:`, selectedRole);
    };

    addLogin = (event) => {
        this.setState({
            login: event.target.value
        });
    }

    addPassword = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    ajout = () => {
        if (this.state.selectedRole != '' && this.state.login != '' && this.state.password != '') {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var userId = userPermissions[0].idUser
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddUser.php',
                type: 'POST',
                data: {
                    nom: this.state.nom,
                    idRole: this.state.selectedRole.value,
                    login: this.state.login,
                    password: this.state.password,
                    userId: userId
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    if (code_html.Result == "OK") {
                        this.props.history.push('/Users');
                    }
                    else if (code_html.Result == "KO") {
                        alert("erreur,l'un de vos champs est vide!!");
                    }
                }
            });
        }
        else {
            toast.error('⛔ veuillez verifier les champs role,login et password !!', { containerId: 'A' });
        }
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout utilisateur</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Users" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Nom</label>
                                            <input type="text" className="form-control" placeholder="Nom" onChange={this.addNom} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Role</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addRole}
                                                options={this.state.roles}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Login</label>
                                            <input type="text" className="form-control" placeholder="Login" onChange={this.addLogin} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Mot de passe</label>
                                            <input type="text" className="form-control" placeholder="Mot de passe" onChange={this.addPassword} style={{ borderRadius: '5px', border: 'solid 1px #B3B3B3' }} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                </div>
            </div>
        );
    }
}

export default withRouter(AjoutUser);