import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Select from "react-select";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import "react-toastify/dist/ReactToastify.css";

class PaiementsEnseignant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test: "f",
      typeEnss:"",
     tCY:0,
     tEP:0,
     tEG:0,
     tET:0,
      testEns: "",
      listTypeEnseign: [],
      listTypePay: [],
      honoraireEnseign: [
        {
          idPay: -1,
          typeEns: "",
          typePay: "",
          honoraire: "",
          DatePayment: "",
        },
      ],
      indexHonEnseignASupprimer: "",
      modal: false,
      idHonEnseignASupprimer: "",
      tabIdHonEnseignDelete: [],
      idEnseignant: "",
      nomEnseignant: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var idRoleUser = userPermissions[0].idRoleUser;
      if (idRoleUser == 1) {
        var idEnseignant = this.props.location.state.idEnseignant;
        this.setState({
          idEnseignant: idEnseignant,
        });
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetPaimentByEnseign.php",
          type: "POST",
          data: {
            idEns: idEnseignant,
            anneeScolaire: anneeScolaire,
          },

          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              honoraireEnseign: code_html,

              //nomEnseignant: code_html[0].nomComplet,
            });
            console.log(this.state.honoraireEnseign.length);
            console.log(this.state.honoraireEnseign);
          },
        });
        axios
          .get(
            process.env.REACT_APP_API_URL +
              "Back_mediatraining/SelectTypeEns.php"
          )
          .then((res) => {
            console.log(res);
            this.setState({
              listTypeEnseign: res.data,
              
            });console.log(this.state.listTypeEnseign)
          });
        axios
          .get(
            process.env.REACT_APP_API_URL +
              "Back_mediatraining/SelectTypePaiement.php"
          )
          .then((res) => {
            console.log(res);
            this.setState({
              listTypePay: res.data,
            });
          });
      } else {
        localStorage.clear();
        this.props.history.push("/");
      }
    } else {
      document.location = "/";
    }
  }

  additionLign = () => {
    let element = {
      idPay: -1,
      typeEns: "",
      typePay: "",
      honoraire: "",
      DatePayment: "",
    };

    this.setState({
      honoraireEnseign: [...this.state.honoraireEnseign, element],
    });
  };

  UpdTypeEnseign = (selectedTypeEnseign, indexTypeEnseign) => {
    let typeEns = selectedTypeEnseign;
    this.state.typeEnss=selectedTypeEnseign;

    for (let i = 0; i < this.state.honoraireEnseign.length; i++) {
      if (this.state.honoraireEnseign[i].typeEns.label =="EG"){
        {this.state.tEG+=1}
        console.log(this.state.tEG)}
        else {this.state.tEG=0}
       if (
        
      this.state.honoraireEnseign[i].typeEns.label =="ET"){
      {this.state.tET+=1}
        console.log(this.state.tET)}
      
       if(  
      this.state.honoraireEnseign[i].typeEns.label =="EP"){

    {this.state.tEP +=1}
    console.log(this.state.tEP)
      }
     if(
  
      this.state.honoraireEnseign[i].typeEns.label =="Cycle"  ){
      {this.state.tCY+=1}
      console.log("tesgstf")
      console.log(this.state.tCY)
      }
      if (
        this.state.honoraireEnseign[i].typeEns.value ==
        selectedTypeEnseign.value
      ) {
        this.state.testEns = "no";
        i = this.state.honoraireEnseign.length;
      } else {
        this.state.testEns = "ok";
      }
    }
    this.setState({
      typEns: typeEns,
      honoraireEnseign: this.state.honoraireEnseign.map((el, id) =>
        id === indexTypeEnseign ? Object.assign(el, { typeEns: typeEns }) : el
      ),
    });
  };

  updTypePay = (selectedTypPay, indexTypePay) => {
    let typePay = selectedTypPay;
    this.setState({
      honoraireEnseign: this.state.honoraireEnseign.map((el, id) =>
        id === indexTypePay ? Object.assign(el, { typePay: typePay }) : el
      ),
    });
  };
 
  updHonoraire = (event, indexHonoraire) => {
    let honoraire = event.target.value;

    this.setState({
      honoraireEnseign: this.state.honoraireEnseign.map((el, id) =>
        id === indexHonoraire ? Object.assign(el, { honoraire: honoraire }) : el
      ),
    });
  };

  upddate = (event, indexdate) => {
    let DatePayment = event.target.value;
    this.setState({
      honoraireEnseign: this.state.honoraireEnseign.map((el, id) =>
        id === indexdate ? Object.assign(el, { DatePayment: DatePayment }) : el
      ),
    });
  };

  toggleHonEnseign = (idHonEnseign, indexHonEnseign) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      idHonEnseignASupprimer: idHonEnseign,
      indexHonEnseignASupprimer: indexHonEnseign,
    }));
  };

  deleteHonEnseign = () => {
    let idHonEnseignASupprimer = this.state.idHonEnseignASupprimer;
    this.setState({
      tabIdHonEnseignDelete: [
        ...this.state.tabIdHonEnseignDelete,
        idHonEnseignASupprimer,
      ],
    });
    this.state.honoraireEnseign.splice(this.state.indexHonEnseignASupprimer, 1);
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  nonDeleteHonEnseign = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  modif = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var userId = userPermissions[0].idUser;
    var anneeScolaire = userPermissions[0].anneeScolaire;

    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/UpdPaiementsEnseign.php",
      type: "POST",
      data: {
        honoraireEnseign: this.state.honoraireEnseign,
        idHonEnseignDelete: this.state.tabIdHonEnseignDelete,
        idEnseignant: this.state.idEnseignant,
        userId: userId,
        anneeScolaire: anneeScolaire,
        test: this.state.honoraireEnseign.length,
        testEns: this.state.testEns,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Enseignants");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">
                {this.state.honoraireEnseign.length == 0 ? (
                  <div>{"Paiements enseignant: "}</div>
                ) : (
                  <div>
                    {"Paiements enseignant: " +
                      this.state.honoraireEnseign[0].nomComplet}
                  </div>
                )}
              </h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/Enseignants">
                    {" "}
                    <button
                      style={{
                        background: "#DC143C",
                        border: "1px solid #DC143C",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i
                        style={{ paddingRight: "5px" }}
                        className="fas fa-arrow-circle-left"
                      ></i>
                      Retour
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <table
                    style={{ marginTop: "10px" }}
                    className="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }} scope="col">
                          Type enseignement
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Type paiement
                        </th>

                        <th style={{ textAlign: "center" }} scope="col">
                          Honoraire
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Date
                        </th>
                        <th
                          style={{ textAlign: "center", width: "15%" }}
                          scope="col"
                        >
                          Suppression
                        </th>
                      </tr>
                    </thead>
                    {this.state.honoraireEnseign.map((el, index) => (
                      <tbody>
                        <tr>
                          <td className="col-md-4">
                            <Select
                              value={el.typeEns}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => this.UpdTypeEnseign(e, index)}
                              options={this.state.listTypeEnseign}
                            />{" "}
                          </td>
                          <td className="col-md-4">
                            <Select
                              value={el.typePay}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(e) => this.updTypePay(e, index)}
                              options={this.state.listTypePay}
                            />{" "}
                          </td>
                          <td
                            className="col-md-3"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="number"
                              placeholder="Honoraire"
                              value={el.honoraire}
                              onChange={(e) => this.updHonoraire(e, index)}
                              style={{
                                textAlign: "center",
                                height: "38px",
                                borderRadius: "5px",
                                border: "solid 1px #B3B3B3",
                              }}
                            />
                          </td>

                          <td
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                          >
                            {index == 0 || this.state.testEns == "ok" ||el.nCY ==1 ||el.nET ==1 
                            ||el.nET ==1||el.nEP ==1
                            ?(
                              <input
                                disabled
                                value={el.DatePayment}
                                onChange={(e) => this.upddate(e, index)}
                                style={{
                                  textAlign: "center",
                                  height: "38px",
                                  borderRadius: "5px",
                                  border: "solid 1px #B3B3B3",
                                }}
                              />
                            ) : (
                              <input
                                value={el.DatePayment}
                                onChange={(e) => this.upddate(e, index)}
                                style={{
                                  textAlign: "center",
                                  height: "38px",
                                  borderRadius: "5px",
                                  border: "solid 1px #B3B3B3",
                                }}
                              />
                            )}
                          </td>
                          <td
                            className="col-md-1"
                            style={{ textAlign: "center" }}
                          >
                            <button
                              className="buttonSupprimer"
                              onClick={() =>
                                this.toggleHonEnseign(el.idPay, index)
                              }
                            >
                              <i
                                style={{ color: "white" }}
                                className="fas fa-trash-alt"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginLeft: "15px",
                    }}
                  >
                    <Button
                      style={{ borderRadius: "5px" }}
                      onClick={this.additionLign}
                      color="secondary"
                    >
                      <i className="fas fa-plus-circle"></i>Ajouter ligne
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    style={{ borderRadius: "5px" }}
                    type="button"
                    className="btn btn-success"
                    onClick={this.modif}
                  >
                    Confirmer
                  </button>
                </div>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.nonDeleteHonEnseign}
                  className={this.props.className}
                  fade={false}
                >
                  <ModalHeader toggle={this.nonDeleteHonEnseign}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "465px",
                      }}
                    >
                      <img
                        width="30%"
                        src="./images/deleteModal.png"
                        alt=".."
                      />
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <div
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        FontSize: "14px",
                        FontWeight: "700",
                        LineHeight: "18.375px",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                      </div>
                      <div
                        className="hvr-push"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <button
                            onClick={() => this.deleteHonEnseign()}
                            style={{
                              backgroundColor: "#00FF7F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-up"
                            ></i>
                            Oui
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={() => this.nonDeleteHonEnseign()}
                            style={{
                              backgroundColor: "#D9534F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-down"
                            ></i>
                            Non
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PaiementsEnseignant);
