import React, { Component, Fragment } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";

import jQuery from "jquery";
import "./paginate.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Flip } from "react-toastify";
import Select from "react-select";

class GestionEnseignant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anneeScolaire: [],
      columns: [
        {
          name: "Année",
          selector: "label",
          sortable: false,
          center: true,
          style: { width: "20%" },
        },
        {
          name: "Imprimer",
          cell: (row) => (
            <button
            style={{
              borderRadius: "5px",
              backgroundColor: "#761C19",
              borderColor: "#761C19",
            }}
            type="button"
            className="btn btn-success"
            onClick={() => this.impr(row.label)}
          >
            <i
              style={{ color: "white", paddingRight: "5px" }}
              class="fas fa-print"
            ></i>
            Imprimer
          </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "50%",
        },
        //
        ,
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      modalDes: false,
      modalAct: false,
      modalAbon: false,
      idEnseignant: "",
      pending: true,
    };
  
    
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var role = userPermissions[0].idRoleUser;
      this.setState(
        {
          role: role,
        },
        () => {
          axios
            .get(
              process.env.REACT_APP_API_URL +
                "Back_mediatraining/Getannee.php"
            )
            .then((res) => {
              console.log(res);
              this.setState({
                nombreTotalElement: res.data.total,
                data: res.data,
              });
            });
          // Recuperer l'année scolaire récente
          axios
            .get(
              process.env.REACT_APP_API_URL +
                "Back_mediatraining/GetAnneeScolRecente.php"
            )
            .then((res) => {
              console.log(res);
              this.setState({
                selectedAnneeScolaire: res.data,
              });
            });

          jQuery.ajax({
            url:
              process.env.REACT_APP_API_URL +
              "Back_mediatraining/Enseignants.php",
            type: "POST",
            data: {
              query: "",
              limit: this.state.nbrEltperPage,
              page: 1,
            },
            dataType: "json",
            success: (code_html, statut) => {
              console.log(code_html);
              this.setState({
                
                datas: code_html.data,
                pending: false,
              });
            },
          });
        }
      );
    }
  }



  addAnneeScolaire = (selectedAnneeScolaire) => {
    this.setState({ selectedAnneeScolaire });
  };


  impr = (label) => {
    var id_annee = label;
    console.log(id_annee);
    window.open("http://artsmetiers.mtd-app.com/ImpDecla?id_annee =" + id_annee);
  };
  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Gestion Enseignants</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/AjoutDeclaration">
                    {" "}
                    <button
                      style={{
                        background: "#3A3F51",
                        border: "1px solid #3A3F51",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i className="fas fa-plus"></i>Ajouter declaration
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <h4 style={{ paddingLeft: "10px" }}>GestionEnseignant</h4>
                 
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover={true}
                    pagination={true}
                    paginationServer={true}
                    onChangePage={(page, totalRows) =>
                      this.newElement(page, totalRows)
                    }
                    paginationTotalRows={this.state.nombreTotalElement}
                    paginationPerPage={this.state.nbrEltperPage}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                      this.perPage(currentRowsPerPage, currentPage)
                    }
                    paginationResetDefaultPage={this.state.resetFirstPage}
                    paginationComponentOptions={{
                      rowsPerPageText: "Elements par page:",
                      rangeSeparatorText: "de",
                      noRowsPerPage: false,
                    }}
                    progressPending={this.state.pending}
                    noHeader={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GestionEnseignant;
