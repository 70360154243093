import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import Table from "reactstrap/lib/Table";
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'


class ResultatTrimestriel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listGroupe: [],
      selectGroupe: "",
      listTrimestre: [
        { value: 1, label: "Trimestre 1" },
        { value: 2, label: "Trimestre 2" },
        { value: 3, label: "Trimestre 3" },
      ],
      selectTrimestre: "",
      releveNote: [],
      blockNote: false,
      filiere: "",
      niveau: "",
      groupe: ""
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetGroupeNonBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listGroupe: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addGroupe = (selectGroupe) => {
    if (selectGroupe != null) {
      this.setState({ selectGroupe }, () => { });
    } else {
      this.setState({
        selectGroupe: "",
      });
    }
  };

  addTrimestre = (selectTrimestre) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (selectTrimestre != null) {
      this.setState({ selectTrimestre }, () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/ResultatTrimestriel.php",
          type: "POST",
          data: {
            groupeId: this.state.selectGroupe.value,
            trimestre: selectTrimestre.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              releveNote: code_html,
            });
          },
        });
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL +
            "Back_mediatraining/GetInfoGroupe.php",
          type: "POST",
          data: {
            groupeId: this.state.selectGroupe.value,
            anneeScolaire: anneeScolaire,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              filiere: code_html.nomFiliere,
              niveau: code_html.niveau,
              groupe: code_html.groupe
            });
          },
        });
      });
    } else {
      this.setState({
        selectTrimestre: "",
      });
    }
  };

  affiche = () => {
    this.setState({
      blockNote: true,
    });
  };

  impr = () => {
    var idGroupeTrim = this.state.selectGroupe.value + '-' + this.state.selectTrimestre.value
    window.open("http://artsmetiers.mtd-app.com/ImprTrimestre?idGroupeTrim=" + idGroupeTrim)
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Resultat Trimestriel </h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroupe}
                        options={this.state.listGroupe}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Trimestre</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addTrimestre}
                        options={this.state.listTrimestre}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <div>
                    <button style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success" onClick={this.affiche}>Afficher</button>
                  </div>
                  <div>
                    <button onClick={this.impr} style={{ borderRadius: '5px', backgroundColor: '#761C19', borderColor: '#761C19' }} type="button" className="btn btn-success"><i style={{ color: 'white', paddingRight: '5px' }} class="fas fa-print"></i>Impression</button>
                  </div>
                </div>
                {this.state.blockNote == true ? (
                  <div>
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                        className="row"
                      >
                        <label>
                          Resultat {this.state.selectTrimestre.label}{" "}
                        </label>
                      </div>
                      <div style={{ display: "flex", justifyContent: "center" }} className="row">
                        <label style={{ fontWeight: "bold" }}>
                          de (groupe : {this.state.groupe}) {this.state.niveau}{" "}
                          - Spécialité {this.state.filiere}
                        </label>
                      </div>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Eleve</th>
                          <th></th>
                          <th style={{ textAlign: "center" }}>
                            Moy.Trimestriel
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.releveNote.map((el, indexOfLigne) => (
                          <tr>
                            <td>{indexOfLigne + 1}</td>
                            <td style={{ width: "20vw" }}>{el.eleve}</td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  {el.allNote.noteEP.map((elem) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <p style={{ width: "15vw" }}>
                                        {" "}
                                        {elem.matiere}{" "}
                                      </p>
                                      <p> {elem.matiereNote} </p>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {el.allNote.noteET.map((elem) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <p style={{ width: "15vw" }}>
                                        {" "}
                                        {elem.matiere}{" "}
                                      </p>
                                      <p> {elem.matiereNote} </p>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {el.allNote.noteEG.map((elem) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <p style={{ width: "15vw" }}>
                                        {" "}
                                        {elem.matiere}{" "}
                                      </p>
                                      <p> {elem.matiereNote} </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </td>
                            <tr
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                fontWeight: "bold",
                              }}
                            >
                              <td> {el.allNote.nameEP} </td>
                              <td> {el.allNote.moyEP} </td>
                              <td> {el.allNote.nameET} </td>
                              <td> {el.allNote.moyET} </td>
                              <td> {el.allNote.nameEG} </td>
                              <td> {el.allNote.moyEG} </td>
                            </tr>
                            <td style={{ textAlign: "center" }}>
                              <p> {el.moyenneGen} </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResultatTrimestriel);

