import React, { Component } from "react";
import jQuery from "jquery";
import "./paginate.css";
import DataTable from 'react-data-table-component';

class ImprVisit extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
       visit:[],
      
    };
  }


  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var etat = arrayUrl[1]
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/ImprVisit.php",
        type: "POST",
        data: {
          etat : etat,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json", 
        success: (code_html, statut) => {
          console.log(code_html)
          this.setState({
            visit: code_html,
            
          
          });console.log(this.state.visit)
        },
      });
    } else {
      document.location = "/";
    }
  }

  impr = () => {
    window.print();
  };    

  render() {
    return (
      <div className="white-box">
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-around" ,
          }}
          className="row"
        >
          
          <div className="form-group">
            <img src="images/art_metier.png" alt="" />
          </div>
          <div className="form-group" style={{width:"135px", textAlign: "left"}}>
          <p style={{ fontWeight: "bold" }}>

          8,rue borj
          Bourguiba,le Passage
          1001-Tunis
          Tél. 71 240 204 /52
          875 028</p>

          </div>
        </div>
        
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            padding:"20px"
          }}
        >
          Liste des visiteurs {" "}
        </h4><div style={{ paddingLeft:'120px' }} >
        <table style={{ color: 'black',padding:'20px' }} width={600} border={0} align="center" >
          <th >Nom </th>
          <th > Prenom</th>
          <th>Mobile</th>
         {this.state.visit.map((el)=>
          <tr>
            <td><p style={{  color: 'black' ,fontSize:"18px"}}>{el.nom}</p></td>
            <td><p style={{ color: 'black' ,fontSize:"18px"}}>{el.prenom}</p></td>
            <td><p style={{  color: 'black' ,fontSize:"18px"}}>{el.mobile}</p></td>
          </tr>
         )}
        </table>
        </div>
        
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default ImprVisit;



