import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class AjoutSpecialite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            specialites: [{ nomSpecialite: '', filiere: '' }],
            indexLignSpecialiteASupprimer: '',
            modal: false,
            ListFilieres: []
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFilieres.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        ListFilieres: code_html
                    })
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    additionLignSpecialite = () => {
        let element = { nomSpecialite: '', filiere: '' }
        this.setState({
            specialites: [...this.state.specialites, element]
        })
    }

    addNomSpecialite = (event, indexNomSpecialite) => {
        let nomSpecialite = event.target.value;
        this.setState({
            specialites: this.state.specialites.map((el, id) => (id === indexNomSpecialite ? Object.assign(el, { nomSpecialite: nomSpecialite }) : el))
        });
    }

    addFiliere = (selectedFiliere, indexFiliere) => {
        let filiere = selectedFiliere;
        this.setState({
            specialites: this.state.specialites.map((el, id) => (id === indexFiliere ? Object.assign(el, { filiere: filiere }) : el))
        });
    }

    toggleLignSpecialite = (indexLignSpecialite) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            indexLignSpecialiteASupprimer: indexLignSpecialite
        }));
    }

    deleteLignSpecialite = () => {
        this.state.specialites.splice(this.state.indexLignSpecialiteASupprimer, 1)
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    nonDeleteLignSpecialite = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    ajout = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/AddSpecialite.php',
            type: 'POST',
            data: {
                specialites: this.state.specialites,
                anneeScolaire: anneeScolaire,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Specialites');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Ajout specialité</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Specialites" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <table style={{ width: '100%', marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Filiére</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Suppression</th>
                                            </tr>
                                        </thead>
                                        {this.state.specialites.map((el, index) =>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-4" style={{ textAlign: 'center' }}><input type="text" placeholder='Nom' value={el.nomSpecialite} onChange={(e) => this.addNomSpecialite(e, index)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                    <td className="col-md-6"><Select
                                                        value={el.filiere}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => this.addFiliere(e, index)}
                                                        options={this.state.ListFilieres}
                                                    />  </td>
                                                    <td className="col-md-2" style={{ textAlign: 'center' }}><button className='buttonSupprimer' onClick={() => this.toggleLignSpecialite(index)}><i style={{ color: 'white' }} className="fas fa-trash-alt"></i></button></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '15px' }}>
                                        <Button style={{ borderRadius: '5px' }} onClick={this.additionLignSpecialite} color="secondary"><i className="fas fa-plus-circle"></i>Ajouter ligne</Button>
                                    </div>
                                </div>
                                <Modal isOpen={this.state.modal} toggle={this.nonDeleteLignSpecialite} className={this.props.className} fade={false}>
                                    <ModalHeader toggle={this.nonDeleteLignSpecialite}> <div style={{ display: 'flex', justifyContent: 'center', width: '465px' }}><img width='30%' src="./images/deleteModal.png" alt=".." /></div></ModalHeader>
                                    <ModalBody>
                                        <div style={{ fontFamily: 'Montserrat, sans-serif', FontSize: '14px', FontWeight: '700', LineHeight: '18.375px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <p>Êtes-Vous sûr de vouloir supprimer cette specialité ?</p>
                                            </div>
                                            <div className='hvr-push' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
                                                <div><button onClick={() => this.deleteLignSpecialite()} style={{ backgroundColor: '#00FF7F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-up"></i>Oui</button></div>
                                                <div><button onClick={() => this.nonDeleteLignSpecialite()} style={{ backgroundColor: '#D9534F', borderRadius: '5px', color: 'white' }}><i style={{ color: 'white' }} className="far fa-thumbs-down"></i>Non</button></div>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.ajout}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AjoutSpecialite);