import React, { Component, Fragment } from "react";
import axios from "axios";
import jQuery from "jquery";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
  Redirect,
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { RadioGroup, Radio } from "react-radio-group";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class AjoutEnseignant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prenom: "",
      nom: "",
      selectedType: "CIN",
      numSelonType: "",
      nationalite: "",
      dateDelivSelonType: new Date(),
      villeDelivreSelonType: "",
      dateNaissance: new Date(),
      lieuNaissance: "",
      adresseOfficiel: "",
      adresseTun: "",
      email: "",
      selectedGender: "Masculin",
      mobile: "",
      tel: "",
      imageVerso: null,
      qualification: "",
      fb: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
    } else {
      document.location = "/";
    }
  }

  addPrenom = (event) => {
    this.setState({
      prenom: event.target.value,
    });
  };

  addNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  addType = (selectedType) => {
    this.setState({ selectedType: selectedType });
  };

  addNumSelonType = (event) => {
    this.setState({
      numSelonType: event.target.value,
    });
  };

  addNationalite = (event) => {
    this.setState({
      nationalite: event.target.value,
    });
  };

  addDateLivraison = (d) => {
    this.setState({
      dateDelivSelonType: d,
    });
  };

  addVilleDelivreSelonType = (event) => {
    this.setState({
      villeDelivreSelonType: event.target.value,
    });
  };

  addDateNaissance = (d) => {
    this.setState({
      dateNaissance: d,
    });
  };

  addLieuNaissance = (event) => {
    this.setState({
      lieuNaissance: event.target.value,
    });
  };

  addAdresseOfficiel = (event) => {
    this.setState({
      adresseOfficiel: event.target.value,
    });
  };

  addAdresseTun = (event) => {
    this.setState({
      adresseTun: event.target.value,
    });
  };

  addEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  addGender = (selectedGender) => {
    this.setState({ selectedGender: selectedGender });
  };

  addMobile = (event) => {
    this.setState({
      mobile: event.target.value,
    });
  };

  addTel = (event) => {
    this.setState({
      tel: event.target.value,
    });
  };

  addImageVerso(e) {
    this.setState({ imageVerso: e.target.files });
  }

  addQualification = (event) => {
    this.setState({
      qualification: event.target.value,
    });
  };

  addTarif = (event) => {
    this.setState({
      tarif: event.target.value,
    });
  };

  addFb = (event) => {
    this.setState({
      fb: event.target.value,
    });
  };

  ajout = () => {
    if (
      this.state.prenom != "" &&
      this.state.nom != "" &&
      this.state.numSelonType != "" &&
      this.state.tel != ""
    ) {
      // console.log(this.state.dateDelivSelonType)
      let dateDelivSelonType = this.state.dateDelivSelonType;
      let month_one = "" + (dateDelivSelonType.getMonth() + 1);
      let day_one = "" + dateDelivSelonType.getDate();
      let year_one = dateDelivSelonType.getFullYear();
      if (month_one.length < 2) month_one = "0" + month_one;
      if (day_one.length < 2) day_one = "0" + day_one;
      let convertDateDelivSelonType = [year_one, month_one, day_one].join("-");
      // console.log(this.state.dateNaissance)
      let dateNaissance = this.state.dateNaissance;
      let month_two = "" + (dateNaissance.getMonth() + 1);
      let day_two = "" + dateNaissance.getDate();
      let year_two = dateNaissance.getFullYear();
      if (month_two.length < 2) month_two = "0" + month_two;
      if (day_two.length < 2) day_two = "0" + day_two;
      let convertDateNaissance = [year_two, month_two, day_two].join("-");
      // Test si photo vide
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var userId = userPermissions[0].idUser;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/AddEnseignant.php",
        type: "POST",
        data: {
          prenom: this.state.prenom,
          nom: this.state.nom,
          type: this.state.selectedType,
          numero: this.state.numSelonType,
          nationalite: this.state.nationalite,
          dateType: convertDateDelivSelonType,
          villeType: this.state.villeDelivreSelonType,
          dateNaissance: convertDateNaissance,
          lieuNaissance: this.state.lieuNaissance,
          adresseOfficiel: this.state.adresseOfficiel,
          adresseTun: this.state.adresseTun,
          email: this.state.email,
          sexe: this.state.selectedGender,
          mobile: this.state.mobile,
          tel: this.state.tel,
          fb: this.state.fb,
          qualification: this.state.qualification,
          userId: userId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          if (code_html.Result == "OK") {
            var idEns = code_html.idEns;
            // Test si photo vide
            if (this.state.imageVerso != null) {
              let files = this.state.imageVerso;
              console.warn("data files", files);
              let reader = new FileReader();
              reader.readAsDataURL(files[0]);
              reader.onload = (e) => {
                console.log(e);
                jQuery.ajax({
                  url:
                    process.env.REACT_APP_API_URL +
                    "Back_mediatraining/Uploadphoto.php",
                  type: "POST",
                  data: {
                    file: e.target.result,
                    nomfile: idEns,
                  },
                  dataType: "json",
                  success: function (code_html, statut) {},
                });
              };
              this.props.history.push("/Enseignants");
            } else {
              this.props.history.push("/Enseignants");
            }
          } else if (code_html.Result == "KO") {
            alert("erreur,l'un de vos champs est vide!!");
          }
        },
      });
    } else {
      toast.error(
        "⛔ Veuillez vérifier les champs nom,prenom,tel et numero selon type !",
        { containerId: "A" }
      );
    }
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Ajout enseignant</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/Enseignants">
                    {" "}
                    <button
                      style={{
                        background: "#DC143C",
                        border: "1px solid #DC143C",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i
                        style={{ paddingRight: "5px" }}
                        className="fas fa-arrow-circle-left"
                      ></i>
                      Retour
                    </button>
                  </Link>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Prénom</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Prénom"
                        onChange={this.addPrenom}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nom"
                        onChange={this.addNom}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Sexe</label>
                    <RadioGroup
                      name="sexe"
                      selectedValue={this.state.selectedGender}
                      onChange={this.addGender}
                    >
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="Masculin" />
                        Masculin
                      </label>
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="Feminin" />
                        Feminin
                      </label>
                    </RadioGroup>
                  </div>
                  <div className="col-md-6">
                    <label>Type</label>
                    <RadioGroup
                      name="type"
                      selectedValue={this.state.selectedType}
                      onChange={this.addType}
                    >
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="CIN" />
                        CIN
                      </label>
                      <label style={{ paddingRight: "5px" }}>
                        <Radio value="Passeport" />
                        Passeport
                      </label>
                    </RadioGroup>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Numero</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Numero selon le type"
                        onChange={this.addNumSelonType}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nationalité</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nationalité"
                        onChange={this.addNationalite}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Delivré le</label>
                    <div className="form-group">
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        selected={this.state.dateDelivSelonType}
                        onChange={this.addDateLivraison}
                        dateFormat="dd/MM/yyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Delivré ville de</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Delivré dans ville de"
                        onChange={this.addVilleDelivreSelonType}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Date naissance</label>
                    <div className="form-group">
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        selected={this.state.dateNaissance}
                        onChange={this.addDateNaissance}
                        dateFormat="dd/MM/yyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Lieu de naissance</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Lieu de naissance"
                        onChange={this.addLieuNaissance}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Adresse officiel</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Adresse officiel"
                        onChange={this.addAdresseOfficiel}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Adresse en tunisie</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Adresse en tunisie"
                        onChange={this.addAdresseTun}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        onChange={this.addEmail}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mobile</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile"
                        onChange={this.addMobile}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tel</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tel"
                        onChange={this.addTel}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Photo verso </label>
                      <input
                        type="file"
                        className="form-control"
                        name="file"
                        onChange={(e) => this.addImageVerso(e)}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Qualification</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Qualification"
                        onChange={this.addQualification}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Facebook</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Facebook"
                        onChange={this.addFb}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    style={{ borderRadius: "5px" }}
                    type="button"
                    className="btn btn-success"
                    onClick={this.ajout}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    );
  }
}

export default withRouter(AjoutEnseignant);
