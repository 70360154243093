import React, { Component } from "react";
import jQuery from "jquery";
import { withRouter } from "react-router-dom";
import Collapsible from "react-collapsible";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class RapportJournalier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      encaissFormat: [],
      decaissFormat: [],
      espFormat: 0,
      checFormat: 0,
      virFormat: 0,
      totalFormat: 0,
      sommeEncaissFormat: 0,
      sommeDecaissFormat: 0,
      encaissCycle: [],
      decaissCycle: [],
      espCycle: 0,
      checCycle: 0,
      virCycle: 0,
      totalCycle: 0,
      sommeEncaissCycle: 0,
      sommeDecaissCycle: 0,
      somEncaissCycle: 0,
      somRembourCycle: 0,
      total: 0,
      dateDebut: 0,
      dateFin: 0,
      totalRemb: 0,
    };
  }

  addDateDebut = (d) => {
    this.setState({
      dateDebut: d,
    });
  };

  addDateFin = (d) => {
    this.setState({
      dateFin: d,
    });
  };

  recherche = () => {
    if (this.state.dateDebut != "" || this.state.dateFin != "") {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      // convertir date debut
      if (this.state.dateDebut != "") {
        var dateDebut = this.state.dateDebut;
        let monthOne = "" + (dateDebut.getMonth() + 1);
        let dayOne = "" + dateDebut.getDate();
        let yearOne = dateDebut.getFullYear();
        if (monthOne.length < 2) monthOne = "0" + monthOne;
        if (dayOne.length < 2) dayOne = "0" + dayOne;
        var convertDateDebut = [yearOne, monthOne, dayOne].join("-");
      } else {
        var convertDateDebut = "";
      }
      // convertir date fin
      if (this.state.dateFin != "") {
        var dateFin = this.state.dateFin;
        let monthTwo = "" + (dateFin.getMonth() + 1);
        let dayTwo = "" + dateFin.getDate();
        let yearTwo = dateFin.getFullYear();
        if (monthTwo.length < 2) monthTwo = "0" + monthTwo;
        if (dayTwo.length < 2) dayTwo = "0" + dayTwo;
        var convertDateFin = [yearTwo, monthTwo, dayTwo].join("-");
      } else {
        var convertDateFin = "";
      }
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/SearchRapportJour.php",
        type: "POST",
        data: {
          dateDebut: convertDateDebut,
          dateFin: convertDateFin,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState(
            {
              encaissFormat: code_html[0],
              decaissFormat: code_html[1],
              espFormat: code_html[2][0].espFormat,
              checFormat: code_html[2][0].checFormat,
              virFormat: code_html[2][0].virFormat,
              totalFormat: code_html[2][1].totalResteFormat,
              encaissCycle: code_html[3],
              decaissCycle: code_html[4],
              espCycle: code_html[5][0].espCycle,
              checCycle: code_html[5][0].checCycle,
              virCycle: code_html[5][0].virCycle,
              totalCycle: code_html[5][1].totalResteCycle,
              somEncaissCycle : code_html[5][1].sommeEncaissCycle,
              somRembourCycle : code_html[5][1].sommeRembourCycle,
            },
            () => {
              // formation
              var sommeEncaissFormat =
                parseFloat(this.state.espFormat) +
                parseFloat(this.state.checFormat) +
                parseFloat(this.state.virFormat);
              this.setState({
                sommeEncaissFormat: sommeEncaissFormat,
              });
              var sommeDecaissFormat = 0;
              for (var i = 0; i < this.state.decaissFormat.length; i++) {
                sommeDecaissFormat += parseFloat(
                  this.state.decaissFormat[i].montant
                );
              }
              this.setState({
                sommeDecaissFormat: sommeDecaissFormat,
              });
              // cycle
              var sommeEncaissCycle =
                parseFloat(this.state.espCycle) +
                parseFloat(this.state.checCycle) +
                parseFloat(this.state.virCycle);
              this.setState({
                sommeEncaissCycle: sommeEncaissCycle,
              });
              var sommeDecaissCycle = 0;
              for (var i = 0; i < this.state.decaissCycle.length; i++) {
                sommeDecaissCycle += parseFloat(
                  this.state.decaissCycle[i].montant
                );
              }
              this.setState({
                sommeDecaissCycle: sommeDecaissCycle,
              });
              // Total formation + cycle
              var total =
                parseFloat(this.state.totalFormat) +
                parseFloat(this.state.totalCycle);
              var totalRemb =
                parseFloat(sommeDecaissFormat) + parseFloat(sommeDecaissCycle);
              this.setState({
                total: total,
                totalRemb: totalRemb,
              });
            }
          );
        },
      });
    } else {
      toast.error("⛔ Veuillez verifier les champs dates !!", {
        containerId: "A",
      });
    }
  };

  imprFor = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    // convertir date debut
    if (this.state.dateDebut != "") {
      var dateDebut = this.state.dateDebut;
      let monthOne = "" + (dateDebut.getMonth() + 1);
      let dayOne = "" + dateDebut.getDate();
      let yearOne = dateDebut.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateDebut = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateDebut = "";
    }
    // convertir date fin
    if (this.state.dateFin != "") {
      var dateFin = this.state.dateFin;
      let monthTwo = "" + (dateFin.getMonth() + 1);
      let dayTwo = "" + dateFin.getDate();
      let yearTwo = dateFin.getFullYear();
      if (monthTwo.length < 2) monthTwo = "0" + monthTwo;
      if (dayTwo.length < 2) dayTwo = "0" + dayTwo;
      var convertDateFin = [yearTwo, monthTwo, dayTwo].join("-");
    } else {
      var convertDateFin = "";
    }
    
          
              var date = convertDateDebut + "/" + convertDateFin;
             
             window.open(
              "http://artsmetiers.mtd-app.com/ImprRapportFor?date=" + date)
             // "http://localhost:3000/ImprRapportFor?date =" + date );
          }
        
      

  imprCycle = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    // convertir date debut
    if (this.state.dateDebut != "") {
      var dateDebut = this.state.dateDebut;
      let monthOne = "" + (dateDebut.getMonth() + 1);
      let dayOne = "" + dateDebut.getDate();
      let yearOne = dateDebut.getFullYear();
      if (monthOne.length < 2) monthOne = "0" + monthOne;
      if (dayOne.length < 2) dayOne = "0" + dayOne;
      var convertDateDebut = [yearOne, monthOne, dayOne].join("-");
    } else {
      var convertDateDebut = "";
    }
    // convertir date fin
    if (this.state.dateFin != "") {
      var dateFin = this.state.dateFin;
      let monthTwo = "" + (dateFin.getMonth() + 1);
      let dayTwo = "" + dateFin.getDate();
      let yearTwo = dateFin.getFullYear();
      if (monthTwo.length < 2) monthTwo = "0" + monthTwo;
      if (dayTwo.length < 2) dayTwo = "0" + dayTwo;
      var convertDateFin = [yearTwo, monthTwo, dayTwo].join("-");
    } else {
      var convertDateFin = "";
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/SearchRapportJour.php",
      type: "POST",
      data: {
        dateDebut: convertDateDebut,
        dateFin: convertDateFin,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState(
          {
            totalFormat: code_html[2][1].totalResteFormat,
            totalCycle: code_html[5][1].totalResteCycle,
            somEncaissCycle : code_html[5][1].sommeEncaissCycle,
            somRembourCycle : code_html[5][1].sommeRembourCycle,
          },
          () => {
           // if (this.state.totalCycle != 0) {
           if (this.state.somEncaissCycle != 0 || this.state.somRembourCycle != 0) {
              jQuery.ajax({
                url:
                  process.env.REACT_APP_API_URL +
                  "Back_mediatraining/ImprRapportCycle.php",
                type: "POST",
                data: {
                  dateDebut: convertDateDebut,
                  dateFin: convertDateFin,
                  anneeScolaire: anneeScolaire,
                },
                dataType: "json",
                success: function (code_html, statut) {
                  var server_path = code_html.server_path;
                  var file_path = code_html.file_path;
                  var path = server_path + file_path;
                  window.open(path, "_blank");
                },
              });
            } else {
              var date = convertDateDebut + "/" + convertDateFin;
              window.open(
                "http://artsmetiers.mtd-app.com/ImprRapportCycle?date=" + date
              );
            }
          }
        );
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Rapport journalier</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date debut</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        selected={this.state.dateDebut}
                        onChange={this.addDateDebut}
                        dateFormat="dd/MM/yyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Date fin</label>
                      <DatePicker
                        class="form-control ddate"
                        style={{ border: "none" }}
                        selected={this.state.dateFin}
                        onChange={this.addDateFin}
                        dateFormat="dd/MM/yyy"
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.recherche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.imprFor}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression formation
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.imprCycle}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression cycle
                    </button>
                  </div>
                </div>
                <Collapsible
                  trigger={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: "#D9534F",
                          borderRadius: "5px",
                        }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="fas fa-info"
                        ></i>
                      </button>
                      <h2>Formation</h2>
                    </div>
                  }
                >
                  <h3>Encaissement</h3>
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Nom
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            N° inscription
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Date
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Reglement
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Montant
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            mode
                          </th>
                        </tr>
                      </thead>
                      {this.state.encaissFormat.map((el) => (
                        <tbody>
                          <tr>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.nomComplet}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.numIns}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.date}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.numRecu}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.montant}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.mode}</p>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    {/* <table className="table table-bordered">
                                            <tbody>
                                                <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>Somme encaissement</td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{Number.parseFloat(this.state.sommeEncaissFormat).toFixed(3)}</td>
                                                    <td className="col-md-2"></td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                  </div>
                  <h3>Decaissement</h3>
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Nom
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Type remboursement
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Date
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Reglement
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Montant
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            mode
                          </th>
                        </tr>
                      </thead>
                      {this.state.decaissFormat.map((el) => (
                        <tbody>
                          <tr>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.nomComplet}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.numIns}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.date}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.numRecu}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.montant}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.mode}</p>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    {/* <table className="table table-bordered">
                                            <tbody>
                                                <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>Somme decaissement</td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{Number.parseFloat(this.state.sommeDecaissFormat).toFixed(3)}</td>
                                                    <td className="col-md-2"></td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                  </div>
                </Collapsible>
                <h3>Total formation</h3>
                <div className="row">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Recette par espéce
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.espFormat).toFixed(3)}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Recette par virement
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.virFormat).toFixed(3)}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Recette par chéque
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.checFormat).toFixed(
                              3
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Recette comptable
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.totalFormat).toFixed(
                              3
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Total des réglement
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.totalFormat).toFixed(
                              3
                            )}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Collapsible
                  trigger={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: "#D9534F",
                          borderRadius: "5px",
                        }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="fas fa-info"
                        ></i>
                      </button>
                      <h2>Cycle</h2>
                    </div>
                  }
                >
                  <h3>Encaissement</h3>
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Nom
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            N° inscription
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Date
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Reglement
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Montant
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            mode
                          </th>
                        </tr>
                      </thead>
                      {this.state.encaissCycle.map((el) => (
                        <tbody>
                          <tr>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.nomComplet}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.numIns}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.date}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.numRecu}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.montant}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.mode}</p>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    {/* <table className="table table-bordered">
                                            <tbody>
                                                <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>Somme encaissement</td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{Number.parseFloat(this.state.sommeEncaissCycle).toFixed(3)}</td>
                                                    <td className="col-md-2"></td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                  </div>
                  <h3>Decaissement</h3>
                  <div className="row">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Nom
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            N° inscription
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Date
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Reglement
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Montant
                          </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            mode
                          </th>
                        </tr>
                      </thead>
                      {this.state.decaissCycle.map((el) => (
                        <tbody>
                          <tr>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.nomComplet}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.numIns}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.date}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.numRecu}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.montant}</p>
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <p>{el.mode}</p>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    {/* <table className="table table-bordered">
                                            <tbody>
                                                <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>Somme decaissement</td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2"></td>
                                                    <td className="col-md-2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{Number.parseFloat(this.state.sommeDecaissCycle).toFixed(3)}</td>
                                                    <td className="col-md-2"></td>
                                                </tr>
                                            </tbody>
                                        </table> */}
                  </div>
                </Collapsible>
                <h3>Total cycle</h3>
                <div className="row">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Recette par espéce
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.espCycle).toFixed(3)}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Recette par virement
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.virCycle).toFixed(3)}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Recette par chéque
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.checCycle).toFixed(3)}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Recette comptable
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.totalCycle).toFixed(
                              3
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                            }}
                          >
                            Total des réglement
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                            }}
                          >
                            {Number.parseFloat(this.state.totalCycle).toFixed(
                              3
                            )}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3>Total</h3>
                <div className="row">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                              fontWeight: "bold",
                            }}
                          >
                            Total Remboursement
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                              fontWeight: "bold",
                            }}
                          >
                            {Number.parseFloat(this.state.totalRemb).toFixed(3)}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              paddingLeft: "5px",
                              fontWeight: "bold",
                            }}
                          >
                            Total
                          </p>
                        </td>
                        <td className="col-md-6">
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: "5px",
                              fontWeight: "bold",
                            }}
                          >
                            {Number.parseFloat(this.state.total).toFixed(3)}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <ToastContainer
                transition={Flip}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RapportJournalier);
