import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";

class ImprDetailCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      nom: "",
      prenom: "",
      age: "",
      email: "",
      fb: "",
      fonction: "",
      mobile: "",
      numero: "",
      carte: "",
      photo: "",
      dateSession: "",
      nomCycle: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href;
      var eleves = url.split("=");
      var eleveId = eleves[1];
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/FicheCycle.php",
        type: "POST",
        data: {
          eleveId: eleveId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            details: code_html,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoEleveCycle.php",
        type: "POST",
        data: {
          eleveId: eleveId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            nom: code_html.nom,
            prenom: code_html.prenom,
            age: code_html.age,
            email: code_html.email,
            fb: code_html.fb,
            fonction: code_html.fonction,
            mobile: code_html.mobile,
            numero: code_html.numero,
            carte: code_html.carte,
            photo: code_html.photo,
            dateSession: code_html.dateSession,
            nomCycle: code_html.nomCycle,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <h3> Fiche D'inscription En Formation Accélérée </h3>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" ,marginTop:'1%' }}>
            <h5>Spécialité : {this.state.nomCycle}</h5>
            <h5>Session : {this.state.dateSession}</h5>
          </div>
        </div>
        <table width="689" align="center" style={{marginTop:'3%'}}>
          <tr align="left">
            <td height="260" valign="top">
              <table
                width="100%"
                cellspacing="1"
                cellpadding="4"
                style={{
                  border: "3px solid",
                  borderRadius: "10px",
                  marginRight: "1%",
                }}
              >
                <tr>
                  <td width="29%" height="33">
                    <strong>Nom : {this.state.nom} </strong>
                  </td>
                  <td colspan="2"> </td>
                </tr>
                <tr>
                  <td height="33">
                    <strong>Prénom: {this.state.prenom}</strong>
                  </td>
                  <td colspan="2"> </td>
                </tr>
                <tr>
                  <td height="33">
                    <strong>Age: {this.state.age}</strong>
                  </td>
                  <td width="19%"></td>
                  <td width="52%">
                    <strong>T&eacute;l Fix:</strong>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <strong>GSM: {this.state.mobile}</strong>{" "}
                  </td>
                </tr>
                <tr>
                  <td height="33">
                    <strong>Adresse Email: {this.state.email} </strong>
                  </td>
                  <td colspan="2"> </td>
                </tr>
                <tr>
                  <td height="33">
                    <strong>Face-book: {this.state.fb} </strong>
                  </td>
                  <td colspan="2"> </td>
                </tr>
                <tr>
                  <td height="33">
                    <strong>Etude: </strong>
                  </td>
                  <td colspan="2"> </td>
                </tr>
                <tr>
                  <td height="33">
                    <strong>Fonction Actuelle: {this.state.fonction}</strong>
                  </td>
                  <td colspan="2"> </td>
                </tr>
              </table>
            </td>
            <td width="210" valign="top">
              <table width="99%" cellspacing="1" cellpadding="4">
                <tr>
                  <td>
                    <table
                      height="114"
                      width="100%"
                      style={{
                        border: "3px solid",
                        marginLeft: "2%",
                        borderRadius: "20px",
                      }}
                    >
                      <tr>
                        <td width="400" height="119">
                          <img src={this.state.photo} alt="" />
                        </td>
                      </tr>
                    </table>
                  </td>{" "}
                </tr>
                <tr>
                  <td height="114">
                    <table
                      width="100%"
                      style={{
                        border: "3px solid",
                        marginLeft: "2%",
                        borderRadius: "20px",
                      }}
                    >
                      <tr>
                        <td width="100%" height="34">
                          N° CIN: {this.state.numero}
                        </td>
                      </tr>
                      <tr>
                        <td width="100%" height="25">N° Carte: {this.state.carte}</td>
                      </tr>
                      <tr>
                        <td width="100%" height="31">Validité :</td>
                      </tr>
                    </table>
                  </td>{" "}
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <div
          style={{
            border: "2px solid",
            borderRaduis: "5px",
            marginTop: "3%",
          }}
        >
          <div style={{ marginLeft: "2%", marginTop: "1%" }}>
            <h3>Historique des Sessions réalisées</h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3%",
              marginBottom: "3%",
            }}
          >
            <table style={{ border: "solid", width: "100%" }}>
              <thead>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    width: "5%",
                  }}
                >
                  N°
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    width: "20%",
                  }}
                >
                  Session
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    width: "5%",
                  }}
                >
                  N°
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    width: "20%",
                  }}
                >
                  Date Formation
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    width: "48%",
                  }}
                >
                  Paiement
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                    width: "2%",
                  }}
                >
                  Solde
                </th>
              </thead>
              <tbody>
                {this.state.details.map((elem, index) => (
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {" "}
                      {elem.nomCycle}{" "}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {" "}
                      {elem.numIns}{" "}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {" "}
                      {elem.dateSession}{" "}
                    </td>
                    <div>
                      <table
                        style={{
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "center",
                                color: "black",
                              }}
                            >
                              Num Reçu
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                color: "black",
                              }}
                            >
                              Date
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                color: "black",
                              }}
                            >
                              Montant
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                color: "black",
                              }}
                            >
                              Mode paiement
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {elem.paiement.map((el) => (
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                {el.numRecu}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                {el.dateReg}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                {el.montant}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                {el.mode}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {" "}
                      {Number.parseFloat(elem.solde).toFixed(3)}{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprDetailCycle);
