import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class ImpFin extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
      listEleves: [],
      selectEleve: "",
      inscriptionId: "",
      niveau: "",
      nomFiliere: "",
      groupe: "",
      moduleEG: [],
      moduleET: [],
      moduleEP: [],
      blockNote: false,
      arrayAllData: [],
      moyenneModule: "",
      notes: [],
      noteEG:[],
      noteEP:[],
      noteET:[], 
      notefinal:[],
      annee:'',
      annees:'',
      anneefinal:'',
      rangfinal:[],
      rangfinale:[],
      r:0,
      tab:[],
      selectEleve:"",
      adresse:"",
      selectEleve:'',
      anneeScolaire:'',
      anneed:0,
      moyPFE:"",
      ids:"",
      f:"",
     
    };
  }
  


  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      
      var url = window.location.href;
      var arrayUrl = url.split("=");
      var annefarr = url.split("&annef=")[1];
    var annef=annefarr.split('&moisimp')[0];
	
	var moisimp = annefarr.split('&moisimp=')[1].split('&ann')[0];
	var anneeimpd= annefarr.split('&anneeimpd=')[1].split('&anneeimpf=')[0];
		var anneeimpf= annefarr.split('&anneeimpf=')[1].split('&moisfinimp=')[0];
		var moisimpf= annefarr.split('&moisfinimp=')[1];
	
      var arrayUrl2 = arrayUrl[1].split("&");
      var selectEleves = arrayUrl2[0];
      var l=selectEleves.length ;

     var anneeScolaire=selectEleves[l-4]+selectEleves[l-3]+selectEleves[l-2]+selectEleves[l-1];
    
     this.state.selectEleve=selectEleves.slice(0,l-4);
 this.state.anneeScolaire=  anneeScolaire;
 this.state.anneed=anneeScolaire;
 this.state.anneeimpd=anneeimpd;
  this.state.anneeimpf=anneeimpf;
    this.state.anneeimpf=anneeimpf;  
      this.state.moisimp=moisimp;
	       this.state.moisimpf= moisimpf
    
      //this.state.annees=this.state.annee ++ 
     
    if (this.state.selectEleve != "" ) {
        jQuery.ajax({
        url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/ReleveFinal.php",
        type: "POST",
        data: {
          eleveId: this.state.selectEleve,
          anneeScolaire: this.state.anneeScolaire,
        
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            notes: code_html,
            noteEG:code_html.allNote.noteEG,
            noteEP:code_html.allNote.noteEP,
            noteET:code_html.allNote.noteET,
               moyet:code_html.allNote.moyET,
            moyeg:code_html.allNote.moyEG,
            moyep:code_html.allNote.moyEP,
          });
        } ,
      });
      this.setState({
        blockNote: true,
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/final.php",
        type: "POST",
        data: {
          eleveId: this.state.selectEleve,
          anneeScolaire: this.state.anneeScolaire,
          
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            notefinal: code_html[1],
            moyenneAnnuelle:code_html[1][0].MoyModyle,
              moyenneStage:code_html[1][0].moyenneStage,
              moyennegenerale:code_html[1][0].moyenne,
              moyPFE:code_html[1][0]. moyPFE
             
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/rang.php",
        type: "POST",
        data: {
          eleveId: this.state.selectEleve,
          anneeScolaire: this.state.anneeScolaire,
          
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            rangfinale: code_html[1],
            ids:code_html[6],
          },
          ()=>
          {
          
            var tab=[];
            var tabs=[];
            var j=1;
            this.state.rangfinal=this.state.rangfinale.sort((a, b) => b.moyenne - a.moyenne)
            for (var i = 0; i < this.state.rangfinal.length; i++) {
              var moyenne = this.state.rangfinal[i].moyenne;
              if (moyenne >= 10) {
              
              }
              if (i>0){
                
              if(this.state.rangfinal[i-1].moyenne==this.state.rangfinal[i].moyenne ){
              if(tab.length>0){
                //j=tab.length
             tab=[...tab,j]
             j=j+1
             tabs=[...tabs,this.state.rangfinal[i].moyenne]
             
              }else{
                
                tab=[...tab,j]
                
              }

              }else{
                j=j+1
                tab=[...tab,j]
                tabs=[...tabs,this.state.rangfinal[i].moyenne ]
              }
            }else{
              tabs=[...tabs,this.state.rangfinal[i].moyenne ]
              tab=[...tab,j]
            }
            }console.log(tab) ;console.log("fhfhfh")
          console.log(tabs)
              
             
          this.setState({
            tab: tab,
          });

          }
          
          );
        },
      });
      this.setState({
        blockNote: true,
      });

  };
    } else {
      document.location = "/";
    }
  }

  
  
    
    affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    if (this.state.selectEleve != "" ) {
        jQuery.ajax({
        url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/ReleveFinal.php",
        type: "POST",
        data: {
          eleveId: this.state.selectEleve.value,
          anneeScolaire:  this.state.anneeScolaire,
        
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            notes: code_html,
            noteEG:code_html.allNote.noteEG,
            noteEP:code_html.allNote.noteEP,
            noteET:code_html.allNote.noteET,
          });
        } ,
      });
      this.setState({
        blockNote: true,
      });
    }}

 
    impr = () => {
    window.print();
  };
  render() {
   /* {this.state.annee>=2019?(
      this.state.f="Octobre"
            ):this.state.f="Juillet"}
*/

{this.state.annee<2022?(
      this.state.f="Octobre"
            ):this.state.f="Juillet"}


    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid" style={{ marginTop:"120px"}}>
          
          {/* /.row */}
          <div className="row">
            <div className="col-sm-11">
              <div >
                <div>
                  <div>
                    
                  </div>
                   
                </div>
                    
                {this.state.blockNote == true ? (
                  <div >
                      {this.state.notefinal.map((el,i) => (
                          <div>
                    <div style={{  paddingLeft:"10px",align:"center" ,paddingBlockEnd:"20px","fontSize": "13px"}} >
                      <div style={{ display: "flex" ,padding:"2px" }}>
                        
                          <h4 ><strong >Relevé de notes de fin de formation : </strong></h4>
                        
                     </div>
                      

                      <div style={{ display: "flex" , padding:"2px" }}>
                        
                          <strong style={{paddingRight:"8px"}}>Apprenant(e) : </strong>
                        {el.nomEleve}
                      </div>
                      
                      <div style={{ display: "flex"  , padding:"2px" }}>
                        
                          <strong style={{paddingRight:"8px"}}>Date et Lieu de Naissance : </strong>
                        
                        
                        {el.ville_naissance!="" ?(
                          el.naissance + " à " + el.adresse):
                          (el.naissance)}
                        
                      </div>
                      <div style={{ display: "flex"  , padding:"2px" }}>
                        
                        <strong style={{paddingRight:"8px"}}>CIN / PASSPORT: </strong>
                       
                          {el.numero}{" "}
                        
                      </div>
                      <div style={{ display: "flex"  , padding:"2px" }}>
                          <strong style={{paddingRight:"8px"}}>Formation : </strong>
                          {el.nomSpec=='BTS'  ? (
                           
                           <p>  Du 01 /*Octobre*/  {/*Octobre*/this.state.moisimp} { /*this.state.anneefinal-2*/this.state.anneeimpd}  au 31   {/*Octobre*/this.state.moisimpf} {/*this.state.anneefinal*/this.state.anneeimpf} </p>
                         ):( <p>  Du 01  {/*Octobre*/this.state.moisimp}  { /*this.state.anneefinal-1*/ this.state.anneeimpd}  au 31 {this.state.moisimpf} {/*this.state.annee<2022?(this.state.f="Octobre"):this.state.f="Juillet"*/} {/*this.state.anneefinal*/this.state.anneeimpf} </p>)}

                         
                        
                      </div>
                      <div style={{ display: "flex"  , padding:"2px"}}>
                        
                          <strong style={{paddingRight:"8px"}}>Specialité : </strong>
                          {el.filere}
                        
                        <div>
                <strong style={{paddingLeft: "50px"}}>Diplôme:</strong>
                    
                    {el.nomSpec}
                        </div>
                      </div>
                    </div>
                    
                    <table
          border={1}
          align="center"
          width={700}
          height={400}
        
          >
                      <thead >
                        <tr>
                          <th style={{ color: "#ac3939",textAlign:"center", fontSize:"15px"}}>Enseignement</th>
                          <th style={{display: "flex",justifyContent:"space-between", fontSize:"15px" }}><div style={{ color: "#ac3939" ,paddingLeft:"15px"}} >Module </div>      <div style={{ color: "#ac3939",paddingRight:"10px" }}> Moyenne</div></th>
                          <th style={{ color: "#ac3939",textAlign:"center", fontSize:"15px"}}>
                            Moyenne 
                          </th>
                        </tr>
                      </thead>
                      <tbody >
                      {this.state.notefinal.map((elem) => (
                          <tr >
                          <td style={{padding:"5px",fontSize: '12px'}}>Enseignement Pratique (Coef:5)</td>
                          <td style={{paddingBottom: "8px",fontSize: '12px'}}>
                          {this.state.noteEP.map((e)=>(
                               <div style={{display: "flex",justifyContent:"space-between",marginBottom: "-8px" ,"marginTop": "-15px",fontSize: '12px'}}>
                               <p style={{padding:"10px",height: "5px"}}>{e.matiere}</p>  <p style={{padding:"10px",height: "5px"}}> {e.matiereNote}</p> 
                               </div>
                              ))}
                          </td>
                            <td style={{fontSize: '12px'}}>
                        
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{paddingTop:"5px"}}>
                                 {this.state.moyep<10?(0+this.state.moyep):(
                                   this.state.moyep
                                 )}   
                                
                                 </p>
                                </div>
                             
                            </td>
                            
                          </tr>
                          ))}
                    
                          {this.state.notefinal.map((elem) => (
                          <tr style={{ height: "5px"}}>
                          <td style={{padding:"5px",fontSize: '12px'}}>Enseignement Général (Coef:1)</td>

                            <td style={{paddingBottom: "8px",paddingTop:"8px",fontSize: '12px'}}>
                            {this.state.noteEG.map((e)=>(
                               <div style={{display: "flex",justifyContent:"space-between",marginBottom: "-7px" ,"marginTop": "-11px"}}>
                               <p style={{padding:"5px" ,height: "1px"}}>{e.matiere}</p>  <p style={{padding:"8px",height: "1px"}}> {e.matiereNote}</p> 
                               </div>
                              ))}
                            </td>
                            <td  style={{fontSize: '12px'}}>
                        
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p style={{padding:"10px"}} >
                                 {this.state.moyeg<10?(0+this.state.moyeg):(this.state.moyeg)}
                                  </p>
                                </div>
                             
                            </td>
                           
                          </tr>
                          ))}
                        {this.state.notefinal.map((elem) => (
                          <tr  >
                          <td style={{padding:"5px",fontSize: '12px'}} >Enseignement Technique (Coef:2)</td>
                            <td style={{paddingBottom: "8px",paddingTop:"8px"}}>
                            {this.state.noteET.map((e)=>(
                                <div style={{display: "flex",justifyContent:"space-between",marginBottom: "-7px" ,"marginTop": "-13px",fontSize: '12px'}}>
                           <p style={{padding:"5px",height: "5px"}}>{e.matiere}</p>  <p style={{padding:"10px",height: "5px"}}> {e.matiereNote}</p> 
                           </div>
                          ))}
                            </td>
                            <td style={{fontSize: '12px'}}>
                        
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p >
                                 {this.state.moyet<10?(0+this.state.moyet):(this.state.moyet)}   
                                
                                    </p>
                                    
                                </div>
                             
                            </td>
                           
                          </tr>
                        ))}
                        
                       
                        <tr  >
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" ,
                            fontsize:"8px",padding:"10px"
                          }}>
                              Moyenne examen de fin de formation {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {this.state.notefinal.map((elem) => (
                              <p> {elem.MoyEFF} </p>
                            ))}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" ,padding:"10px"}}>
                              Moyenne annuelle {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            <p> {this.state.moyenneAnnuelle} </p>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939",padding:"10px" }}>
                              Moyenne des stages {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            <p> {this.state.moyenneStage} </p>
                          </td>
                        </tr>
                        {el.nomSpec=='BTS'  ? (
                        <tr>
                          <td></td>
                         
                          <td style={{ textAlign: "right" }}>
                          {el.nomSpec=='BTS'  ? (
                            <strong style={{ color: "#ac3939",padding:"10px" }}>
                              Moyenne PFE {" "}
                            </strong>
                             ):(<div style={{display:'none'}}></div>) }
                          </td>
                          <td style={{ textAlign: "center" }}>
                          {el.nomSpec=='BTS'  ? (
                           
                            <p> {this.state.moyPFE} </p>
                          ):(<div style={{display:'none'}}></div>)}
                          </td>
                         
 
                        </tr>
                        ):(<tr style={{display:'none'}}></tr>)}
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939",padding:"10px" }}>
                              Moyenne générale {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            <p> {this.state.moyennegenerale} </p>
                          </td>
                        </tr>
                        <tr>
                          
                          <td>
                              
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939",padding:"10px" }}>
                              Rang {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            <p>
                            
                            {this.state.rangfinal.map((elemi,inde) => 
                          
                          (elemi.eleveId == this.state.ids ?
                            this.state.tab[inde]:null
                              
                              ))} 
                           
                           </p>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" ,padding:"10px"}}>
                              Décision du jury {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {this.state.notefinal.map((elem) => (
                              <p> {elem.decision} </p>
                            ))}{" "}
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                    </div>
                    
                    ))}
                <p style={{ display: "flex" ,justifyContent:'center' ,padding:"10px"}}>
                    Cet examen du fin de formation est supervisé par le Ministère de la Formation Professionnelle et de l'emploi. 
                </p>
               <div  style={{ marginRight:"100px"}} >
                <p style={{display :"flex",justifyContent: "end"}}>Le Directeur Général</p>
                <p style={{ display: "flex" ,justifyContent:'end'}}>Taoufik CHAIRI</p>
                </div>
                <p style={{paddingLeft:"170px"}}>Tunis le, {new Date().toLocaleString("fr-FR", { day : '2-digit'})} {new Date().toLocaleString("fr-FR",{ month: "long" })} {new Date().getFullYear()}</p>
                
                <div style={{ display: "flex", justifyContent: "center" }}>
                
                    <i
                    id="print_me_not"
                    style={{ fontSize: "30px", cursor: "pointer" }}
                    onClick={this.impr}
                    class="fas fa-print"
                  ></i>
                  </div>
                  </div>
                  
                  
                  
                ) : null}
              </div>
            </div>
           
          </div>
        </div>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
       <p style={{display :"none"}}>{this.state.r=0}</p>  
         
      </div>
    );
  }
}

export default withRouter(ImpFin);