import React, { Component } from "react";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import Table from "reactstrap/lib/Table";

class ConsulterListGroupe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listGroupe: [],
      listEleve: [],
      listGroupeList: [],
      selectGroupe: "",
      listG: [
        { value: 1, label: "G1" },
        { value: 2, label: "G2" },
        { value: 3, label: "G3" },
        { value: 4, label: "G4" },
        { value: 5, label: "G5" },
        { value: 6, label: "G6" },
        { value: 7, label: "G IFORMATIQUE 1" },
        { value: 8, label: "G IFORMATIQUE 2" },
        { value: 9, label: "G IFORMATIQUE 3" },
      ],
      selectG: "",
      block: false,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mediatraining/GetGroupe.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            listGroupe: code_html,
          });
        },
      });
    }
  }

  addGroupe = (selectGroupe) => {
    if (selectGroupe != null) {
      this.setState({ selectGroupe });
    } else {
      this.setState({
        selectGroupe: "",
      });
    }
  };

  addG = (selectG) => {
    if (selectG != null) {
      this.setState({ selectG });
    } else {
      this.setState({
        selectG: "",
      });
    }
  };

  affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/ConsulterListGroupe.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        groupeId: this.state.selectGroupe,
        selectG: this.state.selectG.value,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          listGroupeList: code_html[0],
          listEleve: code_html[1],
          block: true,
        });
      },
    });
  };

  impr = (idElv, insId) => {
    var params = idElv + "-" + insId;
    window.open("http://artsmetiers.mtd-app.com/ImprCalEFF?params=" + params);
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">List Groupe</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="mb-0 form-group">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Groupe</label>
                        <Select
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addGroupe}
                          options={this.state.listGroupe}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>N° Groupe</label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.addG}
                          options={this.state.listG}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Rechercher
                    </button>
                  </div>
                </div>
                {this.state.block === true ? (
                  <div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>N° Groupe</th>
                          <th>Groupe</th>
                          <th>Apprenant</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> 1 </td>
                          <td>
                            {this.state.listGroupeList.map((el) => (
                              <tr>{el.groupe}</tr>
                            ))}
                          </td>
                          <td>
                            {this.state.listEleve.map((elem) => (
                              <tr
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <td
                                  style={{
                                    textAlign: "center",
                                    marginTop: "1vh",
                                  }}
                                >
                                  {elem.insId}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    marginTop: "1vh",
                                  }}
                                >
                                  {elem.nomEleve}
                                </td>
                                <td>
                                  <i
                                    onClick={() => this.impr(elem.idElv, elem.insId)}
                                    style={{
                                      fontSize: "20px",
                                      cursor: "pointer",
                                      color: "#ac3939",
                                      marginTop: "1vh",
                                    }}
                                    class="fas fa-print"
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConsulterListGroupe);
