import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";
import './paginate.css'

class ImprRattrapageBTS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockNote: false,
      listEleves: [],
      listMatieres: [],
      listEG: [],
      listET: [],
      listEP: [],
      filiere: "",
      niveau: "",
      groupe: "",
      annee: "",
      anneeScolaire: "",
      count: "",
      specialite: '',
      anneeScolaireFin: '',
      formation: "",
      
      date : new Date()
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href
      var arrayUrl = url.split("=");
      var params = arrayUrl[1]
      var arrayParams = params.split("-");
 
      var groupeId=arrayParams[0];
      var mois = arrayParams[1];
      var anneimp = arrayParams[2];

      if (mois == 1) {
        var nomMois = "Janvier"
    }
    else if (mois == 2) {
        var nomMois = "Février"
    }
    else if (mois == 3) {
        var nomMois = "Mars"
    }
    else if (mois == 4) {
        var nomMois = "Avril"
    }
    else if (mois == 5) {
        var nomMois = "Mai"
    }
    else if (mois == 6) {
        var nomMois = "Juin"
    }
    else if (mois == 7) {
        var nomMois = "Juillet"
    }
    else if (mois == 8) {
        var nomMois = "Aout"
    }
    else if (mois == 9) {
        var nomMois = "Septembre"
    }
    else if (mois == 10) {
        var nomMois = "Octobre"
    }
    else if (mois == 11) {
        var nomMois = "Novembre"
    }
    else if (mois == 12) {
        var nomMois = "Decembre"
    }
    this.state.moisimp=nomMois
    this.state.anneimp =anneimp 
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/ImprRattrapageBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          groupeId: groupeId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState(
            {
              listMatieres: code_html[0],
              listEG: code_html[0][0],
              listET: code_html[0][1],
              listEP: code_html[0][2],
              listEleves: code_html[1],
            },
            () => {
              var lengthEg = this.state.listEG.length + 1;
              var lengthEt = this.state.listET.length + 1;
              var lengthEp = this.state.listEP.length + 1;
              this.setState({
                spanEg: lengthEg,
                spanEt: lengthEt,
                spanEp: lengthEp,
              });
              var count = 0;
              for (var i = 0; i < this.state.listEleves.length; i++) {
                var moyenne = this.state.listEleves[i].moyenne;
                if (moyenne >= 10) {
                  count++;
                }
              }
              this.setState({
                count: count
              })
            }
          );
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoGroupeNv.php",
        type: "POST",
        data: {
          groupeId: groupeId,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            filiere: code_html.nomFiliere,
            niveau: code_html.niveau,
            groupe: code_html.groupe,
            annee: code_html.annee,
            anneeScolaire: code_html.anneeScolaire,
            specialite: code_html.typeSpec,
            formation : code_html.formation,
          },
          () => {
            var anneeS = this.state.anneeScolaire;
            var arrayUrl = anneeS.split("/");
            var anneeFin = arrayUrl[1];
            var date = this.state.date
            var year = date.getFullYear();
            this.setState({
              anneeScolaireFin: year,
            });
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div style={{ zoom: "80%" }} className="white-box">
        <div>
          <table
            border={0}
            align="center"
            cellSpacing={1}
            cellPadding={4}
            width={1100}
          >
            <tbody>
              <tr>
                <td width={546} height={54}>
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <u>Session : </u>
                    </b>
                    <span> {this.state.moisimp}</span> <span>{this.state.anneimp }</span>
                  </font>
                </td>
                {this.state.annee >= 2019 ? (
                  this.state.specialite == 1 ? (
                    <td width={535} align height={54}>
                      <b>
                        <font
                          style={{ fontSize: "14px" }}
                          face="Verdana, Arial, Helvetica, sans-serif"
                        >
                          <h3>Session de rattrapage BTS &nbsp;</h3>
                        </font>
                      </b>
                    </td>
                  ) : (
                    <td width={535} align height={54}>
                      <b>
                        <font
                          style={{ fontSize: "14px" }}
                          face="Verdana, Arial, Helvetica, sans-serif"
                        >
                          <h3>Session de rattrapage BTP &nbsp;</h3>
                        </font>
                      </b>
                    </td>
                  )
                ) : (
                  <td width={535} align height={54}>
                    <b>
                      <font
                        style={{ fontSize: "14px" }}
                        face="Verdana, Arial, Helvetica, sans-serif"
                      >
                        <h3>Session de rattrapage BTS &nbsp;</h3>
                      </font>
                    </b>
                  </td>
                )}
              </tr>
              <tr>
                <td height={42}>
                  {" "}
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    {" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <u>Spécialité:</u>
                    </b>
                    &nbsp;{this.state.filiere}
                  </font>
                </td>
                <td width={535} align="left">
                  <b>
                    <font
                      style={{ fontSize: "14px" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      Année Scolaire: {this.state.anneeScolaire}
                    </font>
                  </b>
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Niveau:</u>
                    </b>
                    &nbsp;{this.state.niveau} {this.state.formation}
                  </font>{" "}
                </td>
                <td width={535} align="left">
                  {" "}
                  <b>
                    <u>Groupe:</u>
                  </b>
                  &nbsp;{this.state.groupe}
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Table>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                    color: "black",
                    verticalAlign: "middle",
                  }}
                >
                  N°
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                    color: "black",
                    verticalAlign: "middle",
                  }}
                >
                  Apprenant
                </th>
                <th
                  colspan={this.state.spanEg}
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                    color: "black",
                    verticalAlign: "middle",
                  }}
                >
                  Moyenne Enseignement Général
                </th>
                <th
                  colspan={this.state.spanEt}
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                    color: "black",
                    verticalAlign: "middle",
                  }}
                >
                  Moyenne Enseignement Technique
                </th>
                <th
                  colspan="2"
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                    color: "black",
                    verticalAlign: "middle",
                  }}
                >
                  Moyenne Enseignement Pratique
                </th>
                <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                    color: "black",
                    verticalAlign: "middle",
                  }}
                >
                  Moyenne Module{" "}
                </th>
                {/* <th
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    backgroundColor: "#C0C0C0",
                    color: "black",
                  }}
                >
                  Rang
                </th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                ></td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Module
                </td>
                {this.state.listEG.map((el) => (
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {el}
                  </td>
                ))}
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    backgroundColor: "#C0C0C0",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {" "}
                  Moyenne EG{" "}
                </td>
                {this.state.listET.map((el) => (
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {el}
                  </td>
                ))}
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    backgroundColor: "#C0C0C0",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {" "}
                  Moyenne ET{" "}
                </td>
                {/* {this.state.listEP.map((el) => ( */}
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  Travaux Pratiques
                </td>
                {/* ))} */}
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    backgroundColor: "#C0C0C0",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {" "}
                  Moyenne EP{" "}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                >
                  M.Module
                </td>
                {/* <td
                  style={{
                    textAlign: "center",
                    border: "solid",
                    color: "black",
                  }}
                ></td> */}
              </tr>
              {this.state.listEleves.map((elem, index) => (
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {elem.nomEleve}
                  </td>
                  {elem.allNote.noteEG.map((ele) => (
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {ele.matiereNote}
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                      backgroundColor: "#C0C0C0",
                    }}
                  >
                    {" "}
                    {elem.allNote.moyEG}{" "}
                  </td>
                  {elem.allNote.noteET.map((ele) => (
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {ele.matiereNote}
                    </td>
                  ))}
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                      backgroundColor: "#C0C0C0",
                    }}
                  >
                    {" "}
                    {elem.allNote.moyET}{" "}
                  </td>
                  {/* {elem.allNote.noteEP.map((ele) => ( */}
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {elem.allNote.moyEP}{" "}
                  </td>
                  {/* ))} */}
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                      backgroundColor: "#C0C0C0",
                    }}
                  >
                    {" "}
                    {elem.allNote.moyEP}{" "}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                      backgroundColor: "#C0C0C0",
                    }}
                  >
                    {" "}
                    {elem.MoyModyle}{" "}
                  </td>
                  {/* <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    {index + 1}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* Deuxieme Tableau */}
        <div style={{ marginTop: "60%" }}>
          <table
            border={0}
            align="center"
            cellSpacing={1}
            cellPadding={4}
            width={1100}
          >
            <tbody>
              <tr>
                <td width={546} height={54}>
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <u>Session : </u>
                    </b>
                    <span> {this.state.moisimp}</span> <span>{this.state.anneimp }</span>
                  </font>
                </td>
                {this.state.annee >= 2019 ? (
                  this.state.specialite == 1 ? (
                    <td width={535} align height={54}>
                      <b>
                        <font
                          style={{ fontSize: "14px" }}
                          face="Verdana, Arial, Helvetica, sans-serif"
                        >
                          <h3>Session de rattrapage BTS &nbsp;</h3>
                        </font>
                      </b>
                    </td>
                  ) : (
                    <td width={535} align height={54}>
                      <b>
                        <font
                          style={{ fontSize: "14px" }}
                          face="Verdana, Arial, Helvetica, sans-serif"
                        >
                          <h3>Session de rattrapage BTP &nbsp;</h3>
                        </font>
                      </b>
                    </td>
                  )
                ) : (
                  <td width={535} align height={54}>
                    <b>
                      <font
                        style={{ fontSize: "14px" }}
                        face="Verdana, Arial, Helvetica, sans-serif"
                      >
                        <h3>Session de rattrapage BTS &nbsp;</h3>
                      </font>
                    </b>
                  </td>
                )}
              </tr>
              <tr>
                <td height={42}>
                  {" "}
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    {" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      <u>Spécialité:</u>
                    </b>
                    &nbsp;{this.state.filiere}
                  </font>
                </td>
                <td width={535} align="left">
                  <b>
                    <font
                      style={{ fontSize: "14px" }}
                      face="Verdana, Arial, Helvetica, sans-serif"
                    >
                      Année Scolaire: {this.state.anneeScolaire}
                    </font>
                  </b>
                </td>
              </tr>
              <tr>
                <td height={37}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  >
                    <b>
                      <u>Niveau:</u>
                    </b>
                    &nbsp;{this.state.niveau} {this.state.formation}
                  </font>{" "}
                </td>
                <td width={535} align="left">
                  {" "}
                  <b>
                    <u>Groupe:</u>
                  </b>
                  &nbsp;{this.state.groupe}
                  <font
                    style={{ fontSize: "14px" }}
                    face="Verdana, Arial, Helvetica, sans-serif"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {this.state.specialite == 1 ? (
            <Table>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    N°
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Apprenant
                  </th>
                  <th
                    colspan="4"
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                    }}
                  >
                    Moyenne EFF
                  </th>
                  <th
                    colspan="8"
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Moyenne Stage
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                    }}
                  >
                    Moyenne PFE
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Moyenne Générale
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Rang
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Décision
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  ></td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Module
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    EG
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    ET
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    EP
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      backgroundColor: "#C0C0C0",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Moy EFF
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 1
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 2
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 3
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 4
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 5
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 6
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 7
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      backgroundColor: "#C0C0C0",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    M.Stage
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      backgroundColor: "#C0C0C0",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    M.PFE
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    M.Générale
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Rang
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Décision
                  </td>
                </tr>
                {this.state.listEleves.map((elem, index) => (
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {elem.nomEleve}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {elem.EG}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {elem.ET}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {elem.EP}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        backgroundColor: "#C0C0C0",
                        color: "black",
                      }}
                    >
                      {elem.MoyEFF}
                    </td>
                    {elem.stage.map((obj) => (
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {obj}
                      </td>
                    ))}
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        backgroundColor: "#C0C0C0",
                        color: "black",
                      }}
                    >
                      {elem.moyenneStage}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        backgroundColor: "#C0C0C0",
                        color: "black",
                      }}
                    >
                      {elem.moyPFE}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {" "}
                      {elem.moyenne}{" "}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        color: "black",
                        border: "solid",
                        width: "10px",
                      }}
                    >
                      <input
                        style={{ border: "none", whiteSpace: "normal" }}
                        type="text"
                        defaultValue={elem.decision}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    N°
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Apprenant
                  </th>
                  <th
                    colspan="4"
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                    }}
                  >
                    Moyenne EFF
                  </th>
                  <th
                    colspan="5"
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Moyenne Stage
                  </th>
                  {/* <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                    }}
                  >
                    Moyenne PFE
                  </th> */}
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Moyenne Générale
                  </th>
                  {/* <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Rang
                  </th> */}
                  <th
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#C0C0C0",
                      color: "black",
                      verticalAlign: "middle",
                    }}
                  >
                    Décision
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  ></td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Module
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    EG
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    ET
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    EP
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      backgroundColor: "#C0C0C0",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Moy EFF
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 1
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 2
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 3
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 4
                  </td>
                  {/* <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 5
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Note 6
                  </td> */}
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      backgroundColor: "#C0C0C0",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    M.Stage
                  </td>
                  {/* <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      backgroundColor: "#C0C0C0",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    M.PFE
                  </td> */}
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    M.Générale
                  </td>
                  {/* <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Rang
                  </td> */}
                  <td
                    style={{
                      textAlign: "center",
                      border: "solid",
                      color: "black",
                    }}
                  >
                    Décision
                  </td>
                </tr>
                {this.state.listEleves.map((elem, index) => (
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {elem.nomEleve}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {elem.EG}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {elem.ET}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {elem.EP}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        backgroundColor: "#C0C0C0",
                        color: "black",
                      }}
                    >
                      {elem.MoyEFF}
                    </td>
                    {elem.stage.map((obj) => (
                      <td
                        style={{
                          textAlign: "center",
                          border: "solid",
                          color: "black",
                        }}
                      >
                        {obj}
                      </td>
                    ))}
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        backgroundColor: "#C0C0C0",
                        color: "black",
                      }}
                    >
                      {elem.moyenneStage}
                    </td>
                    {/* <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        backgroundColor: "#C0C0C0",
                        color: "black",
                      }}
                    >
                      {elem.moyPFE}
                    </td> */}
                    <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {" "}
                      {elem.moyenne}{" "}
                    </td>
                    {/* <td
                      style={{
                        textAlign: "center",
                        border: "solid",
                        color: "black",
                      }}
                    >
                      {index + 1}
                    </td> */}
                    <td
                      style={{
                        textAlign: "center",
                        color: "black",
                        border: "solid",
                        width: "10px",
                      }}
                    >
                      <input
                        style={{ border: "none", whiteSpace: "normal" }}
                        type="text"
                        defaultValue={elem.decision}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <div style={{ textAlign: "center" }} className="row">
             <h3>
              Après avoir pris connaissances des notes de la liste
              récapitulative ci-jointe déclarons admis sur un total de &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; candidats présents à l'examen les
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; dont les noms suivent par ordre
              de classement
            </h3> 
            <div style={{ display: "flex", justifyContent: "center" }}>
               <div>
                <h2 style={{ fontWeight: "bold" }}>Signature</h2>
                <h2 style={{ fontWeight: "bold" }}>Président Jury</h2>
              </div> 
              <div style={{ paddingLeft: "30%" }}>
                <h2 style={{ fontWeight: "bold" }}>Signature</h2>
                <h2 style={{ fontWeight: "bold" }}>Directeur Général</h2>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <i
              id="print_me_not"
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={this.print}
              class="fas fa-print"
            ></i>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprRattrapageBTS);
