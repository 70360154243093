import React, { Component } from "react";
import jQuery from "jquery";
import "./paginate.css";
import DataTable, { createTheme } from 'react-data-table-component';

class Imprfrais extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        data: [],
        columns: [
            {
                name: 'Apprenant',
                selector: 'nomComplet',
                sortable: false,
                center: true,
                style: { width: '20%' }
            },
            
            {
                name: 'Montant',
                selector: 'montant',
                sortable: false,
                center: true,
                style: { width: '15%' }
            },
            {
                name: 'Date reglement',
                selector: 'date',
                sortable: false,
                center: true,
                style: { width: '10%' }
            },
            {
                name: 'Type paiement',
                selector: 'mode',
                sortable: false,
                center: true,
                style: { width: '10%' }
            },
           
        
        
        ],
      moisExist: "",
      nomMois: "",
      arrayEnsNonPayer: [],
      disabledEspece: false,
      disabledCheque: false,
      totalAPayer: 0,
      totalACompte: 0,
      totalEsp: 0,
      totalChec: 0,
      totalReste: 0,
      today: "",
    };
  }


  componentDidMount() {
    if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var anneeScolaire = userPermissions[0].anneeScolaire
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/imAutreFrais.php',
            type: 'POST',
            data: {
                query: '',
                limit: this.state.nbrEltperPage,
                page: 1,
                anneeScolaire: anneeScolaire
            },
            dataType: 'json',
            success: (code_html, statut) => {
                console.log(code_html)
                this.setState({
                    nombreTotalElement: code_html.total,
                    data: code_html.data,
                    pending: false
                });
            }
        })
        
    }else {
            localStorage.clear();
            document.location = '/'
        }
   
}

  impr = () => {
    window.print();
  };    

  render() {
    return (
      <div className="white-box">
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          Liste de Tous les Apprenants {" "}
        </h4>
        <DataTable
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    //pagination={true}
                                    paginationServer={true}
                                    onChangePage={(page, totalRows) => this.newElement(page, totalRows)}
                                    paginationTotalRows={this.state.nombreTotalElement}
                                    paginationPerPage={this.state.nbrEltperPage}
                                   
                                />
       
        
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.impr}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default Imprfrais;



