import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import './paginate.css'

class ImprListNonPaye extends Component {
  constructor(props) {
    super(props);
    this.state = {
    etatapp:"",
    apprenant:"",
    groupe:"",
    nameg:"",
    name:"",
      list: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var url = window.location.href;
      var array = url.split("=");
      var params = array[1];
      var arrayInfo = params.split("/");
      var idEleve= arrayInfo[0];
      var idEtat = arrayInfo[1];
      var idModPay = arrayInfo[2];
      var idGroup = arrayInfo[3];
      var nomEleve=arrayInfo[4];
      var nomGroup=arrayInfo[5]
     
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      this.state.etatapp=idEtat ;
      this.state.groupe=idGroup;
      this.state.apprenant=idEleve;
      this.state.name=nomEleve.replace("%20"," ");
      this.state.nameg=nomGroup
           
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/ReglementsAppSearchimp.php',
                type: 'POST',
                data: {
                    idEleve: idEleve,
                    idEtat: idEtat,
                    idModPay: idModPay,
                    idGroup: idGroup,
                    anneeScolaire: anneeScolaire,
                    limit: this.state.nbrEltperPage,
                    page: 1
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        list: code_html,
                        
                        pending: false
                    });
                 
                }
            })
        
  
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        {this.state.etatapp!=""?(
        <div style={{ textAlign: "center" }} className="row">
          <div className="form-group">
            {this.state.apprenant!=""?(
            <label style={{ fontWeight: "bold", color: 'black' ,padding:"3px"}}>Tranche Apprenant { this.state.name}</label>
           
            ):(
              <label style={{ fontWeight: "bold", color: 'black',padding:"3px" }}>  Listes des aprenants du Groupe { this.state.nameg }:</label>
            )}
             {this.state.etatapp==1?(
              <label style={{ fontWeight: "bold", color: 'black',padding:"3px" }}> Régle</label>
            ):(<label style={{ fontWeight: "bold", color: 'black',padding:"3px" }}>Non Régle</label>)}
             <div style={{ display: "flex", justifyContent: "center" }}>
       
       <table style={{ border: "solid", marginBottom: "0", width: "100%", borderBottom: 'none', borderTop: 'none', color: 'black' }}>
         <thead>
           <tr>
           <th
               style={{
                 textAlign: "center",
                 border: "solid",
                 color: 'black',
                 width: '3.5%'
               }}
             >
               NumInscrip
             </th>
           
             <th
               style={{
                 textAlign: "center",
                 border: "solid",
                 color: 'black',
                 width: '7.5%'
               }}
             >
               Apprenant(e)
             </th>
            { this.state.etatapp =="1"?(
             <th
               style={{
                 textAlign: "center",
                 border: "solid",
                 color: 'black',
                 width: '96.5%'
               }}
             >
              ( Tranche  Reglé)
             </th>):(
              <th
              style={{
                textAlign: "center",
                border: "solid",
                color: 'black',
                width: '96.5%'
              }}
            >
             ( Tranche Non Reglé/montant)
            </th>
             )}
           </tr>
         </thead>
         <tbody>
           {this.state.list.map((obj) => (
             <tr>
               <td
                 style={{
                   border: "solid",
                   textAlign: 'center',
                   color: 'black',
                   borderTop: 'none',
                   width: '3.5%'
                 }}
               >
                 {" "}
                 {obj.numInscrip}{" "}
               </td>{" "}
               <td
                 style={{
                   border: "solid",
                   textAlign: 'center',
                   color: 'black',
                   borderTop: 'none',
                   width: '3.5%'
                 }}
               >
                 {" "}
                 {obj.apprenant}{" "}
               </td>{" "}
               <td
                 style={{
                   border: "solid",
                   textAlign: 'center',
                   color: 'black',
                   borderTop: 'none',
                   width: '7.5%'
                 }}
               >
               {obj.reglInscrip.map((ele) => (
               this.state.etatapp =="1" ?(
             <td>
               
               <strong> Tranch {ele.numTrRegle } : </strong>{ ele.etat } </td>):(
                  <td>
                <strong>Tranche N° {ele.numTrRegle } : </strong> {Number.parseFloat(ele.mntNonRegle).toFixed(3)}
                  </td>

                )
               ))}</td>
               
             </tr>
           ))}
         </tbody>
       </table>
       
     </div>
          </div>
        </div>
        
        ):(
          <div style={{ textAlign: "center" }} className="row">
          <div className="form-group">
            {this.state.apprenant!=""?(
            <label style={{ fontWeight: "bold", color: 'black' }}>Tranche Reglé/Non Reglé du apprenant{this.state.name}</label>):(
              <label style={{ fontWeight: "bold", color: 'black' }}> Tranche Reglé/Non Reglé  du Groupe {this.state.nameg}</label>
            )}
             <div style={{ display: "flex", justifyContent: "center" }}>
       
       <table style={{ border: "solid", marginBottom: "0", width: "100%", borderBottom: 'none', borderTop: 'none', color: 'black' }}>
         <thead>
           <tr>
           <th
               style={{
                 textAlign: "center",
                 border: "solid",
                 color: 'black',
                 width: '3.5%'
               }}
             >
               numInscrip
             </th>
           
             <th
               style={{
                 textAlign: "center",
                 border: "solid",
                 color: 'black',
                 width: '3.5%'
               }}
             >
               apprenant(e)
             </th>
             <th
               style={{
                 textAlign: "center",
                 border: "solid",
                 color: 'black',
                 width: '96.5%'
               }}
             >
              ( Tranche  )
             </th>
           </tr>
         </thead>
         <tbody>
           {this.state.list.map((obj) => (
             <tr>
               <td
                 style={{
                   border: "solid",
                   textAlign: 'center',
                   color: 'black',
                   borderTop: 'none',
                   width: '3.5%'
                 }}
               >
                 {" "}
                 {obj.numInscrip}{" "}
               </td>{" "}
               <td
                 style={{
                   border: "solid",
                   textAlign: 'center',
                   color: 'black',
                   borderTop: 'none',
                   width: '3.5%'
                 }}
               >
                 {" "}
                 {obj.apprenant}{" "}
               </td>{" "}
               <td
                 style={{
                   border: "solid",
                   textAlign: 'center',
                   color: 'black',
                   borderTop: 'none',
                   width: '3.5%'
                 }}
               >
               {obj.reglInscrip.map((ele) => (
                ele.etat=="Non Réglé" ?(
                  <td>
                   
                    
                     <strong>Tranche N°:{ele.numTrRegle} </strong> {ele.etat}:{Number.parseFloat(ele.mntNonRegle).toFixed(3)}</td>):(
 
                       <td>
                    <strong>Tranche N° {ele.numTrRegle} :</strong> {ele.etat}
                       </td>
  
                     )
                  

          
               ))}</td>
               
             </tr>
           ))}
         </tbody>
       </table>
       
     </div>
          </div>
        </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprListNonPaye);
