import React, { Component } from "react";
import jQuery from "jquery";
import Table from "reactstrap/lib/Table";
import { withRouter } from "react-router-dom";

class ModifNotePFE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eleve: "",
      arrayNote: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var idEleve = this.props.location.state.idEleve;
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/GetElevePfeById.php",
        type: "POST",
        data: {
          idEleve: idEleve,
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            eleve: code_html,
            arrayNote: code_html.note,
          });
        },
      });
    }
  }

  changeNote = (indexOfColunm, event) => {
    var arrayNote = this.state.arrayNote;
    arrayNote[indexOfColunm].notePfe = event;
    this.setState({ arrayNote: arrayNote });
  };

  modif = () => {
    var idEleve = this.props.location.state.idEleve;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/ModifNotePFE.php",
      type: "POST",
      data: {
        arrayNote: this.state.arrayNote,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/ConsulterNotePFE");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Modification note PFE BTS</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Eleve</th>
                        <th style={{ textAlign: 'center' }}>Note PFE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td style={{ textAlign: 'center' }}>{this.state.eleve.nomEleve}</td>
                      <td style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {this.state.arrayNote.map((el, indexOfColunm) => (
                          <p>
                            <input
                              type="text"
                              value={el.notePfe}
                              onChange={(e) =>
                                this.changeNote(indexOfColunm, e.target.value)
                              }
                            />
                          </p>
                        ))}
                      </td>
                    </tbody>
                  </Table>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModifNotePFE);
