import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { Link, withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

class MoyenneAdmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listGroupe: [],
      selectGroupe: "",
      releveNote: [],
      blockNote: false,
      releveeByGroupe: [],
      filiere: "",
      niveau: "",
      groupe: "",
      arrayEleve: [],
      disabled : false
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetGroupeNonBTS.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listGroupe: code_html,
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  addGroupe = (selectGroupe) => {
    if (selectGroupe != null) {
      this.setState({ selectGroupe }, () => {});
    } else {
      this.setState({
        selectGroupe: "",
      });
    }
  };

  affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/MoyenneAdmission.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        groupeId: this.state.selectGroupe.value,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          blockNote: true,
          listEleves: code_html,
        });
      },
    });
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mediatraining/GetInfoGroupe.php",
      type: "POST",
      data: {
        groupeId: this.state.selectGroupe.value,
        anneeScolaire: anneeScolaire,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          filiere: code_html.nomFiliere,
          niveau: code_html.niveau,
          groupe: code_html.groupe,
        });
      },
    });
  };

  cocheEleve = (e, index, el) => {
    var eleveId = el.eleveId;
    if (e.target.checked === true) {
      var array = this.state.arrayEleve;
      array.push(eleveId);
      this.setState({
        arrayEleve: array,
      });
    } else {
      var array = this.state.arrayEleve;
      array.splice(index, 2);
      this.setState({
        arrayEleve: array,
      });
    }
  };

  filtrer = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mediatraining/FiltrerRattrapage.php",
      type: "POST",
      data: {
        anneeScolaire: anneeScolaire,
        groupeId: this.state.selectGroupe.value,
        eleve : this.state.arrayEleve
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Rattrapage");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };


  print = () => {
    var groupeId = this.state.selectGroupe.value;
    window.open(
      "http://artsmetiers.mtd-app.com/ImprMoyenneAdmission?groupeId=" + groupeId
      //"http://localhost:3000/ImprMoyenneAdmission?groupeId=" + groupeId
    );
  };

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Moyenne d'admission </h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Groupe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addGroupe}
                        options={this.state.listGroupe}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Rechercher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.print}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
                {this.state.blockNote == true ? (
                  <div>
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                        className="row"
                      >
                        <label>Examen fin de formation</label>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            style={{ display: "flex" }}
                            className="form-group"
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Spécialité :{" "}
                            </label>
                            <label style={{ paddingLeft: "5px" }}>
                              {" "}
                              {this.state.filiere}{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            style={{ display: "flex" }}
                            className="form-group"
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Niveau :{" "}
                            </label>
                            <label style={{ paddingLeft: "5px" }}>
                              {this.state.niveau}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            style={{ display: "flex" }}
                            className="form-group"
                          >
                            <label style={{ fontWeight: "bold" }}>
                              Groupe :
                            </label>
                            <label style={{ paddingLeft: "5px" }}>
                              {" "}
                              {this.state.groupe}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            N°{" "}
                          </th>
                          <th style={{ textAlign: "left", color: "#ac3939" }}>
                            {" "}
                            Apprenant{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Trimestre 1{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Trimestre 2{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Trimestre 3{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Moy.Annuelle{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Examen fin d'année{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Stage{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Moy.Admission{" "}
                          </th>
                          <th style={{ textAlign: "center", color: "#ac3939" }}>
                            {" "}
                            Rang{" "}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listEleves.map(
                          (el, indexOfLigne, index) => (
                            <tr>
                              <td>{indexOfLigne + 1}</td>
                              <td> {el.nomEleve} </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.tr1}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.tr2}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.tr3}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {el.moyAnnuelle}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.examen}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.stage}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {el.moyenne}
                                </p>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {indexOfLigne + 1}
                              </td>
                              <td>
                                <Checkbox
                                  onChange={(e) =>
                                    this.cocheEleve(e, index, el)
                                  }
                                  disabled={this.state.disabled}
                                />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <button
                        style={{ borderRadius: "5px" }}
                        type="button"
                        className="btn btn-success"
                        onClick={this.filtrer}
                      >
                        Filtrer
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MoyenneAdmission);
