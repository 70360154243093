import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import './paginate.css'

class ImprProfilApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prenom: "",
      nom: "",
      cin: "",
      delivre: "",
      dateNais: "",
      villeNais: "",
      mobile: "",
      insId: "",
      nomPere: "",
      telPere: "",
      adrPere: "",
      nomFiliere: "",
      niveau: "",
      groupe: "",
      annee: "",
      scolaire: "",
      notes: [],
      spec: "",
      reglements: [],
      modePayTrch: "",
      //   frais
      colSpanFrais: "",
      // anuelle
      colSpanAn: "",
      // mensuelle
      colSpanMensTr1: "",
      colSpanMensTr2: "",
      colSpanMensTr3: "",
      colSpanMensTr4: "",
      colSpanMensTr5: "",
      colSpanMensTr6: "",
      colSpanMensTr7: "",
      colSpanMensTr8: "",
      colSpanMensTr9: "",
      colSpanMensTr10: "",
      // 3 tranches
      colSpanTroisTr1: "",
      colSpanTroisTr2: "",
      colSpanTroisTr3: "",
      // 2 tranche
      colSpanDeuxTr1: "",
      colSpanDeuxTr1: "",
      absEleve: { absMois: [], sum: '' },
      photo: "",
      anneeScolaireSuiv: "",
      fb: ""
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      var url = window.location.href
      var arrayUrl = url.split("=");
      var eleveId = arrayUrl[1]
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoNoteApp.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          eleveId: eleveId
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            notes: code_html,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetAssiduiteApp.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          eleveId: eleveId
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            absEleve: code_html,
          });
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/GetInfoPayApp.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          eleveId: eleveId
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState(
            {
              reglements: code_html,
              modePayTrch: code_html[0].modePayTrch,
              colSpanFrais: code_html[0].reglFrais.length,
            },
            () => {
              if (
                this.state.modePayTrch == "17" ||
                this.state.modePayTrch == "21"
              ) {
                var sizeTr1 = code_html[0].reglInscrip[0].detailRegl;
                this.setState({
                  colSpanAn: sizeTr1.length,
                });
              } else if (this.state.modePayTrch == "18") {
                var sizeTr1 = code_html[0].reglInscrip[0].detailRegl.length;
                var sizeTr2 = code_html[0].reglInscrip[1].detailRegl.length;
                var sizeTr3 = code_html[0].reglInscrip[2].detailRegl.length;
                var sizeTr4 = code_html[0].reglInscrip[3].detailRegl.length;
                var sizeTr5 = code_html[0].reglInscrip[4].detailRegl.length;
                var sizeTr6 = code_html[0].reglInscrip[5].detailRegl.length;
                var sizeTr7 = code_html[0].reglInscrip[6].detailRegl.length;
                var sizeTr8 = code_html[0].reglInscrip[7].detailRegl.length;
                var sizeTr9 = code_html[0].reglInscrip[8].detailRegl.length;
                var sizeTr10 = code_html[0].reglInscrip[9].detailRegl.length;
                this.setState(
                  {
                    colSpanMensTr1: sizeTr1,
                    colSpanMensTr2: sizeTr2,
                    colSpanMensTr3: sizeTr3,
                    colSpanMensTr4: sizeTr4,
                    colSpanMensTr5: sizeTr5,
                    colSpanMensTr6: sizeTr6,
                    colSpanMensTr7: sizeTr7,
                    colSpanMensTr8: sizeTr8,
                    colSpanMensTr9: sizeTr9,
                    colSpanMensTr10: sizeTr10,
                  },
                  () => {
                    console.log(this.state.colSpanMensTr1);
                  }
                );
              } else if (this.state.modePayTrch == "19") {
                var sizeTr1 = code_html[0].reglInscrip[0].detailRegl;
                var sizeTr2 = code_html[0].reglInscrip[1].detailRegl;
                var sizeTr3 = code_html[0].reglInscrip[2].detailRegl;
                this.setState({
                  colSpanTroisTr1: sizeTr1.length,
                  colSpanTroisTr2: sizeTr2.length,
                  colSpanTroisTr3: sizeTr3.length,
                });
              } else if (this.state.modePayTrch == "20") {
                var sizeTr1 = code_html[0].reglInscrip[0].detailRegl;
                var sizeTr2 = code_html[0].reglInscrip[1].detailRegl;
                this.setState({
                  colSpanDeuxTr1: sizeTr1.length,
                  colSpanDeuxTr2: sizeTr2.length,
                });
              }
            }
          );
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/GetInfoApp.php",
        type: "POST",
        data: {
          anneeScolaire: anneeScolaire,
          eleveId: eleveId
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            prenom: code_html.prenom,
            nom: code_html.nom,
            cin: code_html.numero,
            delivre: code_html.delivre,
            dateNais: code_html.dateNais,
            villeNais: code_html.villeNais,
            mobile: code_html.mobile,
            insId: code_html.insId,
            nomPere: code_html.nomPere,
            telPere: code_html.telPere,
            adrPere: code_html.adrPere,
            nomFiliere: code_html.nomFiliere,
            niveau: code_html.niveau,
            groupe: code_html.groupe,
            annee: code_html.annee,
            scolaire: code_html.scolaire,
            spec: code_html.spec,
            photo: code_html.photo,
            fb: code_html.fb,
            anneeScolaireSuiv: parseInt(anneeScolaire) + 1
          });
        },
      });
    } else {
      document.location = "/";
    }
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div className="white-box">
        <table style={{ color: 'black' }} width={850} border={0} align="center">
          <tbody>
            <tr>
              <td style={{ color: 'black' }} height={164}>
                <table
                  border={0}
                  align="center"
                  cellSpacing={1}
                  cellPadding={4}
                  width={700}
                >
                  <tbody>
                    <tr>
                      <td style={{ color: 'black' }} height={95} colSpan={2}>
                        <table
                          border={0}
                          align="left"
                          cellSpacing={1}
                          cellPadding={4}
                          width={738}
                        >
                          <tbody>
                            <tr align="left">
                              <td style={{ color: 'black' }} width={177} align="left">
                                <img
                                  src="images/art_metier.png"
                                  alt="recu"
                                  width={268}
                                  height={113}
                                />
                              </td>
                              <td style={{ color: 'black' }} width={542} align="right">
                                <font
                                  face="Verdana, Arial, Helvetica, sans-serif"
                                  size={2}
                                >
                                  <br />
                                  8, rue borj Bourguiba, le Passage 1001- Tunis
                                  <br /> <br />
                                  Tél. 71 240 204 /52 875 028 <br />
                                  <br />
                                </font>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>{" "}
                    <tr>
                      <td style={{ color: 'black' }} align="left">
                        <strong>Numéro&nbsp;Inscription: </strong>&nbsp;{" "}
                        {this.state.insId}
                      </td>
                      <td style={{ color: 'black' }} width={500} align="right">
                        <font
                          face="Verdana, Arial, Helvetica, sans-serif"
                          size={2}
                        >
                          <strong>Année Scolaire : </strong>{" "}
                          {this.state.scolaire}
                        </font>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ color: 'black' }}>
                <strong>
                  {" "}
                  <em>
                    <u>Information Apprenant</u>
                  </em>
                </strong>
              </td>
            </tr>
            <tr>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Nom :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.nom}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Prenom :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.prenom}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>CIN :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.cin}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Emis le :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.delivre}</p>
                </div>
              </div>
            </tr>
            <tr>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Date de naissance :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.dateNais}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Lieu :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.villeNais}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>GSM :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.mobile}</p>
                </div>
              </div>
            </tr>
            <tr>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Tuteur :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}></p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Qualité :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}></p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Nom  :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}>{this.state.nomPere}</p>
                </div>
              </div>
            </tr>
            <tr>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}>Tel Bureau :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}> {this.state.telPere} </p>
                </div>
              </div>
            </tr>
            <tr>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}> Adresse :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}> {this.state.adrPere} </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: 'bold', color: 'black' }}> fb :</p>
                  <p style={{ paddingLeft: '5px', color: 'black' }}> {this.state.fb} </p>
                </div>
              </div>
            </tr>
            <tr align="left">
              <td width={550}>
                <table width={850} border={0}>
                  <tbody>
                    <tr>
                      <td style={{ color: 'black' }} width={572}>
                        <strong>Formation: </strong>&nbsp; De Octobre{" "}
                        {this.state.annee} à Juillet {this.state.anneeScolaireSuiv}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </td>
                      <td style={{ color: 'black' }} align="left" width={268} rowSpan={4}>
                        <table
                          align="left"
                          border={1}
                          cellSpacing={1}
                          cellPadding={4}
                          bgcolor="#000000"
                        >
                          <tbody>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td>
                                <img
                                  height="150px"
                                  width="200px"
                                  src={"https://localhost:3000/images/Dossier_image/" + this.state.photo}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>{" "}
                    <tr>
                      <td style={{ color: 'black' }}>
                        <strong>Spécialité: </strong>&nbsp;{" "}
                        {this.state.nomFiliere}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: 'black' }}>
                        <strong>Niveau: </strong>&nbsp; {this.state.niveau}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: 'black' }}>
                        <strong>Groupe: </strong>&nbsp; {this.state.groupe}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ color: 'black' }}>
                <strong>
                  {" "}
                  <em>
                    <u>Paiements Effectués</u>
                  </em>{" "}
                </strong>
              </td>
            </tr>
            <tr>
              <td style={{ color: 'black' }}>
                {this.state.modePayTrch == "17" ||
                  this.state.modePayTrch == "21" ? (
                  <table
                    align="left"
                    border={1}
                    cellSpacing={1}
                    cellPadding={4}
                    bgcolor="#000000"
                  >
                    <tbody>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          &nbsp;
                        </td>
                        <td
                          colSpan={this.state.colSpanFrais}
                          width={30}
                          align="left"
                        >
                          frais d'inscription
                        </td>
                        <td colSpan={this.state.colSpanAn}>1</td>
                      </tr>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          Montant
                        </td>

                        {this.state.reglements.map((el) =>
                          el.reglFrais.map((elem) => <td> {elem.mntPayer} </td>)
                        )}
                        {this.state.reglements.map((el) =>
                          el.reglInscrip.map((elem) => (
                            <td>
                              {" "}
                              {elem.detailRegl.map((obj) => obj.totalPay)}{" "}
                            </td>
                          ))
                        )}
                      </tr>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          Size: "14px",
                        }}
                      >
                        <td>Rèçu N°:</td>
                        {this.state.reglements.map((el) =>
                          el.reglFrais.map((elem) => <td> {elem.numRecu} </td>)
                        )}
                        {this.state.reglements.map((el) =>
                          el.reglInscrip.map((elem) => (
                            <td> {elem.numRecu} </td>
                          ))
                        )}
                      </tr>
                    </tbody>
                  </table>
                ) : null}
                {this.state.modePayTrch == "18" ? (
                  <table
                    align="left"
                    border={1}
                    cellSpacing={1}
                    cellPadding={4}
                    bgcolor="#000000"
                  >
                    <tbody>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          &nbsp;
                        </td>
                        <td
                          colSpan={this.state.colSpanFrais}
                          width={30}
                          align="left"
                        >
                          frais d'inscription
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr1}
                        >
                          1
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr2}
                        >
                          2
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr3}
                        >
                          3
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr4}
                        >
                          4
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr5}
                        >
                          5
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr6}
                        >
                          6
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr7}
                        >
                          7
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr8}
                        >
                          8
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr9}
                        >
                          9
                        </td>
                        <td
                          style={{ width: "9%" }}
                          colSpan={this.state.colSpanMensTr10}
                        >
                          10
                        </td>
                      </tr>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          Montant
                        </td>
                        {this.state.reglements.map((el) =>
                          el.reglFrais.map((elem) => <td> {elem.mntPayer} </td>)
                        )}
                        {this.state.reglements.map((el) =>
                          el.reglInscrip.map((elem) =>
                            elem.detailRegl.map((obj) => (
                              <td>{obj.totalPay}</td>
                            ))
                          )
                        )}
                      </tr>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          Rèçu N°:
                        </td>
                        {this.state.reglements.map((el) =>
                          el.reglFrais.map((elem) => <td> {elem.numRecu} </td>)
                        )}
                        {this.state.reglements.map((el) =>
                          el.reglInscrip.map((elem) =>
                            elem.detailRegl.map((obj) => <td>{obj.numRecu}</td>)
                          )
                        )}
                      </tr>
                    </tbody>
                  </table>
                ) : null}
                {this.state.modePayTrch == "19" ? (
                  <table
                    align="left"
                    border={1}
                    cellSpacing={1}
                    cellPadding={4}
                    bgcolor="#000000"
                  >
                    <tbody>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          &nbsp;
                        </td>
                        <td
                          colSpan={this.state.colSpanFrais}
                          width={30}
                          align="left"
                        >
                          frais d'inscription
                        </td>
                        <td colSpan={this.state.colSpanTroisTr1}>1</td>
                        <td colSpan={this.state.colSpanTroisTr2}>2</td>
                        <td colSpan={this.state.colSpanTroisTr3}>3</td>
                      </tr>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          Montant
                        </td>

                        {this.state.reglements.map((el) =>
                          el.reglFrais.map((elem) => <td> {elem.mntPayer} </td>)
                        )}
                        {this.state.reglements.map((el) =>
                          el.reglInscrip.map((elem) => (
                            <td>
                              {" "}
                              {elem.detailRegl.map((obj) => obj.totalPay)}{" "}
                            </td>
                          ))
                        )}
                      </tr>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          Rèçu N°:
                        </td>
                        {this.state.reglements.map((el) =>
                          el.reglFrais.map((elem) => <td> {elem.numRecu} </td>)
                        )}
                        {this.state.reglements.map((el) =>
                          el.reglInscrip.map((elem) => (
                            <td> {elem.numRecu} </td>
                          ))
                        )}
                      </tr>
                    </tbody>
                  </table>
                ) : null}
                {this.state.modePayTrch == "20" ? (
                  <table
                    align="left"
                    border={1}
                    cellSpacing={1}
                    cellPadding={4}
                    bgcolor="#000000"
                  >
                    <tbody>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          &nbsp;
                        </td>
                        <td
                          colSpan={this.state.colSpanFrais}
                          width={30}
                          align="left"
                        >
                          frais d'inscription
                        </td>
                        <td colSpan={this.state.colSpanDeuxTr1}>1</td>
                        <td colSpan={this.state.colSpanDeuxTr2}>2</td>
                      </tr>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          Montant
                        </td>

                        {this.state.reglements.map((el) =>
                          el.reglFrais.map((elem) => <td> {elem.mntPayer} </td>)
                        )}
                        {this.state.reglements.map((el) =>
                          el.reglInscrip.map((elem) => (
                            <td>
                              {" "}
                              {elem.detailRegl.map((obj) => obj.totalPay)}{" "}
                            </td>
                          ))
                        )}
                      </tr>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "14px",
                        }}
                      >
                        <td width={110} align="left">
                          Rèçu N°:
                        </td>
                        {this.state.reglements.map((el) =>
                          el.reglFrais.map((elem) => <td> {elem.numRecu} </td>)
                        )}
                        {this.state.reglements.map((el) =>
                          el.reglInscrip.map((elem) => (
                            <td> {elem.numRecu} </td>
                          ))
                        )}
                      </tr>
                    </tbody>
                  </table>
                ) : null}
              </td>
            </tr>
            <tr>
              <td style={{ color: 'black' }}>
                <strong>
                  {" "}
                  <em>
                    <u>Assiduité</u>
                  </em>{" "}
                </strong>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  align="left"
                  border={1}
                  cellSpacing={1}
                  cellPadding={4}
                  bgcolor="#000000"
                >
                  <tbody>
                    <tr
                      bgcolor="#FFFFFF"
                      align="center"
                      style={{
                        color: "#000000",
                        fontFamily: "Arial",
                        fontSize: "14px",
                      }}
                    >
                      <td width={275} align="left">
                        &nbsp;
                      </td>
                      <td width={85}>1</td>
                      <td width={85}>2</td>
                      <td width={85}>3</td>
                      <td width={85}>4</td>
                      <td width={85}>5</td>
                      <td width={85}>6</td>
                      <td width={85}>7</td>
                      <td width={85}>8</td>
                      <td width={85}>9</td>
                      <td width={85}>10</td>
                      <td width={85}>11</td>
                      <td width={85} align="left">
                        Cummul
                      </td>
                    </tr>
                    <tr
                      bgcolor="#FFFFFF"
                      align="center"
                      style={{
                        color: "#000000",
                        fontFamily: "Arial",
                        fontSize: "14px",
                      }}
                    >
                      <td width={85} align="left">
                        Mois
                      </td>
                      <td width={85}>Octobre </td>
                      <td width={85}>Novembre </td>
                      <td width={85}>Decembre </td>
                      <td width={85}>Janvier </td>
                      <td width={85}>Fevrier </td>
                      <td width={85}>Mars </td>
                      <td width={85}>Avril </td>
                      <td width={85}>Mai </td>
                      <td width={85}>Juin </td>
                      <td width={85}>juillet </td>
                      <td width={85}>aout </td>
                      <td width={85} align="left">
                        {" "}
                      </td>
                    </tr>
                    <tr
                      bgcolor="#FFFFFF"
                      align="center"
                      style={{
                        color: "#000000",
                        fontFamily: "Arial",
                        fontSize: "14px",
                      }}
                    >
                      <td width={200} align="left">
                        nombre d'absences
                      </td>
                      {this.state.absEleve.absMois.map((el) => (
                        <td> {el} </td>
                      ))}
                      <td> {this.state.absEleve.sum} </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ color: 'black' }}>
                <strong>
                  {" "}
                  <em>
                    <u>Résultats Scolaires</u>
                  </em>{" "}
                </strong>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <br />
                {this.state.spec == 1 ? (
                  <table
                    align="left"
                    border={1}
                    cellSpacing={1}
                    cellPadding={4}
                    bgcolor="#000000"
                  >
                    <tbody>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "12px",
                        }}
                      >
                        <td width={110} align="center">
                          <strong>Moyenne Annuelle</strong>.
                        </td>
                        <td width={110} align="center">
                          <strong>Stage</strong>
                        </td>
                        <td width={110} align="center">
                          <strong>PFE</strong>
                        </td>
                        <td width={110} align="center">
                          <strong>EFF</strong>
                        </td>
                        <td width={110} align="center">
                          <strong>Moyenne Général</strong>
                        </td>
                        <td width={110} align="center">
                          <strong>Mention</strong>
                        </td>
                      </tr>

                      {this.state.notes.map((el) => (
                        <tr
                          bgcolor="#FFFFFF"
                          align="center"
                          style={{
                            color: "#000000",
                            fontFamily: "Arial",
                            fontSize: "14px",
                          }}
                        >
                          <td width={110} align="center">
                            {el.moyenneAnn}
                          </td>
                          <td width={110} align="center">
                            {el.stage}
                          </td>
                          <td width={110} align="center">
                            {el.notePFE}
                          </td>
                          <td width={110} align="center">
                            {el.examen}
                          </td>
                          <td width={110} align="center">
                            {el.moyenne}
                          </td>
                          <td width={110} align="center">
                            {el.decision}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <table
                    align="left"
                    border={1}
                    cellSpacing={1}
                    cellPadding={4}
                    bgcolor="#000000"
                  >
                    <tbody>
                      <tr
                        bgcolor="#FFFFFF"
                        align="center"
                        style={{
                          color: "#000000",
                          fontFamily: "Arial",
                          fontSize: "12px",
                        }}
                      >
                        <td width={110} align="center">
                          <strong>Trim 1</strong>.
                        </td>
                        <td width={110} align="center">
                          <strong>Trim 2</strong>.
                        </td>
                        <td width={110} align="center">
                          <strong>Trim 3</strong>.
                        </td>
                        <td width={110} align="center">
                          <strong>Moyenne Annuelle</strong>.
                        </td>
                        <td width={110} align="center">
                          <strong>Stage</strong>
                        </td>
                        <td width={110} align="center">
                          <strong>EFF</strong>
                        </td>
                        <td width={110} align="center">
                          <strong>Moyenne Général</strong>
                        </td>
                        <td width={110} align="center">
                          <strong>Mention</strong>
                        </td>
                      </tr>
                      {this.state.notes.map((el) => (
                        <tr
                          bgcolor="#FFFFFF"
                          align="center"
                          style={{
                            color: "#000000",
                            fontFamily: "Arial",
                            fontSize: "14px",
                          }}
                        >
                          <td width={110} align="center">
                            {el.tr1}
                          </td>
                          <td width={110} align="center">
                            {el.tr2}
                          </td>
                          <td width={110} align="center">
                            {el.tr3}
                          </td>
                          <td width={110} align="center">
                            {el.moyAnnuelle}
                          </td>
                          <td width={110} align="center">
                            {el.stage}
                          </td>
                          <td width={110} align="center">
                            {el.examen}
                          </td>
                          <td width={110} align="center">
                            {el.moyenne}
                          </td>
                          <td width={110} align="center">
                            {el.decision}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {/* Table 2  */}
        <table
          style={{ marginTop: "1%" }}
          width={850}
          border={0}
          align="center"
        >
          <tbody>
            <tr>
              <td valign="top">
                <table width={849}>
                  <tbody>
                    <tr>
                      <td width={489} valign="top">
                        <table
                          width={475}
                          border={1}
                          align="left"
                          cellPadding={4}
                          cellSpacing={1}
                          bgcolor="#000000"
                        >
                          <tbody>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={394} colSpan={2} align="left">
                                <strong>
                                  Comportement &amp; Appréciation :
                                </strong>
                              </td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td height={25} align="left">
                                <strong>Bonne appréciation pour&nbsp;:</strong>
                              </td>
                              <td align="left"> </td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td height={25} align="left">
                                <strong>
                                  Mauvaise appréciation pour&nbsp;:
                                </strong>
                              </td>
                              <td align="left"> </td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td height={28} align="left">
                                <strong>Meilleur élèves&nbsp; </strong>
                              </td>
                              <td align="left"></td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={144} height={25} align="left">
                                <strong> Engagements signés :</strong>{" "}
                              </td>
                              <td width={286} align="left"></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>{" "}
                      <td width={348} valign="top">
                        <table
                          width={360}
                          border={1}
                          align="left"
                          cellPadding={4}
                          cellSpacing={1}
                          bgcolor="#000000"
                        >
                          <tbody>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={394} align="left" bgcolor="#CCCCCC">
                                <strong>Sanction de 1° degré : </strong>
                              </td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={394} align="left" bgcolor="#CCCCCC">
                                <strong>Sanction de 2° degré : </strong>
                              </td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={394} align="left" bgcolor="#CCCCCC">
                                <strong>Sanction de 3° degré : </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <table width={849}>
                  <tbody>
                    <tr>
                      <td width={489} valign="top">
                        <table
                          width={475}
                          border={1}
                          align="left"
                          cellPadding={4}
                          cellSpacing={1}
                          bgcolor="#000000"
                        >
                          <tbody>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td colSpan={2} align="left">
                                <strong>Observation Spéciales</strong>
                              </td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={170}>
                                <font color="#000000">
                                  <font
                                    face="Verdana, Arial, Helvetica, sans-serif"
                                    size={1}
                                  >
                                    Compréhension des consignes orales :
                                  </font>
                                </font>
                              </td>
                              <td width={306}>
                                <font
                                  face="Verdana, Arial, Helvetica, sans-serif"
                                  size={1}
                                ></font>
                              </td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={170}>
                                {" "}
                                <font
                                  face="Verdana, Arial, Helvetica, sans-serif"
                                  size={1}
                                >
                                  Compréhension des consignes écrites :
                                </font>
                              </td>
                              <td width={306}>
                                <font
                                  face="Verdana, Arial, Helvetica, sans-serif"
                                  size={1}
                                ></font>
                              </td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={170}>
                                <font color="#000000">
                                  <font
                                    face="Verdana, Arial, Helvetica, sans-serif"
                                    size={1}
                                  >
                                    handicap ou maladie apparente ou déclarée :
                                  </font>
                                </font>
                              </td>
                              <td width={306}>
                                <font
                                  face="Verdana, Arial, Helvetica, sans-serif"
                                  size={1}
                                ></font>
                              </td>
                            </tr>
                            <tr
                              bgcolor="#FFFFFF"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={170}>
                                <font color="#000000">
                                  <font
                                    face="Verdana, Arial, Helvetica, sans-serif"
                                    size={1}
                                  >
                                    Dossier médical déposé : ou maladie
                                    apparente ou déclarée :
                                  </font>
                                </font>
                              </td>
                              <td width={306}>
                                <font
                                  face="Verdana, Arial, Helvetica, sans-serif"
                                  size={1}
                                ></font>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td width={348} valign="top">
                        <table
                          width={360}
                          border={1}
                          align="left"
                          cellPadding={4}
                          cellSpacing={1}
                          bgcolor="#000000"
                        >
                          <tbody>
                            <tr
                              bgcolor="#FFFFFF"
                              align="center"
                              style={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "12px",
                              }}
                            >
                              <td width={394} align="left">
                                <strong>
                                  Note Spéciale (Comportement, déclarations de
                                  l'apprenant ..)
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <i id="print_me_not"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={this.print}
            class="fas fa-print"
          ></i>
        </div>
      </div>
    );
  }
}

export default withRouter(ImprProfilApp);
