import React, { Component } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";

class NatureNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Nature Note",
          selector: "natNot",
          sortable: false,
          center: true,
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 5,
      modal: false,
      pending: true,
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/NatureNote.php",
        type: "POST",
        data: {
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
    }
  }

  newElement(page, totalRows) {
    this.setState(
      {
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/NatureNote.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: page,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  perPage(currentRowsPerPage, currentPage) {
    this.setState(
      {
        nbrEltperPage: currentRowsPerPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/NatureNote.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: currentRowsPerPage,
            page: currentPage,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  filterSearch(event) {
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      () => {
        jQuery.ajax({
          url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/NatureNote.php",
          type: "POST",
          data: {
            query: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  render() {
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Nature Note</h4>
            </div>
            {/* /.col-lg-12 */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                >
                  <Link to="/AjoutNatureNote">
                    {" "}
                    <button
                      style={{
                        background: "#3A3F51",
                        border: "1px solid #3A3F51",
                        borderRadius: "5px",
                      }}
                      type="button"
                      className="btn btn-info"
                    >
                      {" "}
                      <i className="fas fa-plus"></i>Ajout Nature Note
                    </button>
                  </Link>
                </div>
                <div className="mb-0 form-group">
                  <div className="input-group-alternative input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                      <input
                        onChange={(currentRowsPerPage, currentPage) =>
                          this.filterSearch(currentRowsPerPage, currentPage)
                        }
                        placeholder="Search"
                        type="text"
                        className="search"
                      />
                    </div>
                  </div>
                </div>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  highlightOnHover={true}
                  pagination={true}
                  paginationServer={true}
                  onChangePage={(page, totalRows) =>
                    this.newElement(page, totalRows)
                  }
                  paginationTotalRows={this.state.nombreTotalElement}
                  paginationPerPage={this.state.nbrEltperPage}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                    this.perPage(currentRowsPerPage, currentPage)
                  }
                  paginationResetDefaultPage={this.state.resetFirstPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "Elements par page:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: false,
                  }}
                  progressPending={this.state.pending}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NatureNote;
