import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';

class ModifSpecialite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idSpecialite: '',
            specialite: {},
            ListFilieres: [],
            selectedFiliere: ''
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            var idSpecialite = this.props.location.state.idSpecialite;
            axios.get(process.env.REACT_APP_API_URL + 'Back_mediatraining/GetSpecialiteById.php?id=' + idSpecialite).then(res => {
                console.log(res)
                this.setState({
                    idSpecialite: res.data.idSpecialite,
                    specialite: res.data
                })
            })
            var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
            var anneeScolaire = userPermissions[0].anneeScolaire
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mediatraining/SelectFilieres.php',
                type: 'POST',
                data: {
                    anneeScolaire: anneeScolaire
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    this.setState({
                        ListFilieres: code_html
                    })
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    updNomSpecialite = (event) => {
        let nomSpecialite = event.target.value;
        this.setState({
            specialite: Object.assign(this.state.specialite, { nomSpecialite: nomSpecialite })
        });
    }

    updFiliere = (selectedFiliere) => {
        let filiere = selectedFiliere;
        this.setState({
            specialite: Object.assign(this.state.specialite, { filiere: filiere })
        });
    }

    modif = () => {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        var userId = userPermissions[0].idUser
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + 'Back_mediatraining/UpdSpecialite.php?id=' + this.state.idSpecialite,
            type: 'POST',
            data: {
                specialite: this.state.specialite,
                userId: userId
            },
            dataType: 'json',
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push('/Specialites');
                }
                else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            }
        });
    }

    render() {
        return (
            <div id="page-wrapper" style={{ minHeight: '263px', marginLeft: '0px' }}>
                <div className="container-fluid">
                    <div className="row bg-title">
                        <div className="col-lg-12">
                            <h4 className="page-title">Modification specialité</h4>
                        </div>
                        {/* /.col-lg-12 */}
                    </div>
                    {/* /.row */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginRight: '20px' }}>
                                    <Link to="/Specialites" > <button style={{ background: '#DC143C', border: '1px solid #DC143C', borderRadius: '5px' }} type="button" className="btn btn-info"> <i style={{ paddingRight: '5px' }} className="fas fa-arrow-circle-left"></i>Retour</button></Link>
                                </div>
                                <div className="row">
                                    <table style={{ width: '100%', marginTop: '10px' }} className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }} scope="col">Nom</th>
                                                <th style={{ textAlign: 'center' }} scope="col">Filiére</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="col-md-4" style={{ textAlign: 'center' }}><input type="text" value={this.state.specialite.nomSpecialite} onChange={(e) => this.updNomSpecialite(e)} style={{ textAlign: 'center', height: '38px', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} /></td>
                                                <td className="col-md-8"><Select
                                                    value={this.state.specialite.filiere}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => this.updFiliere(e)}
                                                    options={this.state.ListFilieres}
                                                />  </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <button style={{ borderRadius: '5px' }} type="button" className="btn btn-success" onClick={this.modif}>Confirmer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifSpecialite);