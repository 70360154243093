import React, { Component } from "react";
import jQuery from "jquery";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import Table from "reactstrap/lib/Table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class ReleveFin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listEleves: [],
      selectEleve: "",
      inscriptionId: "",
      niveau: "",
      nomFiliere: "",
      groupe: "",
      moduleEG: [],
      moduleET: [],
      moduleEP: [],
      blockNote: false,
      arrayAllData: [],
      moyenneModule: "",
      notes: [],
      noteEG:[],
      noteEP:[],
      noteET:[], 
      notefinal:[],
      annee:'',
      annees:'',
      rangfinal:[],
      rangfinale:[],
      anneeSco:[],
      tab:[],
      r:0,
      ids:'',
      moyenneAnnuelle:"",
              moyenneStage:"",
              moyennegenerale:"",
              moyPFE:"",
             
              moyeg:"",
              moyep:"",
              moyet:"",
           mois: [{ value: 'Janvier', label: 'Janvier' }, { value: 'Férvier', label: 'Férvier' }, { value: 'Mars', label: 'Mars' }, { value: 'Avril', label: 'Avril' }, { value: 'Mai', label: 'Mai' }, { value: 'Juin', label: 'Juin' }, { value: 'Juillet', label: 'Juillet' }, { value: 'Aout', label: 'Aout' }, { value: 'Septembre', label: 'Septembre' }, { value: 'Octobre', label: 'Octobre' }, { value: 'Novembre', label: 'Novembre' }, { value: 'Decembre', label: 'Decembre' }],
		    moisFin: [{ value: 'Janvier', label: 'Janvier' }, { value: 'Férvier', label: 'Férvier' }, { value: 'Mars', label: 'Mars' }, { value: 'Avril', label: 'Avril' }, { value: 'Mai', label: 'Mai' }, { value: 'Juin', label: 'Juin' }, { value: 'Juillet', label: 'Juillet' }, { value: 'Aout', label: 'Aout' }, { value: 'Septembre', label: 'Septembre' }, { value: 'Octobre', label: 'Octobre' }, { value: 'Novembre', label: 'Novembre' }, { value: 'Decembre', label: 'Decembre' }],
       selectedMois: '',
	    selectedMoisFin: '',
    };
  }
  
   searchMois = selectedMois => {
    if (selectedMois != null) {
        this.setState({ selectedMois });
    }else{
      selectedMois=''
    }console.log(this.state.selectedMois.value)
    
    
}

  searchMoisFin = selectedMoisFin => {
    if (selectedMoisFin != null) {
        this.setState({ selectedMoisFin });
    }else{
      selectedMoisFin=''
    }console.log(this.state.selectedMoisFin.value)
    
    
}

addannee = (event) => {
  this.setState({
      annee: event.target.value
  });
}
addanneefin = (event) => {
  this.setState({
      anneefin: event.target.value
  });
}
  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
      var anneeScolaire = userPermissions[0].anneeScolaire;
      this.state.annee=anneeScolaire;
      this.state.annees=this.state.annee ++ 
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL +
          "Back_mediatraining/elevefiltre.php",
        type: "POST",
        data: {
          
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            listEleves: code_html,
           
          });
        },
      });console.log(this.state.anneeSco)
    } else {
      document.location = "/";
    }
  }

  addEleve = (selectEleve) => {
    if (selectEleve != null) {
      this.setState({ selectEleve });

    } else {
      this.setState({
        selectEleve: "",
      });
    }
  };
    
    
    affiche = () => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    {this.state.r=0}
    if (this.state.selectEleve != "" ) {
        jQuery.ajax({
        url:
            process.env.REACT_APP_API_URL + "Back_mediatraining/ReleveFinal.php",
        type: "POST",
        data: {
          eleveId: this.state.selectEleve.value,
          anneeScolaire: this.state.selectEleve.annee,
        
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            notes: code_html,
            noteEG:code_html.allNote.noteEG,
            noteEP:code_html.allNote.noteEP,
            noteET:code_html.allNote.noteET,
            moyet:code_html.allNote.moyET,
            moyeg:code_html.allNote.moyEG,
            moyep:code_html.allNote.moyEP,
          });
        } ,
      });
      this.setState({
        blockNote: true,
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/final.php",
        type: "POST",
        data: {
          eleveId: this.state.selectEleve.value,
          anneeScolaire: this.state.selectEleve.annee,
          
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
              notefinal: code_html[1],
              moyenneAnnuelle:code_html[1][0].MoyModyle,
              moyenneStage:code_html[1][0].moyenneStage,
              moyennegenerale:code_html[1][0].moyenne,
              moyPFE:code_html[1][0].moyPFE,
          });
          
        },
      });
      jQuery.ajax({
        url:
          process.env.REACT_APP_API_URL + "Back_mediatraining/rang.php",
        type: "POST",
        data: {
          eleveId: this.state.selectEleve.value,
          anneeScolaire: this.state.selectEleve.annee,
          
        },
        dataType: "json",
        success: (code_html, statut) => {
        
          this.setState(
            {
            rangfinale: code_html[1],
            ids:code_html[6],
          },
          ()=>{
          
            var tab=[];
            var tabs=[];
            var j=1;
            this.state.rangfinal=this.state.rangfinale.sort((a, b) => b.moyenne - a.moyenne)
            console.log(this.state.rangfinal)
            for (var i = 0; i < this.state.rangfinal.length; i++) {
            
              
              if (i>0){
                
              if(this.state.rangfinal[i-1].moyenne==this.state.rangfinal[i].moyenne ){
              if(tab.length>0){
                //j=tab.length
             tab=[...tab,j]
             j=j+1
             tabs=[...tabs,this.state.rangfinal[i].moyenne]
             
              }else{
                
                tab=[...tab,j]
                
              }

              }else{
                j=j+1
                tab=[...tab,j]
                tabs=[...tabs,this.state.rangfinal[i].moyenne ]
              }
            }else{
              tabs=[...tabs,this.state.rangfinal[i].moyenne ]
              tab=[...tab,j]
            }
            }console.log(tab) 
          console.log(tabs)
          console.log("test")
              
             
          this.setState({
            tab: tab,
          });

          }
          );

        },
        
        
      }); 
     
             
      this.setState({
        blockNote: true,
      });

    } else {
      toast.error("⛔ Veuillez vérifier Eleve!", {
        containerId: "A",
      });
    }
  };

  impr = (

  ) => {
    var userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
    var anneeScolaire = userPermissions[0].anneeScolaire;
    
        window.open(
          "http://artsmetiers.mtd-app.com/ImpReleveNoteFinale?date=" + this.state.selectEleve.value+this.state.selectEleve.annee+'&annef='+this.state.selectEleve.anneef+'&moisimp='+this.state.selectedMois.value+'&anneeimpd='+this.state.annee+'&anneeimpf='+this.state.anneefin+'&moisfinimp='+this.state.selectedMoisFin.value
          //"http://localhost:3000/ImpReleveNoteFinale?date=" + this.state.selectEleve.value+this.state.selectEleve.annee
        );
      
  };

  render() {
      
    return (
      <div id="page-wrapper" style={{ minHeight: "263px", marginLeft: "0px" }}>
        <div className="container-fluid">
          <div className="row bg-title">
            <div className="col-lg-12">
              <h4 className="page-title">Relevé De Notes de fin de formation</h4>
            </div>
          </div>
          {/* /.row */}
          <div className="row">
          
            <div className="col-sm-12">
              
              <div className="white-box">
              
                <div className="row">
              
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Apprenant</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addEleve}
                        options={this.state.listEleves}
                      />
                    </div>
                  </div>
                  <div className="col-md-2" style={{paddingLeft:"40px" }}>
                                            
                                                <label>Mois debut</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => this.searchMois(e)}
                                                    options={this.state.mois}
                                                />
                                            
                                        </div>
										
										   <div className="col-md-2" style={{paddingLeft:"40px" }}>
                                            
                                                <label>Mois fin</label>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => this.searchMoisFin(e)}
                                                    options={this.state.moisFin}
                                                />
                                            
                                        </div>
                                         <div className="col-md-2" style={{paddingLeft:"40px"}}>
                                            <label>Année debut </label>
                                         <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" className="form-control" placeholder='annee' onChange={(e) => this.addannee(e)} />
                                            
                                        </div>
										 <div className="col-md-2" style={{paddingLeft:"40px"}}>
                                            <label>Année fin </label>
                                         <input style={{ backgroundColor: 'white', borderRadius: '5px', border: 'solid 1px #B3B3B3' }} type="text" className="form-control" placeholder='annee' onChange={(e) => this.addanneefin(e)} />
                                            
                                        </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.affiche}
                    >
                      Afficher
                    </button>
                  </div>
                  <div>
                    <button
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#761C19",
                        borderColor: "#761C19",
                      }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.impr}
                    >
                      <i
                        style={{ color: "white", paddingRight: "5px" }}
                        class="fas fa-print"
                      ></i>
                      Impression
                    </button>
                  </div>
                </div>
                {this.state.blockNote == true ? (
                  <div>
                      {this.state.notefinal.map((el,i) => (
                          <div>
                    <div style={{ margin: "5vh" }}>
                   
                      
                    </div>
                    
                    <Table striped bordered hover>
                      <thead >
                        <tr>
                          <th style={{ color: "#ac3939" }}>Enseignement</th>
                          <th style={{display: "flex",justifyContent:"space-between" }}><div style={{ color: "#ac3939" }} >Module </div>      <div style={{ color: "#ac3939" }}> Moyenne</div></th>
                          <th style={{ color: "#ac3939"  ,textAlign: "center" }}>
                            Moyenne 
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.notefinal.map((elem) => (
                          <tr>
                          <td>Enseignement Pratique(5)</td>
                          <td>
                          {this.state.noteEP.map((e)=>(
                               <div style={{display: "flex",justifyContent:"space-between"}}>
                               <p >{e.matiere}</p>  <p> {e.matiereNote}</p> 
                               </div>
                              ))}
                          </td>
                            <td>
                        
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p >
                                    
                                {this.state.moyep}
                                  </p>
                                </div>
                              
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p>  </p>
                            </td>
                          </tr>
                          ))}
                    
                          {this.state.notefinal.map((elem) => (
                          <tr>
                          <td>Enseignement Général(1)</td>
                 
                            <td>
                            {this.state.noteEG.map((e)=>(
                                <div style={{display: "flex",justifyContent:"space-between"}}>
                                <p >{e.matiere}</p>  <p> {e.matiereNote}</p> 
                                </div>
                              ))}
                            </td>
                            <td>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p>  
                                 {this.state.moyeg} </p>
                                </div>
                             
                            </td>
                            <td style={{ textAlign: "center" }}>
                             
                            </td>
                          </tr>
                          ))}
                        {this.state.notefinal.map((elem) => (
                          <tr>
                          <td>Enseignement Technique(2)</td>
                            <td>
                            {this.state.noteET.map((e)=>(
                                <div style={{display: "flex",justifyContent:"space-between"}}>
                           <p >{e.matiere}</p>  <p> {e.matiereNote}</p> 
                           </div>
                          ))}
                            </td>
                            <td>
                        
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <p >
                                    
                                {this.state.moyet}
                                    </p>
                                    
                                </div>
                                
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p>  </p>
                            </td>
                          </tr>
                        ))}
                        
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                              Moyenne examen de fin de formation {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {this.state.notefinal.map((elem) => (
                              <p> {elem.MoyEFF} </p>
                            ))}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                              Moyenne annuelle {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                          
                              <p> {this.state.moyenneAnnuelle} </p>
                            
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                            Moyenne des stages {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            
                              <p> {this.state.moyenneStage} </p>
                           
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                            Moyenne PFE {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            
                              <p> {this.state.moyPFE} </p>
                           
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                              Moyenne générale {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            
                              <p> {this.state.moyennegenerale} </p>
                          
                          </td>
                        </tr>
                        <tr>
                          
                          <td>
                              
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                              Rang {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            
                            {this.state.rangfinal.map((elemi,inde) => 
                          
                                (elemi.eleveId == this.state.selectEleve.value ?
                                  this.state.tab[inde]:null
                                    
                                    ))} 
                          
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            <strong style={{ color: "#ac3939" }}>
                              Décision du jury {" "}
                            </strong>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {this.state.notefinal.map((elem) => (
                              <p> {elem.decision} </p>
                            ))}{" "}
                          </td>
                        </tr>
                        
                      </tbody>
                    </Table>
                    </div>
                    
                    ))}
               
                  </div>
                ) : null}
                <p style={{display :"none"}}>{this.state.r=0}</p> 
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
        
     
          
      </div>
    );
  }
}

export default withRouter(ReleveFin);